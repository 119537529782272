import * as React from 'react';
import Svg, { G, Circle } from 'react-native-svg';

function MoreVertIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 4}
      height={props.height ? props.height : 20}
      viewBox="0 0 4 20"
      {...props}
    >
      <G data-name="Group 53850" transform="translate(-391 -47)" fill="#9295a5">
        <Circle
          data-name="Ellipse 1122"
          cx={2}
          cy={2}
          r={2}
          transform="translate(391 47)"
        />
        <Circle
          data-name="Ellipse 1123"
          cx={2}
          cy={2}
          r={2}
          transform="translate(391 55)"
        />
        <Circle
          data-name="Ellipse 1124"
          cx={2}
          cy={2}
          r={2}
          transform="translate(391 63)"
        />
      </G>
    </Svg>
  );
}

export default MoreVertIcon;
