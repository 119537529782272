import React, { useContext } from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { ThemeContext } from '../../contexts/ThemeContext';

export const ExtendIcon = (props) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="207"
      viewBox="0 0 41 207"
    >
      <g
        id="Group_333834"
        data-name="Group 333834"
        transform="translate(14836 -12245) rotate(-90)"
      >
        <path
          id="Path_337387"
          data-name="Path 337387"
          d="M40,0H167c10.726,0,15,8.6,20,17.354S207,41,207,41H0S15,26.1,20,17.354,27.784,0,40,0Z"
          transform="translate(-12452 -14836)"
          fill="#5995C7"
        />
        <g
          id="Group_333831"
          data-name="Group 333831"
          transform="translate(-0.422 -2)"
        >
          <g
            id="Group_333832"
            data-name="Group 333832"
            transform="translate(2 4)"
          >
            <path
              id="Union_44"
              data-name="Union 44"
              d="M6.97,7.023,2.422,2.475-2.126,7.023a.85.85,0,0,1-1.2,0,.85.85,0,0,1,0-1.2L1.821.671,1.837.655A.85.85,0,0,1,3.023.671l5.15,5.15a.85.85,0,1,1-1.2,1.2Z"
              transform="translate(-12411 -14821.422)"
              fill="#fff"
            />
            <text
              id="EXTENDED"
              data-name="EXTENDED"
              transform="translate(-12398 -14813)"
              fill="#fff"
              font-size="12"
              font-family="SegoeUI-Bold, Segoe UI"
              font-weight="700"
              letter-spacing="0.12em"
            >
              {props?.text ? props?.text : 'EXTENDED PREV'}
            </text>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default ExtendIcon;
