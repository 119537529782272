import * as React from 'react';
import Svg, { G, Circle } from 'react-native-svg';

function CircleIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 22 22"
      {...props}
    >
      <G
        data-name="circle icon"
        fill="rgba(15,15,18,0.28)"
        stroke="#21222b"
        strokeWidth={1}
      >
        <Circle cx={11} cy={11} r={11} stroke="none" />
        <Circle cx={11} cy={11} r={10.5} fill="none" />
      </G>
    </Svg>
  );
}

export default CircleIcon;
