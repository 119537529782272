import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { BackButton } from '../back-button/back-button';
import { Header } from '../header/header';
import { colors } from '../colors/colors';
import _ from 'lodash';
import { StyleProp, ViewStyle } from 'react-native';
import { isWeb } from '@zonofi/common';

/* eslint-disable-next-line */
export interface LinearBackgroundWrapperProps {
  children: React.ReactNode | React.ReactNode[];
  headerShown?: boolean;
  allowBack?: boolean;
  style?: StyleProp<ViewStyle>;
  navigation?: string;
}

export const LinearBackgroundWrapper: React.FC<
  LinearBackgroundWrapperProps
> = ({ children, headerShown = true, allowBack = true, style, navigation }) => {
  return (
    <LinearGradient
      colors={[
        colors.blackLinearGradient.firstcolor,
        colors.blackLinearGradient.secondcolor,
      ]}
      style={style ? style : { alignItems: 'center', height: hp(100) }}
    >
      {isWeb() &&
        !_.isUndefined(headerShown) &&
        headerShown &&
        (!_.isUndefined(allowBack) && allowBack ? (
          <Header leftContainer={<BackButton navigation={navigation} />} />
        ) : (
          <Header />
        ))}
      {children}
    </LinearGradient>
  );
};
