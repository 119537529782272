import * as React from 'react';
import Svg, { G, Rect, Path } from 'react-native-svg';

function CheckBoxGreen(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 17 17"
      {...props}
    >
      <G
        data-name="Rectangle 15869"
        transform="translate(-106 -420) translate(106 420)"
        fill="#00c17f"
        stroke="#00b174"
        strokeWidth={1}
      >
        <Rect width={17} height={17} rx={4} stroke="none" />
        <Rect x={0.5} y={0.5} width={16} height={16} rx={3.5} fill="none" />
      </G>
      <G data-name="Group 53838">
        <G
          data-name="Group 53331"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={1.5}
          transform="translate(-106 -420) translate(110.444 425.631)"
        >
          <Path
            data-name="Line 4057"
            transform="translate(0 3.136)"
            d="M0 0L2.587 2.587"
          />
          <Path
            data-name="Line 4058"
            transform="translate(2.587)"
            d="M5.722 0L0 5.722"
          />
        </G>
      </G>
    </Svg>
  );
}

export default CheckBoxGreen;
