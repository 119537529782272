import { camelCase, startCase } from 'lodash';
import { ReturnStatusEnum } from '../types';

export const getReturnStatusText = (status: string) => {
  switch (status?.toUpperCase()) {
    case ReturnStatusEnum.SUBMITTED:
      return 'Submitted';
    case ReturnStatusEnum.APPROVED:
      return 'Approved by CFA';
    case ReturnStatusEnum.INREVIEW:
      return 'In Review';
    case ReturnStatusEnum.DRAFT:
      return 'Draft';
    default:
      return startCase(camelCase(status?.replace(/_/g, ' ')));
  }
};
