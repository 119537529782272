import { isWeb } from '@zonofi/zono-money-helpers';
import React, { CSSProperties } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

/* eslint-disable-next-line */
export interface SvgAssetProps {
  SVGComponent: string;
  height: number;
  width: number;
  style?: StyleProp<ViewStyle>;
  styleWeb?: CSSProperties;
}

export const SvgAsset: React.FC<SvgAssetProps> = ({
  SVGComponent,
  height,
  width,
  style,
  styleWeb,
}) => {
  return isWeb() ? (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img src={SVGComponent} height={height} width={width} style={styleWeb} />
  ) : (
    //@ts-ignore
    <SVGComponent height={height} width={width} style={style} />
  );
};
