import _ from 'lodash';
import moment from 'moment';

export const getCalculatedValue = (val: number) => {
  let amount = parseFloat(val?.toString());
  const units = ['', 'K', 'M', 'B', 'T'];
  let i = 0;

  while (amount >= 1e3 && i < units.length - 1) {
    amount /= 1e3;
    i++;
  }
  return `${amount.toFixed(0) + units[i]}`;
};

export const getAbsoluteValue = (val: number) => {
  let value = parseFloat(val?.toString());
  const units = ['', 'K', 'M', 'B', 'T'];
  let i = 0;

  while (value >= 1e3 && i < units.length - 1) {
    value /= 1e3;
    i++;
  }
  return { value: parseFloat(value.toFixed(2)), sign: units[i] };
};

export const getFormattedDate = (
  date?: string | Date,
  format?:
    | 'short-simple'
    | 'short'
    | 'long'
    | 'long-simple'
    | 'date-time'
    | 'date-time-start'
    | 'date-time-end'
    | 'slashed'
    | 'short-reverse'
    | 'date-time-dot-separated'
    | 'short-with-year'
    | 'date-time-query'
) => {
  if (!_.isUndefined(date)) {
    switch (format) {
      case 'short-simple':
        return moment(date).format('Do MMM');
      case 'short-reverse':
        return moment(date).format('MMM DD');
      case 'short':
        return moment(date).format('Do MMMM');
      case 'short-with-year':
        return moment(date).format('Do MMM YYYY');
      case 'long':
        return moment(date).format('Do MMMM, YYYY');
      case 'long-simple':
        return moment(date).format('DD MMMM, YYYY');
      case 'date-time':
        return moment(date).format('DD MMM YYYY | hh:mm a');
      case 'date-time-dot-separated':
        return moment(date).format('DD MMMM [•] hh:mma');
      case 'date-time-start':
        return `${moment(date).format('YYYY-MM-DD')} 00:00:00`;
      case 'date-time-end':
        return `${moment(date).format('YYYY-MM-DD')} 23:59:59`;
      case 'date-time-query':
        return `${moment(date).format('YYYY-MM-DD hh:mm:ss')}`;
      case 'slashed':
        return moment(date).format('DD/MM/YYYY');
      default:
        return moment(date).format('Do MMM, YYYY');
    }
  }
  return '-';
};

export const getFormattedString = (value: string | undefined) => {
  if (!_.isUndefined(value)) {
    const result = value.trim().split(/\s+/);
    const finalString = result
      ?.map((item) => {
        return item[0]?.toLocaleUpperCase() + item.slice(1).toLocaleLowerCase();
      })
      .join(' ');
    return finalString;
  }
  return '';
};

const dateFormats = [
  'DD/MM/YYYY',
  'D/MM/YYYY',
  'MM/DD/YY',
  'DD/MMM/YY',
  'D/MMM/YY',
  'DD/MMM/YYYY',
  'D/MMM/YYYY',
  'DD MMM, YYYY',
  'D MMM, YYYY',
  'DD MMM YYYY',
  'D MMM YYYY',
  'DD MMM YY',
  'D MMM YY',
  'DD MMM, YY',
  'D MMM, YY',
  'YYYY MMM DD',
  'YYYY MMM D',
  'YYYY-MM-DD',
  'YYYY-MM-D',
  'YYYY-MMM-DD',
  'YYYY-MMM-D',
  'DD-MM-YY',
  'D-MM-YY',
  'DD-MM-YYYY',
  'D-MM-YYYY',
  'DD-MMM-YY',
  'D-MMM-YY',
  'DD-MMM-YYYY',
  'D-MMM-YYYY',
  'DD.MM.YYYY',
  'D.MM.YYYY',
  'MMM YYYY',
  'YYYY MMM',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ssZ',
  'DD/MM/YYYY:HH:mm:ssZ',
  'YYYY/MM/DD:HH:mm:ssZ',
  'DD-MM-YYYY:HH:mm:ssZ',
  'YYYY/MM/DDTHH:mm:ss.SSSZ',
];

export const parseDate = (dateString: string) => {
  for (const format of dateFormats) {
    const date = moment(dateString, format, true);
    if (date.isValid()) {
      return date;
    }
  }
  return moment(dateString);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber.toString();
  phoneNumber = phoneNumber.replace(/^0+/, '');

  const countryCode = phoneNumber.slice(0, 2);
  const restOfNumber = phoneNumber.slice(2);

  return `+${countryCode} ${restOfNumber}`;
};

export const formatDate = (inputDate: string | Date) => {
  const date = new Date(inputDate);
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
};
