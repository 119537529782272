import React, { useContext } from 'react';
import { colors } from '../colors/colors';
import { ThemeContext } from '../contexts/ThemeContext';
import './drill-down-text.scss';

interface DrillDownTextProps {
  title: string;
  promotionId?: number;
  truncateValue?: number;
  fontFamily?: string;
  fontSize?: number;
  color?: string;
  textWithHyperLink?: boolean;
  onClickAction?: () => void;
}

export const DrillDownText: React.FC<DrillDownTextProps> = ({
  title,
  fontFamily,
  fontSize,
  color,
  textWithHyperLink,
  onClickAction,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <p
      className="drill-down-text"
      style={{
        ...theme.fontConfig.regular,
        color: 'rgb(50, 167, 232)',
        margin: 0,
        fontSize,

        marginBottom: 3,
        whiteSpace: 'nowrap',
        cursor: textWithHyperLink ? 'pointer' : 'default',
        // textDecoration: textWithHyperLink ? 'underline' : 'none',
      }}
      onClick={() => {
        onClickAction && onClickAction();
      }}
    >
      {title}
    </p>
  );
};
