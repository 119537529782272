import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function BonusPoints({ iconView }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconView.width}
      height={iconView.height}
      viewBox="0 0 52 36.296"
    >
      <G data-name="Bonus Points" fill="#fff">
        <Path
          data-name="Path 2212"
          d="M1329.906 2708.763a.352.352 0 00-.355-.35l-.508.007-.006-.508a.353.353 0 00-.706.009l.007.508-.508.006a.353.353 0 00.01.706l.507-.006.007.507a.353.353 0 00.706-.009l-.007-.507.508-.007a.351.351 0 00.345-.356z"
          transform="translate(-1291.728 -2647.424) translate(-15.617 -26.269)"
        />
        <Path
          data-name="Path 2213"
          d="M1370.606 2652.763a.411.411 0 00.427-.4l.019-.593.592.02a.412.412 0 00.027-.823l-.593-.02.019-.593a.412.412 0 00-.823-.027l-.019.593-.593-.02a.412.412 0 00-.026.823l.592.019-.019.592a.41.41 0 00.397.429z"
          transform="translate(-1291.728 -2647.424) translate(-33.856 -1.097)"
        />
        <Path
          data-name="Path 2214"
          d="M1295.212 2649.217l.593.019-.019.593a.412.412 0 00.823.027l.019-.592.593.019a.412.412 0 00.027-.823l-.593-.019.019-.593a.412.412 0 00-.823-.027l-.019.593-.593-.019a.412.412 0 00-.027.823z"
          transform="translate(-1291.728 -2647.424) translate(-1.349)"
        />
        <Path
          data-name="Path 2215"
          d="M1304.93 2679.056a.412.412 0 00-.536-.224l-.548.225-.225-.549a.412.412 0 00-.762.313l.225.548-.548.225a.412.412 0 00.313.762l.549-.225.225.549a.412.412 0 00.762-.312l-.225-.549.548-.225a.413.413 0 00.222-.538z"
          transform="translate(-1291.728 -2647.424) translate(-4.609 -13.467)"
        />
        <Path
          data-name="Path 2216"
          d="M1292.291 2704.716h.5a.563.563 0 000-1.126h-.5a.563.563 0 000 1.126z"
          transform="translate(-1291.728 -2647.424) translate(0 -24.532)"
        />
        <Path
          data-name="Path 2217"
          d="M1380.332 2704.788a.563.563 0 000-1.126h-2.525a.563.563 0 000 1.126z"
          transform="translate(-1291.728 -2647.424) translate(-37.352 -24.563)"
        />
        <Path
          data-name="Path 2218"
          d="M1336.29 2663.325v-3.367a2.026 2.026 0 00-2.028-2.02h-7.018a7.408 7.408 0 10-13.286 0h-7.018a2.026 2.026 0 00-2.028 2.02v3.367a2.019 2.019 0 001.735 1.99v5.653h-6.679a1.781 1.781 0 00-1.779 1.779v6.971a1.752 1.752 0 00.131.66h-.772a.563.563 0 000 1.126l19.689.043h.013c3.954 0 17.651-.01 21.606-.012a.563.563 0 000-1.126h-1.106a6.311 6.311 0 00-3.2-11.529v-3.565a2.02 2.02 0 001.74-1.99zm-1.126-3.367v3.367a.9.9 0 01-.9.894h-9.746v-5.153h9.746a.9.9 0 01.899.892zm-1.741 8.958a6.317 6.317 0 00-2.778 11.489l-6.128-.005v-15.054h8.906zm-34.107 7.506h13.572v3.3a.653.653 0 01-.652.652h-12.267a.653.653 0 01-.653-.652zm0-2.317h13.572v1.19h-13.572zm18.5-15.04h5.577v5.153h-5.577zm5.577 6.281v15.054h-5.577v-15.049zm.961-13.776a3.188 3.188 0 013.183 3.182 3.188 3.188 0 01-3.183 3.186h-3.186v-3.186a3.188 3.188 0 013.182-3.182zm-10.681 3.182a3.189 3.189 0 013.182-3.182 3.188 3.188 0 013.186 3.182v3.186h-3.186a3.188 3.188 0 01-3.186-3.186zm-7.631 8.573v-3.367a.9.9 0 01.9-.893h9.746v5.153h-9.746a.9.9 0 01-.904-.893zm1.735 2.021h8.913v15.048h-2.807a1.761 1.761 0 00.134-.673v-6.971a1.781 1.781 0 00-1.779-1.779h-4.462zm-7.806 6.75h12.267a.653.653 0 01.652.652v.231h-13.572v-.231a.653.653 0 01.65-.648zm39.43 3.088a5.2 5.2 0 01-5.193 5.193 5.11 5.11 0 01-1.56-.239 5.193 5.193 0 011.383-10.143h.177a5.2 5.2 0 015.192 5.189z"
          transform="translate(-1291.728 -2647.424) translate(-2.296 -1.319)"
        />
        <Path
          data-name="Path 2219"
          d="M1343.819 2658.6a2.26 2.26 0 10-2.262-2.259 2.261 2.261 0 002.262 2.259zm0-3.394a1.134 1.134 0 11-1.135 1.135 1.135 1.135 0 011.135-1.131z"
          transform="translate(-1291.728 -2647.424) translate(-21.765 -2.909)"
        />
        <Path
          data-name="Path 2220"
          d="M1332.77 2656.346a2.26 2.26 0 10-2.262 2.259 2.265 2.265 0 002.262-2.259zm-2.262 1.132a1.134 1.134 0 111.136-1.132 1.133 1.133 0 01-1.136 1.132z"
          transform="translate(-1291.728 -2647.424) translate(-15.952 -2.909)"
        />
        <Path
          data-name="Path 2221"
          d="M1359.791 2688.881l-.522 1.613a.054.054 0 01-.051.037h-1.7a.616.616 0 00-.362 1.115l1.377 1a.053.053 0 01.019.059l-.525 1.61a.616.616 0 00.948.69l1.374-1a.054.054 0 01.063 0l1.374 1a.616.616 0 00.948-.69l-.525-1.61a.052.052 0 01.019-.059l1.376-1a.616.616 0 00-.362-1.115h-1.742c0-.012-.007-.024-.011-.037l-.521-1.614a.616.616 0 00-1.173 0zm.636.174l.522 1.614.017.063a.418.418 0 00.424.362h1.848a.048.048 0 01.05.038.047.047 0 01-.019.059l-1.375 1a.615.615 0 00-.225.691l.525 1.609a.053.053 0 01-.081.06l-1.375-1a.615.615 0 00-.724 0l-1.374 1a.048.048 0 01-.062 0 .048.048 0 01-.019-.06l.525-1.609a.615.615 0 00-.224-.69l-1.376-1a.054.054 0 01.031-.1h1.7a.615.615 0 00.586-.426l.522-1.613c.009-.019.098-.019.104.001z"
          transform="translate(-1291.728 -2647.424) translate(-28.465 -17.922)"
        />
        <Path
          data-name="Path 2222"
          d="M1372.807 2667.292a4.409 4.409 0 104.41 4.409 4.414 4.414 0 00-4.41-4.409zm0 7.692a3.283 3.283 0 113.283-3.282 3.286 3.286 0 01-3.283 3.281z"
          transform="translate(-1291.728 -2647.424) translate(-33.488 -8.678)"
        />
        <Path
          data-name="Path 2223"
          d="M1376.229 2672.686h-.92l-.282-.873a.475.475 0 00-.451-.329.475.475 0 00-.452.329l-.282.873h-.92a.475.475 0 00-.279.859l.744.541-.284.869a.475.475 0 00.732.532l.742-.54.743.54a.475.475 0 00.731-.532l-.284-.869.745-.542a.475.475 0 00-.28-.859zm-.847.981a.475.475 0 00-.173.532l.219.673-.574-.417a.473.473 0 00-.559 0l-.575.418.22-.674a.474.474 0 00-.173-.531l-.577-.419h.714a.474.474 0 00.452-.329l.219-.675.219.675a.474.474 0 00.452.329h.714z"
          transform="translate(-1291.728 -2647.424) translate(-35.255 -10.509)"
        />
        <Path
          data-name="Path 2224"
          d="M1318.97 2699.831a1.39 1.39 0 00.556.129 1.425 1.425 0 100-2.85 1.389 1.389 0 00-.558.129 1.424 1.424 0 100 2.592zm1.418-1.3a.863.863 0 01-.862.862h-.014a1.4 1.4 0 000-1.72h.015a.863.863 0 01.861.862zm-2.006-.862a.863.863 0 11-.863.864.865.865 0 01.863-.861z"
          transform="translate(-1291.728 -2647.424) translate(-11.019 -21.702)"
        />
        <Path
          data-name="Path 2225"
          d="M1308.583 2699.749h1.431a.476.476 0 000-.951h-1.431a.476.476 0 000 .951z"
          transform="translate(-1291.728 -2647.424) translate(-7.154 -22.439)"
        />
        <Path
          data-name="Path 2226"
          d="M1304.345 2699.749h.951a.476.476 0 000-.951h-.951a.476.476 0 100 .951z"
          transform="translate(-1291.728 -2647.424) translate(-5.303 -22.439)"
        />
        <Path
          data-name="Path 2227"
          d="M1313.672 2699.749h.951a.476.476 0 000-.951h-.951a.476.476 0 000 .951z"
          transform="translate(-1291.728 -2647.424) translate(-9.377 -22.439)"
        />
      </G>
    </Svg>
  );
}

export default BonusPoints;
