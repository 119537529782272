import React, { useContext, useState, useMemo, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Icon from 'react-native-easy-icon';
import { ThemeContext } from '../contexts/ThemeContext';
import { colors } from '../colors/colors';
import PaginationDropDown from '../pagination-drop-down/pagination-drop-down';

/* eslint-disable-next-line */
export interface PaginationProps {
  startRecord: number;
  endRecord: number;
  totalRecords: number;
  pageNo: number;
  setPageNo?: (value: number) => void;
  lastPage: number;
  enable?: boolean;
  setPageSize?: (value: number) => void;
  pageSize?: number;
}

const perPage = [
  {
    id: '10',
    mainText: '10 / page',
  },
  {
    id: '15',
    mainText: '15 / page',
  },
  {
    id: '20',
    mainText: '20 / page',
  },
  {
    id: '25',
    mainText: '25 / page',
  },
  {
    id: '30',
    mainText: '30 / page',
  },
  {
    id: '35',
    mainText: '35 / page',
  },
  {
    id: '40',
    mainText: '40 / page',
  },
];

export const Pagination: React.FC<PaginationProps> = ({
  startRecord,
  endRecord,
  totalRecords,
  pageNo,
  setPageNo,
  lastPage,
  enable = false,
  setPageSize,
  pageSize,
}) => {
  const currentPerPage = useMemo(
    () => perPage.find((x) => x.id === pageSize?.toString()) ?? perPage[0],
    [pageSize]
  );
  const [value, setValue] = useState(currentPerPage);
  const onSelectPage = (id: string) => {
    const value = perPage.find((x) => x?.id === id);
    setPageSize && setPageSize(Number(id));
    value && setValue(value);
  };
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  useEffect(() => {
    setValue(currentPerPage);
  }, [currentPerPage]);

  return (
    <View
      style={enable ? [styles.container, styles.enable] : [styles.container]}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TouchableOpacity
          onPress={() => setPageNo && setPageNo(pageNo - 1)}
          disabled={pageNo === 1}
        >
          <Icon
            type="entypo"
            name="chevron-thin-left"
            color={
              pageNo === 1
                ? theme.enableEdit
                  ? theme.input.placeHolder
                  : colors.gray.primary
                : theme.enableEdit
                ? colors.white.color
                : colors.eerieBlack.teritiary
            }
            size={16}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => setPageNo && setPageNo(pageNo + 1)}
          disabled={pageNo === lastPage}
        >
          <Icon
            type="entypo"
            name="chevron-thin-right"
            color={
              pageNo === lastPage
                ? theme.enableEdit
                  ? theme.input.placeHolder
                  : colors.gray.primary
                : theme.enableEdit
                ? colors.white.color
                : colors.eerieBlack.teritiary
            }
            size={16}
          />
        </TouchableOpacity>
        <View style={styles.pageOfPages}>
          <Text
            style={[
              {
                color: theme.text.primary,
                fontFamily: theme.fontConfig.regular.fontFamily,
              },
            ]}
            nativeID="custom-header-pagination"
          >
            {totalRecords !== 0
              ? `${startRecord} - ${endRecord} of ${totalRecords}`
              : `0 - 0 of 0`}
          </Text>
        </View>
      </View>
      {enable && (
        <View
          style={{
            justifyContent: 'center',
            marginLeft: 'auto',
          }}
        >
          <PaginationDropDown
            optionList={perPage}
            onSelect={onSelectPage}
            initialValue={[value]}
            totalRecords={totalRecords}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 270,
  },
  enable: {
    justifyContent: 'space-around',
    borderRadius: 6,
  },
  text: {
    fontSize: 14,
  },
  pageOfPages: {
    minWidth: 125,
    justifyContent: 'center',
    textAlign: 'center',
  },
});

export default Pagination;
