import _ from 'lodash';
import moment from 'moment';

export const getCurrentFinancialYearDates = (format = '') => {
  let fiscalStart: string, fiscalEnd: string;
  if (moment().quarter() >= 2) {
    fiscalStart = moment()
      .month('April')
      .startOf('month')
      .format(!_.isEmpty(format) ? format : 'DD/MM/YYYY');
    fiscalEnd = moment()
      .add(1, 'year')
      .month('March')
      .endOf('month')
      .format(!_.isEmpty(format) ? format : 'DD/MM/YYYY');
  } else {
    fiscalStart = moment()
      .subtract(1, 'year')
      .month('April')
      .startOf('month')
      .format(!_.isEmpty(format) ? format : 'DD/MM/YYYY');
    fiscalEnd = moment()
      .month('March')
      .endOf('month')
      .format(!_.isEmpty(format) ? format : 'DD/MM/YYYY');
  }
  return {
    fiscalStart,
    fiscalEnd,
  };
};
