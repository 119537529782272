import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function FailedSummary({ color = '#a1a3b4', width = 181, height = 218 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 181.464 218.223"
    >
      <G data-name="Group 115744">
        <G
          data-name="Group 115693"
          transform="translate(-5897 330.783) translate(11 -9051.783)"
        >
          <Path
            data-name="Path 198265"
            d="M287.214 135.655H240v-22.263A21.392 21.392 0 01261.392 92h25.822z"
            transform="translate(5646 8647.994)"
          />
          <Path
            data-name="Path 198266"
            d="M337.681 92s15.624.981 21.181 16.468a28 28 0 011.6 9.462v101.594s-1.068 24.912 24.793 26.81h60.5V116.793a24.169 24.169 0 00-17.9-23.443 29.247 29.247 0 00-8.57-1.228z"
            transform="translate(5568.796 8647.994)"
            fill="#fff"
          />
          <Path
            data-name="Path 198267"
            d="M445.985 245.453h-60.832a28.27 28.27 0 01-14.945-5.049 23.4 23.4 0 01-7.408-9.247 31.3 31.3 0 01-2.727-12.835V116.739a27.624 27.624 0 00-1.577-9.357 23.992 23.992 0 00-12.516-13.866 25.674 25.674 0 00-8.393-2.4l.02-.619 81.6.123h.045a29.529 29.529 0 018.615 1.241c9.773 2.978 14.179 9.276 16.155 14.035a24.8 24.8 0 011.961 9.718zm-60.8-.619h60.178V115.6a24.657 24.657 0 00-1.924-9.49c-1.926-4.627-6.223-10.753-15.754-13.657a28.908 28.908 0 00-8.434-1.214h-.045l-78.047-.117a28.1 28.1 0 015.083 1.832 24.6 24.6 0 0112.833 14.216 28.242 28.242 0 011.614 9.567v101.597a30.638 30.638 0 002.68 12.585c3.887 8.453 11.226 13.135 21.815 13.915"
            transform="translate(5568.56 8649.185)"
          />
          <Path
            data-name="Path 198268"
            d="M618.91 655.675v19.732a20.127 20.127 0 01-4.482 12.787 15.111 15.111 0 01-8.607 5.442H661.7a12.52 12.52 0 001.744-.12c3.734-.525 15.756-3.244 18.945-16.658a22.491 22.491 0 00.583-5.2v-15.98z"
            transform="translate(5355.705 8200.692)"
            fill="#fff"
          />
          <Path
            data-name="Path 198269"
            d="M650.244 692.754h-58.856l2.916-.613a14.726 14.726 0 008.432-5.336 19.851 19.851 0 004.411-12.59v-20.041h64.678v16.289a22.857 22.857 0 01-.591 5.274c-3.239 13.625-15.517 16.375-19.2 16.893a12.9 12.9 0 01-1.787.123m-53.7-.619h53.7a12.267 12.267 0 001.7-.117c3.588-.5 15.539-3.18 18.687-16.423a22.23 22.23 0 00.574-5.131v-15.67h-63.441v19.422a20.47 20.47 0 01-4.552 12.983 15.842 15.842 0 01-6.674 4.936"
            transform="translate(5367.158 8201.883)"
          />
          <Path
            data-name="Path 198289"
            d="M776.585 17.491A17.476 17.476 0 11759.125 0a17.476 17.476 0 0117.46 17.491"
            transform="translate(5247.931 8721)"
            fill="#e85b49"
          />
          <Path
            data-name="Path 198304"
            d="M1067.694 986.1l1.232 5.531 1.092 4.9h6.212l-.669-6.01-.5-4.519z"
            transform="translate(4989.187 7938.564)"
            fill="#fff"
          />
          <Path
            data-name="Path 198305"
            d="M1065.829 984.594l8.028-.113 1.24 11.152h-6.806zm7.475.515l-6.7.094 2.188 9.812h5.618z"
            transform="translate(4990.667 7939.77)"
          />
          <Path
            data-name="Path 198306"
            d="M1042.742 1026.217c-.543.566-2.757 2.431-6.422-.112a.1.1 0 00-.109-.007l-10.106 5.4a.515.515 0 00.243.969h16.488a1.185 1.185 0 001.156-1.446l-1.074-4.755a.1.1 0 00-.177-.05"
            transform="translate(5022.406 7906.754)"
          />
          <Path
            data-name="Path 198307"
            d="M973.695 986.1l1.115 6.084.8 4.342h6.212l-.44-6.107-.321-4.419z"
            transform="translate(5063.78 7938.564)"
            fill="#fff"
          />
          <Path
            data-name="Path 198308"
            d="M971.9 984.593l8.025-.112.8 11.152h-6.8zm7.449.515l-6.708.094 1.8 9.812h5.622z"
            transform="translate(5065.206 7939.77)"
          />
          <Path
            data-name="Path 198309"
            d="M1067.694 986.1l1.232 5.531 6.635-1.115-.5-4.519z"
            transform="translate(4989.187 7938.564)"
          />
          <Path
            data-name="Path 198310"
            d="M973.695 986.1l1.115 6.084 6.569-1.765-.318-4.419z"
            transform="translate(5063.78 7938.564)"
          />
          <Path
            data-name="Path 198311"
            d="M947.742 1026.217c-.543.566-2.757 2.431-6.422-.112a.1.1 0 00-.109-.007l-10.105 5.4a.515.515 0 00.243.969h16.488a1.185 1.185 0 001.156-1.446l-1.074-4.755a.1.1 0 00-.177-.05"
            transform="translate(5097.792 7906.754)"
          />
          <Path
            data-name="Path 198312"
            d="M1024.172 234.366s-.6 2.18-1.122 3.621a1.5 1.5 0 01-1.963.918l.012-3.736z"
            transform="translate(5026.172 8535.021)"
          />
          <Path
            data-name="Path 198313"
            d="M968.687 201.6v.016c-.1.915-.19 1.666-.277 2.318a47.013 47.013 0 01-1.472 6.761l10.767 1.041-1.053-15.307c.275-.357 4.414-4.478.974-5.473 0 0 1.142-7.909-5.04-7.593s-8.469 4.249-8.469 4.249-3.7 15.695 4.571 13.987"
            transform="translate(5072.035 8575.498)"
            fill="#fff"
          />
          <Path
            data-name="Path 198314"
            d="M965.337 209.779l.286-1.1a41.55 41.55 0 001.27-5.975c.07-.527.145-1.143.232-1.917a3.962 3.962 0 01-3.609-1.05c-3.233-3.324-1.005-12.976-.909-13.385l.011-.045.023-.04c.1-.166 2.446-4.079 8.721-4.4a4.674 4.674 0 013.893 1.408c1.788 1.961 1.6 5.317 1.5 6.279a1.862 1.862 0 011.265 1.209c.448 1.564-1.528 3.769-2.178 4.494l-.081.09 1.069 15.545zm6.056-27.3c-5.609.289-7.943 3.669-8.194 4.059-.168.747-2.126 9.795.762 12.763a3.5 3.5 0 003.456.8l.372-.077v.436c-.107.966-.195 1.7-.277 2.32a42.012 42.012 0 01-1.285 6.05l-.1.4 10.041.971-1.036-15.059.072-.093c.028-.036.092-.109.183-.21 1.591-1.774 2.279-3.09 2.044-3.91-.116-.4-.473-.688-1.093-.867l-.259-.075.039-.267c.006-.04.551-3.989-1.313-6.033a3.958 3.958 0 00-3.069-1.215q-.165 0-.335.009"
            transform="translate(5073.244 8576.69)"
          />
          <Path
            data-name="Path 198315"
            d="M969.708 168.929s-3.679-6.846-12.134-.388c-.749.572-1.49.473-3.144 1.245a2.044 2.044 0 00-.288 3.54c1.96 1.322 4.369 2.07 9.312-.035-2.176 2.049-5.729 1.958-5.729 1.958 7.047.7 8.482-2.136 8.574-2.186a7.075 7.075 0 001.36 5.7 2.238 2.238 0 01.71-1.222 1.664 1.664 0 012.616.532s3.865-7.239-1.277-9.143"
            transform="translate(5080.004 8589.455)"
          />
          <Path
            data-name="Path 198316"
            d="M972.749 216.2l-2.892-.3a2.217 2.217 0 11-4.4.348l-1.224-.127a2.219 2.219 0 11.041-.4l1.224.127a2.216 2.216 0 014.245-.364l3.048.315zm-8.887-.319a1.815 1.815 0 10-1.992 1.619 1.817 1.817 0 001.992-1.619m5.616.58a1.815 1.815 0 10-1.992 1.619 1.817 1.817 0 001.992-1.619"
            transform="translate(5074.775 8551.593)"
          />
          <Path
            data-name="Path 198317"
            d="M900.375 546.813l.91 7.695.745 6.313 1.87 15.819 9.274 58.949h13.007c0-.37-1.8-20.652-3.072-36.658-1.024-12.905-1.633-42.092-1.633-42.092l.048-2.589.246-13.665.01-.58-2.172.692-9.4 2.991z"
            transform="translate(5121.962 8292.485)"
            fill="#fff"
          />
          <Path
            data-name="Path 198317 - Outline"
            d="M920.471 537.249l-.02 1.155-.251 13.666-.047 2.581c.006.281.159 7.519.432 16.2.254 8.089.667 19.109 1.192 25.777l.007.089c.819 10.308 1.867 22.5 2.493 29.784.439 5.106.581 6.769.581 6.906v.413h-13.766l-9.331-59.32-1.87-15.818-.745-6.313-.95-8.033 10.16-3.233zm.5 59.62l-.007-.089c-.234-2.949-.465-7-.688-12.036-.178-4.028-.351-8.695-.514-13.87-.277-8.81-.431-16.138-.433-16.211v-.016l.048-2.589.246-13.67-11.019 3.509-9.51 3.027.87 7.356.745 6.313 1.87 15.811 9.218 58.592h12.21c-.075-.939-.282-3.339-.547-6.423-.623-7.268-1.667-19.412-2.485-29.705z"
            transform="translate(5123.692 8294.669)"
          />
          <Path
            data-name="Path 198318"
            d="M944.885 540l.21 1.2.436 2.486 1.631 9.3.054.306 1.057 6.026 2.288 14.749c.095.543 1.517 5.634 1.662 6.167l14.805 55.357h13.422l-10.894-57.807-.064-24.651v-.033l-.01-3.784-.836-9.316z"
            transform="translate(5086.642 8292.485)"
            fill="#fff"
          />
          <Path
            data-name="Path 198319"
            d="M950.5 579.123l-.2-.729c-.686-2.477-1.4-5.064-1.468-5.471l-2.286-14.743-3.446-19.68h24.41l.859 9.6.076 28.467 10.955 58.141h-14.03zm17.326-2.474l-.08-28.511-.807-9.018h-23.104l3.325 18.959 2.287 14.743c.074.425 1.041 3.916 1.453 5.407.1.367.175.631.2.734l14.743 55.127h12.81z"
            transform="translate(5088.061 8293.676)"
          />
          <Path
            data-name="Path 198320"
            d="M889.378 348.318l-.041.242c.182.037.363.074.545.109 6.351 1.237 10.8-4.011 18.207-2.04a15.645 15.645 0 012.1.727c9.7 4.129 16.516-1.239 16.516-1.239l-.84-10.292-.248-4.08-.225-3.722-.114-1.854-.235-3.894-.066-1.1-.3-4.957.17-6.231 5.1.619 1.666-11.735a6.4 6.4 0 00-3.38-6.181l-10.942-5.362c-7.441 4.885-9.223-.5-9.223-.5l-10.511 2.306a6.411 6.411 0 00-4.455 3.45l-5.593 13.434 7.907 1.629-1.27 8.543-.593 3.995-1.158 7.806-1.817 13.17-.617 3.689-.57 3.4z"
            transform="translate(5132.176 8493.388)"
            fill="#e85b49"
          />
          <Path
            data-name="Path 198321"
            d="M908.413 346.253a15.405 15.405 0 00-2.058-.712c-3.878-1.032-6.951-.033-9.923.933-2.691.875-5.234 1.7-8.263 1.111a48.547 48.547 0 01-.524-.105l-.319-.065 1.288-7.683 1.817-13.173 2.977-20.054-7.988-1.646 5.745-13.8a6.676 6.676 0 014.667-3.616l10.511-2.306.271-.059.089.263a3.723 3.723 0 002.177 2.126c1.678.579 3.892-.022 6.581-1.788l.148-.1 11.11 5.444a6.709 6.709 0 013.541 6.5l-1.709 12.036-5.059-.615-.16 5.877 1.186 19.587.854 10.457-.13.1a16.417 16.417 0 01-9.416 2.853 18.811 18.811 0 01-7.413-1.573m15.488-11.8l-1.187-19.607v-.014l.179-6.584 5.132.624 1.621-11.416a6.082 6.082 0 00-3.207-5.878l-10.785-5.27c-2.8 1.8-5.148 2.4-6.986 1.755a4.278 4.278 0 01-2.446-2.252l-10.26 2.251a6.056 6.056 0 00-4.232 3.266l-5.449 13.08 7.827 1.612-3.063 20.631-1.818 13.179-1.187 7.086v.016l.249.049c2.872.559 5.34-.243 7.953-1.092 3.058-.994 6.219-2.022 10.274-.943a16.006 16.006 0 012.141.741 16.7 16.7 0 0016.072-1.1z"
            transform="translate(5133.829 8494.775)"
          />
          <Path
            data-name="Path 198322"
            d="M897.974 401.213l18.375-2.816 1.385-.213 3.592-.551-1.047-5.31-5.651.072h-.037l-12.683.161 1.367-8.1.171-1.016-8.463-1.44-.118 2.112-.754 13.506a3.365 3.365 0 003.863 3.594"
            transform="translate(5126.941 8417.866)"
            fill="#fff"
          />
          <Path
            data-name="Path 198323"
            d="M893.771 399.179a3.649 3.649 0 01-1.16-2.975l.89-15.965 9.111 1.556-1.528 9.05 18.257-.232 1.159 5.88-23.671 3.629a3.715 3.715 0 01-.563.043 3.651 3.651 0 01-2.5-.985m26-3.2l-.934-4.74-18.484.235 1.552-9.175-7.818-1.335-.852 15.279a3.055 3.055 0 003.507 3.264z"
            transform="translate(5128.133 8419.264)"
          />
          <Path
            data-name="Path 198324"
            d="M993.354 432.072l1.757 6 1.385-.213 3.592-.551-1.046-5.308-5.651.072z"
            transform="translate(5048.179 8378.188)"
          />
          <Path
            data-name="Path 198325"
            d="M911.832 311.4l6.638 4.23a1.477 1.477 0 00.794.232h2.872s7.992 26.614 14.245 28.72c4.2 1.414 9.61-9.235 11.617-13.574.506-1.092.795-1.784.795-1.784l-8.7-1.571-.277.518-3.043 5.677-8.861-15.715s-1.662-2.769-1.858-3.1a27.35 27.35 0 00-1.858-2.89 4.841 4.841 0 00-4.129-1.445c-.258.027-.429.692-.206.826l1.445.619s-4.356-.114-7.02-1.445a2.158 2.158 0 00-2.449.076.372.372 0 00-.01.624"
            transform="translate(5113.001 8474.712)"
            fill="#fff"
          />
          <Path
            data-name="Path 198326"
            d="M935.093 343.682c-6.1-2.053-13.539-25.957-14.376-28.7h-2.642a1.784 1.784 0 01-.961-.28l-6.642-4.233a.674.674 0 01-.3-.57.685.685 0 01.32-.574 2.46 2.46 0 012.752-.091 16.472 16.472 0 005.172 1.284.741.741 0 01-.121-.676c.064-.287.245-.606.559-.639.3-.032 2.948-.257 4.4 1.56a23.749 23.749 0 011.675 2.577l.207.348 1.862 3.1 8.584 15.225 3.16-5.9 9.3 1.68-.149.356c0 .007-.3.71-.8 1.794-2.473 5.348-7.056 13.884-11.1 13.884a2.777 2.777 0 01-.892-.146m3.982-16.878l-3.48 6.494-9.133-16.2L924.6 314l-.208-.35a23.289 23.289 0 00-1.626-2.5 4.54 4.54 0 00-3.81-1.335.561.561 0 00-.078.274l2.846 1.22-1.615-.042c-.181 0-4.456-.131-7.15-1.478a1.844 1.844 0 00-2.144.061.065.065 0 00-.031.056.054.054 0 00.024.047l6.635 4.228a1.167 1.167 0 00.628.183h3.1l.066.22c.079.265 8.016 26.484 14.048 28.516 4.044 1.362 9.623-9.92 11.237-13.411.287-.619.5-1.111.638-1.418zm-20.161-16.989"
            transform="translate(5114.192 8475.906)"
          />
          <Path
            data-name="Path 198327"
            d="M1032.294 404.343a1.134 1.134 0 01.742-1.062c-.243-.739.742-1.062.742-1.062.823-.816 3.774-.968 4.836-.227s-.661 1.309-.661 1.309c.341 1.341-1.2 1.2-1.2 1.2s.649 1.12-1.032 1.239c.12.132.1.57-.413.826-.918.459-3.1-1.032-3.1-1.032-.728-.594.085-1.192.085-1.192"
            transform="translate(5017.586 8402.382)"
            fill="#fff"
          />
          <Path
            data-name="Path 198328"
            d="M1030.844 404.614l-.021-.016a.984.984 0 01-.416-.8 1.172 1.172 0 01.394-.784 1.394 1.394 0 01.7-1.089 1.032 1.032 0 01.433-.875 1.956 1.956 0 01.49-.281c.99-.879 4.028-1.016 5.179-.212a.868.868 0 01.471.841c-.068.464-.613.769-.959.919a1.105 1.105 0 01-.237.871 1.4 1.4 0 01-.894.43.98.98 0 01-.1.611 1.3 1.3 0 01-.992.6c0 .025-.006.051-.011.076a1.109 1.109 0 01-.63.759 1.258 1.258 0 01-.566.119 6.413 6.413 0 01-2.844-1.173m3.027-.313l.635-.045c.445-.031.74-.142.832-.312a.577.577 0 00-.046-.463l-.3-.513.591.049a1.128 1.128 0 00.818-.229.648.648 0 00.057-.589l-.071-.28.275-.09c.349-.115.771-.366.793-.517 0-.032-.035-.119-.213-.243-.932-.651-3.712-.53-4.441.193l-.052.051-.068.023c-.028.01-.688.238-.546.672l.092.29-.287.1a.81.81 0 00-.531.77v.156l-.126.092a.679.679 0 00-.261.4.4.4 0 00.179.293c.844.575 2.254 1.262 2.772 1 .271-.135.307-.307.3-.363z"
            transform="translate(5018.776 8403.56)"
          />
          <Path
            data-name="Path 198329"
            d="M1048.035 394.628l8.178 2.837c.506-1.092.795-1.784.795-1.784l-8.7-1.571z"
            transform="translate(5004.788 8408.256)"
          />
          <Path
            data-name="Path 198330"
            d="M897.8 384.112l8.411.349.171-1.016-8.46-1.445z"
            transform="translate(5124.002 8417.866)"
          />
          <Path
            data-name="Path 198331"
            d="M986.748 262.392c3.437-1.418 4.763-5.24 4.808-5.372a8.413 8.413 0 01-5.151 2.847c-.039.351.428.3.505.639a6.347 6.347 0 01-.162 1.886"
            transform="translate(5053.695 8517.043)"
          />
          <Path
            data-name="Path 198332"
            d="M973.375 252h3.1s-2.065 2.477-3.1 0"
            transform="translate(5064.034 8521.027)"
          />
          <Path
            data-name="Path 198333"
            d="M1043.2 407.039a1.132 1.132 0 00.82-.28.972.972 0 00.278-.653 1.238 1.238 0 00.982-.519 1.058 1.058 0 00.126-.729 1.374 1.374 0 00.977-.516 1.208 1.208 0 00.2-.828c.6-.3.883-.635.875-1.01-.011-.569-.721-.869-.8-.9l-.23.575c.185.075.411.243.412.338 0 .073-.15.28-.729.537l-.257.114.089.267a.7.7 0 01-.064.549 1.128 1.128 0 01-.881.282h-.5l.224.448s.16.343.031.549c-.1.155-.377.241-.787.241h-.4l.1.387s.065.288-.078.423c-.074.07-.331.21-1.115-.022l-.175.594a3.254 3.254 0 00.9.152"
            transform="translate(5009.342 8402.309)"
          />
          <Path
            data-name="Rectangle 26645"
            transform="rotate(-74.055 8861.238 389.311)"
            d="M0 0H4.509V0.619H0z"
          />
          <Path
            data-name="Rectangle 26646"
            transform="rotate(-82.88 8003.083 986.563)"
            d="M0 0H5.704V0.619H0z"
          />
        </G>
        <Path
          data-name="Path 198678"
          d="M5955.784-226.388l3.755 4.016 8.52-8.033"
          fill="none"
          stroke="#00c17f"
          strokeLinecap="round"
          strokeWidth={2}
          transform="translate(-5897 330.783)"
        />
        <Path
          data-name="Path 198756"
          d="M5955.784-207.334l3.755 4.016 8.52-8.033"
          fill="none"
          stroke="#00c17f"
          strokeLinecap="round"
          strokeWidth={2}
          transform="translate(-5897 330.783)"
        />
        <Path
          data-name="Path 198686"
          d="M5977.9-227.577h17.833"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={1}
          transform="translate(-5897 330.783)"
        />
        <G data-name="Group 115703" fill="none" strokeLinecap="round">
          <Path
            data-name="Path 198674"
            d="M5954.518-279.176l3.755 4.016 8.52-8.033"
            stroke="#00c17f"
            strokeWidth={2}
            transform="translate(-5897 330.783) translate(0 -4)"
          />
          <Path
            data-name="Path 198679"
            d="M5977.9-281.975h17.833"
            stroke="#000"
            strokeWidth={1}
            transform="translate(-5897 330.783) translate(0 -4)"
          />
          <Path
            data-name="Path 198680"
            d="M5977.9-276.975h30.843"
            stroke="#000"
            strokeWidth={1}
            transform="translate(-5897 330.783) translate(0 -4)"
          />
        </G>
        <G data-name="Group 115704" fill="none" strokeLinecap="round">
          <G data-name="Group 115701" stroke="#e85b49">
            <Path
              data-name="Path 198675"
              d="M5955.056-234.175l8.668 8.332"
              strokeWidth={2}
              transform="translate(-5897 330.783) translate(0 -2) translate(1.266 -32)"
            />
            <Path
              data-name="Path 198676"
              d="M5963.261-234.61l-7.75 9.201"
              strokeWidth={2.00104}
              transform="translate(-5897 330.783) translate(0 -2) translate(1.266 -32)"
            />
          </G>
          <Path
            data-name="Path 198682"
            d="M5977.9-264.509h17.833"
            stroke="#000"
            strokeWidth={1}
            transform="translate(-5897 330.783) translate(0 -2)"
          />
          <Path
            data-name="Path 198681"
            d="M5977.9-259.509h30.843"
            stroke="#000"
            strokeWidth={1}
            transform="translate(-5897 330.783) translate(0 -2)"
          />
        </G>
        <G data-name="Group 115705" fill="none" strokeLinecap="round">
          <Path
            data-name="Path 198677"
            d="M5955.784-244.842l3.755 4.016 8.52-8.033"
            stroke="#00c17f"
            strokeWidth={2}
            transform="translate(-5897 330.783)"
          />
          <Path
            data-name="Path 198684"
            d="M5977.9-247.043h17.833"
            stroke="#000"
            strokeWidth={1}
            transform="translate(-5897 330.783)"
          />
          <Path
            data-name="Path 198683"
            d="M5977.9-242.043h30.843"
            stroke="#000"
            strokeWidth={1}
            transform="translate(-5897 330.783)"
          />
        </G>
        <Path
          data-name="Path 198685"
          d="M5977.9-222.577h30.843"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={1}
          transform="translate(-5897 330.783)"
        />
        <G
          data-name="Group 115706"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={1}
        >
          <Path
            data-name="Path 198688"
            d="M5977.9-212.111h17.833"
            transform="translate(-5897 330.783) translate(0 3.794)"
          />
          <Path
            data-name="Path 198687"
            d="M5977.9-207.111h30.843"
            transform="translate(-5897 330.783) translate(0 3.794)"
          />
        </G>
        <G
          data-name="Group 115733"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
        >
          <Path
            data-name="Path 198675"
            d="M5955.396-233.82l15.825 15.211"
            strokeWidth={3}
            transform="translate(-5897 330.783) rotate(4.01 7207.561 515.226)"
          />
          <Path
            data-name="Path 198676"
            d="M5970.109-234.856l-14.15 16.797"
            strokeWidth={3.0015600000000004}
            transform="translate(-5897 330.783) rotate(4.01 7207.561 515.226)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default FailedSummary;
