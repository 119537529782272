import React from 'react';
import { View, TouchableOpacity, StyleSheet, FlatList } from 'react-native';
import { colors } from '../colors/colors';
import _ from 'lodash';

/* eslint-disable-next-line */
export interface CustomAccordianListProps {
  activeItem: number[];
  setActiveItem: (val: number[]) => void;
  data: AccordionItem[];
  verticalSpacing?: number;
  horizontalSpacing?: number;
  multi?: boolean;
  borderless?: boolean;
}

export interface AccordionItem {
  title: JSX.Element;
  content: JSX.Element;
  disabled?: boolean;
  selected?: boolean;
}

export const CustomAccordianList: React.FC<CustomAccordianListProps> = ({
  activeItem,
  setActiveItem,
  data,
  verticalSpacing = 0,
  horizontalSpacing = 0,
  multi = false,
  borderless = false,
}) => {
  const toggleAccordion = (index: number) => {
    if (multi) {
      if (activeItem.includes(index)) {
        setActiveItem(activeItem.filter((x) => x !== index));
      } else {
        setActiveItem([...activeItem, index]);
      }
    } else {
      setActiveItem(activeItem.includes(index) ? [] : [index]);
    }
  };

  return (
    <View>
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <View
            key={index}
            style={[
              {
                marginVertical: verticalSpacing,
                marginHorizontal: horizontalSpacing,
              },
              !borderless && styles.item,
              !borderless && activeItem.includes(index)
                ? styles.activeItem
                : {
                    borderColor:
                      !_.isUndefined(item.selected) && item.selected
                        ? colors.steelBlue.color
                        : colors.chineseWhite.primary,
                  },
            ]}
          >
            <TouchableOpacity
              onPress={() => toggleAccordion(index)}
              disabled={item.disabled}
            >
              {item.title}
            </TouchableOpacity>
            {activeItem.includes(index) && item.content}
          </View>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    borderWidth: 1,
    borderRadius: 8,
  },
  activeItem: {
    borderColor: colors.grey.color,
    shadowColor: colors.black.color,
    shadowOpacity: 0.08,
    shadowRadius: 12,
    shadowOffset: { height: 4, width: 0 },
  },
});

export default CustomAccordianList;
