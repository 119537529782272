import React, { useContext } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';
import { TagStyle } from '../types';
import { titleTruncate } from '@zonofi/common';

/* eslint-disable-next-line */
export interface TagProps {
  label: string;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  style: TagStyle;
  letterSpacing?: boolean;
  variant?: 'small' | 'default';
  fontSize?: number;
  borderless?: boolean;
  truncate?: number;
  isBold?: boolean;
}

export const Tag: React.FC<TagProps> = ({
  label,
  iconLeft,
  iconRight,
  style,
  letterSpacing = false,
  variant = 'default',
  fontSize = 12,
  borderless = false,
  truncate,
  isBold = false
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const { text, ...tagStyles } = style ?? {};
  return (
    <View
      style={[
        tagStyles,
        styles.container,
        {
          paddingVertical: variant === 'small' ? (borderless ? 0 : 1) : 2,
          paddingHorizontal: variant === 'small' ? 4 : 8,
          borderWidth: borderless ? 0 : variant === 'small' ? 0.5 : 1,
        },
      ]}
    >
      {iconLeft}
      <Text
        style={[
          variant === 'small'
            ? theme.fontConfig.regular
            : isBold ? theme.fontConfig.bold : theme.fontConfig.semiBold,
          { color: text, fontSize: fontSize },
          letterSpacing && { letterSpacing: 1.2 },
          iconLeft && iconRight
            ? { marginHorizontal: 4 }
            : iconLeft
            ? { marginLeft: 4 }
            : iconRight
            ? { marginRight: 4 }
            : { marginHorizontal: 0 },
        ]}
      >
        {truncate ? titleTruncate(label, truncate) : label}
      </Text>
      {iconRight}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: 4,
    alignItems: 'center',
  },
});

export default Tag;
