// Generated by ts-to-zod
import { z } from 'zod';
import { MessageType } from './enum';
import { orderSchema } from './order-message';
import {
  messageTemplateSchema,
  noIntentTemplateSchema,
} from './template-schema';
import { invoiceSchema } from './invoice-message';
import { paymentSchema } from './payment-schema';
import { pngcmSchema } from './pngcm-schema';
import { invoiceListItemSchema } from './list-message-schema';
import { campaignSchema } from './campaign-schema';
import { interactiveSchema } from './interactive-template-schema';
import { priceListMessageSchema } from './price-list-message-schema';

const actionSchema = z.object({
  uuid: z.string(),
  actionTimetoken: z.union([z.string(), z.number()]),
});

export const messageTypeSchema = z.nativeEnum(MessageType);

const buyerDetailsSchema = z.object({
  id: z.string(),
  spaceName: z.string(),
});

export const userSchema = z.object({
  city: z.string().optional().nullable(),
  contentType: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  userType: z.string().optional().nullable(),
  mobile: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  id: z.string().optional(),
  phone: z.string().optional(),
  address: z.string().optional().nullable(),
  subtitle: z.string().optional().nullable(),
  profilePic: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  fName: z.string().optional(),
  lName: z.string().optional(),
  mbl: z.string().optional(),
});

export const orderMessageSchema = orderSchema.extend({
  userDetails: userSchema,
});

export const invoiceMessageSchema = invoiceSchema.extend({
  userDetails: userSchema,
  ownerDetails: userSchema,
});

export const paymentMessageSchema = paymentSchema.extend({
  userDetails: userSchema.optional(),
});

export const invoiceListMessageSchema = z.array(invoiceListItemSchema);

export const mediaSchema = z.object({
  _id: z.string(),
  name: z.string(),
  cntType: z.string(),
  size: z.number().optional(),
  path: z.string().optional(),
  cUrl: z.string().optional(),
  text: z.string().optional(),
});

export const textSchema = z.string();

export const msgReferenceSchema = z.object({
  channel: z.string(),
  messageId: z.string(),
});

const msgTemplateSchema = z
  .union([messageTemplateSchema, noIntentTemplateSchema])
  .optional();

const messagePayloadSchema = (
  dataSchema: z.ZodTypeAny,
  template: z.ZodTypeAny = msgTemplateSchema
) =>
  z.object({
    medium: z.string().optional(),
    _v: z.number().optional(),
    sId: z.string(),
    cAt: z.union([z.number(), z.string()]).optional(),
    source: z.string().optional(),
    chnType: z.string().optional(),
    text: textSchema.optional(),
    buyerDetails: buyerDetailsSchema.optional(),
    sellerDetails: buyerDetailsSchema.optional(),
    _id: z.string(),
    latestTransactionMessage: z.string().optional(),
    user: userSchema,
    updateType: z.string().optional(),
    cId: z.string().optional(),
    userDetails: userSchema.optional(),
    status: z.string().optional(),
    productNotFound: z.boolean().optional(),
    token: z.string().optional(),
    template: template,
    data: dataSchema.optional(),
    media: z.array(mediaSchema).optional(),
    fileUrl: z.string().optional(),
    rFor: z.string().optional(),
    isZoey: z.boolean().optional(),
    pn_gcm: pngcmSchema.optional(),
    sType: messageTypeSchema.optional(),
    inviteId: z.string().optional(),
    conversation: z.object({ id: z.string() }).optional(),
  });

export const messagePayload = z.union([
  messagePayloadSchema(orderMessageSchema.partial()).extend({
    type: z.literal(MessageType.ORDER),
  }),
  messagePayloadSchema(invoiceMessageSchema.partial()).extend({
    type: z.literal(MessageType.INVOICE),
  }),
  messagePayloadSchema(paymentMessageSchema.partial()).extend({
    type: z.literal(MessageType.PAYMENT),
  }),
  messagePayloadSchema(priceListMessageSchema).extend({
    type: z.literal(MessageType.PRICE_LIST),
  }),
  messagePayloadSchema(invoiceListMessageSchema).extend({
    type: z.literal(MessageType.INVOICE_LIST),
  }),
  messagePayloadSchema(campaignSchema).extend({
    type: z.literal(MessageType.CAMPAIGN),
  }),
  messagePayloadSchema(msgReferenceSchema).extend({
    type: z.union([
      z.literal(MessageType.FORWARD),
      z.literal(MessageType.ISSUE),
    ]),
  }),
  messagePayloadSchema(z.undefined(), interactiveSchema).extend({
    type: z.literal(MessageType.INTERACTIVE),
  }),
  messagePayloadSchema(z.undefined()).extend({
    type: messageTypeSchema,
  }),
]);

export const messageActionSchema = z.record(z.record(z.array(actionSchema)));

export const messageEnvelopeSchema = z.object({
  channel: z.string(),
  message: messagePayload,
  timetoken: z.union([z.string(), z.number()]),
  messageType: z.union([z.string(), z.number()]).optional(),
  publisher: z.string().optional(),
  uuid: z.string().optional(),
  actions: messageActionSchema,
});
