import React from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { globalStyles } from '../styles/global-style';
import { AddActionHeader } from './add-action-header';
import { Drawer } from '@mui/material';
interface AddActionModalProps {
  isOpen: boolean;
  onClose: () => void;
  headerText: string;
  subHeader: string | React.ReactNode;
  children: React.ReactNode;
}

export const AddActionModal: React.FC<AddActionModalProps> = ({
  isOpen,
  onClose,
  headerText,
  subHeader,
  children,
}) => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: { width: '70%', overflowX: 'hidden', zIndex: 999999 },
      }}
    >
      <div
        style={{
          ...globalStyles.displayFlex,
          ...globalStyles.flex1,
          ...globalStyles.flexColumn,
          ...globalStyles.p32,
          background: theme.primary,
          overflowY: 'hidden',
        }}
      >
        <AddActionHeader
          headerText={headerText}
          subHeader={subHeader}
          onClose={onClose}
        />
        {children}
      </div>
    </Drawer>
  );
};
