import React, { useContext } from 'react';
import Icon from 'react-native-easy-icon';
import { ThemeContext } from '../contexts/ThemeContext';
import { CustomSvg } from '../custom-svg/custom-svg';
import { SHARED_SVGS } from '../assets';
import { globalStyles } from '../styles/global-style';

interface AddActionHeaderProps {
  onClose: () => void;
  headerText?: string;
  subHeader?: string | React.ReactNode;
}

export const AddActionHeader: React.FC<AddActionHeaderProps> = ({
  onClose,
  headerText,
  subHeader,
}) => {
  const { theme } = useContext(ThemeContext);
  const goBack = () => {};
  return (
    <div
      style={{
        ...globalStyles.displayFlex,
        ...globalStyles.flexColumn,
        ...globalStyles.mB12,
      }}
    >
      <div
        style={{
          ...globalStyles.flex1,
          ...globalStyles.displayFlex,
          ...globalStyles.flexRowAlCenterJcSpaceBetn,
        }}
      >
        <div
          style={{
            ...globalStyles.displayFlex,
            gap: 8,
            alignItems: 'center',
          }}
        >
          {/* <div onClick={goBack}>
            <Icon
              name="chevron-back"
              type="ionicon"
              size={40}
              color={theme.text.primary}
            />
          </div> */}
          <div>
            <div
              style={{
                ...theme.fontConfig.bold,
                fontSize: 20,
                color: theme.text.primary,
              }}
            >
              {headerText}
            </div>
            <div
              style={{
                ...globalStyles.flex1,
                ...theme.fontConfig.regular,
                fontSize: 14,
                color: theme.text.tertiary,
              }}
            >
              {subHeader}
            </div>
          </div>
        </div>

        <div style={{ cursor: 'pointer' }} onClick={onClose}>
          <CustomSvg
            svg={SHARED_SVGS.CloseIcon}
            colors={{ fill: theme.closeIcon }}
          />
        </div>
      </div>
    </div>
  );
};
