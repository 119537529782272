import { colors } from '@zonofi/shared';
import {
  InvoiceList,
  ReviewInvoiceSummary,
  UserDetails,
} from '@zonofi/zono-money-whatsapp-stack';
import React, { useEffect, useState } from 'react';
import '../whatsapp-flow.scss';
import { getDaysDifference, useAppSelector } from '@zonofi/zono-money-helpers';
import {
  Invoice,
  getCustomerInvite,
  useGetAllInvoiceMutation,
} from '@zonofi/zono-money-store';
import { useSearchParams } from 'react-router-dom';
import { Dimensions } from 'react-native';

export const ReviewInvoicesScreen: React.FC = () => {
  const [searchParams] = useSearchParams();
  const docNumbers = searchParams.getAll('docNumbers');
  const { height } = Dimensions.get('window');

  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;
  const [fetch, response] = useGetAllInvoiceMutation();
  const [invoiceList, setInvoiceList] = useState<Invoice[]>([]);

  useEffect(() => {
    fetch({
      workspaceId: seller.workspaceId,
      inviteId,
      body: { docNumbers: docNumbers },
    });
  }, []);

  useEffect(() => {
    if (response.isSuccess && response.data) {
      const data = response.data?.invoices?.map((invoice) => ({
        ...invoice,
        dueInDays: getDaysDifference(
          new Date().toISOString(),
          invoice?.dueDate
        ),
      }));
      setInvoiceList(data);
    }
  }, [response]);

  return (
    <div className="screen" style={{ background: colors.cultured.color }}>
      <div className="container" style={{ height }}>
        <UserDetails variant="invoice" />
        <ReviewInvoiceSummary invoices={invoiceList || []} />
        <InvoiceList invoices={invoiceList || []} />
      </div>
    </div>
  );
};
