import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';
import { getConfigValue } from '@zonofi/common';
import { Environment } from '../types';
import { colors } from '../colors/colors';

/* eslint-disable-next-line */
export interface DrawerTabProps {
  focused: boolean;
  icon: JSX.Element;
  text: string;
  width?: number;
  marginLeft?: number;
}

export const DrawerTab: React.FC<DrawerTabProps> = ({
  focused,
  icon,
  text,
  width,
  marginLeft,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const isPharmConnect = getConfigValue('ENV') === Environment.PHARM_CONNECT;

  return (
    <View
      style={{
        // backgroundColor: colors.charlestonGreen.secondary,
        // backgroundColor: theme.drawer.tab,
        // borderRadius: 6,
        borderBottomWidth: 2,
        // borderBottomColor: focused
        //   ? theme.status.success
        //   : colors.transparent.color,
        borderBottomColor: focused
          ? isPharmConnect
            ? '#6BBE4D'
            : theme.secondary
          : colors.transparent.color,
        paddingVertical: 15,
        paddingHorizontal: 4,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: width ? width + 38 : 'auto',
        maxWidth: width ? width + 38 : 'auto',
        marginLeft,
      }}
    >
      {icon}
      <Text
        style={{
          fontFamily: theme.fontConfig.bold.fontFamily,
          fontSize: 14,
          color: focused
            ? isPharmConnect
              ? theme.status.success
              : theme.secondary
            : theme.text.primary,
          marginLeft: 5,
          minWidth: width ?? 'auto',
        }}
      >
        {text}
      </Text>
    </View>
  );
};

export default DrawerTab;
