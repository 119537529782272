import { fontConfig } from '../fonts/fonts';
import { colors } from '../colors/colors';
import React, { useContext } from 'react';
import { Avatar as RNPAvatar } from 'react-native-paper';
import { ThemeContext } from '../contexts/ThemeContext';
import { heightPercentageToDPBoundsViewPort as hvp } from '../helpers';
import { hash } from '@zonofi/common';

/* eslint-disable-next-line */
export interface AvatarProps {
  profileUrl?: string;
  title: string;
  size?: number;
  cfa?: boolean;
  emoji?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  profileUrl,
  title,
  size = 32,
  cfa,
  emoji,
}) => {
  const theme = useContext(ThemeContext).theme;
  const backGrounds = [
    colors.lightGoldenrodYellow.color,
    colors.paleLavenderPink.color,
    colors.alabaster.color,
    colors.roseQuartz.color,
    colors.powderBlue.color,
    colors.paleGreen.color,
    colors.periwinkle.color,
    colors.lightCyan.color,
    colors.lightSteelBlue.color,
    colors.antiqueWhite.color,
    colors.lightGoldenrodYellow.color,
  ];

  const textColors = [
    colors.metallicSunburst.color,
    colors.hollyHock.color,
    colors.darkOliveGreen.color,
    colors.maroon.color,
    colors.queenBlue.color,
    colors.palmLeaf.color,
    colors.navyBlue.color,
    colors.mediumTeal.color,
    colors.gunmetalGray.color,
    colors.milkChocolate.color,
  ];

  const bgColor = backGrounds[hash(title, backGrounds.length)];
  const index = backGrounds?.indexOf(bgColor);
  const textColor = textColors[index];

  return profileUrl ? (
    <RNPAvatar.Image
      source={{
        uri: profileUrl,
      }}
      size={hvp(size)}
      style={{ backgroundColor: emoji ? bgColor : 'transparent' }}
    />
  ) : (
    <RNPAvatar.Text
      label={cfa ? title?.slice(0, 2).toLocaleUpperCase() : title[0]}
      size={hvp(size)}
      labelStyle={{
        ...fontConfig.default.regular,
        fontFamily: theme.fontConfig.regular.fontFamily,
        color: textColor ?? colors.white.color,
      }}
      style={{ backgroundColor: bgColor }}
    />
  );
};
