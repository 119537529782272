export const displayDateTime = (
  timestamp: string,
  type?: 'short' | 'date' | 'time'
) => {
  const dateTime = new Date(parseInt(timestamp) / 10000);
  const dt = dateTime.toISOString();
  const date = new Date(dt);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  const isYesterday =
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear();

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
  };

  switch (type) {
    case 'time':
      return date.toLocaleString('en-US', timeOptions);
    case 'short':
      if (isToday) return date.toLocaleString('en-US', timeOptions);
      if (isYesterday) return 'Yesterday';
      return date.toLocaleString('en-US', { ...dateOptions, year: undefined });
    case 'date':
      if (isToday) return 'Today';
      if (isYesterday) return 'Yesterday';
      return date.toLocaleString('en-GB', dateOptions);
    default:
      if (isToday || isYesterday)
        return (
          (isToday ? 'Today at ' : 'Yesterday at ') +
          date.toLocaleString('en-US', timeOptions)
        );
      return `${date.toLocaleString(
        'en-US',
        dateOptions
      )} at ${date.toLocaleString('en-US', timeOptions)}`;
  }
};

export const timeDiffIs24Hrs = (timestamp: string) => {
  const dateTime = new Date(parseInt(timestamp) / 10000).getTime();
  const currentDate = new Date().getTime();
  const timeDifference = Math.abs(currentDate - dateTime);
  const millisecondsIn24Hours = 24 * 60 * 60 * 1000;
  const is24HoursDifference = timeDifference === millisecondsIn24Hours;
  return is24HoursDifference;
};

export const convertUTCToIST = (dateStr: string) =>  {
  const date = new Date(dateStr);
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istTime = new Date(date.getTime() + istOffset);
  const istDateStr = istTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  return istDateStr;
}
