import * as React from 'react';
import Svg, { G, Path, Text, TSpan } from 'react-native-svg';

function Help(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 14.817 14.817"
      {...props}
    >
      <G data-name="Group 116301" fill="#fff">
        <Path
          data-name="Path 941"
          d="M175.409 1758.174zm-7.409 1.235a7.409 7.409 0 117.409 7.409 7.409 7.409 0 01-7.409-7.409zm7.409-6.174a6.174 6.174 0 106.174 6.174 6.174 6.174 0 00-6.174-6.174z"
          transform="translate(8344.434 6008.949) translate(-8512.434 -7760.949)"
        />
        <Text
          data-name="?"
          transform="translate(8344.434 6008.949) translate(-8339.434 -5997.949)"
          fontSize={10}
          fontFamily="FiraSans-Regular, Fira Sans"
        >
          <TSpan x={0} y={0}>
            {'?'}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default Help;
