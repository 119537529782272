import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import './screen-stepper.scss';

interface ScreenStepperProps {
  steps: string[];
  activeStep: string;
}

export const ScreenStepper: React.FC<ScreenStepperProps> = ({
  steps,
  activeStep,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className="screen-stepper"
      style={{
        fontSize: 16,
        ...theme.fontConfig.semiBold,
      }}
    >
      {steps?.map((step, index) => {
        const getClasses = () => {
          if (activeStep === step) {
            return {
              circle: 'screen-stepper-step-circle-active',
              text: 'screen-stepper-step-text-active',
              divider: 'screen-stepper-step-divider-active',
            };
          }
          if (index < steps?.map((i) => i)?.indexOf(activeStep)) {
            return {
              circle: 'screen-stepper-step-circle-completed',
              text: 'screen-stepper-step-text-completed',
              divider: 'screen-stepper-step-divider-completed',
            };
          }
          return {
            circle: 'screen-stepper-step-circle-inactive',
            text: 'screen-stepper-step-text-inactive',
            divider: 'screen-stepper-step-divider-inactive',
          };
        };
        return (
          <>
            <div
              className={`screen-stepper-step-circle ${getClasses()?.circle}`}
            >
              <span style={{...theme.fontConfig.semiBold, fontSize:'12px', }}>{index + 1} </span>
            </div>
            <span
              className={`screen-stepper-step-text screen-stepper-step-text-1  ${
                getClasses()?.text
              }`}
            >
              {step}
            </span>
            {steps?.length !== index + 1 && (
              <div className={getClasses().divider} />
            )}
          </>
        );
      })}
    </div>
  );
};
