import React, { useContext, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';

/* eslint-disable-next-line */
export interface TabNavigatorProps {
  TabList: TabBarItem[];
  setSelectedTab?: (id: number) => void;
  separator?: JSX.Element;
  selectedTab?: number;
  hideTabHeader?: boolean;
}

export interface TabBarItem {
  tabOption: (focus: boolean) => JSX.Element;
  tabView: JSX.Element;
}

export const TabNavigator: React.FC<TabNavigatorProps> = ({
  TabList,
  setSelectedTab,
  separator,
  selectedTab,
  hideTabHeader = false,
}) => {
  const tabs = TabList.length === 0 ? 1 : TabList.length;
  const TAB_SIZE = 100 / tabs + 1;
  const [selectedId, setSelectedId] = useState(selectedTab ?? 0);
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <>
      {!hideTabHeader && (
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            backgroundColor: theme.drawer.header,
          }}
        >
          {TabList.map((item, index) => {
            return (
              <TouchableOpacity
                style={{
                  width: `${TAB_SIZE}%`,
                  paddingLeft: 20,
                  paddingRight: index === TabList.length - 1 ? 30 : 0,
                }}
                onPress={() => {
                  setSelectedId(index);
                  setSelectedTab && setSelectedTab(index);
                  const currentFormElement =
                    document.getElementById('drawerFormRoot');
                  currentFormElement?.scrollTo({ top: 0 });
                }}
              >
                {item.tabOption(index === selectedId)}
              </TouchableOpacity>
            );
          })}
        </View>
      )}
      {separator}
      {TabList[selectedId]?.tabView}
    </>
  );
};

export default TabNavigator;
