import { PaymentStatusCode } from '@zonofi/common';
import { PaymentModeCode } from '../types';

enum PaymentMode {
  OfflineCash = 'Cash',
  OfflineCheque = 'Cheque',
  Online = 'Online',
  offlineDD = 'Demand Draft',
  OfflineBankTransfer = 'Bank Transfer',
  Upi = 'UPI',
  Wallet = 'Wallet',
  NetBanking = 'Net Banking',
  creditCard = 'Credit Card',
  CreditNote = 'Credit Note',
}

export const getMapOfPaymentsModeCode = (code: string) => {
  switch (code) {
    case PaymentModeCode.OfflineCash:
      return PaymentMode.OfflineCash;
    case PaymentModeCode.OfflineCheque:
      return PaymentMode.OfflineCheque;
    case PaymentModeCode.Online:
      return PaymentMode.Online;
    case PaymentModeCode.NetBanking:
      return PaymentMode.NetBanking;
    case PaymentModeCode.OfflineBankTransfer:
      return PaymentMode.OfflineBankTransfer;
    case PaymentModeCode.UPI:
      return PaymentMode.Upi;
    case PaymentModeCode.Wallet:
      return PaymentMode.Wallet;
    case PaymentModeCode.offlineDD:
      return PaymentMode.offlineDD;
    case PaymentModeCode.creditCard:
      return PaymentMode.creditCard;
    case PaymentModeCode.CreditNote:
      return PaymentMode.CreditNote;
    default:
      return null;
  }
};


export const getLedgerDocType = (type: string) => {
  switch (type) {
    case 'INV':
      return 'Purchase Invoice';
    case 'PYMNT':
      return 'Payment Transaction';
    case 'CN':
      return 'Credit Note';
    case 'DN':
      return 'Debit Note';
    case 'COB':
      return 'Credit Opening Balance';
    case 'DOB':
      return 'Debit Opening Balance';
    default:
      return '';
  }
};



export const getPaymentStatusName = (status: string) => {
  switch (status) {
    case PaymentStatusCode.PAID:
      return 'PAID';
    case PaymentStatusCode.Settled:
      return 'SETTLED';
    case PaymentStatusCode.CONFIRMED:
      return 'CONFIRMED';
    default:
      return '';
  }
};
