export const loadFacebookSDK = () => {
  // Check if the SDK script is already loaded
  if (document.getElementById('facebook-jssdk')) {
    return;
  }

  // Create a script element
  const script = document.createElement('script');
  script.id = 'facebook-jssdk';
  script.async = true;
  script.defer = true;
  script.crossOrigin = 'anonymous';
  script.src = 'https://connect.facebook.net/en_US/sdk.js';

  // Insert the script into the document
  document.body.appendChild(script);

  // Initialize the SDK after the script is loaded
  script.onload = function () {
    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      FB.init({
        appId: '344418548727963',
        xfbml: true,
        version: 'v19.0',
      });
    };
  };
};
