import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';

export const useAppNavigation = () => {
  const navigation = Platform.OS === 'web' ? useNavigate() : useNavigation();

  const appNavigation = (args: any) => {
    //@ts-ignore
    Platform.OS === 'web' ? navigation(args) : navigation.navigate(args);
  };

  return {
    appNavigation,
  };
};
