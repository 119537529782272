import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Info(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.446"
      height="24.446"
      viewBox="0 0 24.446 24.446"
    >
      <Path
        id="Path_210726"
        data-name="Path 210726"
        d="M180.223,1762.186a1.019,1.019,0,0,1,1.019,1.019v6.112a1.019,1.019,0,0,1-2.037,0V1763.2A1.019,1.019,0,0,1,180.223,1762.186Zm0-4.074a1.019,1.019,0,0,1,1.019,1.019v1.019a1.019,1.019,0,0,1-2.037,0v-1.019A1.019,1.019,0,0,1,180.223,1758.111ZM168,1764.223a12.223,12.223,0,1,1,12.223,12.223A12.224,12.224,0,0,1,168,1764.223Zm12.223-10.186a10.186,10.186,0,1,0,10.186,10.186A10.186,10.186,0,0,0,180.223,1754.037Z"
        transform="translate(-168 -1752)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </Svg>
  );
}

export default Info;
