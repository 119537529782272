export const CFA_ROUTES = {
  // HOME
  HOME_PAGE: '/admin/home',
  // REPORTS
  REPORTS_PAGE: { path: '/admin/reports', permissions: '' },

  LEDGERS_LIST: '/catalog/ledgers',

  INVOICES_LIST: 'admin/invoices',
};

export const CFA_COMMERCE_APP_ROUTES = {
  // COMMERCE APP
  LEDGERS_LIST: '/admin/ledgers/customers/:id',
  LEDGERS_CUSTOMERS_LIST: '/admin/ledgers/customers',
  LEDGERS_PAYMENT_DETAILS:
    '/admin/ledgers/customers/:id/paymentDetails/:paymentId',
  PRODUCTS_LIST: '/admin/catalog/products',
  SCHEMES_LIST: '/admin/catalog/schemes',
  ORDERS_LIST: '/admin/orders',
  RETURNS_LIST: '/admin/returns',
  REPORTS_LIST: '/admin/reports',
  MANUAL_RETURN: '/admin/create-return/manual',
  UPLOAD_RETURN: '/admin/returns/create-return/upload',
  ORDERS_VIEW_DETAILS: '/admin/orders/:id',
  TRACK_PO_VIEW_DETAILS: '/admin/orders/poFiles/:id',
  MANUAL_ORDER_SELECT_CUSTOMER: '/admin/orders/place-order/manual',
  MANUAL_RETURN_SELECT_CUSTOMER: '/admin/returns/create-return/manual',
  CUSTOMERS_MANUAL_ORDER: '/admin/place-order/customers/manual',
  CUSTOMERS_UPLOAD_ORDER: '/admin/place-order/customers/upload',
  UPLOAD_ORDER: '/admin/orders/place-order/upload',
  UPLOAD_ORDER_SELECT_CUSTOMER: '/admin/orders/place-order/select-customer',
  UPLOAD_RETURN_SELECT_CUSTOMER: '/admin/returns/create-return/select-customer',
  CUSTOMERS_LIST: '/admin/customers',
  DIVISIONS_LIST: '/admin/divisions',
  PRICE_LIST: '/admin/priceList',
  CAMPAIGN_LIST: '/admin/campaigns',
  CAMPAIGN_DETAILS: '/admin/campaign/details',
  PRICE_LIST_UPLOAD: '/admin/priceList/upload',
  EDIT_PRICE_LIST: '/admin/priceList/edit',
  CFA_LIST: '/admin/cfa',
  RETURNS_VIEW_DETAILS: '/admin/returns/:id',
  INVOICES_LIST: '/admin/invoices',
  INVOICE_VIEW_DETAILS: '/admin/invoices/details',
  PAYMENTS_LIST: '/admin/payments',
  PAYMENTS_VIEW_DETAILS: '/admin/payments/details/:id',
  CUSTOMER_BULK_FILE_UPLOAD: '/admin/customer/bulk/upload',
  PRODUCTS_BULK_FILE_UPLOAD: '/admin/product/bulk/upload',
  DIVISIONS_BULK_FILE_UPLOAD: '/admin/divisions/bulk/upload',
  HEAD_DIVISIONS_BULK_FILE_UPLOAD: '/admin/head/divisions/bulk/upload',
  SCHEMES_BULK_FILE_UPLOAD: '/admin/schemes/bulk/upload',
  CFA_BULK_FILE_UPLOAD: '/admin/cfa/bulk/upload',
  LEDGER_BULK_FILE_UPLOAD: '/admin/ledger/bulk/upload',
};

export const CFA_COMMERCE_APP_ROUTES_SETTINGS = {
  //SETTINGS
  CFA_SETTINGS: { path: '/admin/cfa', permissions: ['cfa_read'] },
  DIVISION_SETTINGS: '/admin/divisions',
  CUSTOMER_SETTINGS: '/admin/customer-config',
  WORKSPACE_SETTINGS: '/admins/workspace-config',
  DIVISION_CONFIGURATION_SETTINGS: '/admin/division-config',
  CUSTOMER_GROUP_SETTINGS: '/admins/customer-group-config',
};

export const CFA_COMMERCE_ROUTES_VARIABLES = {
  ORDERS_VIEW_DETAILS: (id: any) => `/admin/orders/${id}`,
  RETURNS_VIEW_DETAILS: (id: number) => `/admin/returns/${id}`,
  INVOICE_VIEW_DETAILS: (id: string, inviteId: string) =>
    `/admin/invoices/details?id=${id}&inviteId=${inviteId}`,
  TRACK_PO_VIEW_DETAILS: (id: string) => `/admin/orders/poFiles/${id}`,
  PAYMENT_VIEW_DETAILS: (id: string) => `/admin/payments/details/${id}`,
};
