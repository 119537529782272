import { ShimmerPlaceHolder } from '../shimmer-place-holder/shimmer-place-holder';
import { fontConfig } from '../fonts/fonts';
import { ShimmerTextRefObject } from '../types/interfaces';
import React, { forwardRef, Ref } from 'react';
import { colors } from '../colors/colors';

import {
  Text,
  StyleProp,
  TextStyle,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';

/* eslint-disable-next-line */
export interface ShimmerTextProps {
  textStyle?: StyleProp<TextStyle>;
  shimmerColors?: string[];
  text: string;
  width?: number;
  ellipsizeMode?: 'head' | 'tail' | 'middle' | 'clip' | undefined;
  numberOfLines?: number;
  shimmerStyle?: StyleProp<ViewStyle>;
}

export const ShimmerText = forwardRef(
  (props: ShimmerTextProps, ref: Ref<ShimmerTextRefObject>) => {
    const {
      textStyle = styles.text,
      text,
      width,
      ellipsizeMode,
      numberOfLines,
      shimmerStyle,
      shimmerColors,
    } = props;
    const [isVisible, setIsVisible] = React.useState(false);

    React.useImperativeHandle(ref, () => ({ show, hide }));

    const show = () => {
      setIsVisible(true);
    };
    const hide = () => {
      setIsVisible(false);
    };
    return (
      <ShimmerPlaceHolder
        width={width}
        shimmerStyle={[styles.shimmer, shimmerStyle]}
        visible={isVisible}
        shimmerColors={shimmerColors}
      >
        <Text
          ellipsizeMode={ellipsizeMode}
          numberOfLines={numberOfLines}
          style={textStyle}
        >
          {text}
        </Text>
      </ShimmerPlaceHolder>
    );
  }
);

const styles = StyleSheet.create({
  text: {
    ...fontConfig.default.circularStdBold,
    fontSize: 22,
    color: colors.white.color,
  },
  shimmer: { marginVertical: hp(0.5), borderRadius: 7 },
});
