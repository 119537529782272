import { colors } from '@zonofi/shared';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import {
  InvoiceLinesList,
  UserDetails,
  InvoiceSummaryCard,
} from '@zonofi/zono-money-whatsapp-stack';
import {
  Invoice,
  getCustomerInvite,
  useDownloadOrderInvoiceMutation,
  useLazyGetDocumentsQuery,
  useLazyGetInvoiceDetailsQuery,
} from '@zonofi/zono-money-store';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../whatsapp-flow.scss';
import { Dimensions, StyleSheet } from 'react-native';
import ViewInvoiceScreenLayout from './view-invoice-screen-layout';

export const ViewInvoiceScreen: React.FC = () => {
  const { invoiceId } = useParams();

  const { height } = Dimensions.get('window');

  const [fetch, response] = useLazyGetInvoiceDetailsQuery();
  const [fetchMedia, mediaResponse] = useLazyGetDocumentsQuery();
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;
  const [invoiceData, setInvoiceData] = useState<Invoice>();
  const [invoiceMedia, setInvoiceMedia] = useState<
    { url: string; name: string; type: string }[]
  >([]);
  const [downloadInvoice, downloadInvoiceResponse] =
    useDownloadOrderInvoiceMutation();

  useEffect(() => {
    fetch({
      invoiceId,
      workspaceId: seller?.workspaceId,
      customerId: inviteId,
    });
    fetchMedia({
      workspaceId: seller?.workspaceId,
      documentType: 'invoice',
      entityId: invoiceId,
      subType: 'invoice_copy',
    });
  }, [invoiceId]);

  useEffect(() => {
    if (response?.isSuccess && response?.data) {
      setInvoiceData(response?.data);
    }
    if (mediaResponse?.isSuccess && mediaResponse?.data) {
      const data = mediaResponse?.data?.map(({ url }) => {
        const fileName = url.substring(
          url.lastIndexOf('/') + 1,
          url.indexOf('?')
        );
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
        return { url, name: fileName, type: fileExtension };
      });
      setInvoiceMedia(data);
    }
  }, [response]);

  const fetchDownload = () => {
    window.open(mediaResponse?.data?.[0]?.url);
    // const downLoadOptions = {
    //   invoiceIds: [invoiceData?.id],
    //   downloadType: 'IL',
    //   downloadFormat: 'text/csv',
    //   includePayment: true,
    //   startDate: '',
    //   endDate: '',
    // };

    // downloadInvoice({
    //   workspaceId: seller?.workspaceId,
    //   body: {
    //     ...downLoadOptions,
    //     downloadType: 'AIOCD',
    //     includeHeaders: true,
    //   },
    // });
  };

  useEffect(() => {
    if (downloadInvoiceResponse?.isSuccess && downloadInvoiceResponse?.data) {
      const blob = new Blob([downloadInvoiceResponse?.data], {
        type: `text/csv`,
      });
      const filename = `${invoiceData?.docNumber}.csv`;
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    }
  }, [downloadInvoiceResponse.data]);

  return (
    // <div className="screen" style={{ background: colors.cultured.color }}>
    //   <div className="container" style={{ height }}>
    //     <UserDetails variant="invoice" />
    //     <InvoiceSummaryCard invoiceData={invoiceData} media={invoiceMedia} />
    //     {invoiceData?.lines?.length !== 0 && (
    //       <InvoiceLinesList lines={invoiceData?.lines || []} />
    //     )}
    //   </div>
    //   {/* <Button
    //     variant={'plain'}
    //     text={'Pay ₹18,000'}
    //     plainColor={theme.secondary}
    //     style={styles.button}
    //     textStyle={[
    //       theme.fontConfig.bold,
    //       styles.buttonText,
    //       {
    //         color: colors.white.color,
    //       },
    //     ]}
    //     onPress={onConfirm}
    //   /> */}
    // </div>
    <ViewInvoiceScreenLayout
      fetchDownload={fetchDownload}
      invoiceData={invoiceData}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    paddingVertical: 18,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  buttonText: { fontSize: 16, textAlign: 'center' },
});
