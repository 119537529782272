import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { invoiceApi } from './invoice-api';
import {
  CustomerRoutesResponse,
  CustomersEntity,
  InvoiceRootState,
  PaymentDetails,
  SelectedUPIApp,
  UnpaidInvoiceItem,
} from './invoice-model';

export const INVOICE_FEATURE_KEY = 'invoiceData';

export interface InvoiceState {
  paymentDetails: PaymentDetails;
  customerGroup: CustomerRoutesResponse;
  customerInfo: CustomersEntity | undefined;
  unpaidInvoiceItems: UnpaidInvoiceItem[];
  filterText: string;
}

export const initialInvoiceState: InvoiceState = {
  paymentDetails: {
    amountToPay: 0,
    canPay: true,
    selectedUPIOption: SelectedUPIApp.OTHERS,
  },
  customerGroup: {
    customerGroupId: '',
    customerGroupName: '',
  },
  customerInfo: undefined,
  unpaidInvoiceItems: [],
  filterText: '',
};

export const invoiceSlice = createSlice({
  name: INVOICE_FEATURE_KEY,
  initialState: initialInvoiceState,
  reducers: {
    updatePayableAmount(state, action: PayloadAction<PaymentDetails>) {
      state.paymentDetails = action.payload;
    },
    updateSelectedUPIApp(state, action: PayloadAction<SelectedUPIApp>) {
      state.paymentDetails.selectedUPIOption = action.payload;
    },
    selectCustomerGroup(state, action: PayloadAction<CustomerRoutesResponse>) {
      state.customerGroup = action.payload;
    },
    selectCustomerInfo(state, action: PayloadAction<CustomersEntity>) {
      state.customerInfo = action.payload;
    },
    selectUnpaidInvoiceItems(
      state,
      action: PayloadAction<UnpaidInvoiceItem[]>
    ) {
      state.unpaidInvoiceItems = action.payload;
    },
    resetRoutesData(state, action: PayloadAction<void>) {
      state.unpaidInvoiceItems = initialInvoiceState.unpaidInvoiceItems;
      state.customerGroup = initialInvoiceState.customerGroup;
      state.customerInfo = initialInvoiceState.customerInfo;
    },
    filterList(state, action: PayloadAction<string>) {
      state.filterText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      invoiceApi.endpoints.getInvoiceById.matchFulfilled,
      (state, action) => {
        state.paymentDetails = {
          amountToPay: Number(
            (Math.round(action?.payload?.invoiceAmount * 100) / 100).toFixed(2)
          ),
          canPay: action.payload.invoiceStatus !== 'PD',
        };
      }
    );
  },
});

export const invoiceReducer = invoiceSlice.reducer;

export const {
  updatePayableAmount,
  updateSelectedUPIApp,
  selectCustomerGroup,
  selectCustomerInfo,
  selectUnpaidInvoiceItems,
  resetRoutesData,
  filterList,
} = invoiceSlice.actions;

export const getPaymentState = (rootState: InvoiceRootState): InvoiceState =>
  rootState[INVOICE_FEATURE_KEY];

export const getPaymentDetails = createSelector(
  getPaymentState,
  (state) => state.paymentDetails
);

export const getSelectedCustomerGroup = createSelector(
  getPaymentState,
  (state) => state.customerGroup
);

export const getSelectedCustomerInfo = createSelector(
  getPaymentState,
  (state) => state.customerInfo
);

export const getUnpaidInvoiceItemArray = createSelector(
  getPaymentState,
  (state) => state.unpaidInvoiceItems
);

export const getFilterText = createSelector(
  getPaymentState,
  (state) => state.filterText
);
