import _ from 'lodash';
import moment from 'moment';

const divisors = {
  days: 86400000,
};

export const formatDueDays = (inputDate: string) => {
  const currentDate = new Date();
  if (inputDate) {
    let difference = new Date(inputDate).getTime() - currentDate.getTime();
    if (difference > 0) {
      const diff = _.ceil(difference / divisors.days);
      if (diff === 1) return 'Due Today';
      if (diff === 2) return 'Due Tomorrow';
      return `Due in ${diff - 1} days`;
    } else {
      difference = currentDate.getTime() - new Date(inputDate).getTime();
      const diff = _.ceil(difference / divisors.days);
      if (diff === 1) return 'Due from 1 day';
      return `Due since ${diff - 1} days`;
    }
  }
  return null;
};
export const formatDueDay = (inputDate: string) => {
  const currentDate = new Date();
  if (inputDate) {
    const difference = currentDate.getTime() - new Date(inputDate).getTime();
    if (difference > 0) {
      const diff = _.ceil(difference / divisors.days);
      return diff === 1 ? `Due By ${diff} day` : `Due By ${diff} days`;
    } else {
      return `Not Due`;
    }
  }
  return '';
};
export const getOverDueDays = (inputDate: string) => {
  const currentDate = new Date();
  if (inputDate) {
    const difference = currentDate.getTime() - new Date(inputDate).getTime();
    if (difference > 0) {
      const diff = _.ceil(difference / divisors.days);
      return diff === 1 ? `${diff} day` : `${diff} days`;
    } else {
      return `Not Due`;
    }
  }
  return null;
};

export const getDaysDifference = (date1?: string, date2?: string) => {
  if (date1 && date2) {
    const diffInMs = new Date(date1).getTime() - new Date(date2).getTime();
    if (diffInMs < 0) {
      const dateDiff = _.floor(Math.abs(diffInMs) / divisors.days);
      return dateDiff * -1;
    } else {
      const dateDiff = _.floor(diffInMs / divisors.days);
      return dateDiff;
    }
  }
  return null;
};

export const formatDate = (inputDate: string) => {
  if (inputDate) {
    const months: { [key: number]: string } = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec',
    };
    const d = new Date(inputDate);
    const year = d.getFullYear()?.toString()?.substring(2, 4);
    const date = d.getDate();
    const monthIndex = d.getMonth();
    const monthName = months[monthIndex];
    const formatted = `${date} ${monthName} ${year}`;
    return formatted.toString();
  } else return null;
};

export const formatTime = (date: string) => {
  const d = new Date(date);
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const hour = hours % 12;
  const time = hour ? hour : 12;
  const minute = minutes < 10 ? '0' + minutes : minutes;
  const formatted = `${time}:${minute} ${ampm}`;
  return formatted;
};

export const formatTimeToDigitalString = (remainingTime: number) => {
  const mins = Math.floor(remainingTime / 60);
  const secs = remainingTime % 60;
  const minutes = mins.toString().length === 1 ? '0' + mins : mins;
  const seconds = secs.toString().length === 1 ? '0' + secs : secs;
  return `${minutes}:${seconds}`;
};

export const getCurrentFinancialYearDates = () => {
  let thisFiscalStart: string, thisFiscalEnd: string;
  if (moment().quarter() >= 2) {
    thisFiscalStart = moment()
      .month('April')
      .startOf('month')
      .format('YYYY-MM-DD');
    thisFiscalEnd = moment()
      .add(1, 'year')
      .month('March')
      .endOf('month')
      .format('YYYY-MM-DD');
  } else {
    thisFiscalStart = moment()
      .subtract(1, 'year')
      .month('April')
      .startOf('month')
      .format('YYYY-MM-DD');
    thisFiscalEnd = moment().month('March').endOf('month').format('YYYY-MM-DD');
  }
  return {
    thisFiscalStart,
    thisFiscalEnd,
  };
};
