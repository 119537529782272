import React, { useContext } from 'react';
import { ThemeContext } from '@zonofi/shared';
import { SVGS, PNGS } from '@zonofi/zono-money-design-system';
import './opps.scss';
import Link from '@mui/material/Link';

export const Opps: React.FC = () => {
  const { theme } = useContext(ThemeContext);

  
  return (
    <div className="opps">
        <div className='head_sec'>
            <Link href="#"><img className="svg-icon" height={32} src={SVGS.ZotokNewLogo} /></Link>
        </div>
        <div className='opp_list' style={{ ...theme.fontConfig.regular }}>
            <div className='icon'><img className="svg-icon" height={32} src={SVGS.OppsIcon} /></div>
            <h2 style={{...theme.fontConfig.bold}}>Opps!<br />Mobile web isn't supported yet on your device</h2>
            <p style={{ ...theme.fontConfig.condensedSemiBold }}>Good things are coming — For the best experience, visit us on your desktop or tablet.</p>
            <p style={{ ...theme.fontConfig.condensedSemiBold }}>On the go? Download our mobile app for full functionality at your fingertips!</p>
            <p className='gpay'><Link href="#"><img className="svg-icon" height={47} src={PNGS.Gpay} /></Link></p>
        </div>
    </div>
  );
};
