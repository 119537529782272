import React, { useContext } from 'react';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { getCustomerInvite } from '@zonofi/zono-money-store';
import { SVGS } from '@zonofi/zono-money-design-system';
import './user-details.scss';
import { ThemeContext } from '@zonofi/shared';
import _ from 'lodash';

type ScreenType = 'order' | 'invoice';

/* eslint-disable-next-line */
export interface UserDetailsProps {
  variant: ScreenType;
}

export const UserDetails: React.FC<UserDetailsProps> = ({ variant }) => {
  const { theme } = useContext(ThemeContext);
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { buyer, seller } = inviteDetails;

  const heading: Record<ScreenType, string> = {
    order: 'ORDER DETAILS',
    invoice: 'YOU ARE PAYING TO',
  };

  return (
    <div className="user-details" style={{ background: theme.primary }}>
      <div
        className="buyer"
        style={{ ...theme.fontConfig.semiBold, color: theme.text.primary }}
      >
        <img src={SVGS.User} alt="" style={{ height: 16 }} />
        {`Welcome, ${buyer?.name || ''}`}
      </div>
      <div className="divider" />
      <div className="seller-info">
        <div className="heading" style={theme.fontConfig.semiBold}>
          {heading[variant]}
        </div>
        <div className="details">
          <div className="icon">
            <img src={SVGS.Shop} alt="" style={{ height: 18 }} />
          </div>
          <div className="seller">
            <div
              className="name"
              style={{ ...theme.fontConfig.medium, color: theme.text.primary }}
            >
              {seller?.shortName}
              <img src={SVGS.Verified} alt="" style={{ height: 16 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
