import { zonoApi } from '../api';
import {
  CampaignDetailsRequest,
  CampaignDetailsResponse,
  CampaignDetailsV2Request,
  CampaignDetailsV2Response,
  CampaignProductsRequest,
  CampaignProductsResponse,
  CampaignsRequest,
} from './campaign-model';

export const campaignApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query<any, CampaignsRequest>({
      query: (args) => {
        return {
          url: `hub/commerce-v2/campaigns/${args?.workspaceId}`,
          params: args?.params,
          method: 'GET',
        };
      },
    }),
    getCampaignDetails: builder.query<
      CampaignDetailsResponse,
      CampaignDetailsRequest
    >({
      query: (args) => {
        return {
          url: `hub/commerce-v2/campaigns/${args?.workspaceId}/${args?.campaignId}`,
          params: args?.params,
          method: 'GET',
        };
      },
    }),
    getCampaignDetailsV2: builder.query<
      CampaignDetailsV2Response,
      CampaignDetailsV2Request
    >({
      query: (args) => {
        return {
          url: `hub/commerce-v2/campaigns/details/customer/${args?.workspaceId}`,
          params: args?.params,
          method: 'GET',
        };
      },
    }),
    getCampaignProducts: builder.mutation<
      CampaignProductsResponse,
      CampaignProductsRequest
    >({
      query({ workspaceId, sellerWorkspaceId, ...body }) {
        return {
          url: `/hub/commerce-v2/campaigns/products/${workspaceId}`,
          method: 'POST',
          params: { sellerWorkspaceId },
          body: body,
        };
      },
    }),
  }),
});

export const {
  useLazyGetCampaignsQuery,
  useLazyGetCampaignDetailsQuery,
  useLazyGetCampaignDetailsV2Query,
  useGetCampaignProductsMutation,
} = campaignApi;
