import ZonoFiSmallLogo from './svgs/zonofi_small_logo.svg';
import ZonoFiLogo from './svgs/zono_login.svg';
import ZonoFieldCollection from './svgs/zono_filed_collection_login.svg';
import ZonoFieldCollectionOtpLogo from './svgs/zono_filed_collection_otp_.svg';
import Tick from './svgs/tick.svg';
import GoaTripWeb from './svgs/goatripweb.svg';
import CreditCard from './svgs/credit_card.svg';
import Internet from './svgs/internet.svg';
import OTPLock from './svgs/otp_lock.svg';
import UpiIcon from './svgs/upi-icon.svg';
import UserIcon from './svgs/user.svg';
import Group96 from './svgs/Group96.svg';
import GetStarted from './svgs/getStarted.svg';
import SplashScreenImage from './svgs/SplashscreenImage.svg';
import SplashScreenNewImage from './svgs/SplashscreenImage1.svg';
import WalletIcon from './svgs/wallet-icon.svg';
import BankAccountIcon from './svgs/bank-account.svg';
import UserProfileIcon from './svgs/user-profile-icon.svg';
import ZoTokLogo from './svgs/ZoTokLogo.svg';
import DiscountIcon from './svgs/discount_icon.svg';
import LogOutIcon from './svgs/power-off-logout-icon.svg';
import UserReceivingZCoins from './svgs/user-receiving-zcoins.svg';
import ZonoCoin from './svgs/ZonoCoin.svg';
import DebitedArrowTransaction from './svgs/debited_arrow_transaction.svg';
import CreditedArrowTransaction from './svgs/credited_arrow_transaction.svg';
import ErrorSVG from './svgs/error.svg';
import ErrorScreenImage from './svgs/error-image.svg';
import Share from './svgs/share.svg';
import Offer1 from './pngs/offer1.png';
import Offer2 from './pngs/offer2.png';
import Offer3 from './pngs/offer3.png';
import Offer4 from './pngs/offer4.png';
import Gpay from './pngs/Gpay_icon.png';
import UserLogin from './svgs/login_user.svg';
import ZCoinBackground from './svgs/Zcoins.svg';
import Promo from './svgs/promo.svg';
import OfferCard from './svgs/offer1.svg';
import PaytmLogo from './svgs/paytm.svg';
import PhonePeLogo from './svgs/phone_pe.svg';
import GooglePay from './svgs/google_pay.svg';
import BhimUpi from './svgs/bhim_upi.svg';
import CircularTick from './svgs/tick-circle.svg';
import QRCode from './svgs/qrcode.svg';
import SbiLogo from './svgs/sbilogo.svg';
import CheckIn from './svgs/check-in.svg';
import LogOut from './svgs/log-out.svg';
import ZonoSellerLogo from './svgs/zono_seller_logo.svg';
import Wallet from './svgs/wallet.svg';
import ZonoLogoLatest from './svgs/ZonoLogo.svg';
import ZonoLogoWithTitle from './svgs/ZonoLogoWithTitle.svg';
import ShareLedger from './svgs/share_ledger.svg';
import SharePayment from './svgs/payment-link.svg';
import { HomeIcon, ChannelIcon, ZonoMoneyIcon } from './icons';
import User from './svgs/user-icon.svg';
import Shop from './svgs/shop.svg';
import Verified from './svgs/verified.svg';
import EditIcon from './svgs/edit.svg';
import DoubleTick from './svgs/double_tick.svg';
import Plus from './svgs/plus.svg';
import Replace from './svgs/replace.svg';
import DownloadIcon from './svgs/download.svg';
import PlusIcon from './svgs/plus_icon.svg';
import PlusIconWhite from './svgs/plus_icon_white.svg';
import MinusIcon from './svgs/minus_icon.svg';
import MinusIconWhite from './svgs/minus_icon_white.svg';
import SearchIconOrder from './svgs/search_icon_order.svg';
import ErrorIcon from './svgs/icons8-error.svg';
import DiscountIconFindProduct from './svgs/discount_sticker_findproduct.svg';
import InvoiceFileIcon from './svgs/invoice_file_icon.svg';
import DownloadInvoiceIcon from './svgs/download_icon.svg';
import SearchIcon from './svgs/searchicon_order.svg';
import CartIconOrder from './svgs/cart_icon_order.svg';
import RightArrow from './svgs/right-arrow.svg';
import LeftArrowWhite from './svgs/left_arrow_white.svg';
import OrderSuccess from './svgs/ordersuccess_icon.svg';
import ZotokLogoGreen from './svgs/ZoTok_Logo_Green.svg';
import InfoIcon from './svgs/info_icon.svg';
import DeleteIcon from './svgs/delete_icon.svg';
import OppsIcon from './svgs/opps_icon.svg';
import ZotokNewLogo from './svgs/ZoTok_Logo.svg';
import DefaultProduct from './svgs/default-product.svg';
import XCircle from './svgs/x-circle.svg';
import { ReactComponent as XCircleComponent } from './svgs/x-circle.svg';
import SearchBlack from './svgs/search-black.svg';
import LeftArrowBlack from './svgs/left-arrow-black.svg';
import PlusPrimaryCyan from './svgs/plus_primary_cyan.svg';
import MinusPrimaryCyan from './svgs/minus_primary_cyan.svg';

export const SVGS = {
  ZonoFiSmallLogo: ZonoFiSmallLogo,
  ZonoFieldCollectionLogo: ZonoFieldCollection,
  ZonoFiLogo: ZonoFiLogo,
  ZonoFieldCollectionOtpLogo: ZonoFieldCollectionOtpLogo,
  Tick: Tick,
  GoaTripWeb: GoaTripWeb,
  CreditCard: CreditCard,
  Internet: Internet,
  OTPLock: OTPLock,
  UpiIcon: UpiIcon,
  UserIcon: UserIcon,
  Group96: Group96,
  GetStarted: GetStarted,
  SplashScreenImage: SplashScreenImage,
  SplashScreenNewImage: SplashScreenNewImage,
  WalletIcon: WalletIcon,
  BankAccountIcon: BankAccountIcon,
  UserProfileIcon: UserProfileIcon,
  ZoTokLogo: ZoTokLogo,
  DiscountIcon: DiscountIcon,
  LogOutIcon: LogOutIcon,
  UserReceivingZCoins: UserReceivingZCoins,
  ZonoCoin: ZonoCoin,
  CreditedArrowTransaction: CreditedArrowTransaction,
  DebitedArrowTransaction: DebitedArrowTransaction,
  ErrorSVG: ErrorSVG,
  ErrorScreenImage: ErrorScreenImage,
  Share: Share,
  UserLogin: UserLogin,
  Promo: Promo,
  ZCoinBackground: ZCoinBackground,
  OfferCard: OfferCard,
  PaytmLogo: PaytmLogo,
  BhimUpi: BhimUpi,
  GooglePay: GooglePay,
  PhonePeLogo: PhonePeLogo,
  CircularTick: CircularTick,
  QRCode: QRCode,
  SbiLogo: SbiLogo,
  CheckIn: CheckIn,
  LogOut: LogOut,
  ZonoSellerLogo: ZonoSellerLogo,
  Wallet: Wallet,
  ZonoLogoLatest: ZonoLogoLatest,
  ZonoLogoWithTitle: ZonoLogoWithTitle,
  ShareLedger: ShareLedger,
  SharePayment: SharePayment,
  User,
  Shop,
  Verified,
  EditIcon,
  DoubleTick,
  Plus,
  PlusIcon,
  PlusIconWhite,
  MinusIcon,
  MinusIconWhite,
  SearchIconOrder,
  Replace,
  DownloadIcon,
  ErrorIcon,
  DiscountIconFindProduct,
  InvoiceFileIcon,
  DownloadInvoiceIcon,
  SearchIcon,
  CartIconOrder,
  RightArrow,
  OrderSuccess,
  LeftArrowWhite,
  ZotokLogoGreen,
  InfoIcon,
  DeleteIcon,
  OppsIcon,
  ZotokNewLogo,
  DefaultProduct,
  XCircle,
  XCircleComponent,
  SearchBlack,
  LeftArrowBlack,
  PlusPrimaryCyan,
  MinusPrimaryCyan,
};

export const PNGS = {
  Offer1: Offer1,
  Offer2: Offer2,
  Offer3: Offer3,
  Offer4: Offer4,
  Gpay: Gpay,
};

export const ICONS = {
  Home: HomeIcon,
  Channels: ChannelIcon,
  ZonoMoney: ZonoMoneyIcon,
};
