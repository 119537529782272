import { Theme, ThemeContext, colors } from '@zonofi/shared';
import { getFormattedAmount } from '@zonofi/common';
import { formatDueDays } from '@zonofi/zono-money-helpers';
import { Invoice } from '@zonofi/zono-money-store';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';

interface InvoiceCardProps {
  invoice: Invoice;
}

enum StatusCodes {
  Pending = 'P',
  PartiallyPaid = 'PP',
  Paid = 'PD',
  Cancelled = 'C',
  OverDue = 'OD',
  UnPaid = 'UD',
}

const StatusTag: React.FC<{ status: string; theme: Theme }> = ({
  status,
  theme,
}) => {
  const color = useMemo(() => {
    switch (status) {
      case StatusCodes.UnPaid:
      case StatusCodes.Cancelled:
      case StatusCodes.OverDue:
        return '#EA0500';
      case StatusCodes.PartiallyPaid:
      case StatusCodes.Pending:
        return '#5B3EF4';
      case StatusCodes.Paid:
        return '#00B230';
      default:
        return '#1D1D22';
    }
  }, [status]);

  return (
    <div
      className="status-tag"
      style={{
        ...theme.fontConfig.bold,
        color: color,
        background: `${color}1A`,
      }}
    >
      {_.startCase(
        Object.entries(StatusCodes).find((x) => x[1] === status)?.[0] || ''
      )}
    </div>
  );
};

export const InvoiceCard: React.FC<InvoiceCardProps> = ({ invoice }) => {
  const { theme } = useContext(ThemeContext);

  const isOverdue =
    new Date().getTime() - new Date(invoice?.dueDate).getTime() > 0;

  return (
    <div className="invoice-card" style={{ background: theme.primary }}>
      <div className="column left">
        <div
          style={{
            ...theme.fontConfig.regular,
            color: colors.gray.primary,
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {`Invoice on ${moment(invoice?.invoiceDate).format(
            'D MMM YYYY, hh:mm A'
          )}`}
          <StatusTag status={invoice?.invoiceStatus} theme={theme} />
        </div>
        <div
          style={{
            ...theme.fontConfig.regular,
            color: theme.text.primary,
            fontSize: 14,
          }}
        >
          {invoice?.docNumber}
        </div>
        <div
          style={{
            ...theme.fontConfig.regular,
            color: colors.gray.primary,
            fontSize: 14,
          }}
        >
          {formatDueDays(invoice?.dueDate)}
        </div>
      </div>
      <div className="column right">
        <div
          style={{
            ...theme.fontConfig.regular,
            color: colors.gray.primary,
            fontSize: 12,
          }}
        >
          {isOverdue ? 'Overdue' : ''}
        </div>
        <div
          style={{
            ...theme.fontConfig.semiBold,
            color: isOverdue ? '#EA0500' : theme.text.primary,
            fontSize: 14,
          }}
        >
          {getFormattedAmount(invoice?.invoiceAmount - invoice?.paidAmount)}
        </div>
        <div
          style={{
            ...theme.fontConfig.regular,
            color: colors.gray.primary,
            fontSize: 14,
          }}
        >
          {invoice?.paidAmount !== 0
            ? `Total Due: ${getFormattedAmount(invoice?.invoiceAmount)}`
            : ''}
        </div>
      </div>
    </div>
  );
};
