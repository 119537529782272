import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function Thread({ color = '#a1a3b4', width = 19, height = 19 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
    >
      <Path
        id="Therads_Icon"
        data-name="Therads Icon"
        d="M700.455,801.8a.872.872,0,0,1,.864-.88h6.909a.88.88,0,0,1,0,1.76h-6.909A.872.872,0,0,1,700.455,801.8Zm0-3.52a.872.872,0,0,1,.864-.88h2.591a.88.88,0,0,1,0,1.76h-2.591A.872.872,0,0,1,700.455,798.28Zm3.455,7.92v.88a.872.872,0,0,0,.864.88h6.909a.853.853,0,0,1,.611.258l1.98,2.018V800.92a.872.872,0,0,0-.864-.88h-.864v3.52a2.616,2.616,0,0,1-2.591,2.64ZM697,795.64a2.616,2.616,0,0,1,2.591-2.64h10.364a2.616,2.616,0,0,1,2.591,2.64v2.64h.864A2.616,2.616,0,0,1,716,800.92v9.316a1.723,1.723,0,0,1-2.948,1.244l-1.727-1.76h-6.552a2.616,2.616,0,0,1-2.591-2.64v-.88h-.506l-1.727,1.76A1.723,1.723,0,0,1,697,806.716Zm2.591-.88a.872.872,0,0,0-.864.88v11.076l1.98-2.018a.853.853,0,0,1,.611-.258h8.636a.872.872,0,0,0,.864-.88v-7.92a.872.872,0,0,0-.864-.88Z"
        transform="translate(-697 -793)"
        fill={color}
        fill-rule="evenodd"
      />
    </Svg>
  );
}

export default Thread;
