import * as React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';

function ChannelIcon({ color = '#a1a3b4', width = 25.693, height = 16.943 }) {
  return (
    <Svg
      data-name="22 Connectivity"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25.693 16.943"
    >
      <Path
        data-name="Path 136"
        d="M4.767.392a1.342 1.342 0 00-2 .118 12.842 12.842 0 000 15.923 1.342 1.342 0 002 .118 1.324 1.324 0 00.119-1.755 10.155 10.155 0 010-12.65A1.324 1.324 0 004.767.392zm16.158 0a1.324 1.324 0 00-.119 1.754 10.155 10.155 0 010 12.65 1.325 1.325 0 00.119 1.755 1.343 1.343 0 002-.118 12.841 12.841 0 000-15.923 1.342 1.342 0 00-2-.118z"
        fill={color}
      />
      <Path
        data-name="Path 137"
        d="M8.214 3.839a1.347 1.347 0 00-2.08.23 8.021 8.021 0 000 8.805 1.347 1.347 0 002.08.23 1.342 1.342 0 00.16-1.693 5.353 5.353 0 010-5.88 1.342 1.342 0 00-.16-1.692zm9.266 0a1.342 1.342 0 00-.159 1.693 5.353 5.353 0 010 5.88 1.342 1.342 0 00.159 1.693 1.347 1.347 0 002.08-.23 8.019 8.019 0 000-8.805 1.347 1.347 0 00-2.083-.231z"
        fill={color}
      />
      <Circle
        data-name="Ellipse 11"
        cx={3.212}
        cy={3.212}
        r={3.212}
        transform="translate(9.635 5.26)"
        fill={color}
      />
    </Svg>
  );
}

export default ChannelIcon;
