import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { ThemeContext } from '@zonofi/shared';
import { SVGS } from '@zonofi/zono-money-design-system';
import './order-successfull.scss';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { getCustomerInvite } from '@zonofi/zono-money-store';
import { getConfigValue } from '@zonofi/common';
import { useSearchParams } from 'react-router-dom';

export const OrderSuccessfull: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const inviteDetails = useAppSelector(getCustomerInvite);
  const [params] = useSearchParams();
  const skuCount = Number(params?.get('skuCount'));
  const { seller } = inviteDetails;
  const successType = params?.get('successType');

  const onClickBackToWhatsapp = () => {
    if (seller?.workspaceId === 'c9bedd72-85d7-41c4-8c62-e33ffc926966') {
      window.location.href = getConfigValue('WAPP_REDIRECT_BJ_GROUP');
    } else {
      window.location.href = getConfigValue('WAPP_REDIRECT');
    }
  };

  return (
    <div
      className="order_successfull"
      style={{ background: '#fff', ...theme.fontConfig.regular }}
    >
      <div className="icon">
        <img className="svg-icon" height={120} src={SVGS.OrderSuccess} />
      </div>
      <div className="content">
        <h4 style={{ ...theme.fontConfig.condensedSemiBold }}>
          {successType === 'order'
            ? 'Order submitted successfully!'
            : 'Requirement sent successfully!'}
        </h4>
        {skuCount !== 0 && (
          <p>
            {successType === 'order'
              ? `Your order for ${skuCount} SKU's has been submitted to ${seller?.name}.`
              : `Your request for requirement of ${skuCount} SKU's has been sent to ${seller?.name}.`}
          </p>
        )}
      </div>
      <div className="btn_section">
        <Button
          disableRipple
          disableFocusRipple
          disableElevation
          sx={{ all: 'unset' }}
          className="backto_whatsapp"
          style={{ ...theme.fontConfig.condensedSemiBold }}
          onClick={onClickBackToWhatsapp}
        >
          Back to WhatsApp
        </Button>
      </div>
    </div>
  );
};
