import { Button } from '@mui/material';
import { getFormattedAmount } from '@zonofi/common';
import { ThemeContext } from '@zonofi/shared';
import { useAppSelector } from '@zonofi/zono-money-helpers';
import { Invoice, getCustomerInvite } from '@zonofi/zono-money-store';
import moment from 'moment';
import { SVGS } from '@zonofi/zono-money-design-system';
import React, { useContext } from 'react';
import InvoiceStatusTags from './invoice-status-tags';

type Props = {
  invoiceData: Invoice;
  fetchDownload: () => void;
};

const ViewInvoiceScreenLayout = (props: Props) => {
  const { theme } = useContext(ThemeContext);
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { buyer, seller } = inviteDetails;
  const { invoiceData } = props;
  const orderItems = [
    {
      title: 'Chilly Powder Premium 500 gm',
      buyingPrice: 143,
      qty: 10,
      totalAmount: 1430,
    },
    {
      title: 'Chilly Powder Premium 1000 gm',
      buyingPrice: 143,
      qty: 10,
      totalAmount: 2860,
    },
    {
      title: 'Sauce - Red Chilli Premium 200 gm',
      buyingPrice: 243,
      qty: 10,
      totalAmount: 2430,
    },
    {
      title: 'Sauce - Red Chilli Premium 50 gm',
      buyingPrice: 60,
      qty: 10,
      totalAmount: 600,
    },
  ];

  return (
    <div
      className="screen"
      style={{ background: '#F1F2F4', ...theme.fontConfig.regular }}
    >
      <div
        id="header_section"
        style={{
          position: 'fixed',
          top: 0,
          zIndex: 9,
          background: '#589981',
          width: '100%',
          height: '52px',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0px 14px',
          margin: '0px',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img height={18} src={SVGS.UserProfileIcon} />
          </div>
          <div
            style={{
              paddingLeft: '10px',
              fontSize: '16px',
              lineHeight: '20px',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Hello, {buyer?.name}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={SVGS.ZoTokLogo} />
        </div>
      </div>
      <div
        id="invoicedetails_main_section"
        style={{
          width: '100%',
          height: '100%',
          padding: '0px 14px',
          margin: '52px 0 52px',
          boxSizing: 'border-box',
        }}
      >
        <div
          id="orderdetails_top"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 0',
          }}
        >
          <div>
            <h3
              style={{
                padding: '0 0 0px',
                margin: 0,
                fontSize: '20px',
                lineHeight: '20px',
                color: '#091E42',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              Invoice details
            </h3>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontSize: '12px',
                color: '#758195',
              }}
            >
              Created on{' '}
              {moment(props?.invoiceData?.createdDate).format(
                'DD MMM YYYY, hh:mm A'
              )}
            </p>
          </div>
          <div>
            <p
              style={{
                background: '#D6EAED',
                borderRadius: '2px',
                boxShadow: 'none',
                border: 'none',
                padding: '6px 10px',
                margin: '0px',
                color: '#0067FF',
                fontSize: '14px',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              <InvoiceStatusTags invoiceStatus={invoiceData?.invoiceStatus} />
            </p>
          </div>
        </div>
        <div
          id="itemorder_section"
          style={{
            background: '#fff',
            borderRadius: '12px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '14px 16px',
              margin: 0,
              borderBottom: '1px solid #F1F2F4',
              boxSizing: 'border-box',
            }}
          >
            <h4
              style={{
                padding: '0px',
                margin: 0,
                fontSize: '18px',
                color: '#091E42',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              {invoiceData?.lines?.length} items in the invoice
            </h4>
            <Button
              disableRipple
              disableFocusRipple
              disableElevation
              sx={{
                all: 'unset',
                cursor: 'pointer',
                textAlign: 'right',
                width: 'auto',
                color: '#32A7E8',
                fontSize: '12px',
                ...theme.fontConfig.condensedSemiBold,
                background: 'none',
                boxShadow: 'none',
                border: 0,
                padding: 0,
                margin: 0,
              }}
              onClick={props?.fetchDownload}
            >
              Download
            </Button>
          </div>
          {/* <div
            id="congrats_order"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '12px',
              color: '#249F6C',
              background: '#DEF8ED',
              padding: '10px 15px',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            <p style={{ padding: 0, margin: 0 }}>
              <img
                style={{
                  margin: '0px 3px 0 0',
                  position: 'relative',
                  top: '2px',
                }}
                height={14}
                src={SVGS.DiscountIcon}
              />
              Congratulations!
            </p>
            <p style={{ padding: 0, margin: 0 }}>
              You've got ₹1,000 off by scheme savings!
            </p>
          </div> */}
          <div
            id="orderdetails_itemlist"
            style={{ padding: '0px 0 10px', margin: '0px' }}
          >
            {invoiceData?.lines.map((invoiceItem) => (
              <div
                key={invoiceItem?.productTitle}
                style={{
                  borderBottom: '1px solid #F1F2F4',
                  padding: '12px 16px',
                  margin: 0,
                }}
              >
                <h4
                  style={{
                    padding: '0 0 0px',
                    margin: 0,
                    fontSize: '14px',
                    color: '#091E42',
                    ...theme.fontConfig.condensedSemiBold,
                  }}
                >
                  {invoiceItem.productTitle}
                </h4>
                {/* <p
                  id="orderdetails_offer"
                  style={{
                    fontSize: '12px',
                    color: '#249F6C',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  5% Offer applied!
                  <span
                    style={{ color: '#758195', padding: '0 5px', margin: 0 }}
                  >
                    worth
                  </span>
                  ₹429
                </p> */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 0 0px',
                    margin: 0,
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: '12px',
                      fontWeight: 'normal',
                      ...theme.fontConfig.condensedSemiBold,
                    }}
                  >
                    <span
                      style={{ color: '#758195', ...theme.fontConfig.regular }}
                    >
                      Buying Price:{' '}
                    </span>
                    ₹{invoiceItem.listPrice}
                  </p>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: '12px',
                      fontWeight: 'normal',
                      ...theme.fontConfig.condensedSemiBold,
                    }}
                  >
                    <span
                      style={{ color: '#758195', ...theme.fontConfig.regular }}
                    >
                      Qty:{' '}
                    </span>
                    {invoiceItem.invoicedQuantity}
                  </p>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: '12px',
                      fontWeight: 'normal',
                      ...theme.fontConfig.condensedSemiBold,
                    }}
                  >
                    <span
                      style={{ color: '#758195', ...theme.fontConfig.regular }}
                    >
                      Amount:{' '}
                    </span>
                    ₹{invoiceItem.lineTotalWithTax}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div
          id="invoice_document"
          style={{
            background: '#fff',
            borderRadius: '12px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <h4
            style={{
              padding: '15px',
              margin: 0,
              fontSize: '16px',
              color: '#091E42',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Invoice document
          </h4>
          <div
            id="invdocdetails_list"
            style={{
              padding: '0px 0',
              margin: 0,
              fontSize: '12px',
              color: '#091E42',
            }}
          >
            <ul
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                borderBottom: '1px solid #F1F2F4',
                padding: '0px 15px 10px',
                margin: '0px 0 15px',
              }}
            >
              <li
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img height={28} src={SVGS.InvoiceFileIcon} />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <p style={{ padding: 0, margin: 0 }}>INV0003842.pdf</p>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      color: '#758195',
                      fontSize: '12px',
                    }}
                  >
                    2 pages{' '}
                    <span
                      style={{
                        background: '#999',
                        width: '3px',
                        height: '3px',
                        display: 'inline-block',
                        margin: '0 4px',
                      }}
                    ></span>{' '}
                    3 MG{' '}
                    <span
                      style={{
                        background: '#999',
                        width: '3px',
                        height: '3px',
                        display: 'inline-block',
                        margin: '0 4px',
                      }}
                    ></span>{' '}
                    PDF
                  </p>
                </div>
              </li>
              <li style={{ width: '20%', textAlign: 'right' }}>
                <img height={24} src={SVGS.DownloadInvoiceIcon} />
              </li>
            </ul>
            <ul
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: '0px 15px 15px',
                margin: '0px',
              }}
            >
              <li
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img height={28} src={SVGS.InvoiceFileIcon} />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <p style={{ padding: 0, margin: 0 }}>INV0003842.pdf</p>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      color: '#758195',
                      fontSize: '12px',
                    }}
                  >
                    2 pages{' '}
                    <span
                      style={{
                        background: '#999',
                        width: '3px',
                        height: '3px',
                        display: 'inline-block',
                        margin: '0 4px',
                      }}
                    ></span>{' '}
                    3 MG{' '}
                    <span
                      style={{
                        background: '#999',
                        width: '3px',
                        height: '3px',
                        display: 'inline-block',
                        margin: '0 4px',
                      }}
                    ></span>{' '}
                    PDF
                  </p>
                </div>
              </li>
              <li style={{ width: '20%', textAlign: 'right' }}>
                <img height={24} src={SVGS.DownloadInvoiceIcon} />
              </li>
            </ul>
          </div>
        </div> */}

        <div
          id="invoice_details"
          style={{
            background: '#fff',
            borderRadius: '12px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <h4
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '18px',
              color: '#091E42',
              borderBottom: '1px solid #F1F2F4',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Invoice details
          </h4>
          <div
            id="inviocedetails_list"
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '14px',
              color: '#091E42',
            }}
          >
            <ul
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: '0px 0 6px',
                margin: '0px',
              }}
            >
              <li style={{ width: '80px' }}>SKU Count</li>
              <li
                style={{
                  borderBottom: '1px dashed #acacac',
                  width: 'calc(100% - 170px)',
                  height: '1px',
                }}
              ></li>
              <li style={{ width: '90px', textAlign: 'right' }}>
                {invoiceData?.lines?.length}
              </li>
            </ul>
            <ul
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: '0px 0 6px',
                margin: '0px',
              }}
            >
              <li
                style={{
                  ...theme.fontConfig.condensedSemiBold,
                  width: '110px',
                }}
              >
                Invoice Amount
              </li>
              <li
                style={{
                  borderBottom: '1px dashed #acacac',
                  width: 'calc(100% - 200px)',
                  height: '1px',
                }}
              ></li>
              <li
                style={{
                  width: '90px',
                  textAlign: 'right',
                  ...theme.fontConfig.condensedSemiBold,
                }}
              >
                {getFormattedAmount(props?.invoiceData?.invoiceAmount)}
              </li>
            </ul>
            <ul
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: '0px 0 6px',
                color: '#2ABB7F',
                margin: '0px',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              <li style={{ width: '90px' }}>Amount Paid</li>
              <li
                style={{
                  borderBottom: '1px dashed #acacac',
                  width: 'calc(100% - 180px)',
                  height: '1px',
                }}
              ></li>
              <li style={{ width: '90px', textAlign: 'right' }}>
                {' '}
                {getFormattedAmount(props?.invoiceData?.paidAmount)}
              </li>
            </ul>
            <ul
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: '0px 0 6px',
                margin: '0px',
              }}
            >
              <li
                style={{
                  color: '#DA9100',
                  ...theme.fontConfig.condensedSemiBold,
                  width: '110px',
                }}
              >
                Balance Amount
              </li>
              <li
                style={{
                  borderBottom: '1px dashed #acacac',
                  width: 'calc(100% - 200px)',
                  height: '1px',
                }}
              ></li>
              <li
                style={{
                  width: '90px',
                  textAlign: 'right',
                  color: '#DA9100',
                  ...theme.fontConfig.condensedSemiBold,
                }}
              >
                {getFormattedAmount(
                  props?.invoiceData?.invoiceAmount - invoiceData?.paidAmount
                )}
              </li>
            </ul>
          </div>
        </div>

        <div
          id="invoice_summary"
          style={{
            background: '#fff',
            borderRadius: '12px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <h4
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '18px',
              color: '#091E42',
              borderBottom: '1px solid #F1F2F4',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Invoice summary
          </h4>
          <div
            id="invoicesummary_list"
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '12px',
              color: '#091E42',
            }}
          >
            <ul
              style={{
                listStyle: 'none',
                padding: '0px 0 10px',
                margin: '0px',
              }}
            >
              <li style={{ color: '#758195', padding: '0 0 4px' }}>Due Date</li>
              <li
                style={{
                  ...theme.fontConfig.condensedSemiBold,
                  fontSize: '14px',
                }}
              >
                {moment(props?.invoiceData?.dueDate).format('DD MMM YYYY')}
              </li>
            </ul>
            <ul
              style={{
                listStyle: 'none',
                padding: '0px 0 10px',
                margin: '0px',
              }}
            >
              <li style={{ color: '#758195', padding: '0 0 4px' }}>
                Invoice Number
              </li>
              <li
                style={{
                  ...theme.fontConfig.condensedSemiBold,
                  fontSize: '14px',
                }}
              >
                {invoiceData?.docNumber}
              </li>
            </ul>
            <ul
              style={{
                listStyle: 'none',
                padding: '0px 0 10px',
                margin: '0px',
              }}
            >
              <li style={{ color: '#758195', padding: '0 0 4px' }}>
                Payment Id
              </li>
              <li
                style={{
                  ...theme.fontConfig.condensedSemiBold,
                  fontSize: '14px',
                }}
              >
                {invoiceData?.payments[0]?.id || '-'}
              </li>
            </ul>
            {/* <ul
              style={{
                listStyle: 'none',
                padding: '0px 0 15px',
                margin: '0px',
              }}
            >
              <li style={{ color: '#758195', padding: '0 0 4px' }}>
                Order Invoiced
              </li>
              <li style={{ ...theme.fontConfig.condensedSemiBold }}>
                INV8384000
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      {/* <div
        id="bottom_invoice_section"
        style={{
          position: 'fixed',
          bottom: 0,
          zIndex: 9,
          width: '100%',
          height: '45px',
          padding: '0px',
          margin: '0px',
        }}
      >
        <button
          style={{
            color: '#fff',
            fontSize: '16px',
            ...theme.fontConfig.condensedSemiBold,
            textAlign: 'center',
            background: '#000',
            border: '0px',
            width: '100%',
            lineHeight: '45px',
          }}
        >
          Pay ₹1000
        </button>
      </div> */}
    </div>
  );
};

export default ViewInvoiceScreenLayout;
