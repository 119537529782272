export enum PaymentStatusCode {
  PAID = 'P',
  SENT = 'SNT',
  FAILED = 'F',
  CREATED = 'C',
  CONFIRMED = 'CNF',
  Cancelled = 'N',
  Settled = 'SS',
  Settlment_Initiated = 'SSI',
}

export enum DispatchNoteEnum {
  E_WAY_BILL = 'E Way Bill',
  LORRY_RECEIPT = 'Lorry Receipt',
  COURIER_RECEIPT = 'Courier Receipt',
  INVOICE = 'Invoice',
  DELIVERY_CHALLAN = 'Delivery Challan',
}

export enum VisitLocationStatus {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  UNAVAILABLE = 'unavailable',
}
