import { Theme, ThemeContext, colors } from '@zonofi/shared';
import moment from 'moment';
import React, { useContext } from 'react';
import './order-details.scss';
import { getFormattedAmount } from '@zonofi/common';
import { OrderData } from '@zonofi/zono-money-helpers';

/* eslint-disable-next-line */
export interface OrderDetailsProps {
  orderData: OrderData;
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({ orderData }) => {
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);

  return (
    <div className="details">
      <div
        className="card card_first"
        style={{ backgroundColor: theme.primary }}
      >
        <div className="item__row">
          <div className="item">
            <div className="item__data" style={styles?.['itemHeader']}>
              Order Number
            </div>
            <div className="item__data" style={styles?.['itemValue']}>
              {orderData?.orderNumber || '-'}
            </div>
          </div>
          <div className="item">
            <div className="item__data" style={styles?.['itemHeader']}>
              Created On
            </div>
            <div className="item__data" style={styles?.['itemValue']}>
              {moment(orderData?.createdDate).format('D MMM YYYY, hh:mm A')}
            </div>
          </div>
        </div>
        <div className="item__row">
          <div className="item">
            <div className="item__data" style={styles?.['itemHeader']}>
              PO Number
            </div>
            <div className="item__data" style={styles?.['itemValue']}>
              {orderData?.poNumber || '-'}
            </div>
          </div>
          <div className="item">
            <div className="item__data" style={styles?.['itemHeader']}>
              CO Number
            </div>
            <div className="item__data" style={styles?.['itemValue']}>
              {'-'}
            </div>
          </div>
        </div>
      </div>
      <div
        className="card card_second"
        style={{ backgroundColor: theme.primary }}
      >
        <div className="item__row">
          <div className="item__data" style={styles?.['itemHeader']}>
            Total Order Value
          </div>
          <div className="item__data" style={styles?.['itemValue']}>
            {getFormattedAmount(
              Number(orderData?.totalWithTax) +
                Number(orderData?.discount) -
                Number(orderData?.tax)
            )}
          </div>
        </div>
        <div className="item__row">
          <div className="item__data" style={styles?.['itemHeader']}>
            Scheme Savings
          </div>
          <div
            className="item__data"
            style={{ ...styles?.['itemHeader'], color: '#6BBE4E' }}
          >
            {getFormattedAmount(Number(orderData?.discount))}
          </div>
        </div>
        <div className="item__row">
          <div className="item__data" style={styles?.['itemHeader']}>
            Tax
          </div>
          <div className="item__data" style={styles?.['itemValue']}>
            {getFormattedAmount(Number(orderData?.tax))}
          </div>
        </div>
        <div className="item__row">
          <div className="item__data" style={styles?.['itemHeader']}>
            Net Order Value
          </div>
          <div className="item__data" style={styles?.['itemValue']}>
            {getFormattedAmount(Number(orderData?.totalWithTax))}
          </div>
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: Theme): Record<string, React.CSSProperties> => ({
  itemHeader: { ...theme.fontConfig.regular, color: colors.gray.primary },
  itemValue: { ...theme.fontConfig.regular, color: theme.text.primary },
});

export default OrderDetails;
