import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import { ThemeContext } from '@zonofi/shared';
import EStyleSheet from 'react-native-extended-stylesheet';

interface InvoiceStatusTagsProps {
  invoiceStatus: string;
  fontSize?: number;
}

enum PaymentCodes {
  All_Payments = '',
  Pending = 'P',
  PartiallyPaid = 'PP',
  Paid = 'PD',
  Cancelled = 'C',
  OverDue = 'OD',
  UnPaid = 'UD',
}

export const InvoiceStatusTags: React.FC<InvoiceStatusTagsProps> = ({
  invoiceStatus,
  fontSize = 12,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const getTag = (mainText: string, background: string, color: string) => (
    <View
      style={[
        {
          backgroundColor: background,
          borderColor: 'transparent',
          borderRadius: 5,
          paddingHorizontal: 4,
        },
        styles.tagContainer,
      ]}
    >
      <Text
        style={[
          styles.tagText,
          { color: color, fontSize, ...theme.fontConfig.bold },
        ]}
      >
        {mainText}
      </Text>
    </View>
  );

  const getStatus = (status: string) => {
    switch (status) {
      case PaymentCodes.All_Payments:
        return getTag(
          'All PAYMENTS',
          theme.orderStatusTags.submitted.backgroundColor,
          theme.orderStatusTags.submitted.text
        );
      case PaymentCodes.Cancelled:
        return getTag(
          'CANCELLED',
          theme.orderStatusTags.cancelled.backgroundColor,
          theme.orderStatusTags.cancelled.text
        );
      case PaymentCodes.PartiallyPaid:
        return getTag(
          'PARTIALLY PAID',
          theme.orderStatusTags.waiting.backgroundColor,
          theme.orderStatusTags.waiting.text
        );
      case PaymentCodes.OverDue:
        return getTag(
          'OVER DUE',
          theme.orderStatusTags.cancelled.backgroundColor,
          theme.orderStatusTags.cancelled.text
        );
      case PaymentCodes.Paid:
        return getTag(
          'PAID',
          theme.orderStatusTags.submitted.backgroundColor,
          theme.orderStatusTags.submitted.text
        );
      case PaymentCodes.Pending:
        return getTag(
          'PENDING',
          theme.orderStatusTags.waiting.backgroundColor,
          theme.orderStatusTags.waiting.text
        );
      case PaymentCodes.UnPaid:
        return getTag(
          'UN PAID',
          theme.orderStatusTags.waiting.backgroundColor,
          theme.orderStatusTags.waiting.text
        );
      default:
        return null;
    }
  };

  return getStatus(invoiceStatus);
};

export default InvoiceStatusTags;

const styles = EStyleSheet.create({
  tagContainer: {
    borderRadius: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 17,
  },
  tagText: {
    lineHeight: 25,
  },
});
