// Generated by ts-to-zod
import { z } from 'zod';

export const pricelistItemSchema = z.object({
  sku: z.string().optional(),
  minOrderQty: z.number().optional(),
  name: z.string(),
  price: z.number().optional(),
  taxCategory: z.string().optional(),
  promotion: z.string().optional(),
  id: z.number().optional(),
  isActive: z.boolean().optional(),
});

export const invoiceListItemSchema = z.object({
  docNumber: z.string(),
  invoiceAmount: z.number(),
  dueDate: z.string(),
  id: z.string(),
  docs: z.string(),
});
