import React from 'react';
import { StyleProp, View } from 'react-native';
import { Switch as ReactSwitch } from 'react-native-paper';
import { colors } from '../colors/colors';
import { ViewStyle } from 'react-native';


interface SwitchProps {
  isEnable: boolean;
  onChange: () => void;
  switchContainerStyles?: StyleProp<ViewStyle>;
  switchTrackStyles?: StyleProp<ViewStyle>;
}

export const Switch: React.FC<SwitchProps> = ({
  isEnable,
  onChange,
  switchContainerStyles,
  switchTrackStyles,
}) => {
  return (
    <View
      style={[
        {
          backgroundColor: isEnable
            ? colors.steelBlue.color
            : colors.gray.primary,
          height: 21,
          width: 41,
          borderRadius: 16,
        },
        switchContainerStyles,
      ]}
    >
      <ReactSwitch
        value={isEnable}
        onValueChange={onChange}
        style={[
          {
            backgroundColor: isEnable
              ? colors.steelBlue.color
              : colors.gray.primary,
            marginLeft: isEnable ? 5 : 2,
            height: 17,
            maxWidth: 41,
            borderRadius: 16,
            marginTop: 1.8,
          },
          switchTrackStyles,
        ]}
        color="#fff"
        trackColor={{
          false: colors.gray.primary,
          true: colors.steelBlue.color,
        }}
        thumbColor={isEnable ? colors.white.primaryColor : colors.white.color}
      />
    </View>
  );
};
