import React from 'react';
import Svg, { G, Circle } from 'react-native-svg';

const GridIcon = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <G
        id="Group_114201"
        data-name="Group 114201"
        transform="translate(-1236 -25)"
      >
        <G
          id="Group_53532"
          data-name="Group 53532"
          transform="translate(1252 25)"
        >
          <Circle
            id="Ellipse_1122"
            data-name="Ellipse 1122"
            cx="2"
            cy="2"
            r="2"
            fill="#a1a3b4"
          />
          <Circle
            id="Ellipse_1123"
            data-name="Ellipse 1123"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 8)"
            fill="#a1a3b4"
          />
          <Circle
            id="Ellipse_1124"
            data-name="Ellipse 1124"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 16)"
            fill="#a1a3b4"
          />
        </G>
        <G
          id="Group_114199"
          data-name="Group 114199"
          transform="translate(1244 25)"
        >
          <Circle
            id="Ellipse_1122-2"
            data-name="Ellipse 1122"
            cx="2"
            cy="2"
            r="2"
            fill="#a1a3b4"
          />
          <Circle
            id="Ellipse_1123-2"
            data-name="Ellipse 1123"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 8)"
            fill="#a1a3b4"
          />
          <Circle
            id="Ellipse_1124-2"
            data-name="Ellipse 1124"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 16)"
            fill="#a1a3b4"
          />
        </G>
        <G
          id="Group_114200"
          data-name="Group 114200"
          transform="translate(1236 25)"
        >
          <Circle
            id="Ellipse_1122-3"
            data-name="Ellipse 1122"
            cx="2"
            cy="2"
            r="2"
            fill="#a1a3b4"
          />
          <Circle
            id="Ellipse_1123-3"
            data-name="Ellipse 1123"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 8)"
            fill="#a1a3b4"
          />
          <Circle
            id="Ellipse_1124-3"
            data-name="Ellipse 1124"
            cx="2"
            cy="2"
            r="2"
            transform="translate(0 16)"
            fill="#a1a3b4"
          />
        </G>
      </G>
    </Svg>
  );
};

export default GridIcon;
