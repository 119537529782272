import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function UserComplete({ color = '#a1a3b4', width = 17, height = 17 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 17"
    >
      <Path
        data-name="Path 198189"
        d="M609.544 850.273a6.974 6.974 0 00-6.94 6.182h13.88a6.974 6.974 0 00-6.94-6.182zm2.928-1.032a4.655 4.655 0 10-5.856 0 8.513 8.513 0 00-5.564 7.139 1.492 1.492 0 001.51 1.62h13.965a1.492 1.492 0 001.51-1.62 8.513 8.513 0 00-5.565-7.139zm-2.928-6.7a3.091 3.091 0 103.1 3.091 3.1 3.1 0 00-3.1-3.087z"
        transform="translate(-601.044 -841)"
        fill="#87e2bd"
      />
    </Svg>
  );
}

export default UserComplete;
