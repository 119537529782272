import { store } from '../root/ReduxStore.store';

export interface PartyAccountBookResponse {
  customers: CustomersEntity[];
  totalRecords: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export enum InvoiceStatus {
  all = 'all',
  overdue = 'overdue',
  due = 'due',
  unpaid = 'unpaid',
  paid = 'paid',
  new = 'new',
}

export interface PendingInvoiceRequestParams {
  sellerWorkspaceId: string;
  teamMemberId: string;
  startDate: string;
  endDate: string;
  pageSize?: number;
  pageNo?: number;
  type?: string;
}

export interface InvoiceSummary {
  totalInvoicesAmount: number;
  totalPaidInvoiceAmount: number;
  totalUnpaidInvoiceAmount: number;
}
export interface PendingInvoiceResponse {
  invoices: Invoice[];
  summary: InvoiceSummary;
  totalInvoices: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export enum InvoiceStatusCode {
  overdue = 'OD',
  due = 'D',
  unpaid = 'UD',
  paid = 'PD',
  new = 'N',
  partiallyPaid = 'PP',
}

export interface BusinessDetails {
  id: string;
  companyEmail?: string;
  companyName?: any;
  gstin: string;
  businessPAN?: any;
  mobile?: any;
  physicalAddress?: any;
  gstPortalUserName?: any;
  gstPortalPassword?: any;
  legalName?: any;
  workspaceId: string;
  createdDate: string;
  updatedDate: string;
  isSandBox: boolean;
  communicationHierarchy: string;
}

export interface IsoCode {
  isoCountryCode: string;
  startFY: string;
  endFY: string;
  createdDate?: any;
  updatedDate?: any;
}

export interface PaymentDetails {
  amountToPay: number;
  canPay?: boolean;
  selectedUPIOption?: SelectedUPIApp;
}

export interface workspaceDetails {
  id: string;
  spaceName: string;
  location: number[];
  address?: Address;
  userId: string;
  createdDate: string;
  updatedDate: string;
  updatedBy?: any;
  profilePic?: any;
  timeZone?: any;
  defaultCurrencyCode?: any;
  isoLanguageCode?: any;
  isoCountryCode: string;
  businessDetails: BusinessDetails;
  isoCode: IsoCode;
}
export interface Address {
  neighborhood: string;
  suburb: string;
  apartment: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
}
export interface InvoiceBysellerRequest {
  sellerWorkspaceId?: string;
  inviteId?: string;
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  startDate?: string;
  endDate?: string;
  invoiceStatus?: string;
}

export interface Invoice {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  dueDate: string;
  invoiceAmount: number;
  invoiceAmountWithAdjustment: number;
  invoiceAmountBase: number;
  LRNumber: string;
  LRDate: null | string;
  lines: InvoiceLine[];
  paidAmount: number;
  taxApplied: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin?: string;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration?: string;
  workspaceId: string;
  inviteId: string;
  docs?: string;
  info?: string;
  url?: string;
  linkedWorkspacesId: string;
  locationId?: string;
  payments: InvPayment[];
  paymentDate?: string;
  outStandingAmount: number;
  refOrderNumbers: string[];
  erpOrderNmber?: string;
  description?: string;
  dueInDays?: number;
}

export interface InvoiceLine {
  erpOrderNumber: string;
  refOrderNumber: string;
  trackPONumber: string;
  sku: string;
  invoicedQuantity: number;
  bouncedQuantity: number;
  freeQuantity: null | number;
  listPrice: number;
  listPriceWithTax: number;
  lineTotalWithTax: number;
  SGSTAmount: number;
  CGSTAmount: number;
  IGSTAmount: number;
  lineDiscount: number;
  batchId: string;
  name: string;
  skuCode: string;
  PTR: number;
  expiryDate: string;
  PTS: number;
  MRP: number;
  batchCode: string;
  batchExpiryDate: string;
  taxApplied: number;
  orderedDate: string;
  orderedQuantity?: number;
  InvoiceMRP?: number;
  InvoicePTR?: number;
  productTitle?: string;
}

export interface InvPayment {
  invoiceId: string;
  id: string;
  amountPaid: number;
  paymenDate: Date;
  amountCleared: number;
  paymentRefCode: string;
  paymentMode: string;
  paymentType: string;
  status: string;
  bankName: string;
  bankAccountNumber: string;
  bankReference: string;
  bankCode: string;
  channel: string;
  cardNumber: string;
  cardNetwork: string;
  cardType: string;
  upiId: string;
  instrumentDate: string;
  instrumentNumber: string;
}

export interface ListInvoices {
  invoices: Invoice[];
  totalInvoices: number;
  startRecord: number;
  endRecord: number;
}

export interface PartyAccountBookRequest {
  inviteId?: string;
  sellerWorkspaceId?: string;
  includeOverDue: boolean;
}
export interface InvoiceResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  dueDate?: string;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  workspaceId: string;
  inviteId: string;
  linkedWorkspacesId: string;
  outStandingAmount: number;
  statusCode: number;
  responseStatus: string;
  payments?: paymentHistoryDetails[];
}

export interface paymentHistoryDetails {
  invoiceId: string;
  id: string;
  amountPaid: number;
  paymenDate: string;
  amountCleared: number;
  paymentRefCode: string;
  paymentMode: string;
  status: string;
}

export interface SellerInvoiceRequest {
  inviteId?: string;
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  startDate?: string;
  endDate?: string;
  invoiceStatus?: string;
}

export interface BuyerPartyBookAccountRequest {
  buyerWorkspaceId: string;
}

export interface BuyerPartyBookAccountResponse {
  outStandingBalance: number;
  overDueAmount?: number;
  statusCode: number;
  responseStatus: string;
}

export interface SellerInvoiceResponse {
  invoices: SellerInvoice[];
  totalInvoices: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}

export interface SellerInvoice {
  outstandingAmount: string;
  oldestInvoiceDate: string;
  latestInvoiceDate: string;
  oldestDueDate: string;
  latestDueDate: string;
  sellerWorksapce: SellerWorksapce;
  inviteId: string;
}

export interface SellerWorksapce {
  id: string;
  spaceName: string;
}

export interface AggregatedInvoiceDetailsResponse {
  paid: number;
  unpaid: number;
  overdue: number;
  total: number;
  invoiceCount: number;
  customerCount: number;
  unpaidCustomerCount: number;
  unpaidInvoiceCount: number;
  statusCode: number;
  responseStatus: string;
}

export interface AggregatedInvoiceDetailsRequestParams {
  workspaceId: string;
  teamMemberId: string;
  customerGroupId?: string;
  startDate?: string;
  endDate?: string;
}

export interface CustomerRoutesResponse {
  customerGroupName: string;
  customerGroupId: string;
}

export interface CustomerRoutesParams {
  workspaceId: string;
  inviteId?: string;
  hierarchy: string;
}

export interface CustomerGroupParams {
  sellerWorkspaceId: string;
  teamMemberId: string;
  customerGroupId: string | undefined;
  includeLastPayment: boolean;
  includeDueInvoice: boolean;
  includeLastPaidInvoice: boolean;
  includeOverDue: boolean;
  searchKey?: string;
}

export interface CustomerGroupResponse {
  customers?: CustomersEntity[] | null;
  totalRecords: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
}
export interface CustomersEntity {
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  companyName: string;
  address: string;
  city: string;
  phone: string;
  email: string;
  outStandingBalance: number;
  overdueAmount: number;
  lastPayment?: LastPayment | null;
  dueInvoice?: DueInvoice | null;
  lastPaidInvoice?: LastPaidInvoice | null;
}
export interface LastPayment {
  id: string;
  createdDate: string;
  updatedDate: string;
  sellerWorkspaceId: string;
  buyerWorkspaceId: string;
  inviteId: string;
  paymentDate: string;
  amountPaid: number;
  allocatedAmount: number;
  currencyCode: string;
  status: string;
  paymentType: string;
  paymentMode: string;
  paymentProvider?: string | null;
  proofOfPayment?: null;
  approvedBy?: null;
  createdBy: string;
  paymentRefCode: string;
  instrumentNumber?: string | null;
  instrumentDate?: string | null;
  bankName?: string | null;
  bankAccountNumber?: string | null;
  bankReference?: string | null;
  channel?: null;
  cardNumber?: null;
  cardNetwork?: null;
  cardType?: null;
  bankCode?: null;
  upiId?: string | null;
  narration?: null;
  source: string;
  teamMemberId?: string | null;
  docNumber?: string | undefined;
}

export interface LastPaidInvoice {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: Date;
  dueDate: Date;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin?: any;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  narration?: any;
  workspaceId: string;
  inviteId: string;
  linkedWorkspacesId: string;
}
export interface DueInvoice {
  id: string;
  createdDate: string;
  updatedDate: string;
  referenceInvoiceNumber: string;
  supTyp: string;
  invoiceDate: string;
  dueDate: string;
  invoiceAmount: number;
  paidAmount: number;
  irn: string;
  customerName: string;
  docType: string;
  gstin?: null;
  docNumber: string;
  status: string;
  invoiceStatus: string;
  workspaceId: string;
  inviteId: string;
  linkedWorkspacesId: string;
}

export interface UnpaidInvoiceItem {
  docNumber: string;
  invoiceAmount: number;
}

export type InvoiceRootState = ReturnType<typeof store.getState>;

export interface InvoiceDetailsRequest {
  workspaceId: string;
  invoiceId: string;
  customerId: string;
}

export interface InvoiceList {
  invoices: Invoice[];
  totalInvoices: number;
  startRecord: number;
  endRecord: number;
  statusCode: number;
  responseStatus: string;
  customers: CustomerData[];
}

interface CustomerData {
  customerId: string;
  name: string;
}

export interface InvoiceRequest {
  endDate?: string;
  startDate?: string;
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  workspaceId: string;
  inviteId?: string;
  invoiceStatus?: string;
  searchKey?: string;
  body?: { invoiceIds?: string[]; docNumbers?: string[] };
}

export enum SelectedUPIApp {
  GPAY = 'gpay',
  PHONEPE = 'phonepe',
  PAYTM = 'paytm',
  OTHERS = 'others',
}
