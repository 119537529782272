import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { TogglePopUp } from '../toggle-pop-up/toggle-pop-up';
import { Strings } from '../helpers';

interface ProductQuantityInfoProps {
  uploadedQty: number;
  orderedQty: number;
  minOrderQty: number;
}

export const ProductQuantityInfo: React.FC<ProductQuantityInfoProps> = ({
  uploadedQty,
  orderedQty,
  minOrderQty,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const renderProductQtyInfo = () => {
    const systemModifiedQty =
      Math.ceil(uploadedQty / minOrderQty) * minOrderQty;
    const uploadedQuantity = uploadedQty > 0 ? uploadedQty : '-';
    if (orderedQty > systemModifiedQty || orderedQty < systemModifiedQty) {
      return (
        <TogglePopUp
          heading={Strings.ORDER_QUANTITY_MODIFIED}
          value={`Order quantity has been modified from ${uploadedQuantity} to ${orderedQty}.`}
          headingStyles={{ color: '#808285', fontSize: 10, marginTop: 6 }}
          color={'#FC433F'}
          contentStyles={{
            fontSize: 15,
            color: theme.text.primary,
            fontFamily: theme.fontConfig.regular.fontFamily,
            width: 240,
            marginTop: 8,
            marginLeft: 4,
          }}
          iconStyles={{ marginRight: -15 }}
        />
      );
    } else if (systemModifiedQty > uploadedQty) {
      return (
        <TogglePopUp
          heading={Strings.ORDER_QUANTITY_MODIFIED}
          value={`The product failed to meet the minimum order quantity, as the system automatically adjusted the quantity from ${uploadedQuantity} to ${orderedQty}.`}
          headingStyles={{ color: '#808285', fontSize: 10, marginTop: 6 }}
          color={'#FC433F'}
          contentStyles={{
            fontSize: 15,
            color: theme.text.primary,
            fontFamily: theme.fontConfig.regular.fontFamily,
            width: 240,
            marginTop: 8,
          }}
          iconStyles={{ marginRight: -15 }}
        />
      );
    } else return null;
  };

  return renderProductQtyInfo();
};

export default ProductQuantityInfo;
