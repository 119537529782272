import React, { useContext } from 'react';
import { getCalculatedAmount, getFormattedAmount } from '@zonofi/common';
import { globalStyles } from '../styles/global-style';
import { TogglePopUp } from '../toggle-pop-up/toggle-pop-up';
import { ThemeContext } from '../contexts/ThemeContext';

interface  SummaryCurrencyValue {
  value: any, 
  popupLabel: string
}

export const SummaryCurrencyValue: React.FC<SummaryCurrencyValue> = ({
  value,
  popupLabel
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{ ...globalStyles.flexRowAlCenter, ...globalStyles.displayFlex }}
    >
      <div
        style={{
          ...theme.fontConfig.semiBold,
          ...globalStyles.font20,
        }}
      >
        {getCalculatedAmount(value ? value?.toString() : '0')}
      </div>
      <TogglePopUp
        heading={popupLabel}
        value={getFormattedAmount(value) ?? '0'}
      />
    </div>
  );
};
