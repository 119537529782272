import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { fieldCollectionUserApi } from './user-api';
import { SendOTPResponse, UserToken, VerifyOTPResponse } from './user-model';
import { RootState } from '../root/ReduxStore.store';
import { AppType } from '../user-auth';

const FIELD_COLLECTION_USER_AUTH_FEATURE_KEY = 'field-collection-user-Auth';

interface UserauthState {
  userData?: VerifyOTPResponse;
  isAuthenticated: boolean;
  error?: string;
  token?: string;
  refreshToken?: string;
  otp: string;
  teamInviteID?: string;
  appType?: AppType;
}

const initialUserauthState: UserauthState = {
  isAuthenticated: false,
  otp: '',
  appType: undefined,
};

export const fieldCollectionUserAuthSlice = createSlice({
  name: FIELD_COLLECTION_USER_AUTH_FEATURE_KEY,
  initialState: initialUserauthState,
  reducers: {
    onAuthSuccess(state, action: PayloadAction<VerifyOTPResponse>) {
      state.isAuthenticated = _.isUndefined(action.payload.alertMessage);
      state.userData = action.payload;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.appType = AppType.FIELD_COLLECTION;
    },
    onRefreshToken(state, action: PayloadAction<UserToken>) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
    },
    logOut(state, action: PayloadAction<void>) {
      state.isAuthenticated = initialUserauthState.isAuthenticated;
      state.otp = initialUserauthState.otp;
      state.refreshToken = initialUserauthState.refreshToken;
      state.userData = initialUserauthState.userData;
    },
    onSendOTPSuccesss(state, action: PayloadAction<SendOTPResponse>) {
      state.otp = action?.payload?.otp?.toString();
      state.teamInviteID = action?.payload?.teamInviteId;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      fieldCollectionUserApi.endpoints.sendOTP.matchFulfilled,
      (state, action) => {
        fieldCollectionUserAuthSlice.caseReducers.onSendOTPSuccesss(
          state,
          action
        );
      }
    );
    builder.addMatcher(
      fieldCollectionUserApi.endpoints.verifyOTP.matchFulfilled,
      (state, action) => {
        fieldCollectionUserAuthSlice.caseReducers.onAuthSuccess(state, action);
      }
    );
  },
});

export const {
  onRefreshToken: fieldCollectionRefreshToken,
  logOut: fieldCollectionLogOut,
} = fieldCollectionUserAuthSlice.actions;

export const getFieldCollectionUserAuthState = (
  rootState: RootState
): UserauthState => rootState[FIELD_COLLECTION_USER_AUTH_FEATURE_KEY];

export const getFCToken = createSelector(
  getFieldCollectionUserAuthState,
  (state) => state.token
);

export const getFCRefreshToken = createSelector(
  getFieldCollectionUserAuthState,
  (state) => state.refreshToken
);

export const getFCAppType = createSelector(
  getFieldCollectionUserAuthState,
  (state) => state.appType
);

export const getFieldAgentOTP = createSelector(
  getFieldCollectionUserAuthState,
  (state) => state.otp
);
export const getTeamMember = createSelector(
  getFieldCollectionUserAuthState,
  (state) => state.userData
);
export const getTeamMemberID = createSelector(
  getFieldCollectionUserAuthState,
  (state) => state.teamInviteID
);

export const isFieldAgentAuthenticated = createSelector(
  getFieldCollectionUserAuthState,
  (state) => state.isAuthenticated
);
