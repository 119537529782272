import React, { useContext, useState } from 'react';
import { TextInput, ColorValue } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';
import { textInputValidate } from '@zonofi/common';

interface TextInputProps {
  defaultValue?: string;
  placeholder?: string;
  placeholderTextColor?: ColorValue;
  data?: any;
  handleChange?: any;
  alignment?: string;
  type?: string;
  disable?: boolean;
}

export const TextInputComponent: React.FC<TextInputProps> = ({
  defaultValue,
  placeholder,
  handleChange,
  alignment,
  data,
  type,
  disable = false,
}) => {
  const [text, setText] = useState(defaultValue);
  const [isEdit, setIsEdit] = useState(false);
  const { theme } = useContext(ThemeContext);
  return (
    <TextInput
      editable={!disable}
      defaultValue={defaultValue}
      value={isEdit ? String(text) : defaultValue}
      placeholder={placeholder}
      placeholderTextColor={theme.input.placeHolder}
      onChangeText={(newText) => {
        if (textInputValidate(newText).success) {
          setIsEdit(true);
          if (type === 'number') {
            if (Number.isInteger(Number(newText))) {
              setText(String(Number(newText)));
            }
          } else {
            setText(newText);
          }
        }
      }}
      style={{
        width: '100%',
        color: theme.text.primary,
        fontSize: 16,
        ...theme.fontConfig.regular,
        textAlign: alignment === 'flex-end' ? 'right' : 'left',
      }}
      onBlur={(e) => {
        handleChange &&
          handleChange(e.nativeEvent.text, data?.row.original?.id);
        setIsEdit(false);
      }}
    />
  );
};
