import { PermissionsAndroid, Platform } from 'react-native';
// import ReactNativeBlobUtil from 'react-native-blob-util';
import Share from 'react-native-share';

const requestPermission = async () => {
  if (Platform.OS === 'android') {
    await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS['WRITE_EXTERNAL_STORAGE']
    );
    await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS['READ_EXTERNAL_STORAGE']
    );
  }
};

export const shareFile = (
  fileName: string,
  link: string,
  setLoading: (state: any) => void,
  accessToken: string,
  download = false
) => {
  // const dirs = ReactNativeBlobUtil.fs.dirs;
  // const baseUrl = getConfigValue('REQUEST_BASE_URL');
  // const url = link.startsWith('http') ? link : `${baseUrl}${link}`;
  // requestPermission();
  // ReactNativeBlobUtil.config({
  //   fileCache: true,
  //   appendExt: 'pdf',
  //   path: `${dirs.DownloadDir}/Download/zono/${fileName}`,
  //   addAndroidDownloads: {
  //     useDownloadManager: true,
  //     notification: true,
  //     mime: 'application/pdf',
  //     path: `${dirs.DownloadDir}/Download/zono/${fileName}`,
  //   },
  // })
  //   .fetch('GET', url, {
  //     Accept: 'application/pdf',
  //     'Content-Type': 'application/pdf',
  //     Authorization: `Bearer ${accessToken}`,
  //   })
  //   .then(async (res) => {
  //     if (!download) {
  //       const base64 = await res.base64();
  //       const base64Data = `data:application/pdf;base64,` + base64;
  //       Share.open({ url: base64Data });
  //     }
  //     setLoading(false);
  //   });
};

export const shareCsvFile = (
  fileName: string,
  link: string,
  setLoading: (state: boolean) => void,
  accessToken: string
) => {
  // const dirs = ReactNativeBlobUtil.fs.dirs;
  // const baseUrl = getConfigValue('REQUEST_BASE_URL');
  // const url = link.startsWith('http') ? link : `${baseUrl}${link}`;
  // const path = `${dirs.DownloadDir}/Download/zono/${fileName}`;
  // requestPermission();
  // ReactNativeBlobUtil.config({
  //   fileCache: true,
  //   appendExt: 'csv',
  //   path: path,
  //   addAndroidDownloads: {
  //     useDownloadManager: true,
  //     notification: true,
  //     mime: 'text/csv',
  //     path: path,
  //   },
  // })
  //   .fetch('GET', url, {
  //     Accept: 'text/csv',
  //     'Content-Type': 'text/csv',
  //     Authorization: `Bearer ${accessToken}`,
  //   })
  //   .then(async (res) => {
  //     const base64 = await res.base64();
  //     const base64Data = `data:text/csv;base64,` + base64;
  //     setLoading(false);
  //     await Share.open({ url: base64Data, type: 'text/csv' });
  //   });
};
