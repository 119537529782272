import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function Dot(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={24}
      height={24}
      viewBox="0 0 14 14"
      {...props}
    >
      <Path fill="#fff" d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
    </Svg>
  );
}

export default Dot;
