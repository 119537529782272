import React from 'react';

import { View, Animated, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { colors } from '../colors/colors';
import { fontConfig } from '../fonts/fonts';

/* eslint-disable-next-line */
export interface AnimatedHeaderProps {
  scrollPosition: Animated.Value;
  selectedTab: string;
}

export const AnimatedHeader: React.FC<AnimatedHeaderProps> = ({
  scrollPosition,
  selectedTab,
}) => {
  const AnimatedLinearGradient =
    Animated.createAnimatedComponent(LinearGradient);
  const MIN_HEADER_HEIGHT = hp(0);
  const MAX_HEADER_HEIGHT = hp(20);

  const headerHeight = scrollPosition.interpolate({
    inputRange: [0, 300],
    outputRange: [MIN_HEADER_HEIGHT, MAX_HEADER_HEIGHT],
    extrapolate: 'clamp',
  });

  const textOpacity = scrollPosition.interpolate({
    inputRange: [0, 400],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const textLeftOffset = scrollPosition.interpolate({
    inputRange: [0, 400],
    outputRange: [-wp(5), 0],
    extrapolateLeft: 'identity',
    extrapolateRight: 'clamp',
  });
  return (
    <AnimatedLinearGradient
      colors={[colors.richBlack.color, colors.richBlack.color]}
      style={[
        {
          height: headerHeight,
        },
        styles.container,
      ]}
    >
      <View style={styles.left}>
        <Animated.Text
          style={[
            {
              opacity: textOpacity,
              transform: [{ translateX: textLeftOffset }],
            },
            styles.animatedText,
          ]}
        >
          {selectedTab}
        </Animated.Text>
        {selectedTab && (
          <Animated.View
            style={[
              {
                opacity: textOpacity,
                transform: [{ translateX: textLeftOffset }],
              },
              styles.animatedView,
              { width: wp(selectedTab.length * 1.8) },
            ]}
          ></Animated.View>
        )}
      </View>
    </AnimatedLinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    width: wp('100%'),
    justifyContent: 'space-between',
    paddingHorizontal: wp(2),
    flexDirection: 'row',
    alignItems: 'center',
  },
  left: { flexDirection: 'column', alignItems: 'center', paddingLeft: wp(3) },
  animatedText: {
    color: colors.crayolaGreen.color,
    fontFamily: fontConfig.default.semiBold.fontFamily,
    fontWeight: fontConfig.default.semiBold.fontWeight,
    fontSize: 14,
  },
  animatedView: {
    backgroundColor: colors.crayolaGreen.color,
    height: 3,
    borderRadius: 5,
    marginVertical: hp(0.5),
  },
});
