export const ERROR_MESSAGES = {
  REQUIRED: 'This is a mandatory field',
  DIGITS_ONLY: 'Please enter valid number',
  ALPHA_NUMERIC: 'Please enter alpha numberic value',
  TEXT_INPUT: 'Please enter valid input text',
  MOBILE: 'Please enter a valid 10 digit number',
  EMAIL: 'Please enter valid email address',
  EMAIL_OR_MOBILE: 'Please enter a valid email id or 10 digit mobile number',
  NAME: 'Please enter valid name',
  MIN_NAME_LENGTH: 'Name must have at least 3 letters',
  AMOUNT: 'Please enter valid amount',
  DATE: 'Date must be in the format DD/MM/YYYY',
  GSTIN: 'Please enter valid gst number',
  PAN: 'Please enter valid PAN Number',
  AADHAR: 'Please enter valid Aadhar Number',
  PINCODE: 'Please enter a valid 6 digit number',
  BANK_ACCOUNT:
    'Please enter a valid account number with minimum of 9 digits and maximum of 18 digits',
  IFSC: 'Please enter a valid IFSC with 11 characters',
  CHEQUE: 'Please enter a valid cheque number',
  VEHICLE: 'Please enter a valid vehicle number',
};
