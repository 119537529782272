import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from 'react-native';

import Icon from 'react-native-easy-icon';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { colors } from '../colors/colors';
import { FloatingLabelInput } from '../floating-label-input/floating-label-input';
import { fontConfig } from '../fonts/fonts';
import { SearchBarVariants } from '../types/enum';
import { Strings } from '../helpers';

interface SearchBarProps {
  variant: 'oncancel' | 'notcancel';
  placeholder: string;
  setSearchTerm: (term: string) => void;
  defaultValue?: string;
  width?: number;
  searchText?: () => void;
  collapsable?: boolean;
  onCancel?: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  variant,
  placeholder,
  setSearchTerm,
  defaultValue,
  width,
  searchText,
  onCancel,
  collapsable = true,
}) => {
  switch (variant) {
    case SearchBarVariants.NOTCANCEL:
      return (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View style={{ width: width }}>
            <FloatingLabelInput
              initialValue=""
              validationRules={[]}
              label={placeholder}
              prefixIcon="magnify"
              prefixIconColor={colors.white.color}
              onChangeText={(text) => {
                setSearchTerm(text);
              }}
              prefixIconStyle={{ marginTop: 10 }}
            />
          </View>
        </View>
      );
    case SearchBarVariants.CANCEL:
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={[styles.container, { width: width }]}>
            <TextInput
              defaultValue={defaultValue}
              placeholder={placeholder}
              placeholderTextColor={colors.manatee.color}
              autoFocus
              returnKeyType="search"
              style={styles.regularText}
              onChangeText={(text) => {
                setSearchTerm(text);
              }}
            />
            <TouchableOpacity onPress={searchText}>
              <Icon
                type="material"
                name="search"
                color={colors.manatee.color}
                size={20}
              />
            </TouchableOpacity>
          </View>
          {collapsable && (
            <TouchableOpacity onPress={onCancel}>
              <Text style={styles.cancelText}>
                {Strings.CANCEL.toLocaleUpperCase()}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      );
    default:
      return (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View style={[styles.container, { width: width }]}>
            <TextInput
              defaultValue={defaultValue}
              placeholder={placeholder}
              placeholderTextColor={colors.manatee.color}
              autoFocus
              returnKeyType="search"
              style={styles.regularText}
              onChangeText={(text) => {
                setSearchTerm(text);
              }}
            />
            <TouchableOpacity onPress={searchText}>
              <Icon
                type="material"
                name="search"
                color={colors.manatee.color}
                size={20}
              />
            </TouchableOpacity>
          </View>
          {collapsable && (
            <TouchableOpacity onPress={onCancel}>
              <Text style={styles.cancelText}>
                {Strings.CANCEL.toLocaleUpperCase()}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      );
  }
};

const styles = StyleSheet.create({
  regularText: {
    fontSize: 12,
    color: colors.white.color,
    ...fontConfig.default.regular,
  },
  cancelText: {
    color: colors.white.color,
    fontSize: 12,
    letterSpacing: 1,
    ...fontConfig.default.regular,
  },
  container: {
    flexDirection: 'row',
    borderColor: '#373941',
    borderWidth: 1,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: wp(3),
    paddingHorizontal: wp(3),
    marginVertical: hp(1),
  },
});
