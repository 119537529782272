import React, { useContext, useEffect, useMemo } from 'react';
import Parser from 'html-react-parser';
import Icon from 'react-native-easy-icon';
import _ from 'lodash';
import { LoadingSpinner, ThemeContext, colors } from '@zonofi/shared';
import {
  Audit,
  OrderLogsTypes,
  getCustomerInvite,
  useGetOrdersLogsMutation,
} from '@zonofi/zono-money-store';
import { useAppSelector } from '@zonofi/zono-money-helpers';

/* eslint-disable-next-line */
export interface OrderHistoryProps {
  orderId: number;
}

export const OrderHistory: React.FC<OrderHistoryProps> = ({ orderId }) => {
  const { theme } = useContext(ThemeContext);
  const [getAuditLogs, auditLogsResponse] = useGetOrdersLogsMutation();
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller } = inviteDetails;

  useEffect(() => {
    getAuditLogs({
      workspaceId: seller?.workspaceId,
      orderId,
      action: OrderLogsTypes.UPDATE,
    });
  }, [orderId]);

  const renderItem = (data: Audit) => {
    const { action, sku, productName, updatedFields, oldSku, oldProductName } =
      data || {};
    const { quantity, oldQuantity } = updatedFields || {};
    switch (action) {
      case OrderLogsTypes.INSERT:
        return `New product added, <span style={{color: theme.text.primary}}>${
          sku || '-'
        } | ${productName || '-'} | ${quantity || '-'}</span>`;
      case OrderLogsTypes.UPDATE:
        return `<span style={{color: theme.text.primary}}>Order quantity modified from ${
          oldQuantity || '-'
        } to ${quantity || '-'}</span> for product | ${sku || '-'} | ${
          productName || '-'
        } `;
      case OrderLogsTypes.REMAPPED:
        return `<span style={{color: theme.text.primary}}>${oldSku || '-'} |  ${
          oldProductName || '-'
        } | ${
          updatedFields?.oldQuantity || '-'
        }</span> remapped to <span style={{color: theme.text.primary}}>${
          sku || '-'
        } | ${productName || '-'} | ${quantity || '-'}`;
      case OrderLogsTypes.DELETE:
        return `<span style={{color: theme.text.primary}}>${sku || '-'} | ${
          productName || '-'
        } | ${oldQuantity || '-'} qty</span> deleted `;
      default:
        return '';
    }
  };

  const auditLogs = useMemo(
    () => Object.entries(auditLogsResponse?.data || {}),
    [auditLogsResponse]
  );

  return (
    <div className="status-history">
      {auditLogsResponse?.isLoading ? (
        <LoadingSpinner />
      ) : _.isEmpty(auditLogs) ? (
        <div
          className="no-log-item"
          style={{
            ...theme.fontConfig.semiBold,
            color: colors.gray.primary,
          }}
        >
          No status history available
        </div>
      ) : (
        auditLogs.map(([key, value]) => (
          <div className="card">
            <div
              className="date"
              style={{
                ...theme.fontConfig.semiBold,
                color: theme.text.primary,
              }}
            >
              {key}
            </div>
            {value.map((item) => {
              const { time, updatedBy } = item;
              return (
                <div className="log-item">
                  <Icon
                    name="dot-single"
                    type="entypo"
                    size={16}
                    color="#808285"
                  />
                  <div>
                    <div
                      className="log-item__text"
                      style={{
                        ...theme.fontConfig.regular,
                        color: colors.gray.primary,
                      }}
                    >
                      {Parser(renderItem(item))}
                    </div>
                    <div
                      className="log-item__footer"
                      style={{
                        ...theme.fontConfig.regular,
                        color: colors.gray.primary,
                      }}
                    >
                      {`By ${updatedBy ?? '-'} ・ ${time}`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))
      )}
    </div>
  );
};

export default OrderHistory;
