import {
  ShimmerPlaceHolder,
} from '../shimmer-place-holder/shimmer-place-holder';
import {
  fontConfig,
} from '../fonts/fonts';
import {
  ShimmerRefObject,
  ShimmerTextRefObject,
} from '../types/interfaces';
import React, { forwardRef, Ref } from 'react';
import { colors } from '../colors/colors';

import {
  Text,
  StyleProp,
  TextStyle,
  StyleSheet,
  ViewStyle,
  View,
} from 'react-native';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';

/* eslint-disable-next-line */
export interface ShimmerViewProps {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
  width?: number;
  shimmerStyle?: StyleProp<ViewStyle>;
}

export const ShimmerView = forwardRef(
  (props: ShimmerViewProps, ref: Ref<ShimmerRefObject>) => {
    const { style, width, children, shimmerStyle } = props;
    const [isVisible, setIsVisible] = React.useState(false);

    React.useImperativeHandle(ref, () => ({ show, hide }));

    const show = () => {
      setIsVisible(true);
    };
    const hide = () => {
      setIsVisible(false);
    };
    return (
      <ShimmerPlaceHolder
        width={width}
        shimmerStyle={[styles.shimmer, shimmerStyle]}
        visible={isVisible}
      >
        <View style={style}>{children}</View>
      </ShimmerPlaceHolder>
    );
  }
);

const styles = StyleSheet.create({
  shimmer: { marginVertical: hp(1.5), borderRadius: 7 },
});
