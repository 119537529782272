import * as React from 'react';
import Svg, { G, Line } from 'react-native-svg';

function InvoiceArrow(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="55.955"
      height="20.997"
      viewBox="0 0 55.955 20.997"
    >
      <G
        id="Group_138931"
        data-name="Group 138931"
        transform="translate(-380.627 -191.001)"
      >
        <G
          id="Group_138930"
          data-name="Group 138930"
          transform="translate(417 201.5) rotate(-45)"
        >
          <Line
            id="Line_4050"
            data-name="Line 4050"
            x2="12.847"
            transform="translate(0 12.847)"
            fill="none"
            stroke="#34353e"
            stroke-linecap="round"
            stroke-width="2"
          />
          <Line
            id="Line_4051"
            data-name="Line 4051"
            y2="12.847"
            transform="translate(12.847 0)"
            fill="none"
            stroke="#34353e"
            stroke-linecap="round"
            stroke-width="2"
          />
        </G>
        <Line
          id="Line_52691"
          data-name="Line 52691"
          x1="53"
          transform="translate(381.627 201.5)"
          fill="none"
          stroke="#34353e"
          stroke-linecap="round"
          stroke-width="2"
        />
      </G>
    </Svg>
  );
}

export default InvoiceArrow;
