import React from 'react';
import { View, Text, Platform, TouchableOpacity } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
//TO-DO

const isWebPlatform = Platform.OS === 'web';

export const AlertMessage = (
  title: any,
  msg: any,
  isSuccess = false,
  duration = 8000,
  notify = false,
  linkThread = '',
  warning = false
) => {
  console.log(title, msg, isSuccess, duration, notify, linkThread);
  const isSucessOrError = isSuccess ? '#00b778' : warning ? '#DBA800' : '#ce4837';
  const colorCode = notify ? '#34353e' : isSucessOrError;

  // dispatch(
  //   showToast({
  //     canDisplay: true,
  //     title: title,
  //     message: msg,
  //     colorCode: colorCode,
  //   })
  // );
};

const styles = EStyleSheet.create({
  container: {
    marginTop: 42,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 4,
    position: 'absolute',
    // overflow: 'hidden',
  },
  containerWeb: {
    marginBottom: 22,
    marginLeft: 'auto',
    marginRight: 20,
    borderRadius: 4,
    width: '492rem',
    height: '79rem',
  },
  wrapper: {
    flex: 1,
    flexDirection: 'row',
  },
  textView: {
    flexBasis: isWebPlatform ? '70%' : '77%',
  },
  title: {
    marginLeft: 10,
    marginTop: 12,
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: 12,
    letterSpacing: 1.2,
    fontFamily: Platform.select({
      ios: 'FiraSans-SemiBold',
      android: 'FiraSans-SemiBold',
      web: 'Fira Sans, sans-serif',
    }),
    fontWeight: Platform.select({
      web: '600',
    }),
  },
  message: {
    fontSize: 14,
    marginTop: isWebPlatform ? 5 : -2,
    color: 'white',
    marginLeft: 10,
    marginBottom: 11,
    fontFamily: Platform.select({
      ios: 'FiraSans-Regular',
      android: 'FiraSans-Regular',
      web: 'Fira Sans, sans-serif',
    }),
    fontWeight: Platform.select({
      web: '500',
    }),
  },
  dismissView: {
    marginLeft: 'auto',
    marginRight: isWebPlatform ? 12 : 10,
    justifyContent: 'center',
  },
  dismissText: {
    color: 'white',
    textAlign: 'center',
    letterSpacing: 1.2,
    fontFamily: Platform.select({
      ios: 'FiraSans-SemiBold',
      android: 'FiraSans-SemiBold',
      web: 'Fira Sans, sans-serif',
    }),
    fontWeight: Platform.select({
      web: '600',
    }),
  },
});
