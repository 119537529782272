import React, { useContext } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';
import Popup from 'reactjs-popup';
import { colors } from '../colors/colors';
import EStyleSheet from 'react-native-extended-stylesheet';

/* eslint-disable-next-line */
export interface cfaCustomersListProps {
  options: { icon: JSX.Element; text: string; action: () => void }[];
  alignment: string;
}

export const CfaCustomersListOptions: React.FC<cfaCustomersListProps> = ({
  options,
  alignment,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <Popup
      trigger={
        <div style={{ display: 'flex', paddingRight: 16, justifyContent: alignment }}>
          <span
            style={{
              marginLeft: 'auto',
              color: '#9295a5',
              fontSize: 24,
              cursor: 'pointer',
            }}
          >
            {' '}
            &#8942;{' '}
          </span>
        </div>
      }
      position={['bottom right', 'bottom left', 'bottom center']}
      on={['hover', 'focus']}
      closeOnDocumentClick
      nested
    >
      <View
        style={{
          backgroundColor: theme.dropDown.background,
          borderWidth: 1,
          borderColor: colors.chineseWhite.primary,
          borderRadius: 6,
          borderStyle: 'solid',
        }}
      >
        {options.map((item, index) => (
          <Pressable onPress={item.action} key={index}>
            <View style={styles.listItem}>
              {item.icon}
              <Text
                style={[
                  styles.listText,
                  {
                    color: theme.text.primary,
                    fontFamily: theme.fontConfig.regular.fontFamily,
                  },
                ]}
              >
                {item.text}
              </Text>
            </View>
          </Pressable>
        ))}
      </View>
    </Popup>
  );
};

const styles = EStyleSheet.create({
  placeOrderIcon: {
    marginLeft: 8,
    marginTop: 1,
  },
  listItem: {
    paddingHorizontal: 14,
    paddingVertical: 10,
    cursor: 'pointer',
    alignItems: 'center',
    flexDirection: 'row',
  },
  listText: {
    paddingLeft: 8,
    fontSize: 16,
  },
});

export default CfaCustomersListOptions;
