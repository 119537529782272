import React, { useContext } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import {
  Button,
  ButtonVariants,
  colors,
  SvgAsset,
  SVGS,
} from '@zonofi/zono-money-design-system';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { useNavigate } from 'react-router-dom';
import { Strings, WEBROUTES } from '@zonofi/zono-money-helpers';
import LinearGradient from 'react-native-linear-gradient';
import { ThemeContext } from '@zonofi/shared';

export const ErrorScreen: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  return (
    <LinearGradient
      colors={[
        colors.blackLinearGradient.firstcolor,
        colors.blackLinearGradient.secondcolor,
      ]}
      style={{ alignItems: 'center', height: hp('100%') }}
    >
      <View style={styles.imgStyle}>
        <SvgAsset
          SVGComponent={SVGS.ErrorScreenImage}
          height={hp(28)}
          width={wp(55)}
        />
      </View>
      <Text style={[styles.errorStyle, theme.fontConfig.regular]}>
        {Strings.ERRORSCREEN}
      </Text>
      <Text style={[styles.errorTextStyle, theme.fontConfig.regular]}>
        {Strings.ERRORSCREEN_TEXT}
      </Text>
      <Button
        text={Strings.GO_BACK}
        variant={ButtonVariants.gradient_filled_action}
        gradientColors={[
          colors.teritiaryButtonBackgroundColor.firstcolor,
          colors.teritiaryButtonBackgroundColor.secondcolor,
        ]}
        onPress={() => {
          navigate(WEBROUTES.VENDOR_SCREEN);
        }}
        style={{
          marginBottom: hp(2),
          marginTop: hp(10),
          width: wp('90%'),
          alignItems: 'center',
          alignContent: 'center',
        }}
      />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  errorStyle: {
    fontSize: 20,
    color: colors.zonoWhite.color,
    marginTop: hp(2),
    marginBottom: hp(2),
    textAlign: 'center',
    width: wp(50),
  },
  errorTextStyle: {
    fontSize: 14,
    color: colors.zonoWhite.color,
    paddingLeft: wp(2.7),
    textAlign: 'center',
    width: wp(83),
  },
  imgStyle: {
    marginTop: hp(20),
  },
});
