import _ from 'lodash';
import Strings from '../constants/strings';
import { BrowserType } from '../types/enum';
import { FormValue, SelectedUPIApp } from '@zonofi/zono-money-store';

export const getValueFromKey = (key: string, formValues: FormValue[]) => {
  const value = formValues.find((value) => value.key === key);
  return value;
};

export const capitalizeFirstLetter = (str: string) => {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const capitalizeOnlyFirstLetter = (str: string) => {
  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const getUPIAppString = (app: SelectedUPIApp | undefined) => {
  let upiApp = '';
  switch (app) {
    case SelectedUPIApp.GPAY:
      upiApp = Strings.GPAY;
      break;
    case SelectedUPIApp.PAYTM:
      upiApp = Strings.PAYTM;
      break;
    case SelectedUPIApp.PHONEPE:
      upiApp = Strings.PHONEPE;
      break;
    default:
      upiApp = '';
      break;
  }
  return upiApp;
};

export const isUpiAppCheck = (app: SelectedUPIApp | undefined) => {
  if (app) {
    if (app === SelectedUPIApp.OTHERS) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getDefaultOnNull = (value?: string) => (value ? value : '');

export const getBrowserType = () => {
  let isMobileBrowser = false;
  if ('maxTouchPoints' in navigator) {
    isMobileBrowser = navigator.maxTouchPoints > 0;
  } else {
    const mQ = matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      isMobileBrowser = !!mQ.matches;
    } else if ('orientation' in window) {
      isMobileBrowser = true;
    } else {
      //@ts-ignore
      const UA = navigator.userAgent;
      isMobileBrowser =
        /\b(BlackBerry|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad)\b/i.test(UA);
    }
  }
  if (isMobileBrowser) {
    return BrowserType.MOBILE;
  } else {
    return BrowserType.DESKTOP;
  }
};

export const areNil = (args: any[]) => {
  return _.some(args, (val) => _.isNil(val));
};
