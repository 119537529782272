import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  CatalogueView,
  ConfigModule,
  ProductVariant,
  getCustomerInvite,
  useAddItemToActiveOrderMutation,
  useCheckoutOrdersMutation,
  useEditOrderMutation,
  useGetOrderDetailsMutation,
  useGetProductsMutation,
  useGetTrackPODetailsMutation,
  useLazyGetConfigDetailsQuery,
  useLazyPoFileDetailsByIdQuery,
  useNotifyToBuyerMutation,
} from '@zonofi/zono-money-store';
import {
  OrderStatusEnum,
  WEBROUTES,
  extractDataFromOrder,
  extractDataFromPO,
  extractDataFromPOForManual,
  useAppSelector,
} from '@zonofi/zono-money-helpers';
import '../whatsapp-flow.scss';
import { isEmpty } from 'lodash';
import OrderDetailsScreenLayout from './order-details-screen-layout';
import OrderEditScreenLayout from './order-edit-screen-layout';
import { LoadingSpinner } from '@zonofi/zono-money-design-system';
import { OrderDetailsEmptyCart } from './order-details-empty-cart';
import './order-details-screen.scss';

export const OrderDetailsScreen: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const fromScreen = searchParams?.get('from');
  const campaignType = searchParams?.get('campaignType');
  const { orderId } = params;
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;

  const [data, setData] = useState<any>();

  const [fetchOrder, fetchOrderResponse] = useGetOrderDetailsMutation();
  const [fetchPoFile, fetchPoFileResponse] = useGetTrackPODetailsMutation();
  const [fetchOrderAfterEdit, fetchOrderAfterEditResponse] =
    useGetOrderDetailsMutation();
  const [fetchPoFileAfterEdit, fetchPoFileAfterEditResponse] =
    useGetTrackPODetailsMutation();
  const [notifyToBuyer, notifyToBuyerResponse] = useNotifyToBuyerMutation();

  const [getPoDetailsWithIdAPI, getPoDetailsWithIdAPIResponse] =
    useLazyPoFileDetailsByIdQuery();
  const [
    getPoDetailsWithIdAPIAfterEdit,
    getPoDetailsWithIdAPIAfterEditResponse,
  ] = useLazyPoFileDetailsByIdQuery();
  const [addToCartAPI, addToCartAPIResponse] =
    useAddItemToActiveOrderMutation();
  const [getConfigAPI, getConfigAPIResponse] = useLazyGetConfigDetailsQuery();
  const [checkout, checkoutResponse] = useCheckoutOrdersMutation();
  const [editOrder, editOrderResponse] = useEditOrderMutation();
  const [selectedOrderLineId, setSelectedOrderLineId] = useState('');

  const [moqError, setMoqError] = useState({
    show: false,
    productVariantId: null,
  });

  const isPoFile = isNaN(Number(orderId));

  const onValueChange = (productVariantId, value) => {
    const selectedLine = data?.lines.find(
      (item) => item?.productVariantId === productVariantId
    );

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: value,
            productVariantId: productVariantId,
            operator: 'add',
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const onIncrement = (productVariantId) => {
    const selectedLine = data?.lines.find(
      (item) => item?.productVariantId === productVariantId
    );

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: selectedLine.quantity + 1,
            productVariantId: productVariantId,
            operator: 'add',
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const onDecrement = (productVariantId) => {
    const selectedLine = data?.lines.find(
      (item) => item?.productVariantId === productVariantId
    );

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: (selectedLine.quantity - 1).toString(),
            productVariantId: productVariantId,
            operator: 'minus',
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const onDelete = (productVariantId) => {
    onChangeQuantity(productVariantId, 0);
  };

  const onChangeQuantity = (productVariantId, qty: number) => {
    const selectedLine = data?.lines.find(
      (item) => item?.productVariantId === productVariantId
    );

    if (qty === selectedLine?.quantity) {
      return;
    }

    let operator = 'add';

    if (qty < selectedLine?.quantity) {
      operator = 'minus';
    }

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: qty.toString(),
            productVariantId: productVariantId,
            ...(qty !== 0 && { operator: operator }),
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const handleAddProductFromSearch = (productVariantId, moq) => {
    if (isPoFile && fromScreen) {
      addToCartAPI({
        sellerWorkspaceId: seller?.workspaceId,
        customerId: inviteId,
        poFileId: orderId,
        lines: [
          {
            productVariantId: productVariantId,
            quantity: moq,
            operator: 'add',
          },
        ],
      });
    } else {
      const findOrderId = data?.lines?.find((item) => item?.orderId)?.orderId;

      editOrder({
        workspaceId: seller?.workspaceId,
        body: {
          customerId: inviteId,
          lines: [
            {
              quantity: '1',
              productVariantId: productVariantId,
              operator: 'add',
            },
          ],
          orderId: findOrderId?.toString(),
        },
      });
    }
  };

  const handleReplaceProductFromSearch = (productVariantId) => {
    const findOrderId = data?.lines?.find((item) => item?.orderId)?.orderId;
    const selectedOrderLine = data?.lines?.find(
      (item) => item.orderLineId === selectedOrderLineId
    );

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: '1',
            productVariantId: productVariantId,
            orderLineId: selectedOrderLine?.orderLineId,
            poFileLineId: selectedOrderLine?.poFileLineId,
          },
        ],
        ...(isPoFile
          ? { poFileId: orderId }
          : { orderId: findOrderId?.toString() }),
      },
    });
  };

  const showImage = useMemo(() => {
    if (getConfigAPIResponse?.isSuccess && getConfigAPIResponse?.data) {
      if (
        getConfigAPIResponse?.data?.config?.catalogueView &&
        getConfigAPIResponse?.data?.config?.catalogueView !==
          CatalogueView.ListView
      ) {
        return true;
      }
    }
    return false;
  }, [getConfigAPIResponse]);

  const showPrice = useMemo(() => {
    if (getConfigAPIResponse?.isSuccess && getConfigAPIResponse?.data) {
      if (
        getConfigAPIResponse?.data?.config?.displayCataloguePrices &&
        getConfigAPIResponse?.data?.config?.displayCataloguePrices !== 'false'
      ) {
        return true;
      }
    }
    return false;
  }, [getConfigAPIResponse]);

  const getOrder = async () => {
    if (isPoFile) {
      if (fromScreen && fromScreen !== 'campaign') {
        // API used for manual orders
        getPoDetailsWithIdAPI({
          poFileId: orderId,
          workspaceId: seller?.workspaceId,
          params: {
            customerId: inviteId,
            includePromotionData: true,
            sellerWorkspaceId: seller?.workspaceId,
            // This is useless as we are always creating WApp order for Campaign
            // campaignType: campaignType,
          },
        });
      } else {
        await fetchPoFile({
          workspaceId: seller?.workspaceId,
          body: {
            includeInvoice: true,
            includeTax: true,
            pofileId: String(orderId),
            sortBy: 'sequence',
            sortDirection: 'ASC',
            customerId: inviteId,
            includePromotionData: true,
          },
        });
      }
    } else {
      fetchOrder({
        workspaceId: seller?.workspaceId,
        orderId: Number(orderId),
        body: {
          includeInvoice: true,
          includeTax: true,
          includeCustomer: true,
          includePromotions: true,
          customerId: inviteId,
          includePromotionData: true,
        },
      });
    }
  };

  const getOrderAfterEdit = async () => {
    if (isPoFile) {
      if (fromScreen && fromScreen !== 'campaign') {
        // API used for manual orders
        getPoDetailsWithIdAPIAfterEdit({
          poFileId: orderId,
          workspaceId: seller?.workspaceId,
          params: {
            customerId: inviteId,
            includePromotionData: true,
            sellerWorkspaceId: seller?.workspaceId,
            // This is useless as we are always creating WApp order for Campaign
            // campaignType: campaignType,
          },
        });
      } else {
        await fetchPoFileAfterEdit({
          workspaceId: seller?.workspaceId,
          body: {
            includeInvoice: true,
            includeTax: true,
            pofileId: String(orderId),
            sortBy: 'sequence',
            sortDirection: 'ASC',
            customerId: inviteId,
            includePromotionData: true,
          },
        });
      }
    } else {
      fetchOrderAfterEdit({
        workspaceId: seller?.workspaceId,
        orderId: Number(orderId),
        body: {
          includeInvoice: true,
          includeTax: true,
          includeCustomer: true,
          includePromotions: true,
          customerId: inviteId,
          includePromotionData: true,
        },
      });
    }
  };

  const onConfirm = () => {
    if (data?.poFileId && data?.importSource === 'manual') {
      checkout({
        sellerWorkspaceId: seller?.workspaceId,
        customerId: inviteId,
        poFileIds: [data?.poFileId],
      });
    } else {
      notifyToBuyer({
        sellerWorkspaceId: seller?.workspaceId,
        poFileId: data?.poFileId,
        customerId: inviteId,
      });
    }
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  useEffect(() => {
    if (addToCartAPIResponse?.isSuccess) {
      getOrderAfterEdit();
    }
  }, [addToCartAPIResponse]);

  useEffect(() => {
    if (editOrderResponse?.isSuccess) {
      getOrderAfterEdit();
    } else if (editOrderResponse?.isError) {
      if (
        (editOrderResponse?.error as any)?.data?.message?.message ===
        'Quantity cannot be below the Minimum Order Quantity (MOQ)'
      ) {
        setData((prevState) => {
          let lines = prevState?.lines?.map((line) => {
            if (
              line?.productVariantId ===
              (editOrderResponse?.error as any)?.data?.message?.body?.lines?.[0]
                ?.productVariantId
            ) {
              return {
                ...line,
                moqError: true,
              };
            }
            return line;
          });
          return {
            ...prevState,
            lines: lines,
          };
        });
      }
    }
  }, [editOrderResponse]);

  useEffect(() => {
    if (fetchOrderResponse?.isSuccess && fetchOrderResponse?.data) {
      if (isEmpty(fetchOrderResponse?.data)) {
        setData(undefined);
      } else {
        const data = extractDataFromOrder(fetchOrderResponse?.data);
        setData(data);
      }
    }
  }, [fetchOrderResponse]);

  useEffect(() => {
    if (fetchPoFileResponse?.isSuccess && fetchPoFileResponse?.data) {
      if (isEmpty(fetchPoFileResponse?.data)) {
        setData(undefined);
      } else {
        const data = extractDataFromPO(fetchPoFileResponse?.data);
        setData(data);
      }
    }
  }, [fetchPoFileResponse]);

  useEffect(() => {
    if (
      fetchOrderAfterEditResponse?.isSuccess &&
      fetchOrderAfterEditResponse?.data
    ) {
      if (isEmpty(fetchOrderAfterEditResponse?.data)) {
        setData(undefined);
      } else {
        const data = extractDataFromOrder(fetchOrderAfterEditResponse?.data);
        setData(data);
      }
    }
  }, [fetchOrderAfterEditResponse]);

  useEffect(() => {
    if (
      fetchPoFileAfterEditResponse?.isSuccess &&
      fetchPoFileAfterEditResponse?.data
    ) {
      if (isEmpty(fetchPoFileAfterEditResponse?.data)) {
        setData(undefined);
      } else {
        const data = extractDataFromPO(fetchPoFileAfterEditResponse?.data);
        setData(data);
      }
    }
  }, [fetchPoFileAfterEditResponse]);

  useEffect(() => {
    if (notifyToBuyerResponse?.isSuccess) {
      let skuCount = data?.lines?.length;
      navigate(
        `${WEBROUTES.ORDER_SUCCESSFULL}?skuCount=${skuCount}&successType=requirement`
      );
    }
    if (checkoutResponse?.isSuccess) {
      let skuCount = data?.lines?.length;
      navigate(
        `${WEBROUTES.ORDER_SUCCESSFULL}?skuCount=${skuCount}&successType=order`
      );
    }
  }, [notifyToBuyerResponse, checkoutResponse]);

  useEffect(() => {
    if (
      getPoDetailsWithIdAPIResponse?.isSuccess &&
      getPoDetailsWithIdAPIResponse?.data
    ) {
      if (isEmpty(getPoDetailsWithIdAPIResponse?.data?.lines)) {
        setData(undefined);
      } else {
        const data = extractDataFromPOForManual(
          getPoDetailsWithIdAPIResponse?.data
        );
        setData(data);
      }
    }
  }, [getPoDetailsWithIdAPIResponse]);

  useEffect(() => {
    if (
      getPoDetailsWithIdAPIAfterEditResponse?.isSuccess &&
      getPoDetailsWithIdAPIAfterEditResponse?.data
    ) {
      if (isEmpty(getPoDetailsWithIdAPIAfterEditResponse?.data?.lines)) {
        setData(undefined);
      } else {
        const data = extractDataFromPOForManual(
          getPoDetailsWithIdAPIAfterEditResponse?.data
        );
        setData(data);
      }
    }
  }, [getPoDetailsWithIdAPIAfterEditResponse]);

  // Function to clear errors every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.lines?.some((item) => item?.moqError === true)) {
        setData((prevItems) => {
          let lines = prevItems?.lines?.map((line) => {
            return {
              ...line,
              moqError: false,
            };
          });
          return {
            ...prevItems,
            lines: lines,
          };
        });
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [data]);

  useEffect(() => {
    getConfigAPI({
      workspaceId: seller?.workspaceId,
      module: ConfigModule.CATALOGUE,
      rollback: false,
    });
  }, []);

  // useEffect(() => {
  //   if (editOrderResponse?.isLoading) {
  //     const pvid =
  //       editOrderResponse?.originalArgs?.body?.lines?.[0]?.productVariantId;
  //     if (pvid) {
  //       setProductsList((prevState) => {
  //         return prevState?.map((i) => {
  //           if (i?.productVariantId === pvid) {
  //             return {
  //               ...i,
  //               updatingQty: true,
  //             };
  //           }
  //           return i;
  //         });
  //       });
  //     }
  //   } else {
  //     setProductsList((prevState) => {
  //       return prevState?.map((i) => {
  //         return {
  //           ...i,
  //           updatingQty: false,
  //         };
  //       });
  //     });
  //   }
  // }, [editOrderResponse]);

  useEffect(() => {
    return () => {
      console.log('unmounting order details screen');
    };
  }, []);

  const showOrderEditScreen = useMemo(() => {
    if (data?.status === OrderStatusEnum.ADDING_ITEMS) {
      return true;
    }
    return (
      (data?.status === OrderStatusEnum.ORDER_CREATED ||
        data?.status?.toLowerCase()?.includes('submitted')) &&
      !['C', 'Customer'].includes(data?.userType)
    );
  }, [data]);

  if (
    fetchOrderResponse?.isLoading ||
    fetchPoFileResponse?.isLoading ||
    getPoDetailsWithIdAPIResponse?.isLoading ||
    getConfigAPIResponse?.isFetching ||
    getConfigAPIResponse?.isLoading
  ) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  if (
    data === undefined &&
    (fetchOrderResponse?.isSuccess ||
      fetchPoFileResponse?.isSuccess ||
      getPoDetailsWithIdAPIResponse?.isSuccess)
  ) {
    return <OrderDetailsEmptyCart />;
  }

  return showOrderEditScreen ? (
    <>
      <OrderEditScreenLayout
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        onValueChange={onValueChange}
        orderData={data}
        onConfirm={onConfirm}
        onDelete={onDelete}
        handleAddProductFromSearch={handleAddProductFromSearch}
        handleReplaceProductFromSearch={handleReplaceProductFromSearch}
        setSelectedOrderLineId={setSelectedOrderLineId}
        onChangeQuantity={onChangeQuantity}
        isEditing={
          editOrderResponse?.isLoading || addToCartAPIResponse?.isLoading
        }
        showProductImage={showImage}
        showPrice={showPrice}
      />
      {/* <ToastComponent onDismiss={onDismiss} /> */}
    </>
  ) : (
    <OrderDetailsScreenLayout orderData={data} showProductImage={showImage} />
  );
};
