import { z } from 'zod';

export const campaignSchema = z.object({
  description: z.string(),
  sellerName: z.string(),
  assetUrl: z.string(),
  products: z.array(z.any()),
  type: z.string(),
  id: z.string(),
  name: z.string(),
});
