export const colors = {
  primary: {
    light: '#6200EE',
    dark: '#648DAE',
    description: 'primary color for your app, usually your brand color',
  },
  accent: {
    light: '#03DAC4',
    dark: '#388E3C',
    description:
      'secondary color for your app which complements the primary color',
  },
  disabledGrey: {
    color: '#939395',
  },
  background: {
    color: '#21222B',
    description: 'background color for pages, such as lists',
  },
  lightGrey: {
    color: '#9295A5',
  },
  lightvioletLineargradient: {
    firstcolor: 'rgba(163, 115, 190, 0.4)',
    secondcolor: '#232630',
  },
  purpleLinearGradient: {
    firstcolor: '#7856DF',
    secondcolor: '#2A00B0',
  },
  pinkLinearGradient: {
    firstcolor: '#9A71C3',
    secondcolor: '#FF8787',
  },
  blackLinearGradient: {
    firstcolor: '#21222B',
    secondcolor: '#000000',
  },
  zonoRosePink: {
    color: '#FFCBCB',
  },
  redLinearGradient: {
    firstRedColor: '#E85B49',
    secondRedColor: '#CE4837',
  },
  greenLinearGradient: {
    firstGreenColor: '#00B778',
    secondGreenColor: '#4BCBA0',
  },
  yellowLinearGradient: {
    firstYellowColor: '#D57F35',
    secondYellowColor: '#E67F26',
  },
  primaryButtonBorderColor: {
    color: '#FFCBCB',
  },
  cardShadowColor: {
    // color: '#00000040'
    color: '#000000',
  },
  secondaryButtonBackgroundColor: {
    color: '#000000',
  },
  teritiaryButtonBackgroundColor: {
    firstcolor: '#CA3438',
    secondcolor: '#8645D8',
    // thirdcolor: '#9932cc'
  },
  tertiaryInputBorderColor: {
    color: '#C4C4C4',
  },
  darkGreyBorderColor: {
    color: '#34353E',
  },
  zonoWhite: {
    color: '#FFFFFF',
    opacity5: 'rgba(255, 255, 255, 0.05)',
    opacity50: 'rgba(255, 255, 255, 0.5)',
    opacity60: 'rgba(255, 255, 255, 0.6)',
    opacity70: 'rgba(255, 255, 255, 0.7)',
    opacity80: 'rgba(255, 255, 255, 0.8)',
    opacity90: 'rgba(255, 255, 255, 0.9)',
    opacity10: 'rgba(255, 255, 255, 0.1)',
  },
  zono50OpacityWhite: {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  rippleEffectColor: {
    color: '#FFFFFF',
  },
  textLinearGradient: {
    firstcolor: '#FA8B8C',
    secondcolor: '#A6DEFE',
    thirdcolor: '#2B9AD9',
  },
  surface: {
    light: '#FFFFFF',
    dark: '#121212',
    description:
      'background color for elements containing content, such as cards',
  },
  text: {
    light: '#000000',
    dark: '#FFFFFF',
    description: 'text color for content',
  },
  disabled: {
    light: '#00000042',
    dark: '#FFFFFF42',
    description: 'color for disabled elements',
  },
  placeholder: {
    light: '#00000042',
    dark: '#FFFFFF42',
    description: 'color for placeholder text, such as input placeholder',
  },
  backdrop: {
    light: '#00000080',
    dark: '#FFFFFF80',
    description: 'color for backdrops of various components such as modals',
  },
  onSurface: {
    light: '#000000',
    dark: '#FFFFFF80',
    description: 'background color for snackbars',
  },
  notification: {
    light: '#F50057',
    dark: '#F57C00',
    description: 'background color for badges',
  },
  termscolor: {
    green: '#64E6BA',
  },
  buttoncolor: {
    green: '#41A784',
  },
  christmasBrightGreen: {
    color: '#64E6BA',
  },
  cardColor: {
    color: '#21222B',
    zcolor: '#292A34',
  },
  textLinearGradientColor: {
    firstColor: '#CC3334',
    secondColor: '#8546DB',
    thirdColor: '#2B9AD9',
  },
  zonoRed: {
    color1: '#FF5851',
    color2: 'rgba(255, 63, 63, 0.8)',
    color3: '#D3393B',
  },
  zonoGrey: {
    color: '#A2A3A5',
    opacity80: 'rgba(162, 163, 165, 0.8)',
    color2: '#B6B9BE',
  },
  darkTintedGreyishBlackTabColor: {
    color: '#111319',
  },
  cardBackgrounds: {
    black: '#000000',
    grey: '#232630',
    darkgrey: '#181A21',
    blue: '#193040',
    grey2: '#292A34',
    darkerGrey: '#0000004F',
    grey40Opacity: 'rgba(58, 62, 75,0.4)',
    darkgrey70opacity: 'rgba(24, 26, 33, 0.7)',
    lightBlue: '#A7E0FF',
    violet: '#191038',
    navyblue: '#1C204A',
  },
  zonoBlack: {
    color: '#000000',
    opacity80: 'rgba(0, 0, 0, 0.8)',
  },
  containerColor: {
    color: '#3A3A3A',
  },
  heroColor: {
    color: '#143055',
  },
  buttonBorderColor: {
    greyColor: '#C7C7C7',
  },
  zonoGreen: {
    color: '#00D689',
  },
  cardShadow: {
    color: '#0000003B',
  },
  gradientButtonBorder: {
    color: '#1F3447',
  },
  labelColor: {
    grey: '#DBDBDB',
    grey100: '#202129',
  },
  tickColor: {
    green: '#41A784',
  },
  invoiceColors: {
    invNumber: '#DBDBDB',
  },
  downloadCardColor: {
    color: '#352567',
    cashColor: '#D6D6D6',
    buttonColor: '#17191F',
  },
  addbankaccountCard: {
    color: '#252631',
  },
  headingColor: {
    color: '#DBDBDB',
  },
  headerGradient: {
    firstColor: '#1C1D25',
    secondColor: '#1C1D2500',
  },
  zCoinsBackground: {
    color: '#30333E',
  },
  backButtonBorder: {
    color: '#535353',
  },
  tagColor: {
    purple: '#8F43C4',
    yellow: '#E6BE03',
  },
  rewardDarkBlue: {
    color: '#1D204A',
  },
  rewardLightGrey: {
    color: '#D5CCCC',
  },
  linkColor: {
    color: '#4A7EDA',
  },
  redeemcardColors: {
    firstColor: '#FF8787',
    secondColor: '#9A71C3',
  },
  promoCardColors: {
    firstColor: '#6AE5BB',
    secondColor: '#8645D8',
  },
};
