import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function ArrowBack(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 24 20"
      {...props}
    >
      <G
        data-name="Group 84084"
        transform="rotate(-135 9.805 7.824)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={3}
      >
        <Path data-name="Line 4050" d="M0 0L12.847 0" />
        <Path
          data-name="Line 4051"
          transform="translate(12.847)"
          d="M0 12.847L0 0"
        />
      </G>
    </Svg>
  );
}

export default ArrowBack;
