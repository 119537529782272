// Generated by ts-to-zod
import { z } from 'zod';
import { PnGcmMessageType } from './enum';

export const pnGcmMessageTypeSchema = z.nativeEnum(PnGcmMessageType);

const androidSchema = z.object({
  priority: z.string(),
  importance: z.string(),
});

const notificationSchema = z.object({
  body: z.string(),
  title: z.string(),
});

const addInfoSchema = z.object({
  channelName: z.string(),
  channelId: z.string(),
  emojiId: z.string().optional().nullable(),
});

const dataSchema = z.object({
  eventId: z.string(),
  customerName: z.string(),
  notification: notificationSchema,
  inviteId: z.string(),
  messageType: z.string(),
  addInfo: addInfoSchema,
  eventDate: z.string(),
});

export const pngcmSchema = z.object({
  android: androidSchema,
  data: dataSchema,
});
