import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  View,
  Dimensions,
} from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import Icon from 'react-native-easy-icon';
import { DropDownOptions } from '../types';
import { Text } from 'react-native';
import { isWeb } from '@zonofi/common';
import _, { min } from 'lodash';
import { Modal } from 'react-bootstrap';

/* eslint-disable-next-line */
export interface SearchDropDownProps {
  optionList: DropDownOptions[];
  initialValue?: DropDownOptions;
  disabled?: boolean;
  onSelect?: (id: string) => void;
  disableSearch?: boolean;
  backgroundColor?: string;
  borderless?: boolean;
  error?: string;
  disableActiveOptionSetting?: Array<string> | undefined;
  typeColumnValue?: Record<any, any>;
}

export const SearchDropDown: React.FC<SearchDropDownProps> = ({
  disabled = false,
  initialValue,
  optionList,
  onSelect,
  disableSearch = false,
  backgroundColor,
  borderless = false,
  error,
  disableActiveOptionSetting,
  typeColumnValue,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const DropdownButton = useRef(undefined) as any;
  const [focus, setFocus] = useState(false);
  const [dropdown, setDropdown] = useState({ top: 0, left: 0, width: 0 });
  const [searchKey, setSearchKey] = useState('');
  const [options, setOptions] = useState<DropDownOptions[]>(optionList);
  const [hoverId, setHoverId] = useState('');
  const [selectedItem, setSelectedItem] = useState<DropDownOptions>();
  const listHeight = useMemo(
    () => (min([options.length, 8]) || 1) * 32 + (disableSearch ? 0 : 70),
    [options.length, disableSearch]
  );

  useEffect(() => {
    if (
      (disabled || disableSearch) &&
      !selectedItem &&
      !_.isEmpty(optionList)
    ) {
      setSelectedItem(optionList[0]);
    }
  }, [disabled, optionList, disableSearch, selectedItem]);

  useEffect(() => {
    if (initialValue) {
      setSelectedItem(initialValue);
    }
  }, [initialValue]);

  const openDropdown = (): void => {
    if (DropdownButton && DropdownButton.current) {
      const windowHeight = Dimensions.get('window').height;

      DropdownButton?.current?.measure(
        (
          _fx: number,
          _fy: number,
          w: number,
          h: number,
          px: number,
          py: number
        ) => {
          if (windowHeight > py + h + listHeight) {
            setDropdown({ top: py + h, left: px, width: w });
          } else {
            setDropdown({
              top: py - listHeight,
              left: px,
              width: w,
            });
          }
        }
      );
    }
    setFocus(true);
  };

  const toggleDropdown = (): void => {
    focus ? setFocus(false) : openDropdown();
  };

  const onItemPress = (id: string) => {
    const item = optionList.find((x) => x.id === id);
    setSelectedItem(item);
    if (onSelect) {
      onSelect(id);
    }
    toggleDropdown();
  };

  useEffect(() => {
    let searchOptionList: DropDownOptions[] = optionList;
    if (searchKey.length > 2) {
      searchOptionList = optionList.filter((x) =>
        x.mainText.toLowerCase().includes(searchKey.toLowerCase())
      );
    }

    if (disableActiveOptionSetting && typeColumnValue) {
      if (
        disableActiveOptionSetting.includes(
          typeColumnValue?.['selectedValue']?.id
        )
      ) {
        searchOptionList = optionList.filter(
          (item) => item.mainText === 'Rejected'
        );
      }
    }
    setOptions(searchOptionList);
  }, [optionList, searchKey, disableActiveOptionSetting, typeColumnValue]);

  const renderItem = (item: DropDownOptions) => {
    const textStyle = item.textStyle ?? {
      ...theme.fontConfig.regular,
      fontSize: 16,
      color: theme.text.primary,
    };
    return isWeb() ? (
      <div
        style={{
          display: 'flex',
          flex: 1,
          backgroundColor:
            hoverId === item.id
              ? theme.dropDown.borderColor
              : theme.dropDown.background,
          padding: 5,
          cursor: 'pointer',
          borderRadius: 6,
          flexDirection: 'row',
          alignItems: 'center',
        }}
        onMouseEnter={() => setHoverId(item.id)}
        onMouseLeave={() => setHoverId('')}
        onClick={() => onItemPress(item.id)}
      >
        {item?.icon}
        <Text style={textStyle}>{item.mainText}</Text>
      </div>
    ) : (
      <TouchableOpacity
        onPress={() => onItemPress(item.id)}
        style={{
          flex: 1,
          padding: 5,
          borderRadius: 6,
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        {item?.icon}
        <Text style={textStyle}>{item.mainText}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <View>
      <TouchableOpacity
        ref={DropdownButton}
        style={[
          styles.container,
          {
            borderWidth: borderless ? 0 : 1,
            borderColor: theme.dropDown.borderColor,
            backgroundColor: backgroundColor ?? theme.dropDown.background,
          },
        ]}
        disabled={disabled}
        onPress={toggleDropdown}
      >
        {selectedItem ? selectedItem?.icon : optionList[0]?.icon}
        <Text
          style={[
            {
              width: '90%',
            },
            (selectedItem
              ? selectedItem?.textStyle
              : optionList[0]?.textStyle) || {
              color: theme.text.primary,
              fontSize: 16,
              ...theme.fontConfig.regular,
              paddingVertical: 5,
            },
          ]}
        >
          {selectedItem ? selectedItem?.mainText : optionList[0]?.mainText}
        </Text>
        {!disabled ? (
          focus ? (
            <Icon
              type={'antdesign'}
              name={'caretup'}
              size={15}
              color={theme.dropDown.arrow}
              onPress={toggleDropdown}
              style={{ marginRight: 3 }}
            />
          ) : (
            <Icon
              type={'antdesign'}
              name={'caretdown'}
              size={15}
              color={theme.dropDown.arrow}
              onPress={toggleDropdown}
              style={{ marginRight: 3 }}
            />
          )
        ) : null}
      </TouchableOpacity>
      {error && error?.length && (
        <Text
          style={{
            ...theme.fontConfig.regular,
            fontSize: 9,
            color: theme.status.danger,
          }}
        >
          {error}
        </Text>
      )}
      <Modal
        show={focus}
        onHide={toggleDropdown}
        style={{
          backgroundColor: theme.dropDown.background,
          top: dropdown.top,
          left: dropdown.left,
          width: dropdown.width,
          maxHeight: listHeight,
          position: 'absolute',
          borderRadius: 6,
          borderTopLeftRadius: 0,
          boxShadow: '0px 0px 10px #00000040',
          zIndex: 9999,
        }}
      >
        <FlatList
          data={options}
          renderItem={({ item }) => renderItem(item)}
          scrollEnabled={true}
          nestedScrollEnabled={true}
          style={{ height: '100%' }}
          contentContainerStyle={{ height: listHeight }}
          ListHeaderComponent={
            !disableSearch ? (
              <View style={styles.searchContainer}>
                <Icon
                  type="ionicon"
                  name="search-sharp"
                  color={theme.input.placeHolder}
                  size={16}
                  style={{ marginRight: 4 }}
                />
                <TextInput
                  placeholder="Search"
                  value={searchKey}
                  onChangeText={(text) => setSearchKey(text)}
                  placeholderTextColor={theme.input.placeHolder}
                  style={{
                    width: '90%',
                    color: theme.text.primary,
                    fontSize: 16,
                    ...theme.fontConfig.regular,
                    paddingVertical: 5,
                  }}
                />
              </View>
            ) : null
          }
        />
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 5,
    borderRadius: 4,
    minHeight: 32,
  },
  list: {
    maxHeight: hp(30),
    position: 'absolute',
    width: '100%',
    borderRadius: 6,
    borderTopLeftRadius: 0,
    shadowColor: '#000000',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
    zIndex: 9999,
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchContainer: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default SearchDropDown;
