import { fontConfig } from '../fonts/fonts';
import { colors } from '../colors/colors';
import _ from 'lodash';
import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { heightPercentageToDPBoundsViewPort as hvp } from '../helpers';

/* eslint-disable-next-line */
export interface EmptyCheckWrapperProps {
  text?: string;
  Component: React.ReactNode;
  check: boolean;
  emptyView?: React.ReactNode;
}

export const EmptyCheckWrapper: React.FC<EmptyCheckWrapperProps> = ({
  text,
  check,
  Component,
  emptyView,
}) => {
  return check ? (
    emptyView ? (
      <React.Fragment>{emptyView}</React.Fragment>
    ) : (
      <Text style={styles.empty}>{text}</Text>
    )
  ) : (
    <React.Fragment>{Component}</React.Fragment>
  );
};

const styles = StyleSheet.create({
  empty: {
    ...fontConfig.default.regular,
    fontSize: 18,
    color: colors.white.color,
    alignItems: 'center',
    justifyContent: 'center',
    padding: hvp(20),
  },
});
