import * as React from 'react';
import Svg, { G, Path, Circle } from 'react-native-svg';

function ClockIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={15}
      height={15}
      viewBox="0 0 32 32"
      {...props}
    >
      <G fill="#FFF">
        <Path d="M16 32c8.822 0 16-7.178 16-16S24.822 0 16 0 0 7.178 0 16s7.178 16 16 16zm0-31c8.271 0 15 6.729 15 15s-6.729 15-15 15S1 24.271 1 16 7.729 1 16 1z" />
        <Path d="M20.061 21.768a.498.498 0 00.708 0 .5.5 0 000-.707L16 16.293V9.319a.5.5 0 00-1 0V16.5c0 .133.053.26.146.354l4.915 4.914z" />
        <Circle cx={4} cy={16} r={1} />
        <Circle cx={28} cy={16} r={1} />
        <Circle cx={16} cy={4} r={1} />
        <Circle cx={16} cy={28} r={1} />
        <Circle cx={8} cy={8} r={1} />
        <Circle cx={24} cy={24} r={1} />
        <Circle cx={25} cy={8} r={1} />
        <Circle cx={8} cy={24} r={1} />
      </G>
    </Svg>
  );
}

export default ClockIcon;
