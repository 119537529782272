import React from 'react';
import './product-image.scss';
import { SHARED_SVGS } from '../assets';
import { useState, useLayoutEffect } from 'react';

interface ProductImageProps {
  productImage: string;
}

export const ProductImage: React.FC<ProductImageProps> = ({ productImage }) => {
  const [imageSrc, setImageSrc] = useState(SHARED_SVGS.DefaultProduct);
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    setIsLoading(true);
    if (productImage === '') {
      setIsLoading(false);
      return;
    }
    if (productImage) {
      getModifiedImageUrl(productImage)
        .then(setImageSrc)
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [productImage]);

  const checkImageExists = async (url: string): Promise<boolean> => {
    return true;
    try {
      const response = await fetch(url, {
        method: 'HEAD',
        cache: 'no-cache',
      });
      return (
        response.ok &&
        (response.headers.get('Content-Type')?.startsWith('image/') ?? false)
      );
    } catch {
      return false;
    }
  };

  const getModifiedImageUrl = async (url: string) => {
    if (!url || !url.trim()) return SHARED_SVGS.DefaultProduct;

    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('width', '40');
      urlObj.searchParams.set('height', '40');
      const modifiedUrl = urlObj.toString();

      const imageExists = await checkImageExists(modifiedUrl);
      if (!imageExists) {
        console.warn(`Image not found or invalid: ${modifiedUrl}`);
        return SHARED_SVGS.DefaultProduct;
      }
      return modifiedUrl;
    } catch (error) {
      console.warn(`Invalid image URL: ${url}`, error);
      return SHARED_SVGS.DefaultProduct;
    }
  };

  return (
    <img
      src={imageSrc}
      className="product_item_default_img"
      alt="Product"
      onError={(e) => {
        console.warn(`Image failed to load: ${imageSrc}`);
        e.currentTarget.onerror = null;
        e.currentTarget.src = SHARED_SVGS.DefaultProduct;
      }}
    />
  );
};
