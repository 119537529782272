import { CreatePaymentResponseWeb } from '.';
import { zonoApi } from '../api';
import {
  CreateCFOrderRequest,
  CreateCFOrderResponse,
  CreatePaymentRequest,
  CreatePaymentResponse,
  FieldCollectionPaymentRequestParams,
  FieldCollectionPayments,
  GetPaymentResponse,
  PaymentCollectionReportsRequestParams,
  PaymentCollectionReportsResponse,
  PaymentHistory,
  PaymentLinkResponse,
  PaymentSettingsResponse,
  RecordPaymentRequest,
  RecordPaymentResponse,
  UPIIdResponse,
} from './payment-model';
import axios from 'axios';
import { getToken } from '../user-auth';
import { store } from '../root/ReduxStore.store';
import { getConfigValue } from '@zonofi/common';

export const paymentApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    createPayment: builder.mutation<
      CreatePaymentResponse,
      CreatePaymentRequest
    >({
      query: (CreatePaymentRequest) => {
        return {
          url: 'invoiceservice/payments/paymentrequest/token',
          body: CreatePaymentRequest,
          method: 'POST',
        };
      },
      invalidatesTags: ['Payment'],
    }),
    createPaymentWithOutToken: builder.mutation<
      CreatePaymentResponseWeb,
      { CreatePaymentRequest: CreatePaymentRequest; env?: string }
    >({
      query: (arg) => {
        const { CreatePaymentRequest, env } = arg;
        return {
          url: 'invoiceservice/payments/paymentrequest',
          body: CreatePaymentRequest,
          params: { env },
          method: 'POST',
        };
      },
      invalidatesTags: ['Payment'],
    }),
    getGuestPayment: builder.query<GetPaymentResponse, string>({
      query: (id) => ({
        url: `invoiceservice/guest/payments/paymentrequest`,
        method: 'GET',
        params: { paymentRequestId: id },
      }),
      providesTags: ['Payment'],
    }),
    getPayment: builder.query<GetPaymentResponse, string>({
      query: (id) => ({
        url: `invoiceservice/payments/paymentrequest`,
        method: 'GET',
        params: { paymentRequestId: id },
      }),
      providesTags: ['Payment'],
    }),
    getUPIId: builder.query<UPIIdResponse, string>({
      query: (id) => ({
        url: `invoiceservice/party/vaccount`,
        method: 'GET',
        params: { inviteId: id },
      }),
      providesTags: ['Payment'],
    }),
    getPaymentHistory: builder.query<PaymentHistory, string>({
      query: (id) => ({
        url: `invoiceservice/payments`,
        method: 'GET',
        params: { sellerWorkspaceId: id },
      }),
    }),
    createCFOrder: builder.mutation<
      CreateCFOrderResponse,
      CreateCFOrderRequest
    >({
      query: (CreateCFOrderRequest) => {
        return {
          url: 'https://api.cashfree.com/pg/orders/pay',
          body: CreateCFOrderRequest,
          method: 'POST',
        };
      },
    }),
    RecordPayment: builder.mutation<
      RecordPaymentResponse,
      RecordPaymentRequest
    >({
      query: (RecordPaymentRequest) => {
        return {
          url: 'invoiceservice/payments',
          body: RecordPaymentRequest,
          method: 'POST',
        };
      },
    }),
    getPaymentsByTeamMember: builder.mutation<
      FieldCollectionPayments,
      FieldCollectionPaymentRequestParams
    >({
      query: (FieldCollectionPaymentRequestParams) => ({
        url: `invoiceservice/reports/payments/summary`,
        method: 'GET',
        params: FieldCollectionPaymentRequestParams,
      }),
    }),
    getPaymentsReports: builder.mutation<
      PaymentCollectionReportsResponse,
      PaymentCollectionReportsRequestParams
    >({
      query: (FieldCollectionPaymentRequestParams) => ({
        url: `invoiceservice/reports/payments`,
        method: 'GET',
        params: FieldCollectionPaymentRequestParams,
      }),
    }),
    getPaymentSettings: builder.query<
      PaymentSettingsResponse,
      { sellerWorkSpaceId: string }
    >({
      query: (params) => ({
        url: `invoiceservice/settings/paymentlink/${params?.sellerWorkSpaceId}`,
        method: 'GET',
      }),
    }),
    getPaymentLink: builder.query<
      PaymentLinkResponse,
      { inviteId: string; workspaceId: string }
    >({
      query(args) {
        const { inviteId, workspaceId } = args;
        return {
          url: `invoiceservice/party/shortlink/${workspaceId}`,
          method: 'GET',
          params: { inviteId },
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const downloadRecieptOnWeb = (paymentId: string) => {
  const accessToken = getToken(store.getState());
  const baseUrl = getConfigValue('REQUEST_BASE_URL');
  axios
    .get(
      `${baseUrl}invoiceservice/guest/payments/download-receipt?paymentId=${paymentId}`,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      const URL = window.URL || window.webkitURL;
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.setAttribute('download', `${paymentId}_Reciept.pdf`);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      URL.revokeObjectURL(url);
    });
};

export const {
  useCreatePaymentMutation,
  useLazyGetPaymentQuery,
  useCreatePaymentWithOutTokenMutation,
  useLazyGetPaymentHistoryQuery,
  useLazyGetUPIIdQuery,
  useCreateCFOrderMutation,
  useRecordPaymentMutation,
  useGetPaymentsByTeamMemberMutation,
  useGetPaymentsReportsMutation,
  useLazyGetPaymentSettingsQuery,
  useLazyGetPaymentLinkQuery,
  useGetPaymentLinkQuery,
  useLazyGetGuestPaymentQuery,
} = paymentApi;
