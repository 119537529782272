import { TouchableOpacity, View, Text } from 'react-native';
import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

interface TexWithImageProps {
  title: string;
  image: any;
  navigate?: () => void;
  isHyperLink?: boolean;
}

//TODO : REVAMPED
export const TexWithImage: React.FC<TexWithImageProps> = ({
  title,
  image,
  navigate,
  isHyperLink,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  return (
    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
      <Text
        onPress={() => navigate && navigate()}
        style={{
          ...(isHyperLink
            ? {
                color: theme.table.underline,
                fontFamily: theme.fontConfig.regular.fontFamily,
                textDecorationLine: 'underline',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
              }
            : {
                color: theme.text.primary,
                fontFamily: theme.fontConfig.regular.fontFamily,
              }),
        }}
        nativeID="generic-table-cell-text"
      >
        {title}
      </Text>

      {image}
    </View>
  );
};
