// Generated by ts-to-zod
import { z } from 'zod';

const formatTypeSchema = z.union([z.literal('IMAGE'), z.literal('DOCUMENT')]);

export const parameterTypeSchema = z.union([
  z.literal('image'),
  z.literal('text'),
  z.literal('payload'),
  z.literal('document'),
]);

const exampleSchema = z.object({
  header_handle: z.array(z.string()).optional(),
  body_text: z.array(z.array(z.string())).optional(),
});

const buttonSchema = z.object({
  url: z.string().optional(),
  text: z.string(),
  type: z.string(),
  example: z.array(z.string()).optional(),
});

const fileSchema = z.object({
  link: z.string(),
  filename: z.string().optional(),
});

export const componentSchema = z.object({
  type: z.string(),
  example: exampleSchema.optional(),
  format: formatTypeSchema.optional(),
  text: z.string().optional(),
  buttons: z.array(buttonSchema).optional(),
});

export const parameterSchema = z.object({
  text: z.coerce.string().optional(),
  type: parameterTypeSchema,
  payload: z.string().optional(),
  image: fileSchema.optional(),
  document: fileSchema.optional(),
});

const paramsSchema = z.object({
  type: z.string(),
  parameters: z.array(parameterSchema),
  index: z.string().optional(),
  sub_type: z.string().optional(),
});

export const templateParams = z.array(paramsSchema);

export const templateComponents = z.array(componentSchema);

export const templateSchema = z.object({
  params: templateParams,
  components: templateComponents,
});

export const templateSchemaV2 = z.object({
  components: z.array(
    z.object({
      type: z.string(),
      parameters: z.array(parameterSchema).optional(),
      buttons: z.array(buttonSchema).optional(),
    })
  ),
  name: z.string(),
  language: z.object({
    code: z.string(),
  }),
});

export const transformSchema = templateSchemaV2.transform((template) => {
  const { name, components } = template;

  const updatedComponents = components.map(({ type, parameters, buttons }) => {
    const textParameters =
      parameters?.filter(({ type }) => type === 'text') || [];
    const imageParameters =
      parameters?.filter(({ type }) => type === 'image') || [];
    const docParameters =
      parameters?.filter(({ type }) => type === 'document') || [];

    return {
      type: type.toUpperCase(),
      buttons,
      text: textParameters.map((_, index) => `{{${index + 1}}}`).join(' '),
      format:
        imageParameters?.length > 0
          ? 'IMAGE'
          : docParameters?.length > 0
          ? 'DOCUMENT'
          : undefined,
    };
  });

  const params = components.map(({ type, parameters = [] }) => ({
    type,
    parameters,
  }));

  return { [name]: { components: updatedComponents, params } };
});

export const noIntentTemplateSchema = z.record(
  z.string(),
  z.object({
    url: z.string(),
    caption: z.string(),
    filename: z.string().optional(),
  })
);

export const messageTemplateSchema = z.record(z.string(), templateSchema);

export const mappedTemplateSchema = z.array(
  z.object({
    type: z.string(),
    format: formatTypeSchema.optional(),
    text: z.string().optional(),
    parameters: z.array(parameterSchema),
  })
);
