import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function LedgerIcon({ color = '#a1a3b4', width = 20.403, height = 19.874 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.403 19.874"
    >
      <Path
        data-name="Path 33279"
        d="M17.164 4.891l-7.227 7.227v1.433h1.433l7.227-7.227zm-13.55.529a.903.903 0 000-1.807v-.9a.9.9 0 01.9-.9h9.937a.9.9 0 01.9.9v1.433l-6.7 6.7a1.808 1.808 0 00-.529 1.277v3.239h3.239a1.808 1.808 0 001.277-.529l2.71-2.71v5.046a.9.9 0 01-.9.9H4.511a.9.9 0 01-.9-.9v-.9a.903.903 0 100-1.807V10.84a.903.903 0 100-1.807zM1.807 2.71A2.71 2.71 0 014.517 0h9.937a2.71 2.71 0 012.71 2.71v.374a1.8 1.8 0 011.277.529l1.433 1.433a1.806 1.806 0 010 2.555l-2.71 2.71v6.853a2.71 2.71 0 01-2.71 2.71H4.517a2.71 2.71 0 01-2.71-2.71v-.9h-.9a.903.903 0 110-1.807h.9V10.84h-.9a.903.903 0 110-1.807h.9V5.42h-.9a.903.903 0 010-1.807h.9z"
        fill={color}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default LedgerIcon;
