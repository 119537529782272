import { useRef, useEffect } from 'react';

import { isEmpty } from 'lodash';

export const useComponentDidUpdate = (
  effect: () => void,
  dependencyArray: any[]
) => {
  const isMounted = useRef(true);

  useEffect(() => {
  
    if(!Array.isArray(dependencyArray)) {
    throw new Error("dependency must be an array");
   }

    if (isMounted.current) {
      // do componentDidMount logic
      isMounted.current = false;
    } else if (!isEmpty(dependencyArray)) {
      // do componentDidUpdate logic
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyArray);
};
