import { zonoApi } from '../api';
import { ConfigDetailsRequest, ConfigDetailsResponse } from './config-model';

export const configApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfigDetails: builder.query<
      ConfigDetailsResponse,
      ConfigDetailsRequest
    >({
      query(arg) {
        return {
          url: `configsettings/config`,
          params: arg,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useLazyGetConfigDetailsQuery } = configApi;
