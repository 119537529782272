import _ from 'lodash';
import React from 'react';

export const usePagination = () => {
  const [config, setConfig] = React.useState({
    noMoreData: false,
    paginatedLoading: false,
    loading: false,
  });
  const [page, setPage] = React.useState(1);

  const paginate = <
    T extends { isLoading: boolean; isSuccess: boolean; isError: boolean }
  >(
    response: T,
    data: any,
    parseData: () => void
  ) => {
    if (response) {
      if (response.isLoading) {
        if (page === 1) {
          setConfig({ ...config, loading: true });
        } else {
          setConfig({ ...config, paginatedLoading: true });
        }
      } else if (response.isSuccess) {
        setConfig({ ...config, loading: false, paginatedLoading: false });
        if (!_.isEmpty(data)) {
          parseData();
        } else {
          const updated = {
            ...config,
            paginatedLoading: false,
            noMoreData: true,
          };
          setConfig(updated);
        }
      } else if (response.isError) {
        setConfig({ ...config, loading: false, paginatedLoading: false });
      }
    }
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const stopPagination = () => {
    const updated = {
      ...config,
      paginatedLoading: false,
    };
    setConfig(updated);
  };
  return {
    paginate,
    config: config,
    pageNo: page,
    nextPage,
    stopPagination,
  };
};
