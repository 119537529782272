import * as React from 'react';
import Svg, { G, Path, Text, TSpan } from 'react-native-svg';

function PaymentsMethod({ iconView }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconView.width}
      height={iconView.height}
      viewBox="0 0 47.893 33.065"
    >
      <G data-name="Payment Method" fill="#fff">
        <Path
          data-name="Path 2013"
          d="M1662.348 2425.255a.3.3 0 00-.3-.289l-.432.005-.006-.42a.3.3 0 00-.6.008l.006.42h-.431a.292.292 0 10.008.583l.432-.005.005.419a.3.3 0 00.6-.007l-.006-.42.431-.006a.294.294 0 00.293-.288z"
          transform="translate(-1624.812 -2362.409) translate(-16.935 -30.794)"
        />
        <Path
          data-name="Path 2014"
          d="M1704.45 2367.841a.345.345 0 00-.337-.35l-.5-.016.016-.49a.35.35 0 00-.7-.022l-.016.49-.5-.017a.341.341 0 10-.023.681l.5.016-.016.49a.35.35 0 00.7.022l.016-.49.5.016a.347.347 0 00.36-.33z"
          transform="translate(-1624.812 -2362.409) translate(-36.412 -2.257)"
        />
        <Path
          data-name="Path 2015"
          d="M1629.089 2364.749a.346.346 0 00.363-.328l.016-.49.5.016a.341.341 0 10.022-.681l-.5-.016.016-.49a.35.35 0 00-.7-.022l-.016.49-.5-.016a.341.341 0 10-.023.681l.5.016-.016.49a.344.344 0 00.338.35z"
          transform="translate(-1624.812 -2362.409) translate(-1.623)"
        />
        <Path
          data-name="Path 2016"
          d="M1634.327 2399.814l.466-.186.192.454a.353.353 0 00.455.185.337.337 0 00.193-.444l-.191-.454.467-.186a.339.339 0 00.189-.445.355.355 0 00-.456-.186l-.466.187-.191-.454a.355.355 0 00-.458-.184.338.338 0 00-.19.443l.191.453-.466.187a.336.336 0 00-.191.443.353.353 0 00.456.187z"
          transform="translate(-1624.812 -2362.409) translate(-4.707 -17.747)"
        />
        <Path
          data-name="Path 2017"
          d="M1712.516 2419.524h-2.551a.466.466 0 100 .931h2.551a.466.466 0 100-.931z"
          transform="translate(-1624.812 -2362.409) translate(-40.29 -28.261)"
        />
        <Path
          data-name="Path 2018"
          d="M1673.391 2392.224a.52.52 0 00-.535-.5h-6.237a2.415 2.415 0 00.553-1.521v-5.748h3.3a2.09 2.09 0 002.145-2.024v-8.717a2.09 2.09 0 00-2.145-2.025h-3.3v-5.748a2.609 2.609 0 00-2.678-2.528h-16.432a2.609 2.609 0 00-2.678 2.528v15.55a6.269 6.269 0 00-1.054-.092 5.861 5.861 0 00-6.008 5.6 5.568 5.568 0 002.588 4.725h-3.8a.505.505 0 100 1.009h35.745a.52.52 0 00.536-.509zm-23.351-3.438h16.06v1.413a1.568 1.568 0 01-1.609 1.519h-16.432a1.7 1.7 0 01-.278-.024 5.67 5.67 0 002.258-2.908zm20.437-5.344h-13.987v-5.337h15.063v4.322a1.048 1.048 0 01-1.077 1.016zm1.076-6.346h-15.063v-1.89h15.063zm0-3.387v.488h-15.063v-1.5h13.987a1.048 1.048 0 011.075 1.013zm-15.063-2.025v-1a.535.535 0 00-1.069 0v14.758a.535.535 0 001.069 0v-1h9.614v3.326h-15.823a5.658 5.658 0 00-3.827-6.013v-13.406h19.65v3.326zm-8.428-7.266h16.432a1.568 1.568 0 011.609 1.519v1.413h-19.65v-1.413a1.568 1.568 0 011.608-1.518zm-8.671 22.587a4.9 4.9 0 016.371-4.4 4.713 4.713 0 013.5 4.51 4.644 4.644 0 01-2.774 4.138.579.579 0 00-.1.047 5.139 5.139 0 01-2.111.417 5.043 5.043 0 01-3.473-1.4 4.5 4.5 0 01-1.414-3.311z"
          transform="translate(-1624.812 -2362.409) translate(-6.13 -.535)"
        />
        <Path
          data-name="Path 2019"
          d="M1625.291 2420.455h2.577a.466.466 0 100-.931h-2.577a.466.466 0 100 .931z"
          transform="translate(-1624.812 -2362.409) translate(0 -28.261)"
        />
        <Path
          data-name="Path 2020"
          d="M1672.218 2368.25h2.787a.467.467 0 100-.933h-2.787a.467.467 0 100 .933z"
          transform="translate(-1624.812 -2362.409) translate(-22.316 -2.622)"
        />
        <Path
          data-name="Path 2021"
          d="M1667.78 2368.25h.547a.467.467 0 100-.933h-.547a.467.467 0 100 .933z"
          transform="translate(-1624.812 -2362.409) translate(-20.307 -2.622)"
        />
        <Path
          data-name="Path 2022"
          d="M1672.543 2416.5a.791.791 0 10-.811-.789.8.8 0 00.811.789z"
          transform="translate(-1624.812 -2362.409) translate(-22.42 -26.166)"
        />
        <Path
          data-name="Path 2023"
          d="M1692.963 2393.388a1.844 1.844 0 01-1.358 0 1.8 1.8 0 10.677 3.445 1.874 1.874 0 00.68.129 1.787 1.787 0 100-3.572zm-2.238 1.785a.88.88 0 11.88.856.869.869 0 01-.879-.856zm2.509.809a1.674 1.674 0 000-1.617.841.841 0 010 1.617z"
          transform="translate(-1624.812 -2362.409) translate(-30.793 -15.356)"
        />
        <Path
          data-name="Path 2024"
          d="M1679.98 2396.045h-1.7a.549.549 0 100 1.1h1.7a.549.549 0 100-1.1z"
          transform="translate(-1624.812 -2362.409) translate(-25.213 -16.783)"
        />
        <Path
          data-name="Path 2025"
          d="M1686.516 2396.045h-1.129a.549.549 0 100 1.1h1.129a.549.549 0 100-1.1z"
          transform="translate(-1624.812 -2362.409) translate(-28.636 -16.783)"
        />
        <Text
          data-name="\u20B9"
          transform="translate(-1624.812 -2362.409) translate(1635.884 2388.948)"
          fontSize={7}
          fontFamily="HelveticaNeue, Helvetica Neue"
        >
          <TSpan x={0} y={0}>
            {'\u20B9'}
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
}

export default PaymentsMethod;
