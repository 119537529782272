import React from 'react';
import { StyleProp, ViewStyle, ViewProps } from 'react-native';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';
import { colors } from '../colors/colors';
import LinearGradient from 'react-native-linear-gradient';

/* eslint-disable-next-line */
export interface ShimmerPlaceHolderProps {
  visible?: boolean;
  stopAutoRun?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  duration?: number;
  width?: number;
  shimmerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  shimmerColors?: string[];
  childrenContainerProps?: ViewProps;
}

export const ShimmerPlaceHolder: React.FC<ShimmerPlaceHolderProps> = ({
  visible,
  stopAutoRun,
  style,
  children,
  duration,
  width,
  shimmerStyle,
  shimmerColors = [
    colors.containerColor.color,
    colors.cardBackgrounds.darkgrey70opacity,
  ],
  contentStyle,
}) => {
  const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);
  return (
    <ShimmerPlaceholder
      style={style}
      visible={visible}
      duration={duration}
      width={width}
      shimmerColors={shimmerColors}
      shimmerStyle={shimmerStyle}
      contentStyle={contentStyle}
      location={[0, 1]}
      isInteraction={false}
    >
      {children}
    </ShimmerPlaceholder>
  );
};

export default ShimmerPlaceHolder;
