import React, { useContext, useState } from 'react';
import CustomPopUp from '../custom-pop-up/custom-pop-up';
import { TouchableOpacity, View } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';

/* eslint-disable-next-line */
export interface HoverablePopUpProps {
  icon: (highlight: boolean) => JSX.Element;
  maxWidth: number;
  onClickEvent?: () => void;
  onClickContent?: JSX.Element;
  onHoverContent?: JSX.Element;
  onClose?: () => void;
}

export const HoverablePopUp: React.FC<HoverablePopUpProps> = ({
  icon,
  maxWidth,
  onClickEvent,
  onClickContent,
  onHoverContent,
  onClose,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [action, setAction] = useState({ event: '', highlight: false });

  return (
    <CustomPopUp
      icon={
        <TouchableOpacity
          onPress={(event) => {
            setAction({ event: event.type, highlight: true });
            onClickEvent && onClickEvent();
          }}
        >
          {icon(action.highlight)}
        </TouchableOpacity>
      }
      event={['click', 'hover']}
      arrow={false}
      position={'bottom right'}
      onOpen={(event) =>
        setAction({ event: event?.type || '', highlight: true })
      }
      onClose={() => {
        onClose && onClose();
        setAction({ event: '', highlight: false });
      }}
      boxShadow={false}
      content={
        <View style={{ width: maxWidth }}>
          <View
            style={{
              backgroundColor: theme.primary,
              borderRadius: 6,
              elevation: 3,
              shadowColor: '#00000029',
              shadowOffset: {
                width: 0,
                height: 3,
              },
              shadowOpacity: 0.6,
              shadowRadius: 6,
              alignSelf: 'flex-end',
            }}
          >
            {action.event === 'click' ? onClickContent || null : onHoverContent}
          </View>
        </View>
      }
    />
  );
};

export default HoverablePopUp;
