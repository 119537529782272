import { zonoApi } from '../api';
import {
  SendOTPRequest,
  SendOTPResponse,
  VerifyOTPRequest,
  VerifyOTPResponse,
} from './user-model';

export const fieldCollectionUserApi = zonoApi.injectEndpoints({
  endpoints: (build) => ({
    sendOTP: build.mutation<SendOTPResponse | any, SendOTPRequest>({
      query: (SendOTPRequest) => {
        return {
          url: 'teams/member/sendotp',
          body: SendOTPRequest,
          method: 'POST',
        };
      },
    }),
    verifyOTP: build.mutation<VerifyOTPResponse, VerifyOTPRequest>({
      query: (VerifyOTPRequest) => {
        return {
          url: 'teams/member/verifyotp',
          body: VerifyOTPRequest,
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useSendOTPMutation, useVerifyOTPMutation } =
  fieldCollectionUserApi;
