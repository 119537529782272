import ImageResizer from 'react-image-file-resizer';
// import { DeleteFile } from './DeleteFile';
  export const ResizeImages = (
    images: any,
    maxWidth = 1080,
    maxHeight = 1350,
    quality = 80
  ) =>
    new Promise((resolve, reject) => {
      let tempImages;
      if (!!images && images.constructor === Object) {
        tempImages = [images];
      } else {
        tempImages = images;
      }
  
      const imagesLen = tempImages.length;
      const resizedPics: any = [];
  
      for (let i = 0; i < imagesLen; i++) {
        const image = tempImages[i];
        console.log('image--', image);
        const { file } = image;
        let compressFormat : any;
        if (file.type === 'image/jpeg') {
          compressFormat = 'JPEG';
        } else if (file.type === 'image/png') {
          compressFormat = 'PNG';
        }
        if (['JPEG', 'PNG'].includes(compressFormat)) {
          ImageResizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            compressFormat,
            quality,
            0,
            (uri) => {
              console.log('response in resize', uri);
              const tempObj: any = {
                path: uri,
              };
              if (image.text) {
                tempObj.text = image.text;
              }
  
              resizedPics.push(tempObj);
              if (i === imagesLen - 1) {
                //  console.log('resizer inputs', width, height, quality);
                //  console.log('original pics', tempImages);
                console.log('resized pics', resizedPics);
                resolve(resizedPics);
              }
            },
            'base64'
          );
        } else {
          resizedPics.push(image);
          if (i === imagesLen - 1) {
            resolve(resizedPics);
          }
        }
      }
    });
  
