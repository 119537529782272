import axios from 'axios';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Buffer = require('buffer/').Buffer;

export const UploadToS3 = (
  presignedUrl: string,
  file: { type: string; uri: string },
  onUploadProgress?: (e: any) => void
) => {
  const options = {
    timeout: 2 * 60 * 1000,
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: onUploadProgress,
  };
  let bufferValue = Buffer.from(
    file.uri?.replace(/^data:application\/pdf;base64,/, ''),
    'base64'
  );
  if (file.type?.startsWith('image')) {
    bufferValue = Buffer.from(
      file.uri.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );
  }

  return axios.put(presignedUrl, bufferValue.buffer, options);
};

export const RemoveFromS3 = (presignedUrl: string) => {
  const config = {
    method: 'delete',
    url: presignedUrl,
    headers: {
      Accept: 'application/json, text/plain, */*',
    },
    referrerPolicy: 'no-referrer',
  };

  axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      return true;
    })
    .catch((error) => {
      return false;
    });
  return true;
};

export const getS3FileSize = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    const contentLength = response.headers.get('Content-Length');
    return contentLength;
  } catch (error) {
    console.error('Error fetching file size:', error);
    return 0;
  }
};
