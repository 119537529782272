import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

function CheckCircleIcon(props) {
  return (
    <Svg
      data-name="check circle icon"
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="0 0 26 26"
      {...props}
    >
      <Circle
        data-name="Ellipse 2059"
        cx={12.616}
        cy={12.616}
        r={12.616}
        transform="translate(.001)"
        fill="#1d1e26"
      />
      <G data-name="22 Check">
        <Path
          data-name="Path 17"
          d="M839.841 3346.671a13 13 0 1013 13 13 13 0 00-13-13zm5.91 10.515l-6.5 6.5a1.071 1.071 0 01-1.527 0l-3.792-3.791a1.08 1.08 0 011.528-1.528l3.028 3.022 5.736-5.731a1.08 1.08 0 011.528 1.528z"
          transform="translate(-826.841 -3346.671)"
          fill="#87e2bd"
        />
      </G>
    </Svg>
  );
}

export default CheckCircleIcon;
