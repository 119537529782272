import { InputKeyboardType } from './enum';
import { StyleProp, TextStyle } from 'react-native';
import { DateFilter } from '../helpers';
import { ValidationResult, ValidationRule } from '@zonofi/common';

export interface InputValueResult {
  isValid: boolean;
  result: ValidationResult[];
  value: string;
}

export interface Inputs {
  title?: string;
  validationRules?: ValidationRule[];
  initialValue?: string;
  mask?: string;
  maskType?: string;
  label: string;
  mode?: string;
  key?: string;
  placeholder?: string;
  errorStrings?: string[];
  inputKeyBoardType?: InputKeyboardType;
  prefixText?: string;
  outlineColor?: string;
  maxLength?: number;
  disabled?: boolean;
  autoCapitalized?: boolean;
  pasteDisabled?: boolean;
  backgroundColor?: string;
  cursorHidden?: boolean;
}

export interface ShimmerTextRefObject {
  show: () => void;
  hide: () => void;
}

export interface ShimmerRefObject {
  show: () => void;
  hide: () => void;
}

export interface UnpaidItemInvoice {
  id: string;
  amount: number;
  invoiceNumber: string;
  date: string;
  days: string;
}

export interface DropDownOptions {
  mainText: any;
  subText?: string;
  icon?: React.ReactNode;
  id: string;
  slug?: any;
  textStyle?: StyleProp<TextStyle>;
}

export enum ToastType {
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
export interface TostMsg {
  toast: boolean;
  toastType: ToastType;
  msg: string;
  title: string;
}

export interface SelectedReportDate {
  label: DateFilter;
  dateTime: {
    startDate: Date;
    endDate: Date;
  };
}
