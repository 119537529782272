import React, { useContext } from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import Icon from 'react-native-easy-icon';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { colors } from '../colors/colors';
import { fontConfig } from '../fonts/fonts';
import { ThemeContext } from '../contexts/ThemeContext';
import { onlyDigits } from '@zonofi/common';

/* eslint-disable-next-line */
export interface IncrementDecrementInputProps {
  value: number;
  setValue?: (val: number) => void;
  disabled?: boolean;
  percentage?: boolean;
}

export const IncrementDecrementInput: React.FC<
  IncrementDecrementInputProps
> = ({ value, setValue, disabled = false, percentage = false }) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <View style={styles.container}>
      <View
        style={[styles.innerContainer, { width: percentage ? '90%' : '100%' }]}
      >
        <TextInput
          value={value.toString()}
          onChangeText={(text) => {
            if (onlyDigits(text).success) {
              setValue && setValue(Number(text));
            } else {
              setValue && setValue(0);
            }
          }}
          style={[
            styles.input,
            {
              color: theme.text.primary,
              fontFamily: theme.fontConfig.regular.fontFamily,
            },
          ]}
          editable={!disabled}
        />
        {!disabled && (
          <View>
            <Icon
              type={'antdesign'}
              name={'caretup'}
              size={15}
              color={colors.manatee.color}
              onPress={() => setValue && setValue(value + 1)}
            />
            <Icon
              type={'antdesign'}
              name={'caretdown'}
              size={15}
              color={colors.manatee.color}
              onPress={() => {
                if (value > 0) setValue && setValue(value - 1);
              }}
            />
          </View>
        )}
      </View>
      {percentage && (
        <View
          style={{
            backgroundColor: colors.manatee.color,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            justifyContent: 'center',
            paddingHorizontal: wp(0.5),
          }}
        >
          <Text
            style={{
              ...fontConfig.default.regular,
              fontSize: 14,
              color: colors.onyx.color,
            }}
          >
            %
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: 'row',
    borderColor: colors.onyx.color,
    justifyContent: 'space-between',
  },
  innerContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: wp(2),
    paddingLeft: wp(1),
    paddingVertical: hp(1),
  },
  input: {
    // ...fontConfig.default.regular,
    // color: colors.white.color,
    fontSize: 19,
    lineHeight: 23,
    width: '80%',
  },
});

export default IncrementDecrementInput;
