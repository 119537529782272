import React, { useContext, useEffect, useState } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { fontConfig } from '../fonts/fonts';
import { colors } from '../colors/colors';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import Icon from 'react-native-easy-icon';
import { Strings, getDocumentAsync } from '../helpers';
import _ from 'lodash';
import CustomPopUp from '../custom-pop-up/custom-pop-up';
import { ThemeContext } from '../contexts/ThemeContext';
import { SHARED_SVGS } from '../assets';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { UploadToS3, checkIsValidFile, FileUploadOutput } from '@zonofi/common';

/* eslint-disable-next-line */
export interface UploadOptionProps {
  label: string;
  getPresignedUrl: (mimeType: string) => Promise<string>;
  checkUpload?: (state: boolean) => void;
  onDownload?: () => void;
  isFileUploded?: boolean;
  docType?: string;
  edit?: boolean;
  onDelete?: (val: boolean) => void;
  customDownload?: boolean;
  fileSizeLimit?: number;
  fileSize_?: number;
  setCancelUpload?: React.Dispatch<React.SetStateAction<boolean>>;
  cancelUpload?: boolean;
  containerStyles?: StyleProp<ViewStyle>;
  fileFormat?: string;
}

export const getFileSize = (bytes: number) => {
  if (bytes < 1024) {
    return { size: bytes, unit: 'bytes' };
  }
  const kb = bytes / 1024;
  if (kb < 1024) {
    return { size: Math.round(kb * 100) / 100, unit: 'kb' };
  }
  const mb = kb / 1024;
  return { size: Math.round(mb * 100) / 100, unit: 'mb' };
};

export const UploadOption: React.FC<UploadOptionProps> = ({
  label,
  getPresignedUrl,
  checkUpload,
  onDownload,
  isFileUploded,
  docType,
  edit = false,
  onDelete,
  customDownload = false,
  fileSizeLimit = 5,
  fileSize_,
  setCancelUpload,
  cancelUpload,
  containerStyles,
  fileFormat,
}) => {
  const [fileUploaded, setFileuploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>();
  const [output, setOutPut] = useState<FileUploadOutput>();
  const [status, setStatus] = useState('');
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [isUploaded, setIsUploaded] = useState(false);

  const [percentCompleted, setPercentCompleted] = useState(0);

  const onUploadProgress = (e: any) => {
    setPercentCompleted(Math.round((e.loaded * 100) / e.total));
  };

  useEffect(() => {
    if (isUploaded && cancelUpload) {
      onDelete && onDelete(false);
      setIsUploaded(false);
      setSuccess(false);
    }
  }, [success]);

  useEffect(() => {
    if (!_.isUndefined(fileSize_) && fileSize_ !== 0) {
      const { size, unit } = getFileSize(fileSize_);
      setStatus(`File size ${size}${unit}`);
    }
  }, [fileSize_]);

  useEffect(() => {
    setFileuploaded(isFileUploded ? isFileUploded : false);
    cancelUpload && setFileuploaded(false);
    setCancelUpload && setCancelUpload(false);
  }, [isFileUploded]);

  const upload = async () => {
    try {
      const result = await getDocumentAsync({});
      if (result) {
        setLoading(true);
        setSuccess(undefined);
        const success = result.type === 'success';
        if (success && result?.output) {
          const file = result?.output[0];
          setOutPut(file);
          const format = file.name?.split('.')[1];
          const fileSize = getFileSize(file.size);
          if (fileSize.size > fileSizeLimit && fileSize.unit === 'mb') {
            setStatus(
              fileSizeLimit === 2
                ? Strings.FILE_SIZE_EXCEEDED_ERROR_
                : Strings.FILE_SIZE_EXCEEDED_ERROR
            );
            setSuccess(false);
          } else if (!_.isUndefined(fileFormat) && fileFormat !== format) {
            setStatus(Strings.INVALID_FILE_FORMAT_ERROR);
            setSuccess(false);
          } else if (!checkIsValidFile(file.file)) {
            setStatus(Strings.INVALID_FILE_FORMAT_ERROR);
            setSuccess(false);
          } else {
            const presignedUrl = await getPresignedUrl(file.file.type);
            await UploadToS3(
              presignedUrl,
              { uri: file.uri as string, type: file.file.type },
              onUploadProgress
            );
            setIsUploaded(true);
            setSuccess(success);
            setStatus(
              success
                ? `File size ${fileSize.size}${fileSize.unit}`
                : Strings.UPLOAD_ERROR
            );
            setPercentCompleted(0);
          }
          setFileuploaded(true);
        }
      } else {
        setStatus(Strings.UPLOAD_ERROR);
        setSuccess(false);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setSuccess(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (checkUpload) {
      checkUpload(success ?? false);
    }
  }, [success]);

  const renderLabel = () => {
    return (
      <Text style={[styles.regularText, { color: theme.input.label }]}>
        {`${Strings.UPLOAD} ${label}`}
      </Text>
    );
  };

  const renderReUpload = () => (
    <CustomPopUp
      icon={
        <Icon
          type="ionicon"
          name="reload"
          color={colors.apple.color}
          size={16}
          onPress={upload}
          style={{ marginRight: 5 }}
        />
      }
      arrow={true}
      color={colors.manatee.primary}
      event={'hover'}
      content={
        <Text style={[styles.regularTextWhite, { fontSize: 14 }]}>
          Re-Upload
        </Text>
      }
      padding={'5px'}
      border={{
        borderRadius: 3,
      }}
    />
  );

  const renderFileUpload = (containerStyles: StyleProp<ViewStyle>) => (
    <TouchableOpacity style={containerStyles} onPress={upload}>
      <View style={[styles.row]}>
        <Image
          source={{ uri: SHARED_SVGS.UploadIcon }}
          style={{
            height: 32,
            width: 32,
            marginHorizontal: 9,
          }}
        />
        <View style={styles.column}>
          {renderLabel()}
          <Text style={[styles.italicText, { textAlign: 'left' }]}>
            {customDownload
              ? Strings.UPLOAD_PDF_FORMAT
              : Strings.UPLOAD_FORMAT_LIMIT}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  const isSuccess = success || _.isUndefined(success);

  return (
    <View
      style={[
        styles.inputContainer,
        {
          backgroundColor: theme.enableEdit ? 'transparent' : '#F7F9FD',
          borderColor: theme.input.border,
          borderStyle: 'dashed',
        },
        containerStyles,
      ]}
    >
      {fileUploaded || loading ? (
        <View
          style={{
            flexDirection: 'row',
            height: '100%',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            zIndex: 1000,
          }}
        >
          <View
            style={{
              height: '100%',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <LoadingSpinner
                size={'small'}
                color="#6ABE4D"
                containerStyle={{ marginHorizontal: 10 }}
              />
            ) : (
              <Image
                source={{
                  uri: isSuccess ? SHARED_SVGS.DocIcon : SHARED_SVGS.UploadIcon,
                }}
                style={{
                  height: isSuccess ? 26 : 32,
                  width: isSuccess ? 19.81 : 32,
                  marginRight: 10,
                  marginLeft: 13,
                }}
              />
            )}
            <View style={{ flexDirection: 'column' }}>
              <Text style={[styles.regularText, { color: theme.text.primary }]}>
                {docType}
              </Text>
              <Text
                style={[
                  styles.italicText,
                  {
                    fontFamily: theme.fontConfig.regular.fontFamily,
                    color: isSuccess
                      ? colors.grey.primary
                      : colors.venetianRed.color,
                    textAlign: 'left',
                  },
                ]}
              >
                {loading ? `Uploading ${percentCompleted}% of 100%` : status}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginRight: 6,
              height: '100%',
              alignItems: 'center',
            }}
          >
            {isSuccess && !loading && (
              <>
                {!_.isUndefined(fileFormat) && (
                  <TouchableOpacity
                    style={{ marginRight: 10 }}
                    onPress={() => onDownload && onDownload()}
                  >
                    <Icon
                      type={'antdesign'}
                      name={'download'}
                      size={16}
                      color={
                        theme.enableEdit ? theme.input.placeHolder : '#a1a3b4'
                      }
                    />
                  </TouchableOpacity>
                )}

                {edit && (
                  <TouchableOpacity
                    onPress={() => {
                      onDelete && onDelete(true);
                    }}
                    style={{ marginRight: 5 }}
                  >
                    <Image
                      source={{ uri: SHARED_SVGS.DeleteIcon }}
                      style={{ height: 17, width: 17 }}
                    />
                  </TouchableOpacity>
                )}
              </>
            )}
            {isSuccess === false && !loading && renderReUpload()}
            {loading && (
              <TouchableOpacity
                onPress={() => {
                  setCancelUpload && setCancelUpload(true);
                  setLoading(false);
                  setFileuploaded(false);
                }}
                style={{ marginRight: 3 }}
              >
                <Icon
                  type="antdesign"
                  name="closecircle"
                  color={'#808285'}
                  size={16}
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
      ) : (
        renderFileUpload({ marginTop: 0 })
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: { paddingVertical: hp(1) },
  boldText: {
    ...fontConfig.default.segoeBold,
    fontSize: 14,
    lineHeight: 20,
    color: colors.manatee.color,
  },
  container: {
    marginTop: hp(1),
    paddingVertical: hp(1),
    backgroundColor: colors.antiFlashWhite.color,
    borderColor: colors.gainsBoro.color,
    borderWidth: 1.5,
    borderStyle: 'dashed',
    borderRadius: 4,
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  regularText: {
    ...fontConfig.default.segoeRegular,
    fontSize: 16,
    lineHeight: 20,
    color: colors.eerieBlack.teritiary,
  },
  regularTextWhite: {
    ...fontConfig.default.segoeRegular,
    fontSize: 16,
    lineHeight: 20,
    color: colors.white.color,
    textAlign: 'center',
  },
  italicText: {
    ...fontConfig.default.segoeRegular,
    fontSize: 11,
    lineHeight: 16,
    color: colors.grey.primary,
    textAlign: 'center',
  },
  titleStyles: {
    position: 'absolute',
    left: 3,
    fontSize: 12,
    marginLeft: 11,
    marginTop: -18,
    paddingHorizontal: 3,
  },
  inputContainer: {
    borderStyle: 'solid',
    marginVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: '#29346280',
    borderWidth: 1,
    height: 50,
    borderRadius: 6,
    marginBottom: 25,
    marginTop: -10,
    width: 400,
    marginRight: 15,
    zIndex: 0,
  },
});

export default UploadOption;
