import React, { useContext } from 'react';
import './review-invoice.scss';
import { ThemeContext } from '@zonofi/shared';
import { DetailsItem } from '../common';
import { Invoice } from '@zonofi/zono-money-store';
import { max, sum } from 'lodash';
import { getFormattedAmount } from '@zonofi/common';

interface ReviewInvoiceSummaryProps {
  invoices: Invoice[];
}

export const ReviewInvoiceSummary: React.FC<ReviewInvoiceSummaryProps> = ({
  invoices,
}) => {
  const { theme } = useContext(ThemeContext);

  const invoiceCount =
    invoices.length < 10 ? `0${invoices.length}` : invoices.length.toString();

  const overdueAmt = getFormattedAmount(
    sum(
      invoices?.map(
        ({ invoiceAmount, paidAmount }) => invoiceAmount - paidAmount
      )
    )
  );

  const dueCount = max(invoices?.map(({ dueInDays }) => dueInDays)) || 0;
  const dueInDays = `${
    Math.abs(dueCount) < 10 ? `0${Math.abs(dueCount)}` : Math.abs(dueCount)
  } Days`;

  return (
    <div className="review-summary" style={{ background: theme.primary }}>
      <div
        style={{
          ...theme.fontConfig.regular,
          color: theme.text.primary,
          fontSize: 14,
        }}
      >
        Please settle the overdue amount to ensure smooth processing of future
        orders.
      </div>
      <div className="row">
        <div className="column">
          <DetailsItem heading="Invoices Due" value={invoiceCount} />
          <DetailsItem
            heading="Total Over Due"
            value={overdueAmt}
            color="#EA0500"
            fontSize={18}
          />
        </div>
        <DetailsItem
          heading={`Due ${dueCount > 0 ? 'Since' : 'In'}`}
          value={(dueInDays || 0)?.toString()}
        />
      </div>
    </div>
  );
};
