import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { convertCsvToJson } from './csvToJson';
import { FileUploadOutput } from '@zonofi/common';

export const getJSONDocumentAsync = async ({
  type = '*/*',
  multiple = false,
}) => {
  const input = document.createElement('input');
  input.style.display = 'none';
  input.setAttribute('type', 'file');
  input.setAttribute('accept', type);
  input.setAttribute('id', uuidv4());
  if (multiple) {
    input.setAttribute('multiple', 'multiple');
  }

  document.body.appendChild(input);

  return new Promise((resolve, reject) => {
    input.addEventListener('change', () => {
      const finalData: any[] = [];
      if (input.files) {
        const files = input.files || [];
        if (files.length) {
          for (let index = 0; index < files.length; index++) {
            //instantiate a FileReader for each file to read
            // eslint-disable-next-line no-undef
            const reader = new FileReader();
            reader.onload = function () {
              const result = reader.result ?? '';
              finalData[index] = {
                data: isJSON(_.isString(result) ? result : '')
                  ? JSON.parse(_.isString(result) ? result : '')
                  : null,
                name: !_.isNull(input.files) ? input.files[index].name : '',
              };
              if (index === files.length - 1) {
                resolve({
                  type: 'success',
                  output: finalData,
                });
              }
            };
            reader.readAsText(files[index]);
          }
        }
      } else {
        resolve({ type: 'fail' });
      }

      document.body.removeChild(input);
    });

    // eslint-disable-next-line no-undef
    const event = new MouseEvent('click');
    input.dispatchEvent(event);
  });
};

export const getDocumentAsync = async ({ type = '*/*', multiple = false }) => {
  const input = document.createElement('input');
  input.style.display = 'none';
  input.setAttribute('type', 'file');
  input.setAttribute('accept', type);
  input.setAttribute('id', uuidv4());
  if (multiple) {
    input.setAttribute('multiple', 'multiple');
  }

  const readFileAsArrayBuffer = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      //@ts-ignore
      reader.onload = (e) => resolve(e.target.result);
      reader.readAsArrayBuffer(file);
    });
  };

  document.body.appendChild(input);
  return new Promise<{
    type: string;
    output?: FileUploadOutput[];
  }>((resolve, reject) => {
    input.addEventListener('change', () => {
      const finalData: FileUploadOutput[] = [];
      if (input.files) {
        const files = Array.from(input.files); // Convert FileList to array

        const processFile = (index: number) => {
          if (index < files.length) {
            const reader = new FileReader();

            reader.onerror = (error) => {
              console.error('Error reading file:', error);
              reject(
                new Error(
                  'Failed to read the selected media because the operation failed.'
                )
              );
            };

            reader.onload = async ({ target }) => {
              if (target) {
                const uri = target.result as string;
                const targetFile = files[index];

                const data = await readFileAsArrayBuffer(targetFile);

                finalData[index] = {
                  uri,
                  data: data,
                  name: targetFile.name,
                  file: targetFile,
                  lastModified: targetFile.lastModified,
                  size: targetFile.size,
                };

                processFile(index + 1);
              }
            };

            reader.readAsDataURL(files[index]);
          } else {
            resolve({
              type: 'success',
              output: finalData,
            });
            document.body.removeChild(input);
          }
        };

        processFile(0);
      } else {
        resolve({ type: 'cancel' });
        document.body.removeChild(input);
      }
    });

    const event = new MouseEvent('click');
    input.dispatchEvent(event);
  });
};

export const getCSVDocumentAsync = async ({
  type = '*/*',
  multiple = false,
  convertHeaderToCamelCase = false,
  mapper = [],
}) => {
  const input = document.createElement('input');
  input.style.display = 'none';
  input.setAttribute('type', 'file');
  input.setAttribute('accept', type);
  input.setAttribute('id', uuidv4());
  if (multiple) {
    input.setAttribute('multiple', 'multiple');
  }

  document.body.appendChild(input);

  return new Promise((resolve, reject) => {
    input.addEventListener('change', () => {
      const finalData: any[] = [];
      if (
        input.files &&
        Array.from(input.files || []).every((f) => f.type === type)
      ) {
        const files = input.files || [];
        const lastModified = files[0].lastModified;
        const size = files[0].size;

        if (files.length) {
          for (let index = 0; index < files.length; index++) {
            //instantiate a FileReader for each file to read
            // eslint-disable-next-line no-undef
            const reader = new FileReader();
            reader.onload = async function () {
              let data = reader.result;
              if (convertHeaderToCamelCase) {
                data = _.isString(data)
                  ? convertHeadersToCamelCase(data, mapper)
                  : '';
              }
              const result = await convertCsvToJson(data);
              finalData[index] = {
                data: result ? result : null,
                name: !_.isNull(input.files) ? input.files[index].name : '',
                lastModified,
                size,
                type: 'text/csv',
              };
              if (index === files.length - 1) {
                resolve({
                  type: 'success',
                  output: finalData,
                });
              }
            };
            reader.readAsText(files[index]);
          }
        }
      } else {
        resolve({ type: 'fail' });
      }
      document.body.removeChild(input);
    });

    // eslint-disable-next-line no-undef
    const event = new MouseEvent('click');
    input.dispatchEvent(event);
  });
};

const isJSON = (text: string) => {
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

const convertHeadersToCamelCase = (data: string, mapper: any) => {
  const mainHeaders = data?.split('\n')[0];
  const headers = mainHeaders.split(',');
  let newHeaders = '';
  for (let hIndex = 0; hIndex < headers.length; hIndex++) {
    const headerMapper = mapper?.find(
      (m: { key: string }) => m.key === headers[hIndex]
    )?.value;
    const element = headerMapper ?? _.camelCase(headers[hIndex]);
    newHeaders += `${element}${hIndex === headers.length - 1 ? '' : ','}`;
  }
  return data?.replace(mainHeaders, newHeaders);
};
