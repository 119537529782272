import React from 'react';
import { Skeleton } from '@mui/material';

export const ListSkeleton: React.FC<{
  rows?: number;
}> = ({ rows }) => {
  if (!rows) {
    rows = 8;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      {Array.from(Array(rows).keys())?.map((item, index) => {
        return (
          <div key={index}>
            <Skeleton
              animation="wave"
              variant="text"
              sx={{ fontSize: 14 }}
              width={130}
            />
            <Skeleton
              animation="wave"
              variant="text"
              sx={{ fontSize: 14 }}
              width={80}
            />
          </div>
        );
      })}
    </div>
  );
};
