export enum AppType {
  ZONOFI = 'zonofi',
  FIELD_COLLECTION = 'fc',
  SELLER = 'seller',
}

export interface UserAuthResponse {
  address: string;
  city: string;
  contentType: string;
  email: string;
  firstName: string;
  id: string;
  integrationToken: string;
  isVerified: boolean;
  lastName: string;
  mobile: string;
  profilePic: string;
  refreshToken: string;
  registeredOn: string;
  role: string;
  subtitle: string;
  systemFirstName: string;
  systemLastName: string;
  token: string;
  userType: string;
  workspace?: Workspace;
  appType?: AppType;
}

export interface Workspace {
  id: string;
  spaceName: string;
  location: number[];
  address?: any;
  userId: string;
  createdDate: Date;
  updatedDate: Date;
  updatedBy?: any;
  profilePic?: any;
  timeZone: string;
  defaultCurrencyCode: string;
  isoLanguageCode?: any;
  isoCountryCode: string;
}

export interface SendOtpResponse {
  status: string;
  attemptsLeft: number;
  loginAttemptsLeftmessage: string;
  otp: number;
  message?: string;
}

export interface UserTokens {
  token: string;
  refreshToken: string;
}

export interface RewardNotification {
  userId: string;
  workspaceId: string;
  transaction_id: string;
  mobile: string;
  entity: string;
  action: string;
  data: amountData;
}
export interface amountData {
  amount: number;
  payoutStatus: string;
}

// export type RootState = ReturnType<typeof store.getState>;
