import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckOutType } from '@zonofi/shared';
import { RootState } from '../root/ReduxStore.store';
import {
  FieldCollectionPayments,
  FormValue,
  ManualTabs,
  OfflinePaymentDetails,
  PaymentSlice,
} from './payment-model';

export const PAYMENT_FEATURE_KEY = 'paymentData';

export interface PaymentState {
  paymentSlice: PaymentSlice;
  manualPaymentFormValid: boolean;
  manualPaymentForm: FormValue[];
  manualPaymentSelectedTab: ManualTabs;
  offlinePaymentDetails: OfflinePaymentDetails;
  amountPaid: number;
  PaymentRequestId: string;
  selectedCollectionDate: string;
  selectedPendingInvoicesDate: string;
  fieldCollectionPaymentSummary?: FieldCollectionPayments;
  checkOutType: CheckOutType;
}
export const initialPaymentState: PaymentState = {
  paymentSlice: {
    qrImage: '',
  },
  manualPaymentFormValid: false,
  manualPaymentSelectedTab: ManualTabs.CASH,
  manualPaymentForm: [],
  offlinePaymentDetails: {
    paymentDate: '',
    paymentId: '',
  },
  amountPaid: 0,
  PaymentRequestId: '',
  selectedCollectionDate: '',
  selectedPendingInvoicesDate: '',
  fieldCollectionPaymentSummary: undefined,
  checkOutType: CheckOutType.OFFLINE,
};

export const paymentSlice = createSlice({
  name: PAYMENT_FEATURE_KEY,
  initialState: initialPaymentState,
  reducers: {
    addQRImage(state, action: PayloadAction<string>) {
      state.paymentSlice.qrImage = action.payload;
    },
    updateFormValid(state, action: PayloadAction<boolean>) {
      state.manualPaymentFormValid = action.payload;
    },
    updateManualPaymentForm(state, action: PayloadAction<FormValue[]>) {
      state.manualPaymentForm = action.payload;
    },
    updateManualPaymentSelectedTab(state, action: PayloadAction<ManualTabs>) {
      state.manualPaymentSelectedTab = action.payload;
    },
    updateOfflinePaymentDetails(
      state,
      action: PayloadAction<OfflinePaymentDetails>
    ) {
      state.offlinePaymentDetails = action.payload;
    },
    updateAmountPaid(state, action: PayloadAction<number>) {
      state.amountPaid = action.payload;
    },
    updatePaymentRequestId(state, action: PayloadAction<string>) {
      state.PaymentRequestId = action.payload;
    },
    updateSelectedCollectionDate(state, action: PayloadAction<string>) {
      state.selectedCollectionDate = action.payload;
    },
    updateCheckOutType(state, action: PayloadAction<CheckOutType>) {
      state.checkOutType = action.payload;
    },
    updateSelectedPendingInvoicesDate(state, action: PayloadAction<string>) {
      state.selectedPendingInvoicesDate = action.payload;
    },
    updateFieldCollectionPaymentSummary(
      state,
      action: PayloadAction<FieldCollectionPayments>
    ) {
      state.fieldCollectionPaymentSummary = action?.payload;
    },
  },
});

export const paymentReducer = paymentSlice.reducer;

export const {
  addQRImage,
  updateFormValid,
  updateManualPaymentForm,
  updateManualPaymentSelectedTab,
  updateOfflinePaymentDetails,
  updateAmountPaid,
  updatePaymentRequestId,
  updateFieldCollectionPaymentSummary,
  updateSelectedCollectionDate,
  updateSelectedPendingInvoicesDate,
  updateCheckOutType,
} = paymentSlice.actions;

export const getPaymentSliceState = (rootState: RootState): PaymentState =>
  rootState[PAYMENT_FEATURE_KEY];

export const getQRCodeBase64 = createSelector(
  getPaymentSliceState,
  (state) => state.paymentSlice.qrImage
);

export const getManualFormValid = createSelector(
  getPaymentSliceState,
  (state) => state.manualPaymentFormValid
);

export const getManualPaymentForm = createSelector(
  getPaymentSliceState,
  (state) => state.manualPaymentForm
);
export const getAmountPaid = createSelector(
  getPaymentSliceState,
  (state) => state.amountPaid
);

export const getManualFormSelectedTab = createSelector(
  getPaymentSliceState,
  (state) => state.manualPaymentSelectedTab
);

export const getOfflinePaymentDetails = createSelector(
  getPaymentSliceState,
  (state) => state.offlinePaymentDetails
);

export const getPaymentRequestId = createSelector(
  getPaymentSliceState,
  (state) => state.PaymentRequestId
);
export const getSelectedCollectionDate = createSelector(
  getPaymentSliceState,
  (state) => state.selectedCollectionDate
);
export const getSelectedPendingInvoiceDate = createSelector(
  getPaymentSliceState,
  (state) => state.selectedPendingInvoicesDate
);
export const getFieldCollectionPaymentSummary = createSelector(
  getPaymentSliceState,
  (state) => state.fieldCollectionPaymentSummary
);
export const getCheckOutType = createSelector(
  getPaymentSliceState,
  (state) => state.checkOutType
);
