export const colors = {
  mediumAquamarine: {
    color: '#64E6BA',
    opacity10: '#64E6BA1A',
    opacity20: '#64E6BA33',
    opacity30: '#64E6BA4D',
  },
  redVioletLinearGradient: { firstColor: '#CA3438', secondColor: '#8645D8' },
  disabledGrey: { color: '#939395' },
  labelColor: { grey: '#DBDBDB', grey100: '#202129' },
  linkColor: { color: '#4A7EDA' },
  zono50OpacityWhite: { color: 'rgba(255, 255, 255, 0.5)' },
  zonoRed: {
    color1: '#FF5851',
    color2: 'rgba(255, 63, 63, 0.8)',
    color3: '#D3393B',
  },
  grey: {
    color: '#BFC0C2',
    primary: '#34353e',
    secondary: '#525151',
    tertiary: '#464745',
  },
  zonoWhite: {
    color: '#FFFFFF',
    opacity5: 'rgba(255, 255, 255, 0.05)',
    opacity50: 'rgba(255, 255, 255, 0.5)',
    opacity60: 'rgba(255, 255, 255, 0.6)',
    opacity70: 'rgba(255, 255, 255, 0.7)',
    opacity80: 'rgba(255, 255, 255, 0.8)',
    opacity90: 'rgba(255, 255, 255, 0.9)',
    opacity10: 'rgba(255, 255, 255, 0.1)',
  },
  metallicSilver: {
    color: '#A1A3B4',
    opacity20: '#A1A3B433',
    primaryShade: '#A1A3B3',
  },
  containerColor: { color: '#3A3A3A', secondary: '#36282e' },
  cardBackgrounds: {
    black: '#000000',
    grey: '#232630',
    darkgrey: '#181A21',
    blue: '#193040',
    grey2: '#292A34',
    darkerGrey: '#0000004F',
    grey40Opacity: 'rgba(58, 62, 75,0.4)',
    darkgrey70opacity: 'rgba(24, 26, 33, 0.7)',
    lightBlue: '#A7E0FF',
    violet: '#191038',
    navyblue: '#1C204A',
  },
  darkGunmetal: {
    primary: '#2D2F39B3',
    secondary: '#202129',
    tertiary: '#24252F',
    quaternary: '#20212A',
    color: '#21222B',
    secondColor: '#2d2b27',
    tertiaryColor: '#d5bc51',
    primaryColor: '#242429',
  },
  backgroundLinearGradient: { firstColor: '#21222B', secondColor: '#000000' },
  sellerBackgroundLinearGradient: {
    firstColor: '#020203',
    secondColor: '#000000',
  },
  white: {
    color: '#FFFFFF',
    primaryColor: '#ededed',
    primary: '#FCFDFF',
    secondary: '#9295a5',
    tertiary: '#F7F8FA',
    quaternary: '#4481B429',
  },
  manatee: {
    color: '#9295A5',
    opacity20: '#9295A533',
    opacity10: 'rgba(146, 149, 165, 0.1)',
    opacity50: '#9295A580',
    primary: '#9399B0',
    secondary: '#29346280',
  },
  mediumSeaGreen: { color: '#34AA54', secondColor: '#Dffffb' },

  lightGrey: { color: '#9295A5', secondColor: '#C9CCD680' },
  charlestonGreen: {
    primary: '#272D31',
    secondary: '#292933',
    tertiary: '#282A33',
    color: '#252630',
    quarternary: '#292A31',
    primaryColor: '#87e2bd',
  },
  cadetGrey: { color: '#98A5AC' },
  blueGreenLinearGradient: { firstColor: '#50c9ff', secondColor: '#007ab2' },
  gray: {
    color: '#808080',
    primary: '#808285',
    opacity10: '#8082851A',
    opacity20: '#80828533',
    opacity50: '#BFC0C2',
    opacity80: '#808285CC',
  },
  slateGray: {
    color: '#6F7D92',
  },
  lightSlateGrey: {
    color: '#EEEFF2',
  },
  darkSlateGrey: {
    color: '#616F81',
  },
  arsenic: {
    primary: '#3E454A',
    secondary: '#373A46',
    teritiary: '#373A48',
    color: '#3A3D4A',
  },
  burntSienna: { color: '#EB7554' },
  venetianRed: { color: '#E85B49', opacity10: '#E85B491A' },
  crayolaGreen: { color: '#1EC17F', secondary: '#1e3334' },
  munsellGreen: {
    primary: '#00C17F',
    opacity10: '#00C17F1A',
    secondary: '#00B174',
    color: '#00C17F',
  },
  pearlAqua: { color: '#87E2BD', primary: '#79e7ba' },
  gunMetal: {
    color: '#2D2F39B3',
    opacity50: '#2D2F3980',
    primary: '#2d2e38',
    secondary: '#252d38',
    teritiary: '#2D2F39',
  },
  maximumBlue: { color: '#4CA6D0' },
  pastelRed: { color: '#ff7069' },
  chineseGold: { color: '#cc9308' },
  middleBlue: { color: '#87ced9' },
  acidGreen: { color: '#b0cb1f' },
  titaniumYellow: { color: '#ebf10d' },
  seaGreenCrayola: { color: '#0df1c1' },
  vividOrchid: { color: '#d20df1' },
  vividSkyBlue: { color: '#32A7E8' },
  blue: { color: '#0d5cf1' },
  byzontine: { color: '#a72ea4' },
  uclaBlue: { color: '#5b6997' },
  black: { color: '#000000', opacity80: '#000000D9' },
  babyBlueEyes: { color: '#99CFF6', opacity20: '#99CFF633' },
  mayaBlue: { color: '#6ED2FF' },
  japaneseIndigo: { primary: '#2F4445', secondary: '#1E4253' },
  transparent: { color: 'transparent' },
  davysGrey: { color: '#52585D', primary: '#505160' },
  charlestonGrey: { color: '#232630' },
  outerSpace: { color: '#31323B', secondary: '#414453' },
  richBlack: { color: '#020203' },
  emerald: { color: '#5BBE84' },
  fuzzyWuzzyRed: {
    color: '#D1685D',
    primaryShadeDark: '#BC5B50',
    secondaryShadeDark: '#A84F44',
    tertiaryShadeDark: '#9A473D',
  },
  indianYellow: { color: '#E8A549', opacity10: '#E8A5491A' },
  jade: { color: '#00A66D', secondary: '#00AA70' },
  greenLinearGradient: { firstColor: '#00A66D', secondColor: '#005337' },
  redLinearGradient: { firstColor: '#D95043', secondColor: '#6D2822' },
  blueLinearGradient: { firstColor: '#4CA6D0', secondColor: '#07567B' },
  quartz: { color: '#4A4C58' },
  eerieBlack: {
    color: '#1A1B23',
    primary: '#1A1A21',
    secondary: '#181920',
    teritiary: '#242b35',
    opacity25: '#1D1D2240',
    opacity40: '#1D1D2266',
  },
  chineseBlack: {
    color: '#0F0F12',
    secondary: '#0F0F1248',
    primary: '#131318',
    teritiary: '#131419',
  },
  philippineGrey: { color: '#909195' },
  graniteGrey: { color: '#676768' },
  darkElectricBlue: { color: '#65697C' },
  onyx: {
    color: '#34353E',
    secondary: '#373941',
    primary: '#373943',
    opacity20: '#34353E33',
  },
  redLinearGradientToast: {
    firstRedColor: '#E85B49',
    secondRedColor: '#CE4837',
  },
  greenLinearGradientToast: {
    firstGreenColor: '#00B778',
    secondGreenColor: '#4BCBA0',
  },
  yellowLinearGradientToast: {
    firstYellowColor: '#D57F35',
    secondYellowColor: '#E67F26',
  },
  oceanGreen: { color: '#49ce8b' },
  platinum: { color: '#DEE6E9', primary: '#E4E4E4' },
  starCommandBlue: { color: '#127BB0' },
  paleCyan: { color: '#86D1FA' },
  cultured: { color: '#F6F6F6', primary: '#F2F5FA' },
  headerGradient: { firstColor: '#1C1D25', secondColor: '#1C1D2500' },
  blackLinearGradient: { firstcolor: '#21222B', secondcolor: '#000000' },
  zonoBlack: { color: '#000000' },
  backButtonBorder: { color: '#535353' },
  independence: { color: '#565767', primary: '#282A33', secondary: '#4a4c57' },
  yellow: { color: '#A39B36' },
  mediumJungleGreen: { color: '#1e3334' },
  blackCoffee: { color: '#36282e', primary: '#24252ee3' },
  raisinBlack: { color: '#202027', primary: '#1E1F27', secondary: '#1D1D22' },
  darkCharcoal: {
    color: '#2D2D34',
    opacity67: '#2D2D34AB',
    primary: '#2e2e33',
  },
  cola: { color: '#392926' },
  eucalyptus: { color: '#49CCA0' },
  persianOrange: { color: '#D59351' },
  cornFlower: { opacity10: '#96CFF61A' },
  tealBlue: { color: '#2D7D9A' },
  ghostWhite: { color: '#F7F9FD' },
  philippineGray: { color: '#898b8e' },
  morningBlue: { color: '#879a9a' },
  apple: {
    color: '#6DBF50',
    primary: '#6bbe4e',
    secondary: '#6BBE4D',
    opacity10: '#6BBE4D1A',
  },
  iguanaGreen: { color: '#68c37f', primary: '#68C379' },
  fireOpal: { color: '#E85B48' },
  chineseWhite: {
    color: '#E1E1E1',
    primary: '#DFE0E0',
    opacity40: '#DFE0E066',
  },
  steelBlue: {
    color: '#4481B4',
    opacity10: '#4481B41A',
    opacity50: '#4481B480',
    opacity20: '#4481B433',
  },
  blueGray: { color: '#5995C7' },
  winterGreenDream: { color: '#49917c', primary: '#46917C' },
  antiFlashWhite: { color: '#ECF2F7', primary: '#EDF0F7' },
  ceruleanFrost: { color: '#6E9DC5' },
  gainsBoro: { color: '#D6DCE9', primary: '#D5DBE8' },
  dimGray: { color: '#646876' },
  mariGold: { color: '#F09B25', primary: '#F39C26' },
  pistachio: { color: '#8ECC79' },
  brightGray: { color: '#ECF0F5', primary: '#E4E8F0', secondary: '#ECEEF2' },
  indigo: { color: '#293462', opacity50: '#29346280', opacity20: '#29346233' },
  lavender: { color: '#E4ECFC' },
  aliceBlue: { color: '#F4F7FD' },
  darkJungleGreen: { color: '#1b1c24' },
  cadetBlue: { color: '#A7AEBC', primary: '#ADB0C3' },
  darkBlueLinearGradient: { firstColor: '#4481B4', secondColor: '#22415A' },
  zonoDigitalBlueLinearGradient: {
    firstColor: '#34A7E8',
    secondColor: '#77C4F0',
  },
  lavenderGray: { color: '#BDC2CE' },
  budGreen: { color: '#7FBB5C' },
  persianRed: { color: '#C93532', opacity10: '#C935321A' },
  lightMossGreen: { color: '#B4DEA5', opacity50: '#B4DEA580' },
  mediumCarmine: {
    color: '#BA4139',
    opacity50: '#BA413980',
    opacity5: '#BA41390D',
  },
  colorVarient: {
    varientA: '#57ab5a',
    varientB: '#c69027',
    varientC: '#e0823d',
    varientD: '#f47068',
    varientE: '#e275ad',
    varientF: '#b083f0',
    varientG: '#529bf5',
    varientH: '#df9ab7',
    varientI: '#69af8d',
    varientJ: '#4f5cab',
    varientK: '#cd72af',
  },
  forestGreen: {
    primary: '#5E9A75',
  },
  queenBlue: {
    color: '#3F6899',
    primary: '#3e71a0',
  },
  burntUmber: {
    color: '#893227',
  },
  palmLeaf: {
    color: '#7AA049',
  },
  boysenBerry: {
    color: '#7B376E',
  },
  paleLavender: {
    color: '#E0D2F1',
    opacity50: '#E0D2F180',
  },
  blueMagentaViolet: {
    color: '#492999',
    opacity20: '#49299933',
  },
  lightGoldenrodYellow: {
    color: '#F5EFCF',
  },
  metallicSunburst: {
    color: '#9C8743',
    opacity20: '#9C874333',
  },
  alabaster: {
    color: '#F1EFE6',
  },
  milkChocolate: {
    color: '#8D532F',
    opacity20: '#8D532F33',
  },
  darkCornflowerBlue: { color: '#244091', opacity20: '#24409133' },
  azureishWhite: { color: '#D4DEF6', primary: '#D0DFEC' },
  royalOrange: { color: '#F08943', opacity10: '#F089431A' },
  middleGreen: { color: '#4F9E52', opacity10: '#4F9E521A' },
  cyanAzure: {
    color: '#5281B0',
    opacity25: '#5281B040',
    opacity50: '#5281B080',
  },
  americanSilver: { color: '#C9CCD6', opacity50: '#C9CCD680' },
  blueYonder: { color: '#53799F', opacity5: '#53799F0D' },
  sonicSilver: { color: '#797A7C' },
  antiqueWhite: { color: '#F9E9D4', opacity40: '#F9E9D466' },
  hollyHock: { color: '#853071', opacity20: '#85307150' },
  allMadeUp: { color: '#eed3e8' },
  darkSilver: { color: '#707070', opacity20: '#70707033' },
  zonoDigitalBlue: { color: '#34A7E8', opacity10: '#34A7E81A' },
  zonoDigitalRed: { color: '#E83535' },
  zonoDigitalGreen: {
    color: '#3FC227',
  },
  electricViolet: {
    color: '#8135E8',
  },
  blackCrow: {
    color: '#132C45',
  },
  paleLavenderPink: {
    color: '#EED3E9',
  },
  roseQuartz: {
    color: '#F1D5D6',
  },
  powderBlue: {
    color: '#DEF0FC',
  },
  paleGreen: {
    color: '#DEE8CA',
  },
  periwinkle: {
    color: '#DBD1F5',
  },
  lightCyan: {
    color: '#DBF5F5',
  },
  lightSteelBlue: {
    color: '#DCE2E9',
  },
  darkOliveGreen: {
    color: '#A38E4E',
  },
  maroon: {
    color: '#862E29',
  },
  navyBlue: {
    color: '#24118A',
  },
  mediumTeal: {
    color: '#65A49E',
  },
  gunmetalGray: {
    color: '#5D5E60',
  },
  zonoBlurBlue: {
    color: '#E8F4FA',
  },
  avatarColor1: {
    color: '#C8FAF9',
  },
  avatarColor2: {
    color: '#E8D6FE',
  },
  avatarColor3: {
    color: '#FEE2BE',
  },
  avatarColor4: {
    color: '#E5F9B3',
  },
  avatarColor5: {
    color: '#CDFAE4',
  },
  avatarColor6: {
    color: '#F9D5FE',
  },
  avatarColor7: {
    color: '#BEEBFC',
  },
  avatarColor8: {
    color: '#FFDAEE',
  },
  avatarColor9: {
    color: '#FDF7BC',
  },
  avatarColor10: {
    color: '#DDDEFF',
  },
  tagColors: [
    { background: '#DBD1F5', color: '#24118A' },
    { background: '#EFFBDA', color: '#7AA049' },
    { background: '#F6DDD4', color: '#A38E4E' },
    { background: '#DEF0FC', color: '#862E29' },
    { background: '#FDF6D6', color: '#5E9A75' },
    { background: '#F9E9D4', color: '#8D532F' },
    { background: '#F1D5D6', color: '#862E29' },
    { background: '#F1EFE6', color: '#A38E4E' },
    { background: '#DFFBEF', color: '#5E9A75' },
  ],
};
