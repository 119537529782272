import { ThemeContext, colors } from '@zonofi/shared';
import React, { useContext } from 'react';

interface DetailsItemProps {
  heading: string;
  value: string;
  bold?: boolean;
  color?: string;
  fontSize?: number;
}

export const DetailsItem: React.FC<DetailsItemProps> = ({
  heading,
  value,
  bold = false,
  color,
  fontSize,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="item">
      <div style={{ ...theme.fontConfig.regular, color: colors.gray.primary }}>
        {heading}
      </div>
      <div
        style={{
          ...(bold ? theme.fontConfig.semiBold : theme.fontConfig.regular),
          color: color || theme.text.primary,
          ...(fontSize ? { fontSize: fontSize } : {}),
        }}
      >
        {value}
      </div>
    </div>
  );
};
