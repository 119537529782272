import { RouteType } from '../types';

export const checkRouteType = (type: string) => {
  switch (type) {
    case RouteType.SALES:
      return 'sales';
    case RouteType.PRINCIPAL:
      return 'principal';
    case RouteType.COMMUNICATION:
      return 'communication';
    default:
      return '';
  }
};
