import { ERROR_MESSAGES, REGEX } from '../constants';
import { ValidationResult, ValidationRule } from '../types';

export const valueRequired: ValidationRule = (
  value: string,
  error?: string
): ValidationResult => {
  if (value) return { success: true };
  return {
    success: false,
    error: `${ERROR_MESSAGES.REQUIRED}${error ? `\n${error}` : ''}`,
  };
};

const onValidate = (
  text: string,
  regex: RegExp,
  error: string
): ValidationResult => {
  if (!text) return { success: true };
  if (regex.test(text.trim())) return { success: true };
  return { success: false, error };
};

export const onlyDigits: ValidationRule = (value: string): ValidationResult => {
  const newValue = value.replace('$', '');
  return onValidate(newValue, REGEX.DIGITS, ERROR_MESSAGES.DIGITS_ONLY);
};

export const alphaNumericValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.ALPHA_NUMERIC, ERROR_MESSAGES.ALPHA_NUMERIC);
};

export const textInputValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.TEXT_INPUT, ERROR_MESSAGES.TEXT_INPUT);
};

export const mobileNumberValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.MOBILE, ERROR_MESSAGES.MOBILE);
};

export const emailValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.EMAIL, ERROR_MESSAGES.EMAIL);
};

export const emailOrMobileValidate: ValidationRule = (
  value: string
): ValidationResult => {
  if (value && !REGEX.EMAIL.test(value) && !REGEX.MOBILE.test(value))
    return { success: false, error: ERROR_MESSAGES.EMAIL_OR_MOBILE };
  return { success: true };
};

export const nameValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.NAME, ERROR_MESSAGES.NAME);
};

export const nameLenValidation: ValidationRule = (
  value: string
): ValidationResult => {
  if (value.length < 3)
    return { success: false, error: ERROR_MESSAGES.MIN_NAME_LENGTH };
  return { success: true };
};

export const amountValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.AMOUNT, ERROR_MESSAGES.AMOUNT);
};

export const dateValidation: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.DATE, ERROR_MESSAGES.DATE);
};

export const gstinValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.GSTIN, ERROR_MESSAGES.GSTIN);
};

export const panNumberValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.PAN, ERROR_MESSAGES.PAN);
};

export const aadharNumberValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.AADHAR, ERROR_MESSAGES.AADHAR);
};

export const pincodeValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.PINCODE, ERROR_MESSAGES.PINCODE);
};

export const bankAccountNumberValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.BANK_ACCOUNT, ERROR_MESSAGES.BANK_ACCOUNT);
};

export const bankIFSCValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.IFSC, ERROR_MESSAGES.IFSC);
};

export const chequeNumberValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.CHEQUE, ERROR_MESSAGES.CHEQUE);
};

export const vechileValidate: ValidationRule = (
  value: string
): ValidationResult => {
  return onValidate(value, REGEX.VEHICLE, ERROR_MESSAGES.VEHICLE);
};
