import { Theme, ThemeContext, colors } from '@zonofi/shared';
import { getFormattedAmount } from '@zonofi/common';
import { InvoiceLine } from '@zonofi/zono-money-store';
import React from 'react';
import Icon from 'react-native-easy-icon';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

interface ProductCardProps {
  line: InvoiceLine;
}

export const ProductCard: React.FC<ProductCardProps> = ({ line }) => {
  const { theme } = React.useContext(ThemeContext);
  const styles = getStyles(theme);
  const taxInfoPopupRef = React.useRef<PopupActions>(null);

  const getTaxInfoPopup = (taxAmount: number) => (
    <Popup
      ref={taxInfoPopupRef}
      trigger={
        <div>
          <Icon
            type="antdesign"
            name="infocirlce"
            size={10}
            color={colors.gray.opacity80}
          />
        </div>
      }
      on={['hover']}
      closeOnDocumentClick
      contentStyle={styles.popupContent}
    >
      <div style={{ display: 'flex', gap: 4 }}>
        <div style={styles.greyText}>Tax Amount:</div>
        <div style={styles.blackText12}>
          {getFormattedAmount(Number(taxAmount))}
        </div>
      </div>
    </Popup>
  );

  return (
    <main className="product-card" style={{ background: theme.primary }}>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <section
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            ...styles.greyText,
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 2,
            }}
          >
            <div>{`SKU: ${line.skuCode || '-'}`}</div>
          </div>
          <div>Quantity</div>
        </section>
        <section
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            ...styles.blackText,
          }}
        >
          <div>{line?.productTitle || '-'}</div>
          <div>{line?.invoicedQuantity}</div>
        </section>
      </section>
      <section style={styles.greyText}>{`Price: ${getFormattedAmount(
        Number(line.listPrice ?? 0)
      )}`}</section>
      <div style={styles.divider} />
      <footer
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <div style={styles.blackText12}>{`Total: ${getFormattedAmount(
            Number(line.lineTotalWithTax ?? 0)
          )}`}</div>
          {line?.invoicedQuantity && getTaxInfoPopup(line?.taxApplied || 0)}
        </div>
      </footer>
    </main>
  );
};

type stylekey =
  | 'container'
  | 'greyText'
  | 'blackText'
  | 'blackText12'
  | 'divider'
  | 'popupContent';

const getStyles = (theme: Theme): Record<stylekey, React.CSSProperties> => ({
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000014',

    borderRadius: 8,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  greyText: {
    ...theme.fontConfig.regular,
    fontSize: 12,
    color: colors.gray.primary,
  },
  blackText: {
    ...theme.fontConfig.regular,
    fontSize: 14,
    color: colors.raisinBlack.primary,
  },
  blackText12: {
    ...theme.fontConfig.regular,
    fontSize: 12,
    color: colors.raisinBlack.secondary,
  },
  divider: {
    height: 1,
    backgroundColor: colors.gray.opacity20,
    width: '100%',
    marginBlock: 8,
  },
  popupContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: 6,
    background: '#ffffff',
    boxShadow: '0px 3px 6px #00000014',
    border: '1px solid #dfe0e0',
    borderRadius: 6,
  },
});
