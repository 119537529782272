import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { ThemeContext } from '../contexts/ThemeContext';

type GradientButtonProps = {
  text: string | JSX.Element;
  height?: string;
  width?: string;
};

export const GradientButton: React.FC<GradientButtonProps> = ({
  text,
  height,
  width,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <LinearGradient
      colors={Object.values(theme.gradientButton)}
      start={{ y: 0.0, x: 0.0 }}
      end={{ y: 0.0, x: 1.0 }}
      style={[
        {
          borderRadius: 6,
          justifyContent: 'center',
          alignItems: 'center',
        },
        // @ts-ignore
        { height: height ? height : 36, width: width ? width : 135 },
      ]}
    >
      <View
        style={{
          borderRadius: 6,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            color: '#fff',
            letterSpacing: 0,
            fontFamily: theme.fontConfig.bold.fontFamily,

            fontSize: 14,
          }}
        >
          {text}
        </Text>
      </View>
    </LinearGradient>
  );
};
