import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function uploadInvoiceHistory() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.06"
      height="20.06"
      viewBox="0 0 20.06 20.06"
    >
      <Path
        id="Path_771"
        data-name="Path 771"
        d="M562.03,1901.015a.836.836,0,0,1,.836.836v3.777l3.866,3.093a.836.836,0,1,1-1.045,1.306l-4.179-3.343a.837.837,0,0,1-.313-.653v-4.179A.836.836,0,0,1,562.03,1901.015ZM552,1906.03a10.03,10.03,0,1,1,10.03,10.03A10.031,10.031,0,0,1,552,1906.03Zm10.03-8.358a8.358,8.358,0,1,0,8.358,8.358A8.358,8.358,0,0,0,562.03,1897.672Z"
        transform="translate(-552 -1896)"
        fill="#fff"
      />
    </Svg>
  );
}

export default uploadInvoiceHistory;
