import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function Incorrect({ width = '14', height = '14' }) {
  return (
    <Svg
      data-name="Group 116286"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <Path
        data-name="Path 193998"
        d="M7 0a7 7 0 11-7 7 7 7 0 017-7z"
        fill="#e85b49"
      />
      <Path
        data-name="Path 69425"
        d="M8.208 12.461a.709.709 0 001 1l1.625-1.625 1.625 1.625a.709.709 0 001-1l-1.625-1.625 1.63-1.626a.709.709 0 00-1-1l-1.628 1.623L9.21 8.208a.709.709 0 00-1 1l1.625 1.625z"
        transform="translate(-3.835 -3.835)"
        fill="#2d2f39"
      />
    </Svg>
  );
}

export default Incorrect;
