import React, { useContext, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import {
  StyleProp,
  StyleSheet,
  ViewStyle,
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  Animated,
} from 'react-native';
import { TexWithTruncate } from '../text-with-truncate/text-with-truncate';
import { ThemeContext } from '../contexts/ThemeContext';
import { SHARED_SVGS } from '../assets';

enum DocumentMimeTypes {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
  TXT = 'text/plain',
}
interface FileData {
  uri: string;
  type: string;
  data?: any;
}
export interface ExcelPreviewProps {
  fileData: FileData;
  containerStyle?: StyleProp<ViewStyle>;
}

export const ExcelPreview: React.FC<ExcelPreviewProps> = ({
  fileData,
  containerStyle,
}) => {
  const [excelData, setExcelData] = useState<any[]>([]);
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [extended, setExtended] = useState(false);
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;

  const animatedWidth = new Animated.Value(0);

  const animations = () => {
    Animated.timing(animatedWidth, {
      toValue: extended ? 1 : 0,
      duration: 120,
      useNativeDriver: false,
    }).start();
  };

  React.useEffect(() => {
    animations();
  }, [extended]);

  React.useEffect(() => {
    if (
      fileData?.type === DocumentMimeTypes.XLSX ||
      fileData?.type === DocumentMimeTypes.CSV ||
      fileData?.type === DocumentMimeTypes.TXT ||
      fileData?.type === DocumentMimeTypes.XLS
    ) {
      const base64Data = fileData?.data;
      const workbook = XLSX.read(base64Data, { type: 'base64' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      setExcelData(jsonData);
    }
  }, [fileData]);

  const renderPreview = () => {
    let filteredExcelData: string[][] = [];
    excelData.forEach((data) => {
      let count = 0;
      let excelData: string[] = [];
      data?.forEach?.((x: string, index: number) => {
        if (index === count) {
          excelData = [...excelData, x];
          count += 1;
        } else {
          const acc = Array(index - count).fill(' ');
          excelData = [...excelData, ...acc, x];
          count = index;
        }
      });
      filteredExcelData = [...filteredExcelData, excelData];
    });
    const maxCells = Math.max(...filteredExcelData.map((row) => row.length));
    const padRow = (row: string[], maxCells: number) => {
      const paddedRow = [...row];
      while (paddedRow.length < maxCells) {
        paddedRow.push('');
      }
      return paddedRow;
    };
    const headerIndex = filteredExcelData[0]?.length > 1 ? 0 : 1;
    const paddedHeader = padRow(filteredExcelData[0], maxCells);

    return (
      <ScrollView
        style={[styles.container, { marginLeft: extended ? 48 : 0 }]}
        showsVerticalScrollIndicator={true}
        showsHorizontalScrollIndicator={true}
      >
        <View style={{ paddingHorizontal: 10, overflow: 'scroll' }}>
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
            }}
          >
            <thead>
              <tr>
                {paddedHeader.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      padding: 10,
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ccc',
                      minWidth: 100,
                      fontSize: 16,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <TexWithTruncate
                      title={header}
                      truncateValue={extended ? 40 : 30}
                      fontFamily={theme.fontConfig.semiBold.fontFamily}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredExcelData.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex} style={{ width: 'auto' }}>
                  {padRow(row, maxCells).map((cell, cellIndex) => {
                    return (
                      <td
                        key={cellIndex}
                        style={{
                          padding: 10,
                          minWidth: 100,
                          border: '1px solid #ccc',
                          fontFamily:
                            headerIndex === rowIndex + 1
                              ? theme.fontConfig.semiBold.fontFamily
                              : theme.fontConfig.regular.fontFamily,
                        }}
                      >
                        <TexWithTruncate
                          title={cell ? cell : ' '}
                          fontFamily={
                            headerIndex === rowIndex + 1
                              ? theme.fontConfig.semiBold.fontFamily
                              : theme.fontConfig.regular.fontFamily
                          }
                          truncateValue={extended ? 40 : 25}
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </View>
      </ScrollView>
    );
  };

  const renderPdfPreview = () => {
    return (
      <View
        style={[
          { width: '97.5%', height: '100%' },
          { marginLeft: extended ? 48 : 0 },
        ]}
      >
        <iframe
          src={fileData?.uri}
          width="100%"
          height="100%"
          title="Example Iframe"
          className=""
          style={{ margin: 0, borderWidth: 0 }}
        ></iframe>
      </View>
    );
  };

  return (
    <Animated.View
      style={[
        styles.container1,
        {
          backgroundColor: theme.drawer.background,
          paddingLeft: 3,
          paddingTop: 10,
          width: animatedWidth.interpolate({
            inputRange: [0, 1],
            outputRange: ['35%', '100%'],
          }),
        },
      ]}
    >
      <div
        style={{
          cursor: 'pointer',
          position: 'absolute',
          left: extended ? 0 : -40,
          top: windowHeight / 2 - 358 / 2,
          transform: extended ? 'scaleX(-1)' : 'none',
          transition: 'transform 0.3s ease',
        }}
        onClick={() => setExtended(!extended)}
      >
        <SHARED_SVGS.ExtendedIcon
          text={extended ? ' ' : 'EXTENDED PREV'}
          extended={true}
        />
      </div>
      {extended && (
        <TouchableOpacity onPress={() => setExtended(!extended)}>
          <p
            style={{
              whiteSpace: 'nowrap',
              top: windowHeight / 2 - 214 / 2,
              transform: 'rotate(270deg)',
              ...styles.shotendText,
            }}
          >
            SHORTEND PREV
          </p>
        </TouchableOpacity>
      )}
      {fileData?.type === DocumentMimeTypes?.PDF
        ? renderPdfPreview()
        : renderPreview()}
    </Animated.View>
  );
};

export default ExcelPreview;

const styles = StyleSheet.create({
  container: {
    width: '99.5%',
  },
  container1: {
    zIndex: 9999999,
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 'auto',
    right: 0,
    flexDirection: 'row',
    borderLeftWidth: 0.5,
    borderLeftColor: '#DFE0E0',
    borderStyle: 'solid',
    elevation: 3, // Android elevation
    shadowColor: '#00000029',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.6,
    shadowRadius: 6,
  },
  shotendText: {
    position: 'absolute',
    left: -40,
    zIndex: 900000000,
    fontSize: 12,
    fontFamily: 'SegoeUI-Bold, Segoe UI',
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '0.12em',
  },
});
