import { Platform } from 'react-native';
import localforage from 'localforage';
import AsyncStorage from '@react-native-async-storage/async-storage';

const getLocalStorage = () => {
  if (Platform.OS === 'web') {
    return localforage;
  } else {
    return AsyncStorage;
  }
};

export const localAppStorage = getLocalStorage();
