export enum InputKeyboardType {
  TEXT = 'default',
  NUMBER = 'numeric',
  EMAIL = 'email-address',
  PHONE = 'phone-pad',
}
export enum TxnStatus {
  CANCELLED = 'CANCELLED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum CheckOutType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum PaymentRequestStatusEnum {
  PENDING = 'P',
  FAILED = 'F',
  SUCCESS = 'S',
  CANCELLED = 'C',
}

export enum TransactionStatus {
  CANCELLED = 'CANCELLED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum OtpStatus {
  INCORRECT_OTP = 'INCORRECT OTP',
  WARNING_OTP = 'OTP ATTEMPTS',
  OTP_RESENT = 'OTP RESENT',
}
export enum RewardEntity {
  COINS = 'COINS',
  CASHBACK = 'CASHBACK',
}

export enum UrlParamKeys {
  PAYMENT_REQUEST_ID = 'paymentRequestId',
  PAYMENT_APP = 'app',
  ORDER_ID = 'order_id',
}

export enum PaymentMode {
  UPI = 'UPI',
  NET_BANKING = 'NB',
  OTHERS = 'OTHERS',
  CHEQUE = 'CHQ',
  DEMAND_DRAFT = 'DD',
  BANK_TRANSFER = 'BNTR',
  ONLINE = 'ONLINE',
  CASH = 'CASH',
  ONLINE_WALLET = 'APP',
  CREDIT_CARD = 'CC',
  DEBIT_CARD = 'DC',
  TRANSFER = 'TF',
  CREDIT_NOTE = 'CN',
  credit_card = 'credit_card',
  net_banking = 'net_banking',
  upi = 'upi',
  wallet = 'wallet',
  none = 'none',
}

export enum PaymentProvider {
  CASHFREE = 'CF',
  CFAUTOCOLLECT = 'CFAUTOCOLLECT',
}

export enum PaymentOptionsRoute {
  QR = 'qr',
  TIMER = 'timer',
}

export enum BrowserType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export enum SummaryCardType {
  CHECKIN = 'check-in',
  CASH = 'cash',
}

export enum InvoiceDetailsCardType {
  NOW = 'now',
  NEXT = 'next',
}

export enum FCButtonVariants {
  PLAIN = 'plain',
  GRADIENT = 'gradient',
}

export enum RecordPaymentInputFormKeys {
  AMOUNT = 'amount',
  CHQNUMBER = 'cheque-number',
  CHQDAY = 'cheque-day',
  BANK_BRANCH_NAME = 'bank-branch-name',
  NOTES = 'Notes',
}

export enum PaymentType {
  UPI = 'upi',
  BANK = 'bank',
}

export enum PaymentSource {
  FROM = 'from',
  TO = 'to',
}

export enum DayBookSummaryCardType {
  COLLECTION = 'collection',
  CHECKINS = 'check-ins',
  PENDINGINVOICE = 'pending-invoice',
}

export enum OrderStatusEnum {
  CREATED = 'OrderCreated',
  ADDING_ITEMS = 'AddingItems',
  ARRANGING_PAYMENT = 'ArrangingPayment',
  PAYMENT_AUTHORIZED = 'PaymentAuthorized',
  PAYMENT_SETTLED = 'PaymentSettled',
  PARTIALLY_SHIPPED = 'PartiallyShipped',
  SHIPPED = 'Shipped',
  PARTIALLY_DELIVERED = 'PartiallyDelivered',
  MODIFYING = 'Modifying',
  ARRANGING_ADDITIONAL_PAYMENT = 'ArrangingAdditionalPayment',
  CANCELLED = 'Cancelled',
  CONFIRMED = 'Confirmed',
  BILLED = 'Billed',
  WAITING_FOR_CONFIRMATION = 'WaitingForCNF',
  SUBMITTED_BY_SALES = 'SubmittedBySales',
  SUBMITTED_BY_CFA = 'SubmittedByCFA',
  SUBMITTED_BY_CUSTOMER = 'SubmittedByCustomer',
  SUBMITTED = 'SUBMITTED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_SUBMITTED = 'ORDER_SUBMITTED',
  MAPPED = 'MAPPED',
  RE_MAPPED = 'RE_MAPPED',
  UNMAPPED = 'UNMAPPED',
  APPROVED = 'Approved',
  APPROVED_BY_CFA = 'APPROVED BY CFA',
  REJECTED_BY_CFA = 'REJECTED_BY_CFA',
  PRODUCT_NOT_Found = 'PRODUCT_NOT_FOUND',
  TEMPLATE_NOT_FOUND = 'TEMPLATE_NOT_FOUND',
  TEMPLATE_NOT_AVAILABLE = 'TEMPLATE_NOT_AVAILABLE',
  WAITING_FOR_CONFIRMATION_ = 'WAITING_FOR_CONFIRMATION',
  TICKET_CREATED = 'TICKET CREATED',
  TICKET_RESOLVED = 'TICKET RESOLVED',
  BILLED_ = 'BILLED',
  DISPATCHED = 'DISPATCHED',
  PARSING_FAILED = 'PARSING_FAILED',
  MAPPING_FAILED = 'MAPPING_FAILED',
  DRAFT = 'DRAFT',
  SUBMITTEDBYCUSTOMER = 'SUBMITTEDBYCUSTOMER',
  SUBMITTED_2 = 'Submitted',
}
