import { colors } from '../colors/colors';
import React from 'react';

import { View, StyleSheet } from 'react-native';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { heightPercentageToDPBoundsViewPort as hvp } from '../helpers';
import { getDefaultHeaderHeight } from '@react-navigation/elements';
import {
  useSafeAreaFrame,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';

/* eslint-disable-next-line */
export interface HeaderProps {
  leftContainer?: JSX.Element;
  rightContainer?: JSX.Element | undefined;
}

export const Header: React.FC<HeaderProps> = ({
  leftContainer,
  rightContainer,
}) => {
  const frame = useSafeAreaFrame();
  const insets = useSafeAreaInsets();

  const headerHeight = getDefaultHeaderHeight(frame, false, insets.top);

  return (
    <View style={[styles.container, { height: headerHeight }]}>
      {leftContainer && leftContainer}
      {rightContainer && rightContainer}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.richBlack.color,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: wp(3),
    alignContent: 'center',
    paddingTop: hvp(20),
  },
});
