import React, { useContext, useMemo, useState } from 'react';
import { Drawer } from '@mui/material';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { Button } from '../button/button';
import { colors } from '../colors/colors';
import { WarningModal } from '../warning-modal/warning-modal';
import { ThemeContext } from '../contexts/ThemeContext';
import TabNavigator, { TabBarItem } from '../tab-navigator/tab-navigator';
import { SHARED_SVGS } from '../assets';

export interface AllInclusiveDrawerProps {
  open: boolean;
  closeDrawer: (refreshList: boolean) => void;
  type: 'new' | 'edit' | 'details';
  isLoading: boolean;
  isError: boolean;
  btnIsLoading: boolean;
  btnText: string;
  isBtnDisabled: boolean;
  onSubmit: () => any;
  tabs: TabBarItem[];
  formEdited: boolean;
  setFormEdited: (val: boolean) => any;
  drawerName: string;
  customHeaderName?: string;
}

export const AllInclusiveDrawer = ({
  open,
  closeDrawer,
  type,
  isLoading,
  isError,
  btnIsLoading,
  btnText,
  isBtnDisabled,
  onSubmit,
  tabs,
  formEdited,
  setFormEdited,
  drawerName,
  customHeaderName,
}: AllInclusiveDrawerProps) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const [isHovered, setIsHovered] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const handlePressIn = () => {
    setIsHovered(true);
  };

  const handlePressOut = () => {
    setIsHovered(false);
  };

  const onCloseDrawer = () => {
    if (formEdited) {
      setShowWarningModal(true);
    } else {
      //   setSelectedProductDetails(null);
      closeDrawer(false);
    }
  };

  const headingText = useMemo(() => {
    switch (type) {
      case 'new':
        return 'Add New ' + drawerName;
      case 'edit':
        return customHeaderName ? customHeaderName : 'Edit ' + drawerName;
      case 'details':
        return 'View Item';
      default:
        return '-';
    }
  }, [type, drawerName, customHeaderName]);

  return (
    <Drawer
      open={open}
      onClose={onCloseDrawer}
      anchor="right"
      PaperProps={{
        sx: { width: '35%', overflowX: 'hidden', zIndex: 999999 },
      }}
    >
      <div
        style={{
          backgroundColor: theme.drawer.header,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 20,
        }}
      >
        <span
          style={{
            color: theme.text.primary,
            fontSize: 20,
            ...theme.fontConfig.semiBold,
          }}
        >
          {headingText}
        </span>
        {isHovered ? (
          <img
            src={SHARED_SVGS.HoverActive}
            onMouseLeave={handlePressOut}
            onClick={() => onCloseDrawer()}
            style={{ cursor: 'pointer' }}
            alt=""
          />
        ) : (
          <img
            src={SHARED_SVGS.Default}
            onMouseEnter={handlePressIn}
            onClick={() => onCloseDrawer()}
            style={{ cursor: 'pointer' }}
            alt=""
          />
        )}
      </div>
      {isLoading ? (
        <LoadingSpinner customStyle={{ flex: 1 }} size={'large'} />
      ) : isError ? (
        <span
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...theme.fontConfig.regular,
          }}
        >
          Something went wrong!
        </span>
      ) : (
        <>
          <TabNavigator TabList={tabs} hideTabHeader={tabs?.length === 1} />
          <Button
            text={btnIsLoading ? 'Please Wait...' : btnText}
            variant={!isBtnDisabled ? 'gradient' : 'plain'}
            plainColor={colors.chineseWhite.primary}
            gradientColors={Object.values(theme.gradientButton)}
            onPress={onSubmit}
            disabled={isBtnDisabled}
            textStyle={{
              ...theme.fontConfig.regular,
              textAlign: 'center',
              fontSize: 16,
              color: colors.white.color,
            }}
            outerStyle={{
              paddingHorizontal: 15,
              marginVertical: 10,
              paddingTop: 10,
              shadowColor: '#000000',
              shadowOffset: { height: -6, width: 0 },
              shadowOpacity: 0.1,
              shadowRadius: 20,
            }}
          />
        </>
      )}
      <WarningModal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        heading="Warning!"
        content={
          'The changes made will be discarded if you close. Do you still want to close it?'
        }
        cancelButton={{
          text: 'Discard',
          onPress: () => {
            setShowWarningModal(false);
            setFormEdited(false);
            closeDrawer(false);
          },
        }}
        primaryButton={{
          text: 'Continue Editing',
          onPress: () => setShowWarningModal(false),
        }}
      />
    </Drawer>
  );
};
