import {
  ErrorVariants,
  showToast,
  useLazyGetPaymentQuery,
} from '@zonofi/zono-money-store';
import { useDispatch } from 'react-redux';
import Strings from '../constants/strings';
import { PaymentRequestStatusEnum } from '../types/enum';

export const usePaymentVerificationStatus = (
  navigate: (status: PaymentRequestStatusEnum) => void,
  paymentRequestId: string | null
) => {
  const [getPayment, paymentResponse] = useLazyGetPaymentQuery();
  const dispatch = useDispatch();

  const onPaymentSuccess = (id: string, status: string) => {
    switch (status) {
      case PaymentRequestStatusEnum.SUCCESS:
        navigate(PaymentRequestStatusEnum.SUCCESS);
        break;
      case PaymentRequestStatusEnum.PENDING:
        getPayment(id);
        break;
      case PaymentRequestStatusEnum.FAILED:
        dispatch(
          showToast({
            canDisplay: true,
            message: Strings.PAYMENT_FAILURE_MESSAGE,
            responseStatus: Strings.PAYMENT_FAILURE_HEADER_MESSAGE,
            type: ErrorVariants.DANGER,
          })
        );
        break;
    }
  };

  const getPaymentRequest = () => {
    if (paymentResponse.isSuccess && paymentResponse.data && paymentRequestId) {
      onPaymentSuccess(paymentRequestId, paymentResponse.data.status);
    } else if (paymentResponse.isError) {
      dispatch(
        showToast({
          canDisplay: true,
          message: Strings.PAYMENT_FAILURE_MESSAGE,
          responseStatus: Strings.PAYMENT_FAILURE_HEADER_MESSAGE,
          type: ErrorVariants.DANGER,
        })
      );
    } else {
      if (paymentRequestId) {
        getPayment(paymentRequestId);
      }
    }
  };

  return {
    getPaymentRequest,
    paymentResponse,
  };
};
