//@ts-nocheck
import { useLinkTo } from '@react-navigation/native';
import React, { useContext } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';
import Popup from 'reactjs-popup';
import { ThemeContext } from '../contexts/ThemeContext';
import { colors } from '../colors/colors';
import { Hoverable } from 'react-native-web-hover';
import { SHARED_SVGS } from '../assets';

/* eslint-disable-next-line */
export interface CustomerListActionsProps {
  rowData: any;
  inviteViaEmail: any;
  fetchCustomerStatus: any;
  setEditGroupModal: any;
  setData: any;
  alignment: string;
  shareLinkHandler: () => void;
  onClickEdit: (id: number) => any;
}

export const CustomerListActions: React.FC<CustomerListActionsProps> = ({
  rowData,
  inviteViaEmail,
  fetchCustomerStatus,
  setEditGroupModal,
  setData,
  alignment,
  shareLinkHandler,
  onClickEdit,
}) => {
  const linkTo = useLinkTo();

  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const inviteOptions = (inviteId: any, senderWorkspaceId: any) => {
    return (
      <View style={styles.popupContainer}>
        <Hoverable>
          {({ hovered }) => (
            <TouchableOpacity
              onPress={() =>
                inviteViaEmail &&
                inviteViaEmail({
                  inviteId,
                  senderWorkspaceId,
                  reInviteType: 'EMAIL',
                })
              }
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: hovered ? '#4481B41F' : 'white',
                },
                styles.paddingRow,
              ]}
            >
              <Image
                resizeMode="contain"
                source={{ uri: SHARED_SVGS.Email }}
                style={{
                  height: 16,
                  width: 16,
                  marginRight: 10,
                }}
              />
              <Text
                style={{
                  fontSize: 16,
                  color: theme.text.primary,
                  ...theme.fontConfig.regular,
                }}
              >
                Invite with email
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>
        <Hoverable>
          {({ hovered }) => (
            <TouchableOpacity
              onPress={() =>
                inviteViaEmail &&
                inviteViaEmail({
                  inviteId,
                  senderWorkspaceId,
                  reInviteType: 'SMS',
                })
              }
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: hovered ? '#4481B41F' : 'white',
                },
                styles.paddingRow,
              ]}
            >
              <Image
                source={{ uri: SHARED_SVGS.Sms }}
                resizeMode="contain"
                style={{
                  height: 16,
                  width: 16,
                  marginRight: 10,
                }}
              />
              <Text
                style={{
                  fontSize: 16,
                  color: theme.text.primary,
                  ...theme.fontConfig.regular,
                }}
              >
                Invite with SMS
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>
      </View>
    );
  };

  const renderInviteOptions = (inviteId: string, senderWorkspaceId: string) => {
    return (
      <Popup
        trigger={
          <View>
            <Hoverable>
              {({ hovered }) => (
                <View
                  style={[
                    {
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '8px, 0px',
                      zIndex: 2,
                      //@ts-ignore
                      cursor: 'pointer',
                      backgroundColor: hovered ? '#4481B41F' : 'white',
                    },
                    styles.paddingRow,
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: theme.text.primary,
                      ...theme.fontConfig.regular,
                    }}
                  >
                    Invite
                  </Text>
                </View>
              )}
            </Hoverable>
          </View>
        }
        position={['left top']}
        on={['click']}
        arrow={false}
        closeOnDocumentClick
      >
        {inviteOptions(inviteId, senderWorkspaceId)}
      </Popup>
    );
  };

  const renderReInviteOptions = (
    inviteId: string,
    senderWorkspaceId: string
  ) => {
    return (
      <Popup
        trigger={
          <View>
            <Hoverable>
              {({ hovered }) => (
                <View
                  style={[
                    {
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '8px, 0px',
                      zIndex: 2,
                      //@ts-ignore
                      cursor: 'pointer',
                      backgroundColor: hovered ? '#4481B41F' : 'white',
                    },
                    styles.paddingRow,
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: theme.text.primary,
                      ...theme.fontConfig.regular,
                    }}
                  >
                    Re-Invite
                  </Text>
                </View>
              )}
            </Hoverable>
          </View>
        }
        position={['left top']}
        on={['click']}
        arrow={false}
        closeOnDocumentClick
      >
        {inviteOptions(inviteId, senderWorkspaceId)}
      </Popup>
    );
  };

  const onNavigationToGroup = (item: any) => {
    linkTo(
      `/admin/invites/selectionhiearachy?id=${item?.id}&senderWorkspaceId=${item.senderWorkspaceId}&clientWorkspaceId=${item?.senderUserId}&from=zonomoney`
    );
  };

  const renderPopOver = (item: any) => {
    const { senderWorkspaceId } = item;
    const inviteId = item?.id;
    const isActive = item?.isActive === 1 ? true : false;
    return (
      <Popup
        trigger={
          <span
            style={{
              color: '#9295a5',
              fontSize: 24,
              cursor: 'pointer',
            }}
          >
            &#8942;
          </span>
        }
        position={['left top']}
        on={['hover']}
        closeOnDocumentClick
        nested
      >
        <View style={styles.popupContainer}>
          <Text
            style={[
              {
                fontSize: 12,
                fontFamily: theme.fontConfig.regular.fontFamily,
                color: colors.gray.opacity80,
              },
              styles.paddingRow,
            ]}
          >
            More Options
          </Text>

          {onClickEdit && (
            <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity
                  style={[
                    {
                      backgroundColor: hovered ? '#4481B41F' : 'white',
                    },
                    styles.paddingRow,
                  ]}
                  onPress={() => onClickEdit && onClickEdit(inviteId)}
                >
                  <Text
                    style={[
                      {
                        fontSize: 16,
                        color: theme.text.primary,
                        ...theme.fontConfig.regular,
                      },
                    ]}
                  >
                    Edit
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          )}

          {item.status === 'N' &&
            renderInviteOptions(inviteId, senderWorkspaceId)}

          {item.status === 'P' &&
            renderReInviteOptions(inviteId, senderWorkspaceId)}
          <Hoverable>
            {({ hovered }) => (
              <TouchableOpacity
                style={[
                  {
                    backgroundColor: hovered ? '#4481B41F' : 'white',
                  },
                  styles.paddingRow,
                ]}
                onPress={() =>
                  fetchCustomerStatus &&
                  fetchCustomerStatus({
                    workspaceId: item?.id,
                    isActive: !isActive,
                  })
                }
              >
                <Text
                  style={[
                    {
                      fontSize: 16,
                      color: theme.text.primary,
                      ...theme.fontConfig.regular,
                    },
                  ]}
                >
                  {item?.isActive === 1 ? `De-active User` : `Active User`}
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>
          {/* <Hoverable>
            {({ hovered }) => (
              <TouchableOpacity
                style={[
                  {
                    backgroundColor: hovered ? '#4481B41F' : 'white',
                  },
                  styles.paddingRow,
                ]}
                onPress={() => onNavigationToGroup(item)}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: theme.text.primary,
                    ...theme.fontConfig.regular,
                  }}
                >
                  Assign to a Group
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable> */}
          <Hoverable>
            {({ hovered }) =>
              item?.customerGroups &&
              item?.customerGroups?.length > 0 && (
                <TouchableOpacity
                  style={[
                    {
                      backgroundColor: hovered ? '#4481B41F' : 'white',
                    },
                    styles.paddingRow,
                  ]}
                  onPress={() => {
                    setEditGroupModal(true);
                    setData(item);
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: theme.text.primary,
                      ...theme.fontConfig.regular,
                    }}
                  >
                    Edit Assigned Groups
                  </Text>
                </TouchableOpacity>
              )
            }
          </Hoverable>
          <Hoverable>
            {({ hovered }) =>
              item?.isActive && item?.status !== 'N' ? (
                <TouchableOpacity
                  style={[
                    {
                      backgroundColor: hovered ? '#4481B41F' : 'white',
                    },
                    styles.paddingRow,
                  ]}
                  onPress={() => {
                    setData(item);
                    shareLinkHandler();
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: theme.text.primary,
                      ...theme.fontConfig.regular,
                    }}
                  >
                    Share Link
                  </Text>
                </TouchableOpacity>
              ) : null
            }
          </Hoverable>
        </View>
      </Popup>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: alignment,
      }}
    >
      {renderPopOver(rowData)}
    </div>
  );
};

export default CustomerListActions;

const styles = StyleSheet.create({
  paddingRow: {
    paddingLeft: 8,
    paddingVertical: 6,
    paddingRight: 12,
  },
  popupContainer: {
    shadowColor: colors.black.color,
    shadowOpacity: 0.29,
    shadowRadius: 12,
    shadowOffset: { height: 4, width: 0 },
    backgroundColor: colors.white.color,
    borderWidth: 1,
    borderColor: '#C9CCD680',
    borderRadius: 4,
  },
});
