import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function ArrowUpIcon({ color = '#a1a3b4', width = 24, height = 24 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
    >
      <Path
        d="M25 42c-9.4 0-17-7.6-17-17S15.6 8 25 8s17 7.6 17 17-7.6 17-17 17zm0-32c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.7-15-15-15z"
        fill={color}
        fillRule="evenodd"
      />
      <Path
        d="M33.3 26.7L25 18.4l-8.3 8.3-1.4-1.4 9.7-9.7 9.7 9.7z"
        fill={color}
        fillRule="evenodd"
      />
      <Path d="M24 17h2v17h-2z" fill={color} fillRule="evenodd" />
    </Svg>
  );
}

export default ArrowUpIcon;
