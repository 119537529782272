import { zonoApi } from '../api';
import {
  ProductListResponse,
  ProductsRequestBody,
  ProductsRequestParams,
} from './product-model';

export const ProductApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.mutation<
      ProductListResponse,
      {
        params: ProductsRequestParams;
        workspaceId: string;
        body: ProductsRequestBody;
      }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/products/search/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
        params: args.params,
      }),
    }),
  }),
});

export const { useGetProductsMutation } = ProductApi;
