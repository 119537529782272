import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function DashboardIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.479}
      height={19}
      viewBox="0 0 21.479 19"
      {...props}
    >
      <Path
        data-name="Path 210748"
        d="M16999.357 228.637a.785.785 0 01.164.424.892.892 0 01-.082.46.783.783 0 01-.33.349.6.6 0 01-.414.13h-9.912a.6.6 0 01-.414-.13.793.793 0 01-.33-.349.892.892 0 01-.082-.46.785.785 0 01.164-.424l4.957-5.783a.668.668 0 01.332-.215.747.747 0 01.66 0 .67.67 0 01.33.215zM17002 211h-16.521a2.07 2.07 0 00-.908.186 2.42 2.42 0 00-.826.537 3.009 3.009 0 00-.578.805 3.585 3.585 0 00-.166.95v11.565a3.585 3.585 0 00.166.95 3.009 3.009 0 00.578.805 2.42 2.42 0 00.826.537 2.07 2.07 0 00.908.186h1.24c.082 0 .164-.015.164-.04.084-.025.166-.06.166-.1l4.791-5.6a3.806 3.806 0 01.826-.641 2.653 2.653 0 012.148 0 3.9 3.9 0 01.826.641l4.789 5.6c0 .045.082.08.168.1 0 .025.082.039.164.04h1.239a2.756 2.756 0 00.99-.186 3.173 3.173 0 00.744-.537 3.015 3.015 0 00.58-.805 3.664 3.664 0 00.164-.95v-11.565a3.664 3.664 0 00-.164-.95 3.015 3.015 0 00-.58-.805 3.174 3.174 0 00-.744-.537 2.756 2.756 0 00-.99-.186z"
        transform="translate(-16983 -211)"
        fill={props.color}
      />
    </Svg>
  );
}

export default DashboardIcon;
