import { zonoApi } from '../api';
import { Coins, Reward } from './reward-model';

export const rewardApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getRewardCatalogue: builder.query<Reward, void>({
      query: () => ({
        url: 'rewards/catalogue/new',
        method: 'GET',
      }),
    }),
    getZonoCoins: builder.query<Coins, void>({
      query: () => ({
        url: 'rewards/coins',
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetRewardCatalogueQuery, useGetZonoCoinsQuery } = rewardApi;
