import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Modal } from 'react-native';
import Icon from 'react-native-easy-icon';
import { TextInput } from 'react-native-paper';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { colors } from '../colors/colors';
import { fontConfig } from '../fonts/fonts';
// import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { Calendar, DateRangePicker, Range } from 'react-date-range';
import { Button } from '../button/button';
import { ThemeContext } from '../contexts/ThemeContext';
import EStyleSheet from 'react-native-extended-stylesheet';

export enum DateTimePickerVariants {
  SINGLE = 'single',
  RANGE = 'range',
}

/* eslint-disable-next-line */
export interface DateTimePickerProps {
  variant?: DateTimePickerVariants;
  displayFormat: string;
  label?: string;
  setValue?: (value: string) => void;
  valueFormat?: string;
  initialValue?: string;
  disabled?: boolean;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  variant = DateTimePickerVariants.SINGLE,
  displayFormat,
  label,
  setValue,
  valueFormat,
  initialValue,
  disabled,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [focus, setFocus] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [date, setDate] = useState<Date>(new Date());
  const [range, setRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  useEffect(() => {
    if (initialValue && !_.isEmpty(initialValue)) setDisplayValue(initialValue);
    else setDisplayValue('');
  }, [initialValue]);

  const upDateDisplayValue = () => {
    setDisplayValue(formatValue(displayFormat) ?? '');
    if (setValue) {
      if (valueFormat) {
        setValue(formatValue(valueFormat) ?? '');
      } else {
        setValue(formatValue() ?? '');
      }
    }
  };

  const formatValue = (format?: string) => {
    if (variant === DateTimePickerVariants.SINGLE) {
      if (format) {
        return moment(date).format(format);
      } else {
        return moment(date).toISOString();
      }
    } else if (variant === DateTimePickerVariants.RANGE && range) {
      if (format) {
        return `${moment(range.startDate).format(format)} to ${moment(
          range.endDate
        ).format(format)}`;
      } else {
        return (
          moment(range.startDate).toISOString(),
          moment(range.endDate).toISOString()
        );
      }
    }
    return '';
  };

  return (
    <>
      <View style={{ marginVertical: hp(1) }}>
        <TouchableOpacity
          style={styles.container}
          onPress={() => {
            setFocus(true);
          }}
          disabled={disabled}
        >
          <TextInput
            label={<Text style={styles.regularText14Grey}>{label}</Text>}
            theme={{
              colors: {
                primary: colors.transparent.color,
                text: theme.text.primary,
                accent: colors.transparent.color,
                background: colors.transparent.color,
              },
              fonts: {
                regular: styles.boldText16,
              },
            }}
            value={displayValue}
            editable={false}
            style={{ width: '80%' }}
            underlineColor={colors.transparent.color}
          />
          <Icon
            name="calendar"
            type="feather"
            size={15}
            color={colors.manatee.color}
          />
        </TouchableOpacity>
      </View>
      <Modal
        animationType="fade"
        transparent
        visible={focus}
        onDismiss={() => setFocus(false)}
      >
        <View style={styles.blur} />
        <View style={styles.modalContainer}>
          <View
            style={{
              // height: '60%',
              width: '40%',
              justifyContent: 'center',
              backgroundColor: colors.darkGunmetal.color,
              borderRadius: 10,
              paddingHorizontal: '10%',
              paddingVertical: '2%',
            }}
          >
            <View
              style={{
                alignItems: 'center',
              }}
            >
              <Text style={styles.boldText24}>
                {variant === DateTimePickerVariants.RANGE
                  ? `Select Date Range`
                  : `Select Date`}
              </Text>
              {variant === DateTimePickerVariants.RANGE ? (
                <DateRangePicker
                  ranges={[range]}
                  onChange={(date) => {
                    setRange({
                      startDate: date['selection'].startDate,
                      endDate: date['selection'].endDate,
                      key: 'selection',
                    });
                  }}
                  showMonthAndYearPickers={false}
                  rangeColors={[colors.venetianRed.color]}
                />
              ) : (
                <Calendar
                  date={date}
                  onChange={(date) => setDate(date)}
                  color={colors.venetianRed.color}
                />
              )}
            </View>
            <Button
              gradientColors={Object.values(colors.blueGreenLinearGradient)}
              variant="gradient"
              text="Apply"
              onPress={() => {
                upDateDisplayValue();
                setFocus(false);
              }}
              textStyle={{
                textAlign: 'center',
                ...fontConfig.default.bold,
                fontSize: 20,
                color: colors.white.color,
              }}
            />
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = EStyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 10,
    paddingRight: wp(2),
    paddingLeft: wp(1),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: colors.onyx.color,
  },
  regularText14Grey: {
    ...fontConfig.default.regular,
    fontSize: 14,
    color: colors.manatee.color,
  },
  boldText16: {
    ...fontConfig.default.regular,
    fontSize: 16,
    color: colors.white.color,
  },
  boldText24: {
    ...fontConfig.default.regular,
    fontSize: 24,
    color: colors.white.color,
  },
  tagContainer: {
    borderWidth: 0.5,
    borderRadius: 2,
    paddingHorizontal: 6,
    paddingVertical: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 3,
    marginVertical: 3,
    alignItems: 'center',
  },
  tagText: {
    ...fontConfig.default.regular,
    fontSize: 14,
    color: colors.white.color,
    lineHeight: 25,
  },
  list: {
    maxHeight: hp(30),
    position: 'absolute',
    backgroundColor: colors.onyx.color,
    width: '100%',
    borderColor: colors.manatee.color,
    borderRadius: 1,
  },
  blur: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backdropFilter: 'saturate(180%) blur(1px)',
    backgroundColor: 'rgba(255, 255, 255, 0.18)',
  },
  modalContainer: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default DateTimePicker;
