import React from 'react';
import { useAppSelector, WEBROUTES } from '@zonofi/zono-money-helpers';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import {
  ErrorScreen,
  InviteLoadingScreen,
  OrderDetailsScreen,
  ViewInvoiceScreen,
  ReviewInvoicesScreen,
  CatalogueScreen,
  FacebookScreen,
  OrderSuccessfull,
  Opps,
} from '../../screens';
import { isGuestAuthenticated } from '@zonofi/zono-money-store';

export const AppNavigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<ErrorScreen />} />
        <Route
          path={WEBROUTES.CUSTOMER_INVITE_LOADING_SCREEN}
          element={<InviteLoadingScreen />}
        >
          <Route path=":inviteId" element={<InviteLoadingScreen />} />
        </Route>
        <Route path={WEBROUTES.OPPS} element={<Opps />} />
        <Route path={WEBROUTES.FACEBOOK} element={<FacebookScreen />}>
          <Route path=":id" element={<FacebookScreen />} />
        </Route>
        <Route element={<RequireGuestAuth />}>
          <Route
            path={WEBROUTES.ORDER_SUCCESSFULL}
            element={<OrderSuccessfull />}
          />
          <Route
            path={WEBROUTES.INVOICE_SCREEN}
            element={<ViewInvoiceScreen />}
          >
            <Route path=":invoiceId" element={<ViewInvoiceScreen />} />
          </Route>
          <Route path={WEBROUTES.ORDER} element={<OrderDetailsScreen />}>
            <Route path=":orderId" element={<OrderDetailsScreen />} />
          </Route>
          <Route
            path={WEBROUTES.REVIEW_INVOICES}
            element={<ReviewInvoicesScreen />}
          />
          <Route path={WEBROUTES.CAMPAIGN} element={<CatalogueScreen />}>
            <Route path=":campaignId" element={<CatalogueScreen />} />
          </Route>
          <Route path={WEBROUTES.PRICELIST} element={<CatalogueScreen />}>
            <Route path=":pricelistId" element={<CatalogueScreen />} />
          </Route>
          <Route path={WEBROUTES.CATALOGUE} element={<CatalogueScreen />} />
          <Route
            path={WEBROUTES.ORDER_SUCCESSFULL}
            element={<OrderSuccessfull />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const RequireGuestAuth = () => {
  const isAuthenticated = useAppSelector(isGuestAuthenticated);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={WEBROUTES.ERROR_SCREEN} state={{ from: location }} />;
  }

  return <Outlet />;
};
