import React, { createContext, ReactNode } from 'react';
import { colors } from '../colors/colors';
import { AppTheme, CurrentTheme, Environment, Theme } from '../types';
import { getConfigValue } from '@zonofi/common';

const appTheme: AppTheme = {
  dark: {
    primary: colors.darkGunmetal.primaryColor,
    secondary: colors.black.color,
    teritiary: colors.white.color,
    linearGradient: {
      primary: colors.darkGunmetal.color,
      secondary: colors.black.color,
    },
    text: {
      primary: colors.white.primaryColor,
      secondary: colors.white.primaryColor,
      tertiary: colors.gray.primary,
    },
    dropDown: {
      background: colors.gunMetal.teritiary,
      primary: colors.arsenic.color,
      arrow: colors.manatee.color,
      borderColor: colors.onyx.color,
    },
    principalCard: {
      background: colors.eerieBlack.color,
      default: colors.crayolaGreen.color,
      heading: colors.metallicSilver.color,
      success: colors.crayolaGreen.color,
      danger: colors.venetianRed.color,
      divider: colors.metallicSilver.color,
    },
    sideBar: {
      background: colors.gunMetal.teritiary,
      background2: '#285376',
      foreground: colors.queenBlue.primary,
      right: {
        iconColor: colors.white.color,
        hoverIconColor: colors.royalOrange.color,
      },
    },
    feed: {
      background: colors.darkGunmetal.color,
      itemBackground: colors.gunMetal.teritiary,
      heading: colors.manatee.color,
      highlight: colors.maximumBlue.color,
      icon: colors.manatee.color,
      iconBackground: colors.arsenic.secondary,
    },
    graphs: {
      header: colors.gunMetal.teritiary,
      background: colors.eerieBlack.color,
      stroke: colors.winterGreenDream.primary,
      border: colors.gunMetal.teritiary,
      legend: colors.darkGunmetal.quaternary,
      footer: colors.charlestonGreen.quarternary,
    },
    highlight: {
      primary: colors.charlestonGreen.secondary,
      secondary: colors.onyx.color,
      teritiary: colors.white.color,
      divider: {
        primary: colors.onyx.primary,
      },
      underline: colors.mariGold.primary,
      text: colors.white.color,
    },
    datepicker: {
      text: colors.manatee.color,
      background: colors.onyx.color,
    },
    principalHeader: {
      background: colors.gunMetal.teritiary,
      header: colors.gunMetal.teritiary,
      foreground: colors.eerieBlack.color,
      border: colors.charlestonGreen.quarternary,
    },
    table: {
      firstRow: colors.darkJungleGreen.color,
      secondRow: colors.darkGunmetal.color,
      linearGradient: {
        primary: colors.darkGunmetal.color,
        secondary: colors.black.color,
      },
      card: colors.darkGunmetal.color,
      tag: colors.gunMetal.secondary,
      highlight: colors.charlestonGreen.secondary,
      foreground: {
        primary: colors.darkGunmetal.color,
        secondary: colors.gunMetal.teritiary,
      },
      underline: colors.white.color,
    },
    header: {
      primary: colors.gunMetal.teritiary,
      secondary: colors.philippineGrey.color,
    },
    status: {
      success: colors.munsellGreen.primary,
      danger: colors.venetianRed.color,
      default: colors.white.color,
      neutral: colors.persianOrange.color,
      accent: colors.maximumBlue.color,
      background: {
        success: colors.mediumJungleGreen.color,
        danger: colors.blackCoffee.color,
        default: colors.transparent.color,
        neutral: colors.transparent.color,
        accent: colors.japaneseIndigo.primary,
      },
    },
    drawer: {
      background: colors.darkGunmetal.color,
      header: colors.darkGunmetal.color,
      border: colors.gainsBoro.primary,
      foreground: colors.gunMetal.teritiary,
      expandedId: {
        primary: colors.white.color,
        secondary: colors.eerieBlack.color,
      },
      tab: colors.charlestonGreen.secondary,
      selectedTab: colors.apple.secondary,
    },
    tag: colors.charlestonGreen.tertiary,
    calendar: {
      border: colors.onyx.color,
      background: colors.darkGunmetal.color,
    },
    filter: {
      background: colors.onyx.color,
      text: colors.white.color,
      foreground: colors.onyx.color,
      highlight: colors.mayaBlue.color,
      input: colors.onyx.secondary,
      border: colors.manatee.color,
      header: colors.onyx.color,
      divider: colors.manatee.color,
      tick: colors.jade.secondary,
    },
    columnFilter: {
      background: colors.onyx.color,
      text: colors.white.color,
      border: colors.eerieBlack.color,
      checkBoxUnchecked: '#BFC0C250',
    },
    filterButton: {
      selected: {
        backgroundColor: colors.gunMetal.primary,
        borderColor: colors.chineseBlack.color,
      },
      unselected: {
        backgroundColor: colors.darkGunmetal.color,
        borderColor: colors.darkGunmetal.color,
      },
    },
    principalSelection: {
      border: colors.charlestonGreen.tertiary,
      background: colors.eerieBlack.color,
      divider: colors.charlestonGreen.color,
      footer: colors.darkGunmetal.color,
      header: colors.gunMetal.teritiary,
      foreground: colors.gunMetal.color,
      tag: colors.mediumAquamarine.opacity10,
      text: colors.munsellGreen.color,
    },
    gradientButton: colors.blueLinearGradient,
    gradientFilter: colors.blueGreenLinearGradient,
    button: {
      primary: colors.onyx.color,
      hover: '#ffffff33',
    },
    opacity: {
      primary: colors.zonoWhite.opacity80,
    },
    opacity1: {
      primary: colors.gunMetal.opacity50,
    },
    enableEdit: true,
    addToCart: {
      input: colors.onyx.color,
      backGround: colors.onyx.primary,
    },
    menu: {
      background: colors.quartz.color,
    },
    dateRangePicker: {
      backdrop: colors.blackCoffee.primary,
      background: colors.darkGunmetal.color,
      heading: colors.white.color,
    },
    details: {
      header: colors.platinum.color,
      subheader: colors.metallicSilver.primaryShade,
    },
    communication: {
      background: colors.eerieBlack.color,
      foreground: colors.gunMetal.color,
      border: colors.charlestonGreen.tertiary,
      sideListBackground: colors.eerieBlack.color,
      primaryButton: { text: colors.white.color, background: '#34353e' },
      underline: '#e85b49',
      accordionIcon: '#9295a5',
      containerBg: '#21222B',
      myChatBubbleBg: '#4481B415',
      userChatBubbleBg: '#DFE0E0',
      sendIcon: '#87E2BD',
    },
    listItems: {
      listItemColor1: '#ff7069',
      listItemColor2: '#cc9308',
      listItemColor3: '#87ced9',
      listItemColor4: '#b0cb1f',
      listItemColor5: '#ebf10d',
      listItemColor6: '#0df1c1',
      listItemColor7: '#d20df1',
      listItemColor8: '#0d5cf1',
      listItemColor9: '#f1e70d',
      listItemColor10: '#a72ea4',
      listItemColor11: '#5b6997',
    },
    horizontalLine: {
      primary: colors.charlestonGreen.secondary,
    },
    active: {
      primary: colors.charlestonGreen.primaryColor,
    },
    input: {
      border: colors.grey.primary,
      placeHolder: colors.white.secondary,
      label: colors.white.secondary,
    },
    teamList: {
      icon: '#E85B49',
      button: {
        primary: '#E85B49',
        secondary: '#9295a5',
        background: '#34353e',
      },
      text: '#ffffff',
      backGround: { primary: '#373A46', secondary: '#4a4c58', bg03: '#24252F' },
    },
    closeIcon: colors.onyx.color,
    orderTypeTags: {
      reorder: {
        backgroundColor: colors.paleLavender.color,
        borderColor: colors.blueMagentaViolet.opacity20,
        text: colors.blueMagentaViolet.color,
        borderRadius: 32,
      },
      upload: {
        backgroundColor: colors.lightGoldenrodYellow.color,
        borderColor: colors.metallicSunburst.opacity20,
        text: colors.metallicSunburst.color,
        borderRadius: 32,
      },
      email: {
        backgroundColor: colors.alabaster.color,
        borderColor: colors.milkChocolate.opacity20,
        text: colors.milkChocolate.color,
        borderRadius: 32,
      },
      manual: {
        backgroundColor: colors.azureishWhite.color,
        borderColor: colors.darkCornflowerBlue.opacity20,
        text: colors.darkCornflowerBlue.color,
        borderRadius: 32,
      },
      dterp: {
        backgroundColor: colors.allMadeUp.color,
        borderColor: colors.hollyHock.opacity20,
        text: colors.hollyHock.color,
        borderRadius: 32,
      },
    },
    orderStatusTags: {
      submitted: {
        backgroundColor: colors.mediumAquamarine.opacity10,
        borderColor: colors.mediumAquamarine.opacity10,
        text: colors.munsellGreen.color,
        borderRadius: 4,
      },
      waiting: {
        backgroundColor: colors.royalOrange.opacity10,
        borderColor: colors.royalOrange.opacity10,
        text: colors.royalOrange.color,
        borderRadius: 4,
      },
      billed: {
        backgroundColor: colors.steelBlue.opacity10,
        borderColor: colors.steelBlue.opacity10,
        text: colors.steelBlue.color,
        borderRadius: 4,
      },
      cancelled: {
        backgroundColor: colors.persianRed.opacity10,
        borderColor: colors.persianRed.opacity10,
        text: colors.persianRed.color,
        borderRadius: 4,
      },
      inCart: {
        backgroundColor: colors.chineseWhite.primary,
        borderColor: colors.chineseWhite.primary,
        text: colors.gray.primary,
        borderRadius: 4,
      },
      draft: {
        backgroundColor: colors.gray.opacity10,
        borderColor: colors.gray.opacity10,
        text: colors.gray.primary,
        borderRadius: 4,
      },
    },
    returnTooltipTags: {
      accepted: {
        color: colors.munsellGreen.primary,
        text: colors.munsellGreen.primary,
      },
      rejected: {
        text: colors.venetianRed.color,
        color: colors.venetianRed.color,
      },
      templateNotFound: {
        text: colors.persianRed.color,
        color: colors.persianRed.color,
      },
      ticketCreated: {
        text: colors.royalOrange.color,
        color: colors.royalOrange.color,
      },
    },
    returnStatusTags: {
      draft: {
        backgroundColor: colors.gray.opacity10,
        borderColor: colors.gray.opacity10,
        text: colors.gray.primary,
        borderRadius: 4,
      },
      submitted: {
        backgroundColor: colors.mediumAquamarine.opacity10,
        borderColor: colors.mediumAquamarine.opacity10,
        text: colors.munsellGreen.color,
        borderRadius: 4,
      },
      waiting: {
        backgroundColor: colors.royalOrange.opacity10,
        borderColor: colors.royalOrange.opacity10,
        text: colors.royalOrange.color,
        borderRadius: 4,
      },
      rejected: {
        backgroundColor: colors.persianRed.opacity10,
        borderColor: colors.persianRed.opacity10,
        text: colors.persianRed.color,
        borderRadius: 4,
      },
      transit: {
        backgroundColor: colors.steelBlue.opacity10,
        borderColor: colors.steelBlue.opacity10,
        text: colors.steelBlue.color,
        borderRadius: 4,
      },
      issued: {
        backgroundColor: colors.paleLavender.opacity50,
        borderColor: colors.paleLavender.opacity50,
        text: colors.blueMagentaViolet.color,
        borderRadius: 4,
      },
    },
    searchKeyTags: {
      backgroundColor: colors.steelBlue.opacity10,
      borderColor: colors.steelBlue.opacity50,
      text: colors.white.color,
      borderRadius: 22,
    },
    disabledButton: { background: colors.gray.color, text: colors.white.color },
    accordian: {
      openTitleBackground: colors.chineseBlack.color,
      closeTitleBackground: colors.darkGunmetal.color,
    },
    orderClaims: {
      header: {
        background: colors.gunMetal.teritiary,
      },
      card: {
        background: colors.eerieBlack.color,
        border: colors.white.quaternary,
      },
    },
    cartButton: {
      background: `linear-gradient(to right, ${colors.darkBlueLinearGradient.firstColor}, ${colors.darkBlueLinearGradient.secondColor})`,
      iconColor: colors.white.color,
      bubbleColor: colors.royalOrange.color,
      textColor: colors.eerieBlack.teritiary,
    },
    headerCartButton: {
      background: `linear-gradient(to right, ${colors.darkBlueLinearGradient.firstColor}, ${colors.darkBlueLinearGradient.secondColor})`,
      iconColor: colors.white.color,
      bubbleColor: colors.royalOrange.color,
      textColor: colors.eerieBlack.teritiary,
    },
    selectedTab: {
      color: colors.cyanAzure.color,
    },
    blurBackground: {
      color: colors.steelBlue.opacity10,
    },
    fontConfig: {
      bold: {
        fontFamily: 'FiraSans-Bold',
        fontWeight: '900' as const,
      },
      semiBold: {
        fontFamily: 'FiraSans-SemiBold',
        fontWeight: '600' as const,
      },
      regular: {
        fontFamily: 'Fira Sans',
        fontWeight: '500' as const,
      },
      medium: {
        fontFamily: 'FiraSans-Medium',
        fontWeight: '400' as const,
      },
      light: {
        fontFamily: 'FiraSans-Light',
        fontWeight: '300' as const,
      },
      italic: {
        fontFamily: 'FiraSans-Italic',
        fontWeight: '400' as const,
      },
      boldItalic: {
        fontFamily: 'FiraSans-BoldItalic',
        fontWeight: '900' as const,
      },
      semiBoldItalic: {
        fontFamily: 'FiraSans-SemiBoldItalic',
        fontWeight: '600' as const,
      },
      condensedRegular: {
        fontFamily: 'FiraSansCondensed-Regular',
        fontWeight: '500' as const,
      },
      condensedSemiBold: {
        fontFamily: 'FiraSansCondensed-SemiBold',
        fontWeight: '600' as const,
      },
      firaSansRegular: {
        fontFamily: 'Fira Sans, Regular',
        fontWeight: '500' as const,
      },
    },
  },
  light: {
    primary: colors.white.color,
    secondary: colors.steelBlue.color,
    teritiary: colors.black.color,
    linearGradient: {
      primary: colors.brightGray.color,
      secondary: colors.brightGray.color,
    },
    text: {
      primary: colors.eerieBlack.teritiary,
      secondary: colors.indigo.color,
      tertiary: colors.gray.primary,
    },

    dropDown: {
      background: colors.white.color,
      primary: colors.antiFlashWhite.color,
      arrow: colors.sonicSilver.color,
      borderColor: colors.steelBlue.opacity50,
    },
    principalCard: {
      background: colors.ghostWhite.color,
      default: colors.eerieBlack.teritiary,
      heading: colors.gray.primary,
      success: colors.apple.secondary,
      danger: colors.venetianRed.color,
      divider: colors.lavender.color,
    },
    sideBar: {
      background: colors.steelBlue.color,
      foreground: colors.blueGray.color,
      background2: '#285376',
      right: {
        iconColor: colors.white.color,
        hoverIconColor: colors.royalOrange.color,
      },
    },
    feed: {
      background: colors.white.color,
      itemBackground: colors.ghostWhite.color,
      heading: colors.gray.primary,
      highlight: colors.steelBlue.color,
      icon: colors.gainsBoro.primary,
      iconBackground: colors.antiFlashWhite.color,
    },
    graphs: {
      header: colors.cultured.primary,
      background: colors.white.color,
      border: colors.cultured.primary,
      stroke: colors.apple.secondary,
      legend: colors.cultured.primary,
      footer: colors.aliceBlue.color,
    },
    highlight: {
      primary: colors.brightGray.primary,
      secondary: colors.brightGray.primary,
      teritiary: colors.mariGold.primary,
      divider: {
        primary: colors.onyx.primary,
      },
      underline: colors.mariGold.primary,
      text: colors.black.color,
    },
    datepicker: {
      background: colors.cultured.primary,
      text: colors.eerieBlack.teritiary,
    },
    principalHeader: {
      background: colors.white.color,
      header: colors.white.color,
      foreground: colors.ghostWhite.color,
      border: colors.antiFlashWhite.primary,
    },
    table: {
      firstRow: colors.white.color,
      secondRow: colors.ghostWhite.color,
      linearGradient: {
        primary: colors.white.color,
        secondary: colors.white.color,
      },
      card: colors.antiFlashWhite.color,
      tag: colors.antiFlashWhite.color,
      highlight: colors.brightGray.primary,
      foreground: {
        primary: colors.manatee.opacity10,
        secondary: colors.manatee.opacity10,
      },
      underline: colors.steelBlue.color,
    },
    header: {
      primary: colors.white.color,
      secondary: colors.philippineGrey.color,
    },
    status: {
      success: colors.apple.secondary,
      danger: colors.persianRed.color,
      default: colors.eerieBlack.teritiary,
      neutral: colors.mariGold.primary,
      accent: colors.maximumBlue.color,
      background: {
        success: colors.middleGreen.opacity10,
        danger: colors.persianRed.opacity10,
        default: colors.transparent.color,
        neutral: colors.transparent.color,
        accent: colors.babyBlueEyes.opacity20,
      },
    },
    drawer: {
      background: colors.white.color,
      header: colors.antiFlashWhite.color,
      border: colors.gainsBoro.primary,
      foreground: colors.white.color,
      expandedId: {
        primary: colors.white.color,
        secondary: colors.antiFlashWhite.color,
      },
      tab: colors.white.color,
      selectedTab: colors.apple.secondary,
    },
    tag: colors.white.color,
    calendar: {
      background: colors.white.color,
      border: colors.cadetBlue.color,
    },
    filter: {
      background: colors.ghostWhite.color,
      text: colors.gray.primary,
      foreground: colors.antiFlashWhite.color,
      highlight: colors.steelBlue.color,
      input: colors.white.color,
      border: colors.gainsBoro.primary,
      header: colors.white.color,
      divider: colors.chineseWhite.primary,
      tick: colors.apple.secondary,
    },
    columnFilter: {
      background: colors.ghostWhite.color,
      text: colors.gray.primary,
      border: colors.brightGray.secondary,
      checkBoxUnchecked: '#BFC0C250',
    },
    filterButton: {
      selected: {
        backgroundColor: colors.azureishWhite.primary,
        borderColor: colors.cyanAzure.opacity25,
      },
      unselected: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
    principalSelection: {
      border: colors.brightGray.secondary,
      background: colors.ghostWhite.color,
      divider: colors.antiFlashWhite.color,
      footer: colors.ghostWhite.color,
      header: colors.white.color,
      foreground: colors.white.color,
      tag: colors.apple.opacity10,
      text: colors.apple.secondary,
    },
    communication: {
      border: colors.brightGray.secondary,
      background: 'transparent',
      foreground: '#ECF0F5',
      primaryButton: { text: colors.white.color, background: '#4481B4' },
      sideListBackground: colors.white.color,
      underline: '#F39C26',
      accordionIcon: '#4481B4',
      containerBg: '#ECF0F5',
      myChatBubbleBg: '#4481B415',
      userChatBubbleBg: '#DFE0E0',
      sendIcon: '#4481B4',
    },
    teamList: {
      icon: '#E85B49',
      button: {
        primary: '#E85B49',
        secondary: '#24252F',
        background: '#ffffff',
      },
      text: '#000000',
      backGround: {
        primary: colors.ghostWhite.color,
        secondary: '#FAF9F6',
        bg03: '#FAF9F6',
      },
    },
    listItems: {
      listItemColor1: '#244091',
      listItemColor2: '#492999',
      listItemColor3: '#9C8743',
      listItemColor4: '#853071',
      listItemColor5: '#862E29',
      listItemColor6: '#244091',
      listItemColor7: '#492999',
      listItemColor8: '#9C8743',
      listItemColor9: '#853071',
      listItemColor10: '#862E29',
      listItemColor11: '#244091',
    },
    gradientButton: colors.darkBlueLinearGradient,
    button: {
      primary: colors.gainsBoro.primary,
      hover: '#ffffff33',
    },
    opacity: {
      primary: colors.zonoWhite.opacity80,
    },
    opacity1: {
      primary: colors.gunMetal.opacity50,
    },
    gradientFilter: colors.darkBlueLinearGradient,
    menu: {
      background: colors.white.color,
    },
    dateRangePicker: {
      backdrop: colors.zonoWhite.opacity80,
      background: colors.ghostWhite.color,
      heading: colors.eerieBlack.teritiary,
    },
    enableEdit: false,
    addToCart: {
      input: colors.white.color,
      backGround: colors.grey.color,
    },
    details: {
      header: colors.darkGunmetal.color,
      subheader: colors.charlestonGrey.color,
    },
    horizontalLine: {
      primary: colors.lightGrey.secondColor,
    },
    active: {
      primary: colors.mediumSeaGreen.secondColor,
    },
    input: {
      border: colors.manatee.secondary,
      placeHolder: colors.black.color,
      label: colors.grey.tertiary,
    },
    closeIcon: colors.gainsBoro.primary,
    orderTypeTags: {
      reorder: {
        backgroundColor: colors.paleLavender.color,
        borderColor: colors.blueMagentaViolet.opacity20,
        text: colors.blueMagentaViolet.color,
        borderRadius: 32,
      },
      upload: {
        backgroundColor: colors.lightGoldenrodYellow.color,
        borderColor: colors.metallicSunburst.opacity20,
        text: colors.metallicSunburst.color,
        borderRadius: 32,
      },
      email: {
        backgroundColor: colors.alabaster.color,
        borderColor: colors.milkChocolate.opacity20,
        text: colors.milkChocolate.color,
        borderRadius: 32,
      },
      manual: {
        backgroundColor: colors.azureishWhite.color,
        borderColor: colors.darkCornflowerBlue.opacity20,
        text: colors.darkCornflowerBlue.color,
        borderRadius: 32,
      },
      dterp: {
        backgroundColor: colors.allMadeUp.color,
        borderColor: colors.hollyHock.opacity20,
        text: colors.hollyHock.color,
        borderRadius: 32,
      },
    },
    orderStatusTags: {
      submitted: {
        backgroundColor: colors.mediumAquamarine.opacity10,
        borderColor: colors.mediumAquamarine.opacity10,
        text: colors.munsellGreen.color,
        borderRadius: 4,
      },
      waiting: {
        backgroundColor: colors.royalOrange.opacity10,
        borderColor: colors.royalOrange.opacity10,
        text: colors.royalOrange.color,
        borderRadius: 4,
      },
      billed: {
        backgroundColor: colors.steelBlue.opacity10,
        borderColor: colors.steelBlue.opacity10,
        text: colors.steelBlue.color,
        borderRadius: 4,
      },
      cancelled: {
        backgroundColor: colors.persianRed.opacity10,
        borderColor: colors.persianRed.opacity10,
        text: colors.persianRed.color,
        borderRadius: 4,
      },
      inCart: {
        backgroundColor: colors.chineseWhite.primary,
        borderColor: colors.chineseWhite.primary,
        text: colors.gray.primary,
        borderRadius: 4,
      },
      draft: {
        backgroundColor: colors.gray.opacity10,
        borderColor: colors.gray.opacity10,
        text: colors.gray.primary,
        borderRadius: 4,
      },
    },
    returnTooltipTags: {
      accepted: {
        color: colors.munsellGreen.primary,
        text: colors.munsellGreen.primary,
      },
      rejected: {
        text: colors.venetianRed.color,
        color: colors.venetianRed.color,
      },
      templateNotFound: {
        text: colors.persianRed.color,
        color: colors.persianRed.color,
      },
      ticketCreated: {
        text: colors.royalOrange.color,
        color: colors.royalOrange.color,
      },
    },
    returnStatusTags: {
      draft: {
        backgroundColor: colors.gray.opacity10,
        borderColor: colors.gray.opacity10,
        text: colors.gray.primary,
        borderRadius: 4,
      },
      submitted: {
        backgroundColor: colors.mediumAquamarine.opacity10,
        borderColor: colors.mediumAquamarine.opacity10,
        text: colors.munsellGreen.color,
        borderRadius: 4,
      },
      waiting: {
        backgroundColor: colors.royalOrange.opacity10,
        borderColor: colors.royalOrange.opacity10,
        text: colors.royalOrange.color,
        borderRadius: 4,
      },
      rejected: {
        backgroundColor: colors.persianRed.opacity10,
        borderColor: colors.persianRed.opacity10,
        text: colors.persianRed.color,
        borderRadius: 4,
      },
      transit: {
        backgroundColor: colors.steelBlue.opacity10,
        borderColor: colors.steelBlue.opacity10,
        text: colors.steelBlue.color,
        borderRadius: 4,
      },
      issued: {
        backgroundColor: colors.paleLavender.opacity50,
        borderColor: colors.paleLavender.opacity50,
        text: colors.blueMagentaViolet.color,
        borderRadius: 4,
      },
    },
    searchKeyTags: {
      backgroundColor: colors.steelBlue.opacity10,
      borderColor: colors.steelBlue.opacity50,
      text: colors.eerieBlack.teritiary,
      borderRadius: 22,
    },
    disabledButton: {
      background: colors.chineseWhite.primary,
      text: colors.gray.primary,
    },
    accordian: {
      openTitleBackground: colors.chineseWhite.opacity40,
      closeTitleBackground: colors.white.color,
    },
    orderClaims: {
      header: {
        background: colors.ghostWhite.color,
      },
      card: {
        background: colors.white.primary,
        border: colors.white.quaternary,
      },
    },
    cartButton: {
      background: `linear-gradient(to right, ${colors.darkBlueLinearGradient.firstColor}, ${colors.darkBlueLinearGradient.secondColor})`,
      iconColor: colors.white.color,
      bubbleColor: colors.royalOrange.color,
      textColor: colors.eerieBlack.teritiary,
    },
    headerCartButton: {
      background: `linear-gradient(to right, ${colors.darkBlueLinearGradient.firstColor}, ${colors.darkBlueLinearGradient.secondColor})`,
      iconColor: colors.white.color,
      bubbleColor: colors.royalOrange.color,
      textColor: colors.eerieBlack.teritiary,
    },
    selectedTab: {
      color: colors.cyanAzure.color,
    },
    blurBackground: {
      color: colors.steelBlue.opacity10,
    },
    fontConfig: {
      bold: {
        fontFamily: 'Segoe-UI-Bold',
        fontWeight: '900' as const,
      },
      semiBold: {
        fontFamily: 'Segoe-UI-SemiBold',
        fontWeight: '600' as const,
      },
      semiBoldRegFW: {
        fontFamily: 'Segoe-UI-SemiBold',
        fontWeight: '500' as const,
      },
      regular: {
        fontFamily: 'Segoe-UI',
        fontWeight: '500' as const,
      },
      medium: {
        fontFamily: 'Segoe-UI-Bold',
        fontWeight: '400' as const,
      },
      light: {
        fontFamily: 'Segoe-UI',
        fontWeight: '300' as const,
      },
      italic: {
        fontFamily: 'Segoe-UI-Italic',
        fontWeight: '400' as const,
      },
      boldItalic: {
        fontFamily: 'Segoe-UI-BoldItalic',
        fontWeight: '900' as const,
      },
      semiBoldItalic: {
        fontFamily: '',
        fontWeight: '600' as const,
      },
      condensedRegular: {
        fontFamily: 'Segoe-UI-Bold',
        fontWeight: '500' as const,
      },
      condensedSemiBold: {
        fontFamily: '',
        fontWeight: '600' as const,
      },
      firaSansRegular: {
        fontFamily: 'Fira Sans, Regular',
        fontWeight: '500' as const,
      },
    },
  },
  zonoDigital: {
    light: {
      primary: colors.white.color,
      secondary: colors.zonoDigitalBlue.color,
      teritiary: colors.black.color,
      linearGradient: {
        primary: colors.brightGray.color,
        secondary: colors.brightGray.color,
      },
      text: {
        primary: colors.eerieBlack.teritiary,
        secondary: colors.indigo.color,
        tertiary: colors.gray.primary,
      },
      dropDown: {
        background: colors.white.color,
        primary: colors.antiFlashWhite.color,
        arrow: colors.sonicSilver.color,
        borderColor: colors.steelBlue.opacity50,
      },
      principalCard: {
        background: colors.ghostWhite.color,
        default: colors.eerieBlack.teritiary,
        heading: colors.gray.primary,
        success: colors.apple.secondary,
        danger: colors.venetianRed.color,
        divider: colors.lavender.color,
      },
      sideBar: {
        background: colors.blackCrow.color,
        foreground: colors.zonoDigitalBlue.color,
        background2: colors.zonoWhite.color,
        right: {
          iconColor: 'rgb(128, 130, 133)',
          hoverIconColor: colors.zonoDigitalBlue.color,
        },
      },
      feed: {
        background: colors.white.color,
        itemBackground: colors.ghostWhite.color,
        heading: colors.gray.primary,
        highlight: colors.steelBlue.color,
        icon: colors.gainsBoro.primary,
        iconBackground: colors.antiFlashWhite.color,
      },
      graphs: {
        header: colors.cultured.primary,
        background: colors.white.color,
        border: colors.cultured.primary,
        stroke: colors.apple.secondary,
        legend: colors.cultured.primary,
        footer: colors.aliceBlue.color,
      },
      highlight: {
        primary: colors.brightGray.primary,
        secondary: colors.brightGray.primary,
        teritiary: colors.mariGold.primary,
        divider: {
          primary: colors.onyx.primary,
        },
        underline: colors.mariGold.primary,
        text: colors.black.color,
      },
      datepicker: {
        background: colors.cultured.primary,
        text: colors.eerieBlack.teritiary,
      },
      principalHeader: {
        background: colors.white.color,
        header: colors.white.color,
        foreground: colors.ghostWhite.color,
        border: colors.antiFlashWhite.primary,
      },
      table: {
        firstRow: colors.white.color,
        secondRow: colors.ghostWhite.color,
        linearGradient: {
          primary: colors.white.color,
          secondary: colors.white.color,
        },
        card: colors.antiFlashWhite.color,
        tag: colors.antiFlashWhite.color,
        highlight: colors.brightGray.primary,
        foreground: {
          primary: colors.manatee.opacity10,
          secondary: colors.manatee.opacity10,
        },
        underline: colors.zonoDigitalBlue.color,
      },
      header: {
        primary: colors.white.color,
        secondary: colors.philippineGrey.color,
      },
      status: {
        success: colors.apple.secondary,
        danger: colors.persianRed.color,
        default: colors.eerieBlack.teritiary,
        neutral: colors.mariGold.primary,
        accent: colors.maximumBlue.color,
        background: {
          success: colors.middleGreen.opacity10,
          danger: colors.persianRed.opacity10,
          default: colors.transparent.color,
          neutral: colors.transparent.color,
          accent: colors.babyBlueEyes.opacity20,
        },
      },
      drawer: {
        background: colors.white.color,
        header: colors.antiFlashWhite.color,
        border: colors.gainsBoro.primary,
        foreground: colors.white.color,
        expandedId: {
          primary: colors.white.color,
          secondary: colors.antiFlashWhite.color,
        },
        tab: colors.white.color,
        selectedTab: colors.zonoDigitalBlue.color,
      },
      tag: colors.white.color,
      calendar: {
        background: colors.white.color,
        border: colors.cadetBlue.color,
      },
      filter: {
        background: colors.ghostWhite.color,
        text: colors.gray.primary,
        foreground: colors.antiFlashWhite.color,
        highlight: colors.zonoDigitalBlue.color,
        input: colors.white.color,
        border: colors.gainsBoro.primary,
        header: colors.white.color,
        divider: colors.chineseWhite.primary,
        tick: colors.apple.secondary,
      },
      columnFilter: {
        background: colors.ghostWhite.color,
        text: colors.gray.primary,
        border: colors.brightGray.secondary,
        checkBoxUnchecked: '#BFC0C250',
      },
      filterButton: {
        selected: {
          backgroundColor: colors.zonoDigitalBlue.opacity10,
          borderColor: colors.zonoDigitalBlue.color,
        },
        unselected: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
      },
      principalSelection: {
        border: colors.brightGray.secondary,
        background: colors.ghostWhite.color,
        divider: colors.antiFlashWhite.color,
        footer: colors.ghostWhite.color,
        header: colors.white.color,
        foreground: colors.white.color,
        tag: colors.apple.opacity10,
        text: colors.apple.secondary,
      },
      communication: {
        border: colors.brightGray.secondary,
        background: 'transparent',
        foreground: '#ECF0F5',
        primaryButton: { text: colors.white.color, background: '#4481B4' },
        sideListBackground: colors.white.color,
        underline: '#F39C26',
        accordionIcon: '#4481B4',
        containerBg: '#ECF0F5',
        myChatBubbleBg: '#4481B415',
        userChatBubbleBg: '#DFE0E0',
        sendIcon: '#4481B4',
      },
      teamList: {
        icon: '#E85B49',
        button: {
          primary: '#E85B49',
          secondary: '#24252F',
          background: '#ffffff',
        },
        text: '#000000',
        backGround: {
          primary: colors.ghostWhite.color,
          secondary: '#FAF9F6',
          bg03: '#FAF9F6',
        },
      },
      listItems: {
        listItemColor1: '#244091',
        listItemColor2: '#492999',
        listItemColor3: '#9C8743',
        listItemColor4: '#853071',
        listItemColor5: '#862E29',
        listItemColor6: '#244091',
        listItemColor7: '#492999',
        listItemColor8: '#9C8743',
        listItemColor9: '#853071',
        listItemColor10: '#862E29',
        listItemColor11: '#244091',
      },
      pieChartColorVarients: {
        colorVarient9: '#41825c',
        colorVarient10: '#5b0ce8',
        colorVarient1: '#0167ff',
        colorVarient2: '#6da8ff',
        colorVarient3: '#265277',
        colorVarient4: '#F3AF3D',
        colorVarient5: '#F7D17C',
        colorVarient6: '#855F1C',
        colorVarient7: '#5DB884',
        colorVarient8: '#6acf9a',
      },
      pieChartDrillDownColorVarients: {
        colorVarient1: '#8c5e00',
        colorVarient2: '#b17900',
        colorVarient3: '#db9200',
        colorVarient4: '#ffaa01',
        colorVarient5: '#ffb723',
        colorVarient6: '#ffc349',
        colorVarient7: '#ffcf6d',
        colorVarient8: '#ffdc94',
        colorVarient9: '#ffe7b6',
        colorVarient10: '#fff4dd',
      },
      gradientButton: colors.zonoDigitalBlueLinearGradient,
      button: {
        primary: colors.gainsBoro.primary,
        hover: '#ffffff33',
      },
      opacity: {
        primary: colors.zonoWhite.opacity80,
      },
      opacity1: {
        primary: colors.gunMetal.opacity50,
      },
      gradientFilter: colors.zonoDigitalBlueLinearGradient,
      menu: {
        background: colors.white.color,
      },
      dateRangePicker: {
        backdrop: colors.zonoWhite.opacity80,
        background: colors.ghostWhite.color,
        heading: colors.eerieBlack.teritiary,
      },
      enableEdit: false,
      addToCart: {
        input: colors.white.color,
        backGround: colors.grey.color,
      },
      details: {
        header: colors.darkGunmetal.color,
        subheader: colors.charlestonGrey.color,
      },
      horizontalLine: {
        primary: colors.lightGrey.secondColor,
      },
      active: {
        primary: colors.mediumSeaGreen.secondColor,
      },
      input: {
        border: colors.manatee.secondary,
        placeHolder: colors.black.color,
        label: colors.grey.tertiary,
      },
      closeIcon: colors.gainsBoro.primary,
      orderTypeTags: {
        reorder: {
          backgroundColor: colors.paleLavender.color,
          borderColor: colors.blueMagentaViolet.opacity20,
          text: colors.blueMagentaViolet.color,
          borderRadius: 32,
        },
        upload: {
          backgroundColor: colors.lightGoldenrodYellow.color,
          borderColor: colors.metallicSunburst.opacity20,
          text: colors.metallicSunburst.color,
          borderRadius: 32,
        },
        email: {
          backgroundColor: colors.alabaster.color,
          borderColor: colors.milkChocolate.opacity20,
          text: colors.milkChocolate.color,
          borderRadius: 32,
        },
        manual: {
          backgroundColor: colors.azureishWhite.color,
          borderColor: colors.darkCornflowerBlue.opacity20,
          text: colors.darkCornflowerBlue.color,
          borderRadius: 32,
        },
        dterp: {
          backgroundColor: colors.allMadeUp.color,
          borderColor: colors.hollyHock.opacity20,
          text: colors.hollyHock.color,
          borderRadius: 32,
        },
      },
      orderStatusTags: {
        submitted: {
          backgroundColor: colors.mediumAquamarine.opacity10,
          borderColor: colors.mediumAquamarine.opacity10,
          text: colors.munsellGreen.color,
          borderRadius: 4,
        },
        waiting: {
          backgroundColor: colors.royalOrange.opacity10,
          borderColor: colors.royalOrange.opacity10,
          text: colors.royalOrange.color,
          borderRadius: 4,
        },
        billed: {
          backgroundColor: colors.steelBlue.opacity10,
          borderColor: colors.steelBlue.opacity10,
          text: colors.steelBlue.color,
          borderRadius: 4,
        },
        cancelled: {
          backgroundColor: colors.persianRed.opacity10,
          borderColor: colors.persianRed.opacity10,
          text: colors.persianRed.color,
          borderRadius: 4,
        },
        inCart: {
          backgroundColor: colors.chineseWhite.primary,
          borderColor: colors.chineseWhite.primary,
          text: colors.gray.primary,
          borderRadius: 4,
        },
        draft: {
          backgroundColor: colors.gray.opacity10,
          borderColor: colors.gray.opacity10,
          text: colors.gray.primary,
          borderRadius: 4,
        },
      },
      returnTooltipTags: {
        accepted: {
          color: colors.munsellGreen.primary,
          text: colors.munsellGreen.primary,
        },
        rejected: {
          text: colors.venetianRed.color,
          color: colors.venetianRed.color,
        },
        templateNotFound: {
          text: colors.persianRed.color,
          color: colors.persianRed.color,
        },
        ticketCreated: {
          text: colors.royalOrange.color,
          color: colors.royalOrange.color,
        },
      },
      returnStatusTags: {
        draft: {
          backgroundColor: colors.gray.opacity10,
          borderColor: colors.gray.opacity10,
          text: colors.gray.primary,
          borderRadius: 4,
        },
        submitted: {
          backgroundColor: colors.mediumAquamarine.opacity10,
          borderColor: colors.mediumAquamarine.opacity10,
          text: colors.munsellGreen.color,
          borderRadius: 4,
        },
        waiting: {
          backgroundColor: colors.royalOrange.opacity10,
          borderColor: colors.royalOrange.opacity10,
          text: colors.royalOrange.color,
          borderRadius: 4,
        },
        rejected: {
          backgroundColor: colors.persianRed.opacity10,
          borderColor: colors.persianRed.opacity10,
          text: colors.persianRed.color,
          borderRadius: 4,
        },
        transit: {
          backgroundColor: colors.steelBlue.opacity10,
          borderColor: colors.steelBlue.opacity10,
          text: colors.steelBlue.color,
          borderRadius: 4,
        },
        issued: {
          backgroundColor: colors.paleLavender.opacity50,
          borderColor: colors.paleLavender.opacity50,
          text: colors.blueMagentaViolet.color,
          borderRadius: 4,
        },
      },
      searchKeyTags: {
        backgroundColor: colors.steelBlue.opacity10,
        borderColor: colors.steelBlue.opacity50,
        text: colors.eerieBlack.teritiary,
        borderRadius: 22,
      },
      disabledButton: {
        background: colors.chineseWhite.primary,
        text: colors.gray.primary,
      },
      accordian: {
        openTitleBackground: colors.chineseWhite.opacity40,
        closeTitleBackground: colors.white.color,
      },
      orderClaims: {
        header: {
          background: colors.ghostWhite.color,
        },
        card: {
          background: colors.white.primary,
          border: colors.white.quaternary,
        },
      },
      background: {
        primary: colors.white.color,
        secondary: colors.steelBlue.color,
      },
      cartButton: {
        background: colors.lightSlateGrey.color,
        iconColor: colors.slateGray.color,
        bubbleColor: colors.white.color,
        textColor: colors.slateGray.color,
      },
      headerCartButton: {
        background:
          'linear-gradient(76deg, #E83535 0%, #8135E8 52%, #35A7E8 100%)',
        iconColor: colors.zonoWhite.color,
        bubbleColor: colors.blackCrow.color,
        textColor: colors.zonoWhite.color,
      },
      selectedTab: {
        color: colors.zonoDigitalBlue.color,
      },
      blurBackground: {
        color: colors.zonoDigitalBlue.opacity10,
      },
      fontConfig: {
        bold: {
          fontFamily: 'Segoe-UI-Bold',
          fontWeight: '900' as const,
        },
        semiBold: {
          fontFamily: 'Segoe-UI-SemiBold',
          fontWeight: '600' as const,
        },
        semiBoldRegFW: {
          fontFamily: 'Segoe-UI-SemiBold',
          fontWeight: '500' as const,
        },
        regular: {
          fontFamily: 'Segoe-UI',
          fontWeight: '500' as const,
        },
        medium: {
          fontFamily: 'Segoe-UI-Bold',
          fontWeight: '400' as const,
        },
        light: {
          fontFamily: 'Segoe-UI',
          fontWeight: '300' as const,
        },
        italic: {
          fontFamily: 'Segoe-UI-Italic',
          fontWeight: '400' as const,
        },
        boldItalic: {
          fontFamily: 'Segoe-UI-BoldItalic',
          fontWeight: '900' as const,
        },
        semiBoldItalic: {
          fontFamily: '',
          fontWeight: '600' as const,
        },
        condensedRegular: {
          fontFamily: 'Segoe-UI-Bold',
          fontWeight: '500' as const,
        },
        condensedSemiBold: {
          fontFamily: '',
          fontWeight: '600' as const,
        },
        firaSansRegular: {
          fontFamily: 'Fira Sans, Regular',
          fontWeight: '500' as const,
        },
      },
    },
  },
};

export interface ThemeContextInterface {
  theme: Theme;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextInterface>({
  theme: appTheme.light,
  toggleTheme: () => {},
});

interface ProviderProps {
  children: ReactNode;
}

export const ThemeContextProvider = (props: ProviderProps): JSX.Element => {
  const { children } = props;
  const env = getConfigValue('ENV');

  const [currentTheme, setCurrentTheme] = React.useState<Theme>(
    env === Environment.PHARM_CONNECT
      ? appTheme.light
      : appTheme.zonoDigital.light
  );

  // Retrieve the data from localStorage on initial load
  React.useEffect(() => {
    const currentThemeString = localStorage.getItem('currentTheme');
    if (currentThemeString !== null) {
      setCurrentTheme(
        currentThemeString === CurrentTheme.dark
          ? appTheme.dark
          : currentThemeString === CurrentTheme.zonoDigital
          ? appTheme.zonoDigital.light
          : appTheme.light
      );
    }
  }, []);

  const toggleTheme = () => {
    const newTheme =
      env === Environment.ZONO_DIGITAL
        ? appTheme.zonoDigital.light
        : currentTheme === appTheme.dark
        ? appTheme.light
        : appTheme.dark;
    setCurrentTheme(newTheme);
    localStorage.setItem(
      'currentTheme',
      env === Environment.ZONO_DIGITAL
        ? CurrentTheme.zonoDigital
        : currentTheme === appTheme.dark
        ? CurrentTheme.light
        : CurrentTheme.dark
    );
  };

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
