import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

function ZonoMoneyIcon({ color = '#a1a3b4', width = 20.403, height = 19.874 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.999"
      height="24"
      viewBox="0 0 23.999 24"
    >
      <G
        id="Group_204897"
        data-name="Group 204897"
        transform="translate(14155.736 -5200.139)"
      >
        <Path
          id="Path_302180"
          data-name="Path 302180"
          d="M12,0c8.776,0,12,3.224,12,12S20.776,24,12,24,0,20.776,0,12,3.223,0,12,0Z"
          transform="translate(-14155.736 5200.139)"
          fill={color}
        />
        <Path
          id="Path_302180_-_Outline"
          data-name="Path 302180 - Outline"
          d="M12,1.829c-3.974,0-6.515.691-8,2.173S1.829,8.026,1.829,12,2.519,18.515,4,20s4.024,2.173,8,2.173,6.515-.691,8-2.173,2.173-4.024,2.173-8S21.48,5.484,20,4s-4.024-2.173-8-2.173M12,0c8.776,0,12,3.224,12,12S20.776,24,12,24,0,20.776,0,12,3.223,0,12,0Z"
          transform="translate(-14155.736 5200.139)"
          fill={color}
        />
        <Path
          id="Path_302181"
          data-name="Path 302181"
          d="M9.594-128.373h6.785L9.4-121.539v.38H20.838v-.38H14.072l6.96-6.834v-.38H9.594Zm.368,6.834,6.96-6.834h3.567l-6.979,6.834Zm10.876,2.848H9.4v.38H20.838Zm.194-12.909H9.594v.38H21.032Z"
          transform="translate(-14158.899 5337.149)"
          fill="#2d2f39"
        />
        <Path
          id="Path_302181_-_Outline"
          data-name="Path 302181 - Outline"
          d="M9.08-132.1H21.17v1.287H9.08Zm0,2.851H21.17v.38h.109l-.109.109v.532L15-122.025H20.98v1.287H8.891v-.38H8.8l.088-.088v-.553l6.185-6.2h-6Zm10.015,1.287H16.886l-5.9,5.936h2.192Zm-10.2,8.788H20.98v1.287H8.891Z"
          transform="translate(-14158.712 5337.187)"
          fill="#2d2f39"
        />
      </G>
    </Svg>
  );
}

export default ZonoMoneyIcon;
