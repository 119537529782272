import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

function SubmitSummary({ color = '#a1a3b4', width = 275, height = 253 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 275 253.012"
    >
      <G data-name="Group 115952">
        <G
          data-name="Group 115750"
          transform="translate(-571.158 -172.309) translate(571.158 172.309)"
        >
          <Path
            data-name="Rectangle 26713"
            transform="translate(.507 .507)"
            fill="#fff"
            d="M0 0H197.507V147.446H0z"
          />
          <Path
            data-name="Path 198479"
            d="M1.261 1.261h196.746v146.685H1.261zM.5 148.707h198.268V.5H.5z"
            transform="translate(-.373 -.373)"
          />
          <Path
            data-name="Path 198480"
            d="M199.261 149.2H1.5V1.5h197.761zm-197.507-.254h197.253V1.754H1.754z"
            transform="translate(-1.12 -1.12)"
            fill="#fff"
          />
          <Path
            data-name="Path 198481"
            d="M1.015 1.015h196.492v146.431H1.015zM0 148.461h198.522V0H0z"
          />
          <Path
            data-name="Rectangle 26714"
            transform="translate(9.505 7.976)"
            fill="#fff"
            d="M0 0H179.511V132.509H0z"
          />
          <Path
            data-name="Path 198482"
            d="M216.246 163.215H35.974V29.945h180.272zm-179.511-.761h178.75V30.706H36.735z"
            transform="translate(-26.849 -22.35)"
          />
          <Path
            data-name="Path 198484"
            d="M492.171 148.558v4.058h17.755V144.5z"
            transform="translate(-341.156 -109.835)"
          />
          <G data-name="Group 115676">
            <G data-name="Group 115682" transform="translate(111.548 21.813)">
              <Path
                data-name="Path 198485"
                d="M475.5 97.5v11.414c0 1.975.254 5.073-5.327 5.834h18.263a5.585 5.585 0 005.073-5.327V97.5z"
                transform="translate(-435.921 -96.951)"
                fill="#fff"
              />
              <Path
                data-name="Path 198486"
                d="M470.019 114.009l-.051-.757c5.022-.685 5.009-3.193 5-5.208V96h18.77v12.34a6 6 0 01-5.43 5.669zm2.88-.761h15.382a5.173 5.173 0 004.692-4.965V96.761h-17.247v11.279c.008 1.543.02 3.911-2.827 5.208"
                transform="translate(-436.133 -95.45)"
              />
              <Path
                data-name="Rectangle 26715"
                transform="translate(41.496 3.72)"
                d="M0 0H12.682V1.015H0z"
              />
              <Path
                data-name="Rectangle 26716"
                transform="translate(41.496 7.271)"
                d="M0 0H9.892V1.015H0z"
              />
              <Path
                data-name="Rectangle 26717"
                transform="translate(41.623 11.076)"
                d="M0 0H9.892V1.015H0z"
              />
              <Path
                data-name="Path 198487"
                d="M407.171 262.558v4.058h17.755V258.5z"
                transform="translate(-401.539 -213.083)"
              />
              <Path
                data-name="Path 198488"
                d="M390.5 211.5v11.414c0 1.975.254 5.073-5.327 5.834h18.263a5.585 5.585 0 005.073-5.327V211.5z"
                transform="translate(-385.119 -178.004)"
                fill="#fff"
              />
              <Path
                data-name="Path 198489"
                d="M385.019 228.009l-.051-.757c5.022-.685 5.009-3.193 5-5.208V210h18.77v12.34a6 6 0 01-5.431 5.669zm2.88-.761h15.382a5.173 5.173 0 004.692-4.965v-11.522h-17.246v11.279c.008 1.543.02 3.911-2.827 5.208"
                transform="translate(-384.968 -176.885)"
              />
              <Path
                data-name="Rectangle 26718"
                transform="translate(7.661 36.286)"
                d="M0 0H12.682V1.015H0z"
              />
              <Path
                data-name="Rectangle 26719"
                transform="translate(7.661 39.837)"
                d="M0 0H9.892V1.015H0z"
              />
              <Path
                data-name="Rectangle 26720"
                transform="translate(7.788 43.642)"
                d="M0 0H9.892V1.015H0z"
              />
              <Path
                data-name="Path 198490"
                d="M194.171 265.558v4.058h17.755V261.5z"
                transform="translate(-186.956 -248.649)"
              />
              <Path
                data-name="Path 198491"
                d="M177.5 214.5v11.414c0 1.975.254 5.073-5.327 5.834h18.263a5.585 5.585 0 005.073-5.327V214.5z"
                transform="translate(-170.536 -213.57)"
                fill="#fff"
              />
              <Path
                data-name="Path 198492"
                d="M172.019 231.009l-.051-.757c5.022-.685 5.009-3.193 5-5.208V213h18.77v12.34a6 6 0 01-5.431 5.669zm2.88-.761h15.382a5.173 5.173 0 004.692-4.965v-11.522h-17.246v11.279c.008 1.543.02 3.911-2.827 5.208"
                transform="translate(-170.385 -212.451)"
              />
              <Path
                data-name="Rectangle 26721"
                transform="translate(9.244 3.72)"
                d="M0 0H12.682V1.015H0z"
              />
              <Path
                data-name="Rectangle 26722"
                transform="translate(9.244 7.271)"
                d="M0 0H9.892V1.015H0z"
              />
              <Path
                data-name="Rectangle 26723"
                transform="translate(9.371 11.076)"
                d="M0 0H9.892V1.015H0z"
              />
              <Path
                data-name="Path 198493"
                d="M573.171 405.558v4.058h17.755V401.5z"
                transform="translate(-534.281 -356.083)"
              />
              <Path
                data-name="Path 198494"
                d="M556.5 354.5v11.414c0 1.975.254 5.073-5.327 5.834h18.263a5.585 5.585 0 005.073-5.327V354.5z"
                transform="translate(-517.861 -321.004)"
                fill="#fff"
              />
              <Path
                data-name="Path 198495"
                d="M551.019 371.009l-.051-.757c5.022-.685 5.009-3.193 5-5.208V353h18.77v12.34a6 6 0 01-5.431 5.669zm2.88-.761h15.382a5.173 5.173 0 004.692-4.965v-11.522h-17.246v11.279c.008 1.543.02 3.911-2.827 5.208"
                transform="translate(-517.71 -319.885)"
              />
              <Path
                data-name="Rectangle 26724"
                transform="translate(40.919 36.286)"
                d="M0 0H12.682V1.015H0z"
              />
              <Path
                data-name="Rectangle 26725"
                transform="translate(40.919 39.837)"
                d="M0 0H9.892V1.015H0z"
              />
              <Path
                data-name="Rectangle 26726"
                transform="translate(41.046 43.642)"
                d="M0 0H9.892V1.015H0z"
              />
              <Path
                data-name="Path 198500"
                d="M738.98 906l-1.721 10.166-1.059 6.234h-9.676l.335-5.532.647-10.706z"
                transform="translate(-653.794 -698.01)"
                fill="#fff"
              />
              <Path
                data-name="Path 198501"
                d="M735.333 921.641h-10.4l1.028-16.995 12.282-.172zm-9.593-.761h8.95l2.648-15.631-10.662.149z"
                transform="translate(-652.604 -696.872)"
              />
              <Path
                data-name="Path 198502"
                d="M689.658 957.018c-.846.882-4.293 3.786-10-.174a.163.163 0 00-.17-.011l-15.738 8.412a.8.8 0 00.378 1.509h25.678a1.845 1.845 0 001.8-2.252l-1.672-7.405a.162.162 0 00-.275-.078"
                transform="translate(-606.622 -735.935)"
              />
              <Path
                data-name="Path 198503"
                d="M739.965 906l-1.721 10.166-10.4.7.647-10.706z"
                transform="translate(-654.778 -698.01)"
              />
              <Path
                data-name="Path 198504"
                d="M899.165 922.4h-9.675l-.987-5.532-1.91-10.706 11.472-.16.684 10.2z"
                transform="translate(-773.26 -698.01)"
                fill="#fff"
              />
              <Path
                data-name="Path 198505"
                d="M898.239 921.645h-10.4l-3.031-16.993 12.281-.171zm-9.764-.761h8.95l-1.049-15.633-10.663.149z"
                transform="translate(-771.926 -696.876)"
              />
              <Path
                data-name="Path 198506"
                d="M857.324 957.018c-.846.882-4.293 3.786-10-.174a.164.164 0 00-.17-.011l-15.738 8.412a.8.8 0 00.378 1.509h25.678a1.845 1.845 0 001.8-2.252L857.6 957.1a.162.162 0 00-.275-.078"
                transform="translate(-731.76 -735.935)"
              />
              <Path
                data-name="Path 198507"
                d="M898.749 916.2l-10.245.667-1.911-10.706 11.472-.16z"
                transform="translate(-773.26 -698.01)"
              />
              <Path
                data-name="Path 198508"
                d="M657.813 154.767a2.03 2.03 0 01-2.894-.177l-14.678-16.916-.015-.04-1.815-4.566 4.446 2.49.011.006 15.075 16.318a2.03 2.03 0 01-.131 2.885"
                transform="translate(-588.029 -121.13)"
                fill="#fff"
              />
              <Path
                data-name="Path 198509"
                d="M654.25 153.152h-.151a2.392 2.392 0 01-1.674-.826L637.7 135.354l-.039-.1-2.208-5.554 5.435 3.043.052.05 15.075 16.318a2.411 2.411 0 01-1.767 4.046m-15.883-18.194L653 151.823a1.65 1.65 0 102.458-2.2l-15.032-16.273-3.47-1.943z"
                transform="translate(-585.823 -118.612)"
              />
              <Path
                data-name="Path 198510"
                d="M642.858 135.559a1.918 1.918 0 01-.911 1.6 2.441 2.441 0 01-1.72.474l-1.815-4.566z"
                transform="translate(-588.029 -121.131)"
              />
              <Path
                data-name="Path 198511"
                d="M851.56 481.951s1.136 7.23-.276 20.049c-1.469 13.337-3.739 28.465-3.739 28.465l5.7 65.423h-16.07l-9.8-63.406-4.538-53.2z"
                transform="translate(-725.675 -379.527)"
                fill="#fff"
              />
              <Path
                data-name="Path 198511 - Outline"
                d="M820.641 477.069l29.72 2.761.061.39a47.046 47.046 0 01.4 5.28c.063 1.991.053 4.12-.029 6.326-.1 2.755-.318 5.641-.642 8.577-1.407 12.774-3.575 27.371-3.732 28.425l5.742 65.915H835.1l-9.869-63.853v-.017zm28.831 3.7l-27.717-2.575 4.485 52.578 9.731 62.959h15.082L845.4 528.8l.009-.059c.023-.151 2.285-15.271 3.736-28.445.32-2.9.534-5.759.636-8.484a92.3 92.3 0 00.03-6.249c-.081-2.463-.252-4.096-.339-4.796z"
                transform="translate(-724.036 -377.876)"
              />
              <Path
                data-name="Path 198512"
                d="M852.973 595.6h-16.317l-9.817-63.514-4.552-53.359 28.973 2.692.015.1c.012.073 1.123 7.394-.275 20.082-1.433 13-3.654 27.9-3.737 28.455zm-16.1-.254H852.7l-5.688-65.3v-.015c.023-.151 2.286-15.278 3.738-28.46 1.307-11.862.416-19.013.291-19.918l-28.473-2.646 4.526 53.049z"
                transform="translate(-725.265 -379.114)"
              />
              <Path
                data-name="Path 198513"
                d="M766.3 445.647l-24.88 60.868-12.514 63.406h-15.777l7.112-66.432 12.859-59z"
                transform="translate(-643.794 -353.561)"
                fill="#fff"
              />
              <Path
                data-name="Path 198514"
                d="M727.974 569.15H711.46l.045-.421 7.115-66.452v-.02l12.926-59.309 34.068 1.186-.206.505-24.871 60.834zm-15.667-.761h15.042l12.46-63.136.014-.034 24.677-60.363-32.342-1.126-12.786 58.67z"
                transform="translate(-642.549 -352.409)"
              />
              <Path
                data-name="Path 198515"
                d="M671.4 198.131l-3.031 2.369-.54.424-7.768 6.076a5.823 5.823 0 01-9.286-3.391l-7.754-32.822-3.311-7.741a2.354 2.354 0 01-.175-1.186c.156-1.382.67-1.445.67-1.445a2.187 2.187 0 011.225-1.9 3.147 3.147 0 011.73-1.907 1.724 1.724 0 012.448-1l-.213-2.5c-.545-2.717.908-3.348.908-3.348l2.767 5.887-.137 3.49 2.465 2.8-1.687 6.465 8.807 24.9 5.73-6.235 1.613-1.755z"
                transform="translate(-588.856 -133.58)"
                fill="#fff"
              />
              <Path
                data-name="Path 198516"
                d="M655.364 207.157a6.235 6.235 0 01-2.14-.381 6.167 6.167 0 01-3.942-4.553l-7.744-32.78-3.3-7.711a2.731 2.731 0 01-.2-1.378 2.211 2.211 0 01.694-1.652 2.57 2.57 0 011.288-1.935 3.72 3.72 0 011.736-1.922 1.993 1.993 0 012.306-1.233l-.163-1.918c-.587-2.966 1.063-3.72 1.134-3.75l.339-.147 2.964 6.305-.135 3.431 2.5 2.838-1.708 6.548 8.557 24.192 7.312-7.956 5.886 13.638-11.57 9.046a6.174 6.174 0 01-3.811 1.321m-16.192-47.833c-.087.059-.292.345-.379 1.114a1.974 1.974 0 00.146.994l3.332 7.8 7.754 32.822a5.442 5.442 0 008.681 3.179l11.107-8.684-5.189-12.021-7.374 8.024-9.057-25.6 1.665-6.382-2.433-2.763.139-3.55-2.527-5.377a2.963 2.963 0 00-.391 2.685l.006.043.271 3.182-.608-.307a1.611 1.611 0 00-1.137-.081 1.462 1.462 0 00-.788.882l-.07.171-.177.051a2.823 2.823 0 00-1.485 1.692l-.062.146-.147.058a1.812 1.812 0 00-.986 1.58l-.024.311z"
                transform="translate(-587.736 -132.12)"
              />
              <Path
                data-name="Path 198517"
                d="M810.495 148.024l-.456-6.807s-11.191 2.884-5.884-18.229c0 0 7.153-7.5 16.037 0v15.345l1.5 7.845z"
                transform="translate(-710.666 -111.118)"
                fill="#fff"
              />
              <Path
                data-name="Path 198518"
                d="M809.007 147.4l-.455-6.8a5.942 5.942 0 01-5.039-1.559c-2.755-2.739-3.046-8.531-.863-17.215l.024-.1.069-.073c.074-.077 7.5-7.67 16.558-.028l.135.114v15.485l1.564 8.194zm.245-7.734l.458 6.846 10.4-1.714-1.433-7.534v-15.17c-3.535-2.93-7.186-3.814-10.853-2.628a12.071 12.071 0 00-4.461 2.645c-2.6 10.4-1.062 14.65.687 16.388a5.351 5.351 0 004.759 1.278z"
                transform="translate(-709.529 -110.047)"
              />
              <Path
                data-name="Path 198519"
                d="M955.178 308.811l-17.786 18.4-1.169 1.169.583 5.258-1.167 4.383-1.753 2.044-1.086-10.376v-6.133l2.838-2.483 9.025-16.5-2.006-2.082-3.726-3.866 5.261-12.14 5.065 6.577 6.344 8.236a5.761 5.761 0 01-.424 7.518"
                transform="translate(-807.748 -235.629)"
                fill="#fff"
              />
              <Path
                data-name="Path 198520"
                d="M932.481 338.793L931.3 327.5v-6.306l2.917-2.552 8.858-16.2-5.715-5.929 5.618-12.965 11.8 15.324a6.178 6.178 0 01-.452 8.015l-17.786 18.4-1.044 1.045.571 5.147-1.207 4.536zm-.419-11.313l.992 9.475 1.121-1.308 1.126-4.23-.6-5.369 1.3-1.3 17.781-18.392a5.413 5.413 0 00.4-7.022l-11.015-14.3-4.9 11.314 5.751 5.967-9.191 16.809-2.76 2.414z"
                transform="translate(-806.628 -233.441)"
              />
              <Path
                data-name="Path 198521"
                d="M967.3 293.057l-6.6 9.428-3.726-3.866 5.261-12.14z"
                transform="translate(-825.787 -235.629)"
              />
              <Path
                data-name="Path 198522"
                d="M744.161 302.739l-3.031 2.369-4.119-13.443 1.613-1.755z"
                transform="translate(-661.619 -238.189)"
              />
              <Path
                data-name="Path 198523"
                d="M791.831 245.311c-.078 2.133-.127 8.094-.063 15.1.089 9.956.408 22.024 1.2 28.208 0 0-18.181 4.2-30.1 2.879-9.131-1.015-12.667-2.27-12.667-2.27l.023-.426 2.234-42.673-5.141 3.706L741.191 235l8.918-10.567a14.451 14.451 0 016.719-4.464l4.515-1.415.85-.571a2.5 2.5 0 011.748-.4 29.172 29.172 0 0011.975-.928 2.478 2.478 0 012.285.479l.7.586 8.533 2.8a17.688 17.688 0 018.079 5.489l10.508 12.626-9.326 10.8-4.817-4.923c-.018.1-.033.373-.048.8"
                transform="translate(-664.739 -183.441)"
                fill="#e85b49"
              />
              <Path
                data-name="Path 198524"
                d="M766.678 291.009a47 47 0 01-5.142-.257c-9.071-1.008-12.606-2.238-12.752-2.29l-.268-.1.038-.71 2.193-41.88-4.888 3.523-6.4-15.486 9.068-10.744a14.807 14.807 0 016.9-4.582l4.462-1.4.8-.54a2.884 2.884 0 012.013-.462 28.91 28.91 0 0011.814-.916 2.85 2.85 0 012.637.552l.646.539 8.463 2.775a18.051 18.051 0 018.252 5.607l10.715 12.875-9.808 11.358-4.5-4.6c-.077 2.167-.123 8.115-.061 15.012.067 7.554.312 21.258 1.2 28.163l.044.342-.335.078c-.158.036-13.75 3.143-25.083 3.143m-17.372-3.178A72.279 72.279 0 00761.62 290c10.835 1.2 27.1-2.239 29.635-2.8-.857-7.046-1.1-20.461-1.162-27.909-.063-6.981-.014-13 .063-15.115a7.36 7.36 0 01.054-.85l.128-.731 5.047 5.158 8.845-10.243-10.3-12.378a17.293 17.293 0 00-7.9-5.371l-8.6-2.821-.758-.633a2.088 2.088 0 00-1.934-.406 29.688 29.688 0 01-12.136.94 2.124 2.124 0 00-1.481.34l-.9.6-4.568 1.432a14.048 14.048 0 00-6.542 4.347l-8.769 10.39 5.859 14.17 5.395-3.889z"
                transform="translate(-663.444 -182.32)"
              />
              <Path
                data-name="Path 198525"
                d="M817.936 97.947c.928-8.59-3.821-7.567-3.822-7.567-4.768-6.828-7.166-2.753-7.166-2.753-4.022-3.587-5.265-.128-5.265-.128-3.947-1.625-4.643 1.857-4.643 1.857-3.715-.7-3.483 2.786-3.483 2.786-1.393.232-1.678 2.045-1.625 2.786.333 4.665 3.656 5.026 5.505 4.858l.425 5.689a.837.837 0 001.66.077c.012-.074.024-.139.033-.184.4-2.019 3.692-3.577 4.153 0 0 0 .346 4.615-2.077 4.73a8.225 8.225 0 008.852 4.356c1.031-.191 7.854-1.91 7.318-13.773a20.022 20.022 0 00.133-2.735"
                transform="translate(-702.606 -86)"
              />
              <Path
                data-name="Rectangle 26739"
                transform="rotate(-23.94 89.204 -107.29)"
                d="M0 0H0.507V2.498H0z"
              />
              <Path
                data-name="Rectangle 26740"
                transform="rotate(-23.94 85.052 -111.292)"
                d="M0 0H0.507V2.498H0z"
              />
              <Path
                data-name="Rectangle 26741"
                transform="rotate(-20.556 90.898 -137.355)"
                d="M0 0H0.507V2.167H0z"
              />
              <Path
                data-name="Path 198526"
                d="M1025.117 578.449a171.5 171.5 0 01-6.588 30.793l-2.824-.353s.106-3.928.369-9.824c.7-15.616 2.494-45.041 6.339-51.961 0 0 5.141 9.065 2.7 31.344"
                transform="translate(-869.622 -430.147)"
                fill="#fff"
              />
              <Path
                data-name="Path 198527"
                d="M1017.653 607.373l-3.485-.436.009-.345c0-.039.109-4 .369-9.831.737-16.5 2.547-45.217 6.387-52.129l.329-.593.334.59c.212.375 5.173 9.426 2.752 31.573a173.148 173.148 0 01-6.6 30.862zm-2.705-1.105l2.163.27a171.922 171.922 0 006.481-30.417c2.027-18.54-1.258-27.947-2.335-30.433-3.533 7.834-5.239 35.178-5.95 51.108a657.131 657.131 0 00-.359 9.472"
                transform="translate(-868.476 -427.859)"
              />
              <Path
                data-name="Path 198528"
                d="M1020.533 612.24l-.761-.007a450.454 450.454 0 015.3-66.084l.749.132a449.662 449.662 0 00-5.29 65.959"
                transform="translate(-872.659 -429.434)"
              />
              <Path
                data-name="Path 198529"
                d="M1025.117 670.68a171.5 171.5 0 01-6.588 30.793l-2.824-.353s.106-3.928.369-9.824a135.44 135.44 0 019.044-20.617"
                transform="translate(-869.622 -522.378)"
              />
              <Path
                data-name="Path 198530"
                d="M1017.792 688s9.886-27.009 16.064-33.717c0 0 4.06 16.594-13.946 34.6z"
                transform="translate(-871.181 -510.14)"
                fill="#fff"
              />
              <Path
                data-name="Path 198531"
                d="M1018.58 687.037l-2.69-1.121.125-.342c.4-1.106 9.983-27.157 16.141-33.844l.481-.522.168.69a27.115 27.115 0 01-.217 10.71c-1.138 5.891-4.46 14.879-13.83 24.249zm-1.724-1.543l1.545.644c9.1-9.168 12.331-17.926 13.441-23.675a29.047 29.047 0 00.363-9.626c-5.7 6.971-14.212 29.6-15.349 32.657"
                transform="translate(-869.761 -507.846)"
              />
              <Path
                data-name="Path 198532"
                d="M952.025 586.079s15.182 22.066 16.594 51.9h-.53s-19.241-32.128-16.064-51.9"
                transform="translate(-821.831 -459.236)"
                fill="#fff"
              />
              <Path
                data-name="Path 198533"
                d="M967.915 635.421h-1.144l-.111-.185a165.092 165.092 0 01-9.248-18.584c-4.046-9.629-8.521-23.269-6.866-33.571l.151-.938.538.782a95.133 95.133 0 017.8 14.828 109.32 109.32 0 018.857 37.269zM951.158 584.2c-2.207 18.364 13.927 46.816 15.95 50.293-1.382-25.885-13.239-46.007-15.95-50.293"
                transform="translate(-820.728 -456.298)"
              />
              <Path
                data-name="Path 198534"
                d="M967.765 637.512a181.484 181.484 0 00-16.024-51.779l.673-.355a182.354 182.354 0 0116.1 52.02z"
                transform="translate(-821.883 -458.712)"
              />
              <Path
                data-name="Path 198535"
                d="M1015.355 691.546l-.718-.252a259.276 259.276 0 0116.533-37.75l.662.375a258.537 258.537 0 00-16.477 37.627"
                transform="translate(-868.826 -509.588)"
              />
              <Path
                data-name="Path 198536"
                d="M997.394 783.036l-1.161 14.221-3.252 39.8h-12.533l-4.413-54.017z"
                transform="translate(-840.015 -606.235)"
                fill="#e85b49"
              />
              <Path
                data-name="Path 198537"
                d="M992.117 836.314h-13.235l-4.475-54.778h22.186zm-12.533-.761h11.832l4.351-53.256h-20.534z"
                transform="translate(-838.8 -605.116)"
                fill="#191919"
              />
              <Path
                data-name="Path 198538"
                d="M1020.428 839.1l-3.252 39.8h-8.724z"
                transform="translate(-864.21 -648.081)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Group 115751"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={1}
        >
          <Path
            data-name="Path 198689"
            d="M0 0h27.628"
            transform="translate(-571.158 -172.309) translate(618.326 221.6)"
          />
          <Path
            data-name="Path 198690"
            d="M0 5.055h45.893"
            transform="translate(-571.158 -172.309) translate(618.326 221.6)"
          />
          <Path
            data-name="Path 198691"
            d="M0 10.111h45.893"
            transform="translate(-571.158 -172.309) translate(618.326 221.6)"
          />
          <Path
            data-name="Path 198692"
            d="M0 15.166h45.893"
            transform="translate(-571.158 -172.309) translate(618.326 221.6)"
          />
        </G>
        <G
          data-name="Group 115752"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={1}
        >
          <Path
            data-name="Path 198689"
            d="M0 0h27.628"
            transform="translate(-571.158 -172.309) translate(618.326 267.961)"
          />
          <Path
            data-name="Path 198690"
            d="M0 5.055h45.893"
            transform="translate(-571.158 -172.309) translate(618.326 267.961)"
          />
          <Path
            data-name="Path 198691"
            d="M0 10.111h45.893"
            transform="translate(-571.158 -172.309) translate(618.326 267.961)"
          />
          <Path
            data-name="Path 198692"
            d="M0 15.166h45.893"
            transform="translate(-571.158 -172.309) translate(618.326 267.961)"
          />
        </G>
        <Path
          data-name="Path 198792"
          d="M608.967 849.643a6.5 6.5 0 00-6.467 5.757h12.937a6.5 6.5 0 00-6.47-5.757zm2.729-.961a4.339 4.339 0 10-5.458 0 7.934 7.934 0 00-5.186 6.654 1.391 1.391 0 001.407 1.51h13.016a1.39 1.39 0 001.407-1.51 7.935 7.935 0 00-5.182-6.655zm-2.729-6.241a2.881 2.881 0 102.893 2.881 2.887 2.887 0 00-2.893-2.882z"
          transform="translate(-571.158 -172.309) translate(-5.896 -619.401)"
          fill="#5bbe84"
        />
        <Path
          data-name="Path 198793"
          d="M608.967 849.643a6.5 6.5 0 00-6.467 5.757h12.937a6.5 6.5 0 00-6.47-5.757zm2.729-.961a4.339 4.339 0 10-5.458 0 7.934 7.934 0 00-5.186 6.654 1.391 1.391 0 001.407 1.51h13.016a1.39 1.39 0 001.407-1.51 7.935 7.935 0 00-5.182-6.655zm-2.729-6.241a2.881 2.881 0 102.893 2.881 2.887 2.887 0 00-2.893-2.882z"
          transform="translate(-571.158 -172.309) translate(-5.896 -573.718)"
          fill="#5bbe84"
        />
        <G
          data-name="Rectangle 26787"
          transform="translate(-571.158 -172.309) translate(588.652 259.125)"
          fill="none"
          stroke="#000"
          strokeWidth={0.5}
        >
          <Rect width={84.679} height={32.86} rx={3} stroke="none" />
          <Rect x={0.25} y={0.25} width={84.179} height={32.36} rx={2.75} />
        </G>
        <G
          data-name="Rectangle 26788"
          transform="translate(-571.158 -172.309) translate(588.652 212.362)"
          fill="none"
          stroke="#000"
          strokeWidth={0.5}
        >
          <Rect width={84.679} height={32.86} rx={3} stroke="none" />
          <Rect x={0.25} y={0.25} width={84.179} height={32.36} rx={2.75} />
        </G>
        <Path
          data-name="Path 198794"
          d="M663.079 241.842l4.747 5.076 10.768-10.152"
          fill="none"
          stroke="#00c17f"
          strokeLinecap="round"
          strokeWidth={2}
          transform="translate(-571.158 -172.309)"
        />
        <Path
          data-name="Path 198795"
          d="M663.079 288.605l4.747 5.076 10.768-10.152"
          fill="none"
          stroke="#00c17f"
          strokeLinecap="round"
          strokeWidth={2}
          transform="translate(-571.158 -172.309)"
        />
      </G>
    </Svg>
  );
}

export default SubmitSummary;
