//@ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';
import { TextInput } from 'react-native-paper';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { InputKeyboardType } from '../types';
import _ from 'lodash';
import { TextInputMaskOptionProp } from 'react-native-masked-text';
import { colors } from '../colors/colors';
import { fontConfig } from '../fonts/fonts';
import { textInputValidate, ValidationRule, useInput } from '@zonofi/common';

/* eslint-disable-next-line */
export interface InputFieldProps {
  label: string;
  initialValue?: string;
  validationRules?: ValidationRule[];
  prefix?: string;
  maxLength?: number;
  keyboardType?: InputKeyboardType;
  disabled?: boolean;
  pasteDisabled?: boolean;
  onChangeText?: (text: string, name?: string) => void;
  onValidate?: (isValid: boolean, text: string) => void;
  mask?: TextInputMaskOptionProp;
  maskType?: string;
  editable?: boolean;
  autofocus?: boolean;
  width?: number | string;
  displayError?: boolean;
  onSubmitEditing?: () => void;
  inputHeight?: number | string;
  labelStyle?: any;
  isPadding?: boolean;
  IconComponent?: React.ReactNode;
  textStyle?: any;
  prefixStyles?: any;
  borderRadius?: number;
  name?: string;
  multiline?: boolean;
  numberOfLines?: number;
  containerStyle?: StyleProp<ViewStyle>;
  errorStyles?: StyleProp<TextStyle>;
}

export const InputField: React.FC<InputFieldProps> = ({
  label,
  initialValue = '',
  prefix = '',
  maxLength,
  keyboardType,
  pasteDisabled = false,
  onChangeText,
  mask,
  maskType,
  validationRules = [],
  onValidate,
  disabled = false,
  editable = true,
  autofocus = false,
  width,
  displayError = true,
  onSubmitEditing,
  inputHeight,
  labelStyle = {},
  isPadding = true,
  IconComponent,
  prefixStyles,
  textStyle,
  borderRadius,
  name,
  multiline,
  numberOfLines,
  containerStyle,
  errorStyles,
}) => {
  const [text, setText] = useState(initialValue);
  const [focus, setFocus] = useState(false);
  const { checkValue } = useInput();
  const [errors, setErrors] = useState('');
  const validate = (isValid: boolean, text: string) => {
    if (!_.isUndefined(onValidate)) {
      const checkMobile =
        !_.isNaN(Number(text[0])) === true ? text.slice(0, 10) : text;
      setText(checkMobile);
      onValidate(isValid, checkMobile);
    }
  };

  const ref = useRef<any>(null);

  useEffect(() => {
    if (autofocus) {
      ref.current.focus();
    }
  }, [autofocus]);

  return (
    <View style={containerStyle || {}}>
      <TextInput
        ref={ref}
        onSubmitEditing={onSubmitEditing}
        label={
          <Text
            style={[
              styles.label,
              focus || !_.isEmpty(text)
                ? {
                    ...fontConfig.default.segoeBold,
                    backgroundColor: colors.white.color,
                  }
                : {
                    ...fontConfig.default.segoeRegular,
                    backgroundColor: colors.transparent.color,
                  },
              {
                color:
                  editable && !disabled
                    ? colors.grey.tertiary
                    : // colors.eerieBlack.teritiary
                      colors.grey.tertiary,
                // colors.eerieBlack.opacity40,
              },
              labelStyle,
            ]}
          >
            {label}
          </Text>
        }
        value={text}
        onChangeText={(text) => {
          const phone = text.match(/\d+/g) || [];
          let number = phone[0] || '';
          if (number.length >= 10) {
            number = number.substring(0, 10);
          }
          const text_ = label === 'Mobile Number*' ? number : text;
          if (!_.isUndefined(onChangeText)) {
            onChangeText(text_, name);
          }
          checkValue(
            text_,
            setText,
            [...validationRules, textInputValidate],
            setErrors,
            validate
          );
        }}
        right={
          IconComponent ? <TextInput.Icon name={() => IconComponent} /> : null
        }
        mode={editable ? 'outlined' : 'flat'}
        theme={{
          roundness: borderRadius ? borderRadius : 8,
          colors: {
            primary: colors.indigo.opacity50,
            accent: colors.indigo.opacity50,
            text: colors.eerieBlack.teritiary,
            placeholder: colors.eerieBlack.teritiary,
            background: colors.transparent.color,
            disabled: colors.indigo.opacity20,
          },
        }}
        left={
          !_.isEmpty(prefix) && (
            <TextInput.Affix
              text={prefix}
              textStyle={[styles.text, textStyle, prefixStyles]}
            />
          )
        }
        style={[styles.text, textStyle, { width: width, height: inputHeight }]}
        outlineColor={
          disabled ? colors.indigo.opacity20 : colors.indigo.opacity50
        }
        underlineColor={colors.transparent.color}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        disabled={editable && disabled}
        onKeyPress={(event) => {
          if (pasteDisabled) {
            if (
              //@ts-ignore
              event.key === 'Control' ||
              //@ts-ignore
              event.key === 'V' ||
              //@ts-ignore
              event.key === 'v' ||
              //@ts-ignore
              event.key === 'Meta'
            ) {
              setText('');
            }
          }
        }}
        maxLength={maxLength}
        keyboardType={keyboardType}
        editable={editable}
        multiline={multiline}
        numberOfLines={numberOfLines}
      />
      {displayError && (
        <View style={[styles.errorsContainer, { width: width }]}>
          {errors.length > 0 && (
            <Text style={[styles.errors, errorStyles]}>{errors}</Text>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  paddingV1: { paddingVertical: hp(0.5) },
  errorsContainer: { paddingVertical: hp(0.5) },
  label: {
    paddingHorizontal: 4,
    color: 'rgba(255, 255, 255, 0.5)',
  },
  text: {
    fontSize: 20,
    lineHeight: 28,
    ...fontConfig.default.segoeRegular,
    color: colors.eerieBlack.teritiary,
  },
  errors: {
    ...fontConfig.default.segoeRegular,
    fontSize: 12,
    color: colors.steelBlue.color,
    lineHeight: 15,
  },
});

export default InputField;
