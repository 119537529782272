import { PermissionsAndroid, Platform } from 'react-native';
// import ReactNativeBlobUtil from 'react-native-blob-util';
import { Share as RNShare } from 'react-native';
import Share from 'react-native-share';

export const requestPermission = async () => {
  if (Platform.OS === 'android') {
    await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS['WRITE_EXTERNAL_STORAGE']
    );
    await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS['READ_EXTERNAL_STORAGE']
    );
  }
};

export const downloadFileData = (
  fileName: string,
  url: string,
  mime: string
) => {
  // const dirs = ReactNativeBlobUtil.fs.dirs;
  // requestPermission();
  // ReactNativeBlobUtil.config({
  //   fileCache: true,
  // })
  //   .fetch('GET', url, {
  //     Accept: mime,
  //     'Content-Type': mime,
  //   })
  //   .then(async (res) => {
  //     console.log(res, 'fileResponse');
  //     const base64 = await res.base64();
  //     const base64Data = `data:${mime};base64,` + base64;
  //     return base64Data;
  //   })
  //   .catch((ex) => {
  //     console.log(ex, 'DWNLD ERROR');
  //   });
};

export const shareFile = (
  fileName: string,
  url: string,
  accessToken: string,
  setLoading: (state: boolean) => void,
  download = false,
  type = 'pdf',
  isSignedUrl = false
) => {
  // const dirs = ReactNativeBlobUtil.fs.dirs;
  // const getMimeType = (type: string) => {
  //   switch (type) {
  //     case 'csv':
  //       return 'text/plain';
  //     case 'pdf':
  //       return 'application/pdf';
  //     case 'image/jpeg':
  //       return 'image/jpeg';
  //     default:
  //       return '**/**';
  //   }
  // };
  // requestPermission();
  // ReactNativeBlobUtil.config({
  //   fileCache: true,
  //   appendExt: type,
  //   path: `${dirs.DownloadDir}/Download/zono/${fileName}`,
  //   addAndroidDownloads: {
  //     useDownloadManager: true,
  //     notification: true,
  //     mime: getMimeType(type),
  //     path: `${dirs.DownloadDir}/Download/zono/${fileName}`,
  //   },
  // })
  //   .fetch(
  //     'GET',
  //     url,
  //     isSignedUrl
  //       ? {
  //           'Content-Type': getMimeType(type),
  //         }
  //       : {
  //           Accept: getMimeType(type),
  //           'Content-Type': getMimeType(type),
  //           Authorization: `Bearer ${accessToken}`,
  //         }
  //   )
  //   .then(async (res) => {
  //     if (!download) {
  //       const base64 = await res.base64();
  //       // const base64Data = `data:${mimeType};base64,` + base64;
  //       const base64Data = `data:application/pdf;base64,` + base64;
  //       Share.open({ url: base64Data });
  //     }
  //     setLoading && setLoading(false);
  //   })
  //   .catch((ex) => {
  //     console.log(ex, 'DWNLD ERROR');
  //   });
};

export const shareMessage = (message: string) => {
  try {
    RNShare.share({
      message: message,
    });
  } catch (error) {
    alert(error);
  }
};
