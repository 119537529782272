import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function Email(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 14.344 11.787"
    >
      <Path
        id="Path_864"
        data-name="Path 864"
        d="M517.149,751.3l-5.118,2.792a1.8,1.8,0,0,1-1.718,0L505.2,751.3v4.97a.6.6,0,0,0,.6.6h10.758a.6.6,0,0,0,.6-.6Zm-5.69-3.756a.6.6,0,0,0-.573,0l-5.04,2.749,5.04,2.749a.6.6,0,0,0,.573,0l5.04-2.749Zm-1.145-1.049a1.792,1.792,0,0,1,1.718,0l6,3.273a.6.6,0,0,1,.311.525v5.977a1.793,1.793,0,0,1-1.793,1.793H505.793A1.793,1.793,0,0,1,504,756.273V750.3a.6.6,0,0,1,.311-.525Z"
        transform="translate(-504 -746.278)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </Svg>
  );
}

export default Email;
