import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { ActivityIndicator } from 'react-native-paper';
import { colors } from '../colors/colors';
import { TailSpin } from 'react-loader-spinner';
import { isNumber } from 'lodash';

/* eslint-disable-next-line */
export interface LoadingSpinnerProps {
  size?: number | 'small' | 'large' | undefined;
  containerStyle?: StyleProp<ViewStyle>;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 'large',
  containerStyle,
}) => {
  return (
    <TailSpin
      height={isNumber(size) ? size : size === 'small' ? 25 : 45}
      width={isNumber(size) ? size : size === 'small' ? 25 : 45}
      wrapperStyle={{
        padding: '8px',
      }}
    />
  );
  // return (
  //   <ActivityIndicator
  //     color={colors.lightGrey.color}
  //     animating
  //     size={size}
  //     style={[styles.container, containerStyle]}
  //   />
  // );
};

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: 'center', alignItems: 'center' },
});
