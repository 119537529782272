import { ThemeContext } from '@zonofi/shared';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProductVariant,
  getCustomerInvite,
  showToast,
  useEditOrderMutation,
  useGetOrderDetailsMutation,
  useGetProductsMutation,
  useGetTrackPODetailsMutation,
  useNotifyToBuyerMutation,
  ErrorVariants,
} from '@zonofi/zono-money-store';
import {
  dispatch,
  extractDataFromOrder,
  extractDataFromPO,
  useAppSelector,
} from '@zonofi/zono-money-helpers';
import { Dimensions, StyleSheet } from 'react-native';
import '../whatsapp-flow.scss';
import { isEmpty } from 'lodash';
import { getConfigValue } from '@zonofi/common';

export const CampaignCartScreen: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const params = useParams();
  const { orderId } = params;
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;
  const [isEdit, setIsEdit] = useState(false);

  const [productsList, setProductsList] = useState<ProductVariant[]>([]);

  const { height } = Dimensions.get('window');
  const [data, setData] = useState<any>();

  const [fetchOrder, fetchOrderResponse] = useGetOrderDetailsMutation();
  const [fetchPoFile, fetchPoFileResponse] = useGetTrackPODetailsMutation();
  const [notifyToBuyer, notifyToBuyerResponse] = useNotifyToBuyerMutation();
  const [fetchProducts, productResponse] = useGetProductsMutation();

  const [searchKey, setSearchKey] = useState('');
  const [selectedOrderLineId, setSelectedOrderLineId] = useState('');

  const isPoFile = isNaN(Number(orderId));

  const getProducts = () => {
    fetchProducts({
      workspaceId: seller?.workspaceId,
      params: { customerId: inviteId, pageSize: 40, pageNo: 1 },
      body: { searchKey: searchKey },
    });
  };

  const [editOrder, editOrderResponse] = useEditOrderMutation();
  const onIncrement = (productVariantId) => {
    const selectedLine = data?.lines.find(
      (item) => item?.productVariantId === productVariantId
    );

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: selectedLine.quantity + 1,
            productVariantId: productVariantId,
            operator: 'add',
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const onDecrement = (productVariantId) => {
    const selectedLine = data?.lines.find(
      (item) => item?.productVariantId === productVariantId
    );

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: (selectedLine.quantity - 1).toString(),
            productVariantId: productVariantId,
            operator: 'minus',
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const onChangeQuantity = (productVariantId, qty) => {
    const selectedLine = data?.lines.find(
      (item) => item?.productVariantId === productVariantId
    );

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: qty.toString(),
            productVariantId: productVariantId,
          },
        ],
        orderId: selectedLine?.orderId?.toString(),
      },
    });
  };

  const handleAddProductFromSearch = (productVariantId) => {
    const selectedLine = productsList.find(
      (item) => item?.productVariantId === productVariantId
    );
    const findOrderId = data?.lines?.find((item) => item?.orderId)?.orderId;

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: '1',
            productVariantId: productVariantId,
          },
        ],
        orderId: findOrderId?.toString(),
      },
    });
  };

  const handleReplaceProductFromSearch = (productVariantId) => {
    const selectedLine = productsList.find(
      (item) => item?.productVariantId === productVariantId
    );
    const findOrderId = data?.lines?.find((item) => item?.orderId)?.orderId;
    const selectedOrderLine = data?.lines?.find(
      (item) => item.orderLineId === selectedOrderLineId
    );

    editOrder({
      workspaceId: seller?.workspaceId,
      body: {
        customerId: inviteId,
        lines: [
          {
            quantity: '1',
            productVariantId: productVariantId,
            orderLineId: selectedOrderLine?.orderLineId,
            poFileLineId: selectedOrderLine?.poFileLineId,
          },
        ],
        poFileId: selectedOrderLine?.poFileId?.toString(),
        orderId: findOrderId?.toString(),
      },
    });
  };

  const getOrder = async () => {
    if (isPoFile) {
      await fetchPoFile({
        workspaceId: seller?.workspaceId,
        body: {
          includeInvoice: true,
          includeTax: true,
          pofileId: String(orderId),
          sortBy: 'sequence',
          sortDirection: 'ASC',
          customerId: inviteId,
          includePromotionData: true,
        },
      });
    } else {
      fetchOrder({
        workspaceId: seller?.workspaceId,
        orderId: Number(orderId),
        body: {
          includeInvoice: true,
          includeTax: true,
          includeCustomer: true,
          includePromotions: true,
          customerId: inviteId,
          includePromotionData: true,
        },
      });
    }
  };

  useEffect(() => {
    getOrder();
    getProducts();
  }, [orderId, editOrderResponse]);

  useEffect(() => {
    if (fetchOrderResponse?.isSuccess && fetchOrderResponse?.data) {
      if (isEmpty(fetchOrderResponse?.data)) {
        setData(undefined);
      } else {
        const data = extractDataFromOrder(fetchOrderResponse?.data);
        setData(data);
      }
    }
  }, [fetchOrderResponse]);

  useEffect(() => {
    if (fetchPoFileResponse?.isSuccess && fetchPoFileResponse?.data) {
      if (isEmpty(fetchPoFileResponse?.data)) {
        setData(undefined);
      } else {
        const data = extractDataFromPO(fetchPoFileResponse?.data);
        setData(data);
      }
    }
  }, [fetchPoFileResponse]);

  const onConfirm = () => {
    if (data?.poFileId)
      notifyToBuyer({
        sellerWorkspaceId: seller?.workspaceId,
        poFileId: data?.poFileId,
        customerId: inviteId,
      });
  };

  useEffect(() => {
    if (notifyToBuyerResponse?.isSuccess) {
      console.log('SHOW TOAST');
      dispatch(
        showToast({
          canDisplay: true,
          message: 'Redirecting to Whatsapp...',
          responseStatus: 'Order Modified',
          type: ErrorVariants.SUCCESS,
          dismissText: 'GO TO WHATSAPP',
        })
      );
    }
  }, [notifyToBuyerResponse]);

  const showLoader = useMemo(
    () =>
      !isPoFile
        ? fetchOrderResponse?.isUninitialized || fetchOrderResponse?.isLoading
        : fetchPoFileResponse?.isUninitialized ||
          fetchPoFileResponse?.isLoading,
    [
      fetchOrderResponse?.isLoading,
      fetchOrderResponse?.isUninitialized,
      fetchPoFileResponse?.isLoading,
      fetchPoFileResponse?.isUninitialized,
      isPoFile,
    ]
  );

  const onDismiss = () => {
    window.location.href = getConfigValue('WAPP_REDIRECT');
  };

  const onSearchHandler = (value: string) => {
    setSearchKey(value);
    if (value === '') setProductsList([]);
    else {
      fetchProducts({
        workspaceId: seller?.workspaceId,
        params: { pageNo: 1, pageSize: 40, customerId: inviteId },
        body: { searchKey: value },
      });
    }
  };

  useEffect(() => {
    if (productResponse?.isSuccess) {
      const data = productResponse?.data?.products?.map(
        (x) => x.productVariants?.[0]
      );
      setProductsList(data);
    }
  }, [productResponse]);

  useEffect(() => {
    onSearchHandler(searchKey);
  }, [searchKey]);

  return null;

  // return (
  //   <>
  //     {' '}
  //     <OrderEditScreenLayout
  //       productsData={{
  //         products: productsList,
  //         searchKey: searchKey,
  //         setSearchKey: setSearchKey,
  //       }}
  //       onIncrement={onIncrement}
  //       onDecrement={onDecrement}
  //       orderData={data}
  //       onConfirm={onConfirm}
  //       handleAddProductFromSearch={handleAddProductFromSearch}
  //       handleReplaceProductFromSearch={handleReplaceProductFromSearch}
  //       setSelectedOrderLineId={setSelectedOrderLineId}
  //       onChangeQuantity={onChangeQuantity}
  //     />
  //     <ToastComponent onDismiss={onDismiss} />{' '}
  //   </>
  // );
};

const styles = StyleSheet.create({
  button: {
    paddingVertical: 18,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  buttonText: { fontSize: 16, textAlign: 'center' },
});
