export interface OrderDetailsRequest {
  workspaceId: string;
  orderId: number;
  body: OrderDetailsRequestBody;
}

export interface OrderDetailsRequestBody {
  includeInvoice: boolean;
  includeTax: boolean;
  includeCustomer: boolean;
  includePromotions: boolean;
  customerId: string;
  includePromotionData: boolean;
}

export interface OrderDetailsResponse {
  customerName?: string;
  notifyToBuyer?: boolean;
  id: number;
  documentDate: string;
  customerId: string;
  divisionId: string;
  headDivisionId: string;
  status: string;
  importSource: string;
  createdBy: string;
  createdByName: string;
  userType: string;
  distributorName: string;
  subTotal: string;
  subTotalWithTax: string;
  taxAmount: string;
  total: string;
  totalWithTax: string;
  fullFillmentLocationId: string;
  cfaName: string;
  refOrderNumber: string;
  splitBy: string;
  createdAt: string;
  updatedAt: string;
  skucount: number;
  totalQuantity: number;
  discount: string;
  divisions: Division[];
  lines: OrderLine[];
  poFile: PoFile;
  estimatedDeliveryDate: null;
  showPrice: boolean;
  invoiceSummary: OrderInvoiceSummary;
}

export interface Division {
  divisionId: string;
  divisionName: string;
}

export interface OrderInvoiceSummary {
  totalQty: number;
  totalAmount: string;
  invoicedItem?: number;
  invoiceCount?: number;
  invoices: any[];
  discount?: string;
  taxAmount?: string;
  totalItems?: number;
}

export type DiscountType = 'percent' | 'direct';

export interface LineDiscount {
  discount: string;
  discountType: DiscountType;
}

export interface OrderLine {
  poFileLineId?: string;
  packSize?: number;
  uploadedProductName?: string;
  uploadedQty?: number;
  sequence?: string | number;
  createdAt?: string;
  updatedAt?: string;
  initialListPrice?: string;
  listPrice?: string;
  listPriceIncludesTax?: boolean;
  quantity?: number;
  cancelledQuantity?: number;
  minOrderQty?: number;
  erpOrderNumber?: string;
  orderId: number;
  unitPrice?: string;
  unitPriceWithTax?: string;
  discountedUnitPrice?: string;
  discountedUnitPriceWithTax?: string;
  proratedUnitPrice?: string;
  proratedUnitPriceWithTax?: string;
  linePrice?: string;
  linePriceWithTax?: string;
  discountedLinePrice?: string;
  discountedLinePriceWithTax?: string;
  lineTax?: string;
  divisionId?: string;
  divisionName?: string;
  headDivisionId?: string;
  headDivisonName?: string;
  proratedLinePrice?: string;
  proratedLinePriceWithTax?: string;
  proratedLineTax?: string;
  isDeleted?: boolean;
  id?: number;
  refundId?: null;
  customFields?: CustomFields;
  productVariant: OrderProduct;
  unitPriceWithTaxChangeSinceAdded?: number;
  totalLi?: number;
  discounts?: any[];
  taxLines?: TaxLine[];
  promotionIds?: number[];
  promotionNames?: string[];
  adjustedPrice?: string;
  invoiceLineSummary?: InvoiceLineSummary;
  lineDiscount?: LineDiscount;
}

export interface CustomFields {
  perItemPrice: null;
  skuType: null;
}

export interface OrderProduct {
  MOQ: number;
  id: number;
  sku: string;
  translations: Translation[];
  packSize: number;
  productVariantId: number;
  assetUrl: string;
}

export interface TaxLine {
  taxRate: number;
  taxAmount: string;
  taxCategory: string;
  taxCode: string;
}

export interface PoProduct {
  sku: string | number;
  MOQ?: number;
  description?: string;
  name: string;
  packSize?: number;
  productVariantId?: number;
  dtProductName?: string;
}

export interface POLineData {
  id: string;
  divisionName: string;
  creditLimit?: number;
  proratedLinePrice: string;
  proratedLinePriceWithTax: string;
  status: string;
  orderLineId: number;
  productVariantId: number;
  linePrice: string;
  linePriceWithTax: string;
  discountedLinePrice: string;
  discountedLinePriceWithTax: string;
  unitPrice: number;
  CFA: string;
  quantity: number;
  divisionId: string;
  orderId: number;
  product?: PoProduct;
  promotionId: number;
  headDivisionId?: string;
  headDivisionName?: string;
  refOrderNumber: string;
  CFAId: string;
  modeOfOrder: string;
  schemeType: string;
  discountedUnitPrice: number;
  uploadedQty?: number;
  taxCategory?: string;
  errorMessage?: string;
  poFileId?: string;
}

export interface PoFile {
  id: string;
  poNumber: null;
  importSource: string;
  number: string;
  uploadedDate: string;
  skuCount: number;
  totalItemsCount: number;
  subTotal: string;
  subTotalWithTax: string;
  customerName?: string;
  taxAmount: string;
  discount: string;
  total: string;
  totalWithTax: number;
  proratedPrice: string;
  proratedPriceWithTax: number;
  status: string;
  lines?: POLineData[];
  orderState?: string;
  createdBy?: string;
  customerId: string;
  isMapped?: boolean;
  freshdeskTicketId?: number | string | null;
  mappedLineCount?: number | string;
}

export interface CustomFields {
  perItemPrice: null;
  skuType: null;
}

export interface PoOrderDetailsResponse {
  divisions: Division[];
  lines: POLine[];
  allStatus: AllStatus;
  pofileId: string;
  status: string;
  orderState: string;
  refOrderNumber: string;
  customerId: string;
  skuCount: string;
  uploadedDate: string;
  importSource: string;
  poNumber: string;
  totalQuantity: number;
  waMessage: WaMessage;
  total: string;
  totalWithTax: string;
  tax: string;
  subTotal: string;
  subTotalWithTax: string;
  discount: string;
  userType: string;
  userName: string;
  orderedBy: string;
  cfa: null;
  invoiceSummary: OrderInvoiceSummary;
  showPrice: boolean;
  notifyToBuyer: boolean;
  orderDiscount: {
    orderId: any;
    discount: number;
    discountType: any;
  };
  customerName: string;
  customerFirmName: string;
  tags: any[];
}

export interface AllStatus {
  MODIFYING: string;
  CANCELLED: string;
  CONFIRMED: string;
  BILLED: string;
  PAYMENT_AUTHORIZED: string;
  WAITING_FOR_CONFIRMATION: string;
  APPROVED_BY_CFA: string;
  SUBMITTED: string;
  SUBMITTED_BY_CFA: string;
  DISPATCHED: string;
  REJECTED_BY_CFA: string;
  DELIVERED: string;
  ACKNOWLEDGED_BY_CFA: string;
  DRAFT: string;
  IN_CART: string;
  MAPPING_FAILED: string;
  PARSING_FAILED: string;
}

export interface POLine {
  poFileLineId: string;
  errorMessage: string;
  status: string;
  createdAt: string;
  initialListPrice?: string;
  uploadedProductName: string;
  distributorProductName: string;
  uploadedQty: number;
  listPrice?: string;
  quantity?: number;
  sequence: number;
  erpOrderNumber?: string;
  refOrderNumberL: string;
  orderId: number;
  productVariantId: number;
  unitPrice: string;
  unitPriceWithTax: string;
  discountedUnitPrice: string;
  discountedUnitPriceWithTax: string;
  proratedUnitPrice: string;
  proratedUnitPriceWithTax: string;
  linePrice: string;
  linePriceWithTax: string;
  discountedLinePrice: string;
  discountedLinePriceWithTax: string;
  lineTax: string;
  cfaId: string;
  cfa: string;
  divisionId: string;
  divisionName: string;
  headDivisionId: string;
  headDivisionName: string;
  proratedLinePrice: string;
  proratedLinePriceWithTax: string;
  proratedLineTax: string;
  orderLineId: number;
  productVariant: OrderProduct;
  MOQ: number;
  packSize: number;
  unitPriceWithTaxChangeSinceAdded: number;
  totalLi: number;
  discounts: any[];
  taxLines: TaxLine[];
  promotionIds: number[];
  promotionId: number;
  promotionNames: string[];
  adjustedPrice: string;
  invoiceLineSummary: InvoiceLineSummary;
  lineDiscount?: LineDiscount;
}

export interface InvoiceLineSummary {
  totalQty: number;
  totalAmount: string;
  invoicedItem: number;
  invoiceLines: any[];
}

export interface Translation {
  name: string;
}

export interface WaMessage {
  type: string;
  data: number[];
}

export interface TrackPODetailsRequest {
  workspaceId: string;
  body: {
    pofileId: string;
    includeInvoice?: boolean;
    includePromotion?: boolean;
    includePromotionData: boolean;
    includeTax?: boolean;
    includeInCartItemsOnly?: boolean;
    filter?: {
      divisionIds?: string[];
      status?: string[];
      invoice?: string;
      scheme?: string;
    };
    searchKey?: string;
    sortBy?: string;
    sortDirection?: string;
    customerId?: string;
  };
  dispatchFilters?: boolean;
}

export interface OrderLogsRequest {
  workspaceId: string;
  orderId: number;
  action: string;
}

export interface Audit {
  date: string;
  time: string;
  updatedFields: UpdatedFields;
  timestamp: string;
  action: OrderLogsTypes;
  productName: string;
  oldProductName?: string;
  oldSku: string;
  sequence: number;
  sku: string;
  updatedBy: string;
}

interface UpdatedFields {
  quantity: number;
  oldQuantity: number;
  productDetails?: ProductDetails;
  oldProductDetails?: ProductDetails;
}

interface ProductDetails {
  name: string;
  sku: string;
}
export enum OrderLogsTypes {
  UPDATE = 'update',
  DELETE = 'delete',
  INSERT = 'insert',
  REMAPPED = 'remapped',
}

export interface OrderLogsResponse {
  orderId: number;
  orderLineId: number;
  activeQuantity: number;
  productDetails: ProductDetails;
  activeProductVariantId: number;
  sequence: number;
  Activequantity: number;
  sku: string;
  productVariantId: number;
  poFileLineId: string;
  data: OrderLogsData[];
}

export interface OrderLogsData {
  updatedFields: UpdatedFields;
  timestamp: string;
  action: string;
  updatedBy: string;
}

export interface AddItemToActiveOrderRequest {
  customerId: string;
  sellerWorkspaceId: string;
  source?: string;
  fullFillmentLocationId?: string;
  poFileId?: string;
  lines: Line[];
  campaignId?: string;
}

export interface Line {
  productVariantId: number;
  quantity: number;
  poFileLineId?: string;
  operator?: string;
}

export interface AddItemToActiveOrderResponse {
  orders: ActiveOrder[];
  status: string;
  statusCode: number;
  errors: any[];
}

export interface ActiveOrder {
  id: number;
  status: string;
  suggestedValue: string;
  suggestedValueWithTax: string;
  taxAmount: string;
  discount: string;
  fullFillmentLocationId: string;
  CFA: string;
  headDivisionName: null;
  headDivisionId: null;
  pofileId: string;
  importSource: string;
  orderLine: ActiveOrderLine[];
}

export interface ActiveOrderLine {
  id: number;
  sku: string;
  skuName: string;
  divisionId: string;
  description: string;
  unitPrice: string;
  productVariantId: number;
  quantity: number;
  linePrice: string;
  linePriceWithTax: string;
  discountedLinePrice: string;
  discountedLinePriceWithTax: string;
  proratedLinePrice: string;
  proratedLinePriceWithTax: string;
  suggestedValue: string;
  suggestedQuantity: number;
  changedValue: string;
  adjustments: any[];
}

export interface DeletePoFileRequest {
  workspaceId: string;
  poFileLineIds?: string[];
  poFileId: string;
  customerId: string;
  poFileImportSource?: string;
}

export interface DeletePoFileResponse {
  success: boolean;
  message: string;
}

export interface OrderCheckoutRequest {
  customerId: string;
  sellerWorkspaceId: string;
  poFileIds?: string[];
}

export interface CheckoutOrder {
  id: number;
  status: string;
  suggestedValue: string;
  suggestedValueWithTax: string;
  taxAmount: string;
  discount: string;
  fullFillmentLocationId: string;
  orderLine: ChekoutOrderLine[];
}
interface ChekoutOrderLine {
  productVariantId: number;
  quantity: number;
  productVariant: UpdatedProductVariant;
  cfaId: string;
  minOrderQty: number;
  divisionId: string;
  headDivisionId: string;
}

interface UpdatedProductVariant {
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  enabled: boolean;
  isDeleted: boolean;
  ZINS: boolean;
  sku: string;
  sortOrder: number;
  stockOnHand: number;
  stockAllocated: number;
  outOfStockThreshold: number;
  useGlobalOutOfStockThreshold: boolean;
  trackInventory: string;
  id: number;
  taxCategoryId: number;
  productId: number;
  productVariantPrices: ProductVariantPrice[];
  taxCategory: TaxCategory;
  divisionCFAProductVariants: DivisionCfaproductVariant[];
  translations: UpdatedTranslation[];
  price: number;
  priceWithTax: number;
  listPrice?: number;
  listPriceIncludesTax?: boolean;
  taxRateApplied?: UpdatedTaxRateApplied;
  currencyCode?: string;
}

interface ProductVariantPrice {
  createdAt: string;
  updatedAt: string;
  price: number;
  id: number;
  channelId: number;
}

interface TaxCategory {
  createdAt: string;
  updatedAt: string;
  name: string;
  code: string;
  isDefault: boolean;
  id: number;
}

interface DivisionCfaproductVariant {
  id: string;
  createdDate: string;
  updatedDate: string;
  divisionId: string;
  productVariantId: number;
  cfaId: string;
  isActive: boolean;
  minOrderQty: number;
  packSize: number;
}

interface UpdatedTranslation {
  name: string;
  shortDescription: string;
}

interface UpdatedTaxRateApplied {
  createdAt: string;
  updatedAt: string;
  name: string;
  enabled: boolean;
  value: number;
  id: number;
  categoryId: number;
  category: TaxCategory;
  zone: Zone;
  customerGroup: any;
}

interface Zone {
  createdAt: string;
  updatedAt: string;
  name: string;
  id: number;
}

export interface EditOrderResponse {
  message: string;
  orderLines: OrderLine[];
  errors: string[];
  order: OrderDetailsResponse;
}

export interface EditOrderRequest {
  workspaceId: string;
  orderId?: string;
  customerId?: string;
  poFileId?: string;
  lines?: any;
  body?: {
    orderId?: string;
    customerId: string;
    lines?: UpdatedLine[];
    poFileId?: string;
    discount?: number;
  };
}

export interface UpdatedLine {
  productVariantId?: number;
  poFileLineId?: string;
  quantity: string;
  orderLineId?: number;
  operator?: string;
}

export interface SuggestedProducts {
  companyProductCode: string;
  productName: string;
  mdmProductCode: string;
  productVariantId: number;
  pts: number;
}

export interface SuggestMappingRequest {
  workspaceId: string;
  poFileLineId: string;
  customerId: string;
}

export interface ProductMappingRequest {
  inviteId: string;
  workspaceId: string;
  POLines: POLine[];
}

export interface ProductMappingResponse {
  records: MappingRecord[];
  statusCode: number;
  message: string;
}

export interface MappingRecord {
  distributorErpSku: string;
  principalErpSku: string;
  distributorProductName: string;
  productVariantId: number;
  unitQuantity: number;
  isMapped: boolean;
  mappedBy: string;
  manualMappingSync: boolean;
  manualMappingResponse: string;
  status: string;
  packing: string;
}

export interface PoListOrderRequest {
  customerId?: string;
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  includeActiveOrders?: boolean;
  includeInvoice?: boolean;
  includeInvoiceLines?: boolean;
  includeOrderInvoices?: boolean;
  includeSummary?: boolean;
  searchKey?: string;
  orderState?: string[];
  filterParams?:
    | {
        customerIds?: string[];
        divisionIds?: string[];
      }
    | string[];
  startDate?: string;
  endDate?: string;
  importSource?: 'manual' | 'upload' | 'whatsapp';
}

export interface PoListOrderResponse {
  files: PoFile[];
  summary: PoSummary;
  status: string;
  statusCode: number;
  poFileCount: number;
  totalRecords: number;
  startRecord: number;
  endRecord: number;
}

export interface PoSummary {
  totalSKUCount: number;
  totalOrderValue: number;
  totalDiscount: number;
  totalTax: number;
  totalFreeItems?: number;
  grossOrderNumber?: number;
  grossOrderValue?: number;
  totalFreeQuantity?: number;
  totalItemsCount?: number;
  divisions?: { id: string; name: string }[];
  cfas?: { cfaName: string; fullFillmentLocationId: string }[];
  orderStates?: string;
}

export interface POFileDetailsByIdRequest {
  poFileId: string;
  workspaceId: string;
  params: {
    includePromotionData: boolean;
    sellerWorkspaceId: string;
    customerId: string;
    campaignType?: string;
  };
}

export interface POFileDetailsByIdResponse {
  id: string;
  number: any;
  uploadedDate: string;
  skuCount: number;
  totalItemsCount: number;
  importSource: string;
  freshdeskTicketId: any;
  totalWithTax: number;
  status: string;
  showPrice: boolean;
  lines: {
    id: number;
    divisionName: string;
    proratedLinePrice: number;
    proratedLinePriceWithTax: number;
    status: string;
    orderLineId: number;
    productVariantId: number;
    assetUrl: string;
    linePrice: number;
    linePriceWithTax: number;
    discountedLinePrice: number;
    discountedLinePriceWithTax: number;
    unitPrice: number;
    discountedUnitPrice: number;
    CFA: string;
    quantity: number;
    orderId: number;
    freeQuantity: number;
    product: {
      sku: string;
      productVariantId: number;
      MOQ: number;
      description: string;
      name: string;
      packSize: number;
    };
    promotionId: number;
    CFAId: string;
    divisionId: string;
    modeOfOrder: string;
    taxCategory: string;
  }[];
  freeQuantity: number;
}
