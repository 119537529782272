/* eslint-disable-next-line */
import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
export interface CustomerGroupsProps {
  customerGroups: any
}

export function CustomerGroups(props: CustomerGroupsProps) {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const renderGroups = (customerGroups: any) => {
    if (customerGroups.length) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '15px 10px',
            flexDirection: 'row',
            width: '100%',
            flex: 'wrap',
          }}
        >
          {customerGroups?.map((group: any, index: number) => {
            const colors = ['#6C46A8', '#86D1FA', '#64E6BA', '#FD6159'];
            return (
              <div
                key={index}
                style={{
                  height: 20,
                  borderRadius: 4,
                  border: `1px solid ${colors[index]}`,
                  padding: 4,
                  backgroundColor: theme.table.tag,
                  paddingTop: 4,
                  marginRight: 6,
                  marginTop: 4,
                }}
              >
                <span
                  style={{
                    color: theme.text.primary,
                    fontSize: 10,
                    fontFamily: theme.fontConfig.regular.fontFamily,
                    textAlign: 'center',
                  }}
                >
                  {group?.name}
                </span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '15px 10px',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <span
            style={{
              color: theme.text.primary,
              fontSize: 10,
              textAlign: 'center',
            }}
          >
            -
          </span>
        </div>
      );
    }
  };

  return (
   
            <span
              style={{
                fontFamily: theme.fontConfig.regular.fontFamily,
                fontSize: 14,
                fontWeight: 500,
                color: theme.text.primary,
                textAlign: 'center',
              }}
            >
              {props?.customerGroups ? renderGroups(props?.customerGroups) : ''}
            </span>
 
  );
}

export default CustomerGroups;
