// Generated by ts-to-zod
import { z } from 'zod';

export const invoiceSchema = z.object({
  status: z.string(),
  orderId: z.number(),
  invoiceId: z.string(),
  refOrderNumber: z.string(),
  invoiceDate: z.string(),
  invoiceUrl: z.string(),
  appId: z.string(),
  inviteId: z.string(),
  whatsappPhoneNumberId: z.string(),
  invoiceAmount: z.number(),
  detailsLink: z.string(),
  orderType: z.string(),
  dueDate: z.string(),
  invoiceQuantity: z.number(),
  invoiceNumber: z.string(),
  whatsappToken: z.string(),
  orderDate: z.string(),
  poFileId: z.string(),
});
