export const REGEX = {
  DIGITS: /^\d+$/,
  ALPHA_NUMERIC: /^[a-zA-Z][a-zA-Z0-9 ]*$/,
  TEXT_INPUT: /^[\w .,/&@()'-]*$/,
  MOBILE: /^[0-9]{10}$/,
  EMAIL: /^\w[\w.]*@\w[\w.]+[.][\w]+$/,
  NAME: /^[a-zA-Z. ]+$/,
  AMOUNT: /^\d+\.?\d{0,2}$/,
  DATE: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19\d{2}|2\d{3})$/,
  GSTIN: /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z][0-9][0-9A-Za-z][0-9]$/,
  PAN: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  AADHAR: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  PINCODE: /^[0-9]{6}$/,
  BANK_ACCOUNT: /^[0-9]{9,18}$/,
  IFSC: /^[A-Z0-9]{11}$/,
  CHEQUE: /^[0-9]{6}$/,
  VEHICLE: /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/,
};
