import { ThemeContext } from '../contexts/ThemeContext';
import { colors } from '../colors/colors';
import { SHARED_SVGS } from '../assets';
import { Modal } from '../modal/modal';
import _ from 'lodash';
import React from 'react';
import { globalStyles } from '../styles/global-style';

/* eslint-disable-next-line */
export interface WarningModalProps {
  open: boolean;
  onClose: () => void;
  heading?: string;
  headingStyle?: React.CSSProperties;
  content?: string | JSX.Element;
  contentStyle?: React.CSSProperties;
  cancelButton: {
    text: string;
    onPress: () => void;
  };
  primaryButton?: {
    text: string;
    onPress?: () => void;
  };
  primaryButtonColors?: string[];
}

export const WarningModal: React.FC<WarningModalProps> = ({
  open,
  onClose,
  heading = '',
  headingStyle,
  content = '',
  contentStyle,
  cancelButton,
  primaryButton,
  primaryButtonColors,
}) => {
  const { theme } = React.useContext(ThemeContext);
  const primaryButtonColours =
    primaryButtonColors ?? Object.values(theme.gradientButton);
  const gradientColor = `linear-gradient(90deg, ${primaryButtonColours[0]}, ${primaryButtonColours[1]})`;
  return (
    <Modal
      open={open}
      onClickClose={onClose}
      customModalCloseButtonStyle={{ display: 'none' }}
      customModalStyle={{
        width: '30%',
        padding: '2%',
      }}
      modalContent={
        <div
          style={{
            ...globalStyles.displayFlex,
            ...globalStyles.flexColAlCenterJcCenter,
          }}
        >
          <img
            src={SHARED_SVGS.WarningSymbol}
            style={{
              height: 100,
              width: 100,
              marginBottom: 50,
            }}
            alt=""
          />
          <div
            style={{
              ...theme.fontConfig.bold,
              fontSize: 20,
              color: theme.text.primary,
              ...headingStyle,
            }}
          >
            {heading}
          </div>
          {_.isString(content) ? (
            <div
              style={{
                ...theme.fontConfig.regular,
                fontSize: 16,
                color: colors.gray.primary,
                paddingTop: 12,
                ...contentStyle,
              }}
            >
              {content}
            </div>
          ) : (
            content
          )}
          <div
            style={{
              ...globalStyles.displayFlex,
              ...globalStyles.flexRowAlCenterJcSpaceBetn,
              ...globalStyles.flex1,
              ...globalStyles.width100,
              ...globalStyles.mT24,
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                width: primaryButton ? '48%' : '100%',
                border: `1px solid ${theme.secondary}`,
                ...globalStyles.pT12,
                ...globalStyles.pB12,
                ...globalStyles.borderRd6,
              }}
              onClick={cancelButton.onPress}
            >
              <div
                style={{
                  ...theme.fontConfig.bold,
                  fontSize: 16,
                  color: theme.secondary,
                }}
              >
                {cancelButton.text}
              </div>
            </div>
            {primaryButton && <div
              style={{
                width: '48%',
                ...globalStyles.pT12,
                ...globalStyles.pB12,
                ...globalStyles.borderRd6,
                cursor: 'pointer',
                background: gradientColor,
              }}
              onClick={primaryButton.onPress}
            >
              <div
                style={{
                  ...theme.fontConfig.bold,
                  fontSize: 16,
                  color: theme.primary,
                }}
              >
                {primaryButton.text}
              </div>
            </div>}
          </div>
        </div>
      }
    />
  );
};

export default WarningModal;
