import { elementInViewport } from '@zonofi/common';
import { WEBROUTES, useAppSelector } from '@zonofi/zono-money-helpers';
import {
  CatalogListData,
  CatalogueOrderSummary,
  CatalogueView,
  CategoryFilterItem,
  ConfigModule,
  LineInOrder,
  getCustomerInvite,
  useAddItemToActiveOrderMutation,
  useEditOrderMutation,
  useGetCampaignProductsMutation,
  useGetProductsMutation,
  useLazyGetCampaignDetailsV2Query,
  useLazyGetConfigDetailsQuery,
  useLazyListCartQuery,
  useLazyPriceListDetailsQuery,
} from '@zonofi/zono-money-store';
import { isNull } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const useCatalogueScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const lastItemRef = useRef<null | HTMLDivElement>(null);

  const isCatalogue = location?.pathname?.includes('catalogue');
  const isPriceList = location?.pathname?.includes('pricelist');
  const isCampaign = location?.pathname?.includes('campaign');

  const inviteData = useAppSelector(getCustomerInvite);
  const workspaceId = inviteData?.seller?.workspaceId;
  const customerId = inviteData?.inviteId;
  const { campaignId, pricelistId } = params;

  const [error, setError] = useState({
    show: false,
    code: null,
    message: null,
  });
  const [showToastError, setShowToastError] = useState({
    show: false,
    message: '',
  });
  const [listData, setListData] = useState<CatalogListData[]>([]);
  const [orderSummary, setOrderSummary] = useState<CatalogueOrderSummary>({
    poFileId: undefined,
    orderId: undefined,
    skuCount: undefined,
    totalQuantity: undefined,
  });
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [categories, setCategories] = useState<CategoryFilterItem[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryFilterItem[]
  >([]);
  const [searchKey, setSearchKey] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);

  const [getConfigAPI, getConfigAPIResponse] = useLazyGetConfigDetailsQuery();
  const [getCampaignDetails, getCampaignDetailsResponse] =
    useLazyGetCampaignDetailsV2Query();
  const [getCampaignProductsAPI, getCampaignProductsAPIResponse] =
    useGetCampaignProductsMutation();
  const [addToCartAPI, addToCartAPIResponse] =
    useAddItemToActiveOrderMutation();
  const [editOrder, editOrderResponse] = useEditOrderMutation();
  const [getCartAPI, getCartAPIResponse] = useLazyListCartQuery();
  const [listProductsAPI, listProductsAPIResponse] = useGetProductsMutation();
  const [pricelistDetailsAPI, pricelistDetailsAPIResponse] =
    useLazyPriceListDetailsQuery();

  const onScroll = () => {
    if (lastItemRef && lastItemRef?.current) {
      const reachedBottom = elementInViewport(lastItemRef?.current);
      if (
        reachedBottom &&
        !listProductsAPIResponse?.isLoading &&
        !getCampaignProductsAPIResponse?.isLoading &&
        !getCartAPIResponse?.isLoading &&
        !isPageLoading
      ) {
        console.log('reached');
        setPageNo(pageNo + 1);
        setIsPageLoading(true);
      }
    }
  };

  const getName = (name: string) => {
    let words = name.split(' ');
    return words.map((word) => word.charAt(0).toUpperCase()).join('');
  };

  const onIncrement = (lineInOrder: LineInOrder) => {
    editOrder({
      workspaceId,
      customerId,
      body: {
        customerId,
        lines: [
          {
            quantity: (lineInOrder?.quantity + 1).toString(),
            productVariantId: lineInOrder?.productVariantId,
            operator: 'add',
          },
        ],
        orderId: lineInOrder?.orderId?.toString(),
      },
    });
  };

  const onDecrement = (lineInOrder: LineInOrder) => {
    editOrder({
      workspaceId,
      customerId,
      body: {
        customerId,
        lines: [
          {
            quantity: (lineInOrder?.quantity - 1).toString(),
            productVariantId: lineInOrder?.productVariantId,
            operator: 'minus',
          },
        ],
        orderId: lineInOrder?.orderId?.toString(),
      },
    });
  };

  const onAddToCart = (item: CatalogListData) => {
    addToCartAPI({
      sellerWorkspaceId: workspaceId,
      customerId,
      source: isCampaign ? 'whatsapp' : 'manual',
      ...(campaignId ? { campaignId: campaignId } : {}),
      lines: [
        {
          productVariantId: item?.productVariantId,
          quantity: item?.minOrderQty,
          operator: 'add',
        },
      ],
    });
  };

  const onDelete = (lineInOrder: LineInOrder) => {
    editOrder({
      workspaceId: workspaceId,
      body: {
        customerId,
        lines: [
          {
            quantity: '0',
            productVariantId: lineInOrder?.productVariantId,
          },
        ],
        orderId: lineInOrder?.orderId?.toString(),
      },
    });
  };

  const onChangeQuantity = (lineInOrder: LineInOrder, newQty: number) => {
    if (newQty === lineInOrder?.quantity) {
      return;
    }

    let operator = 'add';

    if (newQty < lineInOrder?.quantity) {
      operator = 'minus';
    }

    editOrder({
      workspaceId: workspaceId,
      body: {
        customerId,
        lines: [
          {
            quantity: newQty?.toString(),
            productVariantId: lineInOrder?.productVariantId,
            ...(newQty !== 0 && { operator: operator }),
          },
        ],
        orderId: lineInOrder?.orderId?.toString(),
      },
    });
  };

  const onClickCategory = (item: CategoryFilterItem) => {
    setSelectedCategories((prevState) => {
      let temp = [...prevState];

      const index = temp.findIndex(
        (obj) => obj?.categoryId === item?.categoryId
      );
      if (index === -1) {
        temp.push(item);
      } else {
        temp.splice(index, 1);
      }
      return temp;
    });
  };

  const onSuccess = () => {
    let from = 'catalog';
    if (campaignId) from = 'campaign';
    if (pricelistId) from = 'price-list';
    let route = `${
      WEBROUTES.ORDER
    }/${orderSummary?.poFileId?.toString()}?from=${from}`;

    if (campaignId) {
      route += `&campaignType=${getCampaignDetailsResponse?.data?.data?.type}`;
    }
    navigate(route);
  };

  const btnDisabled = useMemo(() => {
    return (
      addToCartAPIResponse?.isLoading ||
      getCartAPIResponse?.isLoading ||
      getCartAPIResponse?.isFetching ||
      editOrderResponse?.isLoading
    );
  }, [addToCartAPIResponse, getCartAPIResponse, editOrderResponse]);

  const isCatalogueLoading = useMemo(() => {
    return (
      getCampaignDetailsResponse?.isFetching ||
      getCampaignDetailsResponse?.isLoading ||
      pricelistDetailsAPIResponse?.isLoading ||
      pricelistDetailsAPIResponse?.isFetching ||
      (listProductsAPIResponse?.isLoading && pageNo === 1) ||
      getConfigAPIResponse?.isFetching ||
      getConfigAPIResponse?.isLoading ||
      getCampaignProductsAPIResponse?.isLoading
    );
  }, [
    getCampaignProductsAPIResponse,
    getCampaignDetailsResponse,
    pricelistDetailsAPIResponse,
    listProductsAPIResponse,
    getConfigAPIResponse,
    pageNo,
  ]);

  const buttonText = useMemo(() => {
    if (
      campaignId &&
      getCampaignDetailsResponse?.isSuccess &&
      getCampaignDetailsResponse?.data?.data?.type !== 'ORDER'
    ) {
      return 'Select';
    }
    return 'Add';
  }, [campaignId, getCampaignDetailsResponse]);

  const showPrice = useMemo(() => {
    if (isCampaign) {
      if (getCampaignDetailsResponse?.data?.data?.type !== 'ORDER') {
        return false;
      } else {
        return true;
      }
    }
    if (isPriceList) {
      return true;
    }
    if (
      listData?.length !== 0 &&
      getConfigAPIResponse?.isSuccess &&
      getConfigAPIResponse?.data
    ) {
      if (
        getConfigAPIResponse?.data?.config?.displayCataloguePrices &&
        getConfigAPIResponse?.data?.config?.displayCataloguePrices !== 'false'
      ) {
        return true;
      }
    }
    return false;
  }, [
    isCampaign,
    isPriceList,
    listData,
    getConfigAPIResponse,
    getCampaignDetailsResponse,
  ]);

  const showImage = useMemo(() => {
    if (getConfigAPIResponse?.isSuccess && getConfigAPIResponse?.data) {
      if (
        getConfigAPIResponse?.data?.config?.catalogueView &&
        getConfigAPIResponse?.data?.config?.catalogueView !==
          CatalogueView.ListView
      ) {
        return true;
      }
    }
    return false;
  }, [getConfigAPIResponse]);

  const getProductsList = () => {
    listProductsAPI({
      workspaceId,
      params: {
        customerId,
        pageNo: pageNo,
        pageSize: 20,
      },
      body: {
        ...(searchKey?.length !== 0 ? { searchKey: searchKey } : {}),
        sortDirection: 'ASC',
        includeCategoryCodesList: true,
        ...(searchKey?.length === 0
          ? { categoryCodes: selectedCategories?.map((i) => i?.categoryCode) }
          : {}),
      },
    });
  };

  const getCampaignProducts = () => {
    if (
      getCampaignDetailsResponse?.isSuccess &&
      getCampaignDetailsResponse?.data?.data?.type
    ) {
      getCampaignProductsAPI({
        campaignId,
        customerId,
        sellerWorkspaceId: workspaceId,
        workspaceId: workspaceId,
        searchKey,
        campaignType: getCampaignDetailsResponse?.data?.data?.type,
        pageNo,
        pageSize: 20,
      });
    }
  };

  useEffect(() => {
    if (isPriceList && pricelistId) {
      // if (
      //   pageNo !== 1 &&
      //   pricelistDetailsAPIResponse?.data?.endRecord >
      //     pricelistDetailsAPIResponse?.data?.total
      // ) {
      //   return;
      // }
      pricelistDetailsAPI({
        workspaceId,
        params: {
          priceListId: pricelistId,
          sellerWorkspaceId: workspaceId,
          customerId,
        },
      });
    }
  }, [pricelistId]);

  useEffect(() => {
    if (campaignId) {
      getCampaignDetails({
        workspaceId,
        params: {
          customerId,
          sellerWorkspaceId: workspaceId,
          campaignId,
        },
      });
    }
  }, [campaignId]);

  useEffect(() => {
    if (isCatalogue) {
      if (
        listProductsAPIResponse?.isSuccess &&
        listProductsAPIResponse?.data?.endRecord >=
          listProductsAPIResponse?.data?.total
      ) {
        return;
      }
      getProductsList();
    }
  }, [isCatalogue, pageNo, selectedCategories]);

  useEffect(() => {
    if (addToCartAPIResponse?.isSuccess) {
      getCartAPI({
        workspaceId,
        body: {
          customerId,
          includeActiveOrders: true,
          includeSummary: false,
          ...(isCampaign ? { importSource: 'whatsapp' } : {}),
        },
      });
    }
  }, [addToCartAPIResponse]);

  useEffect(() => {
    if (editOrderResponse?.isSuccess) {
      getCartAPI({
        workspaceId,
        body: {
          customerId,
          includeActiveOrders: true,
          includeSummary: false,
          ...(isCampaign ? { importSource: 'whatsapp' } : {}),
        },
      });
    } else if (editOrderResponse?.isError) {
      if (
        (editOrderResponse?.error as any)?.data?.message?.message ===
        'Quantity cannot be below the Minimum Order Quantity (MOQ)'
      ) {
        setListData((prevState) => {
          return prevState?.map((product) => {
            if (
              (editOrderResponse?.error as any)?.data?.message?.body?.lines?.[0]
                ?.productVariantId === product?.productVariantId
            ) {
              return {
                ...product,
                moqError: true,
              };
            }
            return product;
          });
        });
      } else {
        setShowToastError({
          show: true,
          message: 'Error: Something went wrong!',
        });
      }
    }
  }, [editOrderResponse]);

  useEffect(() => {
    if (getCampaignDetailsResponse?.isSuccess) {
      getCampaignProducts();
      // getCartAPI({
      //   workspaceId,
      //   body: {
      //     customerId,
      //     includeActiveOrders: true,
      //     includeSummary: false,
      //   },
      // });
      // setListData(
      //   getCampaignDetailsResponse?.data?.data?.productVariants?.map((item) => {
      //     let pv = item?.productVariants?.[0];
      //     return {
      //       name: pv?.name,
      //       productVariantId: pv?.id,
      //       sku: pv?.sku,
      //       minOrderQty: pv?.minOrderQty,
      //       pts: pv?.price,
      //       isAddedToCart: false,
      //       addedQty: null,
      //       lineInOrder: undefined,
      //       promotions: pv?.promotions ?? [],
      //       discountedPrice: 0,
      //       productImage: pv?.assets?.[0]?.redirectUrl || '',
      //     };
      //   })
      // );
    } else if (getCampaignDetailsResponse?.isError) {
      console.log('error: ', getCampaignDetailsResponse?.error);
      if (getCampaignDetailsResponse?.error) {
        setError({
          show: true,
          code: (getCampaignDetailsResponse?.error as any)?.status ?? 500,
          message:
            (getCampaignDetailsResponse?.error as any)?.data?.message
              ?.message ?? 'Something went wrong!',
        });
      }
    }
  }, [getCampaignDetailsResponse]);

  useEffect(() => {
    if (getCampaignProductsAPIResponse?.isSuccess) {
      getCartAPI({
        workspaceId,
        body: {
          customerId,
          includeActiveOrders: true,
          includeSummary: false,
          ...(isCampaign ? { importSource: 'whatsapp' } : {}),
        },
      });
      const newData = getCampaignProductsAPIResponse?.data?.data?.products?.map(
        (item) => {
          let pv = item?.productVariants?.[0];
          return {
            name: pv?.name,
            productVariantId: pv?.id,
            sku: pv?.sku,
            minOrderQty: pv?.minOrderQty,
            pts: pv?.price,
            isAddedToCart: false,
            addedQty: null,
            lineInOrder: undefined,
            promotions: pv?.promotions ?? [],
            discountedPrice: 0,
            productImage: pv?.assets?.[0]?.redirectUrl || '',
          };
        }
      );
      setListData((prevState) => {
        return [...prevState, ...newData];
      });
    } else if (getCampaignDetailsResponse?.isError) {
      console.log('error: ', getCampaignDetailsResponse?.error);
      if (getCampaignDetailsResponse?.error) {
        setError({
          show: true,
          code: (getCampaignDetailsResponse?.error as any)?.status ?? 500,
          message:
            (getCampaignDetailsResponse?.error as any)?.data?.message
              ?.message ?? 'Something went wrong!',
        });
      }
    }
  }, [getCampaignProductsAPIResponse]);

  useEffect(() => {
    if (getCartAPIResponse?.isSuccess && !getCartAPIResponse?.isFetching) {
      const manualOrder = getCartAPIResponse?.data?.files?.filter(
        (i) => i?.importSource === 'manual'
      )?.[0];
      const whatsappOrder = getCartAPIResponse?.data?.files?.filter(
        (i) => i?.importSource === 'whatsapp'
      )?.[0];
      if (manualOrder && manualOrder?.lines?.length !== 0) {
        setOrderSummary({
          poFileId: manualOrder?.id,
          orderId: manualOrder?.lines?.[0]?.orderId,
          skuCount: manualOrder?.skuCount,
          totalQuantity: manualOrder?.totalItemsCount,
        });
        setListData((prevState) => {
          return prevState?.map((item) => {
            let isAddedToCart = false;
            let addedQty = 0;
            let lineInOrder: LineInOrder = null;
            isAddedToCart =
              manualOrder?.lines?.filter(
                (line) =>
                  item?.productVariantId === line?.product?.productVariantId
              )?.length !== 0;
            if (isAddedToCart) {
              let selectedOrder = manualOrder?.lines?.filter(
                (line) =>
                  item?.productVariantId === line?.product?.productVariantId
              )?.[0];
              addedQty = selectedOrder?.quantity;
              lineInOrder = {
                quantity: selectedOrder?.quantity,
                orderId: selectedOrder?.orderId,
                productVariantId: selectedOrder?.product?.productVariantId,
              };
            }
            return {
              ...item,
              isAddedToCart: isAddedToCart,
              addedQty: addedQty,
              lineInOrder: lineInOrder,
            };
          });
        });
      } else if (isCampaign && whatsappOrder) {
        setOrderSummary({
          poFileId: whatsappOrder?.id,
          orderId: whatsappOrder?.lines?.[0]?.orderId,
          skuCount: whatsappOrder?.skuCount,
          totalQuantity: whatsappOrder?.totalItemsCount,
        });
        setListData((prevState) => {
          return prevState?.map((item) => {
            let isAddedToCart = false;
            let addedQty = 0;
            let lineInOrder: LineInOrder = null;
            isAddedToCart =
              whatsappOrder?.lines?.filter(
                (line) =>
                  item?.productVariantId === line?.product?.productVariantId
              )?.length !== 0;
            if (isAddedToCart) {
              let selectedOrder = whatsappOrder?.lines?.filter(
                (line) =>
                  item?.productVariantId === line?.product?.productVariantId
              )?.[0];
              addedQty = selectedOrder?.quantity;
              lineInOrder = {
                quantity: selectedOrder?.quantity,
                orderId: selectedOrder?.orderId,
                productVariantId: selectedOrder?.product?.productVariantId,
              };
            }
            return {
              ...item,
              isAddedToCart: isAddedToCart,
              addedQty: addedQty,
              lineInOrder: lineInOrder,
            };
          });
        });
      } else {
        setOrderSummary({
          poFileId: manualOrder?.id,
          orderId: manualOrder?.lines?.[0]?.orderId,
          skuCount: manualOrder?.skuCount,
          totalQuantity: manualOrder?.totalItemsCount,
        });
        setListData((prevState) => {
          return prevState?.map((item) => {
            return {
              ...item,
              isAddedToCart: false,
              addedQty: 0,
              lineInOrder: null,
            };
          });
        });
      }
    }
  }, [getCartAPIResponse]);

  useEffect(() => {
    if (listProductsAPIResponse?.isSuccess) {
      const productsToAdd: CatalogListData[] =
        listProductsAPIResponse?.data?.products?.map((item) => {
          const product = item?.productVariants?.[0];
          const disc = Number(product?.promotions[0]?.discount);

          let discountedPrice;
          if (disc) {
            const orgPrice = product?.price / 100;
            const discAmt = (orgPrice / 100) * disc;
            discountedPrice = orgPrice - discAmt;
          }
          return {
            name: product?.name,
            productVariantId: product?.productVariantId,
            sku: product?.sku,
            minOrderQty: product?.minOrderQty,
            pts: product?.price,
            ptr: product?.PTR,
            margin: product?.PTR - product?.price,
            isAddedToCart: false,
            addedQty: null,
            lineInOrder: undefined,
            promotions: product?.promotions,
            discountedPrice: discountedPrice,
            productImage: product?.assets?.[0]?.redirectUrl || '',
          };
        });

      getCartAPI({
        workspaceId,
        body: {
          customerId,
          includeActiveOrders: true,
          includeSummary: false,
          ...(isCampaign ? { importSource: 'whatsapp' } : {}),
        },
      });
      if (searchKey?.length !== 0) {
        setListData((prevState) => {
          return [...productsToAdd];
        });
        return;
      }
      if (
        listProductsAPIResponse?.data?.categoryCodes?.length >
        categories?.length
      ) {
        setCategories(listProductsAPIResponse?.data?.categoryCodes);
      }
      if (selectedCategories?.length === 0) {
        setListData((prevState) => {
          return [...prevState, ...productsToAdd];
        });
      } else {
        setListData((prevState) => {
          return [...productsToAdd];
        });
      }
    } else if (listProductsAPIResponse?.isError) {
      console.log('error: ', listProductsAPIResponse?.error);
      if (listProductsAPIResponse?.error) {
        setError({
          show: true,
          code: (listProductsAPIResponse?.error as any)?.status ?? 500,
          message:
            (listProductsAPIResponse?.error as any)?.data?.message?.message ??
            'Something went wrong!',
        });
      }
    }
    setIsPageLoading(false);
  }, [listProductsAPIResponse]);

  useEffect(() => {
    if (pricelistDetailsAPIResponse?.isSuccess) {
      getCartAPI({
        workspaceId,
        body: {
          customerId,
          includeActiveOrders: true,
          includeSummary: false,
          ...(isCampaign ? { importSource: 'whatsapp' } : {}),
        },
      });
      setListData(
        pricelistDetailsAPIResponse?.data?.Products?.map((item) => {
          return {
            name: item?.name,
            productVariantId: item?.productVariantId,
            sku: item?.sku,
            minOrderQty: item?.minOrderQty,
            pts: item?.price,
            ptr: item?.PTR,
            margin: item?.PTR - item?.price,
            isAddedToCart: false,
            addedQty: null,
            lineInOrder: undefined,
            promotions: [],
            discountedPrice: 0,
            productImage: item?.assets?.[0]?.redirectUrl || '',
          };
        })
      );
    } else if (pricelistDetailsAPIResponse?.isError) {
      console.log('error: ', pricelistDetailsAPIResponse?.error);
      if (pricelistDetailsAPIResponse?.error) {
        setError({
          show: true,
          code: (pricelistDetailsAPIResponse?.error as any)?.status ?? 500,
          message:
            (pricelistDetailsAPIResponse?.error as any)?.data?.message
              ?.message ?? 'Something went wrong!',
        });
      }
    }
  }, [pricelistDetailsAPIResponse]);

  // Function to clear errors every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (listData?.some((item) => item?.moqError === true)) {
        setListData((prevItems) => {
          return prevItems?.map((i) => {
            return {
              ...i,
              moqError: false,
            };
          });
        });
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [listData]);

  useEffect(() => {
    getConfigAPI({
      workspaceId: workspaceId,
      module: ConfigModule.CATALOGUE,
      rollback: false,
    });
  }, []);

  useEffect(() => {
    if (editOrderResponse?.isLoading) {
      const pvid =
        editOrderResponse?.originalArgs?.body?.lines?.[0]?.productVariantId;
      if (pvid) {
        setListData((prevState) => {
          return prevState?.map((i) => {
            if (i?.productVariantId === pvid) {
              return {
                ...i,
                updatingQty: true,
              };
            }
            return i;
          });
        });
      }
    } else {
      setListData((prevState) => {
        return prevState?.map((i) => {
          return {
            ...i,
            updatingQty: false,
          };
        });
      });
    }
  }, [editOrderResponse]);

  useEffect(() => {
    if (searchKey?.length !== 0) {
      setSelectedCategories([]);
      setPageNo(1);
    } else {
      setListData([]);
      if (isCatalogue) {
        getProductsList();
      } else if (isCampaign) {
        getCampaignProducts();
      }
    }
  }, [searchKey]);

  useEffect(() => {
    setPageNo(1);
    if (isCatalogue) {
      getProductsList();
    } else if (isCampaign) {
      getCampaignProducts();
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (isCampaign) {
      if (
        getCampaignProductsAPIResponse?.isSuccess &&
        getCampaignProductsAPIResponse?.data?.data?.endRecord >=
          getCampaignProductsAPIResponse?.data?.data?.total
      ) {
        return;
      }
      getCampaignProducts();
    }
  }, [pageNo]);

  return {
    pageNo,
    btnDisabled,
    categories,
    selectedCategories,
    error,
    orderSummary,
    listData,
    lastItemRef,
    campaignId,
    getName,
    onSuccess,
    onScroll,
    onIncrement,
    onDecrement,
    onAddToCart,
    onDelete,
    onChangeQuantity,
    onClickCategory,
    getCampaignDetailsResponse,
    addToCartAPIResponse,
    editOrderResponse,
    getCartAPIResponse,
    listProductsAPIResponse,
    isCatalogueLoading,
    pricelistDetailsAPIResponse,
    buttonText,
    showPrice,
    showImage,
    showToastError,
    setShowToastError,
    searchKey,
    setSearchKey,
    showSearchBar,
    setShowSearchBar,
  };
};
