import React from 'react';
import LinearGradient from 'react-native-linear-gradient';

export interface ListingPageBaseLayoutProps {
  colors: (string | number)[];
  children?: React.ReactNode;
}

export const ListingPageBaseLayout: React.FC<ListingPageBaseLayoutProps> = ({
  colors,
  children,
}) => {
  return (
    <LinearGradient
      colors={colors}
      start={{ x: 0.25, y: 0 }}
      end={{ x: 1, y: 1 }}
      // @ts-ignore
      style={{
        borderRadius: 4,
        overflow: 'hidden',
        flex: 1,
        marginHorizontal: 8,
        marginBottom: 8,
        padding: 0, //check
        paddingTop: 0,
        height: '100%',
        marginTop: 8,
        minHeight: '87.5vh',
        maxWidth: '110vw',
      }}
    >
      {children}
    </LinearGradient>
  );
};

export default ListingPageBaseLayout;
