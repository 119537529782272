import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Share(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.765"
      height="12.176"
      viewBox="0 0 14.765 12.176"
    >
      <Path
        id="Path_553"
        data-name="Path 553"
        d="M1154.617,1755.859v1.847h-1.1a8.328,8.328,0,0,0-8.112,6.447,10.187,10.187,0,0,1,7.8-3.62h1.414v1.847l3.26-3.26Zm-.651-1.573a.47.47,0,0,1,.513.1l4.4,4.4a.471.471,0,0,1,0,.666l-4.4,4.4a.471.471,0,0,1-.8-.333v-2.042h-.471a9.264,9.264,0,0,0-8.072,4.711.471.471,0,0,1-.882-.236,9.268,9.268,0,0,1,9.267-9.187h.157v-2.042A.472.472,0,0,1,1153.965,1754.286Z"
        transform="translate(-1144.25 -1754.25)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </Svg>
  );
}

export default Share;
