export const ROUTES = {
  AUTHSTACK: 'AuthStack',
  HOMESTACK: 'HomeStack',
  ONBOARDINGSTACK: 'OnBoardingStack',
  PROFILESTACK: 'ProfileStack',
  VENDORSTACK: 'VendorStack',
  BANKLINKAGESTACK: 'BankAccountLinkageStack',
  SPLASH_SCREEN: 'SplashScreen',
  OTP_SCREEN: 'OtpScreen',
  LOGIN_SCREEN: 'LoginScreen',
  HOME_SCREEN: 'HomeScreen',
  VENDOR_SCREEN: 'VendorScreen',
  REWARD_SCREEN: 'RewardScreen',
  VENDOR_PAYMENT_SCREEN: 'VendorPaymentScreen',
  VENDOR_PAYMENT_DETAILS_SCREEN: 'VendorPaymentDetailsScreen',
  VENDOR_HOME_SCREEN: 'VendorHomeScreen',
  VENDOR_DETAIL_SCREEN: 'VendorDetailScreen',
  PAYMENT_SUCCESS_SCREEN: 'PaymentSuccessScreen',
  PAYMENT_LOADING_SCREEN: 'PaymentLoadingScreen',
  INVOICE_SCREEN: 'InvoiceScreen',
  ADD_BANK_DETAILS_SCREEN: 'AddBankDetailsScreen',
  OTP_LOCK_SCREEN: 'OneTimePasswordLockScreen',
  CONNECTING_ACCOUNT_SCREEN: 'ConnectingAccountScreen',
  ONBOARDING_SWIPER_SCREEN: 'OnboardingSwiperScreen',
  MOBILE_NUMBER_CHANGE_SCREEN: 'MobileNumberChangeScreen',
  CASHBACK_LEDGER_SCREEN: 'CashbackLedgerScreen',
  CHANGE_BANK_ACCOUNT_SCREEN: 'ChangeBankAccountScreen',
  USER_PROFILE_SCREEN: 'UserProfileScreen',
  ZCOIN_LEDGER_SCREEN: 'ZCoinLedgerScreen',
  UPI_OPTIONS_SCREEN: 'UpiOptionsScreen',
  DOWNLOAD_RECEIPT_SCREEN: 'DownloadReceiptScreen',
  UPI_QR_CODE_SCREEN: 'UpiQRCodeScreen',
  UPI_VERIFICATION_TIMER_SCREEN: 'UpiPaymentVerificationTimerScreen',
};

export const FIELD_COLLECTION_ROUTES = {
  AUTHSTACK: 'AuthStack',
  HOMESTACK: 'HomeStack',
  COLLECTIONSTACK: 'CollectionStack',
  DAYBOOKSTACK: 'DaybookStack',
  PAYMENTSTACK: 'PaymentStack',
  OTP_SCREEN: 'OtpScreen',
  LOGIN_SCREEN: 'LoginScreen',
  HOME_SCREEN: 'HomeScreen',
  BOTTOM_NAVIGATION_STACK: 'BottomNavigationStack',
  ROUTES_SCREEN: 'RoutesScreen',
  DAY_BOOK_SCREEN: 'DayBookScreen',
  DAYBOOK_COLLECTION_REPORT_SCREEN: 'CollectionReportScreen',
  COLLECTION_SCREEN: 'CollectionScreen',
  SETTINGS_SCREEN: 'SettingsScreen',
  PAYMENT_INPUT_SCREEN: 'PaymentInputScreen',
  INVOICE_SETTLEMENT_SCREEN: 'InvoiceSettlementScreen',
  QR_PAYMENT_SCREEN: 'QRPaymentScreen',
  PAYMENT_SUCCESS_SCREEN: 'PaymentSuccessScreen',
  MANUAL_PAYMENT_SCREEN: 'ManualPaymentScreen',
  CHECKIN_SUCCESS_SCREEN: 'CheckInSuccessScreen',
  INVOICE_DETAILS_SCREEN: 'InvoiceDetailsScreen',
  DAYBOOK_PENDING_INVOICES_REPORT_SCREEN: 'PendingInvoicesReportScreen',
};

export const WEBROUTES = {
  LOGIN_SCREEN: '/login',
  OTP_SCREEN: '/otp-verify',
  VENDOR_SCREEN: '/vendor',
  PAYMENT_SCREEN: '/payment',
  CUSTOMER_INVITE_LOADING_SCREEN: '/invite',
  INVOICE_SCREEN: '/invoice',
  PAYMENT_SUCCESS_SCREEN: '/success',
  OTP_LOCK_SCREEN: '/otp-lock',
  ADD_BANK_DETAILS_SCREEN: '/bank',
  PAYMENT_RESPONE_SCREEN: '/payment-response',
  HOME_SCREEN: '/home',
  ADD_BANK_DETAILS_RESPONSE_SCREEN: '/bank-upi-response',
  MOBILE_NUMBER_CHANGE: '/mobile-number-change',
  ERROR_SCREEN: '/error-screen',
  OFFERS: '/offers',
  DOWNLOAD_RECEIPT: '/download-receipt',
  UPI_VERIFICATION_TIMER_SCREEN: '/upi-payment-verify',
  UPI_PAYMENT_OPTIONS_SCREEN: '/payment-options',
  UPI_QR_CODE_SCREEN: '/scan-qr',
};

export const SELLER_NATIVE_APP_ROUTES = {
  AUTHSTACK: 'AuthStack',
  BOTTOMNAVIGATIONSTACK: 'BottomNavigationStack',
  HOMESTACK: 'HomeStack',
  HOMESIDEBARSTACK: 'HomeSideStack',
  CHANNELSSTACK: 'ChannelsStack',
  CUSTOMERSTACK: 'CustomerStack',
  CUSTOMERSIDEBARSTACK: 'CustomerSideStack',
  SETTINGSSTACK: 'SettingsStack',
  PAYMENTSTACK: 'PaymentStack',
  ONBOARDINGSTACK: 'OnboardingStack',
  KYCSTACK: 'KycStack',
  CHATS_STACK: 'ChatsStack',
  RECORD_PAYMENT_STACK: 'RecordPaymentStack',
  TEAM_MEMBER_STACK: 'TeamMemberStack',
  MICROAPP_STACK: 'MicroAppStack',
  LOGIN_SCREEN: 'LoginScreen',
  OTP_SCREEN: 'OtpScreen',
  PRINCIPAL_SCREEN: 'PrincipalScreen',
  FEEDS_SCREEN: 'FeedsScreen',
  ZONO_MONEY_SCREEN: 'ZonoMoneyScreen',
  MY_CHANNEL_SCREEN: 'MyChannelScreen',
  PRINCIPAL_CHANNEL_SCREEN: 'PrincipalChannelScreen',
  CUSTOMER_SCREEN: 'CustomerScreen',
  THREAD_SCREEN: 'ThreadsScreen',
  RECORD_PAYMENT_SCREEN: 'RecordPaymentScreen',
  INVOICE_LIST: 'InvoiceList',
  LEDGER_LIST: 'LedgerList',
  PAYMENT_LIST: 'PaymentList',
  PROFILE_ACCOUNT_DETAILS: 'ProfileAccountDetails',
  PAYMENT_RECEIPT_SCREEN: 'PaymentReceiptScreen',
  OUTSTANDING_SCREEN: 'OutstandingScreen',
  OVERDUE_SCREEN: 'OverdueScreen',
  ALL_CUSTOMERS_SCREEN: 'AllCustomersScreen',
  QRCODE_SCREEN: 'QrCodeScreen',
  CUSTOMER_PROFILE_SCREEN: 'CustomerProfileScreen',
  ORDERS_SCREEN: 'OrdersScreen',
  LEDGER_SCREEN: 'LedgerScreen',
  CHEQUEBOOK_SCREEN: 'ChequeBookScreen',
  MANUAL_PAYMENT_SCREEN: 'ManualPaymentScreen',
  CLAIMS_SCREEN: 'ClaimsScreen',
  HOME_SCREEN: 'HomeScreen',
  PAYMENTS_SCREEN: 'PaymentsScreen',
  CUSTOMERS_SCREEN: 'CustomersScreen',
  LOGOUT_SCREEN: 'LogoutScreen',
  PAYMENT_DETAILS_SCREEN: 'PaymentDetailsScreen',
  CREATE_CHANNEL_SCREEN: 'CreateChannel',
  ADD_CHANNEL_SCREEN: 'AddChannel',
  CHATBOX_SCREEN: 'ChatBoxScreen',
  CHANNEL_DETAILS_SCREEN: 'ChannelDetails',
  EDIT_CHANNEL_SCREEN: 'EditChannel',
  ADD_MEMBER_SCREEN: 'AddMember',
  GET_STARTED_SCREEN: 'GetStartedScreen',
  USER_CREATION_SCREEN: 'UserCreationScreen',
  FIRM_CREATION_SCREEN: 'FirmCreationScreen',
  FIRM_LOCATION_SCREEN: 'FirmLocationScreen',
  TEAM_INVITE_SCREEN: 'TeamInviteScreen',
  WORKSPACE_SUCCESS_SCREEN: 'WorkspaceSuccessScreen',
  INVITE_SUCCESS_SCREEN: 'InviteSuccessScreen',
  KYC_STEPS_SCREEN: 'KycStepsScreen',
  AADHAR_OTP_SCREEN: 'AadharOtpScreen',
  FIND_IFSC_SCREEN: 'FindIfscScreen',
  KYC_SUBMISSION_SCREEN: 'KycSubmissionScreen',
  PRINCIPAL_INVITE_SCREEN: 'PrincipalInviteScreen',
  PRINCIPAL_INVITE_ACCEPT_SCREEN: 'PrincipalInviteAcceptScreen',
  ADD_CUSTOMER_SCREEN: 'AddCustomerScreen',
  TEAM_SCREEN: 'TeamScreen',
  ADD_TEAM_MEMBER_SCREEN: 'AddTeamMemberScreen',
};

export const COMMUNICATION_WEB_APP_ROUTES = {
  BASE_PATH: '/admin/communication',
  CONVERSATION_SCREEN: '/admin/communication/channels',
  CHANNELS_DETAILS_SCREEN: '/admin/communication/channels/channelDetails',
  THREAD_DETAILS_SCREEN: '/admin/communication/threads/threadDetails',
  THREADS_SCREEN: '/admin/communication/threads',
  CREATE_CHANNEL_SCREEN: '/admin/communication/create-channel',
  CLAIM_DETAILS_SCREEN: '/admin/communication/claim/details',
  ORDER_DETAILS_SCREEN: '/admin/communication/order/details',
};
