export const hash = (text: string = '', size: number) => {
  let total = 0;
  if (text) {
    for (let i = 0; i < text.length; i++) {
      total += total + text.charCodeAt(i);
    }
    total = total % size;
    return total;
  } else {
    return 0;
  }
};
