//@ts-nocheck
import { ThemeContext } from '../contexts/ThemeContext';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import Icon from 'react-native-easy-icon';
import { Divider, TextInput } from 'react-native-paper';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { CheckListItem } from '../check-list-item/check-list-item';
import { colors } from '../colors/colors';
import { fontConfig } from '../fonts/fonts';
import { DropDownOptions } from '../types';
import { hash, titleTruncate } from '@zonofi/common';

/* eslint-disable-next-line */
export interface DropDownSearchProps {
  onSelect?: (value: string) => void;
  optionList: DropDownOptions[];
  initialValue?: DropDownOptions[];
  color?: string;
  resetSelectedItems?: () => void;
}

export const DropDownSearch: React.FC<DropDownSearchProps> = ({
  onSelect,
  optionList,
  initialValue,
  color,
  resetSelectedItems,
}) => {
  const DropdownButton = useRef(undefined);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState<DropDownOptions[]>([]);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);
  const [options, setOptions] = useState<DropDownOptions[]>(optionList);
  const [searchKey, setSearckKey] = useState('');
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  useEffect(() => {
    if (initialValue) {
      setSelectedItem(initialValue?.map((x) => x?.id));
      setValue(initialValue);
      setSearckKey('');
    }
  }, [initialValue]);

  const [dropdown, setDropdown] = useState({ top: 0, left: 0, width: 0 });

  const toggleDropdown = (): void => {
    focus ? setFocus(false) : openDropdown();
  };
  const openDropdown = (): void => {
    if (DropdownButton && DropdownButton.current) {
      DropdownButton?.current?.measure(
        (
          _fx: number,
          _fy: number,
          w: number,
          h: number,
          px: number,
          py: number
        ) => {
          setDropdown({ top: py + h, left: px, width: w });
        }
      );
    }
    setFocus(true);
  };
  const onItemPress = (id: string) => {
    if (selectedItem.includes(id)) {
      setSelectedItem(selectedItem.filter((x) => x !== id));
    } else {
      setSelectedItem([...selectedItem, id]);
    }
    if (onSelect) {
      onSelect(id);
    }
    setFocus(false);
    setSearckKey('');
  };

  useEffect(() => {
    setValue(optionList?.filter((x) => selectedItem?.includes(x?.id)));
  }, [selectedItem]);

  useEffect(() => {
    if (searchKey.length > 0) {
      let temp: DropDownOptions[];
      temp = optionList.filter((x) =>
        x.mainText.toLowerCase().includes(searchKey.toLowerCase())
      );
      temp.length === 0 ? setOptions([]) : setOptions(temp);
    } else setOptions(optionList);
  }, [searchKey]);

  const backGrounds = [
    colors.pastelRed.color,
    colors.chineseGold.color,
    colors.middleBlue.color,
    colors.acidGreen.color,
    colors.titaniumYellow.color,
    colors.munsellGreen.color,
    colors.vividOrchid.color,
    colors.blue.color,
    colors.byzontine.color,
    colors.uclaBlue.color,
  ];

  const getTag = (item: DropDownOptions) => {
    const bgColor =
      color ?? backGrounds[hash(item.mainText, backGrounds.length)];
    return (
      <View
        style={[
          { backgroundColor: bgColor, borderColor: bgColor },
          styles.tagContainer,
        ]}
      >
        <Text style={styles.tagText}>{titleTruncate(item.mainText, 12)}</Text>
        <>
          <Divider
            style={{
              width: 1,
              height: 20,
              marginLeft: 5,
              backgroundColor: '#FFFFFF',
            }}
          />
          <Icon
            type="entypo"
            name="cross"
            size={15}
            color={colors.white.color}
            onPress={() => onItemPress(item.id)}
          />
        </>
      </View>
    );
  };

  const renderDropdown = () => (
    <View
      style={[
        styles.list,
        {
          backgroundColor: theme.linearGradient.primary,
          top: dropdown.top,
          left: dropdown.left,
          maxHeight: options?.length <= 0 ? hp(10) : hp(30),
        },
      ]}
    >
      {options?.length <= 0 ? (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: '40px',
          }}
        >
          <Text style={[styles.regularText14Grey, { color: '#ccc' }]}>
            {searchKey?.length <= 0 ? 'Type to search' : 'No items found'}
          </Text>
        </View>
      ) : (
        <FlatList
          data={options}
          renderItem={({ item }) => (
            <CheckListItem
              listItem={{
                id: item.id,
                value: item.mainText,
              }}
              onPress={onItemPress}
              selectedIds={selectedItem}
            />
          )}
          scrollEnabled={true}
          nestedScrollEnabled={true}
        />
      )}
    </View>
  );

  return (
    <>
      <View
        style={[
          styles.container,
          { paddingVertical: !_.isEmpty(value) ? hp(0.5) : 0 },
        ]}
      >
        <View style={[styles.row, { width: '90%', flexWrap: 'wrap' }]}>
          {value?.map((item) => getTag(item))}
          <TextInput
            theme={{
              colors: {
                primary: colors.transparent.color,
                text: colors.white.color,
                accent: colors.transparent.color,
                background: colors.transparent.color,
              },
              fonts: {
                regular: styles.boldText16,
              },
            }}
            style={{ width: '100%' }}
            value={searchKey}
            editable={true}
            underlineColor={colors.transparent.color}
            onFocus={() => setFocus(true)}
            onChangeText={(text) => setSearckKey(text)}
          />
        </View>
        <Icon
          type="entypo"
          name="cross"
          size={15}
          color={colors.white.color}
          onPress={() => {
            setSelectedItem([]);
            resetSelectedItems && resetSelectedItems();
          }}
        />
        {focus ? (
          <Icon
            type={'antdesign'}
            name={'caretup'}
            size={15}
            color={colors.manatee.color}
            onPress={toggleDropdown}
          />
        ) : (
          <Icon
            type={'antdesign'}
            name={'caretdown'}
            size={15}
            color={colors.manatee.color}
            onPress={toggleDropdown}
          />
        )}
      </View>
      {focus && <View style={styles.overlay}>{renderDropdown()}</View>}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 10,
    paddingRight: wp(2),
    paddingLeft: wp(1),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: colors.onyx.color,
    marginVertical: hp(1),
  },
  regularText14Grey: {
    ...fontConfig.default.regular,
    fontSize: 14,
    color: colors.manatee.color,
  },
  boldText16: {
    ...fontConfig.default.semiBold,
    fontSize: 16,
    color: colors.white.color,
  },
  tagContainer: {
    borderWidth: 0.5,
    borderRadius: 2,
    paddingHorizontal: 6,
    paddingVertical: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 3,
    marginVertical: 3,
    alignItems: 'center',
  },
  tagText: {
    ...fontConfig.default.regular,
    fontSize: 14,
    color: colors.white.color,
    lineHeight: 25,
  },
  list: {
    position: 'absolute',
    backgroundColor: colors.darkGunmetal.color,
    width: '100%',
    borderColor: colors.manatee.color,
    borderRadius: 6,
    shadowColor: '#000000',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
    paddingHorizontal: wp(1),
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraValue: {
    color: colors.white.color,
    fontSize: 14,
    ...fontConfig.default.regular,
  },
});

export default DropDownSearch;
