import {
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  Text,
  Modal,
} from 'react-native';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import _ from 'lodash';
import Icon from 'react-native-easy-icon';
import { DropDownOptions } from '../types';
import { CheckListItem } from '../check-list-item/check-list-item';
import { ThemeContext } from '../contexts/ThemeContext';
import { colors } from '../colors/colors';

/* eslint-disable-next-line */
export interface PaginationDropDownProps {
  onSelect?: (value: string) => void;
  optionList: DropDownOptions[];
  initialValue?: DropDownOptions[];
  totalRecords: number;
}

export const PaginationDropDown: React.FC<PaginationDropDownProps> = ({
  onSelect,
  optionList,
  initialValue,
  totalRecords,
}) => {
  const DropdownButton = useRef(undefined) as any;
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState<DropDownOptions[]>([]);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);
  const [options, setOptions] = useState(optionList);
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      setSelectedItem(initialValue.map((x) => x?.id));
      setOptions(optionList);
    }
  }, [initialValue]);

  const [dropdown, setDropdown] = useState({ top: 0, left: 0, width: 0 });

  const toggleDropdown = (): void => {
    focus ? setFocus(false) : openDropdown();
  };

  const openDropdown = (): void => {
    if (DropdownButton && DropdownButton.current) {
      DropdownButton?.current?.measure(
        (
          _fx: number,
          _fy: number,
          w: number,
          h: number,
          px: number,
          py: number
        ) => {
          setDropdown({ top: py + h, left: px, width: w });
        }
      );
    }
    setFocus(true);
  };
  const onItemPress = (id: string) => {
    setSelectedItem([id]);
    if (onSelect) {
      onSelect(id);
    }
    setFocus(false);
  };

  useEffect(() => {
    setValue(options.filter((x) => selectedItem.includes(x.id)));
  }, [selectedItem]);

  const renderDropdown = () => (
    <View
      style={[
        styles.list,
        {
          backgroundColor: theme.dropDown.background,
          top: dropdown.top,
          left: dropdown.left,
          width: dropdown.width,
        },
      ]}
    >
      <FlatList
        data={options}
        renderItem={({ item }) => (
          <>
            <TouchableOpacity onPress={() => onItemPress(item.id)}>
              <Text
                style={[
                  styles.listItem,
                  {
                    color: theme.text.primary,
                    ...theme.fontConfig.regular,
                  },
                ]}
                nativeID="custom-header-pagination"
              >
                {item.mainText}
              </Text>
            </TouchableOpacity>
            {/* <CheckListItem
            listItem={{
              id: item.id,
              value: item.mainText,
            }}
            onPress={onItemPress}
            selectedIds={selectedItem}
            showCheckIcon={false}
            /> */}
          </>
        )}
        scrollEnabled={true}
        nestedScrollEnabled={true}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
  return (
    <>
      <TouchableOpacity
        ref={DropdownButton}
        style={[
          styles.container,
          {
            backgroundColor: focus
              ? theme.enableEdit
                ? 'rgb(33, 34, 43)'
                : '#D0DFEC'
              : '',
            borderColor: focus
              ? theme.enableEdit
                ? 'rgb(33, 34, 43)'
                : '#5281B040'
              : '',
            borderWidth: focus ? 1 : 0,
            borderRadius: focus ? 4 : 0,
          },
        ]}
        onPress={toggleDropdown}
      >
        <View
          style={{
            backgroundColor: focus
              ? theme.enableEdit
                ? 'rgb(33, 34, 43)'
                : '#D0DFEC'
              : '',
          }}
        >
          {totalRecords !== 0 ? (
            <Text
              style={{
                fontFamily: theme.fontConfig.regular.fontFamily,
                color: theme.text.primary,
                flexDirection: 'row',
                alignItems: 'center',
              }}
              nativeID="custom-header-pagination"
            >
              {!_.isUndefined(value[0]) ? value[0].mainText : ''}
            </Text>
          ) : (
            <Text
              style={{
                fontFamily: theme.fontConfig.regular.fontFamily,
                color: theme.text.primary,
              }}
              nativeID="custom-header-pagination"
            >
              {'0 / page'}
            </Text>
          )}
        </View>
        {focus ? (
          <Icon
            type={'antdesign'}
            name={'caretup'}
            size={10}
            color={colors.manatee.color}
            onPress={toggleDropdown}
          />
        ) : (
          <Icon
            type={'antdesign'}
            name={'caretdown'}
            size={10}
            color={colors.manatee.color}
            onPress={toggleDropdown}
          />
        )}
      </TouchableOpacity>
      <Modal visible={focus} animationType="none" transparent>
        <TouchableOpacity style={styles.overlay} onPress={toggleDropdown}>
          {renderDropdown()}
        </TouchableOpacity>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 5,
    minWidth: 100,
    justifyContent: 'space-between',
  },
  list: {
    position: 'absolute',
    width: '100%',
    borderColor: colors.manatee.color,
    borderRadius: 6,
    shadowColor: '#000000',
    shadowRadius: 8,
    shadowOffset: { height: 3, width: -1 },
    shadowOpacity: 0.5,
  },
  listItem: {
    paddingHorizontal: 10,
    paddingVertical: 6,
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
});
export default PaginationDropDown;
