import { View, Text, StyleSheet } from 'react-native';
import React, { useContext, useRef, useState } from 'react';
import _ from 'lodash';
import { Font } from '../types';
import { ThemeContext } from '../contexts/ThemeContext';
import { Divider } from 'react-native-paper';
import Popup from 'reactjs-popup';
import Icon from 'react-native-easy-icon';
import { colors } from '../colors/colors';
import { TogglePopUp } from '../toggle-pop-up/toggle-pop-up';
import { OrderLevelDiscount2 } from './order-level-discount-2';

type DiscountType = 'percent' | 'direct';
interface HandleDiscountPayload {
  discount: number;
  discountType: DiscountType;
}

interface OrderLevelDiscountData {
  handleApplyDiscount: (payload: HandleDiscountPayload) => void;
  enableDiscount: () => boolean;
  orderValue: number;
}
/* eslint-disable-next-line */
export interface DetailsItemProps {
  heading?: string;
  value?: string | number | JSX.Element;
  list?: JSX.Element;
  headingFont?: Font;
  valueFont?: Font;
  textColor?: string;
  headingFontSize?: number;
  valueFontSize?: number;
  padding?: boolean;
  headingColor?: string;
  underline?: boolean;
  horizontalLine?: boolean;
  valueTextId?: string;
  fullAmount?: string;
  marginVertical?: number;
  orderLevelDiscountData?: OrderLevelDiscountData;
}

export const DetailsItem: React.FC<DetailsItemProps> = ({
  heading,
  value,
  list,
  headingFont,
  valueFont,
  textColor,
  headingFontSize,
  valueFontSize,
  padding,
  headingColor,
  underline = false,
  horizontalLine = false,
  marginVertical,
  valueTextId,
  fullAmount,
  orderLevelDiscountData,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const [discountPopupOpen, setDiscountPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('click is happening', anchorEl);
    setAnchorEl(event.currentTarget);
    setDiscountPopupOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDiscountPopupOpen(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // const isUserAllowedToEditDiscount = useGetIsUserPermitted([
  //   'order_write',
  //   'sales_order_write',
  // ]);
  return (
    <>
      <View
        style={[
          styles.container,
          {
            marginVertical: marginVertical ?? (padding ? 8 : 16),
            flex: 1,
          },
        ]}
      >
        {heading && (
          <Text
            style={[
              headingFont ?? theme.fontConfig.bold,
              {
                color: headingColor ?? theme.text.primary,
                paddingBottom: padding ? 0 : 4,
                fontSize: headingFontSize ?? 12,
              },
              _.isUndefined(headingColor) && { opacity: 0.4 },
            ]}
          >
            {heading}
          </Text>
        )}
        {!_.isNull(value) || !_.isUndefined(value) ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={[
                valueFont ?? theme.fontConfig.regular,
                {
                  color: textColor ?? theme.text.primary,
                  fontSize: valueFontSize ?? 16,
                  textDecorationLine: underline ? 'underline' : 'none',
                },
              ]}
              nativeID={valueTextId}
            >
              {value}
            </Text>
            {heading?.includes('Scheme') &&
              orderLevelDiscountData?.enableDiscount() &&
              true && (
                <button
                  style={{
                    color: '#0067FF',
                    ...theme.fontConfig.semiBold,

                    fontSize: 10,
                  }}
                  onClick={(e) => {
                    handleClick(e);
                    setDiscountPopupOpen(true);
                  }}
                >
                  + Add discount
                </button>
              )}
            {discountPopupOpen && (
              <OrderLevelDiscount2
                openDiscountPopup={discountPopupOpen}
                handleClose={handleClose}
                handleApplyDiscount={(payload) => {
                  setDiscountPopupOpen(false);
                  orderLevelDiscountData?.handleApplyDiscount(payload);
                }}
                anchorEl={anchorEl}
                id={id}
                orderValue={orderLevelDiscountData?.orderValue}
              />
            )}
            <TogglePopUp heading={heading} value={fullAmount} />
          </View>
        ) : null}
        {list && <View>{list}</View>}
      </View>
      {horizontalLine && (
        <Divider
          style={{
            backgroundColor: '#DFE0E0',
            height: 48,
            width: 1,
            marginHorizontal: 4,
            marginLeft: '34%',
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    marginHorizontal: 10,
  },
});
export default DetailsItem;
