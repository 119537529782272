import {
  CustomersEntity,
  getCheckOutType,
  getSelectedCustomerInfo,
  store,
  useLazyGetBankAccountsQuery,
  useLazyGetPaymentQuery,
  useLazyGetWorkSpaceDetailsByIdQuery,
} from '@zonofi/zono-money-store';

import { areNil } from '../assits/functions';
import { CheckOutType, PaymentMode } from '../types/enum';
import { useAppSelector } from './useReduxHooks';

interface TransactionConfig {
  paymentMode: PaymentMode;
  cardNetwork?: string;
  cardNumber?: string;
  transactionId?: string;
  paymentId?: string;
  netbankName?: string;
  netbankCode?: string;
  upi?: string;
  walletName?: string;
  customerName?: string;
  paymentDate?: Date;
}

export const useGetTransactionDetails = (navigate: (param: string) => void) => {
  const customerInfo = getSelectedCustomerInfo(store.getState());
  const [fetchPaymentDetails, paymentDetailsResponse] =
    useLazyGetPaymentQuery();
  const [fetchSellerData, sellerDataResponse] =
    useLazyGetWorkSpaceDetailsByIdQuery();
  const [fetchSellerBankAccount, sellerBankAccountResponse] =
    useLazyGetBankAccountsQuery();
  const checkoutType = useAppSelector(getCheckOutType);

  const getSellerDataAPI = (customerInfo: CustomersEntity) => {
    if (!areNil([customerInfo, customerInfo?.sellerWorkspaceId])) {
      fetchSellerData(customerInfo?.sellerWorkspaceId);
      fetchSellerBankAccount({
        id: customerInfo?.sellerWorkspaceId,
        isUsedForCashBack: false,
      });
    }
  };

  const getTransactionDetailsAPI = (id: string) => {
    fetchPaymentDetails(id);
  };

  const getPaymentDetails = () => {
    let config: TransactionConfig = {
      paymentMode: PaymentMode.none,
    };
    if (
      !areNil([
        paymentDetailsResponse,
        paymentDetailsResponse.data,
        paymentDetailsResponse.data?.paymentMode,
      ])
    ) {
      const paymentMode = paymentDetailsResponse?.data?.paymentMode
        ? paymentDetailsResponse?.data?.paymentMode
        : PaymentMode.none;
      const transactionID =
        paymentDetailsResponse?.data?.paymentTransaction?.bankReference;
      const paymentID =
        paymentDetailsResponse?.data?.paymentTransaction?.paymentRefCode;
      const paymentDate =
        paymentDetailsResponse?.data?.paymentTransaction?.paymentDate;

      switch (paymentMode?.toLocaleLowerCase()) {
        case PaymentMode.credit_card: {
          const cardDetails =
            paymentDetailsResponse?.data?.paymentResponse.data?.payment
              ?.payment_method.card;
          config = {
            cardNetwork: cardDetails?.card_network,
            cardNumber: cardDetails?.card_number,
            paymentMode: PaymentMode.credit_card,
            paymentId: paymentID,
            transactionId: transactionID,
            paymentDate: paymentDate,
          };
          return config;
        }

        case PaymentMode.net_banking: {
          const netbankDetails =
            paymentDetailsResponse?.data?.paymentResponse.data?.payment
              ?.payment_method.netbanking;
          config = {
            paymentMode: PaymentMode.net_banking,
            netbankCode: netbankDetails?.netbanking_bank_code,
            netbankName: netbankDetails?.netbanking_bank_name,
            paymentDate: paymentDate,
          };
          return config;
        }

        case PaymentMode.upi: {
          const upiDetails =
            paymentDetailsResponse?.data?.paymentResponse.data?.payment
              ?.payment_method.upi;
          config = {
            upi:
              upiDetails?.upi_id ||
              paymentDetailsResponse?.data?.paymentResponse?.remitterVpa,
            paymentMode: PaymentMode.upi,
            transactionId: transactionID,
            paymentId: paymentID,
            paymentDate: paymentDate,
          };
          return config;
        }

        case PaymentMode.wallet:
          config = {
            walletName: 'walletDetails?.wallet_name',
            paymentMode: PaymentMode.wallet,
          };
          return config;
        default:
          config = {
            customerName: customerInfo?.companyName ?? '',
            paymentMode: PaymentMode.none,
            paymentId: paymentID,
            transactionId: transactionID,
          };
          return config;
      }
    } else {
      return config;
    }
  };

  const getSellerBankAccount = () => {
    if (
      !areNil([
        sellerBankAccountResponse,
        sellerBankAccountResponse?.data,
        sellerBankAccountResponse?.data?.bankAccounts,
        sellerBankAccountResponse?.data?.bankAccounts[0],
        sellerDataResponse.data,
      ])
    ) {
      return {
        sellerName:
          sellerDataResponse?.data?.businessDetails?.companyName ||
          sellerDataResponse?.data?.spaceName,
        bankAccountNumber:
          sellerBankAccountResponse?.data?.bankAccounts[0].accountNumber ??
          undefined,
        bankMICR:
          sellerBankAccountResponse?.data?.bankAccounts[0].micr ?? undefined,
      };
    } else {
      return {
        sellerName: undefined,
        bankAccountNumber: undefined,
        bankMICR: undefined,
      };
    }
  };

  return {
    isPaymentLoading:
      (checkoutType === CheckOutType.ONLINE &&
        paymentDetailsResponse.isFetching) ||
      checkoutType === CheckOutType.ONLINE,
    isSellerLoading: sellerDataResponse.isFetching,
    getSellerDataAPI,
    getTransactionDetailsAPI,
    getPaymentDetails,
    getSellerBankAccount,
  };
};
