export interface Theme {
  primary: string;
  secondary: string;
  teritiary: string;
  linearGradient: {
    primary: string;
    secondary: string;
  };
  background?: {
    primary: string;
    secondary: string;
  };
  text: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  dropDown: {
    background: string;
    primary: string;
    arrow: string;
    borderColor: string;
  };
  principalCard: {
    background: string;
    default: string;
    success: string;
    danger: string;
    heading: string;
    divider: string;
  };
  sideBar: {
    background: string;
    foreground: string;
    background2: string;
    right: {
      iconColor: string;
      hoverIconColor: string;
    };
  };
  feed: {
    background: string;
    heading: string;
    highlight: string;
    icon: string;
    itemBackground: string;
    iconBackground: string;
  };
  graphs: {
    background: string;
    header: string;
    border: string;
    stroke: string;
    legend: string;
    footer: string;
  };
  highlight: {
    primary: string;
    secondary: string;
    teritiary: string;
    divider: {
      primary: string;
    };
    underline: string;
    text: string;
  };
  datepicker: {
    background: string;
    text: string;
  };
  principalHeader: {
    background: string;
    header: string;
    foreground: string;
    border: string;
  };
  table: {
    firstRow: string;
    secondRow: string;
    linearGradient: {
      primary: string;
      secondary: string;
    };
    card: string;
    tag: string;
    highlight: string;
    foreground: {
      primary: string;
      secondary: string;
    };
    underline: string;
  };
  header: {
    primary: string;
    secondary: string;
  };
  status: {
    success: string;
    danger: string;
    default: string;
    neutral: string;
    accent: string;
    background: {
      success: string;
      danger: string;
      default: string;
      neutral: string;
      accent: string;
    };
  };
  drawer: {
    background: string;
    header: string;
    border: string;
    foreground: string;
    expandedId: {
      primary: string;
      secondary: string;
    };
    tab: string;
    selectedTab: string;
  };
  tag: string;
  calendar: {
    background: string;
    border: string;
  };
  filter: {
    background: string;
    text: string;
    foreground: string;
    highlight: string;
    input: string;
    border: string;
    header: string;
    divider: string;
    tick: string;
  };
  columnFilter: {
    background: string;
    text: string;
    border: string;
    checkBoxUnchecked: string;
  };
  principalSelection: {
    header: string;
    border: string;
    background: string;
    divider: string;
    footer: string;
    foreground: string;
    tag: string;
    text: string;
  };
  communication: {
    background: string;
    foreground: string;
    border: string;
    primaryButton: { text: string; background: string };
    sideListBackground: string;
    underline: string;
    accordionIcon: string;
    containerBg: string;
    myChatBubbleBg: string;
    userChatBubbleBg: string;
    sendIcon: string;
  };
  teamList: {
    icon: string;
    button: { primary: string; secondary: string; background: string };
    text: string;
    backGround: { primary: string; secondary: string; bg03: string };
  };
  listItems: {
    listItemColor1: string;
    listItemColor2: string;
    listItemColor3: string;
    listItemColor4: string;
    listItemColor5: string;
    listItemColor6: string;
    listItemColor7: string;
    listItemColor8: string;
    listItemColor9: string;
    listItemColor10: string;
    listItemColor11: string;
  };
  pieChartColorVarients?: {
    colorVarient1: string;
    colorVarient2: string;
    colorVarient3: string;
    colorVarient4: string;
    colorVarient5: string;
    colorVarient6: string;
    colorVarient7: string;
    colorVarient8: string;
    colorVarient9: string;
    colorVarient10: string;
  }
  pieChartDrillDownColorVarients?: {
    colorVarient1: string;
    colorVarient2: string;
    colorVarient3: string;
    colorVarient4: string;
    colorVarient5: string;
    colorVarient6: string;
    colorVarient7: string;
    colorVarient8: string;
    colorVarient9: string;
    colorVarient10: string;
  }
  addToCart: {
    input: string;
    backGround: string;
  };
  gradientButton: object;
  button: {
    primary: string;
    hover: string;
  };
  gradientFilter: object;
  menu: {
    background: string;
  };
  enableEdit: boolean;
  opacity: {
    primary: string;
  };
  opacity1: {
    primary: string;
  };
  dateRangePicker: {
    backdrop: string;
    background: string;
    heading: string;
  };
  details: { header: string; subheader: string };
  horizontalLine: {
    primary: string;
  };
  active: {
    primary: string;
  };
  input: {
    border: string;
    placeHolder: string;
    label: string;
  };
  closeIcon: string;
  orderTypeTags: {
    reorder: TagStyle;
    upload: TagStyle;
    email: TagStyle;
    manual: TagStyle;
    dterp: TagStyle;
  };
  returnTooltipTags: {
    accepted: TooltipStyle;
    rejected: TooltipStyle;
    templateNotFound: TooltipStyle;
    ticketCreated: TooltipStyle;
  };
  orderStatusTags: {
    submitted: TagStyle;
    waiting: TagStyle;
    billed: TagStyle;
    cancelled: TagStyle;
    inCart: TagStyle;
    draft: TagStyle;
  };
  returnStatusTags: {
    draft: TagStyle;
    submitted: TagStyle;
    waiting: TagStyle;
    transit: TagStyle;
    issued: TagStyle;
    rejected: TagStyle;
  };
  searchKeyTags: TagStyle;
  disabledButton: {
    background: string;
    text: string;
  };
  accordian: {
    openTitleBackground: string;
    closeTitleBackground: string;
  };
  filterButton: {
    selected: {
      backgroundColor: string;
      borderColor: string;
    };
    unselected: {
      backgroundColor: string;
      borderColor: string;
    };
  };
  orderClaims: {
    header: {
      background: string;
    };
    card: {
      background: string;
      border: string;
    };
  };
  cartButton: CartButton;
  headerCartButton: CartButton;
  selectedTab: {
    color: string;
  };
  blurBackground: {
    color: string;
  };
  fontConfig: {
    bold: Font;
    semiBold: Font;
    regular: Font;
    medium: Font;
    light: Font;
    italic: Font;
    boldItalic: Font;
    semiBoldItalic: Font;
    condensedRegular: Font;
    condensedSemiBold: Font;
    firaSansRegular: Font;
    thin?: Font;
    ultraThin?: Font;
    semiBoldRegFW?: Font;
  };
}

export interface AppTheme {
  dark: Theme;
  light: Theme;
  zonoDigital: {
    light: Theme;
  };
}
export enum CurrentTheme {
  light = 'light',
  dark = 'dark',
  zonoDigital = 'zonoDigital',
}

interface CartButton {
  background: string;
  iconColor: string;
  bubbleColor: string;
  textColor: string;
}

export interface TagStyle {
  backgroundColor: string;
  borderColor: string;
  text: string;
  borderRadius: number;
}

export interface TooltipStyle {
  color?: string;
  text?: string;
}
export interface Font {
  fontFamily: string;
  fontWeight?:
    | 'normal'
    | 'bold'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900';
}
