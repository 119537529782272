//@ts-nocheck
//TO-DO
import axios from 'axios';
import * as FileSaver from 'file-saver';
import { Buffer } from 'buffer';

export const documentDownloaderWeb = () => {
  return {
    downloadJSON,
    downloadExcel,
    downloadFile,
    downloadPdf,
    downloadText,
  };
};

export const downloadJSON = (data: any, fileName: string) => {
  // eslint-disable-next-line no-undef
  const fileToSave = new Blob([JSON.stringify(data)], {
    type: 'application/json',
    name: fileName,
  });

  // Save the file
  FileSaver.saveAs(fileToSave, fileName);
};

export const downloadExcel = (data: BlobPart, fileName: any) => {
  // Create a blob of the data
  // eslint-disable-next-line no-undef
  const fileToSave = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: fileName,
  });
  // Save the file
  FileSaver.saveAs(fileToSave, fileName);
};

export const downloadPdf = (data: BlobPart, fileName: any) => {
  // Create a blob of the data
  // eslint-disable-next-line no-undef
  const fileToSave = new Blob([data], {
    type: 'application/pdf',
    name: fileName,
  });
  // Save the file
  FileSaver.saveAs(fileToSave, fileName);
};

export const downloadText = (data: BlobPart, fileName: any) => {
  // Create a blob of the data
  // eslint-disable-next-line no-undef
  const fileToSave = new Blob([data], {
    type: 'text/plain',
    name: fileName,
  });

  // Save the file
  FileSaver.saveAs(fileToSave, fileName);
};

export const getBufferFromFile = async (linkSource: string) => {
  let blobData: Buffer;
  if (linkSource.startsWith('data:')) {
    const checkText = 'base64,';
    const index = linkSource.indexOf(checkText);
    const data = linkSource.slice(index + checkText.length);
    blobData = Buffer.from(data);
  } else {
    const response = await axios.get(linkSource, {
      method: 'GET',
      responseType: 'blob',
    });
    blobData = response.data;
  }
  return blobData;
};

export const downloadFile = async (linkSource: string, fileName: string) => {
  const blobData = getBufferFromFile(linkSource);
  const url = window.URL.createObjectURL(
    new Blob([blobData], { type: 'application/octet-stream' })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const openFileInNewTab = (url: string, filename: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.download = filename;
  link.click();
};
