import React, { useContext } from 'react';
import {
  Modal as NativeModal,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import Icon from 'react-native-easy-icon';
import { ThemeContext } from '../contexts/ThemeContext';

import MuiModal from '@mui/material/Modal';
import { colors } from '../colors/colors';
import { SxProps, Theme } from '@mui/material';
import { SHARED_SVGS } from '../assets';

export enum ModalType {
  NATIVE = 'Native',
  MUI = 'Mui',
}

export interface ModalProps {
  open: boolean;
  onClickClose: () => void;
  modalContent?: JSX.Element;
  customModalStyle?: StyleProp<ViewStyle>;
  customModalCloseButtonStyle?: StyleProp<TextStyle>;
  type?: ModalType;
  customModalTypeStyles?: SxProps<Theme>;
}

export const Modal: React.FC<ModalProps> = ({
  open,
  onClickClose,
  modalContent,
  customModalStyle,
  customModalCloseButtonStyle,
  type = ModalType.NATIVE,
  customModalTypeStyles,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  return type === ModalType.NATIVE ? (
    <NativeModal
      transparent={true}
      visible={open}
      onRequestClose={onClickClose}
    >
      <View style={styles.modalContainer}>
        <View
          style={[
            styles.modalMain,
            customModalStyle,
            { backgroundColor: theme.dropDown.background },
          ]}
        >
          {modalContent}
          <View
            style={{ ...styles.closeButtonContainer, display: 'flex', gap: 12 }}
          >
            <SHARED_SVGS.CloseCross
              height={22}
              color={colors.gray.primary}
              onClick={onClickClose}
              style={{ cursor: 'pointer' }}
            />
          </View>
        </View>
      </View>
    </NativeModal>
  ) : (
    <MuiModal
      sx={{
        width: 548,
        height: 495,
        top: '20%',
        left: '35%',
        ...customModalTypeStyles,
      }}
      open={open}
      onClose={onClickClose}
    >
      <View
        style={[
          {
            backgroundColor: colors.zonoWhite.color,
            borderRadius: 10,
            paddingVertical: 30,
            paddingHorizontal: 40,
          },
          customModalStyle,
        ]}
      >
        <Icon
          onPress={onClickClose}
          type="antdesign"
          name="closecircleo"
          size={25}
          style={{
            color: '#9295A5',
            position: 'absolute',
            right: 13,
            top: 13,
          }}
        />
        <View> {modalContent}</View>
      </View>
    </MuiModal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  modalMain: {
    minWidth: '10%',
    borderRadius: 10,
    padding: '1%',
    position: 'relative',
  },
  closeButtonContainer: {
    position: 'absolute',
    cursor: 'pointer',
    right: 15,
    top: 15,
  },
});
