import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

function Print(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
    >
      <G
        id="Print_Icon"
        data-name="Print Icon"
        transform="translate(-1180 -22)"
      >
        <Rect
          id="Rectangle_24307"
          data-name="Rectangle 24307"
          width="15"
          height="14"
          transform="translate(1180 22)"
          fill="#fff"
          opacity="0"
        />
        <Path
          id="Path_1007"
          data-name="Path 1007"
          d="M273.273,894.909a.591.591,0,0,1,.591-.591h.591a.591.591,0,1,1,0,1.182h-.591A.591.591,0,0,1,273.273,894.909Zm.591,2.955h-4.727v2.364a.591.591,0,0,0,.591.591h3.545a.591.591,0,0,0,.591-.591Zm1.182,1.182h1.182a.591.591,0,0,0,.591-.591v-4.727a.591.591,0,0,0-.591-.591h-9.455a.591.591,0,0,0-.591.591v4.727a.591.591,0,0,0,.591.591h1.182v-1.182a.591.591,0,0,1,0-1.182h7.091a.591.591,0,0,1,0,1.182Zm0-7.091v-1.182A1.773,1.773,0,0,0,273.273,889h-3.545a1.773,1.773,0,0,0-1.773,1.773v1.182h-1.182A1.773,1.773,0,0,0,265,893.727v4.727a1.773,1.773,0,0,0,1.773,1.773h1.182A1.773,1.773,0,0,0,269.727,902h3.545a1.773,1.773,0,0,0,1.773-1.773h1.182A1.773,1.773,0,0,0,278,898.455v-4.727a1.773,1.773,0,0,0-1.773-1.773Zm-5.318-1.773a.591.591,0,0,0-.591.591v1.182h4.727v-1.182a.591.591,0,0,0-.591-.591Z"
          transform="translate(916 -866.498)"
          fill="#fff"
          fill-rule="evenodd"
        />
      </G>
    </Svg>
  );
}

export default Print;
