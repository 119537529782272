import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function FileActive({ color = '#a1a3b4', width = 17, height = 17 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.109 14.332"
    >
      <Path
        data-name="Path 198194"
        d="M603.185 517.351a.8.8 0 01.8-.8h4.777a.8.8 0 110 1.592h-4.777a.8.8 0 01-.8-.792zm14.332-5.574h-15.925v7.166a.8.8 0 00.8.8h14.332a.8.8 0 00.8-.8zm-15.128-3.185a.8.8 0 00-.8.8v.8h15.924v-.8a.8.8 0 00-.8-.8zm-2.389.8a2.389 2.389 0 012.389-2.392h14.332a2.389 2.389 0 012.389 2.389v9.555a2.389 2.389 0 01-2.389 2.389h-14.332a2.389 2.389 0 01-2.389-2.39z"
        transform="translate(-600 -507)"
        fill="#127eb6"
      />
    </Svg>
  );
}

export default FileActive;
