import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function UPIIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.452"
      height="14.047"
      viewBox="0 0 15.452 14.047"
    >
      <Path
        id="Path_210747"
        data-name="Path 210747"
        d="M2331.107,387a2.106,2.106,0,0,0-2.107,2.107v9.833a2.106,2.106,0,0,0,2.107,2.107h9.833a2.106,2.106,0,0,0,2.107-2.107v-.822a2.1,2.1,0,0,0,1.4-1.987v-1.4a2.1,2.1,0,0,0-1.4-1.987v-.822a2.106,2.106,0,0,0-2.107-2.107v-.7a2.106,2.106,0,0,0-2.108-2.107Zm0,1.4a.7.7,0,1,0,0,1.4h8.428v-.7a.7.7,0,0,0-.7-.7Zm-.7,2.689v7.846a.7.7,0,0,0,.7.7h9.833a.7.7,0,0,0,.7-.7v-.7h-2.107a2.105,2.105,0,0,1-2.107-2.107v-1.4a2.105,2.105,0,0,1,2.107-2.107h2.107v-.7a.7.7,0,0,0-.7-.7h-9.833A2.091,2.091,0,0,1,2330.4,391.094Zm9.131,2.93a.7.7,0,0,0-.7.7v1.4a.7.7,0,0,0,.7.7h2.81a.7.7,0,0,0,.7-.7v-1.4a.7.7,0,0,0-.7-.7Z"
        transform="translate(-2329 -387)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </Svg>
  );
}

export default UPIIcon;
