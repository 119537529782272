import React, { useState } from 'react';
import UserDetails from '../user-details/user-details';
import OrderSummary from './order-summary';
import { getFormattedAmount } from '@zonofi/common';
import OrderDetails from './order-details';
import { OrderProductsList } from './order-products-list';
import OrderHistory from './order-history';
import './order-details.scss';
import { OrderData } from '@zonofi/zono-money-helpers';

interface OrderDetailsViewProps {
  orderData: OrderData;
}

export const OrderDetailsView: React.FC<OrderDetailsViewProps> = ({
  orderData,
}) => {
  const [selectedTab, setSelectedTab] = useState('products');

  const tabs: Record<string, JSX.Element> = {
    products: (
      <OrderProductsList
        poLines={orderData?.lines || []}
        showPrice={orderData?.showPrice}
      />
    ),
    details: <OrderDetails orderData={orderData} />,
    history: <OrderHistory orderId={orderData?.lines[0]?.orderId || 0} />,
  };

  return (
    <div className="container" style={{ height: '100%' }}>
      <UserDetails variant="order" />
      <OrderSummary
        skuCount={orderData?.skuCount || ''}
        schemeSavings={getFormattedAmount(Number(orderData?.discount))}
        netOrderValue={getFormattedAmount(Number(orderData?.totalWithTax))}
        tabs={Object.keys(tabs)}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        status={orderData?.status || ''}
      />
      {tabs[selectedTab]}
    </div>
  );
};
