import { Skeleton } from '@mui/material';
import React from 'react';

export const ChatSkeleton: React.FC<any> = () => {
  return (
    <div style={{ margin: 20 }}>
      {Array.from(Array(6).keys()).map((row, index) => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'stretch',
              }}
            >
              <div style={{ marginRight: 10 }}>
                <Skeleton
                  variant="circular"
                  animation="wave"
                  width={30}
                  height={30}
                />
              </div>
              <div
                style={{
                  padding: 10,
                  border: '1px solid #d5dae3',
                  borderRadius: 8,
                  background: '#ffffff',
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{ fontSize: '1rem' }}
                  width={400}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{ fontSize: '1rem' }}
                  width={150}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{ fontSize: '1rem' }}
                  width={200}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  padding: 10,
                  border: '1px solid #d5dae3',
                  borderRadius: 8,
                  background: '#edf3fd',
                }}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{ fontSize: '1rem' }}
                  width={200}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{ fontSize: '1rem' }}
                  width={380}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{ fontSize: '1rem' }}
                  width={150}
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                <Skeleton
                  variant="circular"
                  animation="wave"
                  width={30}
                  height={30}
                />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};
