import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function ArrowCircleRight(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 18 18"
      {...props}
    >
      <Path
        data-name="Path 90547"
        d="M7.838 0a7.9 7.9 0 017.838 7.838 7.9 7.9 0 01-7.846 7.838A7.89 7.89 0 010 7.838 7.9 7.9 0 017.838 0zm0 1.306a6.532 6.532 0 106.532 6.532 6.5 6.5 0 00-6.532-6.532zm3.942 6.54a.6.6 0 01-.215.446L8.968 10.9a.551.551 0 01-.4.154.542.542 0 01-.553-.553.567.567 0 01.169-.407l.961-.953.93-.776-1.621.061H4.442a.576.576 0 110-1.153h4.011l1.621.061-.93-.784-.96-.95a.568.568 0 01-.169-.415.56.56 0 01.953-.4l2.6 2.605a.6.6 0 01.213.456z"
        fill="#9295a5"
      />
    </Svg>
  );
}

export default ArrowCircleRight;
