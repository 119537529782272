import { colors } from './colors';
import { fonts } from './fonts';
import { ThemeConfig } from './interface';

const themeConfig: ThemeConfig = {
  light: {
    fontConfig: fonts,
    avatarColors: [
      '#C8FAF9',
      '#E8D6FE',
      '#FEE2BE',
      '#E5F9B3',
      '#CDFAE4',
      '#F9D5FE',
      '#BEEBFC',
      '#FFDAEE',
      '#FDF7BC',
      '#DDDEFF',
      '#FFE1D9',
      '#E5EFFF',
    ],
    nameColors: [
      '#555555',
      '#136A9B',
      '#0048B3',
      '#4600AA',
      '#1D8459',
      '#B27800',
      '#9B2408',
    ],
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      highlight: colors.textHighlight,
    },
    chat: {
      message: {
        background: {
          sender: colors.cerulean,
          receiver: colors.white,
          failure: colors.msgFailure,
        },
      },
      reply: {
        background: {
          sender: colors.ceruleanSecondary,
          receiver: colors.white,
        },
      },
      dateSeparator: colors.lavenderGray,
      header: colors.white,
      input: { container: colors.white, background: colors.msgInputBg },
      unreadCount: { background: colors.gray90Opacity, text: colors.white },
    },
  },
};

export const theme = themeConfig.light;
