import { zonoApi } from '../api';
import {
  CashbackLedgerResponse,
  CoinsTransactionsResponse,
  DocumentRequest,
  DocumentResponse,
  User,
  UserRequest,
  UserResponse,
  WorkSpaceDetailsRequest,
} from './profile-model';

export const profileApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserCoinsTransactions: builder.query<CoinsTransactionsResponse, void>({
      query: () => {
        return {
          url: 'rewards/history?type=coins',
          method: 'GET',
        };
      },
      providesTags: ['Profile'],
    }),
    getUser: builder.mutation<User, string>({
      query: (id) => ({
        url: `user`,
        method: 'GET',
        params: { userId: id },
      }),
    }),
    getUserCashbackLedgerTransactions: builder.query<
      CashbackLedgerResponse,
      void
    >({
      query: () => {
        return {
          url: 'rewards/history?type=cashback',
          method: 'GET',
        };
      },
      providesTags: ['Profile'],
    }),
    UpdateUserDetails: builder.mutation<UserResponse, UserRequest>({
      query: (UserRequest) => {
        return {
          url: 'users',
          body: UserRequest,
          method: 'PUT',
        };
      },
    }),
    UpdateWorkspaceDetails: builder.mutation<void, WorkSpaceDetailsRequest>({
      query: (WorkSpaceDetailsRequest) => {
        return {
          url: 'workspace/details',
          body: WorkSpaceDetailsRequest,
          method: 'PUT',
        };
      },
    }),
    getDocuments: builder.query<DocumentResponse[], DocumentRequest>({
      query(arg) {
        const { workspaceId, ...params } = arg;
        return {
          url: `workspaces/documents/${workspaceId}`,
          method: 'GET',
          params: params,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserCashbackLedgerTransactionsQuery,
  useGetUserCoinsTransactionsQuery,
  useUpdateUserDetailsMutation,
  useUpdateWorkspaceDetailsMutation,
  useGetUserMutation,
  useLazyGetDocumentsQuery,
} = profileApi;
