import { LinearGradientCard } from '../linear-gradient-card/linear-gradient-card';
import React from 'react';

/* eslint-disable-next-line */
export interface ListingPageBodyBaseLayoutProps {
  children: React.ReactNode;
}

export const ListingPageBodyBaseLayout: React.FC<
  ListingPageBodyBaseLayoutProps
> = ({ children }) => {
  return (
    <LinearGradientCard
      cardStyle={{
        flex: 1,
        borderRadius: 4,
      }}
    >
      {children}
    </LinearGradientCard>
  );
};

export default ListingPageBodyBaseLayout;
