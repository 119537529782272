import { zonoApi } from '../api';
import {
  VisitResponse,
  VisitRequestParams,
  RecordCheckInResponse,
  RecordCheckInRequest,
} from './visit-model';

export const visitApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getVisits: builder.mutation<VisitResponse[], VisitRequestParams>({
      query: (VisitRequestParams) => ({
        url: `visit`,
        method: 'GET',
        params: VisitRequestParams,
      }),
    }),
    recordVisit: builder.mutation<RecordCheckInResponse, RecordCheckInRequest>({
      query: (RecordCheckInRequest) => ({
        url: `visit`,
        method: 'POST',
        body: RecordCheckInRequest,
      }),
      invalidatesTags: ['Visits'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetVisitsMutation, useRecordVisitMutation } = visitApi;
