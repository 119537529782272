import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function ArrowCircleLeft(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 18 18"
      {...props}
    >
      <Path
        data-name="Path 90546"
        d="M7.838 15.677a7.9 7.9 0 007.838-7.838A7.9 7.9 0 007.831 0 7.89 7.89 0 000 7.838a7.9 7.9 0 007.838 7.839zm0-1.306a6.532 6.532 0 116.532-6.533 6.5 6.5 0 01-6.532 6.532zm3.942-6.54a.6.6 0 00-.215-.446L8.968 4.78a.551.551 0 00-.4-.154.542.542 0 00-.553.553.567.567 0 00.169.407l.961.953.93.776-1.622-.061H4.442a.576.576 0 100 1.153h4.011l1.621-.061-.93.784-.961.945a.568.568 0 00-.169.415.56.56 0 00.953.4l2.6-2.605a.6.6 0 00.214-.454z"
        transform="rotate(180 7.838 7.839)"
        fill="#9295a5"
      />
    </Svg>
  );
}

export default ArrowCircleLeft;
