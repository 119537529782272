import React from 'react';
import { Modal, View, StyleSheet } from 'react-native';

/* eslint-disable-next-line */
export interface BottomSheetWebProps {
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
}

export const BottomSheetWeb: React.FC<BottomSheetWebProps> = ({
  isVisible,
  onClose,
  children,
}) => {
  return (
    <Modal visible={isVisible} onDismiss={onClose} transparent>
      <View style={styles.bottomSheetContainer}>{children}</View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  bottomSheetContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'flex-end',
    flex: 1,
    backgroundColor: '#00000080',
  },
});
export default BottomSheetWeb;
