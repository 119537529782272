import moment from 'moment';

export enum ReportDateFilter {
  This_Week = 'This Week',
  Last_Week = 'Last Week',
  This_Month = 'This Month',
  Last_Month = 'Last Month',
  Last_Three_Months = 'Last 3 Months',
  Last_SIX_Months = 'Last 6 Months',
  This_Year = 'This Year',
  Last_Year = 'Last Year',
  TODAY = 'Today',
  CUSTOM_DATE = 'Custom Date',
}

export enum DateFilter {
  This_Week = 'This Week',
  Last_Week = 'Last Week',
  This_Month = 'This Month Till Date',
  Last_Month = 'Last Month',
  This_Qtr = 'This QTR Till Date',
  Last_Qtr = 'Last QTR',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  CUSTOM_DATE = 'Custom Date',
  This_Year = 'This Year Till Date',
  Last_Year = 'Last Year',
}

const getQuarterDates = (today: Date) => {
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  let thisQuarterStartMonth, lastQuarterStartMonth;
  let lastQuarterYear = year;
  if (month >= 4 && month <= 6) {
    thisQuarterStartMonth = 4;
    lastQuarterStartMonth = 1;
    lastQuarterYear = year - 1;
  } else if (month >= 7 && month <= 9) {
    thisQuarterStartMonth = 7;
    lastQuarterStartMonth = 4;
  } else if (month >= 10 && month <= 12) {
    thisQuarterStartMonth = 10;
    lastQuarterStartMonth = 7;
  } else {
    thisQuarterStartMonth = 1;
    lastQuarterStartMonth = 10;
    lastQuarterYear = year - 1;
  }
  const thisQuarterStartDate = new Date(year, thisQuarterStartMonth - 1, 1);
  const thisQuarterEndDate = today;
  const lastQuarterStartDate = new Date(
    lastQuarterYear,
    lastQuarterStartMonth - 1,
    1
  );
  const lastQuarterEndDate = new Date(year, thisQuarterStartMonth - 1, 0);

  return {
    thisQuarterDate: {
      startDate: thisQuarterStartDate,
      endDate: thisQuarterEndDate,
    },
    lastQuarterDate: {
      startDate: lastQuarterStartDate,
      endDate: lastQuarterEndDate,
    },
  };
};

export const ReportsDateDefaultOptionsArray = [
  {
    label: DateFilter.TODAY,
    dateTime: {
      startDate: new Date(moment().format('YYYY-MM-DD')),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
  },
  {
    label: DateFilter.YESTERDAY,
    dateTime: {
      startDate: new Date(moment().subtract(1, 'days').format('YYYY-MM-DD')),
      endDate: new Date(moment().subtract(1, 'days').format('YYYY-MM-DD')),
    },
  },
  {
    label: DateFilter.This_Week,
    dateTime: {
      startDate: new Date(moment().startOf('week').toDate()),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
  },
  {
    label: DateFilter.Last_Week,
    dateTime: {
      startDate: new Date(
        moment().subtract(1, 'weeks').startOf('week').add(1, 'days').toDate()
      ),
      endDate: new Date(
        moment().subtract(1, 'weeks').endOf('week').add(1, 'days').toDate()
      ),
    },
  },
  {
    label: DateFilter.This_Month,
    dateTime: {
      startDate: new Date(moment().startOf('month').toDate()),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
  },
  {
    label: DateFilter.Last_Month,
    dateTime: {
      startDate: new Date(
        moment().clone().subtract(1, 'month').startOf('month').toDate()
      ),
      endDate: new Date(
        moment().clone().subtract(1, 'month').endOf('month').toDate()
      ),
    },
  },
  {
    label: DateFilter.This_Qtr,
    dateTime: getQuarterDates(new Date()).thisQuarterDate,
  },
  {
    label: DateFilter.Last_Qtr,
    dateTime: getQuarterDates(new Date()).lastQuarterDate,
  },
  {
    label: DateFilter.This_Year,
    dateTime: {
      startDate: new Date(moment().startOf('year').toDate()),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
  },
  {
    label: DateFilter.Last_Year,
    dateTime: {
      startDate: new Date(
        moment().clone().subtract(1, 'year').startOf('year').toDate()
      ),
      endDate: new Date(
        moment().clone().subtract(1, 'year').endOf('year').toDate()
      ),
    },
  },

  {
    label: DateFilter.CUSTOM_DATE,
    dateTime: {
      startDate: new Date(moment().format('YYYY-MM-DD')),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
  },
];

export const ReportDayBookDefaultOptionsArray = [
  {
    label: DateFilter.TODAY,
    dateTime: {
      startDate: new Date(moment().format('YYYY-MM-DD')),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
  },
  {
    label: DateFilter.YESTERDAY,
    dateTime: {
      startDate: new Date(moment().subtract(1, 'days').format('YYYY-MM-DD')),
      endDate: new Date(moment().subtract(1, 'days').format('YYYY-MM-DD')),
    },
  },
  {
    label: DateFilter.This_Week,
    dateTime: {
      startDate: new Date(moment().startOf('week').toDate()),
      endDate: new Date(moment().format('YYYY-MM-DD')),
    },
  },

  {
    label: DateFilter.Last_Week,
    dateTime: {
      startDate: new Date(
        moment().subtract(1, 'weeks').startOf('week').add(1, 'days').toDate()
      ),
      endDate: new Date(
        moment().subtract(1, 'weeks').endOf('week').add(1, 'days').toDate()
      ),
    },
  },
];
