import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Customer({ color = '#a1a3b4' }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.977"
      height="20.661"
      viewBox="0 0 30.977 20.661"
      // {...props}
    >
      <G
        id="Group_204895"
        data-name="Group 204895"
        transform="translate(-12636.75 238.91)"
      >
        <G
          id="Group_204894"
          data-name="Group 204894"
          transform="translate(12363 -1100.696)"
        >
          <Path
            id="Path_302175_-_Outline"
            data-name="Path 302175 - Outline"
            d="M448.741,146.232a3.727,3.727,0,0,1-2.092-.639,3.749,3.749,0,0,1-1.355-1.645,3.738,3.738,0,0,1,.345-3.548,3.75,3.75,0,0,1,1.645-1.355,3.738,3.738,0,0,1,3.549.345,3.751,3.751,0,0,1,1.354,1.645,3.74,3.74,0,0,1-.8,4.1,3.744,3.744,0,0,1-1.189.8A3.718,3.718,0,0,1,448.741,146.232Zm0-5.933a2.193,2.193,0,1,0,2.193,2.193A2.195,2.195,0,0,0,448.741,140.3Z"
            transform="translate(-149.873 725.578)"
            fill={color}
          />
          <Path
            id="Path_302176_-_Outline"
            data-name="Path 302176 - Outline"
            d="M518.441,252.279l.673.7a5.012,5.012,0,0,1,1.413,3.478v1.7a3.317,3.317,0,0,1-3.317,3.317H514.74V258.15a6.734,6.734,0,0,0-.4-2.28l-.34-.943,1-.092a3.9,3.9,0,0,0,2.916-1.749Zm-1.231,7.639a1.768,1.768,0,0,0,1.769-1.769v-1.7a3.448,3.448,0,0,0-.443-1.688q-.059.06-.12.118a5.472,5.472,0,0,1-2.073,1.243q-.142.047-.287.086.062.254.107.511a8.307,8.307,0,0,1,.127,1.424v1.77Z"
            transform="translate(-215.8 617.589)"
            fill={color}
          />
          <Path
            id="Path_302177_-_Outline"
            data-name="Path 302177 - Outline"
            d="M133.741,146.232a3.727,3.727,0,0,1-2.092-.639,3.749,3.749,0,0,1-1.354-1.645,3.737,3.737,0,0,1,.345-3.549,3.75,3.75,0,0,1,1.645-1.354,3.737,3.737,0,0,1,3.549.345,3.75,3.75,0,0,1,1.355,1.645,3.737,3.737,0,0,1-.345,3.549,3.749,3.749,0,0,1-1.645,1.355A3.717,3.717,0,0,1,133.741,146.232Zm0-5.933a2.193,2.193,0,1,0,2.193,2.193A2.2,2.2,0,0,0,133.741,140.3Z"
            transform="translate(145.869 725.578)"
            fill={color}
          />
          <Path
            id="Path_302178_-_Outline"
            data-name="Path 302178 - Outline"
            d="M88.34,252.28l.531.807a3.9,3.9,0,0,0,2.916,1.748l1,.092-.341.943a6.737,6.737,0,0,0-.4,2.281v3.315H89.571a3.317,3.317,0,0,1-3.317-3.317v-1.7a5.013,5.013,0,0,1,1.414-3.48Zm2.386,3.933q-.145-.039-.287-.086a5.471,5.471,0,0,1-2.074-1.243q-.061-.058-.12-.118a3.451,3.451,0,0,0-.443,1.69v1.694a1.768,1.768,0,0,0,1.769,1.769h.921V258.15a8.327,8.327,0,0,1,.127-1.426Q90.664,256.468,90.726,256.213Z"
            transform="translate(187.496 617.589)"
            fill={color}
          />
          <Path
            id="Path_302179_-_Outline"
            data-name="Path 302179 - Outline"
            d="M220.725,252.371l.587.463a6.707,6.707,0,0,1,2.549,5.251v3.392a3.317,3.317,0,0,1-3.317,3.317h-8.476a3.317,3.317,0,0,1-3.317-3.317v-3.391a6.707,6.707,0,0,1,2.549-5.252l.587-.463.483.57a5.159,5.159,0,0,0,7.872,0Zm-.181,10.875a1.768,1.768,0,0,0,1.769-1.769v-3.391a5.128,5.128,0,0,0-1.441-3.568q-.244.227-.509.428a6.708,6.708,0,0,1-8.114,0q-.265-.2-.509-.428a5.129,5.129,0,0,0-1.441,3.569v3.389a1.768,1.768,0,0,0,1.769,1.769Z"
            transform="translate(72.933 617.652)"
            fill={color}
          />
          <Path
            id="Path_302180_-_Outline"
            data-name="Path 302180 - Outline"
            d="M266.262,96.275a5.045,5.045,0,0,1-1.009-.1,4.988,4.988,0,0,1-1.794-.755,5.025,5.025,0,0,1-1.815-2.2,4.992,4.992,0,0,1-.292-.942,5.053,5.053,0,0,1,0-2.019,4.989,4.989,0,0,1,.755-1.794,5.012,5.012,0,0,1,5.165-2.108,4.989,4.989,0,0,1,1.794.755,5.024,5.024,0,0,1,1.815,2.2,4.993,4.993,0,0,1,.292.942,5.053,5.053,0,0,1,0,2.019,4.989,4.989,0,0,1-.755,1.794,5.025,5.025,0,0,1-2.2,1.815,4.992,4.992,0,0,1-.942.292A5.045,5.045,0,0,1,266.262,96.275Zm0-8.476a3.464,3.464,0,1,0,3.464,3.464A3.468,3.468,0,0,0,266.262,87.8Z"
            transform="translate(22.977 775.535)"
            fill={color}
          />
        </G>
      </G>
    </Svg>
  );
}

export default Customer;
