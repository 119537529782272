import {
  EditOrderRequest,
  ProductVariant,
  UpdatedLine,
  getCustomerInvite,
  useEditOrderMutation,
} from '@zonofi/zono-money-store';
import React from 'react';
import UserDetails from '../user-details/user-details';
import { ActionButton, SearchBar } from '../common';
import {
  OrderData,
  OrderDataLine,
  useAppSelector,
} from '@zonofi/zono-money-helpers';
import './order-edit.scss';
import { ProductCard } from './product-card';
import { AddProductsModal } from './add-products-modal';
import _ from 'lodash';

interface OrderEditViewProps {
  orderData: OrderData;
  getOrder: () => void;
  isEdit: boolean;
  setIsEdit: (val: boolean) => void;
}

export const OrderEditView: React.FC<OrderEditViewProps> = ({
  orderData,
  getOrder,
  isEdit,
  setIsEdit,
}) => {
  const [searchKey, setSearchKey] = React.useState('');
  const [editableData, setEditableData] = React.useState<OrderDataLine[]>([]);
  const [addProduct, setAddProduct] = React.useState(false);
  const [poFileLineId, setPoFileLineId] = React.useState('');

  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;
  const [editOrder, editOrderResponse] = useEditOrderMutation();

  React.useEffect(() => {
    setEditableData(orderData?.lines || []);
  }, [orderData?.lines, isEdit]);

  const filteredOrderLines = React.useMemo(
    () =>
      searchKey
        ? editableData?.filter(
            (line) =>
              line?.title
                ?.toLocaleLowerCase()
                ?.includes(searchKey.toLocaleLowerCase()) ||
              line?.skuCode
                ?.toLocaleLowerCase()
                ?.includes(searchKey.toLocaleLowerCase())
          )
        : editableData,
    [searchKey, editableData]
  );

  const onSaveChanges = (isReplace?: boolean) => {
    const newLines = [...editableData];
    const updatedLines: UpdatedLine[] = newLines
      ?.filter((item) => item?.isEdited)
      ?.map((line) => {
        const lineData: UpdatedLine = {
          quantity: line?.deleted
            ? '0'
            : line?.newQty?.toString() ?? line?.quantity?.toString() ?? '0',
        };
        if (line?.productVariantId)
          lineData.productVariantId = line.productVariantId;
        if (isReplace && line?.orderLineId)
          lineData.orderLineId = line.orderLineId;
        else if (line?.poFileLineId) lineData.poFileLineId = line.poFileLineId;
        return lineData;
      });

    if (updatedLines?.length) {
      const payload: EditOrderRequest = {
        customerId: inviteId,
        workspaceId: seller?.workspaceId,
        lines: updatedLines,
      };
      if (updatedLines.some((x) => _.isUndefined(x.productVariantId)))
        payload.poFileId = orderData.poFileId;
      if (!updatedLines.every((x) => _.isUndefined(x.productVariantId)))
        payload.orderId = orderData.lines
          .find((x) => x.orderId)
          ?.orderId?.toString();
      editOrder(payload);
    }
    if (!updatedLines.length) setIsEdit(false);
  };

  React.useEffect(() => {
    if (editOrderResponse.isSuccess) {
      getOrder();
    }
  }, [editOrderResponse]);

  const handleDeleteLine = (product: ProductVariant) => {
    const orderline = editableData?.find(
      (line) => line.productVariantId === product.id
    );
    if (orderline) {
      editOrder({
        customerId: inviteId,
        workspaceId: seller?.workspaceId,
        lines: [
          {
            productVariantId: product.id,
            quantity: '0',
            poFileLineId: orderline.poFileLineId,
          },
        ],
        orderId: orderData?.lines?.[0]?.orderId?.toString() || '',
      });
    }
  };

  const updateLine = (line: OrderDataLine, isReplace?: boolean) => {
    line.isEdited = true;
    const index = editableData.findIndex((x) =>
      isReplace
        ? x.poFileLineId === line.poFileLineId
        : x.productVariantId === line.productVariantId
    );

    const allLines = [...editableData];

    if (index !== -1) allLines[index] = line;
    else allLines.push(line);
    setEditableData(allLines);
    if (isReplace) {
      setAddProduct(false);
      setPoFileLineId('');
      onSaveChanges(isReplace);
    }
  };

  const onReplaceProduct = (poFileLineId: string) => {
    setAddProduct(true);
    setPoFileLineId(poFileLineId);
  };

  return (
    <div className="container" style={{ height: '94%' }}>
      <UserDetails variant="order" />
      <div className="order-edit-view">
        <section className="search-section">
          <SearchBar
            setSearchKey={setSearchKey}
            placeholder="Search and add product Title"
          />
          {isEdit ? (
            <>
              <ActionButton
                icon={{ name: 'check', type: 'antdesign' }}
                onClick={() => {
                  onSaveChanges();
                  setIsEdit(false);
                }}
              />
              <ActionButton
                icon={{ name: 'close', type: 'antdesign' }}
                onClick={() => setIsEdit(false)}
              />
            </>
          ) : (
            <>
              <ActionButton
                icon={{ name: 'edit-2', type: 'feather' }}
                onClick={() => setIsEdit(true)}
              />
              <ActionButton
                icon={{ name: 'plus', type: 'antdesign' }}
                onClick={() => setAddProduct(true)}
              />
            </>
          )}
        </section>
        <div className="product-card-container">
          {filteredOrderLines
            ?.filter((x) => !isEdit || (isEdit && !x.deleted))
            ?.map((orderline, index) => (
              <ProductCard
                key={index}
                orderline={orderline}
                isEdit={isEdit}
                updateLine={updateLine}
                setPoFileLineId={onReplaceProduct}
              />
            ))}
        </div>
      </div>
      <AddProductsModal
        isVisible={addProduct}
        onClose={() => {
          getOrder();
          setAddProduct(false);
          setPoFileLineId('');
        }}
        editableData={editableData}
        orderId={orderData?.lines?.[0]?.orderId}
        handleDeleteLine={handleDeleteLine}
        updateLine={updateLine}
        poFileLineId={poFileLineId}
      />
    </div>
  );
};
