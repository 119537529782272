import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { colors } from '../colors/colors';

/* eslint-disable-next-line */
export interface LoadingSpinnerProps {
  size?: number | 'small' | 'large' | undefined;
  containerStyle?: StyleProp<ViewStyle>;
  customStyle?: StyleProp<ViewStyle>;
  color?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 'large',
  containerStyle,
  customStyle,
  color = colors.lightGrey.color,
}) => {
  return (
    <ActivityIndicator
      color={color}
      animating
      size={size}
      style={[customStyle ? customStyle : styles.container, containerStyle]}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: 'center', alignItems: 'center' },
});
