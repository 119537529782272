import { Platform } from 'react-native';

export const isWeb = () => {
  if (Platform.OS === 'web') {
    return true;
  } else {
    return false;
  }
};

export const isNative = () => {
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    return true;
  } else {
    return false;
  }
};
