export const fontConfig = {
  web: {
    bold: {
      fontFamily: 'Fira Sans, sans-serif',
      fontWeight: '900' as '900',
    },
    semiBold: {
      fontFamily: 'Fira Sans, sans-serif',
      fontWeight: '600' as '600',
    },
    regular: {
      fontFamily: 'Fira Sans, sans-serif',
      fontWeight: '500' as '500',
    },
    medium: {
      fontFamily: 'Fira Sans, sans-serif',
      fontWeight: '400' as '400',
    },
    light: {
      fontFamily: 'Fira Sans, sans-serif',
      fontWeight: '300' as '300',
    },
    italic: {
      fontFamily: 'Fira Sans, sans-serif',
      fontStyle: 'italic',
      fontWeight: '400' as '400',
    },
    boldItalic: {
      fontFamily: 'Fira Sans, sans-serif',
      fontStyle: 'italic',
      fontWeight: '900' as '900',
    },
    semiBoldItalic: {
      fontFamily: 'Fira Sans, sans-serif',
      fontStyle: 'italic',
      fontWeight: '600' as '600',
    },
    condensedRegular: {
      fontFamily: 'Fira Sans Condensed, sans-serif',
      fontWeight: '500' as '500',
    },
    condensedSemiBold: {
      fontFamily: 'Fira Sans Condensed, sans-serif',
      fontWeight: '600' as '600',
    },
    firaSansBook: {
      fontFamily: 'Fira Sans Book, sans-serif',
      fontWeight: '500' as '500',
    },
  },
  ios: {
    bold: {
      fontFamily: 'FiraSans-Bold',
      fontWeight: '900' as '900',
    },
    semiBold: {
      fontFamily: 'FiraSans-SemiBold',
      fontWeight: '600' as '600',
    },
    regular: {
      fontFamily: 'FiraSans-Regular',
      fontWeight: '500' as '500',
    },
    medium: {
      fontFamily: 'FiraSans-Medium',
      fontWeight: '400' as '400',
    },
    light: {
      fontFamily: 'FiraSans-Light',
      fontWeight: '300' as '300',
    },
    italic: {
      fontFamily: 'FiraSans-Italic',
      fontWeight: '400' as '400',
    },
    boldItalic: {
      fontFamily: 'FiraSans-BoldItalic',
      fontWeight: '900' as '900',
    },
    semiBoldItalic: {
      fontFamily: 'FiraSans-SemiBoldItalic',
      fontWeight: '600' as '600',
    },
    condensedRegular: {
      fontFamily: 'FiraSansCondensed-Regular',
      fontWeight: '500' as '500',
    },
    condensedSemiBold: {
      fontFamily: 'FiraSansCondensed-SemiBold',
      fontWeight: '600' as '600',
    },
    firaSansBook: {
      fontFamily: 'FiraSans-Book,',
      fontWeight: '500' as '500',
    },
  },
  default: {
    bold: {
      fontFamily: 'FiraSans-Bold',
      fontWeight: '900' as '900',
    },
    semiBold: {
      fontFamily: 'FiraSans-SemiBold',
      fontWeight: '600' as '600',
    },
    regular: {
      fontFamily: 'Fira Sans',
      fontWeight: '500' as '500',
    },
    medium: {
      fontFamily: 'FiraSans-Medium',
      fontWeight: '400' as '400',
    },
    light: {
      fontFamily: 'FiraSans-Light',
      fontWeight: '300' as '300',
    },
    italic: {
      fontFamily: 'FiraSans-Italic',
      fontWeight: '400' as '400',
    },
    boldItalic: {
      fontFamily: 'FiraSans-BoldItalic',
      fontWeight: '900' as '900',
    },
    semiBoldItalic: {
      fontFamily: 'FiraSans-SemiBoldItalic',
      fontWeight: '600' as '600',
    },
    condensedRegular: {
      fontFamily: 'FiraSansCondensed-Regular',
      fontWeight: '500' as '500',
    },
    condensedSemiBold: {
      fontFamily: 'FiraSansCondensed-SemiBold',
      fontWeight: '600' as '600',
    },
    circularStdThin: {
      fontFamily: 'CircularStd-Black',
      fontWeight: '100' as '100',
    },
    circularStdLight: {
      fontFamily: 'CircularStd-Light',
      fontWeight: '300' as '300',
    },
    circularStdRegular: {
      fontFamily: 'CircularStd-Book',
      fontWeight: '400' as '400',
    },
    circularStdMedium: {
      fontFamily: 'CircularStd-Medium',
      fontWeight: '500' as '500',
    },
    circularStdBold: {
      fontFamily: 'CircularStd-Bold',
      fontWeight: '600' as '600',
    },
    RupeeForadianRegular: {
      fontFamily: 'RupeeForadian-Regular',
      fontWeight: '400' as '400',
    },
    firaSansBook: {
      fontFamily: 'FiraSans-Book',
      fontWeight: '500' as '500',
    },
    segoeRegular: {
      fontFamily: 'Segoe-UI',
      fontWeight: '400' as '400',
    },
    segoeBold: {
      fontFamily: 'Segoe-UI-Bold',
      fontWeight: '700' as '700',
    },
    segoeItalic: {
      fontFamily: 'Segoe-UI-Italic',
      fontWeight: '400' as '400',
    },
    segoeBoldItalic: {
      fontFamily: 'Segoe-UI-BoldItalic',
      fontWeight: '700' as '700',
    },
    segoeSemiBold: {
      fontFamily: 'Segoe-UI-SemiBold',
      fontWeight: '600' as '600',
    },
    segoeSemiLight: {
      fontFamily: 'Segoe-UI-SemiLight',
      fontWeight: '300' as '300',
    },
    barlowBold: {
      fontFamily: 'Barlow-Bold',
      fontWeight: '700' as '700',
    },
    barlowSemiBold: {
      fontFamily: 'Barlow-SemiBold',
      fontWeight: '600' as '600',
    },
  },
};
