import React, { useContext } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { globalStyles } from '../styles/global-style';
import { ThemeContext } from '../contexts/ThemeContext';
import Icon from 'react-native-easy-icon';

/* eslint-disable-next-line */
export interface RadioButtonProps {
  text: string;
  isSelected: boolean;
  onPress: () => void;
  size?: number;
  component?: JSX.Element;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  text,
  isSelected,
  onPress,
  size,
  component,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <TouchableOpacity style={[globalStyles.flexRowAlCenter]} onPress={onPress}>
      <Icon
        type="ionicon"
        name={isSelected ? 'radio-button-on' : 'radio-button-off'}
        size={size ? size : 16}
        color={isSelected ? '#6BBE4D' : '#707070'}
        style={{ marginRight: 4 }}
      />
      <Text
        style={[
          theme.fontConfig.regular,
          { color: theme.text.primary, fontSize: 14, marginBottom: 2 },
        ]}
      >
        {text}
      </Text>
      {component}
    </TouchableOpacity>
  );
};

export default RadioButton;
