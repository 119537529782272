import React from 'react';
import { fontConfig } from '../fonts/fonts';
import { colors } from '../colors/colors';

import {
  View,
  Text,
  StyleProp,
  TextStyle,
  StyleSheet,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import Icon from 'react-native-easy-icon';
import { IconType } from 'react-native-easy-icon/src/Icon';
import {
  widthPercentageToDPBoundsViewPort as wvp,
  heightPercentageToDPBoundsViewPort as hvp,
} from '../helpers';

/* eslint-disable-next-line */
export interface IconCardProps {
  text: string;
  icon?: {
    iconType: IconType;
    iconName: string;
    iconColor: string;
    iconSize?: number;
    iconStyle?: StyleProp<TextStyle>;
  };
  img?: React.ReactNode;
  badgeCounter?: number;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

export const IconCard: React.FC<IconCardProps> = ({
  text,
  icon,
  img,
  badgeCounter,
  containerStyle,
  onPress,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      <View style={[styles.badge, { opacity: badgeCounter ? 1 : 0 }]}>
        <Text style={styles.badgeCounter}>{badgeCounter}</Text>
      </View>
      {icon ? (
        <Icon
          name={icon.iconName}
          type={icon.iconType}
          style={icon.iconStyle}
          size={icon.iconSize ?? 25}
          color={icon.iconColor}
        />
      ) : (
        img
      )}
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.outerSpace.color,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
  },
  text: {
    ...fontConfig.default.regular,
    fontSize: 12,
    color: colors.white.color,
  },
  badge: {
    backgroundColor: colors.venetianRed.color,
    height: 20,
    width: 20,
    borderRadius: 10,
    position: 'absolute',
  },
  badgeCounter: {
    ...fontConfig.default.bold,
    color: colors.white.color,
    fontSize: 10,
  },
});
