import { combineReducers } from '@reduxjs/toolkit';
import { zonoApi } from '../api';
import { customerInviteSlice } from '../customer-invite/customer-invite.slice';
import { fieldCollectionUserAuthSlice } from '../field-collection-user-auth/user-slice';
import { invoiceSlice } from '../invoice/invoice-slice';
import { paymentSlice } from '../payment/payment-slice';
import { toastSlice } from '../toast/toast.slice';
import { userAuthSlice } from '../user-auth/userAuth.slice';

const appReducer = combineReducers({
  [zonoApi.reducerPath]: zonoApi.reducer,
  [userAuthSlice.name]: userAuthSlice.reducer,
  [customerInviteSlice.name]: customerInviteSlice.reducer,
  [paymentSlice.name]: paymentSlice.reducer,
  [invoiceSlice.name]: invoiceSlice.reducer,
  [toastSlice.name]: toastSlice.reducer,
  [fieldCollectionUserAuthSlice.name]: fieldCollectionUserAuthSlice.reducer,
});

export default appReducer;
