import React, { useContext, useRef, useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { CustomSvg } from '../custom-svg/custom-svg';
import { SHARED_SVGS } from '../assets';
import { ThemeContext } from '../contexts/ThemeContext';
import _ from 'lodash';
import { colors } from '../colors/colors';
import { Button } from '../button/button';
import NewSwitchTabs from '../new-switch-tabs/new-switch-tabs';
import './custom-filters.scss';

export interface FilterOption {
  id: string;
  text: string;
  avatarName?: string;
  imgUrl?: string;
  subText?: string;
}
interface SearchInput {
  isSearchIncludes: boolean;
  placeholderText?: string;
}
interface TabItems {
  tabName: string;
  tabIcon?: string;
}

interface TabItems {
  tabName: string;
  tabIcon?: string;
}

interface Tabs {
  tabItems: TabItems[];
  selectedTab: number;
  tabButtonStyle: any;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}
interface SearchInputOptions {
  headerText: string;
  placeholderText: string;
  setTextInput: (text: string) => void;
  textInput: string;
}

interface CustomFiltersProps {
  multiSelection: boolean;
  options: FilterOption[];
  secondaryOptions?: FilterOption[];
  searchInputOptions?: SearchInputOptions[];
  secondaryHeader?: string;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedIds: string[];
  searchInput?: SearchInput;
  textWithImage?: boolean;
  filterType?: 'search' | 'option';
  tabs: Tabs;
}

export const CustomFilters: React.FC<CustomFiltersProps> = ({
  multiSelection,
  options,
  setSelectedIds,
  selectedIds,
  searchInput,
  textWithImage,
  tabs,
  secondaryOptions,
  secondaryHeader,
  filterType,
  searchInputOptions,
}) => {
  const [searchText, setSearchText] = useState('');
  const [highlight, setHighlight] = useState(false);
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState<FilterOption[]>([]);

  const [ids, setIds] = useState<string[]>(['']);

  const theme = useContext(ThemeContext).theme;
  const ref = useRef() as any;
  const scrolldivRef = useRef() as any;

  const baseColor = theme.table.linearGradient.primary;

  useEffect(() => {
    if (_.isEmpty(selectedIds)) {
      setIds([]);
      setSearchText('');
    }
  }, [selectedIds]);

  useEffect(() => {
    if (searchText?.length > 1) {
      const results = options?.filter((x) =>
        x?.text
          ?.toLowerCase()
          ?.replace(' ', '')
          ?.includes(searchText?.toLowerCase()?.replace(' ', ''))
      );
      setFilteredData(results);
    } else {
      setFilteredData(options);
    }
  }, [searchText, tabs]);

  // const handleKeyDown = (e: any) => {
  //   if (e.code === 'Enter') {
  //     console.log(e, 'event');
  //     setSearchText(e?.target?.value);
  //   }
  // };

  const optionsData = filteredData?.length > 0 ? filteredData : options;

  const renderListItem = (option: FilterOption) => {
    const isOptionIncludes = ids?.some((x) => x === option?.id);
    const updateFilter = () => {
      if (multiSelection && option?.id !== 'all') {
        if (isOptionIncludes) {
          const newList = ids?.filter((y) => y !== option?.id && y !== 'all');
          setIds(newList);
        } else {
          setIds([...ids, option?.id]?.filter((z) => z !== 'all'));
        }
      } else {
        setIds([option?.id]);
      }
    };

    return (
      <div className="custom-filters-list-item" onClick={updateFilter}>
        {isOptionIncludes ? (
          <img
            src={SHARED_SVGS.CheckBoxZonoBlue}
            style={{
              paddingRight: 4,
              marginLeft: 4,
              height: 18,
              width: 18,
            }}
          />
        ) : (
          <img
            src={SHARED_SVGS.CheckBoxDefault}
            style={{
              paddingRight: 4,
              marginLeft: 4,
              height: 18,
              width: 18,
            }}
          />
        )}
        {textWithImage ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginLeft: 4,
                marginRight: 4,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={option.imgUrl}
                alt=""
                style={{ height: 20, width: 20 }}
              />
            </div>
            <div
              style={{
                ...theme.fontConfig.regular,
                fontSize: 14,
                color: '#242b35',
                marginRight: 4,
              }}
            >
              {option?.text ?? '-'}
            </div>
          </div>
        ) : (
          <div
            style={{
              ...theme.fontConfig.regular,
              fontSize: 14,
              marginLeft: 8,
            }}
          >
            {option?.text ?? '-'}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="custom-filters-root">
      <Popup
        ref={ref}
        trigger={
          <div
            style={{
              borderRadius: 6,
              marginTop: 4,
              borderWidth: 1,
              ...(highlight
                ? theme.filterButton.selected
                : baseColor
                ? { backgroundColor: baseColor, borderColor: baseColor }
                : theme.filterButton.unselected),
            }}
          >
            <CustomSvg
              svg={SHARED_SVGS.FilterNew}
              height={25}
              width={25}
              colors={{
                fill: highlight ? theme.filter.highlight : theme.filter.text,
              }}
            />
          </div>
        }
        on={['click']}
        position={['bottom left', 'bottom right']}
        closeOnDocumentClick
        arrowStyle={{ color: theme.filter.header }}
        onOpen={() => {
          setOpen && setOpen(true);
          !_.isUndefined(setHighlight) && setHighlight(true);
        }}
        onClose={() => {
          setTimeout(() => {
            setOpen && setOpen(false);
          }, 140);
          !_.isUndefined(setHighlight) && setHighlight(false);
        }}
        open={open}
        contentStyle={{
          zIndex: 150000,
          boxShadow: '0px 2px 4px #00000014',
        }}
      >
        <div className="custom-filters-popup-container">
          
          <div className='custom-filters-top-container'>
          <div className="custom-filters-popup-header-container">
            <div
              style={theme.fontConfig.bold}
              className="custom-filters-popup-header-text"
            >
              FILTERS
            </div>
          </div>
          <NewSwitchTabs
            tabs={tabs?.tabItems}
            selectedTab={tabs?.selectedTab}
            setSelectedTab={tabs?.setSelectedTab}
            tabButtonStyle={tabs?.tabButtonStyle}
            tabButtonContainerStyles={{ paddingTop: 12 }}
          />
          <div className="hr-line" />
          {filterType === 'option' ? (
            <>
              <div
                ref={scrolldivRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  paddingTop: 10,
                }}
              >
                {optionsData?.map((option) => renderListItem(option))}
              </div>

              {secondaryOptions && (
                <>
                  <div className="hr-line" />
                  <div
                    style={{
                      ...theme.fontConfig.regular,
                      fontSize: 14,
                      color: '#242b35',
                      lineHeight: 16,
                    }}
                  >
                    {secondaryHeader ?? ''}
                  </div>
                  {secondaryOptions?.map((option) => renderListItem(option))}
                </>
              )}
            </>
          ) : (
            searchInputOptions?.map((item) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    marginTop: 7,
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      border: '1px solid #D9D9D9',
                      width: '100%',
                      borderRadius: 6,
                      borderWidth: 1,
                      padding: 5,
                      flexDirection: 'row',
                      display: 'flex',
                      height: 30,
                    }}
                  >
                    <img
                      src={SHARED_SVGS.searchIcon}
                      style={{ width: 26, height: 26 }}
                    />
                    <input
                      style={{
                        width: '90%',
                        border: 'none',
                        color: theme.text.primary,
                        ...theme.fontConfig.semiBold,
                      }}
                      value={item.textInput}
                      onChange={(e) => {
                        item?.setTextInput(e.target.value);
                      }}
                      placeholder={item.placeholderText}
                      // placeholderTextColor={'#BFC0C2'}
                    />
                  </div>
                </div>
              </div>
            ))
          )}
          </div>

      
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className="hr-line" />
          <div
            style={{
              marginLeft: 15,
              marginRight: 15,
              display: 'flex',
              alignItems: 'center',
              height: 48,
              justifyContent: 'space-between',
            }}
          >
            <div
              onClick={() => {
                setIds([]);
                setSelectedIds([]);
                // ref?.current?.close();
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  ...theme.fontConfig.semiBold,
                  color:
                    ids?.length > 0 ? '#DD360C' : theme.addToCart.backGround,
                }}
              >
                Clear Filter
              </div>
            </div>
            <Button
              variant="gradient"
              gradientColors={
                ids?.length > 0
                  ? Object.values(theme.gradientButton)
                  : ['#999', '#999']
              }
              text={'Apply'}
              disabled={ids?.length === 0}
              style={{
                height: 32,
                width: 132,
                borderRadius: 4,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              textStyle={{
                color: '#fff',
                fontSize: 14,
                fontFamily: theme.fontConfig.semiBold.fontFamily,
              }}
              onPress={() => {
                ids?.includes('all') ? setSelectedIds([]) : setSelectedIds(ids);
                setSearchText('');
                ref?.current?.close();
              }}
            />
          </div>
        </div>

         
        </div>
      </Popup>
    </div>
  );
};
