import React from 'react';
import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material';

export const Tooltip: React.FC<MUITooltipProps> = React.memo(
  ({ children, ...props }) => {
    return <MUITooltip {...props}>{children}</MUITooltip>;
  }
);
