// Generated by ts-to-zod
import { z } from 'zod';

const priceListItemSchema = z.object({
  sku: z.string(),
  productVariantId: z.number(),
  pts: z.number(),
  ptr: z.number(),
  name: z.string(),
});

export const priceListMessageSchema = z.object({
  startDate: z.string(),
  endDate: z.string().nullable(),
  inviteId: z.string(),
  isGlobal: z.boolean(),
  globalPriceListId: z.string().nullable(),
  exclusivePriceListId: z.string().nullable(),
  instructions: z.string(),
  priceListItems: z.array(priceListItemSchema),
  cfaIds: z.array(z.string()),
  id: z.string(),
  priceListId: z.string(),
});
