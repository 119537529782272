import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import CustomPopUp from '../custom-pop-up/custom-pop-up';
import { colors } from '../colors/colors';
import { titleTruncate } from '@zonofi/common';

interface TexWithTruncateProps {
  title: string;
  promotionId?: number;
  truncateValue?: number;
  fontFamily?: string;
  fontSize?: number;
  color?: string;
  textWithHyperLink?: boolean;
  textWithHover?: boolean;
  onClickAction?: () => void
}

export const TexWithTruncate: React.FC<TexWithTruncateProps> = ({
  title,
  truncateValue = 17,
  fontFamily,
  textWithHover,
  fontSize = 16,
  color,
  textWithHyperLink = false,
  onClickAction
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return title?.length > truncateValue ? (
    <CustomPopUp
      icon={
        <p
          style={{
            color: color ? color : theme.text.primary,
            fontFamily: fontFamily
              ? fontFamily
              : theme.fontConfig.regular.fontFamily,
            fontSize,
            padding: 0,
            margin: 0,
            marginBottom: 3,  
            whiteSpace: 'nowrap',
            cursor: textWithHyperLink || textWithHover ? 'pointer' : 'default',
            textDecoration:  textWithHyperLink ? 'underline' : 'none',
          }}
          onClick={() => {
            onClickAction &&  onClickAction()
          }}
        >
          {titleTruncate(title ?? '-', truncateValue)}
        </p>
      }
      arrow={true}
      color={'#9399B0'}
      event={'hover'}
      content={
        <span
          style={{
            color: colors.white.color,
            ...theme.fontConfig.regular,
          }}
        >
          {title}
        </span>
      }
      padding={'10px'}
      border={{
        borderRadius: 3,
      }}
    />
  ) : (
    <p
      style={{
        color: color ? color : theme.text.primary,
        fontFamily: fontFamily
          ? fontFamily
          : theme.fontConfig.regular.fontFamily,
        fontSize,
        padding: 0,
        margin: 0,
        marginBottom: 3,
        whiteSpace: 'nowrap',
        cursor: textWithHyperLink ? 'pointer' : 'default',
        textDecoration:  textWithHyperLink ? 'underline' : 'none',
      }}
      onClick={() => {
        onClickAction &&  onClickAction()
      }}
    >
      {title}
    </p>
  );
};
