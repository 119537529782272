import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root/ReduxStore.store';
import { ErrorVariants, Toast } from './toast-model';
import { stat } from 'fs';

export const TOAST_FEATURE_KEY = 'toast';

export const initialToastState: Toast = {
  canDisplay: false,
  message: '',
  responseStatus: '',
  type: ErrorVariants.SUCCESS,
  dismissText: 'DISMISS',
};

export const toastSlice = createSlice({
  name: TOAST_FEATURE_KEY,
  initialState: initialToastState,
  reducers: {
    showToast(state, action: PayloadAction<Toast>) {
      state.canDisplay = true;
      state.message = action.payload.message;
      state.responseStatus = action.payload.responseStatus;
      state.type = action.payload.type;
      state.dismissText =
        action.payload.dismissText || initialToastState.dismissText;
    },
    hideToast(state) {
      state.canDisplay = false;
      state.dismissText = initialToastState.dismissText;
    },
  },
});

export const toastReducer = toastSlice.reducer;

export const { showToast, hideToast } = toastSlice.actions;

export const getToastState = (rootState: RootState): Toast =>
  rootState[TOAST_FEATURE_KEY];

export const getToastVisibility = createSelector(
  getToastState,
  (state) => state.canDisplay
);

export const getToast = createSelector(getToastState, (state) => state);
