import _ from 'lodash';

export const getQueryParams = (params: Record<string, any>) => {
  const queryParams = Object.entries(params)
    .map(([key, value]) => {
      if (_.isArray(value)) {
        return value
          .map((x) => `${encodeURIComponent(key)}=${encodeURIComponent(x)}`)
          .join('&');
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');
  return queryParams;
};
