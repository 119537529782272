import * as React from 'react';
import Svg, { Defs, ClipPath, Path, G } from 'react-native-svg';

function Group({ iconView }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconView.width}
      height={iconView.height}
      viewBox="0 0 46 34"
    >
      <Defs>
        <ClipPath id="a">
          <Path data-name="Rectangle 26821" fill="#fff" d="M0 0H46V34H0z" />
        </ClipPath>
      </Defs>
      <G data-name="Group 115888" clipPath="url(#a)" fill="#fff">
        <Path
          data-name="Path 198823"
          d="M86.842 57.906A6.123 6.123 0 1193 51.783a6.149 6.149 0 01-6.161 6.123m0-11.288a5.165 5.165 0 105.2 5.165 5.187 5.187 0 00-5.2-5.165"
          transform="translate(-65.121 -37.088)"
        />
        <Path
          data-name="Path 198824"
          d="M95.512 43.142a.451.451 0 01-.077-.006.479.479 0 01-.4-.547 5.976 5.976 0 00-1.654-4.629 7.968 7.968 0 00-8.4-2.087 4.818 4.818 0 00-2.4 1.451.484.484 0 01-.671.121.477.477 0 01-.122-.667 5.651 5.651 0 012.9-1.816 8.937 8.937 0 019.412 2.357 6.928 6.928 0 011.89 5.419.482.482 0 01-.476.4"
          transform="translate(-65.946 -28.065)"
        />
        <Path
          data-name="Path 198825"
          d="M76.885 39.43l.709 1.138a.109.109 0 00.063.048l1.279.371a.11.11 0 01.023.2l-3.321 1.823a.11.11 0 01-.158-.133l1.206-3.425a.111.111 0 01.2-.022"
          transform="translate(-60.918 -32.01)"
        />
        <Path
          data-name="Path 198826"
          d="M77.565 83.64a8.736 8.736 0 01-6.5-2.821 6.927 6.927 0 01-1.89-5.419.482.482 0 01.952.15 5.975 5.975 0 001.654 4.629 7.969 7.969 0 008.4 2.087 4.818 4.818 0 002.4-1.451.484.484 0 01.671-.121.477.477 0 01.122.667 5.651 5.651 0 01-2.9 1.816 9.4 9.4 0 01-2.91.464"
          transform="translate(-55.8 -60.802)"
        />
        <Path
          data-name="Path 198827"
          d="M133.4 99.785l-.709-1.138a.109.109 0 00-.063-.048l-1.279-.371a.11.11 0 01-.023-.2l3.321-1.821a.111.111 0 01.158.133l-1.205 3.423a.111.111 0 01-.2.022"
          transform="translate(-105.954 -77.934)"
        />
        <Path
          data-name="Path 198828"
          d="M154.756 109.106a5.342 5.342 0 115.48-5.34 5.418 5.418 0 01-5.48 5.34m0-9.741a4.4 4.4 0 104.516 4.4 4.464 4.464 0 00-4.516-4.4"
          transform="translate(-120.487 -79.741)"
        />
        <Path
          data-name="Path 198829"
          d="M164.512 119.465a.483.483 0 01-.341-.14l-1.477-1.468a.477.477 0 010-.678.484.484 0 01.682 0l1.144 1.137 3.057-2.906a.484.484 0 01.682.015.477.477 0 01-.015.678l-3.4 3.23a.483.483 0 01-.333.133"
          transform="translate(-131.204 -93.362)"
        />
        <Path
          data-name="Path 198830"
          d="M52.306 29.488H52.3l-3.594-.049a.482.482 0 01-.456-.343l-.85-2.853-2.8-1.317-2.612 1.512a.485.485 0 01-.58-.072L38.8 23.821a.477.477 0 01-.079-.583l1.629-2.781-1.158-2.813-2.826-1a.479.479 0 01-.32-.451v-3.162a.479.479 0 01.312-.448l3.013-1.129 1.249-2.76-1.468-2.729a.478.478 0 01.1-.579l2.585-2.349a.484.484 0 01.542-.074l2.785 1.4 2.8-1.111L49 .32a.483.483 0 01.461-.32l3.25.074a.482.482 0 01.451.344l.872 2.943 2.84 1.24L59.751 3.2a.484.484 0 01.558.1l2.413 2.471a.477.477 0 01.067.581l-1.532 2.524 1.058 2.961 3.027 1.24a.479.479 0 01.3.455l-.074 3.034a.48.48 0 01-.327.442l-2.976 1-.637 1.856a.482.482 0 01-.913-.309l.714-2.08a.481.481 0 01.3-.3l2.881-.969.058-2.37-2.919-1.2a.48.48 0 01-.27-.283l-1.207-3.37a.477.477 0 01.041-.408l1.457-2.4-1.91-1.957-2.76 1.34a.485.485 0 01-.406.008l-3.25-1.419a.481.481 0 01-.269-.3l-.836-2.821-2.55-.061-.992 2.8a.482.482 0 01-.276.286l-3.2 1.272a.485.485 0 01-.4-.017l-2.678-1.339-2.064 1.875 1.4 2.608a.476.476 0 01.014.422l-1.428 3.157a.482.482 0 01-.27.252l-2.888 1.082v2.487l2.708.963a.481.481 0 01.284.27l1.329 3.23a.477.477 0 01-.029.422L39.74 23.4l2.08 2.028 2.513-1.456a.484.484 0 01.449-.019l3.225 1.517a.481.481 0 01.256.3l.81 2.72 2.87.039.81-2.828a.48.48 0 01.3-.319l3.373-1.223a.485.485 0 01.386.024l1.871.954a.478.478 0 01.209.645.484.484 0 01-.649.208l-1.683-.858-2.944 1.068-.846 2.94a.481.481 0 01-.464.348"
          transform="translate(-29.092 -.179)"
        />
        <Path
          data-name="Path 198831"
          d="M12.056 7.29a.481.481 0 01-.482-.479V4.266a.482.482 0 01.964 0v2.545a.481.481 0 01-.482.479"
          transform="translate(-9.342 -3.241)"
        />
        <Path
          data-name="Path 198832"
          d="M5.248 11.024a.479.479 0 110-.958l2.561-.012a.479.479 0 110 .958l-2.56.012z"
          transform="translate(-3.847 -8.307)"
        />
        <Path
          data-name="Path 198833"
          d="M23.57 113.358a.482.482 0 01-.452-.313l-.887-2.387a.482.482 0 01.9-.332l.887 2.387a.479.479 0 01-.285.616.485.485 0 01-.167.03"
          transform="translate(-17.919 -89.106)"
        />
        <Path
          data-name="Path 198834"
          d="M18.489 115.5a.479.479 0 01-.169-.928l2.4-.893a.483.483 0 01.621.28.478.478 0 01-.282.617l-2.4.893a.481.481 0 01-.169.031"
          transform="translate(-14.534 -92.046)"
        />
        <Path
          data-name="Path 198835"
          d="M96.949 162.224h-.014a.48.48 0 01-.468-.493l.074-2.544a.482.482 0 01.964.028l-.074 2.543a.481.481 0 01-.482.465"
          transform="translate(-77.863 -128.48)"
        />
        <Path
          data-name="Path 198836"
          d="M92.9 165.871h-.012l-2.56-.061a.479.479 0 11.023-.958l2.56.061a.479.479 0 01-.011.958"
          transform="translate(-72.532 -133.434)"
        />
        <Path
          data-name="Path 198837"
          d="M228.694 69.288h-.014a.48.48 0 01-.468-.493l.074-2.544a.482.482 0 01.964.028l-.074 2.544a.481.481 0 01-.482.465"
          transform="translate(-184.2 -53.357)"
        />
        <Path
          data-name="Path 198838"
          d="M224.648 72.936h-.012l-2.56-.061a.479.479 0 11.023-.958l2.56.061a.479.479 0 01-.011.958"
          transform="translate(-178.868 -58.312)"
        />
        <Path
          data-name="Path 198839"
          d="M.482 150.514a.479.479 0 110-.958l9.651-.065a.479.479 0 110 .958l-9.651.065z"
          transform="translate(0 -121.017)"
        />
        <Path
          data-name="Path 198840"
          d="M96.507 149.833l-26.918-.065a.479.479 0 110-.958l26.918.065a.479.479 0 110 .958"
          transform="translate(-55.779 -120.467)"
        />
        <Path
          data-name="Path 198841"
          d="M224.516 150.512h-.016l-2.757-.065a.479.479 0 11.023-.958l2.757.065a.479.479 0 01-.011.958"
          transform="translate(-178.602 -121.016)"
        />
        <Path
          data-name="Path 198842"
          d="M103.905 67.651l-2.868-2.672a.409.409 0 01-.15-.318v-.04a.21.21 0 01.23-.228h1.159c.809 0 1.319-.328 1.319-1.013v-.119h-2.428a.264.264 0 01-.29-.278.272.272 0 01.29-.288h2.433v-.089c0-.715-.52-1.043-1.339-1.043h-1.149a.2.2 0 01-.22-.219v-.2a.222.222 0 01.24-.228h4.267a.269.269 0 01.29.278.264.264 0 01-.29.278h-1.469a1.349 1.349 0 01.54 1.142v.079h.929a.272.272 0 01.29.288.264.264 0 01-.29.278h-.929v.1c0 1.093-.829 1.639-2.179 1.639h-.03l2.239 2.046a.4.4 0 01.03.606.442.442 0 01-.62 0"
          transform="translate(-81.422 -49.421)"
        />
      </G>
    </Svg>
  );
}

export default Group;
