import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

const FilterIcon = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.143"
      height="15.108"
      viewBox="0 0 20.143 15.108"
    >
      <G id="Filter_" data-name="Filter " transform="translate(-1061 -30)">
        <G
          id="_11_Options_2"
          data-name="11 Options_2"
          transform="translate(1061 30)"
        >
          <Path
            id="Path_113"
            data-name="Path 113"
            d="M1229.935,2106.979h-8.772a3.355,3.355,0,0,0-6.375,0h-2.9a1.049,1.049,0,1,0,0,2.1h2.9a3.355,3.355,0,0,0,6.375,0h8.772a1.049,1.049,0,0,0,0-2.1Z"
            transform="translate(-1210.841 -2104.671)"
            fill="#9295a5"
          />
          <Path
            id="Path_114"
            data-name="Path 114"
            d="M1229.935,2126.979h-2.9a3.355,3.355,0,0,0-6.375,0h-8.772a1.049,1.049,0,1,0,0,2.1h8.772a3.355,3.355,0,0,0,6.375,0h2.9a1.049,1.049,0,1,0,0-2.1Z"
            transform="translate(-1210.841 -2116.278)"
            fill="#9295a5"
          />
        </G>
      </G>
    </Svg>
  );
};

export default FilterIcon;
