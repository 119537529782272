import React, { useContext, useState } from 'react';
import { useExpanded, useTable } from 'react-table';
import { Table } from 'react-bootstrap';
import { ThemeContext } from '../contexts/ThemeContext';
import { center } from '@shopify/react-native-skia';
import { ClassNames } from '@emotion/react';

const getRowStyle = ({
  isExpandable,
  rowDepth,
  currentRowIndex,
  totalSubRowCounts,
}) => {
  if (!isExpandable && rowDepth !== 1) return {};

  return {
    borderTopLeftRadius: currentRowIndex === 0 && '4px',
    borderTopRightRadius: currentRowIndex === 0 && '4px',
    borderBottomLeftRadius: currentRowIndex === totalSubRowCounts - 1 && '4px',
    borderBottomRightRadius: currentRowIndex === totalSubRowCounts - 1 && '4px',
    borderLeft: '1px solid #DFE0E0',
    borderRight: '1px solid #DFE0E0',
    borderTop: currentRowIndex === 0 && '1px solid #DFE0E0',
    borderBottom:
      currentRowIndex === totalSubRowCounts - 1 && '1px solid #DFE0E0',
    width: '98%',
    marginLeft: 15,
  };
};

export const ReactTable = (props: any) => {
  const {
    columns,
    data,
    padding,
    bordered = false,
    tableStyle = {},
    headerStyle = {},
    bodyStyle = {},
    headerRowStyle = {},
    overrideStyles = {},
    isCustomColumns,
    calculatedWidth,
    className,
    isExpandable = false,
    ExpandableComponent = null,
  } = props;

  const [hoveredItemId, setHoveredItemId] = useState('');
  const [hoveredBorderItemId, setHoveredBorderItemId] = useState('');
  const currentTheme = useContext(ThemeContext);
  const { tbodyStyles = {} } = overrideStyles;
  const { theme } = currentTheme;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        // @ts-ignore
        autoResetExpanded: false
      },
      isExpandable && useExpanded,
    );
  // Table Degine: 'SPECIAL'
  // Render the UI for your table
  return (
    <Table
      className={className ? className : 'flex-table'}
      style={{
        // marginTop: props.margin ? 20 : 0,
        marginLeft: '0%',
        marginRight: '0%',
        zIndex: -1,
        ...tableStyle,
      }}
      bordered={bordered ? bordered : false}
      borderless={!bordered}
      {...getTableProps()}
    >
      <thead
        style={{
          width: isCustomColumns ? calculatedWidth : '100%',
          paddingRight: isCustomColumns ? 0 : 10,
          paddingLeft: isCustomColumns ? 0 : 10,
          ...headerStyle,
        }}
      >
        <div
          style={{
            borderWidth: 0.2,
            borderColor: theme.table.highlight,
            // marginTop: 10,
            backgroundColor: theme.table.highlight,
            borderStyle: 'solid',
          }}
        />

        {headerGroups.map((headerGroup) => (
          <tr
            style={{
              ...headerRowStyle,
            }}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, index) => {
              if (isCustomColumns) {
                return (
                  <th
                    {...column.getHeaderProps()}
                    style={
                      isCustomColumns && index === 0
                        ? {
                            // padding: '16px',
                            display: 'flex',
                            flex: 1,
                            justifyContent:
                              (column as any)?.alignment ?? 'flex-start',
                            position: 'sticky',
                            left: 0,
                            paddingLeft: 4,
                            backgroundColor: theme.dropDown.background,
                            boxShadow: '1px 0px 6px 0px rgba(0, 0, 0, 0.1)',

                            // minWidth: 180,
                          }
                        : {
                            // padding: '16px',
                            display: 'flex',
                            flex: 1,
                            justifyContent:
                              (column as any)?.alignment ?? 'flex-start',
                            backgroundColor: theme.dropDown.background,
                            // minWidth: 180,
                          }
                    }
                  >
                    {column.render('Header')}
                  </th>
                );
              }
              return (
                <th
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column?.minWidth,
                      maxWidth: column?.maxWidth,
                      width: column?.width,
                    },
                  })}
                >
                  {column.render('Header')}
                </th>
              );
            })}
          </tr>
        ))}
        <div
          style={{
            borderWidth: 0.2,
            borderColor: theme.table.highlight,
            // marginTop: props.margin ? 10 : 0,
            backgroundColor: theme.table.highlight,
            borderStyle: 'solid',
          }}
        />
      </thead>

      <tbody
        style={
          isCustomColumns
            ? {
                // overflowX: 'hidden',
                width: isCustomColumns ? calculatedWidth : '100%',
                // ...tbodyStyles,
              }
            : {
                justifyContent: 'center',
                alignItems: 'center',
                // overflowY: 'scroll',
                overflowX: 'hidden',
                width: '100%',
                paddingRight: 10,
                paddingLeft: 10,
                marginBottom: 10,
                ...bodyStyle,
                ...tbodyStyles,
              }
        }
        {...getTableBodyProps()}
      >
        {rows.map((row, i) => {
          const evenRow = i % 2 === 0;
          //@ts-ignore
          const rowId = row.original.id;
          prepareRow(row);

          // @ts-ignore
          return row.canExpand && ExpandableComponent ? (
            <tr>
              <td style={{ width: '100%' }}>
                <ExpandableComponent row={row} />
              </td>
            </tr>
          ) : (
            <tr
              style={
                isCustomColumns
                  ? {
                      backgroundColor:
                        hoveredItemId === rowId
                          ? theme.table.firstRow
                          : evenRow
                          ? 'transparent'
                          : theme.table.secondRow,
                      alignItems: 'center',
                      cursor: 'pointer',
                      borderRadius: '6px',
                      border:
                        hoveredBorderItemId === rowId
                          ? '1px solid #00C17F'
                          : '1px solid rgba(0, 0, 0, 0)',
                    }
                  : {
                      backgroundColor:
                        hoveredItemId === rowId
                          ? theme.table.firstRow
                          : evenRow
                          ? 'transparent'
                          : theme.table.secondRow,
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      width: '100%',
                      borderRadius: '6px',
                      position: 'relative',
                      border:
                        hoveredBorderItemId === rowId
                          ? '1px solid #00C17F'
                          : '1px solid rgba(0, 0, 0, 0)',
                      ...getRowStyle({
                        isExpandable,
                        // @ts-ignore
                        rowDepth: row.depth,
                        currentRowIndex: row.index,
                        // @ts-ignore
                        totalSubRowCounts: row.original.commonSkuCount,
                      }),
                    }
              }
              {...row.getRowProps()}
              onClick={() =>
                props.isSummary
                  ? //@ts-ignore
                    props.navigation(row.original.id, row.original.inviteId)
                  : //@ts-ignore
                    props.navigation(row.original.id)
              }
              // onMouseEnter={() => {
              //   //@ts-ignore
              //   const id = row.original.id;
              //   props.rowBorder && setHoveredItemId(id);
              //   props.rowBorder && setHoveredBorderItemId(id);
              // }}
              // onMouseLeave={() => {
              //   //@ts-ignore

              //   const id = row.original.id;
              //   if (hoveredItemId === id) {
              //     setHoveredItemId('');
              //   }
              //   if (hoveredBorderItemId === id) {
              //     props.rowBorder && setHoveredBorderItemId('');
              //   }
              // }}
            >
              {row.cells.map((cell, index) => {
                return (
                  <td
                    style={
                      isCustomColumns && index === 0
                        ? {
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            position: 'sticky',
                            left: 0,
                            backgroundColor: evenRow
                              ? 'white'
                              : theme.table.secondRow,
                            // boxShadow: '1px 0px 6px 0px rgba(0, 0, 0, 0.1)',
                          }
                        : {
                            color: theme.text.primary,
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: padding,
                            paddingBottom: padding,
                          }
                    }
                    {...cell.getCellProps(
                      isCustomColumns
                        ? {}
                        : {
                            style: {
                              minWidth: cell?.column?.minWidth,
                              maxWidth: cell?.column?.maxWidth,
                              width: cell?.column?.width,
                            },
                          }
                    )}
                    onClick={(e) =>
                      props.excludeColumnIds?.includes(cell.column.id) &&
                      e.stopPropagation()
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
        {props.isCustomer && (
          <div
            style={{
              height: '16%',
            }}
          />
        )}
      </tbody>
    </Table>
  );
};
