import { getDocumentAsync } from './file-picker';
import { ResizeImages } from './resize-images';

export const imagePicker = async () => {
  console.log('in pick image', 1);
  const result = await getDocumentAsync({
    type: 'image/*',
    multiple: true,
  });
  console.log('pick image result', result);
  const media = [];

  if (result.type === 'success') {
    const { output } = result;
    // setIsModalImage(true);
    console.log('pick image result 1', output);

    for (let index = 0; index < (output?.length || 0); index++) {
      // output.forEach(async (file) => {
      const file = output?.[index];
      console.log('pick image result 2', file);
      const resizedPics = (await ResizeImages([file])) as any[];
      const data = {
        path:
          file?.file?.type === 'application/pdf'
            ? file?.uri
            : resizedPics?.[0].path,
        // path: file?.uri,
        mime: file?.file?.type,
        contentUrl: file?.file.name,
      };
      console.log('pick data', data);
      media.push(data);
    }
    console.log('pick data 1', media);
  }
  return media;
};
