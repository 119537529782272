import { ThemeContext } from '../contexts/ThemeContext';
import { colors } from '../colors/colors';
import { SHARED_SVGS } from '../assets';
import { Modal } from '../modal/modal';
import _ from 'lodash';
import React from 'react';
import { globalStyles } from '../styles/global-style';

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
  heading?: string;
  headingStyle?: React.CSSProperties;
  content?: string | JSX.Element;
  contentStyle?: React.CSSProperties;
  primaryButton?: {
    text: string;
    onPress?: () => void;
  };
  cancelButton?: {
    text: string;
    onPress: () => void;
  };
  primaryButtonColors?: string[];
  imageContent?: React.JSX.Element;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  open,
  onClose,
  heading = '',
  headingStyle,
  content = '',
  contentStyle,
  primaryButton,
  primaryButtonColors,
  cancelButton,
  imageContent,
}) => {
  const { theme } = React.useContext(ThemeContext);
  const primaryButtonColours =
    primaryButtonColors ?? Object.values(theme.gradientButton);
  const gradientColor = `linear-gradient(90deg, ${primaryButtonColours[0]}, ${primaryButtonColours[1]})`;
  return (
    <Modal
      open={open}
      onClickClose={onClose}
      customModalCloseButtonStyle={{ display: 'none' }}
      customModalStyle={{
        width: '30%',
        padding: '4%',
      }}
      modalContent={
        <div
          style={{
            ...globalStyles.displayFlex,
            ...globalStyles.flexColAlCenterJcCenter,
          }}
        >
          {imageContent ? (
            imageContent
          ) : (
            <img
              src={SHARED_SVGS.AcceptTick}
              style={{
                height: 100,
                width: 100,
                marginBottom: 50,
              }}
              alt=""
            />
          )}
          <div
            style={{
              ...theme.fontConfig.bold,
              fontSize: 20,
              color: theme.text.primary,
              ...headingStyle,
            }}
          >
            {heading}
          </div>
          {_.isString(content) ? (
            <div
              style={{
                ...theme.fontConfig.regular,
                fontSize: 16,
                color: colors.gray.primary,
                paddingTop: 12,
                ...contentStyle,
              }}
            >
              {content}
            </div>
          ) : (
            content
          )}
          <div
            style={{
              ...globalStyles.displayFlex,
              ...globalStyles.flexRowAlCenterJcSpaceBetn,
              ...globalStyles.flex1,
              ...globalStyles.mT60,
              cursor: 'pointer',
            }}
          >
            {cancelButton && (
              <div
                style={{
                  width: 140,
                  border: `1px solid ${theme.secondary}`,
                  ...globalStyles.pT12,
                  ...globalStyles.pB12,
                  ...globalStyles.borderRd6,
                  ...(primaryButton ? {marginRight: 20} : {})
                  
                }}
                onClick={cancelButton.onPress}
              >
                <div
                  style={{
                    ...theme.fontConfig.bold,
                    fontSize: 16,
                    color: theme.secondary,
                  }}
                >
                  {cancelButton.text}
                </div>
              </div>
            )}
            {primaryButton && (
              <div
                style={{
                  width: cancelButton ? 140 : '100%',
                  ...globalStyles.pT12,
                  ...globalStyles.pB12,
                  ...globalStyles.borderRd6,
                  cursor: 'pointer',
                  background: gradientColor,
                }}
                onClick={primaryButton?.onPress}
              >
                <div
                  style={{
                    ...theme.fontConfig.bold,
                    fontSize: 16,
                    color: theme.primary,
                  }}
                >
                  {primaryButton?.text}
                </div>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

export default SuccessModal;
