import { Tag, Theme, ThemeContext, colors } from '@zonofi/shared';
import { SVGS } from '@zonofi/zono-money-design-system';
import _ from 'lodash';
import React from 'react';
import AddToOrder from '../add-to-order/add-to-order';
import Icon from 'react-native-easy-icon';
import { OrderDataLine } from '@zonofi/zono-money-helpers';

interface ProductCardProps {
  orderline: OrderDataLine;
  isEdit: boolean;
  updateLine: (line: OrderDataLine) => void;
  setPoFileLineId: (vl: string) => void;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  orderline,
  isEdit,
  updateLine,
  setPoFileLineId,
}) => {
  const { theme } = React.useContext(ThemeContext);
  const styles = getStyles(theme);
  const tagStyle = {
    text: theme.status.success,
    borderColor: colors.transparent.color,
    backgroundColor: '#E8FFE0',
    borderRadius: 4,
  };

  const handleDeleteLine = () => {
    orderline.newQty = 0;
    orderline.deleted = true;
    updateLine(orderline);
  };

  if (orderline.deleted) return null;

  return (
    <main className="product-card" style={{ background: theme.primary }}>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <section
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            ...styles.greyText,
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 2,
            }}
          >
            <div>{`SKU: ${orderline.skuCode || '-'}`}</div>
            {!_.isEmpty(orderline.promotionIds) && (
              <Tag
                label="OFFER APPLIED"
                style={tagStyle}
                fontSize={8}
                iconLeft={
                  <img
                    src={SVGS.DoubleTick}
                    alt=""
                    style={{ height: 12, width: 12 }}
                  />
                }
              />
            )}
          </div>
          <div>Quantity</div>
        </section>
        <section
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            ...styles.blackText,
          }}
        >
          <div>{orderline.title || orderline?.uploadedProductName || '-'}</div>
          <div>
            {isEdit &&
            !_.isNull(orderline?.productVariantId) &&
            orderline?.productVariantId !== 0 ? (
              <AddToOrder orderline={orderline} />
            ) : (
              orderline.quantity
            )}
          </div>
        </section>
      </section>
      <div style={styles.divider} />
      <footer
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <div style={{ ...styles.blackText12, color: '#E6A000' }}>
            * Price available on order confirmation
          </div>
          {isEdit && (
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <img
                src={SVGS.Replace}
                onClick={() => setPoFileLineId(orderline.poFileLineId || '')}
                alt=""
              />
              <div
                style={{
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={handleDeleteLine}
              >
                <Icon name="trash" type="evilicon" size={24} color="#EA0500" />
              </div>
            </div>
          )}
        </div>
      </footer>
    </main>
  );
};

type stylekey =
  | 'container'
  | 'greyText'
  | 'blackText'
  | 'blackText12'
  | 'divider'
  | 'popupContent';

const getStyles = (theme: Theme): Record<stylekey, React.CSSProperties> => ({
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000014',

    borderRadius: 8,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  greyText: {
    ...theme.fontConfig.regular,
    fontSize: 12,
    color: colors.gray.primary,
  },
  blackText: {
    ...theme.fontConfig.regular,
    fontSize: 14,
    color: colors.raisinBlack.primary,
  },
  blackText12: {
    ...theme.fontConfig.regular,
    fontSize: 12,
    color: colors.raisinBlack.secondary,
  },
  divider: {
    height: 1,
    backgroundColor: colors.gray.opacity20,
    width: '100%',
    marginBlock: 8,
  },
  popupContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: 6,
    background: '#ffffff',
    boxShadow: '0px 3px 6px #00000014',
    border: '1px solid #dfe0e0',
    borderRadius: 6,
  },
});
