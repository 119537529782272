import { OrderStatusEnum } from '../types/enum';

export const getOrderCardVariant = (status: string) => {
  switch (status) {
    case OrderStatusEnum.ADDING_ITEMS:
    case OrderStatusEnum.CREATED:
      return 'draft';
    case OrderStatusEnum.SUBMITTED_BY_CUSTOMER:
      return 'submitted';
    case OrderStatusEnum.APPROVED:
      return 'accepted';
    case OrderStatusEnum.WAITING_FOR_CONFIRMATION:
      return 'waitingForCNF';
    case OrderStatusEnum.CONFIRMED:
      return 'confirmed';
    case OrderStatusEnum.BILLED:
      return 'billed';
    default:
      return status;
  }
};
