import * as React from 'react';
import Svg, { Defs, ClipPath, Path, G, Rect } from 'react-native-svg';

function SuccessSummary({ width = '282', height = '219' }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 282 219"
    >
      <Defs>
        <ClipPath id="a">
          <Path data-name="Rectangle 26786" fill="none" d="M0 0H282V219H0z" />
        </ClipPath>
      </Defs>
      <G data-name="Group 116392">
        <G
          data-name="Group 115745"
          transform="translate(-523.483 -236.001) translate(523.483 236.001)"
          clipPath="url(#a)"
        >
          <Path
            data-name="Path 198757"
            d="M286.145 134.667H240v-21.76A20.908 20.908 0 01260.907 92h25.238z"
            transform="translate(-191.572 -69.334)"
          />
          <Path
            data-name="Path 198758"
            d="M337.681 92s15.27.959 20.7 16.095a27.37 27.37 0 011.56 9.248v99.3s-1.044 24.348 24.232 26.2H443.3V116.232a23.622 23.622 0 00-17.488-22.912 28.584 28.584 0 00-8.376-1.2z"
            transform="translate(-269.543 -69.334)"
            fill="#fff"
          />
          <Path
            data-name="Path 198759"
            d="M443.532 241.946h-59.455a27.63 27.63 0 01-14.606-4.935 22.875 22.875 0 01-7.241-9.038 30.588 30.588 0 01-2.665-12.545v-99.283a27 27 0 00-1.543-9.145 23.449 23.449 0 00-12.232-13.552 25.094 25.094 0 00-8.2-2.343l.019-.6 79.755.12h.044a28.861 28.861 0 018.42 1.213c9.552 2.911 13.858 9.066 15.789 13.718a24.237 24.237 0 011.917 9.5zm-59.422-.605h58.816V115.034a24.1 24.1 0 00-1.881-9.275c-1.883-4.523-6.083-10.509-15.4-13.347a28.254 28.254 0 00-8.244-1.187h-.044l-76.28-.115a27.463 27.463 0 014.968 1.79 24.043 24.043 0 0112.547 13.9 27.6 27.6 0 011.577 9.35v99.3a29.942 29.942 0 002.62 12.3c3.8 8.262 10.972 12.838 21.321 13.6"
            transform="translate(-269.468 -68.136)"
          />
          <Path
            data-name="Path 198760"
            d="M618.614 655.675v19.285a19.671 19.671 0 01-4.38 12.5 14.769 14.769 0 01-8.412 5.319h54.612a12.243 12.243 0 001.7-.117c3.65-.513 15.4-3.171 18.516-16.281a21.983 21.983 0 00.569-5.085v-15.621z"
            transform="translate(-483.577 -519.269)"
            fill="#fff"
          />
          <Path
            data-name="Path 198761"
            d="M648.912 691.881h-57.524l2.85-.6a14.392 14.392 0 008.241-5.215 19.4 19.4 0 004.312-12.305v-19.587H670v15.921a22.332 22.332 0 01-.578 5.155c-3.166 13.317-15.166 16-18.768 16.511a12.618 12.618 0 01-1.747.12m-52.488-.605h52.488a11.993 11.993 0 001.663-.114c3.507-.493 15.187-3.108 18.264-16.052a21.728 21.728 0 00.561-5.015v-15.316h-62v18.982a20.007 20.007 0 01-4.449 12.689 15.483 15.483 0 01-6.523 4.825"
            transform="translate(-472.057 -518.071)"
          />
          <Path
            data-name="Path 198762"
            d="M775.794 17.1A17.08 17.08 0 11758.729 0a17.08 17.08 0 0117.065 17.1"
            transform="translate(-591.986 4.103)"
            fill="#5bbe84"
          />
          <Path
            data-name="Path 198763"
            d="M1032.763 973l-1.369 8.087-.841 4.959h-7.7l.266-4.4.515-8.517z"
            transform="translate(-816.462 -772.564)"
            fill="#fff"
          />
          <Path
            data-name="Path 198764"
            d="M1029.536 985.131h-8.275l.818-13.52 9.771-.136zm-7.631-.605h7.12l2.107-12.435-8.482.118z"
            transform="translate(-815.189 -771.346)"
          />
          <Path
            data-name="Path 198765"
            d="M980.6 1023.976c-.673.7-3.415 3.012-7.956-.139a.13.13 0 00-.135-.008l-12.52 6.692a.638.638 0 00.3 1.2h20.431a1.468 1.468 0 001.432-1.791l-1.33-5.891a.129.129 0 00-.219-.062"
            transform="translate(-766.012 -813.124)"
          />
          <Path
            data-name="Path 198766"
            d="M1033.816 973l-1.369 8.087-8.272.557.515-8.517z"
            transform="translate(-817.515 -772.564)"
          />
          <Path
            data-name="Path 198767"
            d="M1192.924 986.045h-7.7l-.785-4.4-1.52-8.517 9.127-.127.544 8.114z"
            transform="translate(-944.23 -772.564)"
            fill="#fff"
          />
          <Path
            data-name="Path 198768"
            d="M1191.822 985.136h-8.275l-2.412-13.519 9.77-.136zm-7.768-.605h7.12l-.835-12.436-8.483.118z"
            transform="translate(-942.803 -771.351)"
          />
          <Path
            data-name="Path 198769"
            d="M1148.268 1023.976c-.673.7-3.415 3.012-7.957-.139a.13.13 0 00-.135-.008l-12.52 6.692a.638.638 0 00.3 1.2h20.428a1.468 1.468 0 001.432-1.791l-1.33-5.891a.129.129 0 00-.219-.062"
            transform="translate(-899.846 -813.124)"
          />
          <Path
            data-name="Path 198770"
            d="M1192.592 981.114l-8.151.531-1.52-8.517 9.127-.127z"
            transform="translate(-944.23 -772.564)"
          />
          <Path
            data-name="Path 198771"
            d="M950.175 217.33a1.615 1.615 0 01-2.3-.141L936.2 203.733l-.012-.032-1.444-3.632 3.537 1.98h.009l11.992 12.982a1.615 1.615 0 01-.1 2.295"
            transform="translate(-746.127 -155.596)"
            fill="#fff"
          />
          <Path
            data-name="Path 198772"
            d="M946.737 215.356h-.12a1.9 1.9 0 01-1.331-.657l-11.713-13.5-.031-.083-1.757-4.418 4.323 2.421.042.04 11.993 12.982a1.918 1.918 0 01-1.406 3.219M934.1 200.882l11.642 13.418a1.313 1.313 0 101.956-1.751L935.739 199.6l-2.761-1.546z"
            transform="translate(-743.768 -152.903)"
          />
          <Path
            data-name="Path 198773"
            d="M938.278 202.05a1.526 1.526 0 01-.725 1.275 1.942 1.942 0 01-1.368.377l-1.444-3.632z"
            transform="translate(-746.127 -155.597)"
          />
          <Path
            data-name="Path 198774"
            d="M1142.016 548.4s.9 5.752-.219 15.949c-1.169 10.61-2.974 22.645-2.974 22.645l4.534 52.046h-12.784l-7.8-50.441-3.61-42.322z"
            transform="translate(-893.339 -431.95)"
            fill="#fff"
          />
          <Path
            data-name="Path 198237 - Outline"
            d="M1116.97 544.069l23.643 2.2.049.31a37.549 37.549 0 01.316 4.2c.05 1.584.042 3.277-.023 5.032a94.661 94.661 0 01-.51 6.823c-1.119 10.162-2.844 21.774-2.969 22.613l4.568 52.437h-13.57l-.053-.342-7.8-50.455v-.014zm22.936 2.942l-22.05-2.049 3.568 41.827 7.741 50.086h12l-4.5-51.655.007-.047c.018-.12 1.818-12.149 2.972-22.629.255-2.311.425-4.581.506-6.749.065-1.734.073-3.407.024-4.971-.063-1.957-.199-3.256-.268-3.813z"
            transform="translate(-891.586 -430.183)"
          />
          <Path
            data-name="Path 198775"
            d="M1051.761 512.41l-19.8 48.422-9.955 50.441h-12.551l5.658-52.848 10.229-46.935z"
            transform="translate(-805.767 -404.179)"
            fill="#fff"
          />
          <Path
            data-name="Path 198776"
            d="M1020.926 610.345h-13.137l5.7-53.215 10.283-47.182 27.1.944-19.95 48.8zm-12.464-.605h11.966l9.912-50.226.011-.027 19.632-48.02-25.729-.9-10.172 46.672z"
            transform="translate(-804.436 -402.947)"
          />
          <Path
            data-name="Path 198777"
            d="M961.209 255.238l-2.411 1.885-.43.337-6.179 4.831a4.632 4.632 0 01-7.387-2.7l-6.168-26.111-2.634-6.156a1.869 1.869 0 01-.139-.944c.124-1.1.533-1.149.533-1.149a1.74 1.74 0 01.975-1.515 2.5 2.5 0 011.376-1.517 1.372 1.372 0 011.947-.793l-.17-1.992c-.434-2.161.722-2.664.722-2.664l2.2 4.683-.109 2.776 1.961 2.228-1.342 5.143 7.006 19.807 4.558-4.96 1.283-1.4z"
            transform="translate(-747.012 -168.911)"
            fill="#fff"
          />
          <Path
            data-name="Path 198778"
            d="M948.147 262.018a4.958 4.958 0 01-1.7-.3 4.905 4.905 0 01-3.136-3.622l-6.161-26.078-2.624-6.134a2.169 2.169 0 01-.161-1.1 1.759 1.759 0 01.552-1.314 2.045 2.045 0 011.025-1.539 2.961 2.961 0 011.381-1.529 1.586 1.586 0 011.835-.981l-.13-1.526c-.467-2.359.845-2.959.9-2.984l.27-.117 2.358 5.016-.107 2.729 1.988 2.258-1.359 5.209 6.807 19.245 5.817-6.329 4.682 10.849-9.2 7.2a4.91 4.91 0 01-3.031 1.051m-12.881-38.052c-.069.047-.232.275-.3.886a1.568 1.568 0 00.116.79l2.651 6.208 6.168 26.111a4.329 4.329 0 006.906 2.529l8.836-6.908-4.127-9.563-5.866 6.383-7.2-20.369 1.325-5.077-1.935-2.2.111-2.824-2.011-4.277a2.357 2.357 0 00-.311 2.136v.034l.216 2.532-.484-.244a1.283 1.283 0 00-.9-.064 1.164 1.164 0 00-.627.7l-.055.136-.141.04a2.247 2.247 0 00-1.181 1.346l-.05.116-.117.046a1.442 1.442 0 00-.784 1.258l-.019.247z"
            transform="translate(-745.815 -167.35)"
          />
          <Path
            data-name="Path 198779"
            d="M1105.235 209.223l-.362-5.415s-8.9 2.295-4.681-14.5c0 0 5.69-5.966 12.758 0v12.207l1.193 6.241z"
            transform="translate(-877.286 -144.889)"
            fill="#fff"
          />
          <Path
            data-name="Path 198780"
            d="M1103.74 208.43l-.362-5.408a4.726 4.726 0 01-4.009-1.24c-2.192-2.179-2.423-6.787-.686-13.7l.019-.077.055-.058c.058-.062 5.971-6.1 13.172-.022l.107.091v12.319l1.246 6.518zm.195-6.151l.365 5.445 8.272-1.364-1.14-5.993V188.3c-2.8-2.324-5.7-3.03-8.61-2.1a9.643 9.643 0 00-3.572 2.112c-2.063 8.262-.846 11.647.541 13.031a4.279 4.279 0 003.792 1.022z"
            transform="translate(-876.07 -143.743)"
          />
          <Path
            data-name="Path 198781"
            d="M1246.931 371.245l-14.149 14.635-.93.93.464 4.183-.928 3.487-1.394 1.626-.864-8.253v-4.879l2.258-1.975 7.179-13.13-1.6-1.657-2.964-3.075 4.185-9.657 4.03 5.232 5.047 6.552a4.583 4.583 0 01-.337 5.981"
            transform="translate(-981.114 -278.052)"
            fill="#fff"
          />
          <Path
            data-name="Path 198782"
            d="M1228.568 394.5l-.938-8.984V380.5l2.321-2.031 7.049-12.89-4.546-4.717 4.469-10.314 9.389 12.191a4.915 4.915 0 01-.359 6.376L1231.8 383.75l-.831.831.454 4.095-.96 3.608zm-.333-9l.789 7.538.892-1.04.9-3.365-.474-4.271 1.033-1.033 14.145-14.632a4.307 4.307 0 00.315-5.586l-8.763-11.377-3.9 9 4.575 4.747-7.312 13.372-2.2 1.92z"
            transform="translate(-979.917 -275.711)"
            fill="#191919"
          />
          <Path
            data-name="Path 198783"
            d="M1261.515 358.712l-5.25 7.5-2.964-3.075 4.185-9.657z"
            transform="translate(-1000.407 -278.052)"
          />
          <Path
            data-name="Path 198784"
            d="M1039.028 367.116l-2.411 1.884-3.277-10.694 1.283-1.4z"
            transform="translate(-824.831 -280.789)"
          />
          <Path
            data-name="Path 198785"
            d="M1077.806 306.431c-.062 1.7-.1 6.439-.051 12.01.071 7.92.325 17.521.956 22.44 0 0-14.464 3.344-23.943 2.29-7.264-.807-10.077-1.806-10.077-1.806l.018-.339 1.778-33.948-4.09 2.948-4.877-11.8 7.095-8.406a11.5 11.5 0 015.345-3.551l3.592-1.126.676-.454a1.991 1.991 0 011.39-.319 23.206 23.206 0 009.526-.739 1.971 1.971 0 011.818.381l.559.466 6.788 2.226a14.071 14.071 0 016.427 4.367l8.36 10.045-7.419 8.592-3.832-3.917c-.014.081-.026.3-.038.636"
            transform="translate(-828.167 -222.238)"
            fill="#e85b49"
          />
          <Path
            data-name="Path 198786"
            d="M1057.441 342.478a37.364 37.364 0 01-4.091-.2c-7.216-.8-10.029-1.78-10.145-1.821l-.213-.076.03-.565 1.745-33.317-3.888 2.8-5.094-12.32 7.213-8.547a11.78 11.78 0 015.486-3.645l3.55-1.113.64-.43a2.293 2.293 0 011.6-.367 22.994 22.994 0 009.4-.729 2.266 2.266 0 012.1.439l.514.429 6.733 2.208a14.359 14.359 0 016.565 4.461l8.524 10.242-7.8 9.035-3.584-3.663c-.061 1.726-.1 6.459-.048 11.942.053 6.009.249 16.91.954 22.4l.035.272-.267.062c-.125.029-10.938 2.5-19.955 2.5m-13.819-2.528a57.484 57.484 0 009.8 1.722c8.616.957 21.562-1.781 23.576-2.225-.682-5.606-.872-16.278-.925-22.2-.05-5.55-.012-10.338.051-12.024a5.869 5.869 0 01.043-.677l.1-.581 4.014 4.1 7.037-8.149-8.2-9.847a13.754 13.754 0 00-6.288-4.273l-6.843-2.244-.6-.5a1.663 1.663 0 00-1.539-.323 23.62 23.62 0 01-9.654.748 1.69 1.69 0 00-1.178.27l-.713.479-3.634 1.139a11.174 11.174 0 00-5.2 3.458l-6.976 8.265 4.66 11.272 4.292-3.094z"
            transform="translate(-826.783 -221.039)"
          />
          <Path
            data-name="Path 198787"
            d="M1108.947 162.5c.739-6.834-3.04-6.019-3.04-6.019-3.794-5.432-5.7-2.19-5.7-2.19-3.2-2.853-4.188-.1-4.188-.1-3.14-1.293-3.694 1.478-3.694 1.478a2.086 2.086 0 00-2.771 2.216c-1.108.185-1.335 1.627-1.293 2.216.265 3.711 2.908 4 4.38 3.864l.338 4.526a.666.666 0 001.32.061c.01-.059.019-.111.027-.147.321-1.606 2.937-2.845 3.3 0 0 0 .275 3.671-1.652 3.763a6.544 6.544 0 007.042 3.465c.82-.152 6.248-1.52 5.822-10.957a15.932 15.932 0 00.106-2.175"
            transform="translate(-868.666 -118.025)"
          />
          <Path
            data-name="Rectangle 26782"
            transform="rotate(-23.94 227.576 -418.023)"
            d="M0 0H0.403V1.987H0z"
          />
          <Path
            data-name="Rectangle 26783"
            transform="rotate(-23.94 224.276 -421.208)"
            d="M0 0H0.403V1.987H0z"
          />
          <Path
            data-name="Rectangle 26784"
            transform="rotate(-20.556 243.016 -501.338)"
            d="M0 0H0.404V1.724H0z"
          />
          <Rect
            data-name="Rectangle 26785"
            width={282}
            transform="translate(0 219)"
          />
        </G>
        <Path
          data-name="Path 198789"
          d="M629.154 340.629l3.755 4.016 8.52-8.033"
          fill="none"
          stroke="#00c17f"
          strokeLinecap="round"
          strokeWidth={2}
          transform="translate(-523.483 -236.001)"
        />
        <Path
          data-name="Path 198790"
          d="M651.27 339.439h17.833"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={1}
          transform="translate(-523.483 -236.001)"
        />
        <G data-name="Group 116391" fill="none" strokeLinecap="round">
          <Path
            data-name="Path 198788"
            d="M171.036 21.026l5.872 6.281 13.324-12.562"
            stroke="#f5f5f5"
            strokeWidth={3}
            transform="translate(-523.483 -236.001) translate(509.483 237.001)"
          />
          <G data-name="Group 115746">
            <Path
              data-name="Path 198674"
              d="M5954.518-279.176l3.755 4.016 8.52-8.033"
              stroke="#00c17f"
              strokeWidth={2}
              transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 326.016)"
            />
            <Path
              data-name="Path 198679"
              d="M5977.9-281.975h17.833"
              stroke="#000"
              strokeWidth={1}
              transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 326.016)"
            />
            <Path
              data-name="Path 198680"
              d="M5977.9-276.975h30.843"
              stroke="#000"
              strokeWidth={1}
              transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 326.016)"
            />
          </G>
          <G data-name="Group 115747">
            <G data-name="Group 115701" stroke="#e85b49">
              <Path
                data-name="Path 198675"
                d="M5955.056-234.175l8.668 8.332"
                strokeWidth={2}
                transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 328.016) translate(1.266 -32)"
              />
              <Path
                data-name="Path 198676"
                d="M5963.261-234.61l-7.75 9.201"
                strokeWidth={2.00104}
                transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 328.016) translate(1.266 -32)"
              />
            </G>
            <G data-name="Group 115731" stroke="#e85b49">
              <Path
                data-name="Path 198675"
                d="M5955.056-234.175l8.668 8.332"
                strokeWidth={2}
                transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 328.016) translate(2.266 26)"
              />
              <Path
                data-name="Path 198676"
                d="M5963.261-234.61l-7.75 9.201"
                strokeWidth={2.00104}
                transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 328.016) translate(2.266 26)"
              />
            </G>
            <Path
              data-name="Path 198682"
              d="M5977.9-264.509h17.833"
              stroke="#000"
              strokeWidth={1}
              transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 328.016)"
            />
            <Path
              data-name="Path 198681"
              d="M5977.9-259.509h30.843"
              stroke="#000"
              strokeWidth={1}
              transform="translate(-523.483 -236.001) translate(509.483 237.001) translate(-5836.112 328.016)"
            />
          </G>
        </G>
        <G data-name="Group 115748" fill="none" strokeLinecap="round">
          <Path
            data-name="Path 198677"
            d="M5955.784-244.842l3.755 4.016 8.52-8.033"
            stroke="#00c17f"
            strokeWidth={2}
            transform="translate(-523.483 -236.001) translate(-5326.63 567.017)"
          />
          <Path
            data-name="Path 198684"
            d="M5977.9-247.043h17.833"
            stroke="#000"
            strokeWidth={1}
            transform="translate(-523.483 -236.001) translate(-5326.63 567.017)"
          />
          <Path
            data-name="Path 198683"
            d="M5977.9-242.043h30.843"
            stroke="#000"
            strokeWidth={1}
            transform="translate(-523.483 -236.001) translate(-5326.63 567.017)"
          />
        </G>
        <Path
          data-name="Path 198791"
          d="M651.27 344.439h30.843"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={1}
          transform="translate(-523.483 -236.001)"
        />
        <G
          data-name="Group 115749"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={1}
        >
          <Path
            data-name="Path 198688"
            d="M5977.9-212.111h17.833"
            transform="translate(-523.483 -236.001) translate(-5326.63 570.811)"
          />
          <Path
            data-name="Path 198687"
            d="M5977.9-207.111h30.843"
            transform="translate(-523.483 -236.001) translate(-5326.63 570.811)"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SuccessSummary;
