import _ from 'lodash';
import {
  heightPercentageToDP,
  widthPercentageToDP
} from 'react-native-responsive-screen';

const VIEWPORT_HEIGHT = 828;
const VIEWPORT_WIDTH = 414;

export const heightPercentageToDPBoundsViewPort = (height: number) => {
  const percentage = (height / VIEWPORT_HEIGHT) * 100;
  const roundedPercentageHeight = _.ceil(percentage);
  return heightPercentageToDP(roundedPercentageHeight);
};

export const widthPercentageToDPBoundsViewPort = (width: number) => {
  const percentage = (width / VIEWPORT_WIDTH) * 100;
  const roundedPercentageWidth = _.ceil(percentage);
  return widthPercentageToDP(roundedPercentageWidth);
};
