import { TouchableOpacity, View, Text } from 'react-native';
import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import Popup from 'reactjs-popup';
import { Avatar } from '../avatar/avatar';
import _ from 'lodash';
import { titleTruncate } from '@zonofi/common';

interface CheckboxNavigateProps {
  title: string | JSX.Element;
  image: any;
  navigate?: () => void;
  isHyperLink?: boolean;
  alignment?: 'center' | 'flex-end' | 'flex-start';
  textOverflowLimit?: number;
  icon?: boolean;
  profilePic?: string;
}

interface NavigateProps {
  title: string;
  navigate?: () => void;
  isHyperLink?: boolean;
}

//TODO : REVAMPED
export const CheckboxNavigate: React.FC<CheckboxNavigateProps> = ({
  title,
  image,
  navigate,
  isHyperLink,
  alignment,
  textOverflowLimit = 12,
  icon,
  profilePic,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: alignment ? alignment : 'center',
      }}
    >
      {image}
      {icon && _.isString(title) && (
        <View style={{ marginRight: 10 }}>
          <Avatar title={title} profileUrl={profilePic} size={32} cfa={true} />
        </View>
      )}
      {_.isString(title) ? (
        title?.length < textOverflowLimit ? (
          <span
            onClick={() => navigate && navigate()}
            style={{
              fontFamily: theme.fontConfig.regular.fontFamily,
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              textDecoration: isHyperLink === false ? 'none' : 'underline',
              color:
                isHyperLink === false
                  ? theme.text.primary
                  : theme.table.underline,
            }}
            id="generic-table-cell-text"
          >
            {title}
          </span>
        ) : (
          <Popup
            trigger={
              <span
                style={{
                  fontFamily: theme.fontConfig.regular.fontFamily,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  textDecoration: 'underline',
                  color: theme.table.underline,
                  cursor: 'pointer',
                }}
                onClick={() => navigate && navigate()}
                id="generic-table-cell-text"
              >
                {titleTruncate(title ?? '-', textOverflowLimit ?? 12)}
              </span>
            }
            arrowStyle={{ color: 'transparent' }}
            on={['hover', 'focus']}
          >
            <TouchableOpacity
              style={{
                width: '100%',
                backgroundColor: theme?.menu?.background,
                padding: 10,
                borderRadius: 5,
                elevation: 3,
                shadowColor: '#00000029',
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowOpacity: 0.6,
                shadowRadius: 6,
              }}
              onPress={() => navigate && navigate()}
            >
              <span
                style={{
                  fontFamily: theme.fontConfig.regular.fontFamily,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  textDecoration: 'underline',
                  color: theme.table.underline,
                }}
                id="generic-table-cell-text"
              >
                {title}
              </span>
            </TouchableOpacity>
          </Popup>
        )
      ) : (
        title
      )}
    </View>
  );
};

export const CheckboxText: React.FC<CheckboxNavigateProps> = ({
  title,
  image,
  navigate,
  isHyperLink,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {image}
      {_.isString(title) ? (
        <Text
          style={{
            ...(isHyperLink
              ? {
                  color: theme.table.underline,
                  fontFamily: theme.fontConfig.regular.fontFamily,
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                }
              : {}),
          }}
          nativeID="generic-table-cell-text"
        >
          {title}
        </Text>
      ) : (
        title
      )}
    </View>
  );
};

export const NavigateLink: React.FC<NavigateProps> = ({
  title,
  navigate,
  isHyperLink,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text
        onPress={() => {
          navigate && navigate();
        }}
        style={{
          ...(isHyperLink
            ? {
                color: theme.table.underline,
                fontFamily: theme.fontConfig.regular.fontFamily,
                fontSize: 16,
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }
            : {
                color: theme.text.primary,
                fontFamily: theme.fontConfig.regular.fontFamily,
                fontSize: 16,
              }),
        }}
        nativeID="generic-table-cell-text"
      >
        {title}
      </Text>
    </View>
  );
};
