import React, { useContext, useRef, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import CustomPopUp from '../custom-pop-up/custom-pop-up';
import { ThemeContext } from '../contexts/ThemeContext';
import { CustomSvg } from '../custom-svg/custom-svg';

interface ProductDeletePopUpProps {
  handleClick: React.Dispatch<React.SetStateAction<boolean>>;
  SVGS: any;
}

export const ProductDeletePopUp: React.FC<ProductDeletePopUpProps> = ({
  handleClick,
  SVGS,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [openOptions, setOpenOptions] = useState(false);
  const deleteRef = useRef() as any;
  return (
    <CustomPopUp
      popupRef={deleteRef}
      icon={
        <View
          style={[
            {
              borderRadius: 4,
              marginLeft: 10,
              borderWidth: 1,
              borderColor: 'transparent',
              width: 30,
              height: 30,
            },
            openOptions && {
              ...theme.filterButton.selected,
            },
          ]}
        >
          <CustomSvg
            svg={SVGS?.ThreeDotsHorizontal}
            colors={{
              fill: openOptions
                ? theme.filterButton.selected.borderColor
                : theme.filter.text,
            }}
          />
        </View>
      }
      event="click"
      onOpen={() => setOpenOptions(true)}
      onClose={() => setOpenOptions(false)}
      arrow={false}
      color={theme.primary}
      position={['bottom right']}
      content={
        <TouchableOpacity
          style={{ flexDirection: 'row', padding: 16, alignItems: 'center' }}
          onPress={() => handleClick(true)}
        >
          <CustomSvg
            svg={SVGS?.DeleteIcon}
            colors={{ fill: theme.text.primary }}
          />
          <Text
            style={[
              { color: theme.text.primary, ...theme.fontConfig.semiBold },
              { fontSize: 16, lineHeight: 21, paddingLeft: 8 },
            ]}
          >
            Delete Products
          </Text>
        </TouchableOpacity>
      }
    />
  );
};
