import { TextField } from '@mui/material';
import React from 'react';


export const ZInputField: React.FC<{ id: string, label: string | number, value?: string | number, disabled?: boolean, editable?: boolean, }> = ({ id, label, value, disabled, editable }) => {
    return <TextField
        id={id}
        sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '8px',
                borderColor: '#d5d5d4',
              },
              '&:hover fieldset': {
                borderColor: '#999999',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#999999', // Change to your desired color
              },
            },
                '& .MuiOutlinedInput-input': {
                color: '#666666', // Default text color
                fontSize: '1rem', // Default font size
                '&.Mui-focused': {
                    color: '#111111', // Change to your desired text color
                    fontSize: '1.2rem', // Change to your desired focused font size
                },
                },
                '& .MuiInputLabel-root': {
                color: '#666666', // Default label color
                '&.Mui-focused': {
                    color: '#111111', // Change to your desired label color
                    fontSize: '1.2rem',
                },
            },
          }}
        label={label}
        value={value}
        disabled={disabled || editable}
        variant="outlined"
        InputProps={{
            sx: { borderRadius: '8px' },
        }}
    />
};
