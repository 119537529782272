import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SendReminder(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.425}
      height={13.615}
      viewBox="0 0 12.425 13.615"
      {...props}
    >
      <Path
        data-name="Path 198800"
        d="M83.459 313.248a.567.567 0 01.794.113 6.209 6.209 0 011.217 3.121.567.567 0 01-1.129.112 5.076 5.076 0 00-1-2.552.568.568 0 01.118-.794zm-8.4 0a.568.568 0 01.113.795 5.076 5.076 0 00-1 2.552.567.567 0 01-1.129-.112 6.209 6.209 0 011.217-3.121.567.567 0 01.803-.114zm.38 8.963a.567.567 0 01-.436-.93l.234-.281a5.1 5.1 0 001.183-3.268v-.626a2.836 2.836 0 115.673 0v.626A5.1 5.1 0 0083.281 321l.234.281a.567.567 0 01-.436.93zm2.683 1.135a1.135 1.135 0 002.269 0zM79.261 312a.567.567 0 01.567.567v.608a3.972 3.972 0 013.4 3.931v.626a3.972 3.972 0 00.92 2.542l.234.28a1.7 1.7 0 01-1.308 2.792h-1.543a2.269 2.269 0 11-4.538 0h-1.549a1.7 1.7 0 01-1.308-2.792l.234-.28a3.972 3.972 0 00.92-2.542v-.626a3.972 3.972 0 013.4-3.931v-.608a.567.567 0 01.571-.567z"
        transform="translate(-73.049 -312)"
        fill="#fff"
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default SendReminder;
