import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { ThemeContext, colors } from '@zonofi/shared';
import { getOrderCardVariant } from '@zonofi/zono-money-helpers';

/* eslint-disable-next-line */
export interface OrderSummaryProps {
  skuCount: string;
  schemeSavings: string;
  netOrderValue: string;
  tabs: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  status: string;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({
  skuCount,
  netOrderValue,
  schemeSavings,
  tabs,
  selectedTab,
  setSelectedTab,
  status,
}) => {
  const { theme } = useContext(ThemeContext);

  const variant = useMemo(() => getOrderCardVariant(status || ''), [status]);

  const data = [
    { header: 'SKU Count', value: skuCount },
    { header: 'Scheme Savings', value: schemeSavings, color: '#6BBE4E' },
    { header: 'Net Order Value', value: netOrderValue },
  ];

  return (
    <div className="summary-card" style={{ background: theme.primary }}>
      <div className="summary-card__header">
        <span>
          <span
            className="text"
            style={{ ...theme.fontConfig.semiBold, color: theme.text.primary }}
          >
            Order Details
          </span>
          <span className={`tag tag__${variant}`} style={theme.fontConfig.bold}>
            {variant}
          </span>
        </span>
      </div>
      <div className="item-container">
        {data.map(({ header, value, color }, index) => (
          <div className="item" key={index}>
            <div
              style={{
                ...theme.fontConfig.regular,
                color: colors.gray.primary,
              }}
            >
              {header}
            </div>
            <div
              style={{
                ...theme.fontConfig.regular,
                color: color || theme.text.primary,
              }}
            >
              {value}
            </div>
          </div>
        ))}
      </div>
      <div className="tab-navigator">
        {tabs.map((tab, index) => (
          <div
            className="tab"
            onClick={() => setSelectedTab(tab)}
            style={
              selectedTab === tab
                ? { borderBottom: `2px solid ${theme.secondary}` }
                : {}
            }
            key={index}
          >
            <div
              className={`tab__text`}
              style={{
                ...theme.fontConfig.regular,
                color:
                  selectedTab === tab ? theme.secondary : theme.text.primary,
              }}
            >
              {_.startCase(_.camelCase(tab))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderSummary;
