import appReducer from './AppReducer';
import { persistReducer, persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { zonoApi } from '../api';
import { userAuthSlice } from '../user-auth';
import { customerInviteSlice } from '../customer-invite/customer-invite.slice';
import { invoiceSlice } from '../invoice/invoice-slice';
import { fieldCollectionUserAuthSlice } from '../field-collection-user-auth/user-slice';
import { localAppStorage } from './local-storage';

const persistConfig = {
  key: 'root',
  storage: localAppStorage,
  whitelist: [
    'zonoAPI',
    userAuthSlice.name,
    customerInviteSlice.name,
    invoiceSlice.name,
    fieldCollectionUserAuthSlice.name,
  ],
};

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

const pReducer = persistReducer(persistConfig, rootReducer);

const reduxStore = configureStore({
  reducer: pReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(zonoApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(reduxStore);
export const store = reduxStore;
