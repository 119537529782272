import React, { useContext } from 'react';
import { View } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';
import Popup from 'reactjs-popup';

export interface HoverToolTipProps {
  icon: JSX.Element;
  content: string;
  color?: string;
}

export const HoverToolTip: React.FC<HoverToolTipProps> = ({
  icon,
  content,
  color,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <Popup
      trigger={icon}
      arrowStyle={{ color: 'transparent' }}
      on={['hover', 'focus']}
    >
      <View
        style={{
          width: '100%',
          backgroundColor: theme?.menu?.background,
          paddingVertical: 6,
          paddingHorizontal: 8,
          borderRadius: 5,
          elevation: 3,
          shadowColor: '#00000029',
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowOpacity: 0.6,
          shadowRadius: 6,
        }}
      >
        <span
          style={{
            fontFamily: theme.fontConfig.regular.fontFamily,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: 16,
            textOverflow: 'ellipsis',
            color: color ? color : theme.text.primary,
          }}
        >
          {content}
        </span>
      </View>
    </Popup>
  );
};

export default HoverToolTip;
