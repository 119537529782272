import { isWeb } from '../functions';
import { FontConfig } from './interface';

const fontsWeb: FontConfig = {
  bold: {
    fontFamily: 'Segoe-UI-Bold',
    fontWeight: '700' as const,
  },
  regular: {
    fontFamily: 'Segoe-UI',
    fontWeight: '500' as const,
  },
  semiBold: {
    fontFamily: 'Segoe-UI-SemiBold',
    fontWeight: '600' as const,
  },
  medium: {
    fontFamily: 'Segoe-UI-Bold',
    fontWeight: '400' as const,
  },
  light: {
    fontFamily: 'Segoe-UI',
    fontWeight: '300' as const,
  },
  black: {
    fontFamily: 'Segoe-UI-Bold',
    fontWeight: '900' as const,
  },
  italic: {
    fontFamily: 'Segoe-UI-Italic',
    fontWeight: '500' as const,
  },
};

const fontNative: FontConfig = {
  regular: {
    fontFamily: 'TTHoves-Regular',
  },
  bold: {
    fontFamily: 'TTHoves-Bold',
  },
  semiBold: {
    fontFamily: 'TTHoves-DemiBold',
  },
  medium: {
    fontFamily: 'TTHoves-Medium',
  },
  light: {
    fontFamily: 'TTHoves-Light',
  },
  black: {
    fontFamily: 'TTHoves-ExtraBold',
  },
  italic: {
    fontFamily: 'TTHoves-Regular',
    fontStyle: 'italic',
  },
};

export const fonts = isWeb() ? fontsWeb : fontNative;
