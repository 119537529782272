import moment from 'moment';
import { zonoApi } from '../api';
import {
  AddItemToActiveOrderRequest,
  AddItemToActiveOrderResponse,
  Audit,
  CheckoutOrder,
  DeletePoFileRequest,
  DeletePoFileResponse,
  OrderCheckoutRequest,
  OrderDetailsRequest,
  OrderDetailsResponse,
  OrderLogsRequest,
  OrderLogsResponse,
  OrderLogsTypes,
  PoOrderDetailsResponse,
  ProductMappingRequest,
  ProductMappingResponse,
  SuggestMappingRequest,
  SuggestedProducts,
  TrackPODetailsRequest,
  EditOrderRequest,
  EditOrderResponse,
  PoListOrderRequest,
  PoListOrderResponse,
  POFileDetailsByIdResponse,
  POFileDetailsByIdRequest,
} from './order-model';

export interface NotifyToBuyerRequest {
  sellerWorkspaceId: string;
  poFileId: string;
  customerId: string;
}

export const OrderApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrderDetails: builder.mutation<
      OrderDetailsResponse,
      OrderDetailsRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/details/${args.workspaceId}/${args.orderId}?includeInvoice=true&sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    listCart: builder.query<
      PoListOrderResponse,
      { body: PoListOrderRequest; workspaceId: string }
    >({
      query: (args) => ({
        url: `hub/commerce-v2/poFiles/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'GET',
        params: { ...args.body, random: Math.random() },
      }),
    }),
    getTrackPODetails: builder.mutation<
      PoOrderDetailsResponse,
      TrackPODetailsRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/pofiles/details/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args.body,
      }),
    }),
    getOrdersLogs: builder.mutation<
      { [key: string]: Audit[] },
      OrderLogsRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/order-logs?sellerWorkspaceId=${args.workspaceId}`,
        method: 'POST',
        body: args,
      }),
      transformResponse: (res: OrderLogsResponse[]) => {
        const formatTime = (inputDateString: string) => {
          const inputDate = new Date(inputDateString);
          const formattedTime = inputDate.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          });
          return formattedTime;
        };

        const audits = res
          ?.map((obj) => {
            const data = obj.data?.map((x) => {
              if (x?.action === OrderLogsTypes.REMAPPED) {
                return {
                  ...x,
                  sequence: obj.sequence,
                  productName: x.updatedFields?.productDetails?.name,
                  sku: x.updatedFields?.productDetails?.sku,
                  oldProductName: x.updatedFields?.oldProductDetails?.name,
                  oldSku: x.updatedFields?.oldProductDetails?.sku,
                };
              } else {
                return {
                  ...x,
                  sequence: obj.sequence,
                  productName:
                    obj.productDetails?.name ?? obj?.productDetails ?? '-',
                  sku: obj?.productDetails?.sku ?? '-',
                };
              }
            });
            return { ...obj, data: data };
          })
          ?.map((data) => data?.data)
          ?.flat()
          ?.sort(
            (a, b) =>
              new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime() ||
              parseInt(b.timestamp.slice(-3)) - parseInt(a.timestamp.slice(-3))
          )
          .map((c) => ({
            ...c,
            date: moment(new Date(c?.timestamp))
              .format('Do MMM, YYYY')
              ?.toUpperCase(),
            time: formatTime(c?.timestamp),
          }));
        const groupedAudits = audits.reduce((acc: any, current) => {
          const date = current.date;
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(current);
          return acc;
        }, {});

        return groupedAudits;
      },
    }),
    addItemToActiveOrder: builder.mutation<
      AddItemToActiveOrderResponse,
      AddItemToActiveOrderRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/orders/additemtoactiveorder/${args.sellerWorkspaceId}?sellerWorkspaceId=${args.sellerWorkspaceId}`,
        method: 'POST',
        body: args,
      }),
    }),
    deletePoFile: builder.mutation<DeletePoFileResponse, DeletePoFileRequest>({
      query: (args) => {
        const { workspaceId, poFileImportSource, ...otherParams } = args;
        return {
          url: `hub/commerce-v2/poFile/${workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'DELETE',
          body: otherParams,
        };
      },
    }),
    checkoutOrders: builder.mutation<
      { status: string; statusCode: number; orders: CheckoutOrder[] },
      OrderCheckoutRequest
    >({
      query(arg) {
        const { sellerWorkspaceId } = arg;
        return {
          url: `hub/commerce-v2/orders/checkout/${sellerWorkspaceId}?sellerWorkspaceId=${arg.sellerWorkspaceId}`,
          method: 'POST',
          body: arg,
        };
      },
    }),
    editOrder: builder.mutation<EditOrderResponse, EditOrderRequest>({
      query: (args) => {
        const { workspaceId, body } = args;
        return {
          url: `hub/commerce-v2/orders/edit/${workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    suggestedMapping: builder.mutation<
      SuggestedProducts[],
      SuggestMappingRequest
    >({
      query(arg) {
        const { workspaceId, ...params } = arg;
        return {
          url: `hub/commerce-v2/poFile/line/available-mapping/${workspaceId}?sellerWorkspaceId=${arg.workspaceId}`,
          method: 'GET',
          params: params,
        };
      },
    }),
    mapProduct: builder.mutation<ProductMappingResponse, ProductMappingRequest>(
      {
        query(arg) {
          const { inviteId, ...body } = arg;
          return {
            url: `/hub/commerce-v2/poFile/map/${inviteId}?sellerWorkspaceId=${arg.workspaceId}`,
            method: 'POST',
            body: body,
          };
        },
      }
    ),
    notifyToBuyer: builder.mutation<any, NotifyToBuyerRequest>({
      query(arg) {
        const { sellerWorkspaceId, ...body } = arg;
        return {
          url: `hub/commerce-v2/orders/notify-to-buyer/${sellerWorkspaceId}?sellerWorkspaceId=${arg.sellerWorkspaceId}`,
          method: 'POST',
          body,
        };
      },
    }),

    // Works for manual POs as well
    poFileDetailsById: builder.query<
      POFileDetailsByIdResponse,
      POFileDetailsByIdRequest
    >({
      query(arg) {
        return {
          url: `/hub/commerce-v2/poFile/${arg?.poFileId}/${arg?.workspaceId}`,
          method: 'GET',
          params: arg?.params
        };
      },
    }),
  }),
});

export const {
  useGetOrderDetailsMutation,
  useGetTrackPODetailsMutation,
  useGetOrdersLogsMutation,
  useAddItemToActiveOrderMutation,
  useDeletePoFileMutation,
  useCheckoutOrdersMutation,
  useEditOrderMutation,
  useSuggestedMappingMutation,
  useMapProductMutation,
  useNotifyToBuyerMutation,
  useLazyListCartQuery,
  useLazyPoFileDetailsByIdQuery,
} = OrderApi;
