import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function HomeIcon({ color = '#a1a3b4', width = 21.243, height = 22.979 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.243 22.979"
    >
      <Path
        data-name="Subtraction 10"
        d="M16.308 22.979H4.936a2.5 2.5 0 01-2.584-2.393v-9.653h-.794a1.534 1.534 0 01-1.467-.961 1.345 1.345 0 01.391-1.508L9.549.402a1.6 1.6 0 011.074-.4 1.607 1.607 0 011.076.4l9.065 8.063a1.345 1.345 0 01.391 1.508 1.532 1.532 0 01-1.465.961h-.8v9.653a2.5 2.5 0 01-2.582 2.392zM10.708 12a3 3 0 00-3 3 3 3 0 003 3 3 3 0 003-3 3 3 0 00-3-3z"
        fill={color}
      />
    </Svg>
  );
}

export default HomeIcon;
