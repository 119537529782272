import * as React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';

function Correct({ width = '14', height = '14' }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <G data-name="Group 115956" transform="translate(-1061.999 -86)">
        <Circle
          data-name="Ellipse 2059"
          cx={6.843}
          cy={6.843}
          r={6.843}
          transform="translate(1062 86)"
          fill="#1d1e26"
        />
        <G data-name="22 Check">
          <Path
            data-name="Path 17"
            d="M833.841 3346.67a7 7 0 107 7 7 7 0 00-7-7zm3.182 5.662l-3.5 3.5a.577.577 0 01-.822 0l-2.042-2.042a.582.582 0 01.823-.823l1.63 1.628 3.089-3.085a.582.582 0 01.823.823z"
            transform="translate(1061.999 86) translate(-826.841 -3346.67)"
            fill="#87e2bd"
          />
        </G>
      </G>
    </Svg>
  );
}

export default Correct;
