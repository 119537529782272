/* eslint-disable-next-line */
import React, { useContext } from 'react';
import { Avatar } from '../avatar/avatar';
import { ThemeContext } from '../contexts/ThemeContext';
import Icon from 'react-native-easy-icon';
import Popup from 'reactjs-popup';
import { View, Text, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import { titleTruncate } from '@zonofi/common';
import { SHARED_SVGS } from '../assets';
export interface AvatarNameStatusProps {
  companyName: JSX.Element | string;
  name?: string;
  profilePic: string;
  status: string;
  onClick: () => void;
  textStyle?: React.CSSProperties;
  textOverflowLimit?: number;
  isHyperLink?: boolean;
  swapIcon?: boolean;
  rootStyle?: React.CSSProperties;
  customStatus?: string;
  showStatus?: boolean;
}

export const AvatarNameStatus: React.FC<AvatarNameStatusProps> = ({
  companyName,
  name,
  profilePic,
  status,
  onClick,
  textStyle,
  textOverflowLimit,
  isHyperLink,
  swapIcon,
  rootStyle,
  customStatus,
  showStatus = true,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const renderContext = (color: string, status: string, icon: any[]) => {
    return swapIcon ? (
      <div
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          minWidth: '100px',
        }}
      >
        {icon?.length > 0 && (
          <Icon type={icon[0]} name={icon[1]} size={14} color={color} />
        )}
        <span
          style={{
            fontSize: 12,
            fontFamily: theme.fontConfig.regular.fontFamily,
            color: color,
            marginLeft: 5,
          }}
        >
          {status}
        </span>
      </div>
    ) : (
      <div style={{ flexDirection: 'row', alignItems: 'center' }}>
        <span
          style={{
            fontSize: 12,
            fontFamily: theme.fontConfig.regular.fontFamily,
            color: color,
            marginRight: 5,
          }}
        >
          {status}
        </span>
        {icon?.length > 0 && (
          <Icon type={icon[0]} name={icon[1]} size={10} color={color} />
        )}
      </div>
    );
  };

  const getStatus = (status: string) => {
    switch (status) {
      case 'P':
        return 'Invited';
      case 'N':
        return 'Not Invited';
      case 'A':
        return 'Accepted';
      case 'missing':
        return 'Details Missing';
      default:
        return '';
    }
  };

  const renderStatus = (value: string) => {
    const status = getStatus(value);
    switch (value) {
      case 'P':
        return renderContext('#F39C26', status, ['entypo', 'back-in-time']);
      case 'N':
        return renderContext(theme.text.primary, status, []);
      case 'A':
        return renderContext('#4F9E52', status, ['antdesign', 'checkcircle']);
      case 'missing':
        return renderContext('#C93532', status, ['foundation', 'alert']);
      default:
        return '';
    }
  };

  return (
    <div
      style={
        rootStyle
          ? rootStyle
          : {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: 5,
              paddingTop: 5,
            }
      }
    >
      {companyName && _.isString(companyName) ? (
        <>
          <span style={{ textDecoration: 'none', marginRight: 12 }}>
            <Avatar
              title={companyName}
              cfa={true}
              profileUrl={profilePic}
              size={32}
            />
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {companyName?.length < (textOverflowLimit ?? 12) ? (
              <span
                onClick={() => onClick()}
                style={{
                  fontFamily: theme.fontConfig.regular.fontFamily,
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: isHyperLink === false ? 'none' : 'underline',
                  cursor: isHyperLink === false ? 'default' : 'pointer',
                  color:
                    isHyperLink === false
                      ? theme.text.primary
                      : theme.table.underline,
                  ...(textStyle ? textStyle : {}),
                }}
                id="generic-table-cell-text"
              >
                {companyName}
                {status === 'M' && (
                  <img
                    src={SHARED_SVGS.MiscTagForCustomer}
                    style={{ marginLeft: 8 }}
                    alt="misc"
                  />
                )}
              </span>
            ) : (
              <Popup
                trigger={
                  <span
                    style={{
                      fontFamily: theme.fontConfig.regular.fontFamily,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      textDecoration: 'underline',
                      color: theme.table.underline,
                      cursor: 'pointer',
                      ...(textStyle ? textStyle : {}),
                    }}
                    onClick={() => onClick()}
                    id="generic-table-cell-text"
                  >
                    {titleTruncate(companyName ?? '-', textOverflowLimit ?? 12)}
                  </span>
                }
                arrowStyle={{ color: 'transparent' }}
                on={['hover', 'focus']}
              >
                <TouchableOpacity
                  style={{
                    width: '100%',
                    backgroundColor: theme?.menu?.background,
                    padding: 10,
                    borderRadius: 5,
                    elevation: 3,
                    shadowColor: '#00000029',
                    shadowOffset: {
                      width: 0,
                      height: 3,
                    },
                    shadowOpacity: 0.6,
                    shadowRadius: 6,
                  }}
                  onPress={() => onClick()}
                >
                  <span
                    style={{
                      fontFamily: theme.fontConfig.regular.fontFamily,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      textDecoration: 'underline',
                      color: theme.table.underline,
                      cursor: 'pointer',
                      ...(textStyle ? textStyle : {}),
                    }}
                    id="generic-table-cell-text"
                  >
                    {companyName}
                  </span>
                </TouchableOpacity>
              </Popup>
            )}
            {showStatus && ['P', 'N', 'A', 'missing'].includes(status) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 4,
                }}
              >
                {renderStatus(status)}
              </div>
            )}
            {name && (
              <p
                style={{
                  color: '#758195',
                  ...theme.fontConfig.regular,
                  fontSize: 12,
                  margin: 1,
                }}
              >
                {name}
              </p>
            )}
          </div>
        </>
      ) : (
        companyName && (
          <>
            <span style={{ textDecoration: 'none', marginRight: 12 }}>
              <Avatar
                title={'NA'}
                cfa={true}
                profileUrl={profilePic}
                size={32}
              />
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {companyName}
              <p
                style={{
                  color: '#758195',
                  ...theme.fontConfig.regular,
                  fontSize: 12,
                  margin: 1,
                }}
              >
                {name}
              </p>
            </div>
          </>
        )
      )}

      {!_.isUndefined(customStatus) && (
        <div
          style={{
            backgroundColor: '#E4D9FF',
            borderRadius: 4,
            height: 18,
            width: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 4,
            marginTop: 3,
          }}
        >
          <p
            style={{
              color: '#4600FF',
              ...theme.fontConfig.regular,
              fontSize: 12,
            }}
          >
            {customStatus}
          </p>
        </div>
      )}
    </div>
  );
};

export default AvatarNameStatus;
