import { InvoiceLine } from '@zonofi/zono-money-store';
import React, { useContext } from 'react';
import './invoice-lines-list.scss';
import { ThemeContext } from '@zonofi/shared';
import { ProductCard } from './product-card';

interface InvoiceLinesListProps {
  lines: InvoiceLine[];
}

export const InvoiceLinesList: React.FC<InvoiceLinesListProps> = ({
  lines,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="invoice-lines-container">
      <div
        style={{
          ...theme.fontConfig.semiBold,
          color: theme.text.primary,
          fontSize: 14,
          lineHeight: '28px',
        }}
      >
        Invoiced Products
      </div>
      {lines?.map((line, index) => (
        <ProductCard line={line} key={index} />
      ))}
    </div>
  );
};
