import { useLazyGetPaymentQuery } from '@zonofi/zono-money-store';
import React from 'react';
import { PaymentRequestStatusEnum } from '../types/enum';

export const usePaymentVerificationTimer = (
  navigate: (status: PaymentRequestStatusEnum) => void,
  paymentRequestId: string | null
) => {
  const [timerCompleted, setTimerCompleted] = React.useState(false);
  const [getPayment, paymentResponse] = useLazyGetPaymentQuery();

  const onTimerComplete = () => {
    setTimerCompleted(true);
  };

  const onPaymentSuccess = (id: string, status: string) => {
    switch (status) {
      case PaymentRequestStatusEnum.SUCCESS:
        navigate(PaymentRequestStatusEnum.SUCCESS);
        break;
      case PaymentRequestStatusEnum.PENDING:
        if (timerCompleted) {
          navigate(PaymentRequestStatusEnum.FAILED);
        } else {
          getPayment(id);
        }
        break;
      default:
        navigate(PaymentRequestStatusEnum.FAILED);
        break;
    }
  };

  const getPaymentRequest = () => {
    if (paymentResponse.isSuccess && paymentResponse.data && paymentRequestId) {
      onPaymentSuccess(paymentRequestId, paymentResponse.data.status);
    } else if (paymentResponse.isError) {
      navigate(PaymentRequestStatusEnum.FAILED);
    } else {
      if (paymentRequestId) {
        getPayment(paymentRequestId);
      }
    }
  };

  return {
    getPaymentRequest,
    onTimerComplete,
    paymentResponse,
  };
};
