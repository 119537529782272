const ALLOWED_MIME_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'text/plain',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/pdf',
];

export const checkIsValidFile = (
  file: File,
  allowedMimeTypes = ALLOWED_MIME_TYPES
) => {
  if (!allowedMimeTypes.includes(file.type)) return false;
  const fileNameParts = file.name.split('.');
  if (fileNameParts.length >= 2) return true;
  const regex = /^(?=.*[0-9\s/-])[a-zA-Z0-9\s/-]+$/;
  const valid = regex.test(fileNameParts[fileNameParts.length - 2]);
  return valid;
};
