import { Global } from '@emotion/react';
import { Box, Button } from '@mui/material';
import { getFormattedAmount } from '@zonofi/common';
import { ThemeContext, Tooltip } from '@zonofi/shared';
import { SVGS } from '@zonofi/zono-money-design-system';
import { OrderData } from '@zonofi/zono-money-helpers';
import { ProductVariant } from '@zonofi/zono-money-store';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AddMoreProductsDrawer } from './add-more-drawer';

type Props = {
  orderData: OrderData;
  onIncrement: (id: any) => void;
  onDecrement: (id: any) => void;
  onConfirm: () => void;
  onDelete: (id: any) => void;
  handleAddProductFromSearch: (id, moq) => void;
  handleReplaceProductFromSearch: (id) => void;
  setSelectedOrderLineId: (id) => void;
  onChangeQuantity: (id: any, qty: any) => void;
  isEditing: boolean;
  showProductImage: boolean;
  showPrice: boolean;
  onValueChange: (id: any, qty: any) => void;
};

// Drawer Find Product start

const OrderEditScreenLayout: React.FC<Props> = ({
  orderData,
  handleAddProductFromSearch,
  handleReplaceProductFromSearch,
  onChangeQuantity,
  onConfirm,
  onDecrement,
  onIncrement,
  onDelete,
  onValueChange,
  setSelectedOrderLineId,
  isEditing,
  showProductImage,
  showPrice,
}) => {
  const { theme } = useContext(ThemeContext);
  const [params] = useSearchParams();
  const fromScreen = params?.get('from');

  // Drawer Find Product start
  // const { window } = props;
  const [open, setOpen] = useState(false);
  const [isSearchDrawer, setIsSearchDrawer] = useState(false);
  const [quantityMap, setQuantityMap] = useState({});

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    setIsSearchDrawer(false);
  };

  const toggleSearchDrawer = (newOpen: boolean) => () => {
    setIsSearchDrawer(true);
    setOpen(newOpen);
  };
  // This is used only for the example
  // const container = window !== undefined ? () => window().document.body : undefined;
  // Drawer Find Product end

  const showAddMore = useMemo(() => {
    if (fromScreen && fromScreen === 'price-list') {
      return false;
    }
    return true;
  }, [fromScreen]);

  React.useEffect(() => {
    return () => {
      console.log('unmounting order edit screen layout');
    };
  }, []);

  const getModifiedImageUrl = (url: string) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('width', '65');
      urlObj.searchParams.set('height', '65');
      return urlObj.toString();
    } catch {
      return url;
    }
  };

  useEffect(() => {
    let quantityMapData = {};
    orderData?.lines?.forEach((item) => {
      if (item?.poFileLineId) {
        quantityMapData[item?.poFileLineId] = item?.quantity;
      } else {
        quantityMapData[item?.orderLineId] = item?.quantity;
      }
    });
    setQuantityMap({ ...quantityMapData });
  }, [orderData]);

  return (
    <div
      className="order_screen"
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: '#F1F2F4',
        ...theme.fontConfig.regular,
      }}
    >
      <div
        id="ordersummary_top"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 14px',
          background: '#fff',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 6,
          }}
        >
          <span
            style={{
              padding: '0 0 0px',
              margin: 0,
              fontSize: '16px',
              lineHeight: '20px',
              color: '#091E42',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Requirement Summary
          </span>
          {orderData?.status?.includes('Submitted') ? (
            <span
              style={{
                background: '#D6EAED',
                color: '#0067FF',
                borderRadius: '5px',
                padding: '3px 7px',
                fontSize: '12px',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              {orderData?.status || '-'}
            </span>
          ) : (
            <p
              style={{
                background: '#EAEAEA',
                borderRadius: '5px',
                boxShadow: 'none',
                border: 'none',
                padding: '3px 7px',
                margin: 0,
                color: '#555555',
                fontSize: '12px',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              Draft
            </p>
          )}
        </div>
        <img src={SVGS.ZotokLogoGreen} />
      </div>
      {!showPrice && (
        <div
          style={{
            display: 'flex',
            padding: 15,
            background: '#DBE9FF',
            color: '#00398C',
            gap: 5,
            alignItems: 'flex-start',
          }}
        >
          <img src={SVGS.InfoIcon} />
          <span
            style={{
              fontSize: 12,
              letterSpacing: 0.3,
              lineHeight: '1.3',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Estimate for your requirement will be available after the
            confirmation!
          </span>
        </div>
      )}
      <div
        id="ordersummary_main_section"
        style={{
          width: '100%',
          height: '100%',
          padding: '0px 14px',
          marginTop: '14px',
          marginBottom: '45px',
          boxSizing: 'border-box',
        }}
      >
        <div
          id="itemorder_section"
          style={{
            background: '#fff',
            borderRadius: '8px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '14px 16px',
              borderBottom: '1px solid #F1F2F4',
            }}
          >
            <span
              style={{
                fontSize: '18px',
                color: '#091E42',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              {orderData?.lines?.length} items
            </span>
            {showAddMore && (
              <span
                style={{
                  fontSize: '15px',
                  color: '#32A7E8',
                  ...theme.fontConfig.condensedSemiBold,
                }}
                onClick={toggleSearchDrawer(true)}
              >
                + Add More
              </span>
            )}
          </div>
          <div
            id="ordersummary_itemlist"
            style={{ padding: '0px 0 8px', margin: '0px' }}
          >
            {orderData?.lines?.map((lineItem) => (
              <div
                key={lineItem?.title}
                style={{
                  borderBottom: '1px solid #F1F2F4',
                  padding: '14px 16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                    }}
                  >
                    {showProductImage && (
                      <img
                        src={
                          lineItem?.productImage
                            ? getModifiedImageUrl(lineItem?.productImage)
                            : SVGS.DefaultProduct
                        }
                        className="line-item-default-img"
                        style={{
                          width: 65,
                          height: 65,
                        }}
                        alt=""
                        onError={(e) => {
                          e.currentTarget.onerror = null;
                          e.currentTarget.src = SVGS.DefaultProduct;
                        }}
                      />
                    )}
                    <div>
                      <span
                        style={{
                          fontSize: 12,
                          color: '#8590A2',
                          fontWeight: 600,
                          textTransform: 'uppercase',
                        }}
                      >
                        SKU: {lineItem?.skuCode}
                      </span>
                      <h4
                        style={{
                          padding: '0 0 6px',
                          margin: 0,
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#091E42',
                          ...theme.fontConfig.condensedSemiBold,
                        }}
                      >
                        {lineItem?.title || '-'}
                      </h4>
                      {showPrice && (
                        <div
                          style={{
                            display: 'flex',
                            gap: 5,
                            alignItems: 'center',
                            paddingBottom: 5,
                          }}
                        >
                          <span
                            className=""
                            style={{
                              color: '#8590a2',
                              ...theme.fontConfig.condensedSemiBold,
                              fontSize: 13,
                            }}
                          >
                            Price:
                          </span>

                          <span
                            style={{
                              color:
                                lineItem?.newPrice !== lineItem?.price
                                  ? '#8590a2'
                                  : '#091E42',
                              ...theme.fontConfig.condensedSemiBold,
                              fontSize: 14,
                              textDecorationLine:
                                lineItem?.newPrice !== lineItem?.price
                                  ? 'line-through'
                                  : 'none',
                            }}
                          >
                            {getFormattedAmount(lineItem?.price)}
                          </span>
                          {lineItem?.newPrice !== lineItem?.price && (
                            <span
                              style={{
                                color: '#091E42',
                                ...theme.fontConfig.condensedSemiBold,
                                fontSize: 14,
                              }}
                            >
                              {getFormattedAmount(lineItem?.newPrice)}
                            </span>
                          )}
                        </div>
                      )}
                      {lineItem?.promotionIds &&
                        lineItem?.promotionIds?.length !== 0 && (
                          <div style={{ display: 'flex' }}>
                            <div
                              style={{
                                background: '#d3f7c7',
                                padding: '2px 6px',
                                margin: '0 0 2px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '5px',
                                borderRadius: '3px',
                              }}
                            >
                              {/* <img height={10} src={SVGS.DiscountIconFindProduct} /> */}
                              <span
                                style={{
                                  fontSize: '10px',
                                  color: '#6bbe4d',
                                  textTransform: 'none',
                                  ...theme.fontConfig.condensedSemiBold,
                                }}
                              >
                                Offer Applied
                              </span>
                            </div>
                          </div>
                        )}
                      {lineItem?.skuCode === '-' && (
                        <span
                          style={theme.fontConfig.regular}
                          className="catalog_moq_error"
                        >
                          Product not found
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '5px',
                      opacity: isEditing ? 0.5 : 1,
                    }}
                  >
                    {false && (
                      <Box sx={{ textAlign: 'center' }}>
                        <Tooltip title="Replace" arrow>
                          <Button
                            style={{ width: '16px', minWidth: '16px' }}
                            onClick={() => {
                              toggleDrawer(true);
                              setOpen(true);
                              setSelectedOrderLineId(lineItem?.orderLineId);
                            }}
                          >
                            <img height={16} width={16} src={SVGS.Replace} />
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                    <div
                      style={{
                        border: '1px solid #000',
                        borderRadius: '16px',
                        display: 'flex',
                        visibility: lineItem?.productVariantId
                          ? 'visible'
                          : 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '2px',
                        padding: '0px 4px',
                        width: '70px',
                        height: '28px',
                        lineHeight: '28px',
                      }}
                    >
                      <Button
                        disableRipple
                        disableFocusRipple
                        disableElevation
                        sx={{
                          all: 'unset',
                          cursor: 'pointer',
                          textAlign: 'center',
                          width: '12px',
                          height: '28px',
                          lineHeight: '32px',
                          background: 'none',
                          boxShadow: 'none',
                          border: 0,
                          padding: 0,
                          margin: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={() =>
                          isEditing
                            ? {}
                            : onDecrement(lineItem?.productVariantId)
                        }
                      >
                        <img height={12} width={12} src={SVGS.MinusIcon} />
                      </Button>
                      <input
                        style={{
                          minWidth: '22px',
                          fontSize: '14px',
                          textAlign: 'center',
                          border: 0,
                        }}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*[.,]?[0-9]+"
                        key={lineItem?.poFileLineId || lineItem?.orderLineId}
                        onKeyDown={(e) => {
                          e.key === 'Enter' &&
                            onChangeQuantity(
                              lineItem?.productVariantId,
                              quantityMap[
                                lineItem?.poFileLineId || lineItem?.orderLineId
                              ]
                            );
                        }}
                        onBlur={() =>
                          onChangeQuantity(
                            lineItem?.productVariantId,
                            quantityMap[
                              lineItem?.poFileLineId || lineItem?.orderLineId
                            ]
                          )
                        }
                        // on={(e) => {
                        //   e.target.value &&
                        //     onChangeQuantity(
                        //       lineItem?.productVariantId,
                        //       Number(e.target.value)
                        //     );
                        // }}
                        onChange={(e) => {
                          if (!isNaN(Number(e.target.value))) {
                            let initialQuantiyMap = quantityMap;
                            if (lineItem?.poFileLineId) {
                              initialQuantiyMap[lineItem?.poFileLineId] =
                                Number(e.target.value);
                            } else {
                              initialQuantiyMap[lineItem?.orderLineId] = Number(
                                e.target.value
                              );
                            }
                            setQuantityMap({ ...initialQuantiyMap });
                          }
                        }}
                        value={
                          quantityMap[
                            lineItem?.poFileLineId || lineItem?.orderLineId
                          ]
                        }
                      ></input>
                      <Button
                        disableRipple
                        disableFocusRipple
                        disableElevation
                        sx={{
                          all: 'unset',
                          cursor: 'pointer',
                          textAlign: 'center',
                          width: '12px',
                          height: '28px',
                          lineHeight: '32px',
                          background: 'none',
                          boxShadow: 'none',
                          border: 0,
                          padding: 0,
                          margin: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={() =>
                          isEditing
                            ? {}
                            : onIncrement(lineItem?.productVariantId)
                        }
                      >
                        <img height={12} width={10} src={SVGS.PlusIcon} />
                      </Button>
                    </div>
                    <img
                      src={SVGS.DeleteIcon}
                      onClick={() =>
                        isEditing ? {} : onDelete(lineItem?.productVariantId)
                      }
                    />
                  </div>
                </div>
                {lineItem?.moqError && (
                  <span
                    style={theme.fontConfig.regular}
                    className="catalog_moq_error"
                  >
                    Qty cannot be less than MOQ
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
        <div id="findproduct_drawer">
          {open && (
            <Global
              styles={{
                '.MuiDrawer-root > .MuiPaper-root': {
                  height: `calc(94% - 100px)`,
                  overflow: 'visible',
                  ...theme.fontConfig.regular,
                },
              }}
            />
          )}

          <AddMoreProductsDrawer
            open={open}
            toggleDrawer={toggleDrawer}
            isSearchDrawer={isSearchDrawer}
            orderData={orderData}
            handleAddProductFromSearch={handleAddProductFromSearch}
            handleReplaceProductFromSearch={handleReplaceProductFromSearch}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            isEditing={isEditing}
            onValueChange={onValueChange}
          />
        </div>

        <div
          id="bill_details"
          style={{
            background: '#fff',
            borderRadius: '8px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <h4
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '17px',
              color: '#091E42',
              borderBottom: '1px solid #F1F2F4',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Requirement
          </h4>
          <div
            id="billdetails_list"
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '14px',
              color: '#091E42',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: '0px 0 10px',
                margin: '0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 8,
                  flex: 1,
                  alignItems: 'center',
                }}
              >
                <span style={{ whiteSpace: 'nowrap' }}>Total Items</span>
                <div
                  style={{
                    borderBottom: '1px dashed #acacac',
                    flex: 1,
                    height: 1,
                  }}
                />
              </div>
              <span style={{ minWidth: 30, textAlign: 'right' }}>
                {orderData?.lines?.length < 10
                  ? orderData?.lines?.length.toString().padStart(2, '0')
                  : orderData?.lines?.length}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: '0px 0 10px',
                margin: '0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 8,
                  flex: 1,
                  alignItems: 'center',
                }}
              >
                <span style={{ whiteSpace: 'nowrap' }}>Total Quantity</span>
                <div
                  style={{
                    borderBottom: '1px dashed #acacac',
                    flex: 1,
                    height: 1,
                  }}
                />
              </div>
              <span style={{ minWidth: 30, textAlign: 'right' }}>
                {orderData?.totalQuantity < 10
                  ? orderData?.totalQuantity?.toString().padStart(2, '0')
                  : orderData?.totalQuantity}
              </span>
            </div>
          </div>
        </div>

        <div
          id="bottom_sendorder_section"
          style={{
            position: 'fixed',
            bottom: 15,
            zIndex: 1,
            width: 'calc(100% - 28px)',
            height: '52px',
            padding: '0px',
            margin: '0px',
          }}
          onClick={onConfirm}
        >
          <Button
            disableRipple
            disableFocusRipple
            disableElevation
            sx={{
              all: 'unset',
              cursor: 'pointer',
              textAlign: 'center',
              background: '#000',
              boxShadow: 'none',
              border: 0,
              padding: 0,
              margin: 0,
              fontFamily: `${theme.fontConfig.condensedSemiBold} !important`,
              fontSize: '16px',
              color: '#fff',
              lineHeight: '52px',
              width: '100%',
              borderRadius: '10px',
              fontWeight: 600,
            }}
          >
            {orderData?.importSource === 'manual'
              ? 'Submit Order'
              : 'Get Estimate'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderEditScreenLayout;
