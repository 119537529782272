export const PHARMA_COMMUNICATION_ROUTES = {
  // COMMUNICATION APP
  COMMUNICATION_BASE_ROUTE: '/admin/communication',
  CAMPAIGNS_ROUTE: '/admin/campaigns',
  CONVERSATION_SCREEN: '/channels',
  CHANNELS_DETAILS_SCREEN: '/channels/channelDetails',
  THREAD_DETAILS_SCREEN: '/threads/threadDetails',
  THREADS_SCREEN: '/threads',
  CREATE_CHANNEL_SCREEN: '/create-channel',
  CLAIM_DETAILS_SCREEN: '/claim/details',
  ORDER_DETAILS_SCREEN: '/order/details',
};
