import { ThemeContext } from '@zonofi/shared';
import { debounce } from 'lodash';
import React from 'react';
import './common.scss';

interface SearchBarProps {
  setSearchKey: (val: string) => void;
  delay?: number;
  placeholder?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  setSearchKey,
  delay = 0,
  placeholder,
}) => {
  const { theme } = React.useContext(ThemeContext);

  const onSearchHandler = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
  }, delay);

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className="search-bar">
      <input
        type="text"
        onChange={onSearchHandler}
        placeholder={placeholder}
        autoComplete="off"
        ref={searchInputRef}
        style={{
          ...theme.fontConfig.regular,
          color: theme.text.primary,
          backgroundColor: theme.primary,
        }}
      />
    </div>
  );
};
