import { Button, SwipeableDrawer, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { getFormattedAmount } from '@zonofi/common';
import { ThemeContext } from '@zonofi/shared';
import { LoadingSpinner, SVGS } from '@zonofi/zono-money-design-system';
import React, {
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  ProductVariant,
  getCustomerInvite,
  useGetProductsMutation,
} from '@zonofi/zono-money-store';
import { OrderData, useAppSelector } from '@zonofi/zono-money-helpers';

interface ProductsList extends ProductVariant {
  updatingQty?: boolean;
}

interface AddMoreProductsDrawerProps {
  open: boolean;
  toggleDrawer: (val: boolean) => any;
  isSearchDrawer: boolean;
  orderData: OrderData;
  handleAddProductFromSearch: (id: any, moq: any) => any;
  handleReplaceProductFromSearch: (id: any) => any;
  onIncrement: (id: any) => void;
  onDecrement: (id: any) => void;
  isEditing: boolean;
  onValueChange: (productVariantId: any, value: any) => void;
}

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

export const AddMoreProductsDrawer: React.FC<AddMoreProductsDrawerProps> = ({
  open,
  toggleDrawer,
  isSearchDrawer,
  orderData,
  handleAddProductFromSearch,
  handleReplaceProductFromSearch,
  onDecrement,
  onIncrement,
  isEditing,
  onValueChange,
}) => {
  const { theme } = useContext(ThemeContext);

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;

  const [lastFocusedId, setLastFocusedId] = useState<string | null>(null);
  const [productsList, setProductsList] = useState<ProductsList[]>([]);
  const [searchKey, setSearchKey] = useState('');

  const [fetchProducts, productResponse] = useGetProductsMutation();

  const getProducts = () => {
    fetchProducts({
      workspaceId: seller?.workspaceId,
      params: { customerId: inviteId, pageSize: 40, pageNo: 1 },
      body: { searchKey: searchKey },
    });
  };

  const onSearchHandler = (value: string) => {
    setSearchKey(value);
    // if (value === '') setProductsList([]);
    // else {
    fetchProducts({
      workspaceId: seller?.workspaceId,
      params: { pageNo: 1, pageSize: 40, customerId: inviteId },
      body: { searchKey: value },
    });
    // }
  };

  useEffect(() => {
    if (productResponse?.isSuccess) {
      setProductsList(
        productResponse?.data?.products?.map((x) => {
          return {
            ...x.productVariants?.[0],
            updatingQty: false,
          };
        })
      );
    }
  }, [productResponse]);

  useEffect(() => {
    onSearchHandler(searchKey);
  }, [searchKey]);

  // useEffect(() => {
  //   if (lastFocusedId) {
  //     inputRefs.current[lastFocusedId]?.focus();
  //   }
  // }, [lastFocusedId, productsList]);

  useEffect(() => {
    return () => {
      console.log('unmounting add more drawer');
    };
  }, []);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={56}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <StyledBox
        sx={{
          position: 'absolute',
          top: '-100px',
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          visibility: 'visible',
          right: 0,
          left: 0,
          ...theme.fontConfig.regular,
        }}
      >
        {/* <input value={sk} onChange={(e) => setSk(e?.target?.value)} /> */}
        <Puller />
        <Typography
          component="div"
          sx={{
            padding: '20px 14px 10px',
            color: 'text.secondary',
            ...theme.fontConfig.regular,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h3
              style={{
                fontSize: '18px',
                color: '#091E42',
                ...theme.fontConfig.condensedSemiBold,
                padding: 0,
                margin: 0,
              }}
            >
              {isSearchDrawer ? 'Find Product' : 'Replace Product'}
            </h3>
            <Button
              disableRipple
              disableFocusRipple
              disableElevation
              sx={{
                all: 'unset',
                cursor: 'pointer',
                textAlign: 'center',
                background: 'none',
                boxShadow: 'none',
                border: 0,
                padding: 0,
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: `${theme.fontConfig.condensedSemiBold} !important`,
                fontSize: '16px',
                color: '#32A7E8',
                width: 'auto',
                minWidth: 'auto',
                textTransform: 'capitalize',
              }}
              onClick={toggleDrawer(false)}
            >
              Close
            </Button>
          </div>
          <div
            id="find_search_section"
            style={{
              background: '#fff',
              borderRadius: '8px',
              padding: 0,
              margin: '10px 0px 5px',
            }}
          >
            <div
              style={{
                height: '37px',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: '5px',
                padding: '0 10px',
                border: '1px solid #DCDFE4',
                borderRadius: '8px',
              }}
            >
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img height={20} width={20} src={SVGS.SearchIconOrder} />
              </p>
              <input
                style={{
                  border: 0,
                  fontSize: '16px',
                  fontFamily: `${theme.fontConfig.regular} !important`,
                  width: '100%',
                  outline: 'none',
                }}
                type="text"
                placeholder="Search product to add"
                value={searchKey}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                }}
              />
              {searchKey?.length !== 0 && (
                <img
                  src={SVGS.XCircle}
                  onClick={() => {
                    setSearchKey('');
                  }}
                />
              )}
            </div>
          </div>
        </Typography>
      </StyledBox>
      <StyledBox
        sx={{
          px: '14px',
          pb: '20px',
          height: '100%',
          overflow: 'auto',
          ...theme.fontConfig.regular,
          background: '#F1F2F4',
          mt: '0px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {productResponse?.isLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <div id="findproduct_itemlist" style={{ margin: '22px 0 0' }}>
            {productsList?.map((product) => {
              const isProductAlreadyAdded = orderData?.lines?.find(
                (item) => item?.productVariantId === product?.productVariantId
              );
              let qty = 0;
              if (isProductAlreadyAdded) {
                qty = isProductAlreadyAdded?.quantity;
              }
              return (
                <div
                  key={product?.name}
                  style={{
                    background: '#fff',
                    borderRadius: '12px',
                    border: '1px solid #DCDFE4',
                    padding: '14px 16px',
                    margin: '0 0 8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                >
                  <div>
                    <h4
                      style={{
                        padding: '0 0 5px',
                        margin: 0,
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: '#091E42',
                        ...theme.fontConfig.condensedSemiBold,
                      }}
                    >
                      {product?.name}
                    </h4>
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#091E42',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        gap: 4,
                        ...theme.fontConfig.condensedSemiBold,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          color: '#8590a2',
                        }}
                      >
                        Pack Size
                      </span>
                      <span>{product?.packSize}</span>
                    </div>
                    {orderData?.showPrice && (
                      <div
                        className=""
                        style={theme.fontConfig.condensedSemiBold}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            color: '#8590a2',
                          }}
                        >
                          {`Price: `}
                        </span>
                        <span style={{ fontSize: 14, color: '#091E42' }}>
                          {getFormattedAmount(product?.price / 100)}
                        </span>
                      </div>
                    )}
                    {(product?.promotions?.length !== 0 ||
                      product?.collections?.length !== 0) && (
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            background: '#d3f7c7',
                            padding: '2px 6px',
                            margin: '0 0 2px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                            borderRadius: '3px',
                          }}
                        >
                          {/* <img height={10} src={SVGS.DiscountIconFindProduct} /> */}
                          <span
                            style={{
                              fontSize: '10px',
                              color: '#6bbe4d',
                              textTransform: 'none',
                              ...theme.fontConfig.condensedSemiBold,
                            }}
                          >
                            Offer
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {isProductAlreadyAdded ? (
                    <div
                      className="order-add-more-qty-btn"
                      style={{
                        opacity: isEditing ? 0.5 : 1,
                      }}
                    >
                      <img
                        src={SVGS.MinusPrimaryCyan}
                        onClick={() => {
                          setLastFocusedId(product.productVariantId.toString());
                          onDecrement(product?.productVariantId);
                        }}
                      />
                      {product?.updatingQty ? (
                        <LoadingSpinner size={15} />
                      ) : (
                        <input
                          ref={(el) =>
                            (inputRefs.current[product.productVariantId] = el)
                          }
                          className="order-add-more-add-to-cart-input"
                          type="text"
                          inputMode="numeric"
                          pattern="[0-9]*[.,]?[0-9]+"
                          value={qty}
                          onChange={(e) => {
                            onValueChange(
                              product?.productVariantId,
                              e.target.value
                            );
                          }}
                          onFocus={() =>
                            setLastFocusedId(
                              product.productVariantId.toString()
                            )
                          }
                        />
                      )}
                      <img
                        src={SVGS.PlusPrimaryCyan}
                        onClick={() => {
                          setLastFocusedId(product.productVariantId.toString());
                          onIncrement(product?.productVariantId);
                        }}
                      />
                    </div>
                  ) : isSearchDrawer ? (
                    <div
                      className="order-add-more-add-to-cart"
                      onClick={() => {
                        handleAddProductFromSearch(
                          product?.productVariantId,
                          product?.minOrderQty
                        );
                      }}
                      style={{
                        opacity: isEditing ? 0.5 : 1,
                      }}
                    >
                      Add
                    </div>
                  ) : (
                    <div
                      className="order-add-more-add-to-cart"
                      onClick={() => {
                        handleReplaceProductFromSearch(
                          product?.productVariantId
                        );
                      }}
                    >
                      Replace
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </StyledBox>
    </SwipeableDrawer>
  );
};
