import React, { useContext } from 'react';
import './invoice-summary-card.scss';
import { ThemeContext, colors, downloadFile } from '@zonofi/shared';
import { getFormattedAmount } from '@zonofi/common';
import { Invoice } from '@zonofi/zono-money-store';
import moment from 'moment';
import { SVGS } from '@zonofi/zono-money-design-system';
import { DetailsItem } from '../common';

/* eslint-disable-next-line */
export interface InvoiceSummaryCardProps {
  invoiceData: Invoice;
  media: { url: string; name: string; type: string }[];
}

const ImageFiles = ['svg', 'gif', 'jpeg', 'jpg', 'png', 'webp', 'bmp'];

export const InvoiceSummaryCard: React.FC<InvoiceSummaryCardProps> = ({
  invoiceData,
  media,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className="invoice-summary-card"
      style={{ backgroundColor: theme.primary }}
    >
      <div className="row">
        <div className="column">
          <DetailsItem
            heading="Invoiced On"
            value={moment(invoiceData?.invoiceDate).format(
              'D MMM YYYY, hh:mm A'
            )}
          />
          <DetailsItem
            heading="Invoice Amount"
            bold={true}
            value={getFormattedAmount(invoiceData?.invoiceAmount)}
          />
        </div>
        <div className="column">
          <DetailsItem
            heading="Invoice Number"
            value={invoiceData?.docNumber}
          />
          <DetailsItem
            heading="Due Date"
            value={moment(invoiceData?.dueDate).format('D MMM YYYY, hh:mm A')}
          />
        </div>
      </div>
      <div className="column">
        <DetailsItem
          heading="Invoiced Orders"
          value={invoiceData?.refOrderNumbers?.join(', ') || '-'}
        />
        <DetailsItem heading="Note" value={invoiceData?.description || '-'} />
        {media?.length !== 0 && (
          <div className="item">
            <div
              style={{
                ...theme.fontConfig.regular,
                color: colors.gray.primary,
              }}
            >
              {'Invoice Image / Document'}
            </div>
            {media?.map(({ url, name, type }, index) => {
              const isImage = ImageFiles.includes(type);
              return (
                <div className="media" key={index}>
                  {isImage ? (
                    <img className="image" src={url} alt={name} />
                  ) : (
                    <div className="document">
                      <div className="name" style={theme.fontConfig.semiBold}>
                        {name}
                      </div>
                      <div className="type" style={theme.fontConfig.regular}>
                        {name.slice(name.lastIndexOf('.') + 1)?.toUpperCase()}
                      </div>
                    </div>
                  )}
                  <DownloadButton onClick={() => downloadFile(url, name)} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const DownloadButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className="download" onClick={onClick}>
      <img src={SVGS.DownloadIcon} alt="" style={{ height: 16, width: 16 }} />
    </div>
  );
};

export default InvoiceSummaryCard;
