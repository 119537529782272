import { ThemeContext } from '@zonofi/shared';
import { Invoice } from '@zonofi/zono-money-store';
import React, { useContext } from 'react';
import './invoice-list.scss';
import { InvoiceCard } from './invoice-card';

interface InvoiceListProps {
  invoices: Invoice[];
}

export const InvoiceList: React.FC<InvoiceListProps> = ({ invoices }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="invoices-container">
      <div
        style={{
          ...theme.fontConfig.semiBold,
          color: theme.text.primary,
          fontSize: 14,
          lineHeight: '28px',
        }}
      >
        Invoices
      </div>
      {invoices.map((invoice) => (
        <InvoiceCard invoice={invoice} />
      ))}
    </div>
  );
};
