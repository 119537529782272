import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Watch({ color = '#a1a3b4', width = 14, height = 14 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <G data-name="3 Watch">
        <Path
          data-name="Path 54"
          d="M449.841 3010.671a7 7 0 107 7 7 7 0 00-7-7zm3.208 7.583h-2.625a1.166 1.166 0 01-1.167-1.167v-3.5a.583.583 0 111.167 0v3.5h2.625a.583.583 0 010 1.167z"
          fill="#9295a5"
          transform="translate(-442.841 -3010.671)"
        />
      </G>
    </Svg>
  );
}

export default Watch;
