import { isNull, isUndefined } from 'lodash';
import { currencyFormat, roundedCurrencyFormat } from './currency-formatter';

export const getFormattedAmount = (amt?: number | null) => {
  if (!isUndefined(amt) && !isNull(amt) && isFinite(amt)) {
    if (!Number.isInteger(amt)) {
      return currencyFormat.format(amt).replace(/^(\D+)/, '$1');
    } else {
      return roundedCurrencyFormat.format(amt).replace(/^(\D+)/, '$1');
    }
  }
  return '₹0';
};

export const getCalculatedAmount = (val: string) => {
  let amount = parseFloat(val);
  const units = ['', 'K', 'M', 'B', 'T'];
  let i = 0;

  while (amount >= 1e3 && i < units.length - 1) {
    amount /= 1e3;
    i++;
  }

  return `₹${amount.toFixed(2) + units[i]}`;
};
