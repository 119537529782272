import React, { useContext } from 'react';
import { Button as PaperButton } from 'react-native-paper';
import {
  Text,
  StyleProp,
  ViewStyle,
  TextStyle,
  TouchableOpacity,
} from 'react-native';
import { colors } from '../colors/colors';
import LinearGradient from 'react-native-linear-gradient';
import _ from 'lodash';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { isNative, isWeb } from '@zonofi/zono-money-helpers';
import { ThemeContext } from '@zonofi/shared';

export enum ButtonVariants {
  outlined_action = 'outlined_action',
  color_filled_action = 'color_filled_action',
  gradient_filled_action = 'gradient_filled_action',
}
/* eslint-disable-next-line */
export interface ButtonProps {
  variant: 'color_filled_action' | 'outlined_action' | 'gradient_filled_action';
  text: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  gradientColors?: (string | number)[];
  textStyle?: StyleProp<TextStyle>;
  fontSize?: number;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  variant,
  text,
  onPress,
  style,
  gradientColors,
  textStyle,
  fontSize,
  disabled,
}) => {
  const { theme } = useContext(ThemeContext);
  switch (variant) {
    case ButtonVariants.outlined_action:
      return (
        <TouchableOpacity
          onPress={onPress}
          disabled={disabled}
          style={[
            {
              paddingVertical: hp('1.5%'),
              paddingHorizontal: wp('2%'),
              borderColor: colors.primaryButtonBorderColor.color,
              borderRadius: 10,
              borderWidth: 1,
            },
            style,
          ]}
        >
          <Text
            style={[
              {
                alignSelf: 'center',
                fontSize: !_.isUndefined(fontSize) ? fontSize : 18,
                color: 'white',
              },
              theme.fontConfig.semiBold,
              textStyle,
            ]}
          >
            {text}
          </Text>
        </TouchableOpacity>
      );
    case ButtonVariants.color_filled_action:
      return (
        <TouchableOpacity
          disabled={disabled}
          // >
          //   <TouchableRipple
          onPress={onPress}
          // rippleColor={colors.rippleEffectColor.color}
          // borderless
          style={[
            {
              paddingVertical: hp(2),
              marginVertical: hp(1),
              marginHorizontal: wp('2%'),
              backgroundColor: colors.secondaryButtonBackgroundColor.color,
              borderRadius: 10,
            },
            style,
          ]}
        >
          <Text
            style={[
              {
                alignSelf: 'center',
                fontSize: !_.isUndefined(fontSize) ? fontSize : 18,
                color: colors.zonoWhite.color,
              },
              theme.fontConfig.semiBold,
              textStyle,
            ]}
          >
            {text}
          </Text>
          {/* </TouchableRipple> */}
        </TouchableOpacity>
      );
    case ButtonVariants.gradient_filled_action:
      return (
        <TouchableOpacity
          onPress={onPress}
          disabled={disabled}
          style={[
            {
              paddingVertical: hp('1%'),
              paddingHorizontal: wp('2%'),
              borderRadius: 10,
            },
            style,
          ]}
        >
          <LinearGradient
            colors={
              !_.isUndefined(gradientColors)
                ? gradientColors
                : ['black', 'white']
            }
            style={[
              { width: '100%', borderRadius: 10 },
              style,
              isNative()
                ? { paddingVertical: hp(1) }
                : { paddingVertical: hp(0) },
            ]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 1 }}
          >
            <Text
              style={[
                {
                  alignSelf: 'center',
                  fontSize: !_.isUndefined(fontSize) ? fontSize : 18,
                  color: colors.zonoWhite.color,
                },
                isWeb() ? { lineHeight: hp('7') } : { lineHeight: hp('5') },
                theme.fontConfig.semiBold,
                textStyle,
              ]}
            >
              {text}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      );
    default:
      return <PaperButton>{text}</PaperButton>;
  }
};
