import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function Switch(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={14}
      viewBox="0 0 14.817 12.348"
      {...props}
    >
      <Path
        data-name="Path 927"
        d="M169.852 1853.087a.618.618 0 00-.617.617v4.939a.618.618 0 00.617.617h6.536l-.8-.8a.617.617 0 11.873-.873l1.852 1.852a.617.617 0 010 .873l-1.852 1.852a.617.617 0 11-.873-.873l.8-.8h-6.536a1.852 1.852 0 01-1.852-1.852v-4.939a1.852 1.852 0 011.852-1.852h.617a.617.617 0 010 1.235zm5.376-2.906a.617.617 0 010 .873l-.8.8h6.536a1.852 1.852 0 011.852 1.852v4.939a1.852 1.852 0 01-1.852 1.852h-.617a.617.617 0 010-1.235h.617a.618.618 0 00.617-.617v-4.945a.618.618 0 00-.617-.617h-6.534l.8.8a.617.617 0 01-.873.873l-1.852-1.852a.617.617 0 010-.873l1.852-1.852a.617.617 0 01.871.002z"
        transform="translate(-168 -1850)"
        fill="#fff"
      />
    </Svg>
  );
}

export default Switch;
