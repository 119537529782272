//@ts-nocheck

import { ShimmerText } from '../shimmer-text/shimmer-text';
import { colors } from '../colors/colors';
import { fontConfig } from '../fonts/fonts';
import { ShimmerTextRefObject } from '../types/interfaces';
import React from 'react';

import { View, Text, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import {
  Font,
  heightPercentageToDPBoundsViewPort as hvp,
  widthPercentageToDPBoundsViewPort as wvp,
} from '../helpers';

/* eslint-disable-next-line */
interface FontX extends Font {
  fontSize: number;
  letterSpacing?: number;
}
export interface HeaderSubheaderProps {
  header: string;
  subHeader: string;
  headerFontConfig?: FontX;
  subHeaderFontConfig?: FontX;
  headerColor?: string;
  subHeaderColor?: string;
  containerStyle?: StyleProp<ViewStyle>;
  loading?: boolean;
}

export const HeaderSubheader: React.FC<HeaderSubheaderProps> = ({
  header,
  subHeader,
  headerFontConfig = { ...fontConfig.default.regular, fontSize: 12 },
  subHeaderFontConfig = { ...fontConfig.default.regular, fontSize: 14 },
  headerColor = colors.metallicSilver.primaryShade,
  subHeaderColor = colors.white.color,
  containerStyle = styles.container,
  loading = false,
}) => {
  const contentRef = React.useRef<ShimmerTextRefObject>(null);
  React.useEffect(() => {
    // setTimeout(() => {
    if (!loading) {
      contentRef.current?.show();
    } else {
      contentRef.current?.hide();
    }
    // }, 800);
  }, [loading]);
  return (
    <View style={containerStyle}>
      <Text style={[{ ...headerFontConfig, color: headerColor }]}>
        {header}
      </Text>
      <ShimmerText
        ref={contentRef}
        width={wvp(60)}
        text={subHeader}
        textStyle={[{ ...subHeaderFontConfig, color: subHeaderColor }]}
        numberOfLines={1}
        ellipsizeMode="tail"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
