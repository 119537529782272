// Generated by ts-to-zod
import { z } from 'zod';

const orderLineSchema = z.object({
  sku: z.string(),
  linePrice: z.string(),
  changedValue: z.string(),
  description: z.string(),
  suggestedValue: z.string(),
  proratedLinePrice: z.string(),
  id: z.number(),
  adjustments: z.array(z.any()),
  divisionName: z.string().optional(),
  discountedLinePriceWithTax: z.string(),
  skuName: z.string(),
  linePriceWithTax: z.string(),
  suggestedQuantity: z.number(),
  divisionId: z.string(),
  discountedLinePrice: z.string(),
  productVariantId: z.number(),
  unitPrice: z.string(),
  proratedLinePriceWithTax: z.string(),
  quantity: z.number(),
});

export const orderSchema = z.object({
  status: z.string(),
  orderId: z.number(),
  itemCount: z.number(),
  refOrderNumber: z.string(),
  cfaId: z.string(),
  detailsLink: z.string(),
  orderType: z.string(),
  orderLines: z.array(orderLineSchema).optional(),
  inviteId: z.string(),
  poNumber: z.string(),
  discount: z.string(),
  totalWithTax: z.union([z.number(), z.string()]),
  source: z.string().optional(),
  orderValue: z.union([z.number(), z.string()]),
  totalAmount: z.string(),
  tax: z.union([z.number(), z.string()]),
  orderSaving: z.union([z.number(), z.string()]),
  netAmount: z.string(),
  poFileId: z.string().optional(),
  eventTimestamp: z.null().optional(),
  previousState: z.string().optional(),
  isModified: z.boolean().optional(),
  appId: z.string().optional(),
  erpOrderNumber: z.null().optional(),
  suggestedOrderNumber: z.null().optional(),
  principalWorkspaceId: z.string().optional(),
  generationType: z.null().optional(),
  orderDate: z.string().optional(),
  whatsappToken: z.string().optional(),
  whatsappPhoneNumberId: z.string().optional(),
  userType: z.string().optional(),
});
