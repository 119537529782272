import React from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from '@zonofi/zono-money-store';
import { PersistGate } from 'redux-persist/integration/react';
import { AppNavigator } from '../navigation';
import { ThemeContextProvider } from '@zonofi/shared';
import './fonts.css';

export function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeContextProvider>
          <AppNavigator />
        </ThemeContextProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
