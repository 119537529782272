import React, { useContext } from 'react';
import {
  TouchableOpacity,
  Text,
  StyleSheet,
  View,
  DimensionValue,
} from 'react-native';
import Icon from 'react-native-easy-icon';
import { colors } from '../colors/colors';
import { ThemeContext } from '../contexts/ThemeContext';

interface CheckListItemProps {
  listItem: {
    id: string;
    value: string;
    icon?: React.ReactNode;
    subHeader?: string;
    fontSize?: number;
    width?: DimensionValue;
  };
  onPress: (id: string) => void;
  selectedIds: string[];
  showCheckIcon?: boolean;
}

export const CheckListItem: React.FC<CheckListItemProps> = ({
  listItem,
  onPress,
  selectedIds,
  showCheckIcon = true,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const fontSize = listItem.fontSize ? listItem.fontSize : 16;
  return (
    <TouchableOpacity
      onPress={() => {
        onPress(listItem.id);
      }}
      style={styles.container}
    >
      <View
        style={[
          {
            width: listItem?.width,
            flexDirection: 'row',
            alignItems: 'center',
          },
        ]}
      >
        {listItem.icon}
        <Text
          numberOfLines={1}
          style={[
            styles.regularText,

            {
              color: theme.text.primary,
              ...theme.fontConfig.regular,
              fontSize,
            },
          ]}
        >
          {listItem.value}
        </Text>
        {listItem.subHeader && (
          <Text
            style={[
              styles.regularText10,
              {
                ...theme.fontConfig.regular,
              },
            ]}
          >
            {listItem.subHeader}
          </Text>
        )}
      </View>
      {showCheckIcon ? (
        selectedIds.includes(listItem.id) ? (
          <Icon
            type="material"
            name="check-circle"
            color={colors.steelBlue.color}
            size={20}
          />
        ) : (
          <Icon
            type="material"
            name="circle"
            color={colors.chineseBlack.secondary}
            size={20}
          />
        )
      ) : null}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  regularText: {
    fontSize: 16,
    marginLeft: 4,
  },
  regularText10: {
    fontSize: 10,
    color: colors.manatee.color,
  },
  container: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 5,
    flex: 1,
    alignItems: 'center',
  },
});
