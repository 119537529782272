import * as React from 'react';
import Svg, { Defs, ClipPath, Path, G, Rect } from 'react-native-svg';

function SearchIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={34}
      viewBox="0 0 34 34"
      {...props}
    >
      <Defs>
        <ClipPath id="a">
          <Path
            data-name="Combined Shape"
            d="M14.536 15.66l-3.6-3.6a6.762 6.762 0 111.124-1.124l3.6 3.6a.795.795 0 01-1.124 1.124zM1.589 6.754a5.163 5.163 0 008.788 3.679l.023-.033.028-.027a5.164 5.164 0 10-8.839-3.619z"
            fill="#9295a5"
          />
        </ClipPath>
      </Defs>
      <G data-name="Group 116303">
        <Rect
          width={34}
          height={34}
          rx={4}
          transform="translate(-1215.999 -189) translate(1216 189.002) rotate(180 17 16.999)"
          fill="#34353e"
          data-name="Group 113935"
        />
        <G data-name="Group 113931">
          <G
            data-name="Group 53191"
            clipPath="url(#a)"
            transform="translate(-1215.999 -189) translate(1224.999 198.004)"
          >
            <G
              data-name="\uD83C\uDFA8 Color"
              transform="translate(-1.589 -1.589)"
              fill="#9295a5"
            >
              <Path
                data-name="\uD83C\uDFA8 Color background"
                d="M0 0H19.071V19.071H0z"
              />
              <Path d="M0 0H19.071V19.071H0z" />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SearchIcon;
