import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { CustomSvg } from '../custom-svg/custom-svg';
import _ from 'lodash';
import './styles.scss';

interface Tabs {
  tabName: string;
  tabIcon?: any;
}
export interface NewSwitchTabsProps {
  setSelectedTab: (id: number) => void;
  selectedTab: number;
  tabs: Tabs[];
  tabButtonContainerStyles?: any;
  tabButtonStyle?: any;
  tabTextStyle?: any;
}

export const NewSwitchTabs: React.FC<NewSwitchTabsProps> = ({
  setSelectedTab,
  selectedTab,
  tabs,
  tabButtonContainerStyles,
  tabButtonStyle,
  tabTextStyle,
}) => {
  const theme = useContext(ThemeContext).theme;
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="switch-tab-button-container"
      style={{
        ...tabButtonContainerStyles,
        paddingRight: viewportWidth > 600 ? 15 : 0,
        paddingLeft: viewportWidth > 600 ? 15 : 0,
      }}
    >
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`swicth-tab-button ${
            selectedTab === index ? 'swicth-active-tab-button' : ''
          }`}
          style={{
            ...tabButtonStyle,
            marginRight: viewportWidth > 600 ? tabButtonStyle?.marginRight : 0,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
          onClick={() => {
            setSelectedTab(index);
          }}
        >
          {tab?.tabIcon && !_.isEmpty(tab?.tabIcon) && (
            <CustomSvg
              svg={tab?.tabIcon}
              colors={{
                fill: selectedTab === index ? '#2C9AD9' : theme.text.primary,
              }}
              width={24}
            />
          )}
          <div
            style={{
              alignSelf: 'center',
              color: selectedTab === index ? '#2C9AD9' : theme.text.primary,
              fontFamily: theme.fontConfig.semiBold.fontFamily,
              marginLeft: 4,
              marginTop:-2,
              ...tabTextStyle,
            }}
            className="comms-font-size-medium"
          >
            {tab?.tabName}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewSwitchTabs;
