/*
 * @param {F} fn - The function to debounce.
 * @param {number} t - The number of milliseconds to delay.
 * @return {F} - The debounced version of the input function.
 */
export function debounce<F extends (...args: any[]) => void>(
  fn: F,
  t: number
): F {
  let timer: ReturnType<typeof setTimeout>;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), t);
  } as F;
}
