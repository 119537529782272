import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import Icon from 'react-native-easy-icon';
import { ThemeContext } from '../contexts/ThemeContext';

/* eslint-disable-next-line */
export interface CustomerListStatusTagProps {
  isActive: boolean;
}

export function CustomerListStatusTag(props: CustomerListStatusTagProps) {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const getStatusTag = (isActive: boolean) => {
    const color = isActive ? '#00C17F' : '#C93532';
    const iconName = isActive ? 'unlock' : 'lock';
    const text = isActive ? 'UNLOCKED' : 'LOCKED';
    return (
      <View
        style={{
          width: 75,
          height: 18,
          backgroundColor: isActive ? '#64E6BA1A' : '#E85B491A',
          borderRadius: 2,
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Icon type={'evilicon'} name={iconName} size={14} color={color} />
        <Text
          style={{
            fontSize: 10,
            opacity: 10,
            color,
            ...theme.fontConfig.semiBold,
          }}
        >
          {text}
        </Text>
      </View>
    );
  };

  return (
    // <div
    //         style={{
    //           width: '6rem',
    //           display: 'flex',
    //           alignItems: 'center',
    //           minWidth: 100,
    //           justifyContent: 'center',
    //           marginLeft: 30,
    //         }}
    //       >
    <span
      style={{
        fontFamily: theme.fontConfig.regular.fontFamily,
        fontSize: 14,
        fontWeight: 500,
        color: theme.text.primary,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      {getStatusTag(props.isActive)}
    </span>
    // </div>
  );
}

export default CustomerListStatusTag;
