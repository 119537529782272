import { z } from 'zod';

const mediaSchema = z.object({
  link: z.string(),
  caption: z.string().optional(),
});

const imediaSchema = z
  .object({
    id: z.string(),
  })
  .or(
    z
      .object({
        link: z.string(),
        provider: z.object({
          name: z.string(),
        }),
      })
      .or(mediaSchema)
  );

const headerSchema = z
  .object({
    type: z.literal('image'),
    image: imediaSchema,
  })
  .or(
    z.object({
      type: z.literal('video'),
      video: imediaSchema,
    })
  )
  .or(
    z.object({
      type: z.literal('text'),
      text: z.string(),
    })
  )
  .or(
    z.object({
      type: z.literal('document'),
      document: imediaSchema,
    })
  );

const bodySchema = z.object({
  text: z.string(),
});

const flowActionParameterSchema = z.object({
  flow_message_version: z.string(),
  flow_token: z.string(),
  flow_id: z.string(),
  flow_cta: z.string(),
  flow_action: z.string(),
  flow_action_payload: z.object({
    screen: z.string(),
  }),
});

const flowActionSchema = z.object({
  name: z.string(),
  parameters: flowActionParameterSchema,
});

const buttonsActionSchema = z.object({
  buttons: z.array(
    z.object({
      type: z.literal('reply'),
      reply: z.object({ id: z.string(), title: z.string() }),
    })
  ),
});

const actionSchema = flowActionSchema.or(buttonsActionSchema);

const footerSchema = z.object({
  text: z.string(),
});

const baseInteractiveSchema = z.object({
  action: actionSchema,
  footer: footerSchema.optional(),
});

const interactiveButtonSchema = baseInteractiveSchema.extend({
  type: z.literal('button'),
  header: headerSchema.optional(),
  body: bodySchema,
});
const interactiveFlowSchema = baseInteractiveSchema.extend({
  type: z.literal('flow'),
  header: headerSchema.optional(),
  body: bodySchema,
});

export const interactiveSchema = interactiveButtonSchema.or(
  interactiveFlowSchema
);
