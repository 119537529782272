import React, { useContext } from 'react';
import Tag from '../tag/tag';
import { ThemeContext } from '../contexts/ThemeContext';
import _ from 'lodash';

enum PaymentCodes {
  All_Payments = '',
  Pending = 'P',
  PartiallyPaid = 'PP',
  Paid = 'PD',
  Cancelled = 'C',
  OverDue = 'OD',
  UnPaid = 'UD',
}

interface PaymentStatusTagProps {
  status: string;
}

export const PaymentStatusTag: React.FC<PaymentStatusTagProps> = ({
  status,
}) => {
  const { theme } = useContext(ThemeContext);

  const getStatus = (status: string) => {
    const { orderStatusTags } = theme;
    const { cancelled, submitted, waiting, draft } = orderStatusTags;
    switch (status) {
      case PaymentCodes.All_Payments:
      case PaymentCodes.Paid:
        return {
          background: submitted.backgroundColor,
          color: submitted.text,
        };
      case PaymentCodes.Cancelled:
      case PaymentCodes.OverDue:
        return {
          background: cancelled.backgroundColor,
          color: cancelled.text,
        };
      case PaymentCodes.PartiallyPaid:
      case PaymentCodes.Pending:
      case PaymentCodes.UnPaid:
        return {
          background: waiting.backgroundColor,
          color: waiting.text,
        };
      default:
        return {
          background: draft.backgroundColor,
          color: draft.text,
        };
    }
  };

  const { background, color } = getStatus(status);
  const label = _.startCase(
    _.camelCase(
      Object.entries(PaymentCodes).find(([k, v]) => status === v)?.[0] || ''
    )
  ).toUpperCase();
  return (
    <Tag
      label={label}
      style={{
        backgroundColor: background,
        borderColor: 'transparent',
        borderRadius: 6,
        text: color,
      }}
    />
  );
};
