import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function FileDownload(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={14.765}
      height={10.996}
      viewBox="0 0 14.765 10.996"
      {...props}
    >
      <Path
        data-name="Path 556"
        d="M1000.721 1761.533a.471.471 0 01.471.471v2.513a.786.786 0 00.785.785h11.309a.786.786 0 00.785-.785V1762a.471.471 0 01.942 0v2.513a1.728 1.728 0 01-1.728 1.728h-11.309a1.728 1.728 0 01-1.728-1.728V1762a.471.471 0 01.473-.467zm6.911-6.283a.471.471 0 01.471.471v5.145l1.081-1.08a.471.471 0 01.666.666l-1.885 1.885a.471.471 0 01-.666 0l-1.885-1.885a.471.471 0 01.666-.666l1.081 1.08v-5.145a.472.472 0 01.472-.471z"
        transform="translate(-1000.25 -1755.25)"
        fill={props.color ?? '#FFFFF'}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default FileDownload;
