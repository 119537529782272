import React, { useContext } from 'react';
import { Skeleton } from '@mui/material';
import { ThemeContext } from '../contexts/ThemeContext';

export interface TableSkeletonProps {
  columnCount: number;
  rowCount?: number;
}

export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  columnCount,
  rowCount,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  return (
    <table
      style={{
        flex: 1,
        borderSpacing: 0,
        marginLeft: 12,
        marginRight: 12,
        borderCollapse: 'collapse',
        overflow: 'hidden',
      }}
    >
      <thead
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
          backgroundColor: theme.dropDown.background,
        }}
      >
        <tr>
          <th
            style={{
              borderTop: `2px solid ${theme.table.highlight}`,
              borderBottom: `2px solid ${theme.table.highlight}`,
              backgroundColor: theme.dropDown.background,
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 8,
            }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              width={20}
              height={20}
            />
          </th>
          {Array.from(Array(columnCount).keys()).map((item, index) => (
            <th
              style={{
                borderTop: `2px solid ${theme.table.highlight}`,
                borderBottom: `2px solid ${theme.table.highlight}`,
                backgroundColor: theme.dropDown.background,
                paddingTop: 12,
                paddingBottom: 12,
              }}
            >
              <Skeleton
                animation="wave"
                variant="text"
                sx={{ fontSize: '1rem' }}
                width={100}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from(Array(rowCount || 10).keys()).map((row, index) => (
          <tr
            style={{
              backgroundColor:
                index % 2 === 0 ? 'transparent' : theme.table.secondRow,
            }}
          >
            <td
              style={{
                paddingLeft: 8,
              }}
            >
              <Skeleton
                animation="wave"
                variant="rounded"
                width={20}
                height={20}
              />
            </td>
            {Array.from(Array(columnCount).keys()).map((row, i) => (
              <td>
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{ fontSize: 18 }}
                  width={index % 2 === 0 ? 180 : 120}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
