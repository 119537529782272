import { createNavigationContainerRef } from '@react-navigation/native';

// export const navigationRef: React.RefObject<NavigationContainerRef> = React.createRef()

export const navigationRef = createNavigationContainerRef();

export class NavigationService {
  static navigate(name: string, params?: object) {
    if (navigationRef.isReady()) {
      //@ts-ignore
      navigationRef.navigate(name, params);
    }
  }

  static goBack() {
    if (navigationRef.isReady()) {
      if (navigationRef.canGoBack()) {
        navigationRef.goBack();
      }
    }
  }
}
