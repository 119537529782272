import { getFormattedAmount } from '@zonofi/common';
import { ThemeContext } from '@zonofi/shared';
import { SVGS } from '@zonofi/zono-money-design-system';
import { OrderData, useAppSelector } from '@zonofi/zono-money-helpers';
import { LineDiscount, getCustomerInvite } from '@zonofi/zono-money-store';
import moment from 'moment';
import React, { useContext } from 'react';

type Props = {
  orderData: any;
  showProductImage: boolean;
};

export interface DiscountDetails {
  oldPrice: number;
  newPrice: number;
  quantity: number;
  discount: number | undefined;
  lineDiscount?: LineDiscount;
}

const OrderDetailsScreenLayout: React.FC<Props> = ({
  orderData,
  showProductImage,
}) => {
  const { theme } = useContext(ThemeContext);
  const inviteDetails = useAppSelector(getCustomerInvite);
  const { buyer, seller } = inviteDetails;

  const getModifiedImageUrl = (url: string) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('width', '65');
      urlObj.searchParams.set('height', '65');
      return urlObj.toString();
    } catch {
      return url;
    }
  };

  const getBillDetails = () => {
    const sections = [
      {
        name: 'Total Items',
        value:
          orderData?.lines?.length < 10
            ? orderData?.lines?.length.toString().padStart(2, '0')
            : orderData?.lines?.length || 0,
      },
      {
        name: 'Total Quantity',
        value:
          orderData?.totalQuantity < 10
            ? orderData?.totalQuantity?.toString().padStart(2, '0')
            : orderData?.totalQuantity || 0,
      },
      {
        name: 'Order Amount',
        value: getFormattedAmount(orderData?.total + orderData?.discount),
      },
      {
        name: 'Scheme Savings',
        value: getFormattedAmount(orderData?.discount),
        color: '#2ABB7F',
      },
      {
        name: 'Gross Amount',
        value: getFormattedAmount(orderData?.total),
      },
      {
        name: 'GST Tax',
        value: getFormattedAmount(orderData?.tax),
        color: '#DD360C',
      },
      {
        name: 'Net Order Amount',
        value: getFormattedAmount(orderData?.totalWithTax),
      },
    ];
    return sections?.map((item) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            listStyle: 'none',
            padding: '0px 0 10px',
            gap: 5,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 8,
              flex: 1,
              alignItems: 'center',
            }}
          >
            <span style={{ whiteSpace: 'nowrap' }}>{item?.name}</span>
            <div
              style={{
                borderBottom: '1px dashed #acacac',
                flex: 1,
                height: 1,
              }}
            />
          </div>
          <span
            style={{
              minWidth: 55,
              textAlign: 'right',
              color: item?.color || 'inherit',
            }}
          >
            {item?.value}
          </span>
        </div>
      );
    });
  };

  return (
    <div
      className="order_screen"
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: '#F1F2F4',
        ...theme.fontConfig.regular,
      }}
    >
      <div
        id="ordersummary_top"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 14px',
          background: '#fff',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 6,
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 8,
            }}
          >
            <span
              style={{
                padding: '0 0 0px',
                margin: 0,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#091E42',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              {orderData ? 'Order Details' : ''}
            </span>
            <span
              style={{
                background: '#D6EAED',
                color: '#0067FF',
                borderRadius: '5px',
                padding: '3px 7px',
                fontSize: '12px',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              {orderData?.status || '-'}
            </span>
          </div>
          <span
            style={{
              fontSize: '12px',
              color: '#758195',
            }}
          >
            Created on{' '}
            {moment(orderData?.createdDate).format('DD MMM YYYY, hh:mm A')}
          </span>
        </div>
        <img src={SVGS.ZotokLogoGreen} />
      </div>
      <div
        style={{
          padding: '14px',
        }}
      >
        <div
          id="itemorder_section"
          style={{
            background: '#fff',
            borderRadius: '12px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <h4
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '18px',
              color: '#091E42',
              borderBottom: '1px solid #F1F2F4',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            {orderData?.lines?.length
              ? `${orderData?.lines?.length} items in the order`
              : ''}
          </h4>
          {orderData?.discount ? (
            <div
              id="congrats_order"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '12px',
                color: '#249F6C',
                background: '#DEF8ED',
                padding: '10px 15px',
                ...theme.fontConfig.condensedSemiBold,
              }}
            >
              <p style={{ padding: 0, margin: 0 }}>
                <img
                  style={{
                    margin: '0px 3px 0 0',
                    position: 'relative',
                    top: '2px',
                  }}
                  height={14}
                  src={SVGS.DiscountIcon}
                />
                {`Congratulations! You've got ${getFormattedAmount(
                  orderData?.discount
                )} off by scheme savings!`}
              </p>
            </div>
          ) : (
            ''
          )}
          <div
            id="orderdetails_itemlist"
            style={{ padding: '0px 0 10px', margin: '0px' }}
          >
            {orderData?.lines.map((orderItem) => {
              let isDiscountApplied = false;
              const discountDetails: DiscountDetails = {
                oldPrice: orderItem?.price,
                newPrice: orderItem?.newPrice,
                quantity: orderItem?.quantity,
                lineDiscount: orderItem?.lineDiscount,
                discount: orderItem?.lineDiscount?.discount,
              };
              if (Number(discountDetails?.lineDiscount?.discount) > 0) {
                isDiscountApplied = true;
              }
              const discountValue = () => {
                if (discountDetails?.lineDiscount?.discountType === 'percent') {
                  return `${discountDetails?.lineDiscount?.discount} %`;
                } else {
                  return false;
                }
              };
              return (
                <div
                  key={orderItem.title}
                  style={{
                    borderBottom: '1px solid #F1F2F4',
                    padding: '12px 16px',
                    margin: 0,
                    display: 'flex',
                    gap: 10,
                  }}
                >
                  {showProductImage && (
                    <img
                      src={
                        orderItem?.productImage
                          ? getModifiedImageUrl(orderItem?.productImage)
                          : SVGS.DefaultProduct
                      }
                      className="line-item-default-img"
                      style={{
                        width: 65,
                        height: 65,
                      }}
                      alt=""
                      onError={(e) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = SVGS.DefaultProduct;
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <h4
                      style={{
                        padding: '0 0 0px',
                        margin: 0,
                        fontSize: '14px',
                        color: '#091E42',
                        ...theme.fontConfig.condensedSemiBold,
                      }}
                    >
                      {
                        //@ts-ignore
                        orderItem?.title
                      }
                    </h4>
                    {isDiscountApplied && (
                      <p
                        id="orderdetails_offer"
                        style={{
                          fontSize: '12px',
                          color: '#249F6C',
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        {discountValue() ? discountValue() : ''} Offer applied!
                        <span
                          style={{
                            color: '#758195',
                            padding: '0 5px',
                            margin: 0,
                          }}
                        >
                          worth
                        </span>
                        {getFormattedAmount(
                          Number(orderItem?.totalWithoutTax) -
                            orderItem?.discountedLinePrice
                        )}
                      </p>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1,
                        gap: 5,
                      }}
                    >
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          ...theme.fontConfig.condensedSemiBold,
                        }}
                      >
                        <span
                          style={{
                            color: '#758195',
                            ...theme.fontConfig.regular,
                          }}
                        >
                          Buying Price:{' '}
                        </span>
                        ₹{orderItem?.discountedPrice}
                      </p>
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          ...theme.fontConfig.condensedSemiBold,
                        }}
                      >
                        <span
                          style={{
                            color: '#758195',
                            ...theme.fontConfig.regular,
                          }}
                        >
                          Qty:{' '}
                        </span>
                        {orderItem.quantity}
                      </p>
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          ...theme.fontConfig.condensedSemiBold,
                        }}
                      >
                        <span
                          style={{
                            color: '#758195',
                            ...theme.fontConfig.regular,
                          }}
                        >
                          Total Amount:{' '}
                        </span>
                        {getFormattedAmount(orderItem?.discountedLinePrice)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          id="bill_details"
          style={{
            background: '#fff',
            borderRadius: '12px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <h4
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '18px',
              color: '#091E42',
              borderBottom: '1px solid #F1F2F4',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Bill details
          </h4>
          <div
            id="billdetails_list"
            style={{
              padding: '12px 16px',
              margin: 0,
              fontSize: '14px',
              color: '#091E42',
            }}
          >
            {getBillDetails()}
          </div>
        </div>

        <div
          id="order_summary"
          style={{
            background: '#fff',
            borderRadius: '12px',
            padding: 0,
            margin: '0 0 10px',
          }}
        >
          <h4
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '18px',
              color: '#091E42',
              borderBottom: '1px solid #F1F2F4',
              ...theme.fontConfig.condensedSemiBold,
            }}
          >
            Order summary
          </h4>
          <div
            id="ordersummary_list"
            style={{
              padding: '14px 16px',
              margin: 0,
              fontSize: '12px',
              color: '#091E42',
            }}
          >
            <ul
              style={{
                listStyle: 'none',
                padding: '0px 0 15px',
                margin: '0px',
              }}
            >
              <li style={{ color: '#758195', padding: '0 0 4px' }}>Order Id</li>
              <li
                style={{
                  ...theme.fontConfig.condensedSemiBold,
                  fontSize: '14px',
                }}
              >
                {orderData?.orderNumber}
              </li>
            </ul>
            {/* <ul
              style={{
                listStyle: 'none',
                padding: '0px 0 15px',
                margin: '0px',
              }}
            >
              <li style={{ color: '#758195', padding: '0 0 4px' }}>
                Invoice Id
              </li>
              <li style={{ ...theme.fontConfig.condensedSemiBold }}>
                INVB5283126
              </li>
            </ul>
            <ul
              style={{
                listStyle: 'none',
                padding: '0px 0 15px',
                margin: '0px',
              }}
            >
              <li style={{ color: '#758195', padding: '0 0 4px' }}>
                Payment Id
              </li>
              <li style={{ ...theme.fontConfig.condensedSemiBold }}>
                765038728503717436
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsScreenLayout;
