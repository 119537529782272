import { isEmpty } from 'lodash';

export const getNameInitials = (name: string): string => {
  if (!name || !name.length) return '';
  const nameSplit = name.split(' ').filter((x) => !isEmpty(x));
  const initials =
    nameSplit.length === 1
      ? nameSplit[0].substring(0, 2)
      : nameSplit[0][0] + nameSplit[1][0];
  return initials.toUpperCase();
};
