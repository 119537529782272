import { Environment } from '../types';
import { getConfigValue } from '@zonofi/common';

export const getConfigBasedHeaderText = (text: string) => {
  const isPharmConnect = getConfigValue('ENV') === Environment.PHARM_CONNECT;
  switch (text) {
    case 'PTS':
      return isPharmConnect ? text : 'Buying Price';
    case 'PTR':
      return isPharmConnect ? text : 'Selling Price';
    default:
      return text;
  }
};
