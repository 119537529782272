import { zonoApi } from '../api';
import { CustomerInviteResponse } from './customer-invite.interface';

export const customerInviteApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerInviteDetails: builder.query<CustomerInviteResponse, string>({
      query: (id) => ({
        url: `invite/${id}`,
        method: 'GET',
      }),
      providesTags: ['CustomerInvite'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCustomerInviteDetailsQuery,
  useLazyGetCustomerInviteDetailsQuery,
} = customerInviteApi;
