import _ from 'lodash';
import { ValidationResult, ValidationRule } from '../types';

export const useInput = () => {
  const checkValue = (
    text: string,
    setValue: (value: React.SetStateAction<string>) => void,
    validationRules: ValidationRule[],
    setError: React.Dispatch<React.SetStateAction<string>>,
    onValidate: ((isValid: boolean, text: string) => void) | undefined
  ) => {
    setValue(text);
    const result: ValidationResult[] = [];
    const errorString: string[] = [];

    if (validationRules) {
      for (let i = 0; i < validationRules.length; i += 1) {
        const checkResult: ValidationResult = validationRules[i](text);
        if (!checkResult.success) {
          result.push(checkResult);
          if (checkResult.error) {
            errorString.push(
              [checkResult.error].toString() || checkResult.error
            );
          }
        }
      }
    }

    setError(errorString.join('; '));

    if (!_.isUndefined(onValidate)) {
      onValidate(result.length === 0, text);
    }
  };

  return {
    checkValue,
  };
};
