import _ from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { View, Text, TextStyle, StyleProp, ViewStyle } from 'react-native';
import { ThemeContext } from '../contexts/ThemeContext';
import Popup from 'reactjs-popup';
import Icon from 'react-native-easy-icon';
import { colors } from '../colors/colors';

interface TogglePopUpProps {
  heading?: string;
  value: any;
  color?: string;
  headingStyles?: StyleProp<TextStyle>;
  contentStyles?: StyleProp<TextStyle>;
  iconStyles?: StyleProp<ViewStyle>;
}
export const TogglePopUp: React.FC<TogglePopUpProps> = ({
  heading,
  value,
  color,
  headingStyles,
  contentStyles,
  iconStyles,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const popupRef = useRef() as any;
  const [modal, setModal] = useState(false);
  return !_.isNull(value) && !_.isUndefined(value) && value?.length > 1 ? (
    <Popup
      ref={popupRef}
      trigger={
        <View
          style={[iconStyles, { marginTop: 5, marginLeft: 4, zIndex: 0 }]}
        >
          <Icon
            name="info"
            type="foundation"
            size={16}
            color={
              color
                ? modal
                  ? '#33A7E8'
                  : '#ca3530'
                : modal
                ? colors.vividSkyBlue.color
                : '#707D92' 
            }
          />
        </View>
      }
      open={modal}
      onOpen={() => setModal(true)}
      on={['focus', 'hover']}
      onClose={() => setModal(false)}
      position={['bottom right', 'bottom right']}
      contentStyle={{ 
        zIndex: 99999, 
        position: 'absolute', 
        top: 5,
        left: -50,
        width: 'auto',
        maxWidth: '90%',
        overflow: 'visible', 
      }}
    >
      <View
        style={{
          backgroundColor: theme?.menu?.background,
          borderColor: theme.enableEdit ? 'transparent' : '#DFE0E0',
          height: 'auto',
          width: 'auto',
          shadowColor: '#00000029',
          shadowOffset: {
            width: 0,
            height: 3,
          },
          flexDirection: 'column',
          shadowOpacity: 0.6,
          shadowRadius: 6,
          padding: 10,
          borderRadius: 6,
        }}
      >
        {heading && (
          <Text
            style={[
              {
                fontSize: 16,
                color: theme.text.primary,
                fontFamily: theme.fontConfig.semiBold.fontFamily,
              },
              headingStyles,
            ]}
          >
            {heading}
          </Text>
        )}
        <Text
          style={[
            {
              color: '#808285',
              fontSize: 14,
              marginTop: 3,
              fontFamily: theme.fontConfig.regular.fontFamily,
            },
            contentStyles,
          ]}
        >
          {value}
        </Text>
      </View>
    </Popup>
  ) : null;
};
