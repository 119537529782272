import { DimensionValue, StyleProp, View, ViewStyle } from 'react-native';
import Popup from 'reactjs-popup';
import React from 'react';
import { EventType, PopupPosition } from 'reactjs-popup/dist/types';
import { colors } from '../colors/colors';

/* eslint-disable-next-line */
export interface CustomPopUpProps {
  icon: JSX.Element;
  iconStyles?: object;
  topContainerStyles?: object;
  event: EventType | EventType[];
  content?: JSX.Element;
  height?: number;
  width?: number;
  arrow?: boolean;
  color?: string;
  padding?: DimensionValue | undefined | any;
  border?: {
    borderWidth?: number;
    borderColor?: string;
    borderRadius?: number;
    borderStyle?: 'dashed' | 'dotted' | 'solid';
  };
  onOpen?: (event?: React.SyntheticEvent<Element, Event> | undefined) => void;
  onClose?: () => void;
  popupRef?: any;
  position?: PopupPosition | PopupPosition[];
  open?: boolean;
  boxShadow?: boolean;
  shadowColor?: any;
  shadowOpacity?: any;
  shadowRadius?: any;
  shadowOffset?: {
    height: number;
    width: number;
  };
  borderRadius?: number;
  disabled?: boolean;
  arrowStyles?: React.CSSProperties;
  containerStyles?: StyleProp<ViewStyle>;
}

export const CustomPopUp: React.FC<CustomPopUpProps> = ({
  icon,
  iconStyles,
  event,
  content,
  height,
  width,
  arrow,
  color,
  padding,
  border,
  onOpen,
  onClose,
  popupRef,
  position,
  open,
  boxShadow = true,
  shadowColor,
  shadowOpacity,
  shadowRadius,
  shadowOffset,
  borderRadius,
  disabled,
  arrowStyles,
  containerStyles,
  topContainerStyles,
}) => {
  return (
    <View style={topContainerStyles}>
      <Popup
        ref={popupRef}
        trigger={<View style={iconStyles}>{icon}</View>}
        on={event}
        position={position ?? ['bottom center', 'bottom right', 'bottom left']}
        closeOnDocumentClick
        contentStyle={{
          zIndex: 99999,
        }}
        arrowStyle={{
          color: color,
          ...arrowStyles,
        }}
        arrow={arrow}
        open={open}
        onOpen={onOpen}
        onClose={() => {
          onClose && onClose();
          popupRef?.current?.close();
        }}
        disabled={disabled}
      >
        <View
          style={[
            border,
            {
              backgroundColor: color,
              padding: padding,
              justifyContent: 'center',
              alignItems: 'center',
              height: height,
              width: width,
              borderRadius: borderRadius ?? 0,
            },
            boxShadow && {
              shadowColor: shadowColor ?? colors.black.color,
              shadowOpacity: shadowOpacity ?? 0.08,
              shadowRadius: shadowRadius ?? 12,
              shadowOffset: shadowOffset ?? { height: 4, width: 0 },
            },
            containerStyles,
          ]}
        >
          {content}
        </View>
      </Popup>
    </View>
  );
};

export default CustomPopUp;
