import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customerInviteApi } from './customer-invite-api';
import {
  CustomerInviteResponse,
  CustInviteRootState,
  LastLinkClicked,
} from './customer-invite.interface';

export const CUSTOMER_INVITE_FEATURE_KEY = 'customerInvite';

export interface CustomerInviteState {
  customerData: CustomerInviteResponse;
  error: string;
  lastLinkClicked: LastLinkClicked;
}

export const initialCustomerInviteState: CustomerInviteState = {
  customerData: {
    inviteId: '',
    seller: {
      name: '',
      phone: '',
      workspaceId: '',
    },
    buyer: {
      name: '',
      phone: '',
      workspaceId: '',
    },
  },
  error: '',
  lastLinkClicked: {
    path: '',
    inviteId: '',
  },
};
export const customerInviteSlice = createSlice({
  name: CUSTOMER_INVITE_FEATURE_KEY,
  initialState: initialCustomerInviteState,
  reducers: {
    onSuccess(state, action: PayloadAction<CustomerInviteResponse>) {
      state.customerData = action.payload;
    },

    updateLastlinkClicked(state, action: PayloadAction<LastLinkClicked>) {
      state.lastLinkClicked = action.payload;
    },
    updateBuyerWorkSpaceId(state, action: PayloadAction<string>) {
      if (state.customerData) {
        if (state.customerData.buyer) {
          state.customerData.buyer.workspaceId = action.payload;
        } else {
          state.customerData.buyer = {
            name: '',
            phone: '',
            workspaceId: action.payload,
          };
        }
      }
    },
    updateSellerWorkSpaceIdAndInviteId(
      state,
      action: PayloadAction<{ sellerWorkSpaceId: string; inviteId: string }>
    ) {
      if (state.customerData) {
        state.customerData.inviteId = action.payload.inviteId;
        if (state.customerData.seller) {
          state.customerData.seller.workspaceId =
            action.payload.sellerWorkSpaceId;
        } else {
          state.customerData.seller = {
            name: '',
            phone: '',
            workspaceId: action.payload.sellerWorkSpaceId,
          };
        }
      }
    },
    resetCustomerInviteData(state, action: PayloadAction<void>) {
      state.customerData = initialCustomerInviteState.customerData;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      customerInviteApi.endpoints.getCustomerInviteDetails.matchFulfilled,
      (state, action) => {
        customerInviteSlice.caseReducers.onSuccess(state, action);
      }
    );
  },
});

export const customerInviteReducer = customerInviteSlice.reducer;

export const {
  onSuccess,
  updateLastlinkClicked,
  updateBuyerWorkSpaceId,
  updateSellerWorkSpaceIdAndInviteId,
  resetCustomerInviteData,
} = customerInviteSlice.actions;

export const getCustomerInviteState = (
  rootState: CustInviteRootState
): CustomerInviteState => rootState[CUSTOMER_INVITE_FEATURE_KEY];

export const getCustomerInvite = createSelector(
  getCustomerInviteState,
  (state) => state.customerData
);

export const getLastLinkClicked = createSelector(
  getCustomerInviteState,
  (state) => state?.lastLinkClicked
);
