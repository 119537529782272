import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';

function PendingPayment(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="303.121"
      height="196.31"
      viewBox="0 0 303.121 196.31"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_26757"
            data-name="Rectangle 26757"
            width="303.121"
            height="196.31"
            transform="translate(0 -0.104)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_26766"
            data-name="Rectangle 26766"
            width="9.619"
            height="9.496"
            fill="#fff"
            stroke="#000"
            stroke-width="1"
          />
        </clipPath>
      </defs>
      <G
        id="Group_115742"
        data-name="Group 115742"
        transform="translate(-5543.879 324.415)"
      >
        <G
          id="Group_115691"
          data-name="Group 115691"
          transform="translate(5542.878 -325.31)"
        >
          <G
            id="Group_115684"
            data-name="Group 115684"
            transform="translate(1 1)"
          >
            <G
              id="Mask_Group_115683"
              data-name="Mask Group 115683"
              transform="translate(0)"
              clip-path="url(#clip-path)"
            >
              <Path
                id="Path_198544"
                data-name="Path 198544"
                d="M418.126,208.8h71.583a8.859,8.859,0,0,0,8.859-8.859V31.856A8.859,8.859,0,0,0,489.708,23H418.126a8.859,8.859,0,0,0-8.86,8.859V199.942a8.859,8.859,0,0,0,8.86,8.859"
                transform="translate(-320.144 -17.989)"
              />
              <Path
                id="Path_198545"
                data-name="Path 198545"
                d="M499.217,38.913,486.3,39c.183,3.43-.686,5.946-4.088,5.946H448.128c-3.4,0-3.739-2.671-3.911-6.032H431.126a7.184,7.184,0,0,0-7.185,7.185V211.052a7.185,7.185,0,0,0,7.185,7.185h68.091a7.185,7.185,0,0,0,7.185-7.185V46.1a7.185,7.185,0,0,0-7.185-7.185"
                transform="translate(-331.625 -30.439)"
                fill="#fefefe"
              />
              <Path
                id="Path_198546"
                data-name="Path 198546"
                d="M498.827,217.954H430.736a7.3,7.3,0,0,1-7.293-7.293V45.706a7.3,7.3,0,0,1,7.293-7.293h10.752l2.361.066c.17,3.324.557,5.965,3.888,5.965h34.088c3.332,0,3.245-2.707,3.415-6.032v-.169l13.586.169a7.3,7.3,0,0,1,7.294,7.293V210.661a7.3,7.3,0,0,1-7.294,7.293M430.736,38.631a7.084,7.084,0,0,0-7.075,7.076V210.661a7.084,7.084,0,0,0,7.075,7.076h68.091a7.084,7.084,0,0,0,7.076-7.076V45.706a7.084,7.084,0,0,0-7.076-7.076H485.241c-.227,3.391,0,6.032-3.415,6.032H447.738c-3.413,0-3.662-2.641-3.888-6.032Z"
                transform="translate(-331.234 -30.048)"
              />
              <rect
                id="Rectangle_26745"
                data-name="Rectangle 26745"
                width="12.694"
                height="1.709"
                transform="translate(124.954 9.176)"
                fill="#fff"
              />
              <Path
                id="Path_198547"
                data-name="Path 198547"
                d="M643.762,41.237a1.343,1.343,0,1,1-1.343-1.343,1.343,1.343,0,0,1,1.343,1.343"
                transform="translate(-501.476 -31.207)"
                fill="#fff"
              />
              <Path
                id="Path_198548"
                data-name="Path 198548"
                d="M989.126,422.783a165.62,165.62,0,0,1-6.363,29.74l-2.728-.341s.1-3.793.357-9.488c.673-15.082,2.409-43.5,6.122-50.184,0,0,4.965,8.755,2.612,30.272"
                transform="translate(-766.623 -307.038)"
                fill="#fff"
              />
              <Path
                id="Path_198549"
                data-name="Path 198549"
                d="M981.794,450.484l-3.3-.412.008-.3c0-.038.105-3.862.356-9.493.711-15.934,2.458-43.66,6.163-50.328l.283-.509.287.506c.2.361,4.99,9.092,2.653,30.469a167.2,167.2,0,0,1-6.375,29.8Zm-2.626-.987,2.16.27A166.13,166.13,0,0,0,987.6,420.35c1.985-18.157-1.291-27.23-2.294-29.5-3.441,7.463-5.1,34.014-5.789,49.459-.211,4.733-.318,8.186-.348,9.186"
                transform="translate(-765.421 -304.64)"
              />
              <Path
                id="Path_198550"
                data-name="Path 198550"
                d="M985.557,455.316l-.572-.005A434.945,434.945,0,0,1,990.1,391.5l.563.1a434.337,434.337,0,0,0-5.111,63.716"
                transform="translate(-770.495 -306.248)"
              />
              <Path
                id="Path_198551"
                data-name="Path 198551"
                d="M989.126,531.528a165.62,165.62,0,0,1-6.363,29.74l-2.728-.341s.1-3.793.357-9.488a130.789,130.789,0,0,1,8.734-19.911"
                transform="translate(-766.623 -415.783)"
              />
              <Path
                id="Path_198552"
                data-name="Path 198552"
                d="M982.384,545.646s9.547-26.085,15.515-32.564c0,0,3.921,16.026-13.469,33.416Z"
                transform="translate(-768.461 -401.353)"
                fill="#fff"
              />
              <Path
                id="Path_198553"
                data-name="Path 198553"
                d="M983.018,544.479l-2.537-1.057.108-.294c.39-1.067,9.638-26.22,15.581-32.673l.413-.448.145.592a26.158,26.158,0,0,1-.21,10.326c-1.1,5.684-4.3,14.357-13.345,23.4Zm-1.707-1.419,1.554.648c8.807-8.868,11.938-17.344,13.012-22.905a27.94,27.94,0,0,0,.337-9.4c-5.529,6.685-13.857,28.843-14.9,31.659"
                transform="translate(-766.972 -398.949)"
              />
              <Path
                id="Path_198554"
                data-name="Path 198554"
                d="M908.342,436.355a105.931,105.931,0,0,1,16.026,50.124h-.511s-18.584-31.029-15.515-50.124"
                transform="translate(-710.274 -341.335)"
                fill="#fff"
              />
              <Path
                id="Path_198555"
                data-name="Path 198555"
                d="M923.556,483.727h-1.039l-.1-.159a159.388,159.388,0,0,1-8.929-17.943c-3.905-9.295-8.225-22.461-6.628-32.4l.129-.805.462.672a91.845,91.845,0,0,1,7.532,14.313,105.537,105.537,0,0,1,8.551,35.98ZM907.389,434.18c-2.249,18.034,13.952,46.25,15.48,48.86a105.034,105.034,0,0,0-8.473-35.361,97.062,97.062,0,0,0-7.008-13.5"
                transform="translate(-709.12 -338.256)"
              />
              <Path
                id="Path_198556"
                data-name="Path 198556"
                d="M923.9,486.042a175.427,175.427,0,0,0-15.485-50.034l.505-.267a176.017,176.017,0,0,1,15.545,50.215Z"
                transform="translate(-710.596 -340.854)"
              />
              <Path
                id="Path_198557"
                data-name="Path 198557"
                d="M979.728,549.07l-.539-.19a250.334,250.334,0,0,1,15.962-36.446l.5.282a249.7,249.7,0,0,0-15.92,36.353"
                transform="translate(-765.961 -400.847)"
              />
              <Path
                id="Path_198558"
                data-name="Path 198558"
                d="M956.038,657.924l-1.122,13.735-3.14,38.435h-12.1l-4.262-52.17Z"
                transform="translate(-731.714 -514.654)"
                fill="#d56451"
              />
              <Path
                id="Path_198559"
                data-name="Path 198559"
                d="M950.8,709.247H938.1l-4.316-52.823h21.338Zm-12.1-.653h11.5l4.209-51.517h-19.92Z"
                transform="translate(-730.44 -513.481)"
              />
              <Path
                id="Path_198560"
                data-name="Path 198560"
                d="M983.443,721,980.3,759.432h-8.426Z"
                transform="translate(-760.241 -563.992)"
                fill="#a1a3b3"
              />
              <Path
                id="Path_198561"
                data-name="Path 198561"
                d="M1158.228,90.774s-.115,2.475-.326,4.142a1.645,1.645,0,0,1-1.878,1.455l-.887-4Z"
                transform="translate(-903.595 -71.007)"
              />
              <Path
                id="Path_198562"
                data-name="Path 198562"
                d="M1114.925,73.532l-10.041,1.2c-.928-4.267-.781-4.432-1.289-7.064-.135-.689-.312-1.546-.568-2.69a.432.432,0,0,0-.009-.044c-7.7,3.639-7.278-13.944-7.278-13.944s.837-4.605,7.367-6.434,7.217,6.9,7.217,6.9c4.031.086.484,5.638.277,6.089Z"
                transform="translate(-857.128 -34.655)"
                fill="#fff"
              />
              <Path
                id="Path_198563"
                data-name="Path 198563"
                d="M1103.45,73.917l-.063-.29c-.574-2.64-.74-3.725-.9-4.774-.1-.666-.2-1.295-.391-2.3-.144-.73-.3-1.5-.49-2.337a3.723,3.723,0,0,1-3.582-.187c-4.018-2.612-3.8-13.75-3.79-14.223l.005-.051c.036-.2.95-4.828,7.6-6.69a5.111,5.111,0,0,1,4.49.564c2.379,1.662,2.984,5.288,3.111,6.34a2,2,0,0,1,1.688.928c.784,1.372-.355,3.723-1.28,5.371l-.078.139,4.39,16.229Zm-1.281-10.175c.219.977.4,1.856.568,2.688.2,1.017.3,1.682.4,2.325.152,1,.31,2.032.829,4.443l9.37-1.119-4.257-15.737.049-.108c.022-.047.076-.145.154-.284,1.31-2.335,1.741-3.925,1.283-4.728a1.531,1.531,0,0,0-1.424-.615l-.3-.006-.023-.294c0-.044-.378-4.407-2.865-6.142a4.493,4.493,0,0,0-3.938-.469c-6.03,1.688-7.055,5.814-7.129,6.156-.01.63-.129,11.278,3.494,13.632a3.223,3.223,0,0,0,3.319-.019l.365-.172.093.393c.005.022.009.041.012.058"
                transform="translate(-855.95 -33.482)"
              />
              <Path
                id="Path_198564"
                data-name="Path 198564"
                d="M1093.018,27.173s-5.6-5.5-13.087,3.443c-.663.792-1.479.865-3.061,2.089a2.239,2.239,0,0,0,.545,3.854c2.415.941,5.17,1.16,9.947-2.282-1.833,2.715-5.653,3.474-5.653,3.474,7.7-.948,8.552-4.329,8.639-4.4a7.753,7.753,0,0,0,2.828,5.765,2.452,2.452,0,0,1,.465-1.477c.638-.775,1.74-.81,2.925-.062,0,0,2.409-9.6-3.548-10.4"
                transform="translate(-841.691 -19.947)"
              />
              <Path
                id="Path_198565"
                data-name="Path 198565"
                d="M1101.917,79.733l-3.4.406a2.475,2.475,0,0,0-4.536,1.44l-1.365.163a2.478,2.478,0,1,0,.053.447l1.365-.163a2.477,2.477,0,1,0,4.711-1.46l3.225-.385Zm-11.482,4.538a2.027,2.027,0,1,1,1.773-2.253,2.03,2.03,0,0,1-1.773,2.253m6.263-.748a2.027,2.027,0,1,1,1.773-2.253,2.029,2.029,0,0,1-1.773,2.253"
                transform="translate(-850.856 -61.822)"
              />
              <Path
                id="Path_198566"
                data-name="Path 198566"
                d="M1133.921,117.115c-1.435,4.485-3.544,6.575-4.915,7.508-.135-.689-.53-1.764-.786-2.908a17.177,17.177,0,0,0,5.7-4.6"
                transform="translate(-882.538 -91.612)"
              />
              <Path
                id="Path_198567"
                data-name="Path 198567"
                d="M1247.091,827.539h-7.763l-1.141-4.364-2.267-8.667,9.2-.128,1.13,7.565Z"
                transform="translate(-966.786 -637.041)"
                fill="#fff"
              />
              <Path
                id="Path_198568"
                data-name="Path 198568"
                d="M1245.956,826.678h-8.394L1233.984,813l9.907-.138Zm-7.89-.653h7.132l-1.868-12.5-8.5.119Z"
                transform="translate(-965.272 -635.853)"
              />
              <Path
                id="Path_198569"
                data-name="Path 198569"
                d="M1246.255,821.945l-8.068,1.23-2.267-8.667,9.2-.128Z"
                transform="translate(-966.786 -637.041)"
              />
              <Path
                id="Path_198570"
                data-name="Path 198570"
                d="M1208.751,862.04c-.679.708-3.445,3.038-8.025-.14a.132.132,0,0,0-.136-.009l-12.628,6.75a.643.643,0,0,0,.3,1.211h20.6a1.48,1.48,0,0,0,1.444-1.807l-1.342-5.942a.13.13,0,0,0-.22-.063"
                transform="translate(-929.005 -674.194)"
                fill="#a1a3b3"
              />
              <Path
                id="Path_198571"
                data-name="Path 198571"
                d="M1086.125,814.38l-.806,8.164-.494,5h-7.763l-.046-4.131-.1-8.9Z"
                transform="translate(-842.41 -637.041)"
                fill="#fff"
              />
              <Path
                id="Path_198572"
                data-name="Path 198572"
                d="M1083.935,826.674h-8.382l-.149-13.68,9.9-.138Zm-7.736-.653h7.144l1.235-12.5-8.514.119Z"
                transform="translate(-841.225 -635.849)"
              />
              <Path
                id="Path_198573"
                data-name="Path 198573"
                d="M1086.125,814.38l-.806,8.164-8.3.865-.1-8.9Z"
                transform="translate(-842.41 -637.041)"
              />
              <Path
                id="Path_198574"
                data-name="Path 198574"
                d="M1038.751,862.04c-.679.708-3.445,3.038-8.025-.14a.131.131,0,0,0-.136-.009l-12.628,6.75a.643.643,0,0,0,.3,1.211h20.6a1.481,1.481,0,0,0,1.444-1.807l-1.342-5.942a.13.13,0,0,0-.221-.063"
                transform="translate(-796.024 -674.194)"
                fill="#a1a3b3"
              />
              <Path
                id="Path_198575"
                data-name="Path 198575"
                d="M1082.411,387.33l-9.4,32.655-5.215,50.032-14.938.005,3.726-50.631,8.51-47.994,18.8-5.937Z"
                transform="translate(-823.588 -285.878)"
                fill="#fff"
              />
              <Path
                id="Path_198575_-_Outline"
                data-name="Path 198575 - Outline"
                d="M1082.69,362.671l-1.531,22.553-.013.045-9.391,32.618-5.251,50.384-15.8.006.034-.468,3.728-50.653,0-.022,8.557-48.254Zm-2.393,22.4,1.438-21.186-17.941,5.666-8.461,47.712-3.69,50.141,14.076-.005,5.178-49.68.011-.037Z"
                transform="translate(-821.904 -283.696)"
              />
              <Path
                id="Path_198576"
                data-name="Path 198576"
                d="M1159.134,353.455l3.274,49.676,10.064,55.658h13.953l-6.69-52.3s2.866-43.739.008-55.119Z"
                transform="translate(-906.721 -274.859)"
                fill="#fff"
              />
              <Path
                id="Path_198577"
                data-name="Path 198577"
                d="M1185.55,457.847h-14.6l-.049-.268L1160.838,401.9v-.019l-3.294-49.991,21.2-2.141.069.274c2.823,11.24.147,52.99,0,55.188Zm-14.051-.653h13.309l-6.646-51.959,0-.032c.028-.434,2.785-43.127.083-54.743l-20.011,2.02,3.252,49.343Z"
                transform="translate(-905.476 -273.59)"
              />
              <Path
                id="Path_198578"
                data-name="Path 198578"
                d="M958.521,210.984l-3.7,22.211-32.81-7.648c-1.156.486-5.681-.518-6.5-.854a1.121,1.121,0,0,1-.577-1.307,1.064,1.064,0,0,1-.272-1.487c-.516-.518.122-1.52.122-1.52a1.949,1.949,0,0,1,.427-1.7l-4.375-1.1c-3.1-1.034-2.824-2.646-2.824-2.646l8.78,1.555a8.86,8.86,0,0,1,7,3.963l20.8.544.993-4.1,2.273-9.4,9.255,3.022Z"
                transform="translate(-710.276 -162.315)"
                fill="#fff"
              />
              <Path
                id="Path_198579"
                data-name="Path 198579"
                d="M953.913,232.084l-33.055-7.7c-1.449.459-5.949-.607-6.646-.891a5.517,5.517,0,0,1-1.116-3.075,1.727,1.727,0,0,1,.159-1.585,2.047,2.047,0,0,1,.252-1.453l-3.95-.99c-3.326-1.11-3.056-2.933-3.043-3.01l.055-.324,9.087,1.609a9.2,9.2,0,0,1,7.147,3.966l20.355.533,3.287-13.588,11.273,3.681Zm-33.1-8.387,32.574,7.593,3.6-21.591-10.067-3.287-3.245,13.414-21.245-.556-.091-.161a8.618,8.618,0,0,0-6.739-3.8l-.035,0-8.362-1.481c.13.46.636,1.293,2.555,1.936l4.875,1.221-.345.4a1.637,1.637,0,0,0-.367,1.379l.054.15-.086.135c-.128.2-.434.845-.166,1.114l.2.2-.172.227a.735.735,0,0,0,.225,1.042l.153.131-.049.2c-.017.07-.152.708.383.924.888.363,5.244,1.278,6.253.855Z"
                transform="translate(-709.104 -160.806)"
              />
              <Path
                id="Path_198580"
                data-name="Path 198580"
                d="M1092.088,210.523l-1.96,8.192-9.568-1.814,2.273-9.4Z"
                transform="translate(-845.258 -162.315)"
              />
              <Path
                id="Path_198581"
                data-name="Path 198581"
                d="M1110.347,169.737l3.367,33.7s-19.067,3.752-26.906,2.445c-5.4-.9-7.8-3.123-8.24-3.569-.05-.05-.074-.078-.074-.078l.094-37.457-6.587-.706,3.717-11.685a6.741,6.741,0,0,1,2.99-3.754l6.06-3.58s3.532,4.549,9.936-2.256l13.12,2.689a6.682,6.682,0,0,1,4.843,4.007l5.781,14.065-8.336,3.813Z"
                transform="translate(-838.562 -111.704)"
                fill="#e85b49"
              />
              <Path
                id="Path_198582"
                data-name="Path 198582"
                d="M1089.421,205.209a26.459,26.459,0,0,1-4.221-.283c-5.508-.917-7.98-3.212-8.42-3.663-.06-.06-.09-.1-.09-.1l-.075-.091,0-.12.093-37.162-6.693-.717,3.839-12.066a7.062,7.062,0,0,1,3.135-3.936l6.308-3.726.176.227a4.114,4.114,0,0,0,2.89,1.358c1.994.09,4.2-1.136,6.55-3.638l.125-.133,13.3,2.726a7,7,0,0,1,5.08,4.2l5.9,14.356-8.409,3.846.212,2.13,3.4,34-.291.057c-.662.13-13.995,2.73-22.8,2.73m-12.155-4.383c.473.477,2.821,2.586,8.04,3.456,7.165,1.194,24.068-1.925,26.5-2.388l-3.6-36,8.262-3.779-5.662-13.775a6.345,6.345,0,0,0-4.606-3.811l-12.943-2.653c-2.452,2.564-4.788,3.815-6.945,3.709a4.771,4.771,0,0,1-3.167-1.393l-5.83,3.444a6.41,6.41,0,0,0-2.845,3.572l-3.6,11.3,6.482.694v.294Z"
                transform="translate(-837.007 -110.423)"
              />
              <Path
                id="Path_198583"
                data-name="Path 198583"
                d="M1083.058,299.688s-.8-14.809-6-18.412l8.953-2.8s7.15-.869,9.859,21.213Z"
                transform="translate(-842.515 -217.83)"
                fill="#fff"
              />
              <Path
                id="Path_198584"
                data-name="Path 198584"
                d="M1093.594,298.836h-13.487l-.017-.309c-.008-.147-.851-14.691-5.863-18.16l-.55-.381,9.649-3.014c.3-.037,7.5-.653,10.223,21.5Zm-12.87-.653h12.131c-2.591-20.5-8.978-20.581-9.427-20.564l-8.323,2.6c4.482,3.895,5.475,15.823,5.619,17.959"
                transform="translate(-839.874 -216.652)"
              />
              <Path
                id="Path_198585"
                data-name="Path 198585"
                d="M1045.808,281.311h12.808s.8-3.019,3.2-3.82l-12.808.218s-2,.4-3.2,3.6"
                transform="translate(-818.073 -217.065)"
              />
              <Path
                id="Path_198586"
                data-name="Path 198586"
                d="M1156.558,263.268l-27.311-.179a22.775,22.775,0,0,1-4.148-.017l-3.771-1.391a.808.808,0,0,1-.535-.642c-.027-.221.055-.447.441-.545l-.645-.213s-1.073-.86-.213-1.289c0,0-1.073-1.289.429-1.5,0,0-.561-.561.235-.986a.562.562,0,0,1,.384-.049l3.083.669,2.84.536-.793-2.445,4.512,2.611,18.51-3.743-2.92-6.687-1.753-4.015,10.642-4.878.59,2.228,4.562,17.2a4.252,4.252,0,0,1-4.137,5.342"
                transform="translate(-876.112 -186.564)"
                fill="#fff"
              />
              <Path
                id="Path_198587"
                data-name="Path 198587"
                d="M1155.413,261.947h-.029l-27.3-.179a22.509,22.509,0,0,1-4.215-.022l-.058-.016-3.771-1.391a1.126,1.126,0,0,1-.746-.909.791.791,0,0,1,.076-.468l-.114-.038-.046-.037c-.122-.1-.726-.613-.666-1.181a.739.739,0,0,1,.208-.438,1.32,1.32,0,0,1-.192-1.1.944.944,0,0,1,.581-.537.811.811,0,0,1-.022-.338,1.006,1.006,0,0,1,.591-.723.888.888,0,0,1,.607-.08l3.083.669,2.29.432-.885-2.732,5.142,2.975,17.992-3.639-4.64-10.626,11.286-5.173,5.251,19.8a4.579,4.579,0,0,1-4.426,5.752m-.025-.653h.025a3.925,3.925,0,0,0,3.795-4.931l-5.053-19.052-10,4.583,4.707,10.78-19.027,3.848-3.882-2.247.7,2.16-3.381-.638-3.092-.671a.239.239,0,0,0-.16.018c-.149.079-.241.167-.252.241a.338.338,0,0,0,.1.229l.437.459-.625.092c-.279.04-.463.125-.505.235-.074.192.147.573.281.735l.264.317-.369.184c-.054.027-.146.081-.152.14-.014.131.182.392.382.567l1.652.547-1.09.274c-.213.053-.2.122-.2.188a.482.482,0,0,0,.324.375l3.742,1.38a22.8,22.8,0,0,0,4.037.009h.029Z"
                transform="translate(-874.94 -184.917)"
              />
              <Path
                id="Path_198588"
                data-name="Path 198588"
                d="M1216.725,225.428l-4.573-9.8.587-.287,4.573,9.8Z"
                transform="translate(-948.195 -168.449)"
              />
              <Path
                id="Path_198589"
                data-name="Path 198589"
                d="M1101.654,211.829,1101,211.8v-8.87l.652.032Z"
                transform="translate(-861.248 -158.737)"
              />
              <Path
                id="Path_198590"
                data-name="Path 198590"
                d="M1245.572,240.728l-9.479,6.666-1.753-4.015,10.642-4.878Z"
                transform="translate(-965.55 -186.564)"
              />
              <Path
                id="Path_198591"
                data-name="Path 198591"
                d="M943.966,267.6a42.964,42.964,0,0,1-4.587-.874l.053-.211a42.761,42.761,0,0,0,4.558.868Z"
                transform="translate(-734.82 -208.479)"
              />
              <Path
                id="Path_198592"
                data-name="Path 198592"
                d="M942.927,273.617a14.154,14.154,0,0,1-4.613-.879l.081-.2a13.921,13.921,0,0,0,4.532.863Z"
                transform="translate(-733.987 -213.189)"
              />
              <Path
                id="Path_198593"
                data-name="Path 198593"
                d="M942.192,280.39a18.462,18.462,0,0,1-2.85-.658l.069-.206a18.3,18.3,0,0,0,2.812.649Z"
                transform="translate(-734.791 -218.657)"
              />
              <rect
                id="Rectangle_26746"
                data-name="Rectangle 26746"
                width="0.218"
                height="4.068"
                transform="translate(244.624 71.095) rotate(-74.476)"
              />
              <rect
                id="Rectangle_26747"
                data-name="Rectangle 26747"
                width="0.218"
                height="4.068"
                transform="translate(244.297 72.51) rotate(-74.476)"
              />
              <rect
                id="Rectangle_26748"
                data-name="Rectangle 26748"
                width="0.218"
                height="2.37"
                transform="matrix(0.251, -0.968, 0.968, 0.251, 244.944, 73.985)"
              />
              <Path
                id="Path_198594"
                data-name="Path 198594"
                d="M1109.92,120.5l-3.92.436s.871,3.049,3.92-.436"
                transform="translate(-865.158 -94.26)"
              />
              <Path
                id="Path_198595"
                data-name="Path 198595"
                d="M484.882,130.413v20.932a3.757,3.757,0,0,0,3.757,3.757H565.39a3.757,3.757,0,0,0,3.757-3.757V130.413a3.757,3.757,0,0,0-3.757-3.757H488.639a3.757,3.757,0,0,0-3.757,3.757"
                transform="translate(-379.294 -99.075)"
                fill="#fff"
              />
              <Path
                id="Path_198596"
                data-name="Path 198596"
                d="M564.216,154.255H487.466a4.088,4.088,0,0,1-4.084-4.084V129.24a4.088,4.088,0,0,1,4.084-4.084h76.751a4.088,4.088,0,0,1,4.084,4.084v20.932a4.088,4.088,0,0,1-4.084,4.084m-76.751-28.446a3.434,3.434,0,0,0-3.43,3.43v20.932a3.434,3.434,0,0,0,3.43,3.43h76.751a3.434,3.434,0,0,0,3.431-3.43V129.24a3.434,3.434,0,0,0-3.431-3.43Z"
                transform="translate(-378.121 -97.902)"
              />
              <Path
                id="Path_198597"
                data-name="Path 198597"
                d="M555.367,164.979a7.514,7.514,0,1,1-7.514-7.514,7.514,7.514,0,0,1,7.514,7.514"
                transform="translate(-422.675 -123.175)"
                fill="#fff"
              />
              <Path
                id="Path_198598"
                data-name="Path 198598"
                d="M546.679,171.646a7.841,7.841,0,1,1,7.841-7.841,7.85,7.85,0,0,1-7.841,7.841m0-15.028a7.187,7.187,0,1,0,7.187,7.187,7.2,7.2,0,0,0-7.187-7.187"
                transform="translate(-421.501 -122.002)"
              />
              <Path
                id="Path_198602"
                data-name="Path 198602"
                d="M735,629.006v25.165a1.96,1.96,0,0,0,1.96,1.96h26.558a1.96,1.96,0,0,0,1.96-1.96V629.006a1.96,1.96,0,0,0-1.96-1.96H736.96a1.96,1.96,0,0,0-1.96,1.96"
                transform="translate(-574.947 -490.501)"
                fill="#fff"
              />
              <Path
                id="Path_198603"
                data-name="Path 198603"
                d="M762.344,655.284H735.786A2.289,2.289,0,0,1,733.5,653V627.833a2.289,2.289,0,0,1,2.286-2.286h26.558a2.289,2.289,0,0,1,2.286,2.286V653a2.289,2.289,0,0,1-2.286,2.286M735.786,626.2a1.635,1.635,0,0,0-1.633,1.633V653a1.635,1.635,0,0,0,1.633,1.633h26.558A1.635,1.635,0,0,0,763.978,653V627.833a1.635,1.635,0,0,0-1.633-1.633Z"
                transform="translate(-573.773 -489.327)"
              />
              <Path
                id="Path_198604"
                data-name="Path 198604"
                d="M770.373,694.416a8.888,8.888,0,0,1-17.773,0h-3a11.887,11.887,0,0,0,23.771,0Z"
                transform="translate(-586.368 -543.2)"
                fill="#00c17f"
              />
              <Path
                id="Path_198605"
                data-name="Path 198605"
                d="M760.3,705.284a12.214,12.214,0,0,1-12.212-12.037l0-.331h3.651l.006.321a8.561,8.561,0,0,0,17.12,0l.006-.321h3.651l0,.331A12.213,12.213,0,0,1,760.3,705.284m-11.549-11.715a11.56,11.56,0,0,0,23.1,0H769.5a9.215,9.215,0,0,1-18.4,0Z"
                transform="translate(-585.178 -542.026)"
              />
              <Path
                id="Path_198606"
                data-name="Path 198606"
                d="M805.753,643.336l-2.365,1.853a8.886,8.886,0,0,0-8.356-3.008l-.533-2.953a11.968,11.968,0,0,1,2.108-.185,11.81,11.81,0,0,1,8.405,3.482q.391.391.741.812"
                transform="translate(-621.489 -499.885)"
                fill="#00c17f"
              />
              <Path
                id="Path_198607"
                data-name="Path 198607"
                d="M801.979,644.466l-.2-.24a8.558,8.558,0,0,0-8.049-2.9l-.321.058-.649-3.6.322-.057a12.341,12.341,0,0,1,2.166-.191,12.133,12.133,0,0,1,8.636,3.577c.267.267.523.548.761.834l.215.258Zm-6.734-3.925a9.2,9.2,0,0,1,6.822,3.026l1.854-1.452c-.161-.182-.329-.361-.5-.534a11.484,11.484,0,0,0-8.174-3.386,11.74,11.74,0,0,0-1.728.128l.417,2.311a9.275,9.275,0,0,1,1.311-.093"
                transform="translate(-620.126 -498.711)"
              />
              <Path
                id="Path_198608"
                data-name="Path 198608"
                d="M840.425,666.352c0,.057,0,.114,0,.171h-3c0-.057,0-.114,0-.171a8.848,8.848,0,0,0-2.108-5.741l2.365-1.852a11.8,11.8,0,0,1,2.741,7.593"
                transform="translate(-653.42 -515.308)"
                fill="#fff"
              />
              <Path
                id="Path_198609"
                data-name="Path 198609"
                d="M839.061,665.224h-3.655l.007-.334c0-.055,0-.109,0-.164a8.561,8.561,0,0,0-2.03-5.53l-.22-.259,2.882-2.258.2.243a12.042,12.042,0,0,1,2.816,7.8c0,.059,0,.118,0,.176Zm-2.994-.653h2.346a11.383,11.383,0,0,0-2.465-6.983l-1.85,1.449a9.213,9.213,0,0,1,1.969,5.534"
                transform="translate(-651.736 -513.682)"
              />
              <Path
                id="Path_198610"
                data-name="Path 198610"
                d="M752.594,651.6c0,.057,0,.114,0,.171h-3c0-.057,0-.114,0-.171a11.894,11.894,0,0,1,9.779-11.7l.533,2.953a8.9,8.9,0,0,0-7.313,8.748"
                transform="translate(-586.363 -500.552)"
                fill="#fff"
              />
              <Path
                id="Path_198611"
                data-name="Path 198611"
                d="M751.756,650.734H748.1l0-.322c0-.059,0-.117,0-.176a12.222,12.222,0,0,1,10.048-12.023l.321-.057.649,3.6-.322.058a8.552,8.552,0,0,0-7.044,8.427c0,.055,0,.11,0,.164Zm-3.007-.653h2.345a9.2,9.2,0,0,1,7.26-8.851l-.417-2.312a11.57,11.57,0,0,0-9.188,11.162"
                transform="translate(-585.19 -499.191)"
              />
              <Path
                id="Path_198612"
                data-name="Path 198612"
                d="M579.526,639.624H552.96a1.96,1.96,0,0,1-1.96-1.96v-17.2a1.96,1.96,0,0,1,1.96-1.96h26.567a1.96,1.96,0,0,1,1.96,1.96v17.2a1.96,1.96,0,0,1-1.96,1.96"
                transform="translate(-431.014 -483.816)"
                fill="#fff"
              />
              <Path
                id="Path_198613"
                data-name="Path 198613"
                d="M578.353,638.778H551.786a2.289,2.289,0,0,1-2.286-2.286v-17.2A2.289,2.289,0,0,1,551.786,617h26.567a2.289,2.289,0,0,1,2.286,2.286v17.2a2.289,2.289,0,0,1-2.286,2.286m-26.567-21.124a1.635,1.635,0,0,0-1.633,1.633v17.2a1.635,1.635,0,0,0,1.633,1.633h26.567a1.635,1.635,0,0,0,1.633-1.633v-17.2a1.635,1.635,0,0,0-1.633-1.633Z"
                transform="translate(-429.841 -482.642)"
              />
              <Path
                id="Path_198614"
                data-name="Path 198614"
                d="M147.325,813.627l-2.609,9.271-1.043,3.706h-7.732l.761-4.5,1.456-8.605Z"
                transform="translate(-106.339 -636.353)"
                fill="#fff"
              />
              <Path
                id="Path_198615"
                data-name="Path 198615"
                d="M142.533,825.743h-8.366l2.329-13.761,9.871.137Zm-7.592-.653h7.1l3.47-12.329-8.461-.118Z"
                transform="translate(-104.951 -635.165)"
                fill="#191919"
              />
              <Path
                id="Path_198616"
                data-name="Path 198616"
                d="M150.06,813.627l-2.609,9.271-8.013-.792,1.456-8.605Z"
                transform="translate(-109.074 -636.353)"
              />
              <Path
                id="Path_198617"
                data-name="Path 198617"
                d="M132.778,860.963c.676.7,3.431,3.026,7.992-.139a.13.13,0,0,1,.135-.009l12.576,6.722a.641.641,0,0,1-.3,1.206H132.66a1.475,1.475,0,0,1-1.438-1.8l1.336-5.918a.13.13,0,0,1,.22-.062"
                transform="translate(-102.618 -673.353)"
                fill="#a1a3b3"
              />
              <Path
                id="Path_198618"
                data-name="Path 198618"
                d="M241.85,813.627l-1.683,9.1-.718,3.879h-7.731l.336-4.545.63-8.559Z"
                transform="translate(-181.259 -636.353)"
                fill="#fff"
              />
              <Path
                id="Path_198619"
                data-name="Path 198619"
                d="M238.459,825.741H230.1l1.014-13.761,9.861.137Zm-7.651-.653h7.108l2.28-12.329-8.471-.118Z"
                transform="translate(-179.997 -635.164)"
                fill="#191919"
              />
              <Path
                id="Path_198620"
                data-name="Path 198620"
                d="M243.056,813.627l-1.683,9.1-8.113-.665.63-8.559Z"
                transform="translate(-182.466 -636.353)"
              />
              <Path
                id="Path_198621"
                data-name="Path 198621"
                d="M226.639,860.963c.676.7,3.431,3.026,7.992-.139a.13.13,0,0,1,.135-.009l12.576,6.722a.641.641,0,0,1-.3,1.206h-20.52a1.475,1.475,0,0,1-1.438-1.8l1.336-5.918a.13.13,0,0,1,.22-.062"
                transform="translate(-176.04 -673.353)"
                fill="#a1a3b3"
              />
              <Path
                id="Path_198622"
                data-name="Path 198622"
                d="M236.077,116.037s5.385-.875,5.181,4.5a45.347,45.347,0,0,1-2.16,11.1,10.734,10.734,0,0,0,.737,6.29s-9.077-1.234-10.59-5.48l.369-15.541Z"
                transform="translate(-179.324 -90.728)"
              />
              <Path
                id="Path_198623"
                data-name="Path 198623"
                d="M211.215,151.37l-.485-14.628s8.312,2.2,5.187-13.724a6.356,6.356,0,0,0-7.156-1.172,7.347,7.347,0,0,0-4.195,5.8L200.391,150.1Z"
                transform="translate(-156.754 -94.793)"
                fill="#fff"
              />
              <Path
                id="Path_198624"
                data-name="Path 198624"
                d="M210.171,150.585l-11.548-1.354,4.235-22.775a7.626,7.626,0,0,1,4.373-6.055c4.178-2.094,7.5,1.2,7.535,1.234l.069.07.019.1c1.289,6.566.827,10.941-1.373,13a4.662,4.662,0,0,1-3.795,1.176Zm-10.778-1.921,10.1,1.184-.487-14.687.425.112a4.063,4.063,0,0,0,3.611-.951c1.382-1.3,2.706-4.491,1.191-12.294a5.939,5.939,0,0,0-6.709-1.043,7.058,7.058,0,0,0-4.015,5.526l-.005.044Z"
                transform="translate(-155.371 -93.638)"
              />
              <Path
                id="Path_198625"
                data-name="Path 198625"
                d="M208,109.23a13.705,13.705,0,0,1-3.357,3.27c-1.563.96-3.44.909-4.137,1.3a20.808,20.808,0,0,0,2,14.2,12.714,12.714,0,0,1-9.557-5.168,23.472,23.472,0,0,0,1.488,2.389s-6.76.279-2.66-14.924c2.654-9.839,14.364-5.062,16.118-3.4Z"
                transform="translate(-148.98 -81.567)"
              />
              <Path
                id="Path_198626"
                data-name="Path 198626"
                d="M239.569,438.5l1.307,30.486-6.494,58.131H221.355l2.673-53.01-1.444-34.955Z"
                transform="translate(-173.153 -343.012)"
                fill="#fff"
              />
              <Path
                id="Path_198626_-_Outline"
                data-name="Path 198626 - Outline"
                d="M238.341,436.425l1.327,30.953,0,.033-6.537,58.518H219.252l.023-.457,2.672-52.99-1.461-35.371Zm.453,30.923-1.287-30.02-16.116.62,1.426,34.538v.02l-2.65,52.552h12.179Z"
                transform="translate(-171.507 -341.389)"
              />
              <Path
                id="Path_198627"
                data-name="Path 198627"
                d="M163.56,422.635l-4.425,34.588-13.443,57.484h-11.71l7.989-59.316S139.1,436,140.845,425.545Z"
                transform="translate(-104.806 -330.602)"
                fill="#fff"
              />
              <Path
                id="Path_198628"
                data-name="Path 198628"
                d="M144.609,513.677H132.266l.05-.37,7.982-59.271c-.154-1.065-2.807-19.766-1.118-29.9l.04-.239,23.375-3-4.485,35.04Zm-11.6-.653h11.078l13.38-57.216,4.37-34.152-22.054,2.826c-1.632,10.339,1.136,29.313,1.165,29.505l.007.046-.006.046Z"
                transform="translate(-103.464 -329.245)"
              />
              <Path
                id="Path_198629"
                data-name="Path 198629"
                d="M162,249.7h-3.266L162,269.952a7.057,7.057,0,0,1-1.387,1.494c-1.531,1.3-4.486,3.068-9.61,3.624-.187.02-.379.039-.568.057-.122.011-.242.022-.364.03-9.767.756-20.3-3.027-20.3-3.027l1.546-21.367.414-7.6L121.5,242.3l4.5-12.9a9.358,9.358,0,0,1,5.135-4.793l8.112-3.107s7.186,7.622,10.452.436l13.61,7.73Z"
                transform="translate(-95.042 -173.266)"
                fill="#d56451"
              />
              <Path
                id="Path_198630"
                data-name="Path 198630"
                d="M145.827,274.2a60.617,60.617,0,0,1-17.769-3.145l-.233-.084,1.564-21.614.4-7.273-10.338-.88,4.652-13.323a9.636,9.636,0,0,1,5.314-4.96l8.316-3.186.15.159c.037.039,3.71,3.888,6.828,3.37,1.29-.215,2.329-1.172,3.09-2.845l.148-.325,14.1,8.008-.013.2L160.7,248.65h-3.189l3.224,19.992-.062.1a7.336,7.336,0,0,1-1.455,1.573c-1.622,1.38-4.636,3.142-9.786,3.7-.189.02-.383.04-.573.057l-.031,0c-.114.01-.226.02-.34.028-.883.068-1.775.1-2.664.1m-17.314-3.679c1.6.542,11.143,3.607,19.93,2.928.112-.008.221-.018.33-.027l.03,0c.187-.017.377-.037.563-.056,4.983-.54,7.88-2.227,9.432-3.548a7.573,7.573,0,0,0,1.256-1.32L156.747,248h3.344l1.273-19.524-13.127-7.456a4.775,4.775,0,0,1-3.425,2.9,4.3,4.3,0,0,1-.7.057c-2.9,0-5.846-2.759-6.552-3.465l-7.911,3.031a8.985,8.985,0,0,0-4.949,4.611l-4.357,12.483,10.131.862-.431,7.912Z"
                transform="translate(-93.436 -171.889)"
              />
              <Path
                id="Path_198631"
                data-name="Path 198631"
                d="M227,331.589l5.226,2.178s.653-1.307-1.742-3.266Z"
                transform="translate(-177.569 -258.53)"
              />
              <Path
                id="Path_198632"
                data-name="Path 198632"
                d="M211.5,350.4V348l46.479,1.871-.1,2.7-33.1.653Z"
                transform="translate(-165.444 -272.22)"
              />
              <Path
                id="Path_198633"
                data-name="Path 198633"
                d="M237.179,345,221.5,346.089l11.759,2.4h4.827Z"
                transform="translate(-173.266 -269.873)"
                fill="#fff"
              />
              <Path
                id="Path_198634"
                data-name="Path 198634"
                d="M229.818,347.572l-5.315-.007-14.113-2.875,18.346-1.274Zm-5.217-.653h4.372l-.733-2.813-13.012.9Z"
                transform="translate(-164.576 -268.635)"
              />
              <Path
                id="Path_198635"
                data-name="Path 198635"
                d="M149.726,323.444l-3.049-.653-4.538-.825a.9.9,0,0,0-.492.048l-23.714,12.754c-2.463.869-4.625-1.84-3.92-4.355L116.844,318l8.71.653-2.069,5.988,17.98-6.339a2.391,2.391,0,0,1,1.113-.131c1.071.126,3.3.453,3.978,1.039l3.591,3.1c.455.392-.422,1.137-.422,1.137"
                transform="translate(-89.08 -248.752)"
                fill="#fff"
              />
              <Path
                id="Path_198667"
                data-name="Path 198667"
                d="M8.454,4.209,1.319,0,0,2.535,8.057,4.893Z"
                transform="matrix(-0.985, 0.174, -0.174, -0.985, 36.73, 72.645)"
              />
              <Path
                id="Path_198636"
                data-name="Path 198636"
                d="M115.811,334.014a3.152,3.152,0,0,1-2.169-.9,4.078,4.078,0,0,1-1.119-4.041l2.889-12.671,9.414.706-1.986,5.749,17.342-6.114a2.717,2.717,0,0,1,1.261-.147c1.51.178,3.457.516,4.152,1.115l3.591,3.1a.675.675,0,0,1,.244.527,1.8,1.8,0,0,1-.669,1.106l-.123.1-3.206-.687-4.528-.823a.579.579,0,0,0-.3.025L116.89,333.821l-.024.008a3.165,3.165,0,0,1-1.055.185m.114-16.914-2.768,12.138a3.47,3.47,0,0,0,.932,3.4,2.456,2.456,0,0,0,2.537.58l23.709-12.751.019-.007a1.23,1.23,0,0,1,.67-.065l4.537.825,2.892.62a1.069,1.069,0,0,0,.325-.5c0-.011,0-.015-.017-.03l-3.591-3.1a9.412,9.412,0,0,0-3.8-.962,2.058,2.058,0,0,0-.961.113l-18.623,6.566,2.151-6.228Z"
                transform="translate(-87.905 -247.506)"
              />
              <Path
                id="Path_198637"
                data-name="Path 198637"
                d="M280.011,251.5H308.2a2.117,2.117,0,0,1,2.1,2.348l-2.356,21.061-32.446.436,2.406-21.958a2.117,2.117,0,0,1,2.1-1.887"
                transform="translate(-215.507 -196.733)"
                fill="#fff"
              />
              <Path
                id="Path_198638"
                data-name="Path 198638"
                d="M273.824,274.5l2.447-22.325A2.441,2.441,0,0,1,278.7,250h28.186a2.444,2.444,0,0,1,2.43,2.71l-2.388,21.348Zm4.876-23.849a1.788,1.788,0,0,0-1.78,1.6l-2.366,21.591,31.789-.427,2.324-20.775a1.79,1.79,0,0,0-1.78-1.985Z"
                transform="translate(-214.196 -195.56)"
              />
              <Path
                id="Path_198641"
                data-name="Path 198641"
                d="M362.273,301.3a1.706,1.706,0,1,1-1.245-2.317,1.877,1.877,0,0,1,1.245,2.317"
                transform="translate(-280.778 -233.853)"
                fill="#fff"
              />
              <Path
                id="Path_198642"
                data-name="Path 198642"
                d="M359.331,302.134a1.717,1.717,0,0,1-.336-.033,1.853,1.853,0,0,1-1.268-.98,2.666,2.666,0,0,1,.644-3.22,1.852,1.852,0,0,1,1.548-.417,2.2,2.2,0,0,1,1.5,2.7,2.281,2.281,0,0,1-2.088,1.949m.248-4.031a1.264,1.264,0,0,0-.8.3,2.014,2.014,0,0,0-.481,2.4,1.213,1.213,0,0,0,.821.649,1.747,1.747,0,0,0,.667-3.336,1.078,1.078,0,0,0-.211-.021"
                transform="translate(-279.604 -232.677)"
              />
              <rect
                id="Rectangle_26756"
                data-name="Rectangle 26756"
                width="303.121"
                height="0.871"
                transform="translate(0 195.439)"
              />
            </G>
          </G>
          <rect
            id="Rectangle_26758"
            data-name="Rectangle 26758"
            width="43.036"
            height="53.569"
            transform="translate(105.63 61.953)"
            fill="#fff"
          />
          <Path
            id="Path_198643"
            data-name="Path 198643"
            d="M527.263,337.222h-43.69V283h43.69Zm-43.036-.653H526.61V283.653H484.227Z"
            transform="translate(-378.271 -221.374)"
          />
          <G id="Group_115686" data-name="Group 115686">
            <G id="Group_115709" data-name="Group 115709">
              <Path
                id="Path_198644"
                data-name="Path 198644"
                d="M519.778,314.647a5.661,5.661,0,1,1-5.656-5.666,5.661,5.661,0,0,1,5.656,5.666"
                transform="translate(-397.736 -241.697)"
                fill="#fff"
              />
              <Path
                id="Path_198645"
                data-name="Path 198645"
                d="M512.944,319.455h-.005a5.987,5.987,0,0,1,.005-11.974h.005a5.987,5.987,0,0,1,5.982,5.993h0a5.994,5.994,0,0,1-5.987,5.982m0-11.321a5.334,5.334,0,0,0,0,10.668h0a5.334,5.334,0,0,0,0-10.668Z"
                transform="translate(-396.562 -240.524)"
              />
              <Path
                id="Path_198646"
                data-name="Path 198646"
                d="M522.221,329.171l-2.341-2.345a.5.5,0,1,1,.706-.7l1.65,1.653,3.065-2.944a.5.5,0,1,1,.69.719Z"
                transform="translate(-406.557 -253.987)"
                fill="#00c17f"
              />
              <rect
                id="Rectangle_26759"
                data-name="Rectangle 26759"
                width="8.834"
                height="1.133"
                transform="translate(124.543 70.786)"
                fill="#fff"
              />
              <Path
                id="Path_198650"
                data-name="Path 198650"
                d="M519.778,387.459a5.661,5.661,0,1,1-5.656-5.665,5.661,5.661,0,0,1,5.656,5.665"
                transform="translate(-397.736 -298.655)"
                fill="#fff"
              />
              <Path
                id="Path_198651"
                data-name="Path 198651"
                d="M512.944,392.267h-.005a5.987,5.987,0,0,1,.005-11.974h.005a5.987,5.987,0,0,1-.005,11.974m0-11.321a5.334,5.334,0,0,0,0,10.668h0a5.334,5.334,0,0,0,0-10.668Z"
                transform="translate(-396.562 -297.481)"
              />
              <Path
                id="Path_198652"
                data-name="Path 198652"
                d="M522.221,401.983l-2.341-2.345a.5.5,0,1,1,.706-.7l1.65,1.653,3.065-2.944a.5.5,0,0,1,.69.719Z"
                transform="translate(-406.557 -310.943)"
                fill="#00c17f"
              />
              <Path
                id="Path_198656"
                data-name="Path 198656"
                d="M519.778,460.27a5.661,5.661,0,1,1-5.656-5.665,5.661,5.661,0,0,1,5.656,5.665"
                transform="translate(-397.736 -355.61)"
                fill="#fff"
              />
              <Path
                id="Path_198657"
                data-name="Path 198657"
                d="M512.944,465.079h-.005a5.987,5.987,0,0,1,.005-11.974h.005a5.987,5.987,0,0,1-.005,11.974m0-11.321a5.334,5.334,0,0,0,0,10.668h0a5.334,5.334,0,0,0,0-10.668Z"
                transform="translate(-396.562 -354.437)"
              />
              <Path
                id="Path_198658"
                data-name="Path 198658"
                d="M522.221,474.795l-2.341-2.345a.5.5,0,1,1,.706-.7l1.65,1.653,3.065-2.944a.5.5,0,1,1,.69.719Z"
                transform="translate(-406.557 -367.9)"
                fill="#10c487"
              />
              <Path
                id="Path_198662"
                data-name="Path 198662"
                d="M663.114,503.557a8.057,8.057,0,1,1-8.057-8.057,8.057,8.057,0,0,1,8.057,8.057"
                transform="translate(-506.11 -387.6)"
                fill="#fff"
              />
              <Path
                id="Path_198663"
                data-name="Path 198663"
                d="M653.884,510.767a8.384,8.384,0,1,1,8.384-8.384,8.393,8.393,0,0,1-8.384,8.384m0-16.114a7.73,7.73,0,1,0,7.73,7.73,7.739,7.739,0,0,0-7.73-7.73"
                transform="translate(-504.936 -386.427)"
              />
            </G>
          </G>
        </G>
        <Path
          id="Path_198668"
          data-name="Path 198668"
          d="M1128.5,117.043c.646,2.191,1.312,3.284,1.929,3.74.061-.337.239-.862.354-1.421a5.57,5.57,0,0,1-2.283-2.32"
          transform="matrix(0.961, -0.276, 0.276, 0.961, 4479.245, -85.337)"
        />
        <Path
          id="Path_198669"
          data-name="Path 198669"
          d="M1.279,10.389H4.829a1.948,1.948,0,0,0-.194-.56A1.283,1.283,0,0,0,4.254,9.4a1.84,1.84,0,0,0-.607-.278,3.3,3.3,0,0,0-.86-.1H1.279V8H7.888V9.021h-2.4a2.455,2.455,0,0,1,.348.611,2.849,2.849,0,0,1,.179.757H7.888v1.025H5.978a2.9,2.9,0,0,1-.721,1.5,2.939,2.939,0,0,1-1.438.853v.029a2.6,2.6,0,0,1,.454.271,3.128,3.128,0,0,1,.4.355,4.258,4.258,0,0,1,.377.465q.187.263.4.6L7.309,18.49H5.919l-1.669-2.8q-.242-.4-.457-.71a2.637,2.637,0,0,0-.461-.512,1.709,1.709,0,0,0-.542-.307,2.117,2.117,0,0,0-.692-.1h-.82v-1h1.5a2.086,2.086,0,0,0,.71-.121,2.057,2.057,0,0,0,.6-.337,2.024,2.024,0,0,0,.454-.52,2.152,2.152,0,0,0,.267-.67H1.279Z"
          transform="translate(5687.552 -222)"
        />
        <G
          id="Group_115707"
          data-name="Group 115707"
          transform="translate(0 1)"
        >
          <Path
            id="Path_198689"
            data-name="Path 198689"
            d="M5667.479-184.312h11.869"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_198690"
            data-name="Path 198690"
            d="M5667.479-184.312h22.754"
            transform="translate(0 3)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_198691"
            data-name="Path 198691"
            d="M5667.479-184.312h22.754"
            transform="translate(0 6)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_198692"
            data-name="Path 198692"
            d="M5667.479-184.312h22.754"
            transform="translate(0 9)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
        </G>
        <G
          id="Group_115708"
          data-name="Group 115708"
          transform="translate(18 -104.864)"
        >
          <path
            id="Path_198689-2"
            data-name="Path 198689"
            d="M5667.478-184.312h21.86"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <path
            id="Path_198690-2"
            data-name="Path 198690"
            d="M5667.479-184.312h36.312"
            transform="translate(0 4)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <path
            id="Path_198691-2"
            data-name="Path 198691"
            d="M5667.479-184.312h36.312"
            transform="translate(0 8)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <path
            id="Path_198692-2"
            data-name="Path 198692"
            d="M5667.479-184.312h36.312"
            transform="translate(0 12)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
        </G>
        <path
          id="Path_198693"
          data-name="Path 198693"
          d="M5977.9-281.975h9.468"
          transform="translate(-308.801 28)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <path
          id="Path_198696"
          data-name="Path 198696"
          d="M5977.9-281.975h9.468"
          transform="translate(-308.801 44)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <path
          id="Path_198698"
          data-name="Path 198698"
          d="M5977.9-281.975h9.468"
          transform="translate(-307.521 60)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <path
          id="Path_198694"
          data-name="Path 198694"
          d="M5977.9-281.975h16.375"
          transform="translate(-308.801 31.655)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <path
          id="Path_198695"
          data-name="Path 198695"
          d="M5977.9-281.975h16.375"
          transform="translate(-308.801 47.655)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <path
          id="Path_198697"
          data-name="Path 198697"
          d="M5977.9-281.975h16.375"
          transform="translate(-307.521 63.655)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <G
          id="Group_115730"
          data-name="Group 115730"
          transform="translate(5622.855 -252.455) rotate(-2)"
        >
          <G
            id="Group_115729"
            data-name="Group 115729"
            transform="translate(0 0)"
            clip-path="url(#clip-path-2)"
          >
            <path
              id="Path_198699"
              data-name="Path 198699"
              d="M.42,8.892a2,2,0,0,0,2.149,0s1.164.825,1.79-.032a1.358,1.358,0,0,0,1.611,0,.836.836,0,0,0,1.343-.032A15.388,15.388,0,0,0,9.4,4.037C9.541,3.123,8.357.958,8.357.958s-.594.659-.244,2.436a1.487,1.487,0,0,1-.057.816L7.1,6.162a10.6,10.6,0,0,0-.12-2.951C6.686,2.228,6.09.292,6.09.292s-.836.191-.6,1.936A16,16,0,0,1,5.476,5.9,18.189,18.189,0,0,0,4.031,2.164L3.179.7s-1.069.18.333,3.544a3.042,3.042,0,0,1,.249,2.3S2.837,4.894,2.3,3.973,1.267,2,1.267,2s-1.3,1.24.162,3.08c0,0,.7,1.79.521,2.234A8.754,8.754,0,0,1,.42,8.892Z"
              transform="translate(-0.027 -0.002)"
              fill="#fff"
              stroke="#000"
              stroke-width="0.5"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default PendingPayment;
