import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { fontConfig } from '../fonts/fonts';

interface StatusTagProps {
  mainText: string;
  background: string;
  color: string;
}

export const StatusTag: React.FC<StatusTagProps> = ({
  mainText,
  background,
  color,
}) => {
  return (
    <View
      style={[
        {
          backgroundColor: background,
          borderColor: 'transparent',
          borderRadius: 5,
        },
        styles.tagContainer,
      ]}
    >
      <Text style={[styles.tagText, { color: color }]}>{mainText}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  tagContainer: {
    borderRadius: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 17,
  },
  tagText: {
    fontSize: 12,
    lineHeight: 25,
    ...fontConfig.default.regular,
  },
});
