import { zonoApi } from '../api';
import {
  PriceListDetailsRequest,
  PriceListDetailsResponse,
} from './price-list-model';

export const PriceListApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    priceListDetails: builder.query<
      PriceListDetailsResponse,
      PriceListDetailsRequest
    >({
      query: (args) => ({
        url: `hub/commerce-v2/pricing/customer/details/${args.workspaceId}`,
        method: 'GET',
        params: args.params,
      }),
    }),
  }),
});

export const { useLazyPriceListDetailsQuery } = PriceListApi;
