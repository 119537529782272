import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function OrderIcon({ color = '#a1a3b4', width = 21.243, height = 22.979 }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.891"
      height="24.891"
      viewBox="0 0 24.891 24.891"
    >
      <Path
        id="Path_812"
        data-name="Path 812"
        d="M466.051,1332.314a1.132,1.132,0,0,1,1.131-1.131h4.526a1.131,1.131,0,1,1,0,2.263h-4.526A1.132,1.132,0,0,1,466.051,1332.314Zm13.577-3.394H459.263V1342.5a1.132,1.132,0,0,0,1.131,1.131h18.1a1.132,1.132,0,0,0,1.131-1.131Zm-15.884-5.657a1.132,1.132,0,0,0-.884.424l-2.375,2.97H478.4l-2.375-2.97a1.132,1.132,0,0,0-.884-.424Zm-2.65-.989a3.391,3.391,0,0,1,2.65-1.274h11.4a3.391,3.391,0,0,1,2.65,1.274l3.351,4.188a3.4,3.4,0,0,1,.743,2.12V1342.5a3.394,3.394,0,0,1-3.394,3.394h-18.1A3.394,3.394,0,0,1,457,1342.5v-13.914a3.4,3.4,0,0,1,.743-2.12Z"
        transform="translate(-457 -1321)"
        fill="#9295a5"
        fill-rule="evenodd"
      />
    </Svg>
  );
}

export default OrderIcon;
