import React, { useContext, useEffect, useState } from 'react';
import {
  StyleProp,
  TextStyle,
  View,
  StyleSheet,
  Text,
  ViewStyle,
} from 'react-native';
import { TextInput } from 'react-native-paper';
import { TextInputLabelProp } from 'react-native-paper/lib/typescript/components/TextInput/types';
import { InputKeyboardType } from '../types/enum';
import { fontConfig } from '../fonts/fonts';
import { TextInputMaskOptionProp } from 'react-native-masked-text';
import _ from 'lodash';
import { colors } from '../colors/colors';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { ThemeContext } from '../contexts/ThemeContext';
import { isNative, ValidationRule, useInput } from '@zonofi/common';

/* eslint-disable-next-line */
export interface FloatingLabelInputProps {
  mode?: string;
  label: TextInputLabelProp;
  placeholder?: string;
  prefixText?: string;
  prefixTextStyle?: StyleProp<TextStyle>;
  initialValue: string;
  outlineColor?: string;
  inputStyle?: StyleProp<TextStyle>;
  validationRules: ValidationRule[];
  onValidate?: (isValid: boolean, text: string) => void;
  mask?: TextInputMaskOptionProp;
  maskType?: string;
  keyboardType?: InputKeyboardType;
  onUnmaskedText?: (rawText: string) => void;
  onChangeText?: (text: string) => void;
  errorStrings?: string[];
  maxLength?: number;
  selectionColor?: string;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  autoCapitalize?: boolean;
  pasteDisabled?: boolean;
  height?: number;
  multiline?: boolean;
  backgroundColor?: string;
  cursorHidden?: boolean;
  cursorColor?: string;
  prefixIcon?: string | undefined;
  prefixIconColor?: string;
  prefixIconStyle?: StyleProp<ViewStyle>;
}

export const FloatingLabelInput: React.FC<FloatingLabelInputProps> = ({
  labelStyle,
  mode = 'flat',
  label,
  placeholder,
  prefixText,
  initialValue,
  prefixTextStyle,
  outlineColor,
  inputStyle,
  validationRules,
  onValidate,
  mask,
  maskType,
  keyboardType,
  onUnmaskedText,
  onChangeText,
  errorStrings,
  maxLength,
  selectionColor,
  style,
  disabled,
  autoCapitalize = false,
  pasteDisabled = false,
  height,
  multiline = false,
  cursorHidden = true,
  cursorColor = colors.zonoWhite.color,
  backgroundColor = 'transparent',
  prefixIcon = undefined,
  prefixIconStyle,
  prefixIconColor,
}) => {
  const [value, setValue] = useState('');
  const [errors, setErrors] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [focus, setFocus] = useState(false);
  // const [isDisabled,setIsDisabled]= useState(false);
  const inputColor = focus
    ? colors.linkColor.color
    : colors.containerColor.color;
  const { checkValue } = useInput();
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const validate = (isValid: boolean, text: string) => {
    if (!_.isUndefined(onValidate)) {
      onValidate(isValid, text);
    }
    setIsValid(isValid);
  };
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <View style={{ marginVertical: hp(0.5) }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 6,
          width: '100%',
          borderRadius: 10,
          borderStyle: 'solid',
          borderColor: errors.length > 0 ? colors.zonoRed.color1 : inputColor,
          borderWidth: 1,
          alignSelf: 'center',
          backgroundColor: backgroundColor,
          height: height,
        }}
      >
        <View
          style={{
            width: '90%',
            flexDirection: 'row',
            borderRadius: 10,
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            onFocus={() => setFocus(true)}
            label={
              <Text
                style={[
                  styles.labelStyle,
                  {
                    fontFamily: theme.fontConfig.regular.fontFamily,
                    color: theme.text.primary,
                  },
                  labelStyle,
                ]}
              >
                {label}
              </Text>
            }
            multiline={multiline}
            selectionColor={cursorColor}
            underlineColor="transparent"
            theme={{
              colors: {
                primary: 'transparent',
                text: theme.text.primary,
                accent: 'transparent',
                background: 'transparent',
                placeholder: 'rgba(255, 255, 255, 0.5)',
              },
              fonts: {
                regular: styles.inputFontStyle,
              },
            }}
            caretHidden={cursorHidden}
            onKeyPress={(event) => {
              if (pasteDisabled) {
                if (
                  //@ts-ignore
                  event.key === 'Control' ||
                  //@ts-ignore
                  event.key === 'V' ||
                  //@ts-ignore
                  event.key === 'v' ||
                  //@ts-ignore
                  event.key === 'Meta'
                ) {
                  setValue('');
                }
              }
            }}
            contextMenuHidden={true}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            keyboardType={keyboardType}
            style={
              isNative()
                ? [styles.textFontStyle, { height: height }]
                : { width: '100%' }
            }
            onChangeText={(text) => {
              if (!_.isUndefined(onChangeText)) {
                onChangeText(text);
              }
              checkValue(
                !_.isUndefined(autoCapitalize) && autoCapitalize
                  ? text.toLocaleUpperCase()
                  : text,
                setValue,
                validationRules,
                setErrors,
                validate
              );
            }}
            autoCapitalize="none"
            disabled={!_.isUndefined(disabled) ? disabled : false}
            left={
              !_.isUndefined(prefixIcon)
                ? prefixIcon.length > 0 &&
                  focus && (
                    <TextInput.Icon
                      name={prefixIcon}
                      animated
                      color={prefixIconColor}
                      size={22}
                      style={prefixIconStyle}
                    />
                  )
                : !_.isUndefined(prefixText) &&
                  prefixText?.length > 0 && (
                    <TextInput.Affix
                      text={prefixText}
                      textStyle={[
                        {
                          fontFamily:
                            fontConfig.default.circularStdMedium.fontFamily,
                          fontSize: 16,
                          lineHeight: 22,
                          textAlign: 'left',
                          color: disabled
                            ? colors.disabledGrey.color
                            : colors.zonoWhite.color,
                          paddingRight: wp(1),
                        },
                      ]}
                    />
                  )
            }
          />
        </View>
      </View>
      {errors.length > 0 && (
        // <View>
        <Text
          style={[
            styles.textFontStyle,
            { paddingVertical: hp('2%'), fontSize: 12 },
            { color: colors.zono50OpacityWhite.color },
          ]}
        >
          {errors}
        </Text>
        // </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  floatingLabelView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 6,
    width: '100%',
    borderRadius: 10,
    borderStyle: 'solid',
    borderColor: colors.containerColor.color,
    borderWidth: 1,
    marginTop: 3,
    alignSelf: 'center',
  },
  labelPrefixStyle: {
    alignSelf: 'center',
    marginRight: 1,
    marginBottom: 2,
    alignItems: 'center',
  },
  inputFontStyle: {
    fontFamily: fontConfig.default.circularStdMedium.fontFamily,
    fontSize: 16,
    lineHeight: 22,
    textAlign: 'left',
    color: colors.zonoWhite.color,
  },
  textFontStyle: {
    fontFamily: fontConfig.default.circularStdMedium.fontFamily,
    fontSize: 16,
    lineHeight: 18,
    textAlign: 'left',
    width: wp('80%'),
    color: colors.zonoWhite.color,
  },
  boxStyle: {
    borderColor: colors.containerColor.color,
  },
  labelStyle: {
    // color: colors.labelColor.grey,
    fontSize: 14,
  },
});
