import { CreateBankAccountRequest, CreateBankAccountResponse } from '.';
import { zonoApi } from '../api';
import {
  CreateUpiAccountRequest,
  GetBankAccountResponse,
} from './bank-account-model';

export const bankAccountApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    createBankAccount: builder.mutation<
      CreateBankAccountResponse,
      CreateBankAccountRequest
    >({
      query: (bankAccountRequest) => {
        return {
          url: 'invoiceservice/bankaccount',
          body: bankAccountRequest,
          method: 'POST',
        };
      },
      invalidatesTags: ['BankAccount'],
    }),
    getBankAccounts: builder.query<
      GetBankAccountResponse,
      { id: string; isUsedForCashBack: boolean }
    >({
      query: (arg) => ({
        url: `invoiceservice/bankaccount/${arg.id}?isUsedForCashBack=${arg.isUsedForCashBack}`,
        method: 'GET',
      }),
      providesTags: ['BankAccount'],
    }),
    //UPI API's
    createUPIAccount: builder.mutation<
      CreateBankAccountResponse,
      CreateUpiAccountRequest
    >({
      query: (UPIAccount) => {
        return {
          url: 'invoiceservice/upi',
          body: UPIAccount,
          method: 'POST',
        };
      },
      invalidatesTags: ['UPIAccount'],
    }),
    getUPIAccounts: builder.query<GetBankAccountResponse, { id: string }>({
      query: (arg) => ({
        url: `invoiceservice/upi/${arg.id}`,
        method: 'GET',
      }),
      providesTags: ['UPIAccount'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useCreateBankAccountMutation,
  useLazyGetBankAccountsQuery,
  useCreateUPIAccountMutation,
  useLazyGetUPIAccountsQuery,
} = bankAccountApi;
