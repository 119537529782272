import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { colors } from '../colors/colors';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

/* eslint-disable-next-line */
export interface BackButtonProps {
  navigation?: string;
  marginRight?: number;
}

export const BackButton: React.FC<BackButtonProps> = ({ navigation }) => {
  const navigate = useNavigate();

  const navigateToRoute = () => {
    if (navigation) {
      navigate(navigation);
    } else {
      navigate(-1);
    }
  };

  return (
    <TouchableOpacity style={styles.buttonStyle} onPress={navigateToRoute}>
      <Icon name="chevron-left" color={colors.zonoWhite.color} size={30} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: colors.zonoBlack.color,
    borderColor: colors.backButtonBorder.color,
    height: 30,
    width: 30,
    borderRadius: 20,
    // marginRight: wp('10%'),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
