import {
  addQRImage,
  ErrorVariants,
  getSelectedCustomerInfo,
  getTeamMember,
  selectUnpaidInvoiceItems,
  showToast,
  store,
  UnpaidInvoiceItem,
  updateAmountPaid,
  updateCheckOutType,
  updatePaymentRequestId,
  useCreatePaymentWithOutTokenMutation,
} from '@zonofi/zono-money-store';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { areNil } from '../assits/functions';

import { CheckOutType, PaymentProvider } from '../types/enum';

export const useCreatePayment = (navigate: (param: string) => void) => {
  const teamMember = getTeamMember(store.getState());
  const customerInfo = getSelectedCustomerInfo(store.getState());
  const [createPaymentWithoutToken, createPaymentWithoutTokenResponse] =
    useCreatePaymentWithOutTokenMutation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const dispatch = useDispatch();

  const onCollectPayment = (
    amount: number,
    invoices: UnpaidInvoiceItem[],
    generateQr: boolean
  ) => {
    if (
      !areNil([
        teamMember,
        teamMember?.senderWorkspace.id,
        teamMember?.teamInviteId,
      ])
    ) {
      createPaymentWithoutToken({
        CreatePaymentRequest: {
          sellerWorkspaceId: teamMember?.senderWorkspace?.id,
          inviteId: customerInfo?.inviteId,
          amountPaid: amount,
          currencyCode: 'INR',
          generateQr: generateQr,
          paymentProvider: PaymentProvider.CFAUTOCOLLECT,
          docNumberToClear: invoices.map((val) => val.docNumber),
          teamMemberId: teamMember?.teamInviteId,
        },
      });
    }
  };

  React.useEffect(() => {
    setIsLoading(createPaymentWithoutTokenResponse.isLoading);
    if (
      createPaymentWithoutTokenResponse.isSuccess &&
      !areNil([
        createPaymentWithoutTokenResponse.data,
        createPaymentWithoutTokenResponse.data?.qrImage,
        createPaymentWithoutTokenResponse.data.paymentRequestId,
      ])
    ) {
      if (createPaymentWithoutTokenResponse.data.qrImage) {
        dispatch(addQRImage(createPaymentWithoutTokenResponse.data.qrImage));
        dispatch(
          updatePaymentRequestId(
            createPaymentWithoutTokenResponse.data.paymentRequestId
          )
        );
        dispatch(updateCheckOutType(CheckOutType.ONLINE));
        navigate(createPaymentWithoutTokenResponse.data.paymentRequestId);
      }
    } else if (createPaymentWithoutTokenResponse.isError) {
      setIsError(true);
      dispatch(
        showToast({
          canDisplay: true,
          message: 'Something went wrong, please try again...',
          responseStatus: 'ERROR',
          type: ErrorVariants.DANGER,
        })
      );
      dispatch(selectUnpaidInvoiceItems([]));
    }
  }, [createPaymentWithoutTokenResponse]);

  return {
    onCollectPayment,
    isLoading,
    isError,
  };
};
