import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function RefreshIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={20}
      height={20}
      viewBox="0 0 24 24"
      {...props}
    >
      <Path
        fill="#64e6ba"
        d="M11.995 4a8 8 0 107.735 10h-2.081a6 6 0 11-5.654-8 5.92 5.92 0 014.223 1.78L13 11h7V4l-2.351 2.35A7.965 7.965 0 0011.995 4z"
      />
    </Svg>
  );
}

export default RefreshIcon;
