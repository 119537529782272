import React, { useContext } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { colors as designColors } from '../colors/colors';
import _ from 'lodash';
import { ThemeContext } from '../contexts/ThemeContext';

/* eslint-disable-next-line */
export interface LinearGradientCardProps {
  colors?: (string | number)[];
  start?:
    | {
        x: number;
        y: number;
      }
    | undefined;
  end?:
    | {
        x: number;
        y: number;
      }
    | undefined;
  children: React.ReactNode;
  cardStyle?: StyleProp<ViewStyle>;
  maxHeight?: any;
}

export const LinearGradientCard: React.FC<LinearGradientCardProps> = ({
  colors,
  children,
  cardStyle,
  start,
  end,
  maxHeight,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  return (
    <LinearGradient
      colors={colors ?? Object.values(theme.table.linearGradient)}
      start={!_.isUndefined(start) ? start : { x: 0.25, y: 0 }}
      end={!_.isUndefined(end) ? end : { x: 1, y: 1 }}
      style={[cardStyle, { ...(maxHeight ? { maxHeight } : {}) }]}
    >
      {children}
    </LinearGradient>
  );
};
