import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export enum KeyboardAction {
  Enter = 'enter',
  Delete = 'delete',
  null = 0,
}

export interface KeyboardItemMetaData {
  keyboardAction: KeyboardAction;
  keyboardCallback: (keyCombination: string, itemIndex: number) => void;
  keyboardActiveIndex: number;
  itemIndex: number;
}

export const scrollToItem = (
  containerRef: MutableRefObject<any>,
  listItemRefs: MutableRefObject<any[]>,
  index: number
) => {
  const listItemRef = listItemRefs.current[index];
  if (listItemRef) {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const itemRect = listItemRef.getBoundingClientRect();

      const itemTop = itemRect.top - containerRect.top - 100;
      const itemBottom = itemRect.bottom - containerRect.top + 50;
      const containerHeight = containerRef.current.clientHeight;

      if (itemTop < 0) {
        containerRef.current.scrollTop += itemTop;
      } else if (itemBottom > containerHeight) {
        containerRef.current.scrollTop += itemBottom - containerHeight;
      }
    }
  }
};

// type KeyType = 'ArrowUp'| 'ArrowDown'| 'Enter'| 'Backspace' | 'Tab' | 'Escape' | 'Shift' | 's'

// const handlersRegistry = new Map();

// const activeKeyboardEventListener = useAppSelector(getActiveKeyboardEventListener);

// export const useKeyPress = (
//   componentId: string,
//   callback: (event: KeyboardEvent) => void
// ) => {
//   const callbackRef = useRef(callback);

//   useEffect(() => {
//     callbackRef.current = callback;
//   }, [callback]);

//   const handleKeyDown = useCallback((event) => {
//     const componentHandlers = Array.from(handlersRegistry.values());
//     const latestHandler = componentHandlers[componentHandlers.length - 1];
//     if (latestHandler && latestHandler.keyDown) {
//       latestHandler.keyDown.current(event);
//     }
//   }, []);

//   useEffect(() => {
//     handlersRegistry.set(componentId, {
//       componentId: componentId,
//       keyDown: callbackRef,
//     });
//     document.removeEventListener('keydown', handleKeyDown);

//     document.addEventListener('keydown', handleKeyDown);
//     console.log('handlersRegistry: ', handlersRegistry);
//     return () => {
//       document.removeEventListener('keydown', handleKeyDown);
//       handlersRegistry.delete(componentId);
//     };
//   }, [componentId, handleKeyDown]);
// };
