// Generated by ts-to-zod
import { z } from 'zod';

const withInvoiceSchema = z.object({
  docNumber: z.string(),
  amount: z.number(),
  invoiceId: z.string(),
  inviteId: z.string(),
  invoiceDate: z.string(),
  isReminder: z.boolean().optional(),
});

const withoutInvoiceSchema = z.object({
  amount: z.number(),
  inviteId: z.string(),
});

export const invoiceArraySchema = z.array(withInvoiceSchema);

const paymentInvoiceSchema = z.union([
  invoiceArraySchema,
  withoutInvoiceSchema,
]);

export const paymentSchema = z.object({
  status: z.string(),
  amount: z.number().optional(),
  invoice: paymentInvoiceSchema.optional(),
  invoiceUrl: z.string().optional(),
  bankTransactionId: z.string().optional(),
  amountReceived: z.number().optional(),
  paymentMode: z.string().optional(),
  upiId: z.null().optional(),
  whatsappPhoneNumberId: z.string().optional(),
  upiTransactionId: z.null().optional(),
  paymentRefCode: z.string().optional(),
  paymentDate: z.string().optional(),
  appId: z.string().optional(),
  paymentTransactionId: z.string().optional(),
  whatsappToken: z.string().optional(),
  fileUrl: z.string().optional(),
  userType: z.string().optional(),
});
