import { Button, MenuItem, Popover, Select } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';

import { PopupActions } from 'reactjs-popup/dist/types';
import { ThemeContext } from '../contexts/ThemeContext';

type DiscountType = 'percent' | 'direct';

interface HandleDiscountPayload {
  discount: number;
  discountType: DiscountType;
}
export interface IOrderLevelDiscountProps {
  ref?: React.Ref<PopupActions>;
  children?: JSX.Element;
  handleApplyDiscount: (data: HandleDiscountPayload) => void;
  openDiscountPopup: boolean;
  handleClose: () => void;
  anchorEl: HTMLButtonElement | null;
  id?: string;
  orderValue: number;
}

export const OrderLevelDiscount2 = (props: IOrderLevelDiscountProps) => {
  const { theme } = React.useContext(ThemeContext);
  const [discountInputValue, setDiscountInputVaue] = React.useState<number>();
  const [discountType, setDiscountType] = React.useState<'percent' | 'direct'>(
    'percent'
  );

  const header = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 18,
      }}
    >
      <div style={{ fontSize: 16, color: 'white' }}>
        <span style={{ ...theme.fontConfig.semiBold }}>
          Order Level Discount:
        </span>{' '}
        <span style={{ ...theme.fontConfig.regular }}>
          Please enter and apply the discount percentage below
        </span>
      </div>
    </div>
  );
  const discountInput = () => (
    <div
      style={{
        display: 'flex',
        color: '#F7F8F9',
        flexDirection: 'column',
        marginBottom: 15,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {/* <span
          style={{
            height: 28,
            width: 36,
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            color: '#091E42',
            justifyContent: 'center',
            ...theme.fontConfig.bold,
            fontSize: 14,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (discountType === 'percent') {
              setDiscountType('direct');
            } else {
              setDiscountType('percent');
            }
          }}
        >
          {discountType === 'percent' ? '%' : '₹'}
        </span> */}
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={discountType}
          label="Discount Type"
          //@ts-ignore
          onChange={(e) => setDiscountType(e.target.value)}
          sx={{ backgroundColor: 'white', height: 28 }}
        >
          <MenuItem value="percent">
            <em>%</em>
          </MenuItem>
          <MenuItem value="direct">
            <em>₹</em>
          </MenuItem>
        </Select>
        <input
          style={{
            height: 20,
            borderLeft: '1px solid #DCDFE4',
            borderRight: 0,
            borderTop: 0,
            borderBottom: 0,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            color: '#091E42',
            paddingTop: 4,
            paddingBottom: 4,
            ...theme.fontConfig.bold,
            fontSize: 14,
            width: 250,
          }}
          type="number"
          value={discountInputValue}
          onChange={(e) => {
            if (
              (Number(e.target.value) > -1 && Number(e.target.value) < 101) ||
              discountType === 'direct'
            ) {
              setDiscountInputVaue(Number(e.target.value));
            }
          }}
        />
        {applyBtn()}
        {cancelBtn()}
      </div>
    </div>
  );

  const applyBtn = () => (
    <div
      style={{
        display: 'flex',
        // justifyContent: 'flex-end',
      }}
    >
      <Button
        style={{
          background:
            discountType === 'direct' &&
            !_.isUndefined(discountInputValue) &&
            discountInputValue > props?.orderValue
              ? 'grey'
              : '#0067FF',
          ...theme.fontConfig.semiBold,
          ...theme.fontConfig.semiBold,
          fontSize: 14,
          height: 28,
          color: 'white',
          cursor: 'pointer',
          textTransform: 'none',
          marginLeft: 6,
        }}
        disabled={
          discountType === 'direct' &&
          !_.isUndefined(discountInputValue) &&
          discountInputValue > props?.orderValue
            ? true
            : false
        }
        onClick={() => {
          if (!_.isNaN(discountInputValue)) {
            //  const updatedLine = data?.find((line, i) => {
            //    return i === index;
            //  });
            props.handleApplyDiscount({
              discountType: discountType,
              discount: discountInputValue || 0,
            });
            props.handleClose();
          }
        }}
      >
        Apply
      </Button>
    </div>
  );
  const cancelBtn = () => (
    <div
      style={{
        display: 'flex',
        // justifyContent: 'flex-end',
      }}
    >
      <Button
        style={{
          background: 'white',
          ...theme.fontConfig.semiBold,
          fontSize: 14,
          height: 28,
          color: theme.text.primary,
          cursor: 'pointer',
          textTransform: 'none',
          marginLeft: 6,
          border: '1px solid #0067FF',
        }}
        onClick={() => {
          props.handleClose();
        }}
      >
        Cancel
      </Button>
    </div>
  );
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorEl={props.anchorEl}
      open={props.openDiscountPopup}
      onClose={() => {
        props.handleClose();
      }}
      // trigger={props.children}
    >
      <div
        style={{
          background: '#091E42',
          padding: 16,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
      >
        {header()}
        {discountInput()}
      </div>
    </Popover>
  );
};
