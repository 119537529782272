import { ThemeContext } from '@zonofi/shared';
import { SVGS } from '@zonofi/zono-money-design-system';
import { WEBROUTES } from '@zonofi/zono-money-helpers';
import React, { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const OrderDetailsEmptyCart: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const fromScreen = params?.get('from');

  const onClick = () => {
    if (fromScreen === 'campaign' || fromScreen === 'price-list') {
      navigate(-1);
    } else {
      navigate(WEBROUTES.CATALOGUE);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          padding: '15px 10px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <img src={SVGS.ZotokLogoGreen} />
      </div>
      <div
        style={{
          backgroundColor: '#F1F2F4',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            textAlign: 'center',
          }}
        >
          <span style={{ fontSize: 20, ...theme.fontConfig.semiBold }}>
            Hey, it feels so light!
          </span>
          <span
            style={{
              fontSize: 12,
              color: '#7e818c',
              ...theme.fontConfig.regular,
            }}
          >
            Let's add some items.
          </span>
        </div>
        <span
          style={{
            fontSize: 14,
            color: '#32A7E8',
            ...theme.fontConfig.semiBold,
          }}
          onClick={onClick}
        >
          + ADD ITEMS
        </span>
      </div>
    </div>
  );
};
