import { globalStyles } from '../styles/global-style';
import { ThemeContext } from '../contexts/ThemeContext';
import React, { useContext } from 'react';
import './styles.scss';
import { Theme } from '../types';

interface CustomScrollFooterListItem {
  title: string;
  value: any;
  color?: string;
}

export interface CustomScrollFooterProps {
  content: CustomScrollFooterListItem[];
  top?: number;
  width?: string;
  customStyles?: React.CSSProperties;
}

export const renderFooterContentItem = (
  item: CustomScrollFooterListItem,
  theme: Theme,
  vrLineShow: boolean,
  marginRight: number,
) => {
  return (
    <div style={{ ...globalStyles.displayFlex, marginRight }} >
      <div className="scroll-footer-item-vr-line" />
      <div className="scroll-footer-item-container">
        <div
          style={{
            fontFamily: theme.fontConfig.regular.fontFamily,
            color: item?.color,
          }}
        >
          {item?.value}
        </div>
        <div
          className="scroll-footer-item-heading"
          style={theme.fontConfig.semiBold}
        >
          {item?.title}
        </div>
      </div>
      {vrLineShow && <div className="scroll-footer-item-vr-line" />}
    </div>
  );
};

export const CustomScrollFooter: React.FC<CustomScrollFooterProps> = ({
  content,
  top,
  width,
  customStyles,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;

  const getFooterContent = () => (
    <div className="scroll-content-footer">
      {content?.map((item, index) => {
        return renderFooterContentItem(
          item,
          theme,
          content?.length !== index + 1,
          0
        );
      })}
    </div>
  );

  return (
    <div
      className="custom-scroll-footer"
      style={{
        top,
        width: width ? width : '100%',
        ...customStyles,
      }}
    >
      <div className="custom-scroll-content-footer">{getFooterContent()}</div>
    </div>
  );
};

export default CustomScrollFooter;
