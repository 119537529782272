import React from 'react';

import { View, Text, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { colors } from '../colors/colors';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

/* eslint-disable-next-line */
export interface GradientViewProps {
  children: React.ReactNode;
  gradientColors?: string[];
  style?: StyleProp<ViewStyle>;
}

export const GradientView: React.FC<GradientViewProps> = ({
  children,
  gradientColors = Object.values(colors.backgroundLinearGradient),
  style,
}) => {
  return (
    <LinearGradient colors={gradientColors} style={[style, styles.container]}>
      {children}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'column', padding: wp(3) },
});
