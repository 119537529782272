//@ts-nocheck
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  FlatList,
  Modal,
  TextInput,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';
import Icon from 'react-native-easy-icon';
import { Divider, TextInput as RNPTextInput } from 'react-native-paper';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { CheckListItem } from '../check-list-item/check-list-item';
import { colors } from '../colors/colors';
import { DropDownOptions } from '../types';
import { ThemeContext } from '../contexts/ThemeContext';
import Tag from '../tag/tag';
import { TextField } from '@mui/material';
import { hash } from '@zonofi/common';

/* eslint-disable-next-line */
export interface SelectDropDownProps {
  title: string | JSX.Element;
  displayTitle?: boolean;
  onSelect?: (value: string) => void;
  onSearch?: (key: string) => DropDownOptions[];
  optionList: DropDownOptions[];
  multiSelect?: boolean;
  disabled?: boolean;
  initialValue?: DropDownOptions[] | null;
  color?: string;
  enableSearch?: boolean;
  maxLimit?: number;
  variant?: 'table';
  inlineView?: boolean;
  backgroundColor?: string;
  inputHeight?: number | string;
  showCheckIcon?: boolean;
  arrowSize?: number;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  width?: string | number;
  crossColor?: string;
  styledArrow?: boolean;
  populateSelected?: any[];
}

export const SelectDropDown: React.FC<SelectDropDownProps> = ({
  title,
  displayTitle = true,
  onSelect,
  onSearch,
  optionList,
  multiSelect = false,
  disabled = false,
  initialValue,
  color,
  enableSearch = false,
  maxLimit = 2,
  variant,
  inlineView = false,
  backgroundColor,
  inputHeight,
  showCheckIcon,
  arrowSize,
  containerStyle,
  textStyle,
  crossColor,
  width = '100%',
  styledArrow = false,
  populateSelected = [],
}) => {
  const DropdownButton = useRef(undefined) as any;
  const [focus, setFocus] = useState(false);
  const [selectedItems, setSelectedItems] = useState<DropDownOptions[]>(
    populateSelected ? populateSelected : []
  );
  const [options, setOptions] = useState<DropDownOptions[]>(optionList);
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const [searchKey, setSearchKey] = useState('');

  const dropdownStyles = useMemo(() => {
    switch (variant) {
      case 'table':
        return {
          borderRadius: 4,
          borderColor: theme.dropDown.borderColor,
          backgroundColor: theme.dropDown.background,
          listBackgroundColor: theme.linearGradient.primary,
          titleStyle: {},
          selectedOption: {
            color: theme.text.primary,
            fontSize: 14,
            ...theme.fontConfig.regular,
          },
          searchText: {
            color: theme.text.primary,
            fontSize: 14,
            ...theme.fontConfig.regular,
          },
        };
      default:
        return {
          borderRadius: 10,
          borderColor: theme.input.border,
          backgroundColor: colors.transparent.color,
          listBackgroundColor: backgroundColor ?? theme.linearGradient.primary,
          titleStyle: {
            color: theme.input.label,
            fontSize: 14,
            ...(inlineView ? theme.fontConfig.bold : theme.fontConfig.regular),
            backgroundColor: inlineView
              ? backgroundColor ?? theme.primary
              : 'transparent',
          },
          selectedOption: {
            color: theme.text.primary,
            fontSize: 16,
            ...(!displayTitle || _.isEmpty(selectedItems)
              ? theme.fontConfig.regular
              : theme.fontConfig.bold),
          },
          searchText: {
            color: theme.text.primary,
            fontSize: 16,
            ...theme.fontConfig.regular,
          },
        };
    }
  }, [variant, theme, displayTitle, selectedItems]);

  useEffect(() => {
    if (initialValue) {
      setSelectedItems(initialValue);
      setSearchKey('');
      setOptions(optionList);
    }
  }, [initialValue, optionList]);

  const [dropdown, setDropdown] = useState({ top: 0, left: 0, width: 0 });

  const toggleDropdown = (): void => {
    focus ? setFocus(false) : openDropdown();
  };

  const openDropdown = (): void => {
    if (DropdownButton && DropdownButton.current) {
      DropdownButton?.current?.measure(
        (
          _fx: number,
          _fy: number,
          w: number,
          h: number,
          px: number,
          py: number
        ) => {
          setDropdown({ top: py + h, left: px, width: w });
        }
      );
    }
    setFocus(true);
  };

  const onItemPress = (id: string) => {
    const item = optionList.find((x) => x.id === id);
    if (item) {
      if (multiSelect) {
        if (selectedItems.includes(item)) {
          setSelectedItems(selectedItems.filter((x) => x.id !== item.id));
        } else {
          setSelectedItems([...selectedItems, item]);
        }
      } else {
        if (selectedItems?.[0]?.id === item.id) {
          setSelectedItems([]);
        } else {
          setSelectedItems([item]);
        }
      }
    }
    setSearchKey('');
    if (onSelect) {
      if (!multiSelect && selectedItems?.[0]?.id === item?.id) {
        onSelect('');
      } else {
        onSelect(id);
      }
    }
    toggleDropdown();
  };

  useEffect(() => {
    let searchOptionList: DropDownOptions[] = optionList;
    if (searchKey.length > 2) {
      if (onSearch) {
        searchOptionList = onSearch(searchKey);
      } else {
        searchOptionList = optionList.filter((x) =>
          x.mainText.toLowerCase().includes(searchKey.toLowerCase())
        );
      }
    }
    setOptions(searchOptionList);
  }, [onSearch, optionList, searchKey]);

  const backGrounds = Object.values(colors.colorVarient);

  const getTag = (item: DropDownOptions) => {
    const bgColor =
      color ?? backGrounds[hash(item.mainText, backGrounds.length)];
    return (
      <Tag
        style={{
          backgroundColor: bgColor,
          borderColor: bgColor,
          borderRadius: 2,
          text: colors.white.color,
        }}
        label={item.mainText}
        truncate={12}
        iconRight={
          !disabled ? (
            <>
              <Divider
                style={{
                  width: 1,
                  height: 20,
                  marginLeft: 5,
                  backgroundColor: colors.white.color,
                }}
              />
              <Icon
                type="entypo"
                name="cross"
                size={15}
                color={colors.white.color}
                onPress={() => onItemPress(item.id)}
              />
            </>
          ) : undefined
        }
        variant="small"
        fontSize={14}
      />
    );
  };

  const dropdownArrow = () =>
    focus ? (
      <RNPTextInput.Icon
        name={() => (
          <Icon
            type={'antdesign'}
            name={'caretup'}
            size={arrowSize ? arrowSize : 15}
            color={theme.dropDown.arrow}
            onPress={toggleDropdown}
            style={{ marginRight: 3 }}
          />
        )}
      />
    ) : (
      <RNPTextInput.Icon
        name={() => (
          <Icon
            type={'antdesign'}
            name={'caretdown'}
            size={arrowSize ? arrowSize : 15}
            color={theme.dropDown.arrow}
            onPress={toggleDropdown}
            style={{ marginRight: 3 }}
          />
        )}
      />
    );

  return (
    <>
      <TouchableOpacity
        ref={DropdownButton}
        onPress={toggleDropdown}
        disabled={disabled}
        style={[containerStyle]}
      >
        {multiSelect && !_.isEmpty(selectedItems) ? (
          <View
            style={[
              styles.container,
              {
                paddingVertical: 10,
                borderColor: dropdownStyles.borderColor,
                borderRadius: dropdownStyles.borderRadius,
              },
            ]}
          >
            <View style={{ width: '95%' }}>
              {displayTitle && title ? (
                <Text style={dropdownStyles.titleStyle}>{title}</Text>
              ) : null}
              <View style={styles.row}>
                <View style={{ flexDirection: 'row' }}>
                  {selectedItems.slice(0, maxLimit).map((item) => getTag(item))}
                  {selectedItems.length > maxLimit && (
                    <Tag
                      style={{
                        backgroundColor: color ?? colors.transparent.color,
                        borderColor: color ?? colors.transparent.color,
                        borderRadius: 2,
                        text: color ? colors.white.color : theme.text.primary,
                      }}
                      label={`+ ${selectedItems.length - maxLimit}`}
                      variant="small"
                      fontSize={14}
                    />
                  )}
                </View>
                {!disabled && styledArrow ? (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View>
                      <Icon
                        type="entypo"
                        name="cross"
                        size={15}
                        color={crossColor ? crossColor : colors.white.color}
                        onPress={() => setSelectedItems([])}
                      />
                    </View>
                    <View
                      style={{
                        position: 'relative',
                        bottom: 3,
                        paddingRight: 10,
                      }}
                    >
                      {dropdownArrow()}
                    </View>
                  </View>
                ) : !disabled ? (
                  <Icon
                    type="entypo"
                    name="cross"
                    size={15}
                    color={crossColor ? crossColor : colors.white.color}
                    onPress={() => setSelectedItems([])}
                  />
                ) : null}
              </View>
            </View>
            {!styledArrow ? dropdownArrow() : null}
          </View>
        ) : !inlineView ? (
          <View
            style={[
              styles.container,
              {
                borderColor: dropdownStyles.borderColor,
                borderRadius: dropdownStyles.borderRadius,
              },
            ]}
          >
            <RNPTextInput
              disabled={disabled}
              label={<Text style={dropdownStyles.titleStyle}>{title}</Text>}
              underlineColor="transparent"
              theme={{
                colors: {
                  primary: 'transparent',
                  text: theme.text.primary,
                  accent: 'transparent',
                  background: 'transparent',
                  placeholder: 'rgba(255, 255, 255, 0.5)',
                },
                fonts: {
                  regular: dropdownStyles.selectedOption,
                },
              }}
              value={
                !_.isUndefined(selectedItems) && !_.isEmpty(selectedItems)
                  ? selectedItems[0].mainText
                  : ''
              }
              style={[textStyle, { width: width, height: inputHeight }]}
              right={!disabled ? dropdownArrow() : undefined}
              left={
                !_.isUndefined(selectedItems) &&
                !_.isEmpty(selectedItems) &&
                selectedItems[0].icon ? (
                  <RNPTextInput.Icon name={() => selectedItems[0].icon} />
                ) : undefined
              }
              editable={false}
            />
          </View>
        ) : (
          <TextField
            disabled={disabled}
            label={
              <Text
                style={[dropdownStyles.titleStyle, { marginHorizontal: 1 }]}
              >
                {title}
              </Text>
            }
            value={
              !_.isUndefined(selectedItems) && !_.isEmpty(selectedItems)
                ? selectedItems[0].mainText
                : ''
            }
            variant={'outlined'}
            style={{ ...textStyle, width: width, height: inputHeight }}
            inputProps={{
              style: {
                ...textStyle,
              },
            }}
            sx={{
              ...textStyle,
              width: width,
              height: inputHeight,
            }}
            right={!disabled ? dropdownArrow() : undefined}
            left={
              !_.isUndefined(selectedItems) &&
              !_.isEmpty(selectedItems) &&
              selectedItems[0].icon ? (
                <RNPTextInput.Icon name={() => selectedItems[0].icon} />
              ) : undefined
            }
            outlineColor={
              disabled ? colors.indigo.opacity20 : colors.indigo.opacity50
            }
          />
        )}
      </TouchableOpacity>
      <Modal visible={focus} animationType="none" transparent>
        <TouchableOpacity style={styles.overlay} onPress={toggleDropdown}>
          <TouchableOpacity
            onPress={(e) => e.stopPropagation()}
            activeOpacity={1}
            style={[
              styles.list,
              {
                backgroundColor: theme.enableEdit
                  ? theme.linearGradient.primary
                  : theme.primary,
                borderColor: theme.enableEdit
                  ? theme.input.border
                  : '#C9CCD680',
                top: dropdown.top,
                left: dropdown.left,
                width: dropdown.width,
              },
            ]}
          >
            {enableSearch ? (
              <View style={styles.searchContainer}>
                <Icon
                  type="ionicon"
                  name="search-sharp"
                  color={theme.input.placeHolder}
                  size={16}
                  style={{ marginRight: 4 }}
                />
                <TextInput
                  placeholder="Search"
                  value={searchKey}
                  onChangeText={(text) => setSearchKey(text)}
                  placeholderTextColor={theme.input.placeHolder}
                  style={[dropdownStyles.searchText, { width: '90%' }]}
                />
              </View>
            ) : null}
            <FlatList
              data={options}
              renderItem={({ item }) => (
                <CheckListItem
                  listItem={{
                    id: item.id,
                    value: item.mainText,
                    icon: item.icon,
                  }}
                  onPress={onItemPress}
                  selectedIds={selectedItems.map((x) => x.id)}
                  showCheckIcon={showCheckIcon ?? true}
                />
              )}
              scrollEnabled={true}
              nestedScrollEnabled={true}
            />
          </TouchableOpacity>
        </TouchableOpacity>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  list: {
    maxHeight: hp(30),
    position: 'absolute',
    width: '100%',
    borderRadius: 6,
    shadowColor: 'grey',
    shadowOffset: { height: 3, width: 0 },
    shadowOpacity: 0.5,
    elevation: 3, // Android elevation
    shadowRadius: 4,
    paddingHorizontal: wp(1),
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchContainer: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default SelectDropDown;
