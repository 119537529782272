import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function uploadInvoice() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/Svg"
      width="13.909"
      height="18.545"
      viewBox="0 0 13.909 18.545"
    >
      <Path
        id="Path_90058"
        data-name="Path 90058"
        d="M222.864,757.909a.773.773,0,0,1,.773-.773h4.636a.773.773,0,0,1,0,1.545h-4.636A.773.773,0,0,1,222.864,757.909Zm0-3.091a.773.773,0,0,1,.773-.773h2.318a.773.773,0,0,1,0,1.545h-2.318A.773.773,0,0,1,222.864,754.818Zm-3.864-8.5A2.318,2.318,0,0,1,221.318,744h5.409a.77.77,0,0,1,.546.226l5.409,5.409a.771.771,0,0,1,.226.546v10.045a2.318,2.318,0,0,1-2.318,2.318h-9.272A2.318,2.318,0,0,1,219,760.227Zm8.5.32,2.771,2.771h-2a.773.773,0,0,1-.773-.773Zm-6.182-1.093a.773.773,0,0,0-.773.773v13.909a.773.773,0,0,0,.773.773h9.272a.773.773,0,0,0,.773-.773v-9.272h-3.091a2.318,2.318,0,0,1-2.318-2.318v-3.091Z"
        transform="translate(-219 -744)"
        fill="#fff"
      />
    </Svg>
  );
}

export default uploadInvoice;
