import { ThemeContext } from '@zonofi/shared';
import React from 'react';
import Icon from 'react-native-easy-icon';
import { IconType } from 'react-native-easy-icon/src/Icon';
import './common.scss';

interface ActionButtonProps {
  onClick: () => void;
  icon: { name: string; type: IconType };
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  icon,
}) => {
  const { theme } = React.useContext(ThemeContext);
  const { name, type } = icon;
  return (
    <div
      className="action-button"
      style={{ backgroundColor: theme.primary }}
      onClick={onClick}
    >
      <Icon name={name} type={type} size={22} color={theme.secondary} />
    </div>
  );
};
