import Icon from 'react-native-easy-icon';
import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { List } from 'react-native-paper';
import { fontConfig } from '../fonts/fonts';
import { colors } from '../colors/colors';

interface AccordionProps {
  list: {
    title: string;
    // icon?: React.ReactNode;
    icon?: JSX.Element;
    contents: React.ReactNode;
    selectedIcon?: JSX.Element;
  }[];
}

export const Accordion: React.FC<AccordionProps> = ({ list }) => {
  const [expandedId, setExpandedId] = React.useState('');
  return (
    <List.AccordionGroup
      expandedId={expandedId}
      onAccordionPress={(id) => {
        setExpandedId(expandedId === id.toString() ? '' : id.toString());
      }}
    >
      {list.map((item, index) => {
        return (
          <List.Accordion
            id={index.toString()}
            title={item.title}
            left={({ color }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    // console.log(color, 'COLOR');
                  }}
                >
                  {expandedId === index.toString()
                    ? item.selectedIcon
                    : item.icon}
                </TouchableOpacity>
              );
            }}
            titleStyle={
              expandedId === index.toString()
                ? styles.focusedTitle
                : styles.titleStyle
            }
            style={{
              backgroundColor:
                expandedId === index.toString()
                  ? colors.gunMetal.color
                  : colors.onyx.color,
              padding: 0,
            }}
            right={() => (
              <Icon
                type="feather"
                name={
                  expandedId === index.toString()
                    ? 'chevron-up'
                    : 'chevron-down'
                }
                color={colors.white.color}
                size={30}
              />
            )}
          >
            <View
              style={[
                {
                  backgroundColor:
                    expandedId === index.toString()
                      ? colors.gunMetal.color
                      : colors.white.color,
                },
                styles.container,
              ]}
            >
              {item.contents}
            </View>
          </List.Accordion>
        );
      })}
    </List.AccordionGroup>
  );
};

const styles = StyleSheet.create({
  container: { padding: 12 },
  titleStyle: {
    fontSize: 18,
    ...fontConfig.default.regular,
    color: colors.white.color,
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  margin: { marginRight: 10 },
  focusedTitle: {
    fontSize: 18,
    ...fontConfig.default.semiBold,
    color: colors.mediumAquamarine.color,
  },
});
