import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function PaymentFailed(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="192.411"
      viewBox="0 0 160 192.411"
    >
      <G
        id="Group_115743"
        data-name="Group 115743"
        transform="translate(-5897 330.783)"
      >
        <G
          id="Group_115693"
          data-name="Group 115693"
          transform="translate(5897 -330.783)"
        >
          <Path
            id="Path_198265"
            data-name="Path 198265"
            d="M281.629,130.491H240v-19.63A18.861,18.861,0,0,1,258.861,92h22.768Z"
            transform="translate(-240 -75.253)"
            fill="#1c1c1c"
          />
          <Path
            id="Path_198266"
            data-name="Path 198266"
            d="M337.681,92s13.776.865,18.675,14.52a24.69,24.69,0,0,1,1.407,8.343V204.44s-.941,21.965,21.86,23.638h53.344V113.861a21.31,21.31,0,0,0-15.78-20.67,25.787,25.787,0,0,0-7.556-1.082Z"
            transform="translate(-319.626 -75.253)"
            fill="#fff"
          />
          <Path
            id="Path_198267"
            data-name="Path 198267"
            d="M433.163,227.125H379.527a24.926,24.926,0,0,1-13.177-4.452,20.637,20.637,0,0,1-6.532-8.153,27.592,27.592,0,0,1-2.4-11.317V113.635a24.357,24.357,0,0,0-1.391-8.251,21.154,21.154,0,0,0-11.035-12.226,22.636,22.636,0,0,0-7.4-2.114l.017-.546,71.95.108h.04a26.036,26.036,0,0,1,7.6,1.094c8.617,2.626,12.5,8.179,14.244,12.375a21.865,21.865,0,0,1,1.729,8.568Zm-53.606-.546h53.06V112.633a21.74,21.74,0,0,0-1.7-8.367c-1.7-4.08-5.487-9.481-13.89-12.041a25.488,25.488,0,0,0-7.437-1.071h-.039l-68.815-.1a24.778,24.778,0,0,1,4.482,1.615A21.69,21.69,0,0,1,356.536,105.2a24.9,24.9,0,0,1,1.423,8.435v89.578a27.013,27.013,0,0,0,2.363,11.1c3.427,7.454,9.9,11.582,19.234,12.269"
            transform="translate(-319.823 -74.025)"
          />
          <Path
            id="Path_198268"
            data-name="Path 198268"
            d="M617.362,655.675v17.4a17.746,17.746,0,0,1-3.951,11.274,13.323,13.323,0,0,1-7.589,4.8h49.267a11.045,11.045,0,0,0,1.538-.106c3.293-.463,13.892-2.86,16.7-14.688a19.824,19.824,0,0,0,.514-4.587V655.675Z"
            transform="translate(-539.229 -536.319)"
            fill="#fff"
          />
          <Path
            id="Path_198269"
            data-name="Path 198269"
            d="M643.282,688.191H591.388l2.571-.54a12.984,12.984,0,0,0,7.434-4.7,17.5,17.5,0,0,0,3.89-11.1V654.174h57.028v14.363a20.152,20.152,0,0,1-.521,4.651c-2.856,12.013-13.681,14.438-16.932,14.9a11.376,11.376,0,0,1-1.576.108m-47.352-.546h47.352a10.822,10.822,0,0,0,1.5-.1c3.163-.445,13.7-2.8,16.477-14.481a19.6,19.6,0,0,0,.506-4.524V654.72H605.829v17.125a18.048,18.048,0,0,1-4.014,11.448,13.966,13.966,0,0,1-5.885,4.352"
            transform="translate(-527.423 -535.092)"
          />
          <Path
            id="Path_198289"
            data-name="Path 198289"
            d="M772.451,15.422A15.409,15.409,0,1,1,757.056,0a15.408,15.408,0,0,1,15.4,15.422"
            transform="translate(-650.319 0)"
            fill="#e85b49"
          />
          <Path
            id="Path_198304"
            data-name="Path 198304"
            d="M1067.695,986.091l1.086,4.877.963,4.316h5.478l-.59-5.3-.442-3.985Z"
            transform="translate(-917.026 -806.514)"
            fill="#fff"
          />
          <Path
            id="Path_198305"
            data-name="Path 198305"
            d="M1065.829,984.58l7.079-.1,1.093,9.833h-6Zm6.59.454-5.911.083,1.929,8.651h4.953Z"
            transform="translate(-915.5 -805.272)"
          />
          <Path
            id="Path_198306"
            data-name="Path 198306"
            d="M1040.742,1026.2c-.479.5-2.431,2.143-5.662-.1a.092.092,0,0,0-.1-.006l-8.91,4.762a.454.454,0,0,0,.214.854h14.538a1.045,1.045,0,0,0,1.019-1.275l-.947-4.193a.092.092,0,0,0-.156-.044"
            transform="translate(-882.784 -839.303)"
            fill="#a1a3b3"
          />
          <Path
            id="Path_198307"
            data-name="Path 198307"
            d="M973.695,986.091l.983,5.365.7,3.828h5.477l-.388-5.385-.28-3.9Z"
            transform="translate(-840.137 -806.514)"
            fill="#fff"
          />
          <Path
            id="Path_198308"
            data-name="Path 198308"
            d="M971.9,984.58l7.076-.1.708,9.833h-6Zm6.568.454-5.914.083,1.586,8.651H979.1Z"
            transform="translate(-838.668 -805.272)"
          />
          <Path
            id="Path_198309"
            data-name="Path 198309"
            d="M1067.695,986.091l1.086,4.877,5.851-.983-.442-3.985Z"
            transform="translate(-917.026 -806.514)"
          />
          <Path
            id="Path_198310"
            data-name="Path 198310"
            d="M973.695,986.091l.983,5.365,5.792-1.556-.28-3.9Z"
            transform="translate(-840.137 -806.514)"
          />
          <Path
            id="Path_198311"
            data-name="Path 198311"
            d="M945.742,1026.2c-.479.5-2.431,2.143-5.662-.1a.092.092,0,0,0-.1-.006l-8.91,4.762a.454.454,0,0,0,.214.854h14.538a1.045,1.045,0,0,0,1.019-1.275l-.947-4.193a.092.092,0,0,0-.156-.044"
            transform="translate(-805.078 -839.303)"
            fill="#a1a3b3"
          />
          <Path
            id="Path_198312"
            data-name="Path 198312"
            d="M1023.807,234.366s-.528,1.922-.989,3.193a1.324,1.324,0,0,1-1.731.81l.011-3.294Z"
            transform="translate(-878.903 -191.703)"
          />
          <Path
            id="Path_198313"
            data-name="Path 198313"
            d="M968.049,199.443c0,.005,0,.009,0,.015-.089.806-.167,1.469-.244,2.044a41.427,41.427,0,0,1-1.3,5.962l9.493.917-.928-13.5c.242-.315,3.892-3.948.859-4.826,0,0,1.007-6.974-4.443-6.7s-7.467,3.746-7.467,3.746-3.26,13.838,4.03,12.333"
            transform="translate(-831.628 -149.979)"
            fill="#fff"
          />
          <Path
            id="Path_198314"
            data-name="Path 198314"
            d="M964.914,206.476l.252-.97a36.643,36.643,0,0,0,1.12-5.269c.062-.465.127-1.008.2-1.691a3.493,3.493,0,0,1-3.182-.926c-2.851-2.931-.886-11.441-.8-11.8l.009-.04.02-.035c.086-.147,2.157-3.6,7.689-3.882a4.121,4.121,0,0,1,3.433,1.241c1.577,1.729,1.408,4.688,1.322,5.537a1.642,1.642,0,0,1,1.115,1.066c.4,1.379-1.347,3.323-1.92,3.962l-.071.08.943,13.706Zm5.339-24.068c-4.946.254-7,3.235-7.225,3.579-.148.659-1.875,8.636.672,11.253a3.083,3.083,0,0,0,3.047.707l.328-.068v.335l0,.049c-.094.851-.172,1.5-.244,2.046a37.037,37.037,0,0,1-1.133,5.334l-.091.35,8.854.856-.913-13.278.063-.082c.025-.032.081-.1.161-.185,1.4-1.564,2.009-2.724,1.8-3.447-.1-.356-.417-.607-.963-.765l-.229-.066.034-.235c0-.035.485-3.517-1.158-5.32a3.49,3.49,0,0,0-2.706-1.072c-.1,0-.2,0-.3.008"
            transform="translate(-830.382 -148.75)"
          />
          <Path
            id="Path_198315"
            data-name="Path 198315"
            d="M967.761,168.555s-3.244-6.036-10.7-.342c-.66.5-1.314.417-2.772,1.1a1.8,1.8,0,0,0-.254,3.121c1.729,1.165,3.852,1.825,8.21-.031a7.983,7.983,0,0,1-5.051,1.726c6.214.619,7.479-1.883,7.56-1.928a6.238,6.238,0,0,0,1.2,5.025,1.973,1.973,0,0,1,.626-1.077,1.467,1.467,0,0,1,2.306.469s3.408-6.383-1.126-8.062"
            transform="translate(-823.413 -135.593)"
          />
          <Path
            id="Path_198316"
            data-name="Path 198316"
            d="M971.222,215.883l-2.55-.263a1.955,1.955,0,1,1-3.881.307l-1.079-.112a1.956,1.956,0,1,1,.036-.354l1.079.112a1.954,1.954,0,0,1,3.743-.321l2.687.278Zm-7.836-.281a1.6,1.6,0,1,0-1.756,1.427,1.6,1.6,0,0,0,1.756-1.427m4.952.512a1.6,1.6,0,1,0-1.756,1.427,1.6,1.6,0,0,0,1.756-1.427"
            transform="translate(-828.803 -174.62)"
          />
          <Path
            id="Path_198317"
            data-name="Path 198317"
            d="M900.375,546.007l.8,6.784.657,5.567,1.649,13.948,8.177,51.976h11.468c0-.326-1.588-18.209-2.709-32.322l0,0c-.9-11.379-1.44-37.113-1.44-37.113l.042-2.283.217-12.049.009-.511-1.915.61-8.284,2.638Z"
            transform="translate(-780.164 -441.702)"
            fill="#fff"
          />
          <Path
            id="Path_198317_-_Outline"
            data-name="Path 198317 - Outline"
            d="M917.836,537.249l-.018,1.019-.217,12.049-.042,2.276c.005.248.14,6.629.381,14.279.224,7.132.588,16.849,1.051,22.728l0,0,.006.078c.722,9.089,1.646,19.839,2.2,26.261.387,4.5.512,5.968.512,6.089v.364H909.567l-8.228-52.3-1.649-13.947-.657-5.567-.838-7.083,8.958-2.851Zm.445,52.568,0,0-.006-.078c-.206-2.6-.41-6.17-.607-10.612-.157-3.552-.309-7.666-.453-12.229-.244-7.768-.38-14.229-.381-14.293v-.014l.042-2.283.217-12.053-9.716,3.094-8.385,2.668.767,6.486.657,5.567,1.648,13.94,8.128,51.662h10.766c-.066-.828-.249-2.944-.482-5.663C919.922,609.6,919,598.891,918.281,589.816Z"
            transform="translate(-778.381 -439.451)"
          />
          <Path
            id="Path_198318"
            data-name="Path 198318"
            d="M944.885,540l.186,1.056.384,2.192,1.438,8.2.047.269.932,5.314,2.017,13c.083.479,1.338,4.968,1.465,5.437l13.054,48.809h11.834l-9.606-50.97-.057-21.735v-.029l-.009-3.337L965.835,540Z"
            transform="translate(-816.572 -441.702)"
            fill="#fff"
          />
          <Path
            id="Path_198319"
            data-name="Path 198319"
            d="M949.628,574.318c-.024-.089-.089-.321-.177-.643-.6-2.184-1.231-4.465-1.294-4.824l-2.016-13L943.1,538.5h21.526l.758,8.46.067,25.1,9.662,51.268H962.736Zm15.277-2.182L964.835,547l-.712-7.951H943.747l2.932,16.716,2.016,13c.066.375.918,3.453,1.281,4.767.09.323.154.557.179.647l13,48.606h11.3Z"
            transform="translate(-815.109 -440.475)"
          />
          <Path
            id="Path_198320"
            data-name="Path 198320"
            d="M889.156,341.045l-.036.213c.16.033.32.066.48.1,5.6,1.09,9.521-3.537,16.054-1.8a13.8,13.8,0,0,1,1.851.641c8.556,3.641,14.563-1.092,14.563-1.092l-.741-9.074-.219-3.6-.2-3.282-.1-1.635-.208-3.433-.058-.97-.264-4.371.149-5.494,4.492.546,1.469-10.347a5.645,5.645,0,0,0-2.98-5.45l-9.648-4.727c-6.561,4.307-8.132-.441-8.132-.441l-9.267,2.033a5.653,5.653,0,0,0-3.928,3.042l-4.931,11.845,6.972,1.436-1.12,7.533-.522,3.522-1.021,6.883-1.6,11.612-.544,3.253-.5,3Z"
            transform="translate(-769.637 -234.617)"
            fill="#e85b49"
          />
          <Path
            id="Path_198321"
            data-name="Path 198321"
            d="M905.693,339.018a13.581,13.581,0,0,0-1.815-.628c-3.419-.91-6.129-.029-8.75.823-2.373.771-4.615,1.5-7.285.98-.154-.03-.308-.061-.462-.093l-.281-.057,1.135-6.774,1.6-11.615,2.625-17.682-7.043-1.451,5.065-12.166a5.886,5.886,0,0,1,4.115-3.188l9.268-2.033.239-.052.078.232a3.283,3.283,0,0,0,1.92,1.875c1.48.511,3.432-.019,5.8-1.576l.13-.085,9.8,4.8a5.915,5.915,0,0,1,3.122,5.73l-1.507,10.612-4.46-.542-.141,5.182,1.046,17.27.753,9.22-.115.091a14.474,14.474,0,0,1-8.3,2.516,16.586,16.586,0,0,1-6.536-1.387m13.656-10.4L918.3,311.329v-.012l.158-5.806,4.525.55L924.414,296a5.363,5.363,0,0,0-2.828-5.183l-9.509-4.659c-2.467,1.591-4.539,2.112-6.159,1.547a3.772,3.772,0,0,1-2.156-1.985l-9.047,1.985a5.34,5.34,0,0,0-3.732,2.879l-4.8,11.539,6.9,1.422-2.7,18.19-1.6,11.62L887.73,339.6l0,.014.22.044c2.533.493,4.709-.214,7.013-.963,2.7-.876,5.484-1.783,9.059-.831a14.108,14.108,0,0,1,1.888.653,14.722,14.722,0,0,0,14.171-.971Z"
            transform="translate(-767.932 -233.186)"
          />
          <Path
            id="Path_198322"
            data-name="Path 198322"
            d="M897.516,398.94l16.2-2.483,1.221-.188,3.167-.486-.923-4.682-4.982.064h-.033l-11.182.142,1.205-7.138.151-.9L894.878,382l-.1,1.862-.664,11.909a2.967,2.967,0,0,0,3.406,3.169"
            transform="translate(-775.032 -312.462)"
            fill="#fff"
          />
          <Path
            id="Path_198323"
            data-name="Path 198323"
            d="M893.633,396.939a3.217,3.217,0,0,1-1.023-2.623l.785-14.076,8.033,1.372-1.347,7.979,16.1-.2,1.022,5.184-20.871,3.2a3.28,3.28,0,0,1-.5.038,3.219,3.219,0,0,1-2.2-.869m22.923-2.822-.824-4.179-16.3.207,1.365-8.087-6.893-1.177-.752,13.472a2.694,2.694,0,0,0,3.092,2.878Z"
            transform="translate(-773.804 -311.022)"
          />
          <Path
            id="Path_198324"
            data-name="Path 198324"
            d="M993.354,432.064l1.549,5.292,1.221-.187,3.167-.486L998.37,432l-4.982.064Z"
            transform="translate(-856.218 -353.361)"
          />
          <Path
            id="Path_198325"
            data-name="Path 198325"
            d="M911.812,311.279l5.852,3.73a1.3,1.3,0,0,0,.7.2H920.9s7.047,23.466,12.56,25.323c3.7,1.247,8.474-8.142,10.243-11.969.446-.963.7-1.573.7-1.573l-7.667-1.385-.244.457-2.683,5.006-7.813-13.856s-1.465-2.441-1.638-2.731a24.121,24.121,0,0,0-1.638-2.548,4.268,4.268,0,0,0-3.641-1.274c-.228.024-.379.61-.182.728l1.274.546a18.044,18.044,0,0,1-6.189-1.274,1.9,1.9,0,0,0-2.159.067.327.327,0,0,0-.009.55"
            transform="translate(-789.401 -253.867)"
            fill="#fff"
          />
          <Path
            id="Path_198326"
            data-name="Path 198326"
            d="M932.144,339.563c-5.376-1.81-11.938-22.886-12.676-25.308h-2.33a1.573,1.573,0,0,1-.847-.247l-5.856-3.732a.594.594,0,0,1-.267-.5.6.6,0,0,1,.282-.506,2.168,2.168,0,0,1,2.427-.08,14.523,14.523,0,0,0,4.56,1.132.653.653,0,0,1-.107-.6c.056-.253.216-.534.493-.563a4.634,4.634,0,0,1,3.883,1.375,20.944,20.944,0,0,1,1.477,2.272l.182.307c.173.289,1.638,2.73,1.642,2.737l7.569,13.424,2.786-5.2,8.2,1.481-.131.314c0,.006-.262.626-.705,1.582-2.181,4.715-6.221,12.242-9.792,12.242a2.45,2.45,0,0,1-.787-.129m3.511-14.882-3.068,5.725-8.053-14.283s-1.466-2.441-1.639-2.731l-.183-.309a20.511,20.511,0,0,0-1.434-2.209,4,4,0,0,0-3.36-1.177.5.5,0,0,0-.069.241l2.51,1.075-1.424-.037a18.066,18.066,0,0,1-6.3-1.3,1.626,1.626,0,0,0-1.89.053.057.057,0,0,0-.028.049.047.047,0,0,0,.021.042l5.85,3.728a1.029,1.029,0,0,0,.554.161h2.736l.058.194c.07.233,7.068,23.352,12.386,25.143,3.566,1.2,8.485-8.747,9.908-11.825.253-.545.445-.979.563-1.25ZM917.879,309.7h0"
            transform="translate(-788.174 -252.636)"
          />
          <Path
            id="Path_198327"
            data-name="Path 198327"
            d="M1032.249,404.008a1,1,0,0,1,.654-.937c-.214-.651.654-.937.654-.937.725-.72,3.327-.854,4.264-.2s-.583,1.154-.583,1.154c.3,1.182-1.059,1.061-1.059,1.061s.572.988-.91,1.092c.105.116.087.5-.364.728-.809.4-2.731-.91-2.731-.91-.642-.524.075-1.051.075-1.051"
            transform="translate(-887.752 -328.423)"
            fill="#fff"
          />
          <Path
            id="Path_198328"
            data-name="Path 198328"
            d="M1030.793,404.071l-.019-.014a.866.866,0,0,1-.367-.7,1.034,1.034,0,0,1,.348-.691,1.229,1.229,0,0,1,.615-.96.91.91,0,0,1,.382-.772,1.725,1.725,0,0,1,.432-.248,4.955,4.955,0,0,1,4.567-.187.766.766,0,0,1,.416.742c-.06.409-.541.678-.846.81a.975.975,0,0,1-.209.768,1.231,1.231,0,0,1-.788.38.865.865,0,0,1-.084.539,1.144,1.144,0,0,1-.875.528c0,.022-.005.045-.009.067a.978.978,0,0,1-.556.67,1.109,1.109,0,0,1-.5.1,5.654,5.654,0,0,1-2.508-1.034m2.669-.276.56-.04c.392-.028.652-.125.733-.275a.509.509,0,0,0-.04-.408l-.261-.452.521.043a.994.994,0,0,0,.721-.2.571.571,0,0,0,.05-.519l-.063-.247.242-.08c.307-.1.68-.322.7-.456,0-.028-.031-.1-.188-.214-.821-.574-3.273-.467-3.915.17l-.046.045-.06.02c-.025.008-.607.21-.481.592l.082.255-.253.089a.715.715,0,0,0-.469.679v.138l-.111.081a.6.6,0,0,0-.23.355.351.351,0,0,0,.158.258c.744.507,1.987,1.113,2.444.885.239-.119.271-.27.266-.32Z"
            transform="translate(-886.526 -327.209)"
          />
          <Path
            id="Path_198329"
            data-name="Path 198329"
            d="M1048.035,394.567l7.21,2.5c.446-.963.7-1.573.7-1.573l-7.667-1.385Z"
            transform="translate(-900.945 -322.368)"
          />
          <Path
            id="Path_198330"
            data-name="Path 198330"
            d="M897.8,383.862l7.416.308.151-.9L897.908,382Z"
            transform="translate(-778.061 -312.462)"
          />
          <Path
            id="Path_198331"
            data-name="Path 198331"
            d="M986.707,261.757a8.468,8.468,0,0,0,4.24-4.737,7.418,7.418,0,0,1-4.542,2.51c-.034.309.377.261.445.564a5.6,5.6,0,0,1-.143,1.663"
            transform="translate(-850.532 -210.234)"
          />
          <Path
            id="Path_198332"
            data-name="Path 198332"
            d="M973.375,252h2.73s-1.82,2.184-2.73,0"
            transform="translate(-839.876 -206.127)"
          />
          <Path
            id="Path_198333"
            data-name="Path 198333"
            d="M1043.091,406.4a1,1,0,0,0,.723-.247.856.856,0,0,0,.245-.575,1.092,1.092,0,0,0,.866-.457.932.932,0,0,0,.111-.643,1.212,1.212,0,0,0,.862-.455,1.064,1.064,0,0,0,.177-.73c.525-.268.778-.56.772-.891-.01-.5-.636-.766-.707-.795l-.2.507c.163.066.363.214.364.3,0,.064-.132.246-.643.474l-.227.1.078.235a.614.614,0,0,1-.057.484,1,1,0,0,1-.776.249h-.442l.2.4s.141.3.028.484c-.086.137-.332.213-.694.213h-.353l.089.342s.057.254-.068.373c-.065.062-.292.185-.983-.019l-.155.524a2.867,2.867,0,0,0,.8.134"
            transform="translate(-896.25 -328.499)"
          />
          <rect
            id="Rectangle_26645"
            data-name="Rectangle 26645"
            width="3.976"
            height="0.546"
            transform="translate(146.889 72.011) rotate(-74.055)"
          />
          <rect
            id="Rectangle_26646"
            data-name="Rectangle 26646"
            width="5.029"
            height="0.546"
            transform="matrix(0.124, -0.992, 0.992, 0.124, 126.781, 72.31)"
          />
        </G>
        <Path
          id="Path_198686"
          data-name="Path 198686"
          d="M5977.9-281.975h47.855"
          transform="translate(-28.902 -0.132)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <Path
          id="Path_210740"
          data-name="Path 210740"
          d="M5977.9-281.975h47.855"
          transform="translate(-28.902 18.204)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <Path
          id="Path_210744"
          data-name="Path 210744"
          d="M5977.9-281.975h47.855"
          transform="translate(-28.902 37.602)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <g
          id="Group_115703"
          data-name="Group 115703"
          transform="translate(5949.001 -291.275)"
        >
          <Path
            id="Path_198679"
            data-name="Path 198679"
            d="M5977.9-281.975h15.725"
            transform="translate(-5977.903 281.975)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_210738"
            data-name="Path 210738"
            d="M5977.9-281.975h48.164"
            transform="translate(-5977.903 300.311)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_210742"
            data-name="Path 210742"
            d="M5977.9-281.975h15.725"
            transform="translate(-5977.903 319.709)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_198680"
            data-name="Path 198680"
            d="M5977.9-281.975h47.854"
            transform="translate(-5977.904 286.384)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_210739"
            data-name="Path 210739"
            d="M5977.9-281.975h47.854"
            transform="translate(-5977.904 304.72)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_210743"
            data-name="Path 210743"
            d="M5977.9-281.975h47.854"
            transform="translate(-5977.904 324.118)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
        </g>
        <Path
          id="Path_198685"
          data-name="Path 198685"
          d="M5977.9-281.975h44.459"
          transform="translate(-28.902 4.277)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <Path
          id="Path_210741"
          data-name="Path 210741"
          d="M5977.9-281.975h48.163"
          transform="translate(-28.902 22.613)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <Path
          id="Path_210745"
          data-name="Path 210745"
          d="M5977.9-281.975h44.459"
          transform="translate(-28.902 42.01)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-width="1"
        />
        <g
          id="Group_115706"
          data-name="Group 115706"
          transform="translate(5968.334 -222.803)"
        >
          <Path
            id="Path_198688"
            data-name="Path 198688"
            d="M5977.9-281.975h15.725"
            transform="translate(-5977.903 281.975)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
          <Path
            id="Path_198687"
            data-name="Path 198687"
            d="M5977.9-281.975h27.2"
            transform="translate(-5977.903 286.384)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-width="1"
          />
        </g>
        <G
          id="Group_115733"
          data-name="Group 115733"
          transform="matrix(0.998, 0.07, -0.07, 0.998, 5996.627, -323.204)"
        >
          <Path
            id="Path_198675"
            data-name="Path 198675"
            d="M0,0,13.953,13.412"
            transform="translate(0.899 0.908)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="3"
          />
          <Path
            id="Path_198676"
            data-name="Path 198676"
            d="M0,13.412,13.954,0"
            transform="matrix(-0.995, 0.105, -0.105, -0.995, 15.28, 13.339)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="3"
          />
        </G>
      </G>
    </Svg>
  );
}

export default PaymentFailed;
