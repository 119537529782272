import { ListInvoices } from '.';
import { zonoApi } from '../api';
import {
  workspaceDetails,
  InvoiceBysellerRequest,
  PartyAccountBookRequest,
  PartyAccountBookResponse,
  InvoiceResponse,
  SellerInvoiceRequest,
  SellerInvoiceResponse,
  BuyerPartyBookAccountResponse,
  BuyerPartyBookAccountRequest,
  AggregatedInvoiceDetailsResponse,
  AggregatedInvoiceDetailsRequestParams,
  CustomerRoutesResponse,
  CustomerRoutesParams,
  CustomerGroupResponse,
  CustomerGroupParams,
  PendingInvoiceRequestParams,
  PendingInvoiceResponse,
  InvoiceDetailsRequest,
  Invoice,
  InvoiceList,
  InvoiceRequest,
} from './invoice-model';

export const invoiceApi = zonoApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatyBookAccount: builder.mutation<
      PartyAccountBookResponse,
      PartyAccountBookRequest
    >({
      query(PartyAccountBookRequest: PartyAccountBookRequest) {
        return {
          url: `invoiceservice/guest/partyAccountBook/balanceBySeller`,
          method: 'GET',
          params: PartyAccountBookRequest,
        };
      },
    }),
    getGuestInvoiceDetailsBySeller: builder.mutation<
      ListInvoices,
      InvoiceBysellerRequest
    >({
      query(InvoiceBysellerRequest: InvoiceBysellerRequest) {
        return {
          url: `invoiceservice/guest/invoiceBySeller`,
          method: 'GET',
          params: InvoiceBysellerRequest,
        };
      },
    }),
    getInvoiceDetailsBySeller: builder.mutation<
      ListInvoices,
      InvoiceBysellerRequest
    >({
      query(InvoiceBysellerRequest: InvoiceBysellerRequest) {
        return {
          url: `invoiceservice/invoiceBySeller`,
          method: 'GET',
          params: InvoiceBysellerRequest,
        };
      },
    }),
    getWorkSpaceDetailsById: builder.query<workspaceDetails, string>({
      query: (workSpaceId) => `guest/workspace/${workSpaceId}`,
      providesTags: ['WorkSpaceDetails'],
    }),
    workSpaceDetailsById: builder.mutation<workspaceDetails, string>({
      query: (workSpaceId) => `workspaces/${workSpaceId}`,
    }),
    getInvoiceById: builder.mutation<
      InvoiceResponse,
      { invoiceId: string; includePayment: boolean }
    >({
      query(arg) {
        return {
          url: `invoiceservice/guest/invoice/${arg.invoiceId}`,
          method: 'GET',
          params: { includePayment: arg.includePayment },
        };
      },
    }),
    getInvoiceBySeller: builder.mutation<
      SellerInvoiceResponse,
      SellerInvoiceRequest
    >({
      query(SellerInvoiceRequest: SellerInvoiceRequest) {
        return {
          url: `invoiceservice/invoice/balanceSellerwise`,
          method: 'GET',
          params: SellerInvoiceRequest,
        };
      },
    }),
    getPartyAccountBookDataByBuyerId: builder.mutation<
      BuyerPartyBookAccountResponse,
      BuyerPartyBookAccountRequest
    >({
      query(BuyerPartyBookAccountRequest: BuyerPartyBookAccountRequest) {
        return {
          url: `invoiceservice/guest/partyAccountBook/balance`,
          method: 'GET',
          params: BuyerPartyBookAccountRequest,
        };
      },
    }),
    aggregatedInvoiceDetails: builder.mutation<
      AggregatedInvoiceDetailsResponse,
      AggregatedInvoiceDetailsRequestParams
    >({
      query: (params) => {
        return {
          url: `invoiceservice/invoices/aggregated/${params.workspaceId}`,
          params: params.customerGroupId
            ? {
                teamMemberId: params.teamMemberId,
                customerGroupId: params.customerGroupId,
                startDate: params.startDate,
                endDate: params.endDate,
              }
            : {
                teamMemberId: params.teamMemberId,
                startDate: params.startDate,
                endDate: params.endDate,
              },
          method: 'GET',
        };
      },
    }),
    getCustomerRoutes: builder.query<
      CustomerRoutesResponse[],
      CustomerRoutesParams
    >({
      query: (params) => {
        const { workspaceId, ...otherParams } = params;
        return {
          url: `teams/groups/${workspaceId}`,
          params: otherParams,
          method: 'GET',
        };
      },
    }),
    getCustomerGroupById: builder.query<
      CustomerGroupResponse,
      CustomerGroupParams
    >({
      query: (params) => {
        return {
          url: `invoiceservice/partyAccountBook/balanceBySeller`,
          params: {
            ...params,
          },
          method: 'GET',
        };
      },
    }),
    getPendingInvoices: builder.mutation<
      PendingInvoiceResponse,
      PendingInvoiceRequestParams
    >({
      query: (params: PendingInvoiceRequestParams) => {
        return {
          url: `invoiceservice/reports/invoices`,
          params: params,
          method: 'GET',
        };
      },
    }),
    getInvoiceDetails: builder.query<Invoice, InvoiceDetailsRequest>({
      query(arg) {
        const { invoiceId, ...params } = arg;
        return {
          url: `invoiceservice/invoice/${invoiceId}?includePayment=true`,
          method: 'GET',
          params: params,
        };
      },
    }),
    downloadOrderInvoice: builder.mutation<
      string,
      { workspaceId: string; body: any }
    >({
      query(args) {
        return {
          url: `invoiceservice/invoices/download/${args.workspaceId}?sellerWorkspaceId=${args.workspaceId}`,
          method: 'POST',
          body: args.body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getAllInvoice: builder.mutation<InvoiceList, InvoiceRequest>({
      query(arg) {
        const { workspaceId, body, ...params } = arg;
        return {
          url: `invoiceservice/invoices/all/${workspaceId}`,
          method: 'POST',
          body: body || {},
          params: params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPatyBookAccountMutation,
  useGetWorkSpaceDetailsByIdQuery,
  useLazyGetWorkSpaceDetailsByIdQuery,
  useGetInvoiceByIdMutation,
  useGetInvoiceBySellerMutation,
  useGetPartyAccountBookDataByBuyerIdMutation,
  useGetInvoiceDetailsBySellerMutation,
  useWorkSpaceDetailsByIdMutation,
  useAggregatedInvoiceDetailsMutation,
  useLazyGetCustomerRoutesQuery,
  useGetCustomerRoutesQuery,
  useLazyGetCustomerGroupByIdQuery,
  useGetCustomerGroupByIdQuery,
  useGetPendingInvoicesMutation,
  useGetGuestInvoiceDetailsBySellerMutation,
  useLazyGetInvoiceDetailsQuery,
  useGetAllInvoiceMutation,
  useDownloadOrderInvoiceMutation,
} = invoiceApi;
