import React from 'react';
import { ProductCard } from './product-card';
import { SearchBar } from '../common';
import { OrderDataLine } from '@zonofi/zono-money-helpers';

/* eslint-disable-next-line */
export interface OrderProductsListProps {
  poLines: OrderDataLine[];
  showPrice: boolean;
}

export const OrderProductsList: React.FC<OrderProductsListProps> = ({
  poLines,
  showPrice,
}) => {
  const [searchKey, setSearchKey] = React.useState('');

  const filteredOrderLines = React.useMemo(
    () =>
      poLines?.filter(
        (line) =>
          line?.title
            ?.toLocaleLowerCase()
            ?.includes(searchKey.toLocaleLowerCase()) ||
          line?.skuCode
            ?.toLocaleLowerCase()
            ?.includes(searchKey.toLocaleLowerCase())
      ),
    [searchKey, poLines]
  );

  return (
    <main className="product-details-container">
      <section className="search-section">
        <SearchBar
          setSearchKey={setSearchKey}
          placeholder="Search SKU, product name"
        />
      </section>
      <section className="product-card-container">
        {filteredOrderLines?.map((orderline, index) => (
          <ProductCard
            key={index}
            orderline={orderline}
            showPrice={showPrice}
          />
        ))}
      </section>
    </main>
  );
};
