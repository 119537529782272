export const qtyMultiplierOptions = () => {
  const newArray = [];
  for (let i = 1; i <= 20; i++) {
    newArray.push({
      label: i.toString(),
      value: i.toString(),
    });
  }
  return newArray;
};
