import { fontConfig } from '../fonts/fonts';
import { ButtonVariants } from '../types/enum';
import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TextStyle,
  StyleSheet,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { colors } from '../colors/colors';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { TailSpin } from 'react-loader-spinner';

/* eslint-disable-next-line */
export interface ButtonProps {
  variant: 'gradient' | 'plain';
  gradientColors?: string[];
  text: string;
  onPress?: () => void;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  isLoading?: boolean;
  outerStyle?: StyleProp<ViewStyle>;
  icon?: React.ReactNode;
  plainColor?: string;
  isLeftIcon?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  variant,
  gradientColors = Object.values(colors.redVioletLinearGradient),
  text,
  onPress,
  disabled = false,
  style,
  textStyle,
  outerStyle,
  icon,
  plainColor = colors.charlestonGrey.color,
  isLoading,
  isLeftIcon,
}) => {
  switch (variant) {
    case ButtonVariants.GRADIENT:
      return (
        <TouchableOpacity
          onPress={onPress}
          disabled={disabled}
          style={outerStyle}
        >
          <LinearGradient
            colors={gradientColors}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 1 }}
            style={style ? style : styles.container}
          >
            {isLoading ? (
              <TailSpin
                height={25}
                width={25}
                color={colors.white.color}
                strokeWidth={2.6}
                // ariaLabel="loading"
              />
            ) : icon ? (
              <View style={styles.alignmentStyle}>
                {isLeftIcon && icon}
                <Text style={textStyle ? textStyle : styles.text}>{text}</Text>
                {!isLeftIcon && icon}
              </View>
            ) : (
              <Text style={[textStyle ? textStyle : styles.text]}>{text}</Text>
            )}
          </LinearGradient>
        </TouchableOpacity>
      );
    case ButtonVariants.PLAIN:
      return (
        <TouchableOpacity
          onPress={onPress}
          disabled={disabled}
          style={outerStyle}
        >
          <View
            style={[
              style ? style : styles.plainContainer,
              { backgroundColor: plainColor },
            ]}
          >
            {isLoading ? (
              <LoadingSpinner size={'small'} color={colors.white.color} />
            ) : icon ? (
              <View style={styles.alignmentStyle}>
                {icon}
                <Text style={textStyle ? textStyle : styles.text}>{text}</Text>
              </View>
            ) : (
              <Text style={textStyle ? textStyle : styles.text}>{text}</Text>
            )}
          </View>
        </TouchableOpacity>
      );
    default:
      return (
        <TouchableOpacity
          onPress={onPress}
          disabled={disabled}
          style={outerStyle}
        >
          <View
            style={[
              style ? style : styles.plainContainer,
              { backgroundColor: plainColor },
            ]}
          >
            <Text style={textStyle ? textStyle : styles.text}>{text}</Text>
          </View>
        </TouchableOpacity>
      );
  }
};

const styles = StyleSheet.create({
  text: {
    ...fontConfig.default.circularStdBold,
    fontSize: 18,
    textAlign: 'center',
    color: colors.white.color,
  },
  container: { paddingVertical: 18, borderRadius: 10 },
  plainContainer: {
    paddingVertical: 18,
    borderRadius: 10,
  },
  alignmentStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
