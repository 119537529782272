import { isWeb } from '../functions';

const colorsWeb = {
  textPrimary: '#1D1D22',
  textSecondary: '#808285',
  textHighlight: '#33A7E8',
};

const colorsNative = {
  textPrimary: '#091E42',
  textSecondary: '#626F86',
  textHighlight: '#0067FF',
};

const colorsCommon = {
  msgBg: '#F7F8F9',
  cerulean: '#DBE9FF',
  ceruleanSecondary: '#B6D4FF',
  msgInputBg: '#ecf0f4',
  white: '#FFFFFF',
  davysGrey: '#555555',
  gainsboro: '#DCDFE4',
  lavender: '#EAEAFF',
  paleLavender: '#E8D8FF',
  pinkLace: '#FFE2F1',
  water: '#CCF1FF',
  mistyRose: '#FFE5DE',
  lemonChiffon: '#FFF8C6',
  gray90Opacity: '#808285',
  lavenderGray: '#bcc6d1',
  aquaMist: '#C8FAF9',
  cloudedPearl: '#F1F2F4',
  azureishWhite: '#DEF8ED',
  sereneBlue: '#DCF0FB',
  black: '#000000',
  orange90: '#B27800',
  orange10: '#FFF3DC',
  blue: '#002966',
  blue10: '#DBE9FF',
  green90: '#1D8459',
  green10: '#DEF8ED',
  silverBlue: '#DCDFE4',
  veryPaleOrange: '#FEE2BE',
  paleSpringBud: '#E5F9B3',
  msgFailure: '#FDE0D9',
};

export const colors = {
  ...(isWeb() ? colorsWeb : colorsNative),
  ...colorsCommon,
};
