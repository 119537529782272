import { StyleSheet, Dimensions } from 'react-native';
import { colors } from '../colors/colors';

export const ACTUAL_WIDTH = Dimensions.get('screen').width;
export const ACTUAL_HEIGHT = Dimensions.get('screen').height;

export const globalStyles = StyleSheet.create({
  /*  ---  Flex --- */
  displayFlex: {
    display: 'flex',
  },
  flex1: {
    flex: 1,
  },
  flex40Percent: {
    flex: 0.4,
  },
  flex60Percent: {
    flex: 0.6,
  },
  flex80Percent: {
    flex: 0.8,
  },
  flex90Percent: {
    flex: 0.9,
  },
  flex22Percent: {
    flex: 0.22,
  },
  flex78Percent: {
    flex: 0.78,
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  flexColumnJcSpaceArnd: {
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  flexColumnAlCenter: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexColumnAlFlexStart: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  flexColumnAlFlexEnd: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  flexRowAlCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexRowAlFlexEnd: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  flexRowAlFlexStart: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  flexRowJcCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  flexRowAlCenterJcCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRowAlFlexEndJcCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  flexRowAlFlexEndJcFlexStart: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  flexRowAlCenterJcFlexStart: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  flexColAlCenterJcCenter: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexColAlCenterJcSpaceBetn: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexColJcSpaceBetn: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flexColAlCenterJcSpaceEve: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  flexColAlCenterJcSpaceArnd: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  flexColAlCenterJcFlexStart: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  flexColAlCenterJcFlexEnd: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexColAlFlexStartJcCenter: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  flexRowAlCenterJcSpaceBetn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexRowAlStartJcSpaceBetn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  flexRowAlCenterJcSpaceEve: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
  flexRowAlCenterJcSpaceAr: {
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
  },
  flexRowAlFlexStartJcSpaceAr: {
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  flexRowAlFlexStartJcSpaceBetn: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  flexRowAlFlexEndJcSpaceBetn: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  flexRowAlFlexStartJcStart: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  flexShrink1: {
    flexShrink: 1,
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignSelfFlexStart: {
    alignSelf: 'flex-start',
  },
  alignSelfFlexEnd: {
    alignSelf: 'flex-end',
  },
  alignSelfStretch: {
    alignSelf: 'stretch',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end',
  },
  /*  ---  Margins --- */
  mB18: {
    marginBottom: 18,
  },
  mB0: {
    marginBottom: 0,
  },
  mB2: {
    marginBottom: 2,
  },
  mB3: {
    marginBottom: 3,
  },
  mB4: {
    marginBottom: 4,
  },
  mB5: {
    marginBottom: 5,
  },
  mB6: {
    marginBottom: 6,
  },
  mB8: {
    marginBottom: 8,
  },
  mB9: {
    marginBottom: 9,
  },
  mB10: {
    marginBottom: 10,
  },
  mB12: {
    marginBottom: 12,
  },
  mB15: {
    marginBottom: 15,
  },
  mB16: {
    marginBottom: 16,
  },
  mB20: {
    marginBottom: 20,
  },
  mB24: {
    marginBottom: 24,
  },
  mB30: {
    marginBottom: 30,
  },
  mB40: {
    marginBottom: 40,
  },
  mB100: {
    marginBottom: 100,
  },
  mB160: {
    marginBottom: 160,
  },
  pB160: {
    paddingBottom: 160,
  },
  mB60: {
    marginBottom: 60,
  },
  mB80: {
    marginBottom: 80,
  },
  m8: {
    margin: 8,
  },
  m12: {
    margin: 12,
  },
  m16: {
    margin: 16,
  },
  mL0: {
   marginLeft: 0,
  },
  mR0: {
    marginRight: 0,
   }, 
  mL3: {
    marginLeft: 3,
  },
  mL4: {
    marginLeft: 4,
  },
  mL6: {
    marginLeft: 6,
  }, 
  mL8: {
    marginLeft: 4,
  },
  mL9: {
    marginLeft: 9,
  },
  mL10: {
    marginLeft: 10,
  },
  mL14: {
    marginLeft: 14,
  },
  mL24: {
    marginLeft: 24,
  },
  mL32: {
    marginLeft: 32,
  },
  mL38: {
    marginLeft: 38,
  },
  mL40: {
    marginLeft: 40,
  },
  mR6: {
    marginRight: 6,
  },
  mR8: {
    marginRight: 8,
  },
  mR10: {
    marginRight: 10,
  },

  /**
   * margin right - 4
   */
  mR3: {
    marginRight: 3,
  },
  mR4: {
    marginRight: 4,
  },
  mR12: {
    marginRight: 12,
  },
  mR14: {
    marginRight: 14,
  },
  mR16: {
    marginRight: 16,
  },
  m4: { margin: 4 },
  mV12: {
    marginVertical: 12,
  },
  mV16: {
    marginVertical: 16,
  },
  mV5: {
    marginVertical: 5,
  },
  mV60: {
    marginVertical: 60,
  },
  mV6: {
    marginVertical: 6,
  },
  mV10: {
    marginVertical: 10,
  },
  mV2: {
    marginVertical: 2,
  },
  mV4: {
    marginVertical: 4,
  },
  mV8: {
    marginVertical: 8,
  },
  mV30: {
    marginVertical: 30,
  },
  mT12: { marginTop: 12 },
  mT14: { marginTop: 14 },
  mT16: { marginTop: 16 },
  mT20: { marginTop: 20 },
  mT24: { marginTop: 24 },
  mT40: { marginTop: 40 },
  mT60: { marginTop: 60 },
  mT100: { marginTop: 100 },
  mT2: { marginTop: 2 },
  mT3: { marginTop: 3 },
  mT8: {
    marginTop: 8,
  },
  mT4: { marginTop: 4 },
  mT5: { marginTop: 5 },
  mT6: { marginTop: 6 },
  mT21: { marginTop: 21 },
  mL12: { marginLeft: 12 },
  mL16: { marginLeft: 16 },
  mL2: { marginLeft: 2 },
  mL30: { marginLeft: 30 },
  mL60: { marginLeft: 60 },
  mL80: { marginLeft: 80 },
  mT10: { marginTop: 10 },
  mT25: { marginTop: 25 },
  mT30: { marginTop: 30 },
  mH4: { marginHorizontal: 4 },
  mH12: { marginHorizontal: 12 },
  mH10: { marginHorizontal: 10 },
  mH20: { marginHorizontal: 20 },
  mH24: { marginHorizontal: 24 },
  mH8: { marginHorizontal: 8 },
  mH16: { marginHorizontal: 16 },
  mH18: { marginHorizontal: 18 },
  mH60: { marginHorizontal: 60 },
  /*  ---  Paddings --- */
  padding0: {
    padding: 0,
  },
  padding7: {
    padding: 7,
  },
  p4: {
    padding: 4,
  },
  p5: {
    padding: 5,
  },
  p6: {
    padding: 6,
  },
  p8: {
    padding: 8,
  },
  p10: {
    padding: 10,
  },
  p12: {
    padding: 12,
  },
  p16: {
    padding: 16,
  },
  p24: {
    padding: 24,
  },
  p32: {
    padding: 32,
  },
  pL4: {
    paddingLeft: 4,
  },
  pL8: {
    paddingLeft: 8,
  },
  pL10: {
    paddingLeft: 10,
  },
  pL6: {
    paddingLeft: 6,
  },
  pR4: {
    paddingRight: 4,
  },
  pR10: {
    paddingRight: 10,
  },
  pR12: {
    paddingRight: 12,
  },
  pR16: {
    paddingRight: 16,
  },
  pR20: {
    paddingRight: 20,
  },
  pL12: {
    paddingLeft: 12,
  },
  pL14: {
    paddingLeft: 14,
  },
  pL16: {
    paddingLeft: 16,
  },
  pL20: {
    paddingLeft: 20,
  },
  paddingHo25: {
    paddingHorizontal: 25,
  },
  pT7: {
    paddingTop: 7,
  },
  pV1: {
    paddingVertical: 1,
  },
  pV2: {
    paddingVertical: 2,
  },
  pV4: {
    paddingVertical: 4,
  },
  pV8: {
    paddingVertical: 8,
  },
  pH4: {
    paddingHorizontal: 4,
  },
  pH7: {
    paddingHorizontal: 7,
  },
  pH8: {
    paddingHorizontal: 8,
  },
  pH13: {
    paddingHorizontal: 13,
  },
  pH12: {
    paddingHorizontal: 12,
  },
  pH16: {
    paddingHorizontal: 12,
  },
  pH20: {
    paddingHorizontal: 20,
  },
  pH24: {
    paddingHorizontal: 24,
  },
  pH30: {
    paddingHorizontal: 30,
  },
  pV7: {
    paddingVertical: 7,
  },
  pV6: {
    paddingVertical: 6,
  },
  pV10: {
    paddingVertical: 10,
  },
  pV12: {
    paddingVertical: 12,
  },
  pV13: {
    paddingVertical: 13,
  },
  pV14: {
    paddingVertical: 14,
  },
  pV15: {
    paddingVertical: 15,
  },
  pV16: {
    paddingVertical: 16,
  },
  pV30: {
    paddingVertical: 30,
  },
  pV3: {
    paddingVertical: 3,
  },
  pT3: {
    paddingTop: 3,
  },
  pT4: {
    paddingTop: 4,
  },
  pT5: {
    paddingTop: 5,
  },
  pT6: {
    paddingTop: 6,
  },
  pT8: {
    paddingTop: 8,
  },
  pT10: {
    paddingTop: 10,
  },
  pB2: {
    paddingBottom: 2,
  },
  pB4: {
    paddingBottom: 4,
  },
  pB6: {
    paddingBottom: 6,
  },
  pB8: {
    paddingBottom: 8,
  },
  pB10: {
    paddingBottom: 10,
  },
  pB14: {
    paddingBottom: 14,
  },
  pB20: {
    paddingBottom: 20,
  },
  pB40: {
    paddingBottom: 40,
  },
  pB100: {
    paddingBottom: 100,
  },
  pT12: {
    paddingTop: 12,
  },
  pT14: {
    paddingTop: 14,
  },
  pT16: {
    paddingTop: 16,
  },
  pB12: {
    paddingBottom: 12,
  },
  pT20: {
    paddingTop: 20,
  },
  pV20: {
    paddingVertical: 20,
  },
  pV24: {
    paddingVertical: 24,
  },
  /*  ---  Borders --- */
  borderRd2: {
    borderRadius: 2,
  },
  borderRd3: {
    borderRadius: 3,
  },
  borderRd4: {
    borderRadius: 4,
  },
  borderRd6: {
    borderRadius: 6,
  },
  borderRd8: {
    borderRadius: 8,
  },
  borderRd10: {
    borderRadius: 10,
  },
  borderRd15: {
    borderRadius: 15,
  },
  borderRd16: {
    borderRadius: 16,
  },
  borderRd20: {
    borderRadius: 20,
  },
  borderR42: {
    borderRadius: 42,
  },
  borderR45: {
    borderRadius: 45,
  },
  borderRd50: {
    borderRadius: 50,
  },
  borderRd100: {
    borderRadius: 100,
  },
  borderRd32: {
    borderRadius: 32,
  },
  bottomBorderR8: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  bottomBorderR12: {
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  bottomBorderR32: {
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
  },
  topBorderR8: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  topBorderR16: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  topBorderR24: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  borderSolid: {
    borderStyle: 'solid',
    borderWidth: 1,
  },
  borderSolid3: {
    borderStyle: 'solid',
    borderWidth: 3,
  },
  borderSolidBottomWidth1: {
    borderStyle: 'solid',
    borderBottomWidth: 1,
  },
  /* --- Divider --- */
  divider: {
    backgroundColor: colors.chineseWhite.color,
    height: 1,
  },
  verticalDivider: {
    backgroundColor: colors.chineseWhite.color,
    width: 1,
    height: '100%',
  },
  dividerBlack: {
    backgroundColor: colors.black.color,
    height: 1,
  },
  /* --- Position --- */
  posAbsolute: {
    position: 'absolute',
  },
  posRelative: {
    position: 'relative',
  },
  posTop0: {
    top: 0,
  },
  posTop50: {
    top: 50,
  },
  posLeft0: {
    left: 0,
  },
  posLeft15: {
    left: 15,
  },
  posBottom0: {
    bottom: 0,
  },
  posBottom20: {
    bottom: 20,
  },
  posRight0: {
    right: 0,
  },
  posRightMin20: {
    right: -20,
  },
  posTopMin20: {
    top: -20,
  },
  width100: {
    width: '100%',
  },
  height100: {
    height: '100%',
  },
  height60: {
    height: '60%',
  },
  width2: {
    width: 2,
  },
  height32: {
    height: 32,
  },
  maxW205: {
    maxWidth: 205,
  },


  /* --- font ---- */
  font10: {
    fontSize: 10,
  },
  font12: {
    fontSize: 12,
  },
  font13: {
    fontSize: 13,
  },
  font14: {
    fontSize: 14,
  },
  font16: {
    fontSize: 16,
  },
  font18: {
    fontSize: 18,
  },
  font20: {
    fontSize: 20,
  },


  /* --- Background --- */
  transparentBG: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  /* --- Text Alignment -- */
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignVerticalBottom: {
    textAlignVertical: 'bottom',
  },
  textAlignRight: { textAlign: 'right' },
  elevatedBackdrop: {
    elevation: 5, // Android elevation for shadow
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    backgroundColor: '#fff',
  },
  shadowEffect: {
    shadowColor: colors.black.color,
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.57,
    shadowRadius: 15.19,
    elevation: 23,
  },
  shadowLight: {
    shadowColor: colors.eerieBlack.teritiary,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.4,
    shadowRadius: 15,
    elevation: 1,
  },
  tooltip: {
    borderRadius: 12,
    width: ACTUAL_WIDTH * 0.6,
  },
  textUnderline: { textDecorationLine: 'underline' },
  /* --- Letter Spacing --- */
  letterSpaceOneAndtwoTenth: { letterSpacing: 1.2 },
  /* --- Line Height --- */
  lineHeight16: { lineHeight: 16 },
  lineHeight12: { lineHeight: 12 },
  modalBackdrop: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backdropFilter: 'blur(10px)',
    backgroundColor: colors.eerieBlack.opacity40,
  },
});
