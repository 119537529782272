import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

function PrincipalEmptyIcon({
  color = '#6a6c78',
  width = 20.403,
  height = 19.874,
}) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="172.306"
      height="111.356"
      viewBox="0 0 172.306 111.356"
    >
      <G id="Skyscraper" transform="translate(-1458.351 -4345.328)">
        <Path
          id="Path_3821"
          data-name="Path 3821"
          d="M1462.505,4402.715l-2.279,0h0a1.873,1.873,0,0,0,0,3.746h2.281a1.873,1.873,0,0,0,0-3.745Z"
          transform="translate(0 50.092)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3822"
          data-name="Path 3822"
          d="M1552.846,4402.787l-5.623,0h0a1.873,1.873,0,0,0,0,3.745l5.622,0h0a1.873,1.873,0,0,0,0-3.746Z"
          transform="translate(75.94 50.152)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3823"
          data-name="Path 3823"
          d="M1498.061,4357.9a1.871,1.871,0,0,0-1.873,1.873v10.153H1484.11a5.446,5.446,0,0,0-5.439,5.438v5.05h-3.634a5.446,5.446,0,0,0-5.431,5.45v66.956l-3.289,0h0a1.873,1.873,0,0,0,0,3.746l138.652.109h0a1.873,1.873,0,0,0,0-3.746l-10.591-.007v-90.09a5.625,5.625,0,0,0-5.618-5.619h-7.317v-6.258a5.623,5.623,0,0,0-5.619-5.619h-23.138a5.624,5.624,0,0,0-5.619,5.619v6.258h-7.3a5.625,5.625,0,0,0-5.619,5.619v30.491h-7.623v-7.454a5.448,5.448,0,0,0-5.432-5.45h-3.643v-5.05a5.443,5.443,0,0,0-5.435-5.438h-12.08v-10.153A1.871,1.871,0,0,0,1498.061,4357.9Zm18.976,37.592a3.71,3.71,0,0,0-.365,1.591v2.941a.9.9,0,0,1-.568.215h-7.522a.936.936,0,0,1-.932-.935v-6.922a.934.934,0,0,1,.932-.935h7.522a.935.935,0,0,1,.933.935Zm-5.922,11.455a1.915,1.915,0,0,1,1.912-1.913c-.187,0,6.677.006,32.762,0a1.9,1.9,0,0,1,1.886,1.777v32.445c0,.093.024.18.028.271V4452.8h-36.589Zm38.433-4.2V4374.5a4.39,4.39,0,0,1,8.78,0v64.752a4.381,4.381,0,0,1-6.879,3.608v-35.917A5.628,5.628,0,0,0,1549.549,4402.744Zm1.264-51.8a1.873,1.873,0,0,1,1.873-1.873h23.138a1.873,1.873,0,0,1,1.873,1.873v6.258h-26.883Zm-11.048,10c47.955,0,34.645,0,48.995,0a1.877,1.877,0,0,1,1.873,1.873V4452.8H1551.45v-7.8a6.2,6.2,0,0,0,2.489.522,6.27,6.27,0,0,0,6.263-6.262V4374.5a6.263,6.263,0,0,0-12.526,0v27.131a5.57,5.57,0,0,0-1.886-.346h-7.9v-38.462A1.876,1.876,0,0,1,1539.765,4360.951Zm-5.62,40.335h-13.721l.013-4.225h13.708Zm-11.367-15.425v7.454h-2.341a3.742,3.742,0,0,0-1.526.33v-1.266a2.81,2.81,0,0,0-2.806-2.808h-7.522a2.81,2.81,0,0,0-2.805,2.808v6.922a2.81,2.81,0,0,0,2.805,2.807h1.549a5.639,5.639,0,0,0-2.762,4.836V4452.8h-34.017v-66.937a1.7,1.7,0,0,1,1.685-1.7c.188,0,49.173,0,46.054,0A1.7,1.7,0,0,1,1522.778,4385.861Zm-9.074-10.5v5.05h-31.286v-5.05a1.7,1.7,0,0,1,1.693-1.692h27.9A1.694,1.694,0,0,1,1513.7,4375.36Z"
          transform="translate(5.318)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3824"
          data-name="Path 3824"
          d="M1481.675,4368.951h-7.522a2.81,2.81,0,0,0-2.805,2.807v6.922a2.81,2.81,0,0,0,2.805,2.807h7.522a2.811,2.811,0,0,0,2.807-2.807v-6.922A2.811,2.811,0,0,0,1481.675,4368.951Zm.935,9.729a.936.936,0,0,1-.935.935h-7.522a.936.936,0,0,1-.933-.935v-6.922a.935.935,0,0,1,.933-.935h7.522a.935.935,0,0,1,.935.935Z"
          transform="translate(11.345 20.62)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3825"
          data-name="Path 3825"
          d="M1489.259,4368.951h-7.521a2.811,2.811,0,0,0-2.807,2.807v6.922a2.811,2.811,0,0,0,2.807,2.807h7.521a2.81,2.81,0,0,0,2.806-2.807v-6.922A2.81,2.81,0,0,0,1489.259,4368.951Zm.933,9.729a.936.936,0,0,1-.933.935h-7.521a.936.936,0,0,1-.934-.935v-6.922a.935.935,0,0,1,.934-.935h7.521a.934.934,0,0,1,.933.935Z"
          transform="translate(17.963 20.62)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3826"
          data-name="Path 3826"
          d="M1481.675,4376.962h-7.522a2.81,2.81,0,0,0-2.805,2.807v6.925a2.811,2.811,0,0,0,2.805,2.807h7.522a2.812,2.812,0,0,0,2.807-2.807v-6.925A2.811,2.811,0,0,0,1481.675,4376.962Zm.935,9.732a.936.936,0,0,1-.935.935h-7.522a.936.936,0,0,1-.933-.935v-6.925a.936.936,0,0,1,.933-.935h7.522a.936.936,0,0,1,.935.935Z"
          transform="translate(11.345 27.613)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3827"
          data-name="Path 3827"
          d="M1489.259,4376.962h-7.521a2.811,2.811,0,0,0-2.807,2.807v6.925a2.812,2.812,0,0,0,2.807,2.807h7.521a2.81,2.81,0,0,0,2.806-2.807v-6.925A2.81,2.81,0,0,0,1489.259,4376.962Zm.933,9.732a.936.936,0,0,1-.933.935h-7.521a.936.936,0,0,1-.934-.935v-6.925a.936.936,0,0,1,.934-.935h7.521a.936.936,0,0,1,.933.935Z"
          transform="translate(17.963 27.613)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3828"
          data-name="Path 3828"
          d="M1481.675,4384.974h-7.522a2.81,2.81,0,0,0-2.805,2.808v6.923a2.809,2.809,0,0,0,2.805,2.807h7.522a2.81,2.81,0,0,0,2.807-2.807v-6.923A2.812,2.812,0,0,0,1481.675,4384.974Zm.935,9.731a.936.936,0,0,1-.935.935h-7.522a.936.936,0,0,1-.933-.935v-6.923a.936.936,0,0,1,.933-.935h7.522a.936.936,0,0,1,.935.935Z"
          transform="translate(11.345 34.607)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3829"
          data-name="Path 3829"
          d="M1489.259,4384.974h-7.521a2.812,2.812,0,0,0-2.807,2.808v6.923a2.81,2.81,0,0,0,2.807,2.807h7.521a2.808,2.808,0,0,0,2.806-2.807v-6.923A2.81,2.81,0,0,0,1489.259,4384.974Zm.933,9.731a.936.936,0,0,1-.933.935h-7.521a.936.936,0,0,1-.934-.935v-6.923a.936.936,0,0,1,.934-.935h7.521a.936.936,0,0,1,.933.935Z"
          transform="translate(17.963 34.607)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3830"
          data-name="Path 3830"
          d="M1481.675,4392.986h-7.522a2.809,2.809,0,0,0-2.805,2.806v6.925a2.81,2.81,0,0,0,2.805,2.806h7.522a2.811,2.811,0,0,0,2.807-2.806v-6.925A2.811,2.811,0,0,0,1481.675,4392.986Zm.935,9.73a.935.935,0,0,1-.935.933h-7.522a.934.934,0,0,1-.933-.933v-6.925a.934.934,0,0,1,.933-.932h7.522a.934.934,0,0,1,.935.932Z"
          transform="translate(11.345 41.601)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3831"
          data-name="Path 3831"
          d="M1489.259,4392.986h-7.521a2.811,2.811,0,0,0-2.807,2.806v6.925a2.811,2.811,0,0,0,2.807,2.806h7.521a2.809,2.809,0,0,0,2.806-2.806v-6.925A2.809,2.809,0,0,0,1489.259,4392.986Zm.933,9.73a.934.934,0,0,1-.933.933h-7.521a.934.934,0,0,1-.934-.933v-6.925a.934.934,0,0,1,.934-.932h7.521a.934.934,0,0,1,.933.932Z"
          transform="translate(17.963 41.601)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3832"
          data-name="Path 3832"
          d="M1526.166,4434.837a6.27,6.27,0,0,0,6.263-6.262v-64.752a6.262,6.262,0,1,0-12.524,0v64.752A6.269,6.269,0,0,0,1526.166,4434.837Zm-4.388-71.015a4.389,4.389,0,0,1,8.778,0v64.752a4.389,4.389,0,1,1-8.778,0Z"
          transform="translate(53.73 10.679)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3833"
          data-name="Path 3833"
          d="M1516.823,4379.209h-21.782a3.7,3.7,0,1,0,0,7.4h21.782a3.7,3.7,0,0,0,0-7.4Zm0,5.526h-21.782a1.826,1.826,0,1,1,0-3.653h21.782a1.826,1.826,0,0,1,0,3.653Z"
          transform="translate(28.796 29.576)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3834"
          data-name="Path 3834"
          d="M1516.823,4385.058h-21.782a3.7,3.7,0,1,0,0,7.4h21.782a3.7,3.7,0,0,0,0-7.4Zm0,5.523h-21.782a1.825,1.825,0,1,1,0-3.649h21.782a1.825,1.825,0,0,1,0,3.649Z"
          transform="translate(28.796 34.68)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3835"
          data-name="Path 3835"
          d="M1516.823,4390.905h-21.782a3.7,3.7,0,1,0,0,7.4h21.782a3.7,3.7,0,0,0,0-7.4Zm0,5.525h-21.782a1.826,1.826,0,1,1,0-3.653h21.782a1.826,1.826,0,0,1,0,3.653Z"
          transform="translate(28.796 39.785)"
          fill="#6a6c78"
        />
        <Path
          id="Path_3836"
          data-name="Path 3836"
          d="M1516.823,4396.754h-21.782a3.7,3.7,0,1,0,0,7.4h21.782a3.7,3.7,0,0,0,0-7.4Zm0,5.527h-21.782a1.827,1.827,0,1,1,0-3.655h21.782a1.827,1.827,0,0,1,0,3.655Z"
          transform="translate(28.796 44.889)"
          fill="#6a6c78"
        />
      </G>
    </Svg>
  );
}

export default PrincipalEmptyIcon;
