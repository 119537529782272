import React, { useContext, useRef, useState } from 'react';
import {
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  ViewStyle,
  StyleProp,
  Animated,
  TextInput,
  TextStyle,
} from 'react-native';
import Popup from 'reactjs-popup';
import EStyleSheet from 'react-native-extended-stylesheet';
import { ThemeContext } from '../contexts/ThemeContext';
import { colors } from '../colors/colors';
import { fontConfig } from '../fonts/fonts';
import _ from 'lodash';
import Icon from 'react-native-easy-icon';
import { textInputValidate, hash, titleTruncate } from '@zonofi/common';

interface dropdownOption {
  value: any;
  label: string;
}

interface selectDropDownProps {
  value?: string;
  data: dropdownOption[];
  title: string;
  showDropdown: { open: boolean; id: string };
  setShowDropdown: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      id: string;
    }>
  >;
  selectedValue?: string;
  changeSelected?: (value: string) => void;
  onOpen?: () => void;
  multiOption?: boolean;
  changeSelectedOptions?: (value: { value: any; label: string }[]) => void;
  containerStyles?: StyleProp<ViewStyle>;
  dropDownContainerStyles?: StyleProp<ViewStyle>;
  disabled?: boolean;
  optionList?: { value: any; label: string }[];
  labelStyles?: StyleProp<TextStyle>;
  textStyles?: StyleProp<TextStyle>;
  multiSelect?: boolean;
  isSearchDropdown?: boolean;
  searchText?: (text: string) => void;
  iconStyles?: StyleProp<ViewStyle>;
  isEditTM?: boolean | string;
  multiSelectStyle?: StyleProp<ViewStyle>;
  customDropDown?: true;
  customDropDownComponent?: JSX.Element;
}

export const MultiSelectDropDown: React.FC<selectDropDownProps> = ({
  data,
  title,
  showDropdown,
  setShowDropdown,
  selectedValue,
  changeSelected,
  onOpen,
  multiSelect,
  optionList,
  containerStyles,
  dropDownContainerStyles,
  disabled = false,
  labelStyles,
  textStyles,
  isSearchDropdown,
  searchText,
  value,
  changeSelectedOptions,
  iconStyles,
  multiOption,
  isEditTM,
  multiSelectStyle,
  customDropDown,
  customDropDownComponent,
}) => {
  const currentTheme = useContext(ThemeContext);
  const { theme } = currentTheme;
  const position = useRef(new Animated.Value(0)).current;
  const popupRef = useRef() as any;
  const [isFieldActive, setIsFieldActive] = useState<boolean>(false);
  const [animatedValue] = useState(new Animated.Value(0));

  const [options, setOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >(optionList ?? []);
  const handleFocus = () => {
    if (!isFieldActive) {
      setIsFieldActive(true);
      Animated.timing(position, {
        toValue: 1,
        duration: 150,
        useNativeDriver: false,
      }).start();
    }
  };

  React.useEffect(() => {
    setOptions(optionList ?? []);
  }, [optionList]);

  const handleBlur = () => {
    if (isFieldActive) {
      setIsFieldActive(false);
      Animated.timing(position, {
        toValue: 0,
        duration: 150,
        useNativeDriver: false,
      }).start();
    }
  };

  const translateY = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [34, 10],
  });

  const _returnAnimatedTitleStyles = () => {
    return {
      top: position.interpolate({
        inputRange: [0, 1],
        outputRange: [34, 10],
      }),
      fontSize: isFieldActive ? 10 : 14,
      color: isFieldActive ? '#999' : theme.text.primary,
      fontfamily: theme.fontConfig.regular.fontFamily,
    };
  };

  const onDelete = (val: string) => {
    setOptions(options?.filter((x) => x.value !== val));
    changeSelectedOptions &&
      changeSelectedOptions(options?.filter((x) => x.value !== val));
  };

  return (
    <View style={[styles['container']]}>
      <Popup
        trigger={
          <View
            style={[
              styles['dropDownContainer'],
              { borderColor: theme.input.border },
              !_.isUndefined(containerStyles) && containerStyles,
            ]}
          >
            <View style={{ width: '94%' }}>
              <Animated.Text
                style={[
                  // _returnAnimatedTitleStyles(),

                  {
                    transform: [{ translateY }],
                    marginLeft: 10,
                  },
                  isFieldActive
                    ? {
                        // marginTop: -31,
                        //   fontSize: 12,
                        backgroundColor: colors.white.color,
                        color: colors.black.opacity80,
                      }
                    : {
                        ...fontConfig.default.segoeRegular,
                        backgroundColor: colors.transparent.color,
                      },
                ]}
              >
                {title}
              </Animated.Text>
              {multiSelect && !_.isEmpty(options) ? (
                <View
                  style={[
                    {
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: 15,
                    },
                    multiSelectStyle,
                  ]}
                >
                  <ScrollView
                    showsHorizontalScrollIndicator={true}
                    keyboardShouldPersistTaps="always"
                    contentContainerStyle={[
                      {
                        height: 28,
                        width: 180,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      },
                      multiSelectStyle,
                    ]}
                    scrollEnabled={true}
                  >
                    {options.map((item) =>
                      getTagItem(
                        item.label,
                        12,
                        theme?.enableEdit,
                        isEditTM,
                        () => (disabled ? () => {} : onDelete(item.value))
                      )
                    )}
                  </ScrollView>
                </View>
              ) : (
                <TextInput
                  value={selectedValue}
                  style={[
                    styles['selectedStyle'],
                    fontConfig.default.segoeRegular,
                    { color: theme.text.primary },
                    textStyles,
                  ]}
                  onChangeText={(text) => {
                    if (textInputValidate(text).success) {
                      searchText && searchText(text);
                    }
                  }}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  editable={isSearchDropdown ? true : false}
                />
              )}
            </View>
            {!disabled && (
              <Icon
                type="antdesign"
                name={showDropdown?.open && !disabled ? 'caretup' : 'caretdown'}
                size={9}
                color={theme.dropDown.arrow}
                style={[styles['iconStyle'], iconStyles]}
              />
            )}
          </View>
        }
        ref={popupRef}
        onOpen={() => {
          !disabled && setShowDropdown({ open: true, id: title });
          !disabled && onOpen && onOpen();
        }}
        onClose={() => {
          setShowDropdown({ open: false, id: '' });
        }}
        on={['click']}
        arrowStyle={{ color: theme.enableEdit ? '#808285' : 'transparent' }}
        position={['bottom center', 'bottom center']}
        contentStyle={{ zIndex: 4250000 }}
      >
        {customDropDown ? (
          <View style={{ flex: 1 }}>{customDropDownComponent}</View>
        ) : (
          showDropdown &&
          !disabled && (
            <ScrollView
              showsHorizontalScrollIndicator={true}
              keyboardShouldPersistTaps="always"
              nestedScrollEnabled={true}
              style={[
                styles['dropDown'],
                !_.isUndefined(dropDownContainerStyles) &&
                  dropDownContainerStyles,
                {
                  backgroundColor: theme.enableEdit
                    ? theme.linearGradient.primary
                    : theme.primary,
                  borderColor: theme.enableEdit
                    ? theme.input.border
                    : '#C9CCD680',
                },
              ]}
            >
              {data.map((item, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    style={[
                      styles['dropDownListStyle'],
                      {
                        backgroundColor:
                          selectedValue === item?.value ||
                          optionList?.map((x) => x?.value).includes(item?.value)
                            ? '#eef4fc'
                            : 'transparent',
                      },
                    ]}
                    onPress={() => {
                      if (multiSelect) {
                        const isIncludes = optionList
                          ?.map((x) => x?.value)
                          .includes(item?.value);
                        if (isIncludes) {
                          setOptions(options.filter((x) => x !== item?.value));
                          changeSelectedOptions &&
                            changeSelectedOptions(
                              options?.filter((x) => x !== item?.value)
                            );
                        } else {
                          changeSelectedOptions &&
                            changeSelectedOptions([
                              ...options,
                              { label: item?.label, value: item?.value },
                            ]);
                        }
                      } else {
                        changeSelected && changeSelected(item?.value);
                      }
                      !multiOption && setShowDropdown({ open: false, id: '' });
                    }}
                  >
                    <Text
                      style={[
                        styles['textStyle'],
                        fontConfig.default.segoeRegular,
                        { color: theme.text.primary },
                      ]}
                    >
                      {item?.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          )
        )}
      </Popup>
    </View>
  );
};

const styles = EStyleSheet.create({
  container: {
    minHeight: 50,
    maxHeight: 50,
    width: '100%',
  },
  dropDownContainer: {
    // borderColor: '#34353e',
    borderWidth: 1,
    height: '50rem',
    borderRadius: '6rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderStyle: 'solid',
    paddingTop: '20rem',
    maxHeight: 50,
    position: 'relative',
    zIndex: 420000,
  },
  iconStyle: {
    color: '#9295a5',
    alignItems: 'center',
    marginRight: '10rem',
    marginTop: -20,
  },
  dropDownTextInputStyle: {
    position: 'absolute',
    fontFamily: 'Avenir-Medium',
    left: 3,
    fontSize: `12rem`,
    marginLeft: '11rem',
    marginTop: -53,
    paddingHorizontal: 3,
  },
  dropDown: {
    borderWidth: 1,
    maxHeight: 200,
    borderRadius: '6rem',
    paddingTop: '4rem',
    elevation: 3, // Android elevation
    shadowColor: '#00000029',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.6,
    shadowRadius: 6,
    width: '100%',
    marginTop: -6,
  },
  dropDownListStyle: {
    paddingHorizontal: '15rem',
    paddingVertical: '4rem',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  textStyle: {
    fontSize: `14rem`,
  },
  selectedStyle: {
    fontSize: `15rem`,
    fontFamily: 'Avenir-Medium',
    width: '100%',
    paddingLeft: '18rem',
    marginTop: -20,
  },
  extraValue: {
    color: colors.white.color,
    fontSize: 13,
    padding: 3,
    ...fontConfig.default.regular,
  },
  tagContainer: {
    borderWidth: 0.5,
    borderRadius: 3,
    paddingHorizontal: 4,
    paddingVertical: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 2,
    marginVertical: 3,
    alignItems: 'center',
  },
  tagText: {
    fontSize: 12,
    color: colors.white.color,
    lineHeight: 25,
    ...fontConfig.default.regular,
  },
  circle: {
    marginHorizontal: 5,
    height: 14,
    width: 14,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.manatee.color,
  },
});

export default MultiSelectDropDown;

export const getTagItem = (
  mainText: string,
  truncate: number,
  enableEdit: boolean,
  isEdit?: boolean | string,
  onDelete?: () => void
) => {
  const backGrounds = colors.tagColors;
  const bgColor = backGrounds[hash(mainText, backGrounds.length)];
  return (
    <View
      style={[
        {
          backgroundColor: bgColor.background,
          borderColor: 'transparent',
        },
        styles['tagContainer'],
        { width: 'max-content', height: 18, zIndex: 9 },
      ]}
    >
      {mainText?.length < 17 ? (
        <Text style={[styles['tagText'], { color: bgColor?.color }]}>
          {titleTruncate(mainText, truncate)}
        </Text>
      ) : (
        <Popup
          trigger={
            <Text style={[styles['tagText'], { color: bgColor?.color }]}>
              {titleTruncate(mainText ?? '-', 17)}
            </Text>
          }
          arrowStyle={{ color: 'transparent' }}
          on={['hover', 'focus']}
        >
          <Text
            style={[
              styles['tagText'],

              {
                color: bgColor?.color,
                padding: 2,
                paddingHorizontal: 3,
                borderRadius: 2,
                backgroundColor: bgColor.background,
                fontFamily: 'Segoe-UI',
              },
            ]}
          >
            {mainText}
          </Text>
        </Popup>
      )}
      {isEdit && (
        <TouchableOpacity
          style={[styles['circle'], { zIndex: 99997989987 }]}
          onPress={() => {
            onDelete && onDelete();
          }}
        >
          <Icon
            type="entypo"
            name="cross"
            size={12}
            color={colors.white.color}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};
