import { ThemeContext } from '@zonofi/shared';
import { BottomSheetWeb } from '@zonofi/zono-money-design-system';
import { OrderDataLine, useAppSelector } from '@zonofi/zono-money-helpers';
import {
  ProductVariant,
  getCustomerInvite,
  useGetProductsMutation,
} from '@zonofi/zono-money-store';
import React, { useContext, useEffect, useState } from 'react';
import Icon from 'react-native-easy-icon';
import { AddProductCard } from './add-product-card';
import { SearchBar } from '../common';
import _ from 'lodash';

interface AddProductsModalProps {
  isVisible: boolean;
  onClose: () => void;
  editableData: OrderDataLine[];
  orderId?: number;
  handleDeleteLine: (product: ProductVariant) => void;
  updateLine: (line: OrderDataLine, isReplace?: boolean) => void;
  poFileLineId: string;
}

export const AddProductsModal: React.FC<AddProductsModalProps> = ({
  isVisible,
  onClose,
  editableData,
  orderId,
  handleDeleteLine,
  updateLine,
  poFileLineId,
}) => {
  const { theme } = useContext(ThemeContext);

  const [searchKey, setSearchKey] = useState('');

  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;
  const [fetchProducts, productResponse] = useGetProductsMutation();
  const [products, setProducts] = useState<ProductVariant[]>();

  const getProducts = () => {
    fetchProducts({
      workspaceId: seller?.workspaceId,
      params: { customerId: inviteId, pageSize: 40, pageNo: 1 },
      body: { searchKey: searchKey },
    });
  };

  useEffect(() => {
    getProducts();
  }, [seller, inviteId, searchKey]);

  useEffect(() => {
    const data = productResponse?.data?.products?.map(
      (x) => x?.productVariants?.[0]
    );
    setProducts(data);
  }, [productResponse]);

  return (
    <BottomSheetWeb isVisible={isVisible} onClose={onClose}>
      <div
        className="add-product-modal"
        style={{ backgroundColor: theme.primary }}
      >
        <div
          className="header"
          style={{ ...theme.fontConfig.semiBold, color: theme.text.primary }}
        >
          Search and add product
          <div style={{ cursor: 'pointer' }} onClick={onClose}>
            <Icon
              name="close"
              type="antdesign"
              size={18}
              color={theme.text.primary}
            />
          </div>
        </div>
        <section className="search-section">
          <SearchBar
            setSearchKey={setSearchKey}
            placeholder="Search SKU, product name"
            delay={500}
          />
        </section>
        <div className="product-card-container">
          {products
            ?.filter(
              (p) =>
                _.isEmpty(poFileLineId) ||
                !editableData.map((x) => x.productVariantId).includes(p.id)
            )
            ?.map((x) => (
              <AddProductCard
                product={x}
                editableData={editableData}
                orderId={orderId}
                handleDeleteLine={handleDeleteLine}
                updateLine={updateLine}
                poFileLineId={poFileLineId}
              />
            ))}
        </div>
      </div>
    </BottomSheetWeb>
  );
};
