export enum ConfigModule {
  CATALOGUE = 'Catalogue',
  PROMOTIONS = 'Promotions',
  ORDER = 'Order',
  INVOICE = 'Invoice',
  CUSTOMER = 'Customer',
  TAX = 'Tax',
  MFA = 'Mfa',
  CREDIT_LIMIT = 'Credit Limit',
  WBA = 'WBA',
  PRICING = 'Pricing',
}

export enum CreditLimitStrategy {
  DIVISON_CREDIT_LIMIT = 'Division credit limit',
  OVER_ALL_CREDIT_LIMIT = 'Over all credit limit',
  NONE = 'None',
}

export enum OrderSplitBy {
  cfaDivision = 'cfa-division',
  cfa = 'cfa',
  cfaHeaderDivision = 'cfa-header-division',
}

export enum CatalogueView {
  ListWithImagesView = 'ListWithImagesView',
  ThumbnailView = 'ThumbnailView',
  ListView = 'ListView',
}

export interface Config {
  pricingStrategy?: string;
  cfaApprovalRequired?: boolean;
  orderCutOffTime?: number;
  MOQ_delta?: number;
  priceCalculationStrategy?: string;
  distributorMargin?: number;
  MFA_workspace?: boolean;
  displayCreditLimitOnCheckout?: boolean;
  displayCreditLimitStrategy?: CreditLimitStrategy;
  displayCreditLimitOnApproval?: boolean;
  orderSplitBy?: OrderSplitBy;
  catalogueView?: CatalogueView;
  displayCataloguePrices?: string;
}

export interface ConfigDetailsRequest {
  workspaceId: string;
  divisionId?: string;
  customerGroupId?: string;
  customerId?: string;
  teamMemberId?: string;
  module: ConfigModule;
  rollback: boolean;
}

export interface ConfigDetailsResponse {
  id: string;
  createdDate: string;
  updatedDate: string;
  config: Config;
  workspaceId: string;
  divisionId: string | null;
  customerGroupId: string | null;
  customerId: string | null;
  teamMemberId: string | null;
  module: ConfigModule;
}
