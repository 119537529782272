import { ThemeContext } from '@zonofi/shared';
import {
  ProductVariant,
  getCustomerInvite,
  useEditOrderMutation,
} from '@zonofi/zono-money-store';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Icon from 'react-native-easy-icon';
import './add-to-order.scss';
import { OrderDataLine, useAppSelector } from '@zonofi/zono-money-helpers';

export interface AddToOrderProps {
  product?: ProductVariant;
  orderline: OrderDataLine;
  orderId?: number;
  allowUpdate?: boolean;
  updateLine?: (line: OrderDataLine) => void;
}

export const AddToOrder: React.FC<AddToOrderProps> = ({
  product,
  orderline,
  orderId,
  allowUpdate = false,
  updateLine,
}) => {
  const { theme } = useContext(ThemeContext);

  const [value, setValue] = useState(orderline?.newQty?.toString());
  const [moqError, setMoqError] = useState(false);

  const productMOQ = useMemo(
    () => orderline?.moq || product?.minOrderQty || 1,
    [orderline, product]
  );

  const inviteDetails = useAppSelector(getCustomerInvite);
  const { seller, inviteId } = inviteDetails;
  const [editOrder] = useEditOrderMutation();

  const onSaveChanges = () => {
    if (orderline.productVariantId) {
      editOrder({
        customerId: inviteId,
        workspaceId: seller?.workspaceId,
        lines: [
          {
            productVariantId: orderline.productVariantId,
            quantity: orderline.newQty?.toString(),
          },
        ],
        orderId: orderId?.toString() || '',
      })
        .unwrap()
        .then((res) => {
          if (res.orderLines) {
            const line = res.orderLines?.find(
              (x) => x.productVariant?.id === orderline.productVariantId
            );
            const qty = line?.quantity || orderline.quantity || 0;
            orderline.newQty = qty;
            updateLine && updateLine(orderline);
            setValue(qty?.toString());
          }
        })
        .catch((e) => console.error('add-to-order', e));
    }
  };

  const onEdit = (newValue: string) => {
    if (Number(newValue) < productMOQ) {
      setMoqError(true);
      setTimeout(() => {
        setMoqError(false);
      }, 2500);
      setValue(Math.ceil(orderline?.newQty)?.toString());
    } else {
      const qty = Math.ceil(Number(newValue) / productMOQ) * productMOQ;
      orderline.newQty = qty;
      if (allowUpdate) {
        onSaveChanges();
      } else {
        orderline.isEdited = true;
        setValue(qty.toString());
      }
    }
  };

  const onIncrement = () => {
    const qty = orderline?.newQty + productMOQ;
    orderline.newQty = qty;
    if (allowUpdate) {
      onSaveChanges();
    } else {
      orderline.isEdited = true;
      setValue(qty.toString());
    }
  };

  const onDecrement = () => {
    const qty = orderline?.newQty - productMOQ;
    if (qty < productMOQ) {
      setMoqError(true);
      setTimeout(() => {
        setMoqError(false);
      }, 2500);
    } else {
      orderline.newQty = qty;
      if (allowUpdate) {
        onSaveChanges();
      } else {
        orderline.isEdited = true;
        setValue(qty.toString());
      }
    }
  };

  const onAdd = () => {
    orderline.newQty = productMOQ;
    if (allowUpdate) {
      onSaveChanges();
    } else {
      orderline.isEdited = true;
      setValue(productMOQ?.toString());
    }
  };

  useEffect(() => {
    setValue(orderline?.newQty?.toString());
  }, [orderline?.newQty]);

  return (
    <div className="add-to-order">
      {Number(orderline?.newQty) !== 0 ? (
        <div className="update">
          <div className="icon" onClick={onDecrement}>
            <Icon type="antdesign" name="minus" color="#34a7e8" size={16} />
          </div>
          <input
            type="number"
            value={value}
            onChange={(e) => setValue(e?.target.value)}
            onBlur={(e) => {
              onEdit(e?.target.value);
            }}
          />

          <div className="icon" onClick={onIncrement}>
            <Icon type="antdesign" name="plus" color="#34a7e8" size={16} />
          </div>
        </div>
      ) : (
        <span className="add" style={theme.fontConfig.semiBold} onClick={onAdd}>
          Add to Order
        </span>
      )}
      {moqError && (
        <div className="moq-error" style={theme.fontConfig.semiBold}>
          Qty can’t be below product MOQ
        </div>
      )}
    </div>
  );
};

export default AddToOrder;
