import React, { useContext } from 'react';

import { ThemeContext } from '../contexts/ThemeContext';

type PlainButtonProps = {
  text: string;
  height?: string;
  width?: string;
  color: string;
  textColor: string;
};

export const PlainButton: React.FC<PlainButtonProps> = ({
  text,
  height,
  width,
  color,
  textColor,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      style={{
        ...{
          borderRadius: 6,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          backgroundColor: color,
          height: height ? height : 36,
          width: width ? width : 135,
        },
      }}
    >
      <div
        style={{
          borderRadius: 6,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            color: textColor,
            letterSpacing: 0,
            fontFamily: theme.fontConfig.bold.fontFamily,

            fontSize: 14,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};
