/* eslint-disable-next-line */
import React, { useContext } from 'react';
import { Avatar } from '../avatar/avatar';
import { ThemeContext } from '../contexts/ThemeContext';
import Popup from 'reactjs-popup';
import { TouchableOpacity } from 'react-native';
import { titleTruncate } from '@zonofi/common';
export interface AvatarNameProps {
  companyName: string;
  profilePic?: string;
  onClick?: () => void;
  textStyle?: React.CSSProperties;
  textOverflowLimit?: number;
}

export function AvatarName(props: AvatarNameProps) {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 5,
        paddingTop: 5,
      }}
    >
      <span style={{ textDecoration: 'none', marginRight: 12 }}>
        <Avatar
          title={props.companyName}
          cfa={true}
          profileUrl={props?.profilePic}
          size={32}
        />
      </span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '12rem',
          maxWidth: '85%',
          alignItems: 'center',
        }}
      >
        {props?.companyName?.length < (props?.textOverflowLimit ?? 30) ? (
          <span
            style={{
              fontFamily: theme.fontConfig.regular.fontFamily,
              fontSize: 16,
              width: '12rem',
              whiteSpace: 'nowrap',
              // textDecoration: 'underline',
              color: theme.table.underline,
              ...(props.textStyle ? props.textStyle : {}),
            }}
          >
            {props.companyName}
          </span>
        ) : (
          <Popup
            trigger={
              <span
                style={{
                  fontFamily: theme.fontConfig.regular.fontFamily,
                  fontSize: 16,
                  width: '12rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  textDecoration: 'underline',
                  color: theme.table.underline,
                  cursor: 'pointer',
                  ...(props.textStyle ? props.textStyle : {}),
                }}
                onClick={() => props?.onClick?.()}
              >
                {titleTruncate(
                  props.companyName ?? '-',
                  props.textOverflowLimit ?? 30
                )}
              </span>
            }
            arrowStyle={{ color: 'transparent' }}
            on={['hover', 'focus']}
          >
            <TouchableOpacity
              style={{
                width: '100%',
                backgroundColor: theme?.menu?.background,
                padding: 10,
                borderRadius: 5,
                elevation: 3,
                shadowColor: '#00000029',
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowOpacity: 0.6,
                shadowRadius: 6,
              }}
              onPress={() => props?.onClick?.()}
            >
              <span
                style={{
                  fontFamily: theme.fontConfig.regular.fontFamily,
                  fontSize: 16,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  textDecoration: 'underline',
                  color: theme.table.underline,
                  ...(props.textStyle ? props.textStyle : {}),
                }}
              >
                {props.companyName}
              </span>
            </TouchableOpacity>
          </Popup>
        )}
      </div>
    </div>
  );
}

export default AvatarName;
