export enum ButtonVariants {
  PLAIN = 'plain',
  GRADIENT = 'gradient',
}

export enum InputKeyboardType {
  TEXT = 'default',
  NUMBER = 'numeric',
  EMAIL = 'email-address',
  PHONE = 'phone-pad',
}

export enum PaymentRequestStatusEnum {
  PENDING = 'P',
  FAILED = 'F',
  SUCCESS = 'S',
  CANCELLED = 'C',
}

export enum PaymentMode {
  UPI = 'UPI',
  NET_BANKING = 'NB',
  OTHERS = 'OTHERS',
  CHEQUE = 'CHQ',
  DEMAND_DRAFT = 'DD',
  BANK_TRANSFER = 'BNTR',
  ONLINE = 'ONLINE',
  CASH = 'CASH',
  ONLINE_WALLET = 'APP',
  CREDIT_CARD = 'CC',
  DEBIT_CARD = 'DC',
  TRANSFER = 'TF',
  CREDIT_NOTE = 'CN',
}

export enum RecordPaymentInputFormKeys {
  AMOUNT = 'amount',
  CHQNUMBER = 'cheque-number',
  CHQDAY = 'cheque-day',
  BANK_BRANCH_NAME = 'bank-branch-name',
  NOTES = 'Notes',
}
export enum StatusVariants {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  WAITING = 'waiting',
  NEUTRAL = 'neutral',
}

export enum UserCreationInfoFormKeys {
  NAME = 'name',
  EMAIL = 'email',
}

export enum SearchBarVariants {
  CANCEL = 'oncancel',
  NOTCANCEL = 'notcancel',
}

export enum KYCStepStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum TeamInviteStatus {
  ACCEPTED = 'A',
  PENDING = 'P',
  NOTINVITED = 'N',
}

export enum CheckOutType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum OrderStatus {
  PAYMENT_AUTHORIZED = 'PaymentAuthorized',
  MODIFYING = 'Modifying',
  CANCELLED = 'Cancelled',
  CONFIRMED = 'Confirmed',
  BILLED = 'Billed',
  WAITING_FOR_CONFIRMATION = 'WaitingForCNF',
}

export enum RouteType {
  SALES = 'S',
  PRINCIPAL = 'P',
  COMMUNICATION = 'C',
}

export enum SelectedTabEnum {
  ORDERS = 'ORDERS',
  INVOICES = 'INVOICES',
  PAYMENTS = 'PAYMENTS',
  SUMMARY = 'SUMMARY',
  ACTIVITIES = 'ACTIVITIES',
  MEDIA_LINKS_DOCS = 'MEDIA LINKS DOCS',
  STARED_MESSAGES = 'STARED MESSAGES',
  DETAILS = 'detais',
}

export enum InvoiceDownloadType {
  IL = 'IL',
  AIOCD = 'AIOCD',
}

export enum InvoiceSelectedType {
  WITH_HEADER = 'WITH_HEADER',
  WITHOUT_HEADER = 'WITHOUT_HEADER',
}

export const taxOptions = [
  {
    label: 'GST-28',
    value: 'GST-28',
  },
  {
    label: 'GST-12',
    value: 'GST-12',
  },
  {
    label: 'GST-5',
    value: 'GST-5',
  },
  {
    label: 'GST-18',
    value: 'GST-18',
  },
  {
    label: 'GST-0',
    value: 'GST-0',
  },
];

export const expiryBeforeAfterOptions = [
  {
    label: 'Before',
    value: 'Before',
  },
  {
    label: 'After',
    value: 'After',
  },
];

export const periodOptions = [
  {
    label: '1 Months',
    value: '1 Months',
  },
  {
    label: '2 Months',
    value: '2 Months',
  },
  {
    label: '3 Months',
    value: '3 Months',
  },
  {
    label: '6 Months',
    value: '6 Months',
  },
  {
    label: '9 Months',
    value: '9 Months',
  },
  {
    label: '12 Months',
    value: '12 Months',
  },
  {
    label: '15 Months',
    value: '15 Months',
  },
  {
    label: '18 Months',
    value: '18 Months',
  },
];

export const uomOptions = [
  {
    label: 'DZ',
    value: 'DZ',
  },
  {
    label: 'PC',
    value: 'PC',
  },
  {
    label: 'CSE',
    value: 'CSE',
  },
  {
    label: 'KG',
    value: 'KG',
  },
  {
    label: 'GM',
    value: 'GM',
  },
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'CM',
    value: 'CM',
  },
  {
    label: 'PU',
    value: 'PU',
  },
  {
    label: 'PCK',
    value: 'PCK',
  },
  {
    label: 'PH',
    value: 'PH',
  },
  {
    label: 'STR',
    value: 'STR',
  },
  {
    label: 'VLS',
    value: 'VLS',
  },
  {
    label: 'BTL',
    value: 'BTL',
  },
  {
    label: 'LTR',
    value: 'LTR',
  },
];
export enum Environment {
  SELLERQA = 'QA',
  SELLERPROD = 'production',
  PHARM_CONNECT = 'PHARM_CONNECT',
  ZONO_DIGITAL = 'zono-digital',
}

export enum ReturnStatusEnum {
  APPROVED = 'APPROVED',
  SUBMITTED = 'SUBMITTED',
  DRAFT = 'DRAFT',
  INREVIEW = 'IN_REVIEW',
}

export enum ReturnsTooltipTagsEnum {
  TEMPLATE_NOT_FOUND = 'TEMPLATE NOT FOUND',
  TICKET_CREATED = 'TICKET CREATED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}

export enum NotExpiredReturnType {
  NotSold = 'NS',
  IncorrectProduct = 'IP',
  NotReceived = 'NR',
  PurchaseReturn = 'PR',
  Damaged = 'D',
  NearExpiry = 'NE',
  RejectedByPolicy = 'RBP',
}

export enum ReturnTypeEnum {
  PurchaseReturn = 'PR',
  NearExpiry = 'NE',
  IncorrectProduct = 'IP',
  RejectedByPolicy = 'RBP',
  NotSold = 'NS',
  NotReceived = 'NR',
  Damaged = 'D',
  NonDamaged = 'ND',
}

export enum ExpiredReturnType {
  IncorrectProduct = 'IP',
  RejectedByPolicy = 'RBP',
  // NotSold = 'NS',
  NotReceived = 'NR',
  Damaged = 'D',
  // NonDamaged = 'ND',
  NearExpiry = 'NE',
  PurchaseReturn = 'PR',
}

export enum ChannelDetailsTabs {
  ABOUT = 'about',
  MEDIA = 'media',
  MEMBERS = 'members',
}

export enum AssignAction {
  TO_CUSTOMER = 'AssignTeamMemberToCustomer',
  TO_TEAM_MEMBER = 'AssignCustomerToTeamMember',
}

export enum PaymentModeCode {
  OfflineCash = 'CASH',
  OfflineCheque = 'CHQ',
  Online = 'ONLINE',
  offlineDD = 'DD',
  OfflineBankTransfer = 'BNTR',
  UPI = 'UPI',
  Wallet = 'WALLET',
  NetBanking = 'NET_BANKING',
  Card = 'CARD',
  creditCard = 'CREDIT_CARD',
  CreditNote = 'CN',
}

export enum PricingStrategy {
  CFA_PRICE = 'cfa-price',
  CFA_CUSTOMER_PRICE = 'cfa-customer-price',
  CUSTOMER = 'customer-price',
  BASE_PRICE = 'base-price',
  PINCODE_PRICE = 'pinCode-price',
  PINCODE_CFA = 'pinCode-cfa-price',
  PINCODE_CFA_CUSTOMER = 'pinCode-cfa-customer-price',
  PINCODE_CUSTOMER = 'pinCode-customer-price',
}
