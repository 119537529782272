import { SendOtpResponse, UserAuthResponse } from '.';
import { zonoApi } from '../api';

interface sendOtpParams {
  mobileNumber: string;
  isResend: boolean;
}

interface verifyOtpParams {
  mobile: string;
  otp: string;
  inviteId?: string;
  changeRequest?: boolean;
}

interface UserAuthMutationResponse extends UserAuthResponse {
  alertMessage?: string;
  loginAttemptsLeftmessage?: string;
  message?: string;
  attemptsLeft?: number;
}

export const userApi = zonoApi.injectEndpoints({
  endpoints: (build) => ({
    sendOtp: build.query<SendOtpResponse, sendOtpParams>({
      query: (args) => {
        const { mobileNumber, isResend } = args;

        return isResend
          ? {
              url: 'sendotp',
              body: { mobile: mobileNumber, loginType: 'RESEND' },
              method: 'POST',
            }
          : {
              url: 'sendotp',
              body: { mobile: mobileNumber },
              method: 'POST',
            };
      },
    }),
    createUser: build.mutation<UserAuthMutationResponse, verifyOtpParams>({
      query: (verifyOtpParams) => {
        return {
          url: 'buyer/verifyotp',
          body: verifyOtpParams,
          method: 'POST',
        };
      },
      invalidatesTags: ['User'],
    }),
    getGuestToken: build.query<string, { inviteId: string }>({
      query: (input) => ({
        url: `user/guestToken/${input?.inviteId}`,
        method: 'GET',
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazySendOtpQuery,
  useCreateUserMutation,
  useLazyGetGuestTokenQuery,
} = userApi;
